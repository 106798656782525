import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TruckPopup from "./components/truck";
import Icon from '@material-ui/core/Icon';


const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentTruck = undefined;
let editMode = false;

const Truck = ({ showError, showMessage, history }) => {

    const [trucks, setTrucks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showTrucksDialog, setShowTrucksDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    function onNewTrucks(event) {
        editMode = false;
        setErrorMessage("");
        event.preventDefault();
        setShowTrucksDialog(true);
    }

    function handleTruckClose() {
        setErrorMessage(null);
        currentTruck = undefined;
        setShowTrucksDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const onTruckDeleteConfirm = () => {
        if (currentTruck && currentTruck.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`truck-delete/${currentTruck.id}`);

            response.then(res => {
                if (res) {
                    let index = trucks.findIndex((el) => {
                        return el.id === currentTruck.id;
                    });
                    setTrucks([...trucks.slice(0, index), ...trucks.slice(index + 1)]);
                }
            }).catch(err => {
                if (err.message !==undefined && err.message !== null && err.message === "data in use") {
                    showError("Truck Detail In Use")
                    return;
                }
                showError(err.message !== undefined
                    && err.message !== null ? err.message : "Unknown Error")
            }).finally(() => {
                setLoading(false);
                currentTruck = undefined;
            });

        }
    };

    const removeErrorMessage = value => {
        setErrorMessage('');
    }

    const onTruckDelete = (Truck) => {
        currentTruck = Truck;
        setShowDeleteConfirm(true);
    };

    const onTrucksSave = (Truck, setpopuploading) => {
        showMessage('');
        const response = api.post('truck', Truck);
        response.then(res => {
            setTrucks([...trucks, res])
            setShowTrucksDialog(false)
        }).catch(err => {
            setErrorMessage(err.message !== undefined &&
                err.message !== null ? err.message : "unknown error")
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onTruckEdit = (Truck) => {
        setErrorMessage("");
        currentTruck = {
            ...Truck,
        };
        editMode = true;
        setShowTrucksDialog(true);

    };

    const onTruckUpdate = (Truck, setPopupLoading) => {
        const response = api.post(`truck/${Truck.id}`, { ...Truck });
        response.then(() => {
            let index = trucks.findIndex((el) => {
                return el.id === Truck.id;
            });
            setTrucks([...trucks.slice(0, index),
            { ...Truck },
            ...trucks.slice(index + 1)]);
            setShowTrucksDialog(false);
        }).catch((err) => {
            setErrorMessage(err.message !== undefined
                && err.message !== null ? err.message : "Unknown error");
        }).finally(() => {
            setPopupLoading(false)
            setLoading(false);
        })
    };

    //Get all Trucks API Call
    const getAllTrucks = () => {
        setShowTrucksDialog(false);
        showError('');
        showMessage('');

        const response = api.get('trucks');

        response.then(res => {
            setTrucks([...res]);
        }).catch(err => {
            showError(err !== null && err !== undefined
                && err.message !== undefined
                && err.message !== null ? err.message : "No Truck Details Found");
        })
        return response;
    }


    const onload = () => {
        setLoading(true);
        Promise.all([getAllTrucks()]).finally(() => {
            setLoading(false);
        });
    }
    useEffect(onload, []);

    let actions = [
        rowData => ({
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit Truck',
            onClick: (event, rowData) => onTruckEdit(rowData)
        }),
        rowData => ({
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete Truck',
            onClick: (event, rowData) => onTruckDelete(rowData)
        })
    ];
    // if (isDesktop) {
    //     actions = [
    //         rowData => ({
    //             icon: () => <Icon color='primary'>edit</Icon>,
    //             tooltip: 'Edit Truck',
    //             onClick: (event, rowData) => onTruckEdit(rowData)
    //         }),
    //         rowData => ({
    //             icon: () => <Icon color='error'>delete</Icon>,
    //             tooltip: 'Delete Truck',
    //             onClick: (event, rowData) => onTruckDelete(rowData)
    //         })
    //     ]
    // }


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Truck
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewTrucks}>Add Truck Detail</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Id', field: 'id', hidden: true },
                { title: 'Truck Number', field: 'number', editable: 'never' },
            ]}
            data={trucks}
            title='Truck Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,

                toolbar: true
            }}
            actions={actions}
        />
        <TruckPopup errorMessage={errorMessage} showDialog={showTrucksDialog} handleClose={handleTruckClose} onSave={onTrucksSave} onEdit={onTruckUpdate}
            editMode={editMode} data={currentTruck} deleteErrorMessage={removeErrorMessage} />
        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onTruckDeleteConfirm}
            title='Delete Truck Details ?'
            subText='This action will delete the Truck Details. Please confirm.' />
    </div>
}


export default Truck;