import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

const schema = {
    incomeTypeName: {
        presence: {allowEmpty: false, message: 'is required'}
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },
}));

const IncomeType = ({ deleteErrorMessage, errorMessage, onEdit, data, onSave, handleClose, showDialog, editMode = false,dialogStyle,TransitionComponent}) => {
    const classes = useStyles();
    const [formState,hasError,handleChange,updateData] = useFromValidator(schema);
    const [popupLoading, setPopupLoading] = useState(false);

    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if(showDialog){
            setPopupLoading(false);
            updateData(data, editMode);
        }
    };
    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        setPopupLoading(true)
        const tempData = {
            ...formState.values
        };
        if (editMode)
            onEdit(tempData, setPopupLoading);
        else
            onSave(tempData, setPopupLoading);
    };




    return <div>

    {showDialog ? 
        <Dialog disableBackdropClick 
        hideBackdrop
        TransitionComponent={TransitionComponent}
        PaperProps={{ style: dialogStyle }}
        open={showDialog} onClose={handleClose} 
        aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog- title"   >{editMode?'Update':'New'} Income Type
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
            {popupLoading ?
                <div>
                <div className={classes.progressContainer}>
                        <CircularProgress  className={classes.progressBar}/ >
                    </div>
            </div>
            :""
            }
            <div>

            <div id="error">
            {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
            </div> 
            <br/>
                <TextField
                    className={classes.textField}
                    error={hasError('incomeTypeName')}
                    fullWidth
                    helperText={
                        hasError('incomeTypeName') ? formState.errors.incomeTypeName[0] : null
                    }
                    label="Income Type"
                    name="incomeTypeName"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.incomeTypeName || ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                />
                <DialogActions>
                    <Button
                        className={classes.signInButton}
                        color="primary"
                        disabled={!formState.isValid}
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={onSubmit}
                    >
                        Save
                    </Button>
                </DialogActions>
                </div>
            
            </DialogContent>
        </Dialog> :""
    }
    </div>
};

export default IncomeType;