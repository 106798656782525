import React, {useEffect,useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider,DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useGlobalState} from '../../../state';
import { version } from '../../../common/enums';


const schema = {
    amount: {
        presence: {allowEmpty: false, message: 'is required'},
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    date: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    farmIndex: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    expenseTypeIndex: {
        presence: {allowEmpty: false, message: 'is required'}
    },
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '50%',
        zIndex: '9999'
    },
}));

const OtherExpense = ({deleteErrorMessage, errorMessage, farms, vendors, expenseTypes, onEdit, data, onSave, handleClose, showDialog, editMode = false}) => {
    const classes = useStyles();
    const [{user}] = useGlobalState();
    const [formState,hasError,handleChange,updateData] = useFromValidator(schema);
    const [expenseTypeNoteVisible,setExpenseTypeNoteVisible]=useState(false)
    const [vendorNoteVisible,setVendorNoteVisible]=useState(false)
    const [popupLoading, setPopupLoading] = useState(false) ;
    const [vendorValidation,setvendorValidation] = useState(false) ;
    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if(showDialog){
            updateData(data, editMode);
        }
        if (data !== undefined && data.expenseTypeId === 0) {
            setExpenseTypeNoteVisible(true)
        }else{
            setExpenseTypeNoteVisible(false)
        }
        if (data !== undefined && data.vendorId === 0) {
            setVendorNoteVisible(true)
        }else{
            setVendorNoteVisible(false)
        }
    };
    useEffect(resetBeforeEdit, [showDialog]);

    useEffect(() => {
        const vendorIndex = formState.values?.vendorIndex
        if (user.versionID === version.pro) {
            setvendorValidation(true)
        } else if(vendorIndex)  {
            setvendorValidation(true)
        }
        // eslint-disable-next-line
    }, [formState.values, schema]);

    const onSubmit = () => {
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        const tempData = {
            ...formState.values,
            vendorId:(user.versionID === version.proPlus) ? vendors[formState.values.vendorIndex].id : 0,
            farmId:farms[formState.values.farmIndex].id,
            expenseTypeId:expenseTypes[formState.values.expenseTypeIndex].id,
            vendorName:(user.versionID === version.proPlus) ? vendors[formState.values.vendorIndex].name: " ",
            farmName:farms[formState.values.farmIndex].name,
            expenseTypeName:expenseTypes[formState.values.expenseTypeIndex].expenseTypeName,
        };
        setPopupLoading(true)
        if (editMode)
            onEdit(tempData, setPopupLoading);
        else
            onSave(tempData, setPopupLoading);
    };

    const handleIfExpenseTypeOther = (event) =>{
        console.debug("In handleIfNote",event);
        if (event.target.name === "expenseTypeIndex" && event.target.value === 0) {
            setExpenseTypeNoteVisible(true)
        }else{
            setExpenseTypeNoteVisible(false)
        }
    }

    const handleIfVendorOther = (event) =>{
        console.debug("In handleIfNote",event);
        if (event.target.name === "vendorIndex" && event.target.value === 0) {
            setVendorNoteVisible(true)
        }else{
            setVendorNoteVisible(false)
        }
    }



    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title" >{editMode?'Update':'New'} Other Expense
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>{}
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>
                <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
                
            <DatePicker
            disabled
                autoOk
                halfWidth
                variant="inline"
                inputVariant="outlined"
                label="Date"
                format="dd/MM/yyyy"
                value={formState.values.date || null}
                onChange={data => {
                    handleChange({target: {name: 'date', value: data}})
                }}
                animateYearScrolling
                name = "date"
                style={{backgroundColor:"#ffffff"}}
            />
            </MuiPickersUtilsProvider>
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="farmName-select-outlined-label">
                Farm Name
                </InputLabel>
                <Select
                id="farmName-select"
                value={formState.values.farmIndex !== undefined ? formState.values.farmIndex : ''}
                onChange={handleChange}
                name="farmIndex"
                >
                {farms !== undefined && farms !== null ?
                    farms.map((farm,index)=>
                    <MenuItem key = {farm.id} value={index}>{farm.name}</MenuItem>
                ):""}         
                </Select>
            </FormControl>
            <FormControl disabled  className={classes.formControl}>
                <InputLabel id="vendorName-select-outlined-label">
                Vendor Name
                </InputLabel>
                <Select
                id="vendorName-select"
                value={formState.values.vendorIndex !== undefined ? formState.values.vendorIndex : ''}
                onChange={data => {
                    handleChange(data,handleIfVendorOther)
                }}
                name="vendorIndex"
                >
                {vendors !== undefined && vendors !== null ?
                    vendors.map((vendor,index)=>
                    <MenuItem key = {vendor.id} value={index}>{vendor.name}</MenuItem>
                ):""}         
                </Select>
            </FormControl>
            {vendorNoteVisible ?  
            <TextField
            disabled
                multiline
                rows="3"
                className={classes.textField}
                error={hasError('vendorNote')}
                fullWidth
                helperText={
                    hasError('vendorNote') ? formState.errors.vendorNote[0] : null
                }
                label="Vendor Note"
                name="vendorNote"
                onChange={handleChange}
                type="text"
                value={formState.values.vendorNote || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            : null }
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="expenseTypeName-select-label">Expense Type</InputLabel>
                <Select
                id="expenseTypeName-select"
                value={formState.values.expenseTypeIndex !== undefined ? formState.values.expenseTypeIndex : ''}
                onChange={data => {
                    handleChange(data,handleIfExpenseTypeOther)
                }}
                name="expenseTypeIndex"
                >
                {expenseTypes.map((expenseType,index)=>
                    <MenuItem key = {expenseType.id} value={index}>{expenseType.expenseTypeName}</MenuItem>
                )}
                </Select>
            </FormControl>
            {expenseTypeNoteVisible ?  
            <TextField disabled
                multiline
                rows="3"
                className={classes.textField}
                error={hasError('expenseTypeNote')}
                fullWidth
                helperText={
                    hasError('expenseTypeNote') ? formState.errors.expenseTypeNote[0] : null
                }
                label="Expense Type Note"
                name="expenseTypeNote"
                onChange={handleChange}
                type="text"
                value={formState.values.expenseTypeNote || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            : null }
            <TextField disabled
                className={classes.textField}
                error={hasError('amount')}
                fullWidth
                helperText={
                    hasError('amount') ? formState.errors.amount[0] : null
                }
                label="Amount (in Rs.)"
                name="amount"
                onChange={handleChange}
                type="number"
                value={formState.values.amount || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions>
                <Button 
                    className={classes.signInButton}
                    color="primary"
                    disabled
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
                :
                <div>
          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
            <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
            <DatePicker 
                autoOk
                halfWidth
                variant="inline"
                inputVariant="outlined"
                label="Date"
                format="dd/MM/yyyy"
                value={formState.values.date || null}
                onChange={data => {
                    handleChange({target: {name: 'date', value: data}})
                }}
                animateYearScrolling
                name = "date"
                style={{backgroundColor:"#ffffff"}}
            />
            </MuiPickersUtilsProvider>
            <FormControl  className={classes.formControl}>
                <InputLabel id="farmName-select-outlined-label">
                Farm Name
                </InputLabel>
                <Select
                id="farmName-select"
                value={formState.values.farmIndex !== undefined ? formState.values.farmIndex : ''}
                onChange={handleChange}
                name="farmIndex"
                >
                {farms !== undefined && farms !== null ?
                    farms.map((farm,index)=>
                    <MenuItem key = {farm.id} value={index}>{farm.name}</MenuItem>
                ):""}         
                </Select>
            </FormControl>
            {
                 (user.versionID === version.proPlus) && 
                <FormControl  className={classes.formControl}>
                    <InputLabel id="vendorName-select-outlined-label">
                    Vendor Name
                    </InputLabel>
                    <Select
                    id="vendorName-select"
                    value={formState.values.vendorIndex !== undefined ? formState.values.vendorIndex : ''}
                    onChange={data => {
                        handleChange(data,handleIfVendorOther)
                    }}
                    name="vendorIndex"
                    >
                    {vendors !== undefined && vendors !== null ?
                        vendors.map((vendor,index)=>
                        <MenuItem key = {vendor.id} value={index}>{vendor.name}</MenuItem>
                    ):""}         
                    </Select>
                </FormControl>
            }
            {vendorNoteVisible && (user.versionID === version.proPlus) ?  
            <TextField
                multiline
                rows="3"
                className={classes.textField}
                error={hasError('vendorNote')}
                fullWidth
                helperText={
                    hasError('vendorNote') ? formState.errors.vendorNote[0] : null
                }
                label="Vendor Note"
                name="vendorNote"
                onChange={handleChange}
                type="text"
                value={formState.values.vendorNote || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            : null }
            <FormControl className={classes.formControl}>
                <InputLabel id="expenseTypeName-select-label">Expense Type</InputLabel>
                <Select
                id="expenseTypeName-select"
                value={formState.values.expenseTypeIndex !== undefined ? formState.values.expenseTypeIndex : ''}
                onChange={data => {
                    handleChange(data,handleIfExpenseTypeOther)
                }}
                name="expenseTypeIndex"
                >
                {expenseTypes.map((expenseType,index)=>
                    <MenuItem key = {expenseType.id} value={index}>{expenseType.expenseTypeName}</MenuItem>
                )}
                </Select>
            </FormControl>
            {expenseTypeNoteVisible ?  
            <TextField
                multiline
                rows="3"
                className={classes.textField}
                error={hasError('expenseTypeNote')}
                fullWidth
                helperText={
                    hasError('expenseTypeNote') ? formState.errors.expenseTypeNote[0] : null
                }
                label="Expense Type Note"
                name="expenseTypeNote"
                onChange={handleChange}
                type="text"
                value={formState.values.expenseTypeNote || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            : null }
            <TextField
                className={classes.textField}
                error={hasError('amount')}
                fullWidth
                helperText={
                    hasError('amount') ? formState.errors.amount[0] : null
                }
                label="Amount (in Rs.)"
                name="amount"
                onChange={handleChange}
                type="number"
                value={formState.values.amount || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid || !vendorValidation}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>

}
{}

        </DialogContent>
    </Dialog>
};

export default OtherExpense;