import React, {useEffect} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Input } from '@material-ui/core';
import useFromValidator from '../../../common/formValidator';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';

const schema = {
    companyName: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    address: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    paymentTerms: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    termsAndConditions: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    gstNumber: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    bankName: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    accountNumber: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    ifscCode: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    branchName: {
        presence: {allowEmpty: false, message: 'is required'}
    }
   
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    }
    
}));

const InvoiceSetting = ({ deleteErrorMessage, errorMessage, onEdit, data, onSave, handleClose, showDialog, editMode = false}) => {
    const classes = useStyles();
    const [formState,hasError,handleChange,updateData, setFormState] = useFromValidator(schema);

    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if(showDialog){
            updateData(data, editMode);
        }
    };
    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        const tempData = {
            ...formState.values
        };
        if (editMode)
            onEdit(tempData);
        else
            onSave(tempData);
    };

    const getBase64 = (file, cb) =>{
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    
    const handleImage=(event)=>{
        handleChange(event);
        let imgBase64 = '';
        getBase64(event.target.files[0], (result) => {
            imgBase64 = result;
            setFormState({
                ...formState,
                values : {
                    ...formState.values,
                    companyLogo:imgBase64
                }
            })
        });
    };


    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >{editMode?'Update':'New'} Invoice Settings Detail
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
        <img alt="Company Logo"label="company Logo" hidden={(formState.values.companyLogo==null||undefined?true:false)}src={formState.values.companyLogo || ''} /><br />
     Company Logo<Input 
                className={classes.textField}
                error={hasError('companyLogo')}
                fullWidth
                helpertext={
                    hasError('companyLogo') ? formState.errors.companyLogo[0] : null
                }
                label="Company Logo"
                name="companyLogoSelector"
                onChange={handleImage}
                type="file"
                value={formState.values.companyLogoSelector || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
           
            <TextField
                className={classes.textField}
                error={hasError('companyName')}
                fullWidth
                helpertext={
                    hasError('companyName') ? formState.errors.companyName[0] : null
                }
                label="Company Name"
                name="companyName"
                onChange={handleChange}
                type="text"
                value={formState.values.companyName || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
             <TextField
                className={classes.textField}
                error={hasError('address')}
                fullWidth
                helpertext={
                    hasError('address') ? formState.errors.address[0] : null
                }
                label="Address"
                name="address"
                multiline
                rows="3"
                onChange={handleChange}
                type="text"
                value={formState.values.address || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
             <TextField
                className={classes.textField}
                error={hasError('paymentTerms')}
                fullWidth
                multiline
                rows="3"
                helpertext={
                    hasError('paymentTerms') ? formState.errors.paymentTerms[0] : null
                }
                label="Payment Terms"
                name="paymentTerms"
                onChange={handleChange}
                type="text"
                value={formState.values.paymentTerms || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('TermsAndConditions')}
                fullWidth
                multiline
                rows="3"
                helpertext={
                    hasError('TermsAndConditions') ? formState.errors.termsAndConditions[0] : null
                }
                label="Terms And Conditions"
                name="termsAndConditions"
                onChange={handleChange}
                type="text"
                value={formState.values.termsAndConditions || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('gstNumber')}
                fullWidth
                helpertext={
                    hasError('gstNumber') ? formState.errors.gstNumber[0] : null
                }
                label="GST Number"
                name="gstNumber"
                onChange={handleChange}
                type="text"
                value={formState.values.gstNumber || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('bankName')}
                fullWidth
                helpertext={
                    hasError('bankName') ? formState.errors.bankName[0] : null
                }
                label="Bank Name"
                name="bankName"
                onChange={handleChange}
                type="text"
                value={formState.values.bankName || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('accountNumber')}
                fullWidth
                helpertext={
                    hasError('accountNumber') ? formState.errors.accountNumber[0] : null
                }
                label="Account Number"
                name="accountNumber"
                onChange={handleChange}
                type="text"
                value={formState.values.accountNumber || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('ifscCode')}
                fullWidth
                helpertext={
                    hasError('ifscCode') ? formState.errors.ifscCode[0] : null
                }
                label="IFSC Code"
                name="ifscCode"
                onChange={handleChange}
                type="text"
                value={formState.values.ifscCode || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('branchName')}
                fullWidth
                helpertext={
                    hasError('branchName') ? formState.errors.branchName[0] : null
                }
                label="Branch Name"
                name="branchName"
                onChange={handleChange}
                type="text"
                value={formState.values.branchName || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
};

export default InvoiceSetting;