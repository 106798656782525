import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

const useCardStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    cardActionsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    grid :{
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2)
    }
}));

const Mobile = ({data,onDelete,onEdit}) => {
    const classes = useCardStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        onDelete();
    };

    const handleEdit = () => {
        setAnchorEl(null);
        onEdit();


    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    return <Grid item>
        <Card className={classes.card}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                }
                title={`Week ${data.week}`}
            />
            <CardContent>
                <Grid
                    className={classes.grid}
                    container
                    justify='space-between'
                    alignItems='flex-end'
                >
                    <Grid item  className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.stdProduction}
                        </Typography>
                        <Typography color="textSecondary">
                            Standard Production (%)
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.stdMortalityRate}
                        </Typography>
                        <Typography color="textSecondary">
                            Standard Mortality Rate (%)
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.weight}
                        </Typography>
                        <Typography color="textSecondary">
                            Weight (gms)
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Menu
            id="vaccination-schedule-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
    </Grid>
};

export default Mobile;