import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';



const schema = {
    email: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    roleIndex: {
        presence: {allowEmpty: false, message: 'is required'}
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },
}));

const SubUser = ({ deleteErrorMessage, errorMessage, onEdit, roles,data,farms, onSave, handleClose, showDialog, editMode = false}) => {
    const classes = useStyles();
    const [formState,hasError,handleChange,updateData] = useFromValidator(schema);
    const [chosenFarms, setChosenFarms] = useState([]);
    const [newFarms, setNewFarms] = useState([]);
    const [popupLoading, setPopupLoading] = useState(false);
    const [deletedFarms, setDeletedFarms] = useState([]);

    const removeFromArray = (farmId, variable,setVariable) =>{
        /*let index = variable.findIndex((farm) => {
            return farm.id === farmId;
        });*/
        let index = indexInOriginalArray(variable, farmId)
        if (index !== -1){
            setVariable([...variable.slice(0, index),
                ...variable.slice(index + 1)]);
        }
    }
    const indexInOriginalArray = (theArray, value)=>{
        let index = theArray.findIndex((farm) => {
            return farm.id === value;
        });
        return index
    }

      const handlCheckboxChange = (event) => {
        let farmId = parseInt(event.target.name);
        let isChecked = event.target.checked;
        if(editMode){
            if(isChecked){
                if(data.farms === undefined ||
                    (data.farms !== undefined && data.farms !== null && 
                    indexInOriginalArray(data.farms,farmId) === -1)){ // Not Present in original array
                    setNewFarms([ ...newFarms,{id : farmId}]);
                }
                removeFromArray(farmId, deletedFarms, setDeletedFarms);
            }
            else{
                removeFromArray(farmId, newFarms, setNewFarms)
                if(data.farms === undefined ||
                    (data.farms !== undefined && data.farms !== null && 
                    indexInOriginalArray(data.farms,farmId) !== -1)){ // Already in original array
                    setDeletedFarms([ ...deletedFarms,{id : farmId}]);
                }
            }            
        }
        if(isChecked){
            setChosenFarms([ ...chosenFarms,{id : farmId}]);
        }
        else{
            /*let index = chosenFarms.findIndex((farm) => {
                return farm.id === farmId;
            });
            setChosenFarms([...chosenFarms.slice(0, index),
                ...chosenFarms.slice(index + 1)]);*/
            removeFromArray(farmId, chosenFarms, setChosenFarms)
        }
      };
    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        
        setNewFarms([])
        setDeletedFarms([])

        if(editMode){ 
            console.log("data", data)    
            if(data !== undefined && data !== null &&
                data.farms !== undefined && data.farms !== null){
                setChosenFarms([...data.farms])
            }else{
                setChosenFarms([])
            }
        }else{
            setChosenFarms([])
        }
        if(showDialog){
            setPopupLoading(false);
            updateData(data, editMode);
        }
        
    };
    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        setPopupLoading(true)
        if (chosenFarms.length === 0 && formState.values.roleIndex !== "DRIVER"){
            alert("Please choose atleast one farm");
            setPopupLoading(false)
            return
        }
        if(formState.values.roleIndex === "FEEDSUPER" || formState.values.roleIndex === "SUPER" || formState.values.roleIndex === "DRIVER"){
            if(formState.values.firstName === undefined || formState.values.firstName === null || formState.values.firstName === ""){
                alert("Please enter the First Name");
                return
            }
            if(formState.values.lastName === undefined || formState.values.lastName === null || formState.values.lastName === ""){
                alert("Please enter the Last Name");
                return
            }
            if(formState.values.password === undefined || formState.values.password === null || formState.values.password === ""){
                alert("Please enter the Password");
                return
            }
        }
        const tempData = {
            ...formState.values,
            role:formState.values.roleIndex,
            farms: [...chosenFarms],
            newFarms: [...newFarms],
            deletedFarms: [...deletedFarms],
            
        };
        if (editMode){
            onEdit(tempData, setPopupLoading);
            console.log("tempdata", tempData)
        }
        else
            onSave(tempData, setPopupLoading);
    };

    const isChosen = (id) =>{
        let retVal = false;
        chosenFarms.map(farm =>{
            if(farm.id === id){
                retVal = true
            }
            return 0
        })
        return retVal
    }

    



    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   
        >{editMode?'Update':'New'} SubUser Detail</DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
          <DialogContent>
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>
                <TextField disabled
                className={classes.textField}
                error={hasError('email')}
                fullWidth
                helperText={
                    hasError('email') ? formState.errors.email[0] : null
                }
                label="Email"
                name="email"
                onChange={handleChange}
                type="text"
                value={formState.values.email || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
             <FormControl disabled className={classes.formControl}>
                <InputLabel disabled id="type-select-outlined-label">
                Role
                </InputLabel>
                <Select
                id="role"
                value={formState.values.roleIndex !== undefined ? formState.values.roleIndex : ''}
                onChange={handleChange}
                name="roleIndex"
                >
                      {roles !== undefined && roles !== null ?
                    roles.map((role,index)=>
                    <MenuItem key = {role.ID} value={role.ID}>{role.Name}</MenuItem>
                ):""}     
                </Select>
            </FormControl>
            <br/>
            {
                formState.values.roleIndex === "FEEDSUPER" || formState.values.roleIndex === "SUPER"|| formState.values.roleIndex === "STAFF"|| formState.values.roleIndex === "DRIVER"  ?   
                    <FormControl>        
                        <TextField disabled 
                            className={classes.textField}
                            error={hasError('firstName')}
                            fullWidth
                            helperText={
                                hasError('firstName') ? formState.errors.firstName[0] : null
                            }
                            label="First Name"
                            name="firstName"
                            onChange={handleChange}
                            type="text"
                            value={formState.values.firstName || ''}
                            variant="outlined"
                            style={{backgroundColor:"#ffffff"}}
                        />
                        <TextField disabled
                            className={classes.textField}
                            error={hasError('lastName')}
                            fullWidth
                            helperText={
                                hasError('lastName') ? formState.errors.lastName[0] : null
                            }
                            label="Last Name"
                            name="lastName"
                            onChange={handleChange}
                            type="text"
                            value={formState.values.lastName || ''}
                            variant="outlined"
                            style={{backgroundColor:"#ffffff"}}
                        />
                        <TextField disabled
                            className={classes.textField}
                            error={hasError('password')}
                            fullWidth
                            helperText={
                                hasError('password') ? formState.errors.password[0] : null
                            }
                            label="Password"
                            name="password"
                            onChange={handleChange}
                            type="text"
                            value={formState.values.password || ''}
                            variant="outlined"
                            style={{backgroundColor:"#ffffff"}}
                        />
                    </FormControl>     
                :""
            }
            <br/>
            <FormControl disabled component="fieldset" className={classes.formControl}>
            <div hidden={formState.values.roleIndex === "DRIVER"}>
                <FormLabel disabled component="legend">Farms</FormLabel>
                <FormGroup>
                {
                    farms.map((farm, i)=> 
                        <FormControlLabel
                            control={<Checkbox checked = {isChosen(farm.id)} onChange={handlCheckboxChange} name={farm.id} />}
                            label={farm.name}
                        />
                    )
                }
                </FormGroup>

            </div>
         
      </FormControl>
     
            
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
            :
            <div>
                
          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
            <TextField
                className={classes.textField}
                error={hasError('email')}
                fullWidth
                helperText={
                    hasError('email') ? formState.errors.email[0] : null
                }
                label="Email"
                name="email"
                onChange={handleChange}
                type="text"
                value={formState.values.email || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
             <FormControl  className={classes.formControl}>
                <InputLabel id="type-select-outlined-label">
                Role
                </InputLabel>
                <Select
                id="role"
                value={formState.values.roleIndex !== undefined ? formState.values.roleIndex : ''}
                onChange={handleChange}
                name="roleIndex"
                >
                      {roles !== undefined && roles !== null ?
                    roles.map((role,index)=>
                    <MenuItem key = {role.ID} value={role.ID}>{role.Name}</MenuItem>
                ):""}     
                </Select>
            </FormControl>
            <br/>
            {
                formState.values.roleIndex === "FEEDSUPER" || formState.values.roleIndex === "SUPER"|| formState.values.roleIndex === "STAFF"|| formState.values.roleIndex === "DRIVER" ?   
                    <FormControl>        
                        <TextField
                            className={classes.textField}
                            error={hasError('firstName')}
                            fullWidth
                            helperText={
                                hasError('firstName') ? formState.errors.firstName[0] : null
                            }
                            label="First Name"
                            name="firstName"
                            onChange={handleChange}
                            type="text"
                            value={formState.values.firstName || ''}
                            variant="outlined"
                            style={{backgroundColor:"#ffffff"}}
                        />
                        <TextField
                            className={classes.textField}
                            error={hasError('lastName')}
                            fullWidth
                            helperText={
                                hasError('lastName') ? formState.errors.lastName[0] : null
                            }
                            label="Last Name"
                            name="lastName"
                            onChange={handleChange}
                            type="text"
                            value={formState.values.lastName || ''}
                            variant="outlined"
                            style={{backgroundColor:"#ffffff"}}
                        />
                        <TextField
                            className={classes.textField}
                            error={hasError('password')}
                            fullWidth
                            helperText={
                                hasError('password') ? formState.errors.password[0] : null
                            }
                            label="Password"
                            name="password"
                            onChange={handleChange}
                            type="text"
                            value={formState.values.password || ''}
                            variant="outlined"
                            style={{backgroundColor:"#ffffff"}}
                        />
                    </FormControl>     
                :""
            }
            <br/>
            <FormControl component="fieldset" className={classes.formControl}>
            <div hidden={formState.values.roleIndex === "DRIVER"}>
                <FormLabel component="legend">Farms</FormLabel>
                <FormGroup>
                {
                    farms.map((farm, i)=> 
                        <FormControlLabel
                            control={<Checkbox checked = {isChosen(farm.id)} onChange={handlCheckboxChange} name={farm.id} />}
                            label={farm.name}
                        />
                    )
                }
                </FormGroup>

            </div>
         
      </FormControl>
     
            
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
          }
        </DialogContent>
    </Dialog>
};

export default SubUser;