/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import api from '../../../common/api';
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import amber from '@material-ui/core/colors/amber';
import Typography from "@material-ui/core/Typography";

import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
    },  
    chip: {
        margin: theme.spacing(1),
    },
    flex: {
        marginTop: theme.spacing(2),
        display:"flex",
        alignItems:"center",
    },
    formControl: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        minWidth: 160,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    datePicker: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth: 160,
    },
    dialogWidth: {
        minWidth: "1042px"
    },
    typo: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },
    warning: {
        backgroundColor: amber[700],
      }
}));


const schema = {
    materialNameIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    quantity: {
        presence: { allowEmpty: false, message: 'is required' },
        type: 'integer',
        numericality: {
            greaterThanOrEqualTo: 1,
        }
    },
    neccDate: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    neccPrice: {
        presence: false,
    },
    discount: {
        presence: false,
    },
    freeQuantity: {
        presence: false,
        type: 'integer',
        numericality: {
            greaterThanOrEqualTo: 0 ,
        }
    },
    returnTrays: {
        presence: false,
    },
    billValue: {
        presence: false
    },

};


const MaterialDialog = ({t, showDialog, handleCloseDialog, editMode, eggMaterials, onSave, onUpdate, data, isDesktop, selectedLineID}) => {
    const classes = useStyles();
    
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [billValue, setBillValue] = useState(undefined)
    const [eggMaterial, setEggMaterial] = useState(undefined)
    const [neccDate, setNeccDate] = useState(undefined)
    const [salePrice, setSalePrice] = useState(undefined)
    const [eggRemainingStock, setEggRemainingStock] = useState(undefined)

    useEffect(() => {
        if (showDialog) {
            if (editMode) {
                let lineSalePriceRequest = {
                    eggMaterialTypeID: data.materialId,
                    neccDate: data.neccDate,
                }
                getEggRemainingStock(data.materialId)
                getLineSalePrice(lineSalePriceRequest)
            } else {
                setNeccDate('')
                setEggMaterial(undefined)
                setBillValue(undefined)
                setSalePrice(undefined)
                setEggRemainingStock(undefined)
            }
            updateData(data, editMode)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDialog])

    const handleSaveMaterial = () => {
        let eggMaterial = eggMaterials[formState.values.materialNameIndex]
        
        let material = {
            materialID: eggMaterial.materialId,
            materialIndex: formState.values.materialNameIndex,
            materialName: eggMaterial.materialName, // eggType
            quantity: formState.values.quantity,
            neccDate: formState.values.neccDate,
            neccPrice: salePrice,
            discount: formState.values.discount ? formState.values.discount: 0,
            freeQuantity: formState.values.freeQuantity ? formState.values.freeQuantity: 0,
            billValue: billValue,
            returnTrays: formState.values.returnTrays ? formState.values.returnTrays : 0,
        }
        if (editMode) {
            material = {
                ...material,
                id: data.id
            }
            onUpdate(material)
        } else {
            onSave(material)
        }
    }

    const handleEggMaterialChange = (event) => {
        let eggMaterial = eggMaterials[event.target.value]
        getEggRemainingStock(eggMaterial.materialId)
        setEggMaterial(eggMaterial)
    }

    const getEggRemainingStock = (materialId) => {
        let date = new Date()

        const response = api.post(`eggRemainingStock`, {
            lineNameID: selectedLineID,
            date: date,
            materialId
        });
        response.then(res => {
            console.log("res.eggRemainingStock", res.eggRemainingStock)
            setEggRemainingStock(res.eggRemainingStock)
        }).catch(err => {
            if (err.message) {
                console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
            setEggRemainingStock(0)
        });
    }

    const handleNECCDateChange = (event) => {
        let neccDate = event.target.value
        setNeccDate(neccDate)
    }

    useEffect(() => {
        
        if (neccDate && (eggMaterial || data)) {
            let lineSalePriceRequest = {
                eggMaterialTypeID: eggMaterial ? eggMaterial.materialId : data.materialId,
                neccDate,
            }

            getLineSalePrice(lineSalePriceRequest)
        }
    }, [eggMaterial, neccDate])

    useEffect(() => {
        
        if (formState.values.quantity && salePrice !== undefined ) {
            let newSalePrice = salePrice
            if (formState.values.discount) {
                newSalePrice -= formState.values.discount
            }
            let bill = formState.values.quantity * 30 * newSalePrice
            
            setBillValue(bill)
        }
    }, [formState.values.quantity, salePrice, formState.values.discount])

    const getLineSalePrice = (lineSalePriceRequest) => {
        
        const response = api.post("get-line-sale-price", lineSalePriceRequest)
        response.then(res => {
            setSalePrice(res)
        }).catch(err => {
            if (err.message) {
                console.log("err.message", err.message);
                if (err.message === 'No data found') {
                    setSalePrice(0)
                } else {
                    console.log(err.message)
                }
            } else {
                console.log('Unknown Error')
            }
        })
    }


    return <Dialog disableBackdropClick fullWidth 
    maxWidth='sm' 
    fullScreen={!isDesktop} open={showDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" >{editMode ? t("updateMaterial") : t("newMaterial")} 
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
            <div>
                {salePrice && salePrice > 0 ? 
                    <Chip
                        label={`${t('necPrice')} = ${salePrice}`}
                        className={classes.chip} 
                        color="primary"
                    />
                : (salePrice !== undefined && salePrice === 0 && neccDate && (eggMaterial || data) ?
                    <Chip
                        label={t('noNeccPrice')}
                        className={classes.chip} 
                        color="primary"
                    />
                : null)}
            </div>
            <div>
                 <FormControl className={classes.formControl}>
                 <InputLabel id="materialName-select-label">{t("eggType")}</InputLabel>
                 <Select
                     id="materialName-select"
                     value={formState.values.materialNameIndex !== undefined ? formState.values.materialNameIndex : ''}
                     onChange={event => {
                        handleChange(event, handleEggMaterialChange)
                     }}
                     name="materialNameIndex"
                 >
                 {eggMaterials ? eggMaterials.map((eggMaterial, index) =>
                         <MenuItem key={eggMaterial.materialId} value={index}>{eggMaterial.materialName}</MenuItem>
                     ) : null} 
                 </Select>
                </FormControl>
                {eggRemainingStock !== undefined ? 
                <Typography className={classes.typo}>
                    {eggRemainingStock !== 0 ?
                        <span>Egg Remaining Stock = {eggRemainingStock} </span>
                        :
                        <span>No Remaining Stock is Present</span>
                    }
                </Typography>
                : null}
                <TextField
                    className={classes.textField}
                    error={hasError('quantity')}
                    fullWidth
                    helperText={
                        hasError('quantity') ? formState.errors.quantity[0] : null
                    }
                    label={t("quantityInTrays")}
                    name="quantity"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.quantity !== undefined ? formState.values.quantity : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                />
                <div style={{
                    display:"flex"
                }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            className={classes.textField}
                            helperText={
                                hasError('neccDate') ? formState.errors.neccDate[0] : null
                            }
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label={t("neccDate")}
                            format="dd/MM/yyyy"
                            value={formState.values.neccDate || null}
                            onChange={data => {
                                handleChange({ target: { name: 'neccDate', value: data } }, handleNECCDateChange)
                            }}
                            animateYearScrolling
                            name="neccDate"
                            style={{backgroundColor:"#ffffff"}}
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                    disabled={salePrice !== 0}
                    className={classes.textField}
                    error={hasError('neccPrice')}
                    helperText={
                        hasError('neccPrice') ? formState.errors.neccPrice[0] : null
                    }
                    label={t("neccPrice")}
                    name="neccPrice"
                    onChange={handleChange}
                    type="number"
                    value={salePrice ? salePrice : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff", marginLeft:"0.5rem", display:'none'}}
                />
                </div>
            <TextField
                    className={classes.textField}
                    error={hasError('discount')}
                    fullWidth
                    helperText={
                        hasError('discount') ? formState.errors.discount[0] : null
                    }
                    label={t("discount")}
                    name="discount"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.discount !== undefined ? formState.values.discount : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                />
                <TextField
                    className={classes.textField}
                    error={hasError('freeQuantity')}
                    fullWidth
                    helperText={
                        hasError('freeQuantity') ? formState.errors.freeQuantity[0] : null
                    }
                    label={t('freeQuantityInTrays')}
                    name="freeQuantity"
                    onChange={handleChange}
    
                    type="number"
                    value={formState.values.freeQuantity !== undefined ? formState.values.freeQuantity : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                />
                <TextField
                    className={classes.textField}
                    error={hasError('returnTrays')}
                    fullWidth
                    helperText={
                        hasError('returnTrays') ? formState.errors.returnTrays[0] : null
                    }
                    label={t("returnTrays")}
                    name="returnTrays"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.returnTrays !== undefined ? formState.values.returnTrays : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                />
                <TextField
                    disabled
                    className={classes.textField}
                    fullWidth
                    value={billValue !== undefined ? billValue : ''}
                    label={t("billValue")}
                    name="billValue"
                    type="number"
                    style={{backgroundColor:"#ffffff"}}
                    variant="outlined"
                />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSaveMaterial}
                >
                    {t("save")}
                </Button>
            </DialogActions>
            </div>
            
        </DialogContent>
    </Dialog>
};


export default MaterialDialog;