import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import red from '@material-ui/core/colors/red';

const useCardStyles = makeStyles({
    card: {
        minWidth: 275,
    },
    cardActionsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    chip: {
        backgroundColor: red[500],
        color: "white",
        fontWeight: 500
    },
});

const Mobile = ({data,onDelete,onEdit,handleIsActiveChange}) => {
    const classes = useCardStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        onDelete();
    };

    const handleEdit = () => {
        setAnchorEl(null);
        onEdit();


    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    return <Grid item>
        <Card className={classes.card}>
            <CardHeader
                action={data.isActive ? 
                    <IconButton aria-label="settings" onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                    :""
                }
                title={`Name : ${data.name}`}
            />
            <CardContent>
                <Grid
                    container
                    justify='space-between'
                    alignItems='flex-end'
                >
                    <Grid item>
                    <Typography variant="h5" component="h2">
                        {data.shedName}
                    </Typography>
                    <Typography color="textSecondary">
                        Shed Name
                    </Typography>
                    </Grid>
                    <Grid item>
                    <Typography variant="h5" component="h2">
                        {data.breedName}
                    </Typography>
                    <Typography color="textSecondary">
                        Breed Name
                    </Typography>
                    </Grid>
                    <Grid item>
                    <Typography variant="h5" component="h2">
                        {moment(data.purchaseDate).format("DD-MM-YYYY")}
                    </Typography>
                    <Typography color="textSecondary">
                        Purchase Date
                    </Typography>
                    </Grid><br/>
                    <Grid item>
                    <Typography variant="h5" component="h2">
                        {data.numberOfBirds}
                    </Typography>
                    <Typography color="textSecondary">
                        Number Of Birds
                    </Typography>
                    </Grid>
                    <Grid item>
                    <Typography variant="h5" component="h2">
                        {data.isActive ? 
                            <Switch
                                checked={data.isActive}
                                onChange={handleIsActiveChange}
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            :
                            <Chip 
                                variant="outlined" size="small" label="Deactivated"
                                className={classes.chip}
                            />
                        }
                    </Typography>
                    <Typography color="textSecondary">
                        Active
                    </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        {data.isActive ? 
        <Menu
            id="batch-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
        :
        ""
        }
    </Grid>
};

export default Mobile;