import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../../../common/formValidator';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import TextField from "@material-ui/core/TextField";

let schema = {
    date: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    mfgDate: {
        presence: false
    },
    expDate: {
        presence: false
    },
    batchNumber: {
        presence: false
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    spacing: {
        margin: theme.spacing(1)
    }
}));

const CompletedVacination = ({deleteErrorMessage, errorMessage, onVaccineCompletedNotificationSave, data, handleClose, showDialog,
    editMode = false, from, onDoctorNoteCreation, onDoctorNoteItemUpdate }) => {
    const classes = useStyles();
    const [isVaccine, setIsVaccine] = useState(false) 
    const [label, setLabel] = useState("")
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);

    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if (showDialog) {
            console.debug(data,hasError,from)

            if(from === "reminder"){
                console.log("formState.values.mfgDate", formState.values.mfgDate);
                setIsVaccine(data.isVaccination)
                setLabel("Reminder Completed Date")
            }else if(from === "doctorNoteCreation"){
                setLabel("Doctor Note Creation Date")
                data = {
                    ...data,
                    date:new Date()
                }
                editMode = true
            }else if(from === "doctorNotesItemCompleted"){
                setLabel("Item Completion Date")
            }else{
                setLabel("Completed Date")
            }
            updateData(data, editMode);
        }
    };
    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        if(from === "reminder"){
            let tempData = {
                ...formState.values,
                vaccinatedDate: {
                    Time: formState.values.date,
                    Valid: true
                }
            }
            console.log("DATA", tempData);
            onVaccineCompletedNotificationSave(tempData);
        }else if(from === "doctorNoteCreation"){
            onDoctorNoteCreation(formState.values.date)
        }else if(from === "doctorNotesItemCompleted"){
            let tempData = {
                ...formState.values,
                completionDate: {
                    Time: formState.values.date,
                    Valid: true
                }
            }
            onDoctorNoteItemUpdate(tempData)
        }
    };


    return <Dialog fullWidth={true} maxWidth='sm' disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >{label}
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
            <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
                <DatePicker
                    className={classes.spacing}
                    autoOk
                    fullWidth
                    // halfWidth
                    variant="inline"
                    inputVariant="outlined"
                    label={label}
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            {isVaccine ? <div>
                
                <TextField
                    className={classes.spacing}
                    error={hasError('Batch Number')}
                    fullWidth
                    // halfWidth
                    label="Batch No."
                    name="batchNumber"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.batchNumber !== undefined ? formState.values.batchNumber : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                />
                <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
                    <DatePicker
                        className={classes.spacing}
                        autoOk
                        fullWidth
                        // halfWidth
                        variant="inline"
                        inputVariant="outlined"
                        label={"Mfg. Date"}
                        format="dd/MM/yyyy"
                        value={formState.values.mfgDate !== "" ? formState.values.mfgDate : null}
                        onChange={data => {
                            handleChange({ target: { name: 'mfgDate', value: data } })
                        }}
                        animateYearScrolling
                        name="mfgDate"
                        style={{backgroundColor:"#ffffff"}}
                    />
                    <DatePicker
                        className={classes.spacing}
                        autoOk
                        fullWidth
                        // halfWidth
                        variant="inline"
                        inputVariant="outlined"
                        label={"Expiry Date"}
                        format="dd/MM/yyyy"
                        value={formState.values.expDate !== "" ? formState.values.expDate : null}
                        onChange={data => {
                            handleChange({ target: { name: 'expDate', value: data } })
                        }}
                        animateYearScrolling
                        name="expDate"
                        style={{backgroundColor:"#ffffff"}}
                    />
                </MuiPickersUtilsProvider>
            </div> : ""}
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
};

export default CompletedVacination;