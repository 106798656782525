import React,{useEffect} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {useGlobalState} from '../../../state';
// import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { version } from '../../../common/enums';

import FarmDashGraph from './farmGraph';
const useCardStyles = makeStyles(theme => ({
    cardContainer:{
        width:'inherit'
    },
    card: {
        // minWidth: 387
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    media: {
        height: 40,
        backgroundSize: 'contain',
        marginTop:'3px'
    },
    cardAction:{
        margin: 'auto'
    },
    grid:{
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    customSpacing: {
        whiteSpace: "pre",
    },
}));

const FarmCard = ({farm, onDelete, onEdit,history, farmReport, onFarmDetails, weatherData,weeklyData,isMainDash,
totalFarms}) => {
    const classes = useCardStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [{user}] = useGlobalState();


    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        onDelete(farm);
    };

    const handleEdit = () => {
        setAnchorEl(null);
        onEdit(farm);
    };

    const handleDetail = () => {
        onFarmDetails(farm)
    }

    const onload=()=>{
        console.debug(farmReport)
    }
    const infoText = `
    The following conditions are not
    used for calculation,

    1. AGE <=25 & PROD % <= 75%
    2. PROD % < 0
    3. PROD % >=100
    `
    useEffect(onload, []);

    const {name, location, pincode,id} = farm;
    const isMultiFarm  = (totalFarms > 1 );

    return <Grid item xl={8} lg={(!isMultiFarm && isMainDash) ? 12 : 4} md={!isMultiFarm ? 12 : 4} sm={!isMultiFarm ? 8 : 6}  xm={4} className={classes.cardContainer}>
        <Card className={classes.card}>
            <CardHeader
                action={
                    <div style={{display:'flex'}}>
                        {user.versionID !== version.lite && 
                              <div>
                                <img className={classes.media} src={`images/icons/${weatherData.iconCode}.png`}
                               // image={`http://openweathermap.org/img/wn/${weather.imageID}@4x.png`}
                               alt="weather"
                               />
                               <p style={{fontSize:"14px", lineHeight:"1rem",marginTop:"-15px",marginLeft:"1px"}}>{parseFloat((weatherData.minTemp + weatherData.maxTemp)/2).toFixed(2)} &#8451;</p>
                              </div>
                        }
                        <Tooltip
                        arrow
                        disableFocusListener
                        leaveDelay={500}
                        title={<span style={{fontSize:"14px", lineHeight:"1rem",}}>{infoText}</span>}
                        classes={{ tooltip: classes.customSpacing }}>
                            <IconButton aria-label="info">
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                        <IconButton aria-label="settings" onClick={handleClick}>
                            <MoreVertIcon/>
                        </IconButton>
                    </div>

                }
                title={name}
                subheader={<Typography style={{color:'#546e7a'}}>{location} - {pincode} <br/> {farmReport.date}</Typography>}
            />
            <CardContent>
                <Grid
                    container
                    justify='space-between'
                    alignItems='flex-end'
                    className={classes.topBar}
                >
                {user.versionID === version.proPlus ? 
                    <React.Fragment>
                        <Grid className={classes.grid}  xs={6}   item>
                            <Typography variant="h5" component="h2">
                                {weatherData.minTemp} &#8451;
                            </Typography>
                            <Typography color="textSecondary">
                                Min Temp
                            </Typography>
                        </Grid>
                        <Grid className={classes.grid}  xs={6}   item>
                            <Typography variant="h5" component="h2">
                                {weatherData.maxTemp} &#8451;
                            </Typography>
                            <Typography color="textSecondary">
                                Max Temp
                            </Typography>
                        </Grid>
                    </React.Fragment>
                : null}
                    <Grid className={classes.grid}  xs={6}   item>
                        <Typography variant="h5" component="h2">
                            {farmReport.produtionPercentage !== null && farmReport.produtionPercentage !== undefined ? 
                            (farmReport.produtionPercentage).toFixed(2) : 0}
                        </Typography>
                        <Typography color="textSecondary">
                            Production %
                        </Typography>
                    </Grid>
                    {
                        (user.versionID === version.proPlus) 
                        ? 
                        <Grid className={classes.grid}  xs={6}   item>
                        <Typography variant="h5" component="h2">
                            {farmReport.prodution !== null && farmReport.prodution !== undefined ? 
                            (farmReport.prodution) : 0}
                        </Typography>
                        <Typography color="textSecondary">
                            Production
                        </Typography>
                        </Grid>
                        :
                        <Grid className={classes.grid}  xs={6}   item>
                        <Typography variant="h5" component="h2">
                            {farmReport.feedIntakePerGram !== null && farmReport.feedIntakePerGram !== undefined ? 
                            (farmReport.feedIntakePerGram).toFixed(1) : 0}
                        </Typography>
                        <Typography color="textSecondary">
                            Feed Per Bird
                        </Typography>
                        </Grid>
                    }
                    {
                        (user.versionID === version.proPlus)
                        ? 
                        <Grid className={classes.grid}  xs={6}   item>
                        <Typography variant="h5" component="h2">
                            {farmReport.feedIntake !== null && farmReport.feedIntake !== undefined ? 
                            (farmReport.feedIntake).toFixed(2) : 0}
                        </Typography>
                        <Typography color="textSecondary">
                            Feed Intake
                        </Typography>
                        </Grid>
                        :
                        <Grid className={classes.grid}  xs={6}   item>
                        <Typography variant="h5" component="h2">
                            {farmReport.prodution !== null && farmReport.prodution !== undefined ? 
                            (farmReport.prodution) : 0}
                        </Typography>
                        <Typography color="textSecondary">
                            Production
                        </Typography>
                        </Grid>
                    }
                    {
                        (!isMainDash) && 
                        <>{
                            (user.versionID === version.proPlus)
                             ?
                             <Grid className={classes.grid}  xs={6}   item>
                             <Typography variant="h5" component="h2">
                                 {farmReport.sales !== null && farmReport.sales !== undefined ? 
                                 (farmReport.sales).toFixed(2) : 0}
                             </Typography>
                             <Typography color="textSecondary">
                                 Sales
                             </Typography>
                            </Grid>
                             :
                             <Grid className={classes.grid}  xs={6}   item>
                             <Typography variant="h5" component="h2">
                                 {farmReport.feedIntake !== null && farmReport.feedIntake !== undefined ? 
                                 (farmReport.feedIntake).toFixed(2) : 0}
                             </Typography>
                             <Typography color="textSecondary">
                                 Feed Intake
                             </Typography>
                             </Grid>
                        }
                        </>
                    }
                    {user.versionID === version.proPlus ? 
                        <React.Fragment>
                            {
                                (!isMainDash) ?
                                <Grid className={classes.grid}  xs={6}   item>
                                <Typography variant="h5" component="h2">
                                    {farmReport.eggsPerBag !== null && farmReport.eggsPerBag !== undefined ? 
                                    (farmReport.eggsPerBag).toFixed(2) : 0}
                                </Typography>
                                <Typography color="textSecondary">
                                    Eggs Per Bag
                                </Typography>
                            </Grid>
                               :
                               <Grid className={classes.grid}  xs={6}   item>
                                <Typography variant="h5" component="h2">
                                    {farmReport.feedIntakePerGram !== null && farmReport.feedIntakePerGram !== undefined ? 
                                    (farmReport.feedIntakePerGram).toFixed(1) : 0}
                                </Typography>
                                <Typography color="textSecondary">
                                    Feed Per Bird
                                </Typography>
                            </Grid>
                            }
                        </React.Fragment>
                    : null}
                    {
                        (isMainDash || (user.versionID === version.lite) || (user.versionID === version.pro)  ) &&
                        <Grid container spacing={3} style={{marginTop:'5px'}}>
                             <FarmDashGraph  farm={farm} weeklyData={weeklyData}
                                onFarmDetails={onFarmDetails}/>
                        </Grid>
                    }
                </Grid>
            </CardContent>
            <CardActions >
                <Button className={classes.cardAction} size="large" color="primary" 
                onClick={()=>{
                    if(user.role === "SUPER"){
                        history.push(`/farm/${id}/daily-entry`)
                    } else if (user.role === "FEEDSUPER") {
                        history.push(`/daily-entry-feed`)
                    }
                    else{ 
                        history.push(`/farm/${id}/dashboard`)
                    }
                    window.localStorage.setItem("farmName",name)
                }}>Click here for more details</Button>
            </CardActions>
        </Card>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleDetail}>Details</MenuItem>
            {
                (user.role === "ADMIN") &&
                <>
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </>
            }
        </Menu>

        {/* TODO
            Add a single bar chart for all farm cards dataa (Take production % and sale values alone)
        */}
    </Grid>
};

export default FarmCard;