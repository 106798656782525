import {blue, green, grey, orange, red} from '@material-ui/core/colors';


const white = '#FFFFFF';
const black = '#666666';

/*
#035A81 - DarkBlue

#0185C1 - Light Blue

#F26522 - Orange

 */
const regalBlue ={
    rb_100 : 'rgba(27, 58, 86, 1)',
    rb_90  : 'rgba(27, 58, 86, 0.9)',
    rb_70  : 'rgba(27, 58, 86, 0.7)',
    rb_50  : 'rgba(27, 58, 86, 0.5)',
    rb_30  : 'rgba(27, 58, 86, 0.3)',
    rb_10  : 'rgba(27, 58, 86, 0.1)'
}
const amaranth ={
    am_100 : 'rgba(238, 58, 67, 1)',
    am_90  : 'rgba(238, 58, 67, 0.9)',
    am_70  : 'rgba(238, 58, 67, 0.7)',
    am_50  : 'rgba(238, 58, 67, 0.5)',
    am_30  : 'rgba(238, 58, 67, 0.3)',
    am_10  : 'rgba(238, 58, 67, 0.1)'
}
const harvestGold ={
    hg_100 : 'rgba(243, 181, 107, 1)',
    hg_90  : 'rgba(243, 181, 107, 0.9)',
    hg_70  : 'rgba(243, 181, 107, 0.7)',
    hg_50  : 'rgba(243, 181, 107, 0.5)',
    hg_30  : 'rgba(243, 181, 107, 0.3)',
    hg_10  : 'rgba(243, 181, 107, 0.1)'
}
const darkGrey ={
    dg_100 : 'rgba(51, 51, 51, 1)',
    dg_90  : 'rgba(51, 51, 51, 0.9)',
    dg_70  : 'rgba(51, 51, 51, 0.7)',
    dg_50  : 'rgba(51, 51, 51, 0.5)',
    dg_30  : 'rgba(51, 51, 51, 0.3)',
    dg_10  : 'rgba(51, 51, 51, 0.1)'
}
const appwhite ={
    wt_100 : 'rgba(255, 255, 255, 1)',
    wt_90  : 'rgba(255, 255, 255, 0.9)',
    wt_70  : 'rgba(255, 255, 255, 0.7)',
    wt_50  : 'rgba(255, 255, 255, 0.5)',
    wt_30  : 'rgba(255, 255, 255, 0.3)',
    wt_10  : 'rgba(255, 255, 255, 0.1)'
}
export default {
    black,
    white:'#666666',
    primary: {
        contrastText: white,
        main: regalBlue.rb_100,
        secondary:'rgba(36, 42, 51, 0.9)',
        background:'rgba(36, 42, 51, 0.9)'
    },
    secondary: {
        contrastText: white,
        main: amaranth.am_100
    },
    success: {
        contrastText: white,
        dark: green[900],
        main: green[600],
        light: green[400]
    },
    info: {
        contrastText: white,
        main: '#0185C1'
    },
    warning: {
        contrastText: white,
        dark: orange[900],
        main: orange[600],
        light: orange[400]
    },
    error: {
        contrastText: white,
        dark: red[900],
        main: red[600],
        light: red[400]
    },
    text: {
        primary: regalBlue.rb_100,
        secondary: regalBlue.rb_90,
        link: blue[600],
        main: darkGrey.dg_90,
        default: appwhite.wt_100
    },
    background: {
        default: '#f6f9fa',
        paper: white
    },
    navBackground: {
        default: '#242a33',
        paper: '#d7e0ea'
    },
    navActive: harvestGold.hg_90,
    navHover: {
        text: harvestGold.hg_100,
    },
    navNestedMenuArrow: appwhite.wt_70,
    buttonColor:{
        primary: appwhite.wt_100//blueGrey[800]
    },
    profile:{
        name : appwhite.wt_100,
        box : appwhite.wt_30,
        bio : harvestGold.hg_100,
    },
    logoutSidebar: {
        background : harvestGold.hg_100,
        text : appwhite.wt_100,
        hover : harvestGold.hg_50
    },
    allButton: {
            background : darkGrey.dg_90,
            text : appwhite.wt_100,
            hover : harvestGold.hg_100
    },
    backgroundOpacityColor: appwhite.wt_70,
    icon: '#c5c7c9',
    divider: grey[200],
    red:{
        dark : amaranth.am_100,
        lightDark : amaranth.am_90,
        medium : amaranth.am_50,
        mediumLight : amaranth.am_30,
        light : amaranth.am_10
    },
    blue:{
        dark : regalBlue.rb_100,
        lightDark : regalBlue.rb_90,
        medium : regalBlue.rb_50,
        mediumLight : regalBlue.rb_30,
        light : regalBlue.rb_10
    },
};