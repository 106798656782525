import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const SegmentationGraph = (props) => {
    const doughnutChartData = {
        labels: props.label,
        datasets: [
            {
                data: props.data,
                // backgroundColor: ['#4B9E86', '#3D72EB', '#B658F5', '#F44336',
                //     '#00BCD4', '#FCBD01', '#FC91AD', '#ffeb3b', '#E65100'],
                backgroundColor: ['rgba(27, 58, 86, 0.9)','rgba(238, 58, 67, 1)','rgba(243, 181, 107, 1)','rgba(51, 51, 51, 1)',
                                  '#4B9E86','#242a33','rgba(70, 164, 77, 1)','rgba(153, 216, 344, 1)'],
                weight: 2
            }
        ]
    }
    const doughnutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: props.legendProp
        },
        cutoutPercentage: 80

    }

    return <Doughnut width={300} height={300} data={doughnutChartData} options={doughnutOptions} />

}

export default SegmentationGraph;