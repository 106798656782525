import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog,MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import IncomeType from "./components/incomeType";
import Icon from '@material-ui/core/Icon';
import Slide from '@material-ui/core/Slide';


const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentIncomeType = undefined;
let editMode = false;

const IncomeTypesView = ({showError, showMessage, history}) => {

    const [incomeTypes, setIncomeTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showIncomeTypeDialog, setShowIncomeTypeDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    function onNewIncomeType(event) {
        editMode = false;
        event.preventDefault();
        setShowIncomeTypeDialog(true);
    }

    function handleIncomeTypeClose() {
        currentIncomeType = undefined;
        setErrorMessage(null);
        setShowIncomeTypeDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onIncomeTypeDeleteConfirm = () => {
        if (currentIncomeType && currentIncomeType.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`income-type/${currentIncomeType.id}`);

            response.then(res => {
                if (res) {
                    let index = incomeTypes.findIndex((srcIncomeType) => {
                        return srcIncomeType.id === currentIncomeType.id;
                    });
                    let newIncomeTypes = [...incomeTypes];
                    newIncomeTypes.splice(index, 1);
                    setIncomeTypes(newIncomeTypes);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Income Type not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentIncomeType = undefined;
            });

        }
    };

    const onDelete = (incomeType) => {
        currentIncomeType = incomeType;
        setShowDeleteConfirm(true);
    };

    const onIncomeTypeSave = (incomeType, setpopuploading) => {
        //setLoading(true);
        // showError('');
        showMessage('');
        const response = api.post('income-type', {
            ...incomeType,
        });

        response.then(res => {
            setIncomeTypes([...incomeTypes, res]);
            setShowIncomeTypeDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Income Type not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onIncomeTypeEdit = (incomeType) => {
        currentIncomeType = {
            ...incomeType,
        };
        editMode = true;
        setShowIncomeTypeDialog(true);
    };

    const onIncomeTypeUpdate = (incomeType, setpopuploading) => {
        //setLoading(true);
        // showError('');
        const response = api.post(`income-type/${incomeType.id}`, {
            ...incomeType,

        });
        response.then(() => {
            let index = incomeTypes.findIndex((srcIncomeType) => {
                return srcIncomeType.id === incomeType.id;
            });
            setIncomeTypes([...incomeTypes.slice(0, index),
                {...incomeType},
                ...incomeTypes.slice(index + 1)]);
            setShowIncomeTypeDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('Income Type not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };

    //Get all IncomeTypes API Call
    const getAllIncomeTypes = () => {
        setShowIncomeTypeDialog(false);
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('income-type');

        response.then(res => {
            setIncomeTypes([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Income Type Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onload=()=>{
        getAllIncomeTypes()
    }
    useEffect(onload, []);
    

    let incomeTypeNameColumn = {title: 'Income Type', field: 'incomeTypeName', editable: 'never', defaultSort:'asc',
        render: rowData => <Mobile data={rowData}
                                   onEdit={() => {
                                       onIncomeTypeEdit(rowData)
                                   }}
                                   onDelete={() => {
                                       onDelete(rowData)
                                   }}
        />
    };
    let actions = [];
    if (isDesktop) {
        incomeTypeNameColumn = {title: 'Id', field: 'id', hidden:true, editable: 'never', defaultSort:'asc'};
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Income Type',
                onClick: (event, rowData) => onIncomeTypeEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Income Type',
                onClick: (event, rowData) => onDelete(rowData)
            }
        ]
    }
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
      });
      

    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Income Types
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewIncomeType}>Add Income Type</Button>
                <IncomeType deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage}
                 TransitionComponent={Transition}
                dialogStyle={isDesktop ? {
                    position: 'absolute',
                    top: "60px",
                    right: "20px",
                  } :{}}
                showDialog={showIncomeTypeDialog} handleClose={handleIncomeTypeClose} onSave={onIncomeTypeSave} onEdit={onIncomeTypeUpdate}
               editMode={editMode} data={currentIncomeType} />
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                incomeTypeNameColumn,
                {title: 'Income Type', field: 'incomeTypeName', hidden: !isDesktop, editable: 'never'},
            ]}
            data={incomeTypes}
            title='Income Type Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true
            }}
            actions={actions}
        />
      
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onIncomeTypeDeleteConfirm}
                       title='Delete Income Type ?'
                       subText='This action will delete the Income Type. Please confirm.'/>
    </div>
}

export default IncomeTypesView;