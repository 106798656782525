import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog,MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import ExpenseType from "./components/expenseType";
import Icon from '@material-ui/core/Icon';
import { useLocation } from 'react-router-dom'
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentExpenseType = undefined;
let editMode = false;

const ExpenseTypesView = ({showError, showMessage, history}) => {

    const [expenseTypes, setExpenseTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showExpenseTypeDialog, setShowExpenseTypeDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLineExpenseType, setIsLineExpenseType] = useState(0)
    const url = useLocation()
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    function onNewExpenseType(event) {
        editMode = false;
        event.preventDefault();
        setShowExpenseTypeDialog(true);
    }

    function handleExpenseTypeClose() {
        setErrorMessage(null);
        currentExpenseType = undefined;
        setShowExpenseTypeDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onExpenseTypeDeleteConfirm = () => {
        if (currentExpenseType && currentExpenseType.id) {
            setShowDeleteConfirm(false);
            //setLoading(true);
            showError('');
            const response = api.delete(`expense-type/${currentExpenseType.id}`);

            response.then(res => {
                if (res) {
                    let index = expenseTypes.findIndex((srcExpenseType) => {
                        return srcExpenseType.id === currentExpenseType.id;
                    });
                    let newExpenseTypes = [...expenseTypes];
                    newExpenseTypes.splice(index, 1);
                    setExpenseTypes(newExpenseTypes);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Expense Type not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentExpenseType = undefined;
            });

        }
    };

    const onDelete = (expenseType) => {
        currentExpenseType = expenseType;
        setShowDeleteConfirm(true);
    };

    const onExpenseTypeSave = (expenseType, setpopuploading) => {
        //setLoading(true);
        // showError('');
        showMessage('');
        const response = api.post('expense-type', {
            ...expenseType,
        });

        response.then(res => {
            setExpenseTypes([...expenseTypes, res]);
            setShowExpenseTypeDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Expense Type not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onExpenseTypeEdit = (expenseType) => {
        currentExpenseType = {
            ...expenseType,
        };
        editMode = true;
        setShowExpenseTypeDialog(true);
    };

    const onExpenseTypeUpdate = (expenseType, setpopuploading) => {
        setShowExpenseTypeDialog(false);
        //setLoading(true);
        // showError('');
        const response = api.post(`expense-type/${expenseType.id}`, {
            ...expenseType,

        });
        response.then(() => {
            let index = expenseTypes.findIndex((srcExpenseType) => {
                return srcExpenseType.id === expenseType.id;
            });
            setExpenseTypes([...expenseTypes.slice(0, index),
                {...expenseType},
                ...expenseTypes.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Expense Type not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };

    //Get all ExpenseTypes API Call
    const getAllExpenseTypes = () => {
        setShowExpenseTypeDialog(false);
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('expense-type');

        response.then(res => {
            setExpenseTypes([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Expense Type Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onload=()=>{
        getAllExpenseTypes()
    }
    useEffect(onload, []);

    useEffect(() => {
        if (url !== undefined && url !== null && 
            url.pathname !== undefined && url.pathname !== null &&
            url.pathname.includes("line")) {
                setIsLineExpenseType(1)
        } else {
            setIsLineExpenseType(0)
        }
    }, [url])    

    let expenseTypeNameColumn = {title: 'Expense Type', field: 'expenseTypeName', editable: 'never', defaultSort:'asc',
        render: rowData => <Mobile data={rowData}
                                   onEdit={() => {
                                       onExpenseTypeEdit(rowData)
                                   }}
                                   onDelete={() => {
                                       onDelete(rowData)
                                   }}
        />
    };
    let actions = [];
    if (isDesktop) {
        expenseTypeNameColumn = {title: 'Id', field: 'id', hidden:true, editable: 'never', defaultSort:'asc'};
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Expense Type',
                onClick: (event, rowData) => onExpenseTypeEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Expense Type',
                onClick: (event, rowData) => onDelete(rowData)
            }
        ]
    }
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
      });
      

    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Expense Types
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewExpenseType}>Add expense Type</Button>
                <ExpenseType deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showExpenseTypeDialog} handleClose={handleExpenseTypeClose} onSave={onExpenseTypeSave} onEdit={onExpenseTypeUpdate}
               editMode={editMode} 
               
               TransitionComponent={Transition}
               dialogStyle={isDesktop ? {
                   position: 'absolute',
                   top: "60px",
                   right: "20px",
                 } :{}}
               data={currentExpenseType} isLineExpenseType={isLineExpenseType}/>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                expenseTypeNameColumn,
                {title: 'Expense Type', field: 'expenseTypeName', hidden: !isDesktop, editable: 'never'},
            ]}
            data={expenseTypes.filter((el)=>{return  el.isLineEnabled === isLineExpenseType})}
            title='Expense Type Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true
            }}
            actions={actions}
        />
     
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onExpenseTypeDeleteConfirm}
                       title='Delete Expense Type ?'
                       subText='This action will delete the Expense Type. Please confirm.'/>
    </div>
}

export default ExpenseTypesView;