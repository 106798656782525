import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

const schema = {
    date: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    maleCost: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    femaleCost: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    overtimeCost: {
        presence: {allowEmpty: false, message: 'is required'}
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '2'
    },
}));

const LabourCost = ({ deleteErrorMessage, labourCostData,errorMessage,setErrorMessage,farmID, onEdit, data, onSave, 
    handleClose, showDialog, editMode = false,action}) => {
    const classes = useStyles();
    // eslint-disable-next-line
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [popupLoading, setPopupLoading] = useState(false) ;
    const resetBeforeEdit = () => {
        if(showDialog){
            updateData(data, editMode);
            setPopupLoading(false);
        }
    };

    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        const tempData = {
            ...formState.values
        };

        //same date entry validation
        let entryDate = moment(tempData.date).format('YYYY-MM-DD');
        let labourCostTemp = [...labourCostData];
        
        if (editMode) {
          let editDataIndex = labourCostTemp.findIndex(labour => labour.id === tempData.id);
          if (editDataIndex !== -1) {
            labourCostTemp.splice(editDataIndex, 1);
          }
        }
        
        for (let labour of labourCostTemp) {
          let labourDate = moment(labour.date).format('YYYY-MM-DD');
        
          if (labourDate === entryDate) {
            setErrorMessage('Already labour cost added for the Date');
            return;
          }
        }
        
        setPopupLoading(true)
        if (editMode){
                onEdit(tempData, setPopupLoading);
        }
        else
            onSave(tempData, setPopupLoading);
        
    };


    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >{action} Labour Cost
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({target: {name: 'date', value: data}})
                    }}
                    helperText={
                        hasError('date') ? formState.errors.date[0] : null
                    }
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <TextField
                    className={classes.textField}
                    error={hasError('maleCost')}
                    fullWidth
                    helperText={
                        hasError('maleCost') ? formState.errors.maleCost[0] : null
                    }
                    label="Male Labour Cost"
                    name="maleCost"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.maleCost !== undefined ? formState.values.maleCost : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                    InputProps={{
                        inputProps: { min: 0.1 }
                    }}
                />
                <TextField
                    className={classes.textField}
                    error={hasError('femaleCost')}
                    fullWidth
                    helperText={
                        hasError('femaleCost') ? formState.errors.femaleCost[0] : null
                    }
                    label="Female Labour Cost"
                    name="femaleCost"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.femaleCost !== undefined ? formState.values.femaleCost : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                    InputProps={{
                        inputProps: { min: 0.1 }
                    }}
                />
                <TextField
                    className={classes.textField}
                    error={hasError('overtimeCost')}
                    fullWidth
                    helperText={
                        hasError('overtimeCost') ? formState.errors.overtimeCost[0] : null
                    }
                    label="Overtime Cost"
                    name="overtimeCost"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.overtimeCost !== undefined ? formState.values.overtimeCost : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                    InputProps={{
                        inputProps: { min: 0 }
                    }}
                />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
                <br/><br/>
            </div>
                :
            <div>
          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({target: {name: 'date', value: data}})
                    }}
                    helperText={
                        hasError('date') ? formState.errors.date[0] : null
                    }
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <TextField
                    className={classes.textField}
                    error={hasError('maleCost')}
                    fullWidth
                    helperText={
                        hasError('maleCost') ? formState.errors.maleCost[0] : null
                    }
                    label="Male Labour Cost"
                    name="maleCost"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.maleCost !== undefined ? formState.values.maleCost : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                    InputProps={{
                        inputProps: { min: 0.1 }
                    }}
                />
                <TextField
                    className={classes.textField}
                    error={hasError('femaleCost')}
                    fullWidth
                    helperText={
                        hasError('femaleCost') ? formState.errors.femaleCost[0] : null
                    }
                    label="Female Labour Cost"
                    name="femaleCost"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.femaleCost !== undefined ? formState.values.femaleCost : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                    InputProps={{
                        inputProps: { min: 0.1 }
                    }}
                />
                <TextField
                    className={classes.textField}
                    error={hasError('overtimeCost')}
                    fullWidth
                    helperText={
                        hasError('overtimeCost') ? formState.errors.overtimeCost[0] : null
                    }
                    label="Overtime Cost"
                    name="overtimeCost"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.overtimeCost !== undefined ? formState.values.overtimeCost : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                    InputProps={{
                        inputProps: { min: 0 }
                    }}
                />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>

          }
        </DialogContent>
    </Dialog>
};

export default LabourCost;