import {blue, blueGrey, green, grey, lightBlue, orange, red} from '@material-ui/core/colors';


const white = '#FFFFFF';
const black = '#000000';

export default {
    black,
    white,
    primary: {
        contrastText: white,
        dark: lightBlue[900],
        main: lightBlue[700],
        light: lightBlue[100]
    },
    secondary: {
        contrastText: white,
        dark: red[900],
        main: red['A400'],
        light: red['A400']
    },
    success: {
        contrastText: white,
        dark: green[900],
        main: green[600],
        light: green[400]
    },
    info: {
        contrastText: white,
        dark: blue[900],
        main: blue[600],
        light: blue[400]
    },
    warning: {
        contrastText: white,
        dark: orange[900],
        main: orange[600],
        light: orange[400]
    },
    error: {
        contrastText: white,
        dark: red[900],
        main: red[600],
        light: red[400]
    },
    text: {
        primary: blueGrey[900],
        secondary: blueGrey[600],
        link: blue[600]
    },
    background: {
        default: '#ffffff',
        paper: white
    },
    buttonBackground :{
        primary :'#4CAF50',
        primaryHover :'#388E3C',
        secondary :'#EF5350',
        secondaryHover:'#E53935',
    },
    icon: blueGrey[600],
    divider: grey[200],
    labelBackground : {
        primary :'#E8F7F4',
        secondary: '#EAE0FF',
    },
};
