import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog, MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import MaterialMaster from "./components/materialMaster";
import Icon from '@material-ui/core/Icon';
import { CSVReader } from 'react-papaparse';
import MaterialMasterCSV from './templateFile/material_master.csv';
import { useGlobalState } from '../../state';
import { version } from '../../common/enums';
import MaterialMasterLiteCSV from './templateFile/material_master_lite.csv';
import MaterialMasterProCSV from './templateFile/material_master_pro.csv';

const buttonRef = React.createRef()

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    breadcrumbs: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    spacing: {
        marginLeft: theme.spacing(2),
    },
    download: {
        marginLeft: theme.spacing(1)
    }
}));

let currentMaterialMaster = undefined;
let editMode = false;

const MaterialMastersView = ({showError, showMessage}) => {
    const [{user}] = useGlobalState();

    const [materialMasters, setMaterialMasters] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showMaterialMasterDialog, setMaterialMasterDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [materialTypes, setMaterialType] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')
    const theme = useTheme();
    const [materialUnitTypes, setMaterialUnitTypes]= useState([]);

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    function onNewMaterialMaster(event) {
        editMode = false;
        event.preventDefault();
        if(user.versionID===version.lite){
            // The material type value set to Egg
            let index = materialTypes.findIndex((materialTypes) => {
                return materialTypes.materialType === "Eggs";
            });
            let allowedMaterialTypes = [materialTypes[index]];
            setMaterialType(allowedMaterialTypes);

            // The material unit set to tray
            let index1 = materialUnitTypes.findIndex((materialUnitTypes) => {
                return materialUnitTypes.name === "trays";
            });
            let allowedMaterialUnitTypes = [materialUnitTypes[index1]];
            setMaterialUnitTypes(allowedMaterialUnitTypes);
        }
        if(user.versionID===version.pro){
            // Removing raw materials from list
            let index = materialTypes.findIndex((materialTypes) => {
                return materialTypes.materialType === "Raw Materials";
            });
            let allowedMaterialTypes = [...materialTypes];
            allowedMaterialTypes.splice(index,1);
            setMaterialType(allowedMaterialTypes);
        }
        setMaterialMasterDialog(true);
    }

    function handleMaterialMasterClose() {
        setErrorMessage(null);
        currentMaterialMaster = undefined;
        setMaterialMasterDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onMaterialMasterDeleteConfirm = () => {
        if (currentMaterialMaster && currentMaterialMaster.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`material-master/${currentMaterialMaster.id}`);

            response.then(res => {
                if (res) {
                    let index = materialMasters.findIndex((srcMaterialMaster) => {
                        return srcMaterialMaster.id === currentMaterialMaster.id;
                    });
                    let newMaterialMasters = [...materialMasters];
                    newMaterialMasters.splice(index, 1);
                    setMaterialMasters(newMaterialMasters);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Material Master not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentMaterialMaster = undefined;
            });

        }
    };

     //Get All Account Types
     const getAllMaterialUnit = () => {
        
        showError('');
        showMessage('');

        const response = api.get('materialunit-types');
        response.then(res => {
            setMaterialUnitTypes([...res]);
            
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No materialUnit Found Catch Error');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }

    const onDelete = (materialMaster) => {
        currentMaterialMaster = materialMaster;
        setShowDeleteConfirm(true);
    };

    const onMaterialMasterSave = (materialMaster, setpopuploading) => {
        //setLoading(true);
        // showError('');
        showMessage('');
        const response = api.post('material-master', {
            ...materialMaster,
            
        });

        response.then(res => {
            setMaterialMasters([res, ...materialMasters]);
            setMaterialMasterDialog(false)
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Material Master not added');
                else if (err.message === 'duplicate key')
                    setErrorMessage('Duplicate Entry');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onMaterialMasterEdit = (materialMaster) => {
        currentMaterialMaster = {
            ...materialMaster,
            materialTypeIndex:materialTypes.findIndex((type)=>{return type.id === materialMaster.materialTypeID;}),
            unit:materialUnitTypes.findIndex((unitType)=>{return unitType.id ===materialMaster.unitID; })
        };
        editMode = true;
        setMaterialMasterDialog(true);
    };

    const onMaterialMasterUpdate = (materialMaster, setpopuploading) => {
        // setLoading(true);
        // showError('');
        const response = api.post(`material-master/${materialMaster.id}`, {
            ...materialMaster
        });
        response.then(() => {
            let index = materialMasters.findIndex((srcMaterialMaster) => {
                return srcMaterialMaster.id === materialMaster.id;
            });
            setMaterialMasters([...materialMasters.slice(0, index),
                {...materialMaster},
                ...materialMasters.slice(index + 1)]);
            setMaterialMasterDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('Material Master not Updated');
                else if (err.message === 'duplicate key')
                setErrorMessage('Duplicate Entry');
                else
                setErrorMessage(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };

    //Get all MaterialMasters API Call
    const getAllMaterialMasters = () => {
        setMaterialMasterDialog(false);

        showError('');
        showMessage('');

        const response = api.get('material-master');

        response.then(res => {
           
               
            setMaterialMasters([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Materials Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    };

    //Get all Material Type API Call
    const getAllMaterialType = () => {
        
        showError('');
        showMessage('');

        const response = api.get(`material-types`);

        response.then(res => {
            setMaterialType([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Material Type');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    };

    
    const onLoad=()=>{
        setLoading(true);
        Promise.all([getAllMaterialMasters(),getAllMaterialUnit(), getAllMaterialType()]).finally(()=>{
            setLoading(false);
        });
        
    }
    useEffect(onLoad, []);

    
    let materialMasterNameColumn = {
        render: rowData => <Mobile data={rowData}
                                   onEdit={() => {
                                       onMaterialMasterEdit(rowData)
                                   }}
                                   onDelete={() => {
                                       onDelete(rowData)
                                   }}
        />
    };
    let actions = [];
    if (isDesktop) {
        materialMasterNameColumn = {title: 'Material Name', field: 'materialName', hidden: !isDesktop};
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Material Master',
                onClick: (event, rowData) => onMaterialMasterEdit(rowData)
            },
            rowData => ({
                  icon: () => (rowData.isDefault  !== 1 ? <Icon color='error'>delete</Icon> : null),
                   tooltip: 'Delete Material Master',
                    onClick: (event, rowData) => {
                        if (rowData.isDefault !== 1) {
                               onDelete(rowData);
                     }
                     }
                      })
        ]
    }

    // useEffect(getAllMaterialMasters, []);
    
     const handleOnFileLoad = (datas) => {
        setLoading(true)
        let mmDatas = []
        if(user.versionID===version.lite){
            datas.forEach((singleData, index) => {
                if (index !== 0) {
                    if(singleData.data[0]!==''){
                        let mmData = {}
                        mmData.materialCode = singleData.data[0]
                        mmData.materialName = singleData.data[1]
                        mmData.materialTypeID = 6
                        mmData.inventoryType = 2
                        mmData.unit = "trays"
                        mmData.unitID = 1
                        mmDatas.push(mmData)
                        // if(mmData.currentStock !== null || isNaN(mmData.currentStock)){
                        // }
                    }
                }
            })
        }
        else if(user.versionID===version.pro){
            datas.forEach((singleData, index) => {
                if (index !== 0) {
                    let mmData = {}
                    if(singleData.data[4] === null || isNaN(singleData.data[4])  ){
                        return;
                    }
                    mmData.materialCode = singleData.data[0]
                    mmData.materialName = singleData.data[1]
                    if(parseInt(singleData.data[2])===1){
                        showError('Material Type can not be 1 for pro users.')
                        return;
                    }
                    else{
                        mmData.materialTypeID = parseInt(singleData.data[2])
                    }
                    mmData.inventoryType = 2
                    mmData.openingStock = parseFloat(singleData.data[3])
                    mmData.currentStock = parseFloat(singleData.data[4])
                    mmData.openingValue = parseFloat(singleData.data[5])
                    mmData.storageLoss = parseFloat(singleData.data[6])
                    mmData.minimumStockValue = parseInt(singleData.data[7])
                    mmData.unit = singleData.data[8]
                    mmData.unitID = parseInt(singleData.data[9])
                    if(mmData.currentStock !== null || isNaN(mmData.currentStock)){
                    mmDatas.push(mmData)}
                }
            })
        }
        else{
            datas.forEach((singleData, index) => {

                if (index !== 0) {
                    let mmData = {}
                    if(singleData.data[5] === null || isNaN(singleData.data[5])  ){
                        return;
                    }
                    mmData.materialCode = singleData.data[0]
                    mmData.materialName = singleData.data[1]
                    mmData.materialTypeID = parseInt(singleData.data[2])
                    mmData.inventoryType = parseInt(singleData.data[3])
                    mmData.openingStock = parseFloat(singleData.data[4])
                    mmData.currentStock = parseFloat(singleData.data[5])
                    mmData.openingValue = parseFloat(singleData.data[6])
                    mmData.storageLoss = parseFloat(singleData.data[7])
                    mmData.minimumStockValue = parseInt(singleData.data[8])
                    mmData.unit = singleData.data[9]
                    mmData.unitID = parseInt(singleData.data[10])
                    if(mmData.currentStock !== null || isNaN(mmData.currentStock)){
                    mmDatas.push(mmData)}
                }
            })
        }   
        console.log(mmDatas)
        const response = api.post("bulk-material-master", mmDatas)

        response.then(res => {
            setMaterialMasters([...res, ...materialMasters])
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found'){
                    showError('Create Bulk MaterialMaster not added');
                }
                else if(err?.ErrorCode === 409){
                    showError("Material name should be unique")
                }
                else{
                    showError(err.message)
                }
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });

    }

    const handleOnError = (err) => {
        setErrorMessage(err)
    }


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Material Master
                </Typography>
            </Grid>
            <Grid
            style={{display:"flex"}}>
                <div>
                    
                <CSVReader
                        ref={buttonRef}
                        onFileLoad={handleOnFileLoad}
                        onError={handleOnError}
                        noProgressBar
                        // onRemoveFile={handleOnRemoveFile}
                    >
                        {({file}) => (
                            <div>
                                <Button onClick={(e) => {
                                    if (buttonRef.current) {
                                        buttonRef.current.open(e)
                                    }
                                    }}
                                    variant="contained" color="primary">Bulk Import
                                </Button>
                            </div>
                        )}
                    </CSVReader>
                    {user.versionID===version.lite ?
                        <a className={classes.download} href={MaterialMasterLiteCSV} color="primary" download>Download Template</a>
                        :
                    user.versionID===version.pro ?
                        <a className={classes.download} href={MaterialMasterProCSV} color="primary" download>Download Template</a>
                        :
                        <a className={classes.download} href={MaterialMasterCSV} color="primary" download>Download Template</a>
                    }
                </div>
                <Button className={classes.spacing} variant="contained" color="primary" onClick={onNewMaterialMaster}>Add Material</Button>
            </Grid>
        </Grid>
        {user.versionID===version.proPlus ?
            <MaterialTable
                isLoading={loading}
                columns={[
                    {title: 'id', field: 'id', hidden: true},
                    {title: 'Material Code', field: 'materialCode', hidden: !isDesktop},
                    materialMasterNameColumn,
                    {title: 'Material Type', field: 'materialTypeName', hidden: !isDesktop},
                    {title: 'Inventory Type', field: 'inventoryType', hidden: !isDesktop,
                        render:(rowData) =>{
                        return <span>{rowData.inventoryType  === 1 ? 'Feed Mill' : 'Farm'}</span>
                    }},
                    {title: 'Opening Stock', field: 'openingStock', hidden: !isDesktop},
                    {title: 'Current Stock', field: 'currentStock', hidden: !isDesktop},
                    {title: 'Opening Value', field: 'openingValue', hidden: !isDesktop},
                    {title: 'Storage Loss (%)', field: 'storageLoss', hidden: !isDesktop},
                    {title: 'Minimum Stock Value', field: 'minimumStockValue', hidden: !isDesktop},
                    {title: 'Unit', field: 'unit', hidden: !isDesktop}
                ]}
                title='Material Master Table'
                data={materialMasters}
                options={{
                    actionsColumnIndex: -1,
                    search: true,
                    showTitle: true,
                    
                    toolbar: true
                }}
                actions={actions}
            />
            :
            <MaterialTable
                isLoading={loading}
                columns={[
                    {title: 'id', field: 'id', hidden: true},
                    {title: 'Material Code', field: 'materialCode', hidden: !isDesktop},
                    materialMasterNameColumn,
                    {title: 'Material Type', field: 'materialTypeName', hidden: !isDesktop},
                    {title: 'Unit', field: 'unit', hidden: !isDesktop}
                ]}
                title='Material Master Table'
                data={materialMasters}
                options={{
                    actionsColumnIndex: -1,
                    search: true,
                    showTitle: true,
                    
                    toolbar: true
                }}
                actions={actions}
            />
        }                              
        
        <MaterialMaster deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showMaterialMasterDialog} handleClose={handleMaterialMasterClose}
                        onSave={onMaterialMasterSave} onEdit={onMaterialMasterUpdate} materialUnitTypes={materialUnitTypes}
                        editMode={editMode} data={currentMaterialMaster} materialTypes={materialTypes}/>
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onMaterialMasterDeleteConfirm}
                       title='Delete Material Master ?'
                       subText='This action will delete the Material Master. Please confirm.'/>
    </div>
};

export default MaterialMastersView;