import {useEffect, useState} from 'react';
import validate from "validate.js";

const parseValue = function (target) {
    if (target.value !== '') {
        switch (target.type) {
            case 'number':
                return parseFloat(target.value);
            case 'integer':
                return parseInt(target.value, 10);
            default:
                return target.value;
        }
    }
    return target.value;
};

const useFromValidator = (schema) => {
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    useEffect(() => {

        const errors = validate(formState.values, schema);
        setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
        }));
    }, [formState.values, schema]);


    const handleChange = (event, callback) => {
        if (typeof event.persist === "function") {
            event.persist();
        }

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : parseValue(event.target)
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
        //Ideally this should be called after the state is set, but right now , there is no easy way to do this.
        if (callback && typeof callback === "function") {
            callback(event);
        }
    };

    const updateData = (data, editMode) => {
        if (data && editMode) {
            setFormState(() => ({
                isValid: true,
                values: {...data},
                touched: {},
                errors: {}
            }));
        } else {
            setFormState(() => ({
                isValid: false,
                values: {},
                touched: {},
                errors: {}
            }));
        }
    };

    const hasError = field => !!(formState.touched[field] && formState.errors[field]);

    return [formState, hasError, handleChange, updateData, setFormState];
};
export default useFromValidator;