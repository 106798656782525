import palette from '../palettes/default';

export default {
    root: {
        color: palette.icon,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03)'
        }
    }
};
