import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/styles/makeStyles";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import api from "../../common/api";
import {ConfirmDialog, MaterialTable} from "../../components";
import moment from "moment";
import Icon from "@material-ui/core/Icon";
import Schedule from './components/schedule';
import Mobile from "./components/mobile";
import Link from "@material-ui/core/Link";
import HomeIcon from '@material-ui/icons/Home';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import PetsIcon from '@material-ui/icons/Pets';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { CSVReader } from 'react-papaparse';
import VaccinationScheduleCSV from './templateFile/vaccination_schedule.csv';

const buttonRef = React.createRef()

const useStyles = makeStyles(theme => ({
    root:  props=>({
        margin: props.isDesktop?theme.spacing(4):theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    breadcrumbs:{
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    spacing: {
        marginLeft: theme.spacing(2),
    },
    download: {
        marginLeft: theme.spacing(1)
    }
}));

let currentData = undefined;
let editMode = false;

const VacScheduleView = ({showError, showMessage, match,history}) => {
    const breedID = match.params['breedID'];
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    const [schedules, setSchedules] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showScheduleDialog, setShowScheduleDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    const handleScheduleClose = () => {
        currentData = undefined;
        setShowScheduleDialog(false);
    };

    const onScheduleSave = (schedule) => {
        setLoading(true);
        showError('');
        showMessage('');
        const response = api.post(`breed/${breedID}/vaccination-schedule`, {
            ...schedule,
            day: parseInt(schedule.day, 10)
        });

        response.then(res => {
            setSchedules([...schedules, res]);
            setShowScheduleDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Schedule not added');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onScheduleUpdate = (schedule) => {
        setLoading(true);
        showError('');
        const response = api.post(`breed/${breedID}/vaccination-schedule/${schedule.id}`, {
            ...schedule,
            day: parseInt(schedule.day, 10)
        });
        response.then((res) => {
            let index = schedules.findIndex((srcData) => {
                return srcData.id === schedule.id;
            });
            console.debug("schedule ",schedule,res);
            setSchedules([...schedules.slice(0, index),
                {...res},
                ...schedules.slice(index + 1)]);
            setShowScheduleDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Schedule not updated');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    function onNewSchedule(event) {
        editMode = false;
        event.preventDefault();
        setShowScheduleDialog(true);
    };

    const onScheduleEdit = (schedule) => {
        currentData = schedule;
        editMode = true;
        setShowScheduleDialog(true);
    };

    //Get all Schedules API Call
    const getAll = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get(`breed/${breedID}/vaccination-schedule`);

        response.then(res => {
            setSchedules([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Vaccination schedule empty');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(getAll, []);


    const onDelete = (data) => {
        currentData = data;
        setShowDeleteConfirm(true);
    };

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    function onDeleteConfirm (){
        if (currentData && currentData.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`breed/${breedID}/vaccination-schedule/${currentData.id}`);

            response.then(res => {
                if (res) {
                    let index = schedules.findIndex((src) => {
                        return src.id === currentData.id;
                    });
                    let newData = [...schedules];
                    newData.splice(index, 1);
                    setSchedules(newData);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Schedule not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentData = undefined;
            });

        }
    }


    let vacDayColumnDefaults = {title: 'Day', field: 'day',defaultSort:'asc'};
    let vacDayColumn = {...vacDayColumnDefaults,
        render: rowData => <Mobile data={rowData}
                                   onEdit={()=>{onScheduleEdit(rowData)}}
                                   onDelete={()=>{onDelete(rowData)}}
        />};
    let actions = [];
    if (isDesktop) {
        vacDayColumn = vacDayColumnDefaults;
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Breed',
                onClick: (event, rowData) => {
                    onScheduleEdit(rowData);
                }
            },
           {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Breed',
                onClick: (event, rowData) => {
                    onDelete(rowData);
                }
            }
        ]
    }

    const handleOnFileLoad = (datas) => {
        setLoading(true)
        let vcDatas = []
        for(let i=0;i<=datas.length-1;i++){
            if(datas[i].data[0] !== "Day" && datas[i].data[1] !== "Vaccine name"){
                if( datas[i].data[0] !== ""){
                    let vcData = {}
                    vcData.day = parseInt(datas[i].data[0])    
                    vcData.name = datas[i].data[1]
                    vcDatas.push(vcData)
                }
            }
        }
        // The below loop structure was producing error in importing
        // datas.forEach((singleData, index) => {
        //     console.log(singleData)
        //     if (index > 0) {
        //         let vcData = {}
        //         vcData.day = parseInt(singleData.data[0])    
        //         vcData.name = singleData.data[1]

        //         vcDatas.push(vcData)
        //     }
        // })

        console.log("vcDatas", vcDatas);

        const response = api.post(`/breed/${breedID}/bulk-vaccination-schedule`, vcDatas)
        response.then(res => {
            setSchedules([...(res.reverse()), ...schedules])
          }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                  showError('Create Bulk VaccinationSchedule not added');
                else
                showError(err.message)
            } else {
              showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleOnError = () => {

    }

    return <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
            <Link color="inherit"  href="#" onClick={()=>history.push('/')} className={classes.link}>
                <HomeIcon className={classes.icon} />
                Home
            </Link>
            <Link color="inherit"  href="#" onClick={()=>history.push('/breed')} className={classes.link}>
                <PetsIcon className={classes.icon} />
                Breeds
            </Link>
            <Typography color="textPrimary" className={classes.link}>
                <LocalHospitalIcon className={classes.icon} />
                Vaccination Schedule
            </Typography>
        </Breadcrumbs>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Vaccination Schedule
                </Typography>
            </Grid>
            <Grid
            style={{display:"flex"}}>
            <div>
                <CSVReader
                        ref={buttonRef}
                        onFileLoad={handleOnFileLoad}
                        onError={handleOnError}
                        noProgressBar
                    >
                        {({file}) => (
                            <div>
                                <Button onClick={(e) => {
                                    if (buttonRef.current) {
                                        buttonRef.current.open(e)
                                    }
                                    }}
                                    variant="contained" color="primary">Bulk Import
                                </Button>
                            </div>
                        )}
                    </CSVReader>
                    <a className={classes.download} href={VaccinationScheduleCSV} color="primary" download>Download Template</a>
            </div>
                <Button className={classes.spacing} variant="contained" color="primary" onClick={onNewSchedule}>Add Schedule</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'id', hidden: true},
                vacDayColumn,
                {title: 'Vaccine Names', field: 'name', hidden: !isDesktop},
                {title: 'Modified By', field: 'modifiedBy', hidden: !isDesktop, editable: 'never'},
                {title: 'Modified', field: 'modifiedTime', type: 'datetime', editable: 'never', hidden: !isDesktop,
                    render: rowData => {
                        return <span>{moment(rowData.modifiedTime).format("DD-MM-YYYY HH:mm")}</span>
                    }
                },
            ]}
            data={schedules}
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true
            }}
            actions={actions}
            title='Vaccination Schedules'
        />
        <Schedule deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showScheduleDialog} handleClose={handleScheduleClose} onSave={onScheduleSave}
                  onEdit={onScheduleUpdate}
                  editMode={editMode} data={currentData}/>
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onDeleteConfirm}
                       title='Delete Vaccination Schedule ?'
                       subText='This action will delete the vaccination schedule.'/>
    </div>;
};

export default VacScheduleView;