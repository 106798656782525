import React, {  useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import {  MaterialTable } from "../../components";
import api from "../../common/api";
import Chip from "@material-ui/core/Chip";
import red from "@material-ui/core/colors/red";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1),
  }),
  topBar: {
    marginBottom: theme.spacing(2),
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  progressBar: {
    margin: theme.spacing(2),
  },
  breadcrumbs: {
    marginBottom: theme.spacing(2),
  },
  link: {
    display: "flex",
  },
  chip: {
    backgroundColor: red[500],
    color: "white",
    fontWeight: 500,
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  redChip: {
    justifyContent: "center",
    fontSize: "0.85rem",
    letterSpacing: "0.5px",
    padding: "4px 8px",
    color: "#f44336",
    backgroundColor: "rgba(244, 67, 54, 0.08)",
  },
  errorMargin: {
    marginBottom: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AttendanceView = ({ showError, showMessage, match }) => {
  const farmID = match.params["farmID"];
  const [AttendanceDate, setAttendanceDate] = useState(null);

  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const classes = useStyles({ isDesktop });
  const [labours, setLabours] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");


  var columns = [
    {
      title: "Name",
      field: "name",
      headerStyle: {
        position: "sticky",
        left: "0",
        zIndex: "999",
        backgroundColor: "rgba(36, 42, 51, 1)",
      },
      cellStyle: {
        position: "sticky",
        left: "0",
        zIndex: "1",
        backgroundColor: "#FFFFFF",
      },
      required: true,
      render: function (props) {
        return (
          <InputLabel id="type-select-outlined-label">{props.name}</InputLabel>
        );
      },
    },
    {
      title: "Attendance",
      field: "labour_attendance",
      type: "numeric",
      required: false,
      render: function (props) {
        return (
          <Checkbox
            checked={props.labour_attendance === 1 ? true : false}
            onChange={(event) => {
              props.labour_attendance = event.target.checked ? 1 : 0;
              setLabours([...labours]);
            }}
          />
        );
      },
    },

    {
      title: "OT Hours",
      field: "othours",
      type: "numeric",
      required: false,
      render: function (props) {
        return (
          <TextField
            name="othours"
            type="number"
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            value={props.othours === -1 ? 0 : props.othours}
            onChange={(event) => {
              props.othours = parseInt(event.target.value);
              setLabours([...labours]);
            }}
          />
        );
      },
    },
    {
      title: "OT Pay",
      field: "otpay",
      type: "numeric",
      required: false,
      render: function (props) {
        return (
          <TextField
            name="otpay"
            type="number"
            variant="outlined"
            style={{
              backgroundColor: "#ffffff",
            }}
            value={props.otpay === -1 ? 0 : props.otpay}
            onChange={(event) => {
              props.otpay = parseInt(event.target.value);
              setLabours([...labours]);
            }}
          />
        );
      },
    },
  ];


  const removeErrorMessage = (value) => {
    setErrorMessage("");
  };

  const onLabourAttendanceSave = () => {
    setLoading(true);
    showMessage("");
    let payload = {
      AttendanceDate: AttendanceDate,
      labours: [...labours],
    };
    const response = api.post(`farm/${farmID}/labourAttendance`, {
      ...payload,
    });

    response
      .then((res) => {
        let laboursArray = res?.labours;
        setLabours([...laboursArray]);
      })
      .catch((err) => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            setErrorMessage("Labour attendance not added");
          else if (err.message === "Duplicate Key")
            setErrorMessage("Duplicate Name");
          else setErrorMessage(err.message);
        } else {
          setErrorMessage("Unknown Error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handleAttendanceDateChange(date) {
    setAttendanceDate(date);
    let payload = {
      AttendanceDate: date,
      farmId: parseInt(farmID, 10),
    };
    const response = api.post(`farm/${farmID}/getActivelabourByDate`, {
      ...payload,
    });
    response
      .then((res) => {
        setLabours([...res]);
      })
      .catch((err) => {
        setLabours([]);
        console.log(err);
      })
      .finally(() => {});
  }

  return (
    <div className={classes.root}>
      <div id="error" className={classes.errorMargin}>
        {errorMessage ? (
          <Chip
            onDelete={removeErrorMessage}
            color="primary"
            label={errorMessage}
          />
        ) : (
          ""
        )}
      </div>
      <Grid
        container
        justify="space-between"
        alignItems="flex-end"
        className={classes.topBar}
      >
        <Grid>
          <Typography display="block" variant="h3" gutterBottom>
            Labour Attendance
          </Typography>
        </Grid>
      </Grid>
      <FormControl className={classes.formControl}>
        <MuiPickersUtilsProvider
          class={classes.datePicker}
          utils={DateFnsUtils}
        >
          <DatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="Attendance Date"
            format="dd/MM/yyyy"
            animateYearScrolling
            value={AttendanceDate || null}
            name="AttendanceDate"
            style={{ backgroundColor: "#ffffff" }}
            onChange={handleAttendanceDateChange}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      <MaterialTable
        isLoading={loading}
        columns={columns}
        data={labours}
        options={{
          actionsColumnIndex: -1,
          search: true,
          showTitle: true,

          toolbar: true,
        }}
        title="Labour Attendance Table"
      />
      <DialogActions>
        <Button
          className={classes.signInButton}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          // disabled={Disabled}
          onClick={onLabourAttendanceSave}
        >
          Save
        </Button>
      </DialogActions>
    </div>
  );
};

export default AttendanceView;
