import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {DialogTitle} from "../../../components";
import makeStyles from '@material-ui/styles/makeStyles';
import useFromValidator from '../../../common/formValidator';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

let schema = {
    name: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    joiningDate: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    leavingDate: {
        presence: false,
    },
    payperday: {
        presence: { allowEmpty: false, message: 'is required' },
        type: 'integer'
    },
    advance:{
        type: 'integer'
    },
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    addIconCss:{
        marginLeft:theme.spacing(2),
    }
}));

const Labour = ({ deleteErrorMessage, errorMessage, onEdit, data,  onSave, handleClose, showDialog, editMode = false }) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);

    const resetBeforeEdit = () => {

        if (showDialog) {
            updateData(data, editMode);
        }
        
    };

    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {

        let tempData = {
            ...formState.values,
        };
    
        if (editMode)
            onEdit(tempData);
        else
            onSave(tempData);
    };




    const onClose = () =>{
        handleClose()
    }

    return <Dialog disableBackdropClick open={showDialog} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" >{editMode ? 'Update' : 'New'} Labour
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          <div id="error">
          {/* {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''} */}
         
          </div>
            <TextField
                className={classes.textField}
                error={hasError('name')}
                fullWidth
                helperText={
                    hasError('name') ? formState.errors.name[0] : null
                }
                label="Labour Name"
                name="name"
                onChange={handleChange}
                type="text"
                value={formState.values.name || ''}
                variant="outlined"
            />
           
           
           
           
            <TextField
                className={classes.textField}
                error={hasError('payperday')}
                fullWidth
                helperText={
                    hasError('payperday') ? formState.errors.payperday[0] : null
                }
                label="Pay / Day"
                name="payperday"
                onChange={handleChange}
                type="number"
                value={formState.values.payperday || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('advance')}
                fullWidth
                helperText={
                    hasError('advance') ? formState.errors.age[0] : null
                }
                label="Advance"
                name="advance"
                onChange={ handleChange}
                type="number"
                value={formState.values.advance ||""}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
           
           <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    fullWidth
                    className={classes.textField}
                    variant="inline"
                    inputVariant="outlined"
                    label="Joining Date"
                    format="dd/MM/yyyy"
                    value={formState.values.joiningDate || null}
                    onChange={data => {
                        handleChange({ target: { name: 'joiningDate', value: data } })
                    }}
                    animateYearScrolling
                    name="joiningDate"
                />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    fullWidth
                    className={classes.textField}
                    variant="inline"
                    inputVariant="outlined"
                    label="Leaving Date"
                    format="dd/MM/yyyy"
                    value={formState.values?.leavingDate  ? formState.values.leavingDate : null}
                    onChange={data => {
                        handleChange({ target: { name: 'leavingDate', value: data } })
                    }}
                    animateYearScrolling
                    name="leavingDate"
                />
            </MuiPickersUtilsProvider>
             <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
       
    </Dialog>

};

export default Labour;