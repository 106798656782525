import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TaxProfile from "./components/taxProfiles";
import Icon from '@material-ui/core/Icon';
import { updateLocalStorageTaxData } from '../../common/functions';


const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentTaxProfile = undefined;
let editMode = false;

const SetTaxProfiles = ({ showError, showMessage, history }) => {

    const [taxProfiles, setTaxProfiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showTaxProfilesDialog, setShowTaxProfilesDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [taxTypes, setTaxTypes] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [salePurchaseTaxProfileHashMap, setSalePurchaseTaxProfileHashMap] = useState(null);

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    function onNewTaxProfiles(event) {
        editMode = false;
        setErrorMessage("");
        event.preventDefault();
        setShowTaxProfilesDialog(true);
    }

    function handleTaxClose() {
        setErrorMessage(null);
        currentTaxProfile = undefined;
        setShowTaxProfilesDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const onTaxDeleteConfirm = () => {
        if (currentTaxProfile && currentTaxProfile.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`tax-profiles/delete/${currentTaxProfile.id}`);

            response.then(res => {
                if (res) {
                    let index = taxProfiles.findIndex((srcTax) => {
                        return srcTax.id === currentTaxProfile.id;
                    });
                    let newtaxProfiles = [...taxProfiles];
                    newtaxProfiles.splice(index, 1);
                    setTaxProfiles(newtaxProfiles);
                    updateLocalStorageTaxData(newtaxProfiles);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('TaxProfile not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentTaxProfile = undefined;
            });

        }
    };

    const removeErrorMessage = value => {
        setErrorMessage('');
    }

    const onTaxProfileDelete = (TaxProfile) => {
        currentTaxProfile = TaxProfile;
        setShowDeleteConfirm(true);
    };

    const onTaxProfilesSave = (TaxProfile, setpopuploading) => {
        console.log(TaxProfile, "from liner 111");
        showMessage('');
        const obj = {
            taxPercentage: parseFloat(TaxProfile.taxPercentage),
            taxTypeId: TaxProfile.taxTypeId,
            taxProfileName: TaxProfile.taxProfileName
        }
        const response = api.post('tax-profiles/create', obj);

        response.then(res => {
            let buffTaxType = taxTypes.find((el) =>
                el.id === res.taxTypeId
            )
            res.taxType = buffTaxType.taxType;
            let utdData = [...taxProfiles, res]
            setTaxProfiles([...taxProfiles, res]);
            updateLocalStorageTaxData(utdData);
            setShowTaxProfilesDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('TaxProfile not added');
                else if (err.message === 'duplicate key') {
                    setErrorMessage("Duplicate entry. Tax Profile already exists")
                }
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onTaxProfileEdit = (TaxProfile) => {
        setErrorMessage("");
        currentTaxProfile = {
            ...TaxProfile,
            taxTypeIndex: taxTypes.findIndex((tax) => { return tax.id === TaxProfile.taxTypeId; }),
        };
        editMode = true;
        setShowTaxProfilesDialog(true);

    };

    const onTaxUpdate = (TaxProfile, setPopupLoading) => {
        const obj = {
            id: TaxProfile.id,
            taxType: TaxProfile.taxType,
            taxPercentage: parseFloat(TaxProfile.taxPercentage),
            taxTypeId: TaxProfile.taxTypeId,
            taxProfileName: TaxProfile.taxProfileName
        }
        const response = api.post(`tax-profiles/update/${TaxProfile.id}`, obj);
        response.then(() => {
            let index = taxProfiles.findIndex((srcTax) => {
                return srcTax.id === TaxProfile.id;
            });
            setTaxProfiles([...taxProfiles.slice(0, index),
            { ...TaxProfile },
            ...taxProfiles.slice(index + 1)]);

            let utdData = [...taxProfiles.slice(0, index),
            { ...TaxProfile },
            ...taxProfiles.slice(index + 1)]
            updateLocalStorageTaxData(utdData)
            setShowTaxProfilesDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('TaxProfile not Updated');
                else if (err.message === 'duplicate key') {
                    setErrorMessage("Duplicate entry. Tax Profile already exists")
                }
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setPopupLoading(false)
            setLoading(false);
        })
    };

    //Get all taxProfiles API Call
    const getAlltaxProfiles = () => {
        setShowTaxProfilesDialog(false);
        showError('');
        showMessage('');

        const response = api.get('tax-profiles');

        response.then(res => {
            setTaxProfiles([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Tax Profile Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

    // //Get All TaxProfile Profile Types
    const getAlltaxTypes = () => {


        showError('');
        showMessage('');

        const response = api.get('tax-profiles/type');
        response.then(res => {
            setTaxTypes([...res]);

        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No AccountType Found Catch Error');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }


    function getSalePurchaseTaxProfileIDs() {
        return api.get(`sale-purchase-tax-profiles`)
        .then((res)=>{
            let map = new Map();
            for (const el of res) {
                if (el !== -1) {
                    map.set(el,"salePurchaseTaxProfileID")
                }
            }
            setSalePurchaseTaxProfileHashMap(map)
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    const onload = () => {
        setLoading(true);
        Promise.all([getAlltaxProfiles(), getAlltaxTypes(),getSalePurchaseTaxProfileIDs()]).finally(() => {
            setLoading(false);
        });
    }
    useEffect(onload, []);

    let actions = [];
    if (isDesktop) {
        actions = [
            rowData => ({
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit TaxProfile',
                onClick: (event, rowData) => onTaxProfileEdit(rowData),
                hidden: salePurchaseTaxProfileHashMap !== undefined && 
                salePurchaseTaxProfileHashMap !== null && typeof salePurchaseTaxProfileHashMap.get === "function" 
                && salePurchaseTaxProfileHashMap.get(rowData.id) === undefined ? false : true
            }),
            rowData => ({
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete TaxProfile',
                onClick: (event, rowData) => onTaxProfileDelete(rowData),
                hidden: salePurchaseTaxProfileHashMap !== undefined && 
                salePurchaseTaxProfileHashMap !== null && typeof salePurchaseTaxProfileHashMap.get === "function" 
                && salePurchaseTaxProfileHashMap.get(rowData.id) === undefined ? false : true
            })
        ]
    }


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Tax Profiles
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewTaxProfiles}>Add Tax Profile</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'id', field: 'id', hidden: true },
                { title: 'Tax Profile Name', field: 'taxProfileName', hidden: !isDesktop, editable: 'never' },
                { title: 'Tax Percentage', field: 'taxPercentage', hidden: !isDesktop, editable: 'never' },
                { title: 'Tax Type', field: 'taxType', hidden: !isDesktop, editable: 'never' },
            ]}
            data={taxProfiles}
            title='TaxProfile Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,

                toolbar: true
            }}
            actions={actions}
        />
        <TaxProfile errorMessage={errorMessage} showDialog={showTaxProfilesDialog} handleClose={handleTaxClose} onSave={onTaxProfilesSave} onEdit={onTaxUpdate}
            editMode={editMode} data={currentTaxProfile} taxTypes={taxTypes} deleteErrorMessage={removeErrorMessage} />
        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onTaxDeleteConfirm}
            title='Delete Tax Profile ?'
            subText='This action will delete the Tax Profile Details. Please confirm.' />
    </div>
}


export default SetTaxProfiles;