import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {DialogTitle} from "../../../components";
import makeStyles from "@material-ui/styles/makeStyles";
import useFromValidator from "../../../common/formValidator";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';

const schema = {
  week: {
    presence: { allowEmpty: false, message: "is required" },
    type: "integer"
  },
  weight: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 1
    }
  },
  stdProduction: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  stdMortalityRate: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  stdFeedIntake: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0
    }
  },
  hhew: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0
    }
  },
  hhet: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0
    }
  },
};

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.text.default,
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const Schedule = ({
  deleteErrorMessage, errorMessage,
  onEdit,
  data,
  onSave,
  handleClose,
  showDialog,
  editMode = false
}) => {
  const classes = useStyles();
  const [formState, hasError, handleChange, updateData] = useFromValidator(
    schema
  );

  const resetBeforeEdit = () => {
    if (showDialog) {
      updateData(data, editMode);
    }
  };

  useEffect(resetBeforeEdit, [showDialog]);

  const onSubmit = () => {
    if (editMode) onEdit(formState.values);
    else onSave(formState.values);
  };

  return (
    <Dialog
      disableBackdropClick
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog- title"   >
        {editMode ? "Update" : "New"} Breed Standard
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
        <DialogContent>
        <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
        <TextField
          className={classes.textField}
          error={hasError("week")}
          fullWidth
          helperText={hasError("week") ? formState.errors.week[0] : null}
          label="Week"
          name="week"
          onChange={handleChange}
          type="number"
          value={
            formState.values.week !== undefined ? formState.values.week : ""
          }
          variant="outlined"
          style={{backgroundColor:"#ffffff"}}
        />
        <TextField
          className={classes.textField}
          error={hasError("weight")}
          fullWidth
          helperText={hasError("weight") ? formState.errors.weight[0] : null}
          label="Weight (gms)"
          name="weight"
          onChange={handleChange}
          type="number"
          value={
            formState.values.weight !== undefined ? formState.values.weight : ""
          }
          variant="outlined"
          style={{backgroundColor:"#ffffff"}}
        />
        <TextField
          className={classes.textField}
          error={hasError("stdProduction")}
          fullWidth
          helperText={
            hasError("stdProduction") ? formState.errors.stdProduction[0] : null
          }
          label="Standard Production (%)"
          name="stdProduction"
          onChange={handleChange}
          type="number"
          value={
            formState.values.stdProduction !== undefined
              ? formState.values.stdProduction
              : ""
          }
          variant="outlined"
          style={{backgroundColor:"#ffffff"}}
        />
        <TextField
          className={classes.textField}
          error={hasError("stdMortalityRate")}
          fullWidth
          helperText={
            hasError("stdMortalityRate")
              ? formState.errors.stdMortalityRate[0]
              : null
          }
          label="Standard Mortality Rate (%)"
          name="stdMortalityRate"
          onChange={handleChange}
          type="number"
          style={{backgroundColor:"#ffffff"}}
          value={
            formState.values.stdMortalityRate !== undefined
              ? formState.values.stdMortalityRate
              : ""
          }
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          error={hasError("stdFeedIntake")}
          fullWidth
          helperText={
            hasError("stdFeedIntake") ? formState.errors.stdFeedIntake[0] : null
          }
          label="Standard Feed Intake (in gms)"
          name="stdFeedIntake"
          onChange={handleChange}
          type="number"
          style={{backgroundColor:"#ffffff"}}
          value={
            formState.values.stdFeedIntake !== undefined
              ? formState.values.stdFeedIntake
              : ""
          }
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          error={hasError("hhew")}
          fullWidth
          helperText={
            hasError("hhew") ? formState.errors.hhew[0] : null
          }
          label="HHEW"
          name="hhew"
          onChange={handleChange}
          type="number"
          style={{backgroundColor:"#ffffff"}}
          value={
            formState.values.hhew !== undefined
              ? formState.values.hhew
              : ""
          }
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          error={hasError("hhet")}
          fullWidth
          helperText={
            hasError("hhet") ? formState.errors.hhet[0] : null
          }
          label="HHET"
          name="hhet"
          onChange={handleChange}
          type="number"
          style={{backgroundColor:"#ffffff"}}
          value={
            formState.values.hhet !== undefined
              ? formState.values.hhet
              : ""
          }
          variant="outlined"
        />
        <DialogActions>
          <Button
            className={classes.signInButton}
            color="primary"
            disabled={!formState.isValid}
            size="large"
            type="submit"
            variant="contained"
            onClick={onSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default Schedule;
