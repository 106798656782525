import React, { useEffect, useState,useRef } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from '@material-ui/core/Button';
import { ConfirmDialog, MaterialTable } from "../../components";
import api from "../../common/api";
import Icon from "@material-ui/core/Icon";
import NewDailyEntry from './components/dailyEntry';
import NewBulkDailyEntry from './components/bulkDailyEntry';
import Mobile from "./components/mobile";
import moment from "moment";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { useGlobalState } from '../../state';
import {version} from '../../common/enums';
import { isToday } from '../../common/date';
import { standardPageSizes } from '../../common/enums';



const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1),
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    red: {
        color: 'red'
    },
    green: {
        color: 'green'
    },
    gridMargin: {
        margin: theme.spacing(1.5)
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        minWidth: 160,
    },
    searchButton: {
        color: 'white',
        width: theme.spacing(21.25),
        backgroundColor: '#EF5350',
        '&:hover': {
            backgroundColor: '#E53935',
        },
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom: theme.spacing(4)
    },
    success: {
        color: 'white',
        backgroundColor: '#4CAF50',
        '&:hover': {
            backgroundColor: '#388E3C',
        },
        width: theme.spacing(13.75),
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom: theme.spacing(4)
    },
    addButtonContainer: props => ({
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        flexDirection: props.isSmallScreen ? 'column' : 'row',
    }),
    buttonMarginRight: props => ({
        marginRight: props.isSmallScreen ? theme.spacing(0) : theme.spacing(1)
    }),
    marginTopCustom: props => ({
        marginTop: props.isSmallScreen ? theme.spacing(1) : theme.spacing(0)
    })
}));

let currentData = undefined;
let editMode = false;
const DailyEntries = ({ showError, showMessage, match }) => {
    const farmID = match.params['farmID'];
    const [{user}] = useGlobalState();
    const [sheds, setSheds] = useState([]);
    const [materialLists, setMaterialList] = useState([]);
    const [minClosingFeed, setMinClosingFeed] = useState({});

    const [loading, setLoading] = useState(false);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop, isSmallScreen });
    const [dailyEntries, setDailyEntries] = useState([]);
    const [showDailyEntryDialog, setShowDailyEntryDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [filterSelectedStartDate, setFilterSelectedStartDate] = useState(null);
    const [filterSelectedEndDate, setFilterSelectedEndDate] = useState(null);
    const [ShowBulkAddDialog, setShowBulkAddDialog] = useState(false);
    const [availableMedicineStock,setAvailableMedcineStock] = useState([])
    const [ pageSize, setPageSize] = useState(5)
    const rowsPerPageArray = useRef();

    const handleDailyEntryClose = () => {
        setMinClosingFeed({})
        setErrorMessage('');
        currentData = undefined;
        setShowDailyEntryDialog(false);
    };

    function handleBulkDailyEntryClose() {
        setMinClosingFeed({})
        setShowBulkAddDialog(false);
    }

    const getAvailableMedicineStock =(data,shedID,dailyEntryID)=>{
        showError('');
        showMessage('');
        const response = api.post(`/medicine-stock/data/${farmID}`, {"date":data,"shedID":shedID,"dailyEntryID":dailyEntryID});
        response.then(res => {
            setAvailableMedcineStock(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found'){
                    showError('No Medicine and Vaccination List');
                   setAvailableMedcineStock([])
                }
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
    
    }


    const onDailyEntryEdit = (dailyEntry) => {
        currentData = {
            ...dailyEntry
        };
        editMode = true;
        setShowDailyEntryDialog(true);
    };

    function onNewDailyEntry(event) {
        editMode = false;
        event.preventDefault();
        setErrorMessage('');
        setShowDailyEntryDialog(true);
    };

    function onNewBulkDailyEntry(event) {
        editMode = false;
        event.preventDefault();
        setErrorMessage('');
        setShowBulkAddDialog(true);
    }

    const onDelete = (data) => {
        currentData = data;
        setShowDeleteConfirm(true);
    };

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const  onDailyEntrySave = (dailyEntry) => {
        var mDailyEntryData = {...dailyEntry,
            eggBreakage: parseInt(dailyEntry.eggBreakage, 10),
            damagedEggs: parseInt(dailyEntry.damagedEggs, 10),
            eggClosingStock: parseInt(dailyEntry.eggClosingStock, 10),
            eggSales: parseInt(dailyEntry.eggSales, 10),
            mortality: parseInt(dailyEntry.mortality, 10),
            closingFeed: parseInt(dailyEntry.closingFeed, 10) 
        };


        //future date validation
        const selectedDate = new Date(dailyEntry.entryDate);
        const currentDate = new Date();

        if (selectedDate > currentDate) {
            setErrorMessage('Daily Entry cannot be in future');
            return; 
        }
        setLoading(true);

        if(dailyEntry.bodyWeight){
            mDailyEntryData['bodyWeight'] = +parseFloat(mDailyEntryData['bodyWeight']).toFixed(2);
        }
        if(user.versionID === version.lite && dailyEntry.feedReceived){
            mDailyEntryData['feedReceived'] = +parseFloat(mDailyEntryData['feedReceived']).toFixed(2);
        }      
        const response = api.post(`farm/${farmID}/daily-entry`,mDailyEntryData);
        response.then(res => {
            //console.debug("dailyEntry",dailyEntry,res)
            setErrorMessage('')
            setShowDailyEntryDialog(false);

            //check duplicate entry already present
            let index = dailyEntries.findIndex((srcData) => {
                return  srcData.id === res.oldId
            });
            if (index !== -1 ) {
                setDailyEntries([...dailyEntries.slice(0, index),
                { ...res },
                ...dailyEntries.slice(index + 1)]);

            } else {
                setDailyEntries([...dailyEntries, res]);
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Daily Entry not added');
                else if (err.message === 'Duplicate Key' || err.message === 'duplicate key')
                setErrorMessage('Data already exists for '+dailyEntry.shedName+' on '+moment(dailyEntry.entryDate).format("DD-MM-YYYY"));
                else
                setErrorMessage(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onDailyEntryUpdate = (dailyEntry) => {
        //future date validation
        const selectedDate = new Date(dailyEntry.entryDate);
        const currentDate = new Date();

        if (selectedDate > currentDate) {
            setErrorMessage('Daily Entry cannot be in future');
            return; 
        }
       
        setLoading(true);
        // showError('');
        var mDailyEntryData = {...dailyEntry,
            eggBreakage: parseInt(dailyEntry.eggBreakage, 10),
            damagedEggs: parseInt(dailyEntry.damagedEggs, 10),
            eggClosingStock: parseInt(dailyEntry.eggClosingStock, 10),
            eggSales: parseInt(dailyEntry.eggSales, 10),
            mortality: parseInt(dailyEntry.mortality, 10),
            closingFeed: parseInt(dailyEntry.closingFeed, 10),
            doubleEggs:parseInt(dailyEntry.doubleEggs,10),
            dirtyEggs:parseInt(dailyEntry.dirtyEggs,10)
        };
        if(dailyEntry.bodyWeight){
            mDailyEntryData['bodyWeight'] = +parseFloat(mDailyEntryData['bodyWeight']).toFixed(2);
        }
        if(user.versionID === version.lite && dailyEntry.feedReceived){
            mDailyEntryData['feedReceived'] = +parseFloat(mDailyEntryData['feedReceived']).toFixed(2);
        }
        const response = api.post(`farm/${farmID}/daily-entry/${dailyEntry.id}`,mDailyEntryData);
        response.then((res) => {
            let index = dailyEntries.findIndex((srcData) => {
                return srcData.id === res.id;
            });
       
            setDailyEntries([...dailyEntries.slice(0, index),
            { ...res },
            ...dailyEntries.slice(index + 1)]);
            setShowDailyEntryDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Daily Entry not updated');
                else if (err.message === 'Duplicate Key' || err.message === 'duplicate key')
                    setErrorMessage('Data already exists for '+dailyEntry.shedName+' on '+moment(dailyEntry.entryDate).format("DD-MM-YYYY"));
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    function onDeleteConfirm() {
        if (currentData && currentData.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`farm/${farmID}/daily-entry/${currentData.id}`);

            response.then(res => {
                if (res) {
                    let index = dailyEntries.findIndex((src) => {
                        return src.id === currentData.id;
                    });
                    let newData = [...dailyEntries];
                    newData.splice(index, 1);
                    setDailyEntries(newData);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Daily Entry not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentData = undefined;
            });

        }
    }

    //Get all Daily Entry API Call
    const getAll = () => {
        setLoading(true);
        showError('');
        showMessage('');
        const getDailyEntriesResponse = api.get(`farm/${farmID}/daily-entry`).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Daily Entry');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        const getMatListsResponse = api.post(`farm/${farmID}/daily-entry-material-list`).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Materials Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        const getShedsResponse = api.get(`farm/${farmID}/shed`).catch(err => {
            if (err.message) {
                console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
        Promise.all([getDailyEntriesResponse,getMatListsResponse,getShedsResponse]).then(results => {
            if(results[0])
                setDailyEntries([...results[0]]);
            if(results[1])
                setMaterialList([...results[1]]);
            if(results[2])
                setSheds([...results[2]]);
        }).finally(() => {
            setLoading(false);
        });
    }
    
    

    //Get all Daily Entry By Date API Call
    const getAllDailyEntryForDate = () => {

        if (filterSelectedEndDate === null && filterSelectedStartDate === null) {
            getAll()
        }
        if (filterSelectedEndDate !== null && filterSelectedStartDate !== null &&
            filterSelectedEndDate < filterSelectedStartDate) {
            showError('Start Date is Greater than or Equal to End Date');
            return
        }
        let payload = {
            startDate: filterSelectedStartDate,
            endDate: filterSelectedEndDate,
            farmId: parseInt(farmID, 10),
        }

        setLoading(true);
        showError('');
        showMessage('');

        const response = api.post(`farm/${farmID}/daily-entry-by-date`, { ...payload });

        response.then(res => {
            setDailyEntries([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Daily Entry Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }
    //Get Min Closing Feed API Call
    const getMinClosingFeed = (shedId, entryDate, shedName) => {
        setLoading(true);
        // showError('');
        showMessage('');

        const response = api.post(`farm/${farmID}/get-minimum-closing-feed`, {
            shedId: shedId,
            farmId: parseInt(farmID, 10),
            entryDate: entryDate,
            shedName : shedName
        });

        response.then(res => {
            console.debug(res)
            setMinClosingFeed(res);
        }).catch(err => {
            if (err.message) {
                console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
        console.debug(minClosingFeed)
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const handleFilterStartDateChange = date => {
        setFilterSelectedStartDate(date);
    };

    const handleFilterEndDateChange = date => {
        setFilterSelectedEndDate(date);
    };

    const clearDateRangeFilter = () => {
        setFilterSelectedStartDate(null)
        setFilterSelectedEndDate(null)
        getAll()

    }
    useEffect(getAll, []);
    
    
    useEffect(()=>{
        if (sheds.length > 0) {
            setPageSize(sheds.length);
            const isStandardPageSize = standardPageSizes.includes(sheds.length);
            rowsPerPageArray.current = isStandardPageSize ? standardPageSizes : [...standardPageSizes, sheds.length].sort((a, b) => a - b);
        }
    },[sheds])
    let dailyEntryColumnDefaults = { title: 'Shed Name', field: 'shedName' };
    let dailyEntryColumn = {
        ...dailyEntryColumnDefaults,
        render: rowData => <Mobile data={rowData}
            onEdit={() => { onDailyEntryEdit(rowData) }}
            onDelete={() => { onDelete(rowData) }}
        />
    };
    let actions = [];
    if (isDesktop) {
        dailyEntryColumn = dailyEntryColumnDefaults;
        actions = [
            rowData=>({
                icon: () => {
                    return <Icon color='primary'>edit</Icon> 
                },
                tooltip: (user.role !== "ADMIN" && !isToday(rowData.entryDate)) ? 'Please contact your Administrator to edit old data' : 'Edit Daily Entry',
                onClick: (event, rowData) => {
                    onDailyEntryEdit(rowData);
                },
                disabled:(user.role !== "ADMIN" && !isToday(rowData.entryDate))
            }),
            rowData=>({
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: (user.role !== "ADMIN" && !isToday(rowData.entryDate)) ? 'Please contact your Administrator to delete old data' :  'Delete Daily Entry',
                onClick: (event, rowData) => {
                    onDelete(rowData);
                },
                disabled:(user.role !== "ADMIN" && !isToday(rowData.entryDate))
            })
        ]
    }
    let dailyEntryAllColumns = [
                    {
                        title: 'Date', field: 'entryDate', type: 'datetime', defaultSort: 'desc',
                        editable: 'never',
                        hidden: !isDesktop,
                        render: rowData => {
                            return <span>{moment(rowData.entryDate).format("DD-MM-YYYY")}</span>
                        }
                    },
                    dailyEntryColumn,
                    { title: 'Egg type', field: 'materialTypeName', hidden: !isDesktop },
                    { title: 'Mortality', field: 'mortality', hidden: !isDesktop },
                    { title: 'Egg Sales', field: 'eggSales', hidden: !isDesktop },
                    { title: 'Egg Closing', field: 'eggClosingStock', hidden: !isDesktop },
                    { title: 'Broken eggs', field: 'eggBreakage', hidden: !isDesktop },
                    { title: 'Damaged Eggs', field: 'damagedEggs', hidden: !isDesktop },
        ];
    if(user.versionID === version.lite){
        dailyEntryAllColumns.push(
            {title: 'Feed received', field: 'feedReceived', hidden: !isDesktop},
            {title: 'Closing Feed', field: 'closingFeed', hidden: !isDesktop}
            );
    } else {
        dailyEntryAllColumns.push({
            title: 'Closing Feed', field: 'closingFeed', hidden: !isDesktop
        });
    }

    return (
        <div className={classes.root}>
            <Grid
                container
                justify='space-between'
                alignItems='flex-start'
                direction={isSmallScreen ? 'column' : 'row'}
                className={classes.topBar}
            >
                <Grid>
                    <Typography display="block" variant="h3" gutterBottom>
                        Daily Entries
                    </Typography>
                </Grid>

                <Grid className={classes.addButtonContainer}>
                        <Button className={classes.buttonMarginRight} style={{width:'auto'}} variant="contained" color="primary" onClick={onNewDailyEntry} >Add Daily Entry</Button>
                         <Button className={classes.marginTopCustom} style={{width:'auto'}} variant="contained" color="secondary" onClick={onNewBulkDailyEntry} >Add Bulk Daily Entry</Button>   
                </Grid>

            </Grid>

            <Grid container
                spacing={1}
                justify="flex-start"
                alignItems="center">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        className={classes.formControl}
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="From"
                        format="dd/MM/yyyy"
                        value={filterSelectedStartDate || null}
                        onChange={handleFilterStartDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    <KeyboardDatePicker
                        className={classes.formControl}
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="To"
                        format="dd/MM/yyyy"
                        value={filterSelectedEndDate || null}
                        onChange={handleFilterEndDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <Grid>
                    <Button onClick={(getAllDailyEntryForDate)} className={classes.success}>Search <SearchIcon /></Button>
                    <Button onClick={clearDateRangeFilter} className={classes.searchButton}>Clear Search <CloseIcon /></Button>
                </Grid>
            </Grid>
            <MaterialTable
                spacing={5}
                isLoading={loading}
                columns={dailyEntryAllColumns}
                data={dailyEntries}
                options={{
                    actionsColumnIndex: -1,
                    search: true,
                    showTitle: true,
                    
                    toolbar: true,
                    pageSize:pageSize,
                    pageSizeOptions: rowsPerPageArray.current ? rowsPerPageArray.current : standardPageSizes
                }}
                actions={actions}
                title='Daily Entries Table'
            />
            <NewDailyEntry errorMessage={errorMessage} showDialog={showDailyEntryDialog} handleClose={handleDailyEntryClose} onSave={onDailyEntrySave}
                onEdit={onDailyEntryUpdate} sheds={sheds} materialLists={materialLists} minClosingFeed={minClosingFeed}
                editMode={editMode} data={currentData} getMinClosingFeed={getMinClosingFeed} deleteErrorMessage={removeErrorMessage} getAvailbleMedicineList={getAvailableMedicineStock} medicineList={availableMedicineStock} />
            <ConfirmDialog showDialog={showDeleteConfirm}
                handleClose={handleDeleteConfirmClose}
                onConfirm={onDeleteConfirm}
                title='Delete Daily Entry ?'
                subText='This action will delete the Daily Entry.' />
            <NewBulkDailyEntry showDialog={ShowBulkAddDialog} handleClose={handleBulkDailyEntryClose} match={match} sheds={sheds} 
                materialLists={materialLists} farmID={farmID} getMinClosingFeed={getMinClosingFeed} minClosingFeed={minClosingFeed}
                dailyEntries={dailyEntries} getAllDailyEntryForDate={getAllDailyEntryForDate} showError={showError} deleteErrorMessage={removeErrorMessage}
            />
        </div>
    );
};

export default DailyEntries;