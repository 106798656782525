import React,{useState,useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card'; 
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { HorizontalBar} from 'react-chartjs-2'
import {Chart} from 'chart.js'
import { useGlobalState } from '../../../state';
import { version } from '../../../common/enums';
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
//import FormControl from '@material-ui/core/FormControl';

/*const labels = [['Production %','Std Production %'],['Feed Intake', 'Std Feed Intake'],
                ['Mortality', 'Std Mortality'],['Broken Eggs', 'Std Broken Eggs']]*/

const labels = ['Production %','Feed Intake','Mortality %', 'Broken Eggs %']

const useStyles = makeStyles(theme => ({
    paddingTopZero :{
        paddingTop:theme.spacing(0)
    },
    formControl: {
        marginLeft: theme.spacing(16.25),
        marginBottom: theme.spacing(1),
        minWidth: 160,
    },
    root: props=>({
        margin: props.isDesktop?theme.spacing(4):theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    cardContainer:{
        paddingBottom:theme.spacing(2),
        paddingLeft: theme.spacing(1.37),
        width:'min-content'
    },
    card: {
        minWidth: 275,
        '-webkit-box-shadow': '0px 2px 9px 0px rgba(0,0,0,0.75)',
        '-moz-box-shadow': '0px 2px 9px 0px rgba(0,0,0,0.75)',
        boxShadow: '0px 2px 9px 0px rgba(0,0,0,0.75)',
    },
    typo: {
        paddingLeft: theme.spacing(2),
        paddingBottom:theme.spacing(2),
    },
    media: {
        height: 140,
        backgroundSize: 'contain'
    },
    prodData : {
        color:"#4999db",
        fontSize:'13px',
        marginLeft:'1px'
    }
}));

const ShedDetails = ({row, tabValue}) => {
    const classes = useStyles();
    // eslint-disable-next-line
    const [tab, setTab] = useState("Daily");
    const [{user}] = useGlobalState()
    //const [columnName, setColumnName] = useState(0);
    //const [measurementType ,setMeasurementType] = useState([])
    //const [measurementTypeLabel, setMeasurementTypeLabel] = useState([])

    useEffect(()=>{
        console.debug(row,tabValue);
        if (tabValue === 2){
            setTab("Weekly")
        }else{
            setTab("Daily");
        }
       /* let tempMeasurementType =[row.graphData[0], row.stdGraphData[0]]
        setMeasurementType(tempMeasurementType)
        setMeasurementTypeLabel([...labels[0]])*/
    },[row,tabValue]);

    /*const ChangeMeasurementType = (index) =>{
        let tempMeasurementType =[row.graphData[index], row.stdGraphData[index]]
        setMeasurementType(tempMeasurementType)
        setMeasurementTypeLabel([...labels[index]])
    }

    const handleColumnNameChange =  (event, value)=>{
        console.debug(event, value);
        setColumnName(event.target.value)
        ChangeMeasurementType(event.target.value)
    }*/

    return  <Card className={classes.card}>
        <CardHeader 
            title={row.shedName}
        />
        <CardContent  className={classes.paddingTopZero}>
            <Grid
                container
                justify='space-between'
                alignItems='flex-end'
                className={classes.topBar}
            >
           {/*} <Grid>
                <FormControl  className={classes.formControl}>
                    <Select
                        id={"column-name"+row.shedName}
                        value={columnName}
                        onChange={handleColumnNameChange}
                        name={"column-name"+row.shedName}
                    >
                        <MenuItem key = {0} value={0} >Production %</MenuItem>
                        <MenuItem key = {1} value={1}>Feed Intake</MenuItem>
                        <MenuItem key = {2} value={2}>Mortality</MenuItem>
                        <MenuItem key = {3} value={3}>Broken Egg %</MenuItem>
                    </Select>
                </FormControl>
            </Grid>*/}
            {/* {user.versionID === version.proPlus ? 
                <Grid  className={classes.topBar}>
                    <CustomDualHorizontalBar graphData = {row.graphData} stdGraphData ={row.stdGraphData} tab={tab}
                    /* measurementType={measurementType} 
                    measurementTypeLabel={measurementTypeLabel}
                    />
                </Grid>
            : null} */}
            <Grid>
                <Grid item className={classes.typo}>
                    <Typography variant="h5" component="h2">
                        {(row.productionPercentage).toFixed(2)} <span className={classes.prodData}>({ row.stdProductionPercentage.toFixed(2)})</span>
                    </Typography>
                    <Typography color="textSecondary">
                        Production %
                    </Typography>
                </Grid>
                <Grid item className={classes.typo}>
                    <Typography variant="h5" component="h2">
                        {(row.feedIn).toFixed(2)}<span className={classes.prodData}>({ row.stdFeedIn.toFixed(2)})</span>
                    </Typography>
                    {
                        ( tabValue === 1 ) ? 
                            <Typography color="textSecondary">
                                Feed<p>/bird ( gms )</p>
                            </Typography>
                        :
                            <Typography color="textSecondary">
                                Avg Feed<p>/bird ( gms )</p>
                            </Typography>
                    }
                </Grid>
                {
                    (user.versionID !== version.lite) && 
                    <Grid item className={classes.typo}>
                    <Typography variant="h5" component="h2">
                    {(row.costPerBird).toFixed(2)}
                    </Typography>
                    <Typography color="textSecondary">
                        Cost Per Bird
                    </Typography>
                    </Grid>
                }
                <Grid item className={classes.typo}>
                    <Typography variant="h5" component="h2">
                        {row.age}
                    </Typography>
                    <Typography color="textSecondary">
                        Age
                    </Typography>
                </Grid>
            </Grid>
            <Grid>
                <Grid item className={classes.typo}>
                    <Typography variant="h5" component="h2">
                        {(row.mortality).toFixed(2)}<span className={classes.prodData}>({ row.stdMortality.toFixed(2)})</span>
                    </Typography>
                    <Typography color="textSecondary">
                        Mortality %
                    </Typography>
                </Grid>
                <Grid item className={classes.typo}>
                    <Typography variant="h5" component="h2">
                        {(row.brokenEggsPercentage).toFixed(2)}
                    </Typography>
                    <Typography color="textSecondary">
                        Broken Eggs %
                    </Typography>
                </Grid>
                {
                    (user.versionID !== version.lite) && 
                    <Grid item className={classes.typo}>
                    <Typography variant="h5" component="h2">
                        {(row.runningCost).toFixed(2)}
                    </Typography>
                    <Typography color="textSecondary">
                        Running Cost
                    </Typography>
                </Grid>
                }
                <Grid item className={classes.typo}>
                    <Typography variant="h5" component="h2">
                        {(row.eggSales)}
                    </Typography>
                    <Typography color="textSecondary">
                        Egg Sales
                    </Typography>
                </Grid>
            </Grid>
            </Grid>
        </CardContent>
    </Card>
}

// eslint-disable-next-line
const CustomDualHorizontalBar =(props) =>{
    const  dualHorizontalBarsChartData = {
        labels:labels,
        datasets: [ 
            { 
                label:props.tab+" data",
                backgroundColor: '#1B3A56',
                data:props.graphData,
                borderWidth: 0,
            },
            { 
                label: "Standard data",
                backgroundColor: '#F3B56B',
                data:props.stdGraphData,
                borderWidth: 0,
            }
        ]
    }
        const dualChartOptions = { 
            responsive: true ,
            maintainAspectRatio:false,
            barPercentage: 5,
            legend: {
             display: true
            },
            scales: {
              xAxes: [
                {
                  ticks:{
                      min: 0,
                      stepSize:30
                  }
                }
              ],
              yAxes: [
                {
                    barPercentage: 0.5,
                    display: true
                }
              ]
            },
            animation: {
                duration: 1,
                onComplete: function () {
                    var chartInstance = this.chart,
                        ctx = chartInstance.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';
        
                    this.data.datasets.forEach(function (dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach(function (bar, index) {
                            var data = dataset.data[index];                            
                            ctx.fillText(data, bar._model.x+15, bar._model.y+8);
                        });
                    });
                }
            }
          }
       
    return <HorizontalBar width={300}  height={300} data={dualHorizontalBarsChartData} options={dualChartOptions} />
                                     
}

export default ShedDetails;