import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from "@material-ui/core/Typography";
import { Divider } from '@material-ui/core';
import SegmentationGraph from './segmentationGraph';
import {useGlobalState} from '../../../state';



const useStyles = makeStyles(theme => ({
    searchGrid: {
        padding: theme.spacing(2)
    },
    loaderGrid: {
        verticalAlign: 'middle',
        borderRight: '1px solid #bfbfbf',
        padding: theme.spacing(2),
        textAlign: 'center',
        display: 'table-cell'
    },
    card: {
        minHeight: '100%',
        minWidth: '94%',
        marginTop: theme.spacing(2),
        display: 'table',
        width: '98.5%',
        tableLayout: 'fixed'
    },
    paddingCenter: {
        padding: theme.spacing(2),
        textAlign: 'center',
        display: 'table-cell'
    },
    netProfitLastGrid: props => ({
        padding: theme.spacing(2),
        textAlign: 'center',
        display: props.isDesktop ? 'table-cell' : null
    }),
    segmentationGraphGrid: {
        //borderRight: '1px solid #bfbfbf',
        padding: theme.spacing(2),
        textAlign: 'center',
        display: 'table-cell'
    }, progressBar: {
        margin: theme.spacing(2)
    }
}));


const earningSegmentationGraphLabels = ['Sales', 'Purchases', 'Other Expenses', 'Other Income'];

const Earnings = ({ netProfitLoading, netProfitData }) => {
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }



    return <>
        {netProfitLoading || !netProfitData ?
            <Card className={classes.card}>
                <Grid className={classes.loaderGrid} item xs={12}>
                    <CircularProgress className={classes.progressBar} />
                </Grid>
            </Card>
            :
            <Card className={classes.card}>
                <CardHeader title="Earnings" className={classes.header} />
                <Divider />
                <CardContent>
                    <SegmentationGraph data={netProfitData.segmentationGraph} label={earningSegmentationGraphLabels} legendProp={true} />
                </CardContent>
                <Divider />
                <CardActions >
                    <Grid
                        container
                        justify='space-between'
                        alignItems='flex-end'>
                    <Grid className={classes.segmentationGraphGrid} item xs={6}>
                        <Typography color="textSecondary">
                            Total Sales
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {netProfitData.totalSales.toLocaleString(user.locales,amountObj)}
                        </Typography>
                    </Grid>
                    <Grid className={classes.segmentationGraphGrid} item xs={6}>
                        <Typography color="textSecondary">
                            Total Purchases
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {netProfitData.totalPurchase.toLocaleString(user.locales,amountObj)}
                        </Typography>
                    </Grid>
                    <Grid className={classes.netProfitLastGrid} item xs={6}>
                        <Typography color="textSecondary">
                            Total Other Expenses
                         </Typography>
                        <Typography variant="h5" component="h2">
                            {netProfitData.totalOtherExpense.toLocaleString(user.locales,amountObj)}
                        </Typography>
                    </Grid>
                    <Grid className={classes.netProfitLastGrid} item xs={6}>
                        <Typography color="textSecondary">
                            Total Other Income
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {netProfitData.totalOtherIncome.toLocaleString(user.locales,amountObj)}
                        </Typography>
                    </Grid>
                    </Grid>
                </CardActions>
            </Card>
        }
    </ >
};

export default Earnings;