import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';


const ConfirmDialog = ({showDialog, handleClose, onConfirm, title, subText}) => {
    return <Dialog open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {subText}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="secondary">
                No
            </Button>
            <Button onClick={onConfirm} color="primary" autoFocus>
                Yes
            </Button>
        </DialogActions>
    </Dialog>
};

ConfirmDialog.propTypes = {
    title: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
    showDialog: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};
export default ConfirmDialog;