import React, { useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';

let schema = {
    batchIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    vaccineName: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    days: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThanOrEqualTo: 0
        }
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1.5),
        minWidth: 180,
    },
    textField: {
        margin: theme.spacing(1),
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    }
}));

const VaccinationRemainder = ({ deleteErrorMessage, errorMessage, batches, data, handleClose, showDialog, onRemainderSave, onRemainderUpdate,
    editMode = false }) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);

    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if (showDialog) {
            console.debug(data)
            if (editMode) {
                let dueFormat
                if (data.reminderDueStartDate.Valid && data.days === 0) {
                    data={
                        ...data,
                        reminderDueStartDate:data.reminderDueStartDate.Time
                    }
                    dueFormat = 1
                    schema = {
                        ...schema,
                        reminderDueStartDate: {
                            presence: { allowEmpty: false, message: 'is required' }
                        }
                    }
                } else {
                    dueFormat = 2
                    schema = {
                        ...schema,
                        days: {
                            presence: { allowEmpty: false, message: 'is required' },
                            numericality: {
                                greaterThanOrEqualTo: 0
                            }
                        }
                    }
                }
                data = {
                    ...data,
                    batchIndex: batches.findIndex((batch) => { return batch.id === data.batchId; }),
                    dueFormat: dueFormat
                }
            }
            updateData(data, editMode);
        }
    };
    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        var tempData = {
            ...formState.values,
            batchId: batches[formState.values.batchIndex].id,
            batchName: batches[formState.values.batchIndex].name,
            shedId: batches[formState.values.batchIndex].shedID,
            shedName: batches[formState.values.batchIndex].shedName,
        }
        if (tempData.dueFormat === 1) {
            tempData={
                ...tempData,
                reminderDueStartDate:{
                    Time:tempData.reminderDueStartDate,
                    Valid:true
                }
            }
            delete tempData.days;
        }else{
            tempData={
                ...tempData,
                reminderDueStartDate:{
                    ...tempData.reminderDueStartDate,
                    Valid:false
                }
            }
            //delete tempData.reminderDueStartDate;
        }
        if (editMode)
            onRemainderUpdate(tempData)
        else
            onRemainderSave(tempData);
    };

    const handleChangeDueFormat = (event) => {
        console.debug("event",event)
        if (event.target.value === 1) {
            schema = {
                batchIndex: {
                presence: { allowEmpty: false, message: 'is required' }
            },
            vaccineName: {
                presence: { allowEmpty: false, message: 'is required' }
            },
                reminderDueStartDate: {
                    presence: { allowEmpty: false, message: 'is required' }
                }
            }
        } else {
            schema = {
                batchIndex: {
                    presence: { allowEmpty: false, message: 'is required' }
                },
                vaccineName: {
                    presence: { allowEmpty: false, message: 'is required' }
                },
                days: {
                    presence: { allowEmpty: false, message: 'is required' },
                    numericality: {
                        greaterThanOrEqualTo: 0
                    }
                }
            }
        }
    }

    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >{editMode ? 'Update' : 'New'}  Reminder
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
        <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
            <FormControl className={classes.formControl}>
                <InputLabel id="batchName-select-outlined-label">
                    Batch Name
                        </InputLabel>
                <Select
                    id="batchName-select"
                    value={formState.values.batchIndex !== undefined ? formState.values.batchIndex : ''}
                    onChange={handleChange}
                    name="batchIndex"
                >
                    {batches !== undefined && batches !== null ?
                        batches.map((batch, index) =>
                            <MenuItem key={batch.id} value={index}>{batch.name}({batch.shedName})</MenuItem>
                        ) : ""}
                </Select>
            </FormControl>
            <TextField
                className={classes.textField}
                error={hasError('vaccineName')}
                fullWidth
                helperText={
                    hasError('vaccineName') ? formState.errors.vaccineName[0] : null
                }
                label="Notes"
                name="vaccineName"
                onChange={handleChange}
                type="text"
                value={formState.values.vaccineName || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <FormControl className={classes.formControl}>
                <InputLabel id="dueFormat-select-outlined-label">
                    Due Format
                </InputLabel>
                <Select
                    id="dueFormat"
                    value={formState.values.dueFormat !== undefined ? formState.values.dueFormat : ''}
                    onChange={event => { handleChange(event, handleChangeDueFormat) }}
                    name="dueFormat"
                >
                    <MenuItem key={0} value={1}>Remind me on</MenuItem>
                    <MenuItem key={1} value={2}>Remind me in</MenuItem>
                </Select>
            </FormControl>
            {formState.values.dueFormat === 1 ?
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        className={classes.textField}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Reminder Date"
                        format="dd/MM/yyyy"
                        value={formState.values.reminderDueStartDate || null}
                        onChange={data => {
                            handleChange({ target: { name: 'reminderDueStartDate', value: data } })
                        }}
                        animateYearScrolling
                        name="reminderDueStartDate"
                        style={{backgroundColor:"#ffffff"}}
                    />
                </MuiPickersUtilsProvider>
                : formState.values.dueFormat === 2 ?
                    <TextField
                        className={classes.textField}
                        error={hasError('days')}
                        helperText={
                            hasError('days') ? formState.errors.days[0] : null
                        }
                        label="Days"
                        name="days"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.days || ''}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                    />
                    :
                    null
            }
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
};

export default VaccinationRemainder;