import React, {useState, useEffect} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { red, green } from '@material-ui/core/colors';
import { MaterialTable } from '../../../components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import {useGlobalState} from '../../../state';
import { version } from '../../../common/enums';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0)
    },
    spacing: {
        paddingBottom: theme.spacing(3),
        "& .MuiTableCell-root" :{
            padding:'5px'
        }
    },
    formControlLabelClass: {
        marginLeft: theme.spacing(2),
    },
}));


const FarmCardDetails = ({showDialog, farm, handleClose, currentFarm}) => {
    const classes = useStyles();
    const [{user}] = useGlobalState();
    const [isUsedSheds, setIsUsedSheds] = useState(false)
    const [myFarm, setMyFarm] = useState([])
    
    var date;
    var columns;
    columns =  [
        { title: 'id', field: 'id', hidden: true },
        {
            title: 'Shed Name', field: 'shedName', editable: 'never',
            render: rowData => {
                return <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {rowData.shedName}
                </span>
            }
        },
        {
            title: 'Batch Name', field: 'batchName', editable: 'never',
            render: rowData => {
                return <span>
                    {rowData.batchName}
                </span>
            }
        },
        {
            title: 'Age', field: 'age', editable: 'never',
            render: rowData => {
                return <span >
                    {rowData.shedName === "Total" ? "" : rowData.age}
                </span>
            }
        },
        {
            title: 'Feed In', field: 'feedIn', editable: 'never',
            render: rowData => {
                return <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {(rowData.feedIn).toFixed(1)}
                </span>
            }
        },
        {
            title: 'Feed Per Bird (in grams)', field: 'feedIn', editable: 'never',
            render: rowData => {
                return <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {(rowData.feedPerBird).toFixed(1)}
                </span>
            }
        },
        {
            title: 'Closing Birds', field: 'closingBirds', editable: 'never',
            render: rowData => {
                return <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {rowData.closingBirds}
                </span>
            }
        },
        {
            title: 'Egg Production', field: 'eggs', editable: 'never',
            render: rowData => {
                return <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {rowData.eggs}
                </span>
            }
        },
        {
            title: 'Production %', field: 'productionPercentage', editable: 'never',
            render: rowData => {
                return <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {rowData.productionPercentage !== "" ? (rowData.productionPercentage).toFixed(2) : ""}
                </span>
            }
        },
        {
            title: 'Used In Calculation', field: 'usedShed', editable: 'never',
            render: rowData => {
                return <span>
                    {rowData.shedName !== "Total" ? 
                     rowData.isShedDataUsed ? 
                        <CheckIcon style={{ color: green[600] }} />
                        : <ClearIcon style={{ color: red[600] }} />
                    : ""
                    }
                </span>
            }
        },
    ];

    if(user.versionID !== version.lite){
        columns.splice(8,0,{
            title: 'Egg Sales', field: 'eggSales', editable: 'never',
            render: rowData => {
                return  <span style={rowData.shedName === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                    {rowData.eggSales}
                </span>
            }
        });
    }

    const setInitialValue = () => {
        if(showDialog){
            setMyFarm(farm[currentFarm.id].usedShedData);
            setIsUsedSheds(true);
        }
    }

    if (showDialog) {
        date = farm[currentFarm.id].date
    }

    useEffect(setInitialValue, [showDialog]);


    const handleChange = (event) => {
        setIsUsedSheds(event.target.checked)

        if (event.target.checked === true) {
            setMyFarm(farm[currentFarm.id].usedShedData)
        } else {
            setMyFarm(farm[currentFarm.id].allShedData);
        }
    }
    
    return <Dialog maxWidth="xl" disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title" >
            <DialogTitle>
            
            Record Date: {date !== undefined ? date : "" }
            
            <FormControlLabel
                className={classes.formControlLabelClass}
                control={
                    <Switch
                        checked={isUsedSheds}
                        name="isUsedSheds"
                        color="primary"
                        onChange={handleChange}
                    />
                }
                label={"Hide sheds not used in calculations"}
            />

            </DialogTitle>

            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent>

            <div id='report-container' className={classes.spacing} >
                <MaterialTable
                    columns={columns}
                    data={myFarm !== undefined ? myFarm : []}
                    title='Shed Data Table'
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false

                    }}
                />
            </div>

            </DialogContent>
            
        </Dialog>
}

export default FarmCardDetails