import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {DialogTitle} from "../../../components";
import makeStyles from '@material-ui/styles/makeStyles';
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import Breed from '../../Breed/components/breed';
import AddIcon from '@material-ui/icons/Add';

let schema = {
    name: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    purchaseDate: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    shedIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    breedIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    numberOfBirds: {
        presence: { allowEmpty: false, message: 'is required' },
        type: 'integer'
    },
    openingEggs:{
        type: 'integer'
    },
    openingCost:{
        type: 'integer'
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    addIconCss:{
        marginLeft:theme.spacing(2),
    }
}));

const Batch = ({ deleteErrorMessage,addBatchError, errorMessage, onEdit, data, breeds, sheds, onSave, handleClose, showDialog, editMode = false ,closeBreedDialog,showBreedDialog,onBreedSave,addBreedDialog}) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [showMovementDate, setShowMovementdate] = useState(false)
    const [purcahseDateValidation,setpurchaseDateValidation] = useState(false)
    const resetBeforeEdit = () => {
        if(data !== undefined && data.movementDate !== undefined){
            data.movementDate = ""
        }
        if(data !== undefined && data.bsaCount > 1){ //if batched is already moved disable purchase data
            setpurchaseDateValidation(true)
        } else {
            setpurchaseDateValidation(false)
        }
        if(editMode && data !== undefined && data.age !== undefined && data.age !== null && data.age !== ""){
            schema = {
                ...schema,
                age: {
                    presence: { allowEmpty: false, message: 'is required' },
                    type: 'integer',
                    numericality: {
                        greaterThanOrEqualTo: 0,
                    }
                }
            }
        }
        if (showDialog) {
            updateData(data, editMode);
        }
        setShowMovementdate(false)
    };

    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        console.debug("On submit")
        let tempData = {
            ...formState.values,
            age:parseInt(formState.values.age||'0'),
            breedID: breeds[formState.values.breedIndex].id,
            shedID: sheds[formState.values.shedIndex].id,
            breedName: breeds[formState.values.breedIndex].name,
            shedName: sheds[formState.values.shedIndex].name,
        };
        if(tempData.movementDate ===undefined || tempData.movementDate === ""){
            tempData = {
                ...tempData,
                movementDate:new Date()
            }
        }
        if(schema.age !== undefined){
            delete schema.age
        }
        if (editMode)
            onEdit(tempData);
        else
            onSave(tempData);
    };

    const onShedChange = (event) => {
        if (editMode) {
            if (event.target.value !== data.shedIndex) {
                setShowMovementdate(true)
                schema = {
                    ...schema,
                    movementDate: {
                        presence: { allowEmpty: false, message: 'is required' },
                    }
                }
            } else {
                setShowMovementdate(false)
                if(schema.movementDate !== undefined){
                    delete schema.movementDate
                }
            }
        }
        console.debug(schema)
    };

    const onAgeChange =(event) =>{
        console.debug("Age : ",event.target.value)
        if (event.target.value !== "" && schema.age === undefined) {
                schema = {
                    ...schema,
                    age: {
                        presence: { allowEmpty: false, message: 'is required' },
                        type: 'integer',
                        numericality: {
                            greaterThanOrEqualTo: 0,
                        }
                    }
                }
        }  
        if(event.target.value === "" && schema.age !== undefined){
            delete schema.age
        }
    }

    const onClose = () =>{
        if(schema.age !== undefined){
            delete schema.age
        }
        handleClose()
    }

    return <Dialog disableBackdropClick open={showDialog} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" >{editMode ? 'Update' : 'New'} Batch
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          <div id="error">
          {/* {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''} */}
          {addBatchError ? <Chip onDelete={deleteErrorMessage} color="primary" label={addBatchError}/> : ''}
          
          </div>
            <TextField
                className={classes.textField}
                error={hasError('name')}
                fullWidth
                helperText={
                    hasError('name') ? formState.errors.name[0] : null
                }
                label="Batch Name"
                name="name"
                onChange={handleChange}
                type="text"
                value={formState.values.name || ''}
                variant="outlined"
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Purchase Date"
                    format="dd/MM/yyyy"
                    disabled={purcahseDateValidation}
                    value={formState.values.purchaseDate || null}
                    onChange={data => {
                        handleChange({ target: { name: 'purchaseDate', value: data } })
                    }}
                    animateYearScrolling
                    name="purchaseDate"
                />
            </MuiPickersUtilsProvider>
            <FormControl className={classes.formControl}>
                <InputLabel id="shedType-select-outlined-label">
                    Shed Type
                </InputLabel>
                <Select
                    id="shedType-select"
                    value={formState.values.shedIndex !== undefined ? formState.values.shedIndex : ''}
                    onChange={event => { handleChange(event, onShedChange) }}
                    name="shedIndex"
                >
                    {sheds !== undefined && sheds !== null ?
                        sheds.map((shed, index) =>
                            <MenuItem key={shed.id} value={index}>{shed.name}</MenuItem>
                        ) : ""}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="breedType-select-label">Breed Type</InputLabel>
                <Select
                    id="breedType-select"
                    value={formState.values.breedIndex !== undefined ? formState.values.breedIndex : ''}
                    onChange={handleChange}
                    name="breedIndex"
                >
                    {breeds.length === 0 ?  
                        <MenuItem onClick={addBreedDialog}>
                          <AddIcon /> 
                          <div className={classes.addIconCss}>
                            Add
                          </div>
                        </MenuItem>
                    : breeds.map((breed, index) =>
                        <MenuItem key={breed.id} value={index}>{breed.name}</MenuItem>
                    )}
                </Select>
            </FormControl>
            {showMovementDate ?
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Movement Date"
                        format="dd/MM/yyyy"
                        value={formState.values.movementDate || null}
                        onChange={data => {
                            handleChange({ target: { name: 'movementDate', value: data } })
                        }}
                        animateYearScrolling
                        name="movementDate"
                        style={{backgroundColor:"#ffffff"}}
                    />
                </MuiPickersUtilsProvider>
                : ""
            }
            <TextField
                className={classes.textField}
                error={hasError('numberOfBirds')}
                fullWidth
                helperText={
                    hasError('numberOfBirds') ? formState.errors.numberOfBirds[0] : null
                }
                label="Number Of Birds"
                name="numberOfBirds"
                onChange={handleChange}
                type="number"
                value={formState.values.numberOfBirds || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('age')}
                fullWidth
                helperText={
                    hasError('age') ? formState.errors.age[0] : null
                }
                label="Age(in weeks on Purchase date)"
                name="age"
                onChange={event => { handleChange(event, onAgeChange) }}
                type="number"
                value={formState.values.age ||""}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            {formState.values.age > 0 ? 
            
            <>
            <TextField
                className={classes.textField}
                error={hasError('openingEggs')}
                fullWidth
                helperText={
                    hasError('openingEggs') ? formState.errors.openingEggs[0] : null
                }
                label="Opening Total Eggs (In trays)"
                name="openingEggs"
                onChange={handleChange}
                type="number"
                value={formState.values.openingEggs ||""}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('openingCost')}
                fullWidth
                helperText={
                    hasError('openingCost') ? formState.errors.openingCost[0] : null
                }
                label="Opening Cost"
                name="openingCost"
                onChange={handleChange}
                type="number"
                value={formState.values.openingCost ||""}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            </>
            : ""
            
            }
             <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
        <Breed 
        deleteErrorMessage={deleteErrorMessage} 
        handleClose={closeBreedDialog}
        showDialog={showBreedDialog} 
        onSave={onBreedSave} 
        />
    </Dialog>

};

export default Batch;