import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from '@material-ui/core/Button';
import {ConfirmDialog, MaterialTable} from "../../components";
import api from "../../common/api";
import Icon from "@material-ui/core/Icon";
import Labour from './components/Labour';
import Mobile from "./components/mobile";
import moment from "moment";
import Chip from '@material-ui/core/Chip';
import red from '@material-ui/core/colors/red';

const useStyles = makeStyles(theme => ({
    root: props=>({
        margin: props.isDesktop?theme.spacing(4):theme.spacing(1),
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    breadcrumbs:{
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    chip: {
        backgroundColor: red[500],
        color: "white",
        fontWeight: 500
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    redChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#f44336',
        backgroundColor: 'rgba(244, 67, 54, 0.08)',
    },
    errorMargin:{
        marginBottom: theme.spacing(3),
    }
}));



const LabourView = ({showError, showMessage, match}) => {
    const farmID = match.params['farmID'];

    const [loading, setLoading] = useState(false);
    const [LabourEditMode,setLabourEditMode] = useState(false)
    const [LabourData,setLabourData] = useState(false)

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});
    const [labours,setLabours] = useState([])
    const [showLabourDialog, setShowLabourDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    const handleLabourClose = () => {
        setLabourData({})
        
        setShowLabourDialog(false);

    };





    const onLabourEdit = (labour) => {
        setLabourEditMode(true);
        setLabourData(labour)
        setShowLabourDialog(true)
    };

    
    function onNewLabour(event) {
        setLabourEditMode(false);
        event.preventDefault();
        setShowLabourDialog(true);
    }

    const onDelete = (data) => {
        setLabourData(data)
        setShowDeleteConfirm(true);
    };

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }
    
    const onLabourSave = (labour) => {
        setLoading(true);
        showMessage('');
        const response = api.post(`farm/${farmID}/createLabour`, {
            ...labour
        });

        response.then(res => {
            setLabours([...labours, res]);
            setShowLabourDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Labour not added');
                else if(err.message ===  'Duplicate Key')
                    setErrorMessage('Duplicate Name');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onLabourUpdate = (labour) => {
        setLoading(true);
        console.debug("On Labour update")
        const response = api.post(`farm/${farmID}/labour/${labour.id}`, {
            ...labour
        });
        response.then(() => {
            let index = labours.findIndex((srcData) => {
                return srcData.id === labour.id;
            });

            setLabours([...labours.slice(0, index),
                {...labour},
                ...labours.slice(index + 1)]);
            setShowLabourDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('labour not updated');
                else if(err.message ===  'Duplicate Key')
                    setErrorMessage('Duplicate Name');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setLabourData(false)
        });
    };

    function onDeleteConfirm (){
        if (LabourData && LabourData.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`farm/${farmID}/labour/${LabourData.id}`);

            response.then(res => {
                if (res) {
                    let index = labours.findIndex((src) => {
                        return src.id === LabourData.id;
                    });
                    let newData = [...labours];
                    newData.splice(index, 1);
                    setLabours(newData);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        setErrorMessage('Labour not Deleted');
                    else
                        setErrorMessage(err.message)
                } else {
                    setErrorMessage('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                setLabourData(false)
            });

        }
    }





    //Get all labour API Call
    const getAllLabours = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get(`farm/${farmID}/getLabour`);

        response.then(res => {
            setLabours([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('No Labours');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const onload=()=>{
        getAllLabours()
       
    }
    useEffect(onload, []);



    let labourColumnDefaults = {title: 'Name',field: 'name', defaultSort:'asc'};
    let labourColumn = {...labourColumnDefaults,
        render: rowData => <Mobile data={rowData}
                                   onEdit={()=>{onLabourEdit(rowData)}}
                                   onDelete={()=>{onDelete(rowData)}}
                                   
        />};
    let actions = [];
    if (isDesktop) {
        labourColumn = labourColumnDefaults;
        actions = [
            rowData => ({
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Labour',
                onClick: (event, rowData) => {
                    onLabourEdit(rowData);
                },
            }),
            rowData => ({
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Labour',
                onClick: (event, rowData) => {
                    onDelete(rowData);
                },
            })
        ]
    }
    return (
        <div className={classes.root}>
             <div id="error" className={classes.errorMargin}>
                    {errorMessage ? <Chip onDelete={removeErrorMessage} color="primary" label={errorMessage}/> : ''}
             </div>
            <Grid
                container
                justify='space-between'
                alignItems='flex-end'
                className={classes.topBar}
            >
                <Grid>
                    <Typography display="block" variant="h3" gutterBottom>
                        Labour
                    </Typography>
                </Grid>

                <Grid>
                    <Button variant="contained" color="primary"  onClick={onNewLabour}>Add Labour</Button>
                </Grid>
            </Grid>
                <MaterialTable
                    isLoading={loading}
                    columns={[
                        {title: 'id', field: 'id', hidden: true},
                        labourColumn,
                        {title: 'Pay/Day', field: 'payperday', hidden: !isDesktop},
                        {title: 'Advance', field: 'advance', hidden: !isDesktop},
                        {title: 'Joining Date', field: 'joiningDate', type: 'datetime',
                        editable: 'never',
                        hidden: !isDesktop,
                        render: rowData => {
                            return <span>{moment(rowData.joiningDate).format("DD-MM-YYYY")}</span>
                        }},
                        {title: 'Leaving Date', field: 'leavingDate', type: 'datetime',
                        editable: 'never',
                        hidden: !isDesktop,
                        render: rowData => {
                            return <span>{rowData.leavingDate ? moment(rowData.leavingDate).format("DD-MM-YYYY"):""}</span>
                        }},
                    ]}
                    data={labours}
                    options={{
                        actionsColumnIndex: -1,
                        search: true,
                        showTitle: true,
                        
                        toolbar: true
                    }}
                    actions={actions}
                    title='Labour Table'
                />   
            <Labour deleteErrorMessage={removeErrorMessage}  errorMessage={errorMessage} showDialog={showLabourDialog} handleClose={handleLabourClose} onSave={onLabourSave}
                  onEdit={onLabourUpdate}  loading={loading}
                  editMode={LabourEditMode} data={LabourData}
                  />


             <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onDeleteConfirm}
                       title='Delete Labour ?'
                       subText='This action will delete the Labour.'/>
        </div>
    );
};

export default LabourView;