import React, { useState, useEffect,useRef } from 'react';
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/styles/makeStyles';
//import jsPDF from 'jspdf';
import Button from "@material-ui/core/Button";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
//import 'jspdf-autotable';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../../state';

//import html2canvas from "html2canvas";


const useStyles = makeStyles(theme => ({
    generatePDF:{
        position: 'relative',
        left: theme.spacing(70),
        backgroundColor: theme.palette.background.paper

    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.text.default,
      }, 
    divider: {
        backgroundColor: '#101010'
    },
    textCenter: {
        textAlign: "center"
    }
}))


const InvoiceView = ({ data, showDialog, handleClose, invoiceSetting }) => {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    //const [eggPresent, setEggPresent] = useState(false);
    const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
    });
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
   // const [disablePdf, setDisablePdf] = useState(false)
    const resetBeforeEdit = () => {
        if (showDialog) {
            let tempSaleMaterials = []
            //console.debug(tempSaleMaterials)
            for (let i = 0; i < data.lineSaleMaterials.length; i++) {
                let material = data.lineSaleMaterials[i]
                let index = tempSaleMaterials.findIndex((tempMaterial) => {
                    return tempMaterial.materialId === material.materialId && tempMaterial.costPerUnit === material.costPerUnit
                })
                console.debug(index)
                if (index >= 0) {
                    // console.debug(tempSaleMaterials[index].billValue, material.billValue, 
                    // tempSaleMaterials[index].billValue + material.billValue)
                    tempSaleMaterials[index].billValue = tempSaleMaterials[index].billValue + material.billValue
                    tempSaleMaterials[index].quantity = tempSaleMaterials[index].quantity + material.quantity
                } else {
                    //console.debug(tempSaleMaterials)
                    tempSaleMaterials = [
                        ...tempSaleMaterials,
                        material
                    ]
                }

            }
            console.debug(tempSaleMaterials)
            setItems([...tempSaleMaterials])
        }
    };


    useEffect(resetBeforeEdit, [showDialog]);

   /* const generatePDF = function () {

        var oIframe = document.getElementById('receipt');
        var oContent = document.getElementById('sale-invoice').innerHTML;
        var oDoc = (oIframe.contentWindow || oIframe.contentDocument);
        if (oDoc.document) oDoc = oDoc.document;
        oDoc.write('<head>');
        oDoc.write('</head><body onload="this.focus(); this.print();">');
        oDoc.write(oContent + '</body>');
        oDoc.close();
        

          var iframe = document.createElement('iframe');
      iframe.id = "iFrameReport";
      iframe.style.visibility = 'hidden';
      document.body.appendChild(iframe);
          var printContents = document.getElementById("sale-invoice").innerHTML;
          var originalContents = document.body.innerHTML;
          document.body.innerHTML = printContents;
          window.print();
          document.body.innerHTML = originalContents;
      */

        /*var divContents = document.getElementById("sale-invoice").innerHTML; 
        var a = window.open('', '', 'height=100%, width=100%'); 
        a.document.write(divContents); 
        a.document.close(); 
        a.print(); 
*/
        //Set the print button visibility to 'hidden' 
        //  window.print()



 /*       const input = document.getElementById('sale-invoice');
        html2canvas(input,{
            scrollX: 0,
            scrollY: 0
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            console.log(imgData)
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'png', 0, 0);
            pdf.save("sale-invoice.pdf");
          });
*/


                /*const pdf = new jsPDF('p', 'px', 'a3');
        
                document.getElementById('sale-invoice').style.width = pdf.internal.pageSize.getWidth() + 'px';
                document.getElementById('sale-invoice').style.height = pdf.internal.pageSize.getHeight() + 'px';
                pdf.setFont("times");
                pdf.setFontStyle("normal");
                pdf.html(document.getElementById('sale-invoice'), {
                    callback: function (doc) {
                        //var pageCount = doc.internal.getNumberOfPages();
                        //doc.deletePage(pageCount)
                        doc.save('sale-invoice.pdf');
                        document.getElementById('sale-invoice').style.width = '100%';
                        document.getElementById('sale-invoice').style.height = '100%';
                    }
                });

    }*/

    if (data) {
        return <Dialog disableBackdropClick maxWidth="md" open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{backgroundColor:'#bac8d6'}}>

                    <Button disabled={!data} variant="outlined" color="primary" id="printpagebutton" className={classes.generatePDF}
                        onClick={generatePDF} endIcon={<PictureAsPdfIcon />}>Download
                        PDF</Button>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers style={{backgroundColor:'#f4f6f8'}}>
            <iframe
                id="receipt"
                //style={{ display: 'none' }}
                style={{ width: '100%',display: 'none' }}
                title="Receipt"
            />
 <div id="sale-invoice" ref={componentRef}  >
            {showDialog && data ?
                <div className="invoice-box" style={{fontFamily: 'Montserrat,Roboto,sans-serif'}}>
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr >
                                <td>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr><td><img src={invoiceSetting.companyLogo}
                                                            style={{width:'100%', maxWidth:'150px'}} alt="company logo"></img><br/><br/></td></tr>
                                                            <tr><td><b>TO</b></td></tr>
                                                            <tr><td>{data.customerName}</td></tr>
                                                            <tr><td style={{whiteSpace: 'pre-wrap'}} 
                                                           // className="show-white-space"
                                                            >{data.customerAddress}</td></tr>
                                                            <tr><td>
                                                                <b>GST No.</b>{data.customerGstNumber}<br /><br />
                                                            </td></tr>
                                                            {/*<tr><td><b>GSTIN:</b>{data.customerGstNumber}</td></tr>*/}
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style={{ width: "40%", textAlign: "right" }}>
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ fontWeight: '400', fontSize: '39px' }} colSpan="2">
                                                                    INVOICE<br />
                                                                </td>
                                                            </tr>
                                                            <tr><td colSpan="2"><h3>{invoiceSetting.companyName}</h3></td></tr>
                                                            <tr><td colSpan="2"  style={{whiteSpace: 'pre-wrap'}} >{invoiceSetting.address}</td></tr>
                                                            <tr><td colSpan="2"><b>GST No.</b>{invoiceSetting.gstNumber}<br /><br /></td></tr>
                                                            <tr>
                                                                <td style={{width: '80px'}}><b>Invoice </b></td>
                                                                <td> {data.displayId}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Date </b></td>
                                                                <td>{moment(data.date).format('DD/MM/YYYY')}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style={{ minHeight: "350px"}}>
                                    <table style={{ width: "100%"}}>
                                        <tbody>
                                            <tr className="heading" style={{fontWeight: "bold", backgroundColor: "#eeeeee"}}>
                                                <td>
                                                    Description
                                                </td>
                                                <td>
                                                    Quantity
                                                </td>
                                               
                                                    <td>
                                                        Eggs
                                                        </td>
                                                   
                                                <td>
                                                    Amount
                                                </td>
                                                <td>
                                                    Total
                                                </td>
                                            </tr>
                                            {
                                                items.map(saleItem => {
                                                    console.debug(saleItem)
                                                    return <tr className="item" key={saleItem.id}>
                                                        <td>{saleItem.materialName}</td>
                                                        <td>{saleItem.quantity}(trays)</td>
                                                      
                                                            <td>
                                                                {
                                                                    saleItem.quantity * 30
                                                                    
                                                                }
                                                            </td>
                                                            
                                                        <td>{(saleItem.costPerUnit).toFixed(2)}</td>
                                                        <td>{(saleItem.billValue).toLocaleString(user.locales,amountObj)}</td>
                                                    </tr>
                                                })
                                            }
                                            <tr><td><br/></td></tr>
                                            <tr className="total">
                                               
                                                    <td></td>
                                                   
                                                <td></td>
                                                <td></td>
                                                <td>Total Sum</td>
                                                <td>{(data.totalBillValue).toLocaleString(user.locales,amountObj)}</td>
                                            </tr>
                                            <tr className="total">
                                               
                                                    <td></td>
                                                   
                                                <td></td>
                                                <td></td>
                                                <td>TCS</td>
                                                <td>{(data.totalBillValue * (data.tds / 100)).toLocaleString(user.locales,amountObj)}</td>
                                            </tr>
                                            <tr className="total">
                                               
                                                    <td></td>
                                                   
                                                <td></td>
                                                <td></td>
                                                <td>Total Bill</td>
                                                <td>{(data.totalBillValue + (data.totalBillValue * (data.tds / 100))).toLocaleString(user.locales,amountObj)}</td>
                                            </tr>
                                            <tr className="total">
                                               
                                                    <td></td>
                                                    
                                                <td></td>
                                                <td></td>
                                                <td>Paid</td>
                                                <td>{(data.totalAmountPaid).toLocaleString(user.locales,amountObj)}</td>
                                            </tr>
                                            <tr><td colSpan="5"><Divider className={classes.divider} /></td></tr>
                                            <tr className="total">
                                                
                                                    <td></td>
                                                   
                                                <td></td>
                                                <td></td>
                                                <td>Total Due</td>
                                                <td>{parseInt((data.totalBillValue + (data.totalBillValue * (data.tds / 100))) - data.totalAmountPaid).toLocaleString(user.locales,amountObj)}</td>
                                            </tr>
                                            <tr><td colSpan="5"><Divider className={classes.divider} /></td></tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                            <tfoot>
                            <tr>
                                <td>
                                    <table style={{ fontSize: '11px' }}>
                                        <tbody>
                                            <tr>
                                                <td> <b>Terms & Conditions:</b></td>
                                            </tr>
                                            <tr>
                                                <td>  {invoiceSetting.termsAndConditions}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <br />
                                    <Divider className={classes.divider} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table  style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td style={{ textAlign: "center" }}>for <b>{invoiceSetting.companyName}</b></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style={{ width: "100%" }}> 
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <br /><br /><br /><br />
                                                        Receiver Signature
                                                        </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    <table  style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <br /><br /><br /><br />
                                                        Authorised Signatory
                                                        </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr><td><Divider className={classes.divider} /></td></tr>
                            <tr>
                                <td>
                                    <table  style={{ width: "100%" }}>
                                        <tbody>
                                            <tr style={{ textAlignLast: "center" }}>
                                                <td><b>Bank:</b>&nbsp;
                                                {invoiceSetting.bankName} &nbsp;| &nbsp;
                                                {invoiceSetting.accountNumber} &nbsp;| &nbsp;
                                                {invoiceSetting.ifscCode} &nbsp;| &nbsp;
                                                {invoiceSetting.branchName}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                : ""}
        </div>                </DialogContent>
            </Dialog>
    } else {
        return null
    }
};

/**const GenerateInvoice = ({ data, invoiceSetting, items, eggPresent, showDialog }) => {
    const classes = useStyles();
    return (
        <div id="sale-invoice" >
            {showDialog && data ?
                <div className="invoice-box" style={{fontFamily: 'Montserrat,Roboto,sans-serif'}}>
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr >
                                <td>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr><td><img src={invoiceSetting.companyLogo}
                                                            style={{width:'100%', maxWidth:'150px'}} alt="company logo"></img><br/><br/></td></tr>
                                                            <tr><td><b>TO</b></td></tr>
                                                            <tr><td>{data.customerName}</td></tr>
                                                            <tr><td style={{whiteSpace: 'pre-wrap'}} 
                                                           // className="show-white-space"
                                                            >{data.customerAddress}</td></tr>
                                                            <tr><td>
                                                                <b>GST No.</b>{data.customerGstNumber}<br /><br />
                                                            </td></tr>
                                                            <tr >
                                                                <td colSpan="2">
                                                                    <table style={{ width: "auto" }}><tbody>
                                                                        <tr className="heading" 
                                                                        style={{fontWeight: "bold", backgroundColor: "#eeeeee", }}>
                                                                            <td>Payment Due Days</td></tr>
                                                                        <tr>
                                                                            <td className="show-white-space">
                                                                                 {data.paymentDueDate}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                                </td>
                                                            </tr>*/
                                                           /* {<tr><td><b>GSTIN:</b>{data.customerGstNumber}</td></tr>}*/
                                                         /*</tbody>
                                                    </table>
                                                </td>
                                                <td style={{ width: "40%", textAlign: "right" }}>
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ fontWeight: '400', fontSize: '39px' }} colSpan="2">
                                                                    INVOICE<br />
                                                                </td>
                                                            </tr>
                                                            <tr><td colSpan="2"><h3>{invoiceSetting.companyName}</h3></td></tr>
                                                            <tr><td colSpan="2"  style={{whiteSpace: 'pre-wrap'}} >{invoiceSetting.address}</td></tr>
                                                            <tr><td colSpan="2"><b>GST No.</b>{invoiceSetting.gstNumber}<br /><br /></td></tr>
                                                            <tr>
                                                                <td style={{width: '80px'}}><b>Invoice </b></td>
                                                                <td> {data.displayId}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Date </b></td>
                                                                <td>{moment(data.date).format('DD/MM/YYYY')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Lorry No. </b></td>
                                                                <td>{data.truckNumber}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style={{ minHeight: "450px"}}>
                                    <table style={{ width: "100%"}}>
                                        <tbody>
                                            <tr className="heading" style={{fontWeight: "bold", backgroundColor: "#eeeeee"}}>
                                                <td>
                                                    Description
                                                </td>
                                                <td>
                                                    Quantity
                                                </td>
                                                {eggPresent ?
                                                    <td>
                                                        Eggs
                                                        </td>
                                                    :
                                                    null
                                                }
                                                <td>
                                                    Amount
                                                </td>
                                                <td>
                                                    Total
                                                </td>
                                            </tr>
                                            {
                                                items.map(saleItem => {
                                                    console.debug(saleItem)
                                                    return <tr className="item" key={saleItem.id}>
                                                        <td>{saleItem.materialName}</td>
                                                        <td>{saleItem.quantity}({saleItem.materialUnit})</td>
                                                        {eggPresent ?
                                                            <td>
                                                                {saleItem.unitID === 1 ?
                                                                    saleItem.quantity * 30
                                                                    :
                                                                    ""
                                                                }
                                                            </td>
                                                            :
                                                            ""
                                                        }
                                                        <td>{(saleItem.costPerUnit).toFixed(2)}</td>
                                                        <td>{(saleItem.billValue).toLocaleString("ta-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                    </tr>
                                                })
                                            }
                                            <tr><td><br/></td></tr>
                                            <tr className="total">
                                                {eggPresent ?
                                                    <td></td>
                                                    :
                                                    ""
                                                }
                                                <td></td>
                                                <td></td>
                                                <td>Total Sum</td>
                                                <td>{(data.totalBillValue).toLocaleString("ta-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                            </tr>
                                            <tr className="total">
                                                {eggPresent ?
                                                    <td></td>
                                                    :
                                                    ""
                                                }
                                                <td></td>
                                                <td></td>
                                                <td>Paid</td>
                                                <td>{(data.totalAmountPaid).toLocaleString("ta-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                            </tr>
                                            <tr><td colSpan={eggPresent ? "5" : "4"}><Divider className={classes.divider} /></td></tr>
                                            <tr className="total">
                                                {eggPresent ?
                                                    <td></td>
                                                    :
                                                    ""
                                                }
                                                <td></td>
                                                <td></td>
                                                <td>Total Due</td>
                                                <td>{parseInt(data.totalBillValue - data.totalAmountPaid).toLocaleString("ta-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                            </tr>
                                            <tr><td colSpan={eggPresent ? "5" : "4"}><Divider className={classes.divider} /></td></tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                            <tfoot>
                            <tr>
                                <td>
                                    <table style={{ fontSize: '11px' }}>
                                        <tbody>
                                            <tr>
                                                <td> <b>Terms & Conditions:</b></td>
                                            </tr>
                                            <tr>
                                                <td>  {invoiceSetting.termsAndConditions}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <br />
                                    <Divider className={classes.divider} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table  style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td style={{ textAlign: "center" }}>for <b>{invoiceSetting.companyName}</b></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style={{ width: "100%" }}> 
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <br /><br /><br /><br />
                                                        Receiver Signature
                                                        </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    <table  style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <br /><br /><br /><br />
                                                        Authorised Signatory
                                                        </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr><td><Divider className={classes.divider} /></td></tr>
                            <tr>
                                <td>
                                    <table  style={{ width: "100%" }}>
                                        <tbody>
                                            <tr style={{ textAlignLast: "center" }}>
                                                <td><b>Bank:</b>&nbsp;
                                                {invoiceSetting.bankName} &nbsp;| &nbsp;
                                                {invoiceSetting.accountNumber} &nbsp;| &nbsp;
                                                {invoiceSetting.ifscCode} &nbsp;| &nbsp;
                                                {invoiceSetting.branchName}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                : ""}
        </div>
    )
}*/
export default InvoiceView;