import React, { useEffect, useState } from 'react';
import makeStyles from "@material-ui/styles/makeStyles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
    formControl: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        minWidth: 160,
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    addButton: {
        marginBottom: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
    }, 
}))



let schema = {
    paymentAmount: {
        presence:{allowEmpty: false, message:'is required'},
        numericality: {
            greaterThanOrEqualTo: 1,
        }
    },
    chequeNumber: {
        presence: false
    },
}

const presenceFalse = {
    presence: false
}


const PaymentDialog = ({t, showDialog, editMode, handleCloseDialog, data, onSave, onUpdate}) => {

    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });

    const classes = useStyles({ isDesktop });
    const [paymentMode, setPaymentMode] = useState(1)

    useEffect(() => {
        if (showDialog) {
            updateData(data, editMode)
            
            if (editMode) {
                setPaymentMode(data.paymentMode)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDialog])

    const handlePaymentMode = (event) => {
        let payMode = parseInt(event.target.value)

        if (payMode === 2) {
            schema = {
                ...schema,
                chequeNumber: {
                    presence:{allowEmpty: false, message:'is required'},
                },
            }
        } else {
            schema = {
                ...schema,
                chequeNumber: presenceFalse,
            }
        }
        setPaymentMode(payMode)

    }

    const handleSavePayment = () => {
        let payment = {}
        payment = {
            paymentMode,
            paymentAmount: formState.values.paymentAmount,
        }
        if (paymentMode === 2) {
            payment = {
                paymentMode,
                chequeNumber: formState.values.chequeNumber,
                paymentAmount: formState.values.paymentAmount
            }
        }
    
        console.log("payment", payment)
        if (editMode) {
            payment["id"] = data.id
            onUpdate(payment)
        } else {
            onSave(payment)
        }
    }

    return <Dialog disableBackdropClick fullWidth maxWidth='sm' fullScreen={!isDesktop} open={showDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title" >{editMode ? t('updatePayment') : t('newPayment')}
    <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
      <CloseIcon />
    </IconButton>
    </DialogTitle>
      <DialogContent>
      <FormControl className={classes.formControl}>
        <div>
            <RadioGroup
                aria-label="Payment Mode"
                name="paymentMode"
                value={paymentMode}
                onChange={handlePaymentMode}
            >
                <FormControlLabel value={1} control={<Radio />} label={t('cash')} />
                <FormControlLabel value={2} control={<Radio />} label={t('cheque')} />
            </RadioGroup>
        </div>
            </FormControl>
         
            <TextField
                    className={classes.textField}
                    error={hasError('paymentAmount')}
                    fullWidth
                    helperText={
                        hasError('paymentAmount') ? formState.errors.paymentAmount[0] : null
                    }
                    label={t('paymentAmount')}
                    name="paymentAmount"
                    onChange = {handleChange}
                    value={formState.values.paymentAmount !== undefined ? formState.values.paymentAmount : ''}
                    type="number"
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}}
                />
        {paymentMode && paymentMode === 2 ? 
            <div>
                <TextField
                        className={classes.textField}
                        error={hasError('chequeNumber')}
                        fullWidth
                        helperText={
                            hasError('chequeNumber') ? formState.errors.chequeNumber[0] : null
                        }
                        label={t('chequeNumber')}
                        name="chequeNumber"
                        onChange = {handleChange}
                        value={formState.values.chequeNumber !== undefined ? formState.values.chequeNumber : ''}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                    />
            </div>
        : null}
        <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSavePayment}
                >
                    {editMode ? "Update" : t('save')}
                </Button>
        </DialogActions>
      </DialogContent>
      </Dialog>
}

export default PaymentDialog