export const initialEnergyValues = ({
    metabolizableEnergy: {
        name: "Metabolizable Energy",
        shortName:'ME',
        value: 0
    },
    protein: {
        name: "Protein",
        shortName: "Pr",
        value: 0
    },
    fat: {
        name: "Fat",
        shortName: "Fa",
        value: 0
    },
    fibre: {
        name: "Fibre",
        shortName: "Fi",
        value: 0
    },
    calcium: {
        name: "Calcium",
        shortName: "Ca",
        value: 0
    },
    phosphorus: {
        name: "Phosphorus",
        shortName: "P",
        value: 0
    },
    lysine: {
        name: "Lysine",
        shortName: "Ly",
        value: 0
    },
    methionine: {
        name: "Methionine",
        shortName: "Me",
        value: 0
    }
})

export const getTotalQuantity = (data) => {
    const totalQuantity =
        data?.reduce((accumulator, currentRow) => accumulator + currentRow.quantity, 0) || 0;
        
    return totalQuantity
}

export const calculateEnergyData = ({data, energyValues, materialNutritionMap}) =>{
   // filter materials that have energy
   const filteredMaterials = data.filter((material) => materialNutritionMap[material.materialTypeID]);

   let newEnergyValues = {...energyValues}
   // calculate the value of each energy item
   Object.keys(newEnergyValues).forEach((energyItem) => {
       let totalEnergyValue = 0.0
       filteredMaterials.forEach((material) => {
           const energyValue = materialNutritionMap[material.materialTypeID][energyItem] || 0;
           totalEnergyValue += energyValue * material.quantity;
       })
       newEnergyValues[energyItem].value = totalEnergyValue;
       
   });
   return newEnergyValues;
}

export function formatEnergyProfileValue(value) {
    // value was too big, so divided by 1000
    const numValue = parseFloat(value/1000);
  
    if (Number.isInteger(numValue)) {
        return numValue.toString();
    } else {
        const twoDecimals = numValue.toFixed(2);
        if (twoDecimals.endsWith('0')) {
            return numValue.toFixed(1);
        } else {
            return twoDecimals;
        }
    }
}