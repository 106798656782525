import React, { useEffect,useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {DialogTitle} from "../../../components";
import makeStyles from '@material-ui/styles/makeStyles';
import useFromValidator from '../../../common/formValidator';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import red from '@material-ui/core/colors/red';
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import MaterialTable from 'material-table';
//import validate from "validate.js";
import { useGlobalState } from '../../../state';
import { version } from '../../../common/enums';
import Link from "@material-ui/core/Link";
import { useHistory } from 'react-router-dom';
import palette from '../../../theme/palettes/default';
import useSearchStyles from "../../../common/searchStyle"
import { getCurrentTimeByAccount } from "../../../common/functions"
import moment from "moment"
var minClosingFeedCheck = 0;

var schema = {
    entryDate: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    shedIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    mortality: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    eggSales: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zeror"
        }
    },
    eggClosingStock: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    eggBreakage: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    brokenEggSales:{
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    brokenDiscardedSales:{
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    labourExpense:{
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    miscExpense:{
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    ebExpense:{
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    miscIncome:{
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    damagedEggs: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    truckBrokenEggs: {
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    truckDamagedEggs: {
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    closingFeed: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    bodyWeight : {
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    eggWeight : {
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    uniformity : {
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    noOfMaleLabours : {
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"No Of Male Labour cannot be negative"
        }
    },
    noOfFemaleLabours : {
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"No Of Female Labour cannot be negative"
        }
    },
    overtimeHours : {
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    feedReceived : {
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    doubleEggs:{
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    },
    dirtyEggs:{
        numericality: {
            greaterThanOrEqualTo: 0,
            message:"must be a positive number or zero"
        }
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(2, 0)
    },
    typo: {
        marginLeft: theme.spacing(0.25),
        marginTop: theme.spacing(-1),
        fontSize: 'initial'
    },
    typoRed: {
        marginLeft: theme.spacing(0.25),
        marginTop: theme.spacing(-1),
        fontSize: 'initial',
        color: red[900]
    },

    requiredField : {
        display:"flex"
    },
    requiedSwitch:{
        marginLeft:theme.spacing(2),
        marginTop:theme.spacing(2),
        fontSize: "initial",
        display:"flex",
        width:"100%"
    },
    checkSwitch : {
        marginTop:"-10px",
    },
    labelField :{
        padding :'2px 10px',
        borderRadius :'12px',
        fontSize:'10px',
        fontWeight :"700" 
    },
    stickyContainer: {
        position: 'fixed',
        zIndex: 9999, 
        width: '100%',
      },
    stickyChip: {
        position: 'sticky',
        zIndex: 1,
    },
}));
const NewDailyEntry = ({ deleteErrorMessage, errorMessage, onEdit, data, onSave, handleClose, showDialog, editMode = false, sheds, materialLists, getMinClosingFeed, minClosingFeed ,getAvailbleMedicineList,medicineList}) => {
    const [{user}] = useGlobalState();
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
     const [compulsoryColSwicth,setCompulsoryColSwitch] = useState(true)
    const history = useHistory();
    const [medcineStockList,setMedicineStockList] =useState([])
    const closeButtonClass = useSearchStyles() 
    const resetBeforeEdit = () => {
        if (data !== undefined) {
            data = {
                ...data,
                shedIndex: sheds.findIndex((shed) => { return shed.id === data.shedID; }),
            }
            if(data.materialTypeID > 0){
                data = {
                    ...data,
                    materialIndex: materialLists.findIndex((materialList) => { return materialList.id === data.materialTypeID; }),
                } 
            }
             checkForOptionalFields();
        }
        setMedicineStockList([])
        console.debug("materialLists", materialLists)
        if (showDialog) {
            //fetch Today Data by Timezone
            getCurrentTimeByAccount().then(dateString => {
                if(dateString && !editMode){
                    const dateParts = dateString.split('T')[0];
                    let entryDate = moment.utc(dateParts); // Parse the date as UTC
                    handleChange({ target: { name: 'entryDate', value: entryDate } }, onDateChange)
                }
            }).catch(err => {
                console.log("error",err)
            })
            updateData(data, editMode);
            if (editMode){
                getAvailbleMedicineList(data.entryDate,data.shedID,data.id)
                setMedicineStockList(data.dailyEntryMedicines)
            }
        }
    };

    const checkForOptionalFields = () => {
        let optionalFields = Object.keys(Object.fromEntries(Object.entries(schema).filter(([key, value]) => !value.presence)));
        for(let i=0;i<=optionalFields.length-1;i++){
            if(data[optionalFields[i]] > 0){
                setCompulsoryColSwitch(false);
                break;
            }
            else{
                setCompulsoryColSwitch(true);
            }
        }
    }   


    useEffect(resetBeforeEdit, [showDialog]);
    useEffect(() => {
        minClosingFeedCheck = minClosingFeed.minClosingFeed
        console.debug("minClosingFeedCheck", minClosingFeedCheck)
    }, [minClosingFeed])

    const onSubmit = () => {
        console.debug(formState.values)
        console.debug(minClosingFeedCheck)
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        var tempData = {
            ...formState.values,
            shedID: sheds[formState.values.shedIndex].id,
            shedName: sheds[formState.values.shedIndex].name,
            dailyEntryMedicines:medcineStockList   
        };

        if (formState.values.materialIndex !== undefined) {
            tempData = {
                ...tempData,
                materialTypeID: materialLists[formState.values.materialIndex].id,
                materialTypeName: materialLists[formState.values.materialIndex].name
            }
        }
        setMedicineStockList([])

        if (editMode)
            onEdit(tempData);
        else
            onSave(tempData);
    };

    const  handleCompFieldChange  = () => {
        setCompulsoryColSwitch(!compulsoryColSwicth)
    }  
    const filteredDropdown = medicineList?.filter(element => !medcineStockList?.some(item => item?.materialID === element?.id))
      const columns = [
        {title:"Id",field:"materialMasterId",hidden:true},
        {title:"medicinestock",field:"materialtypename",hidden:true},
        { title: 'Available Medicine', field: 'materialID',editComponent: props => 
        { 
            return <Select
                    id="materialType-select"
                    value={props.value}
                    onChange={(e) =>{
                        props.onChange(e.target.value)
                      
                    } }
                    required
                  
                     >
                
                { filteredDropdown.map((ele, index) =>(
                   <MenuItem key={ele.materialMasterId} value={ele.id }>
                   <div>{`${ele.materialName} - ${ele.quantity}`}  <span  class={classes.labelField}  style= {{ backgroundColor :`${ele.AvailableType === 1 ? palette.labelBackground.primary : palette.labelBackground.secondary}`}}>{ele.AvailableType === 1 ?  "Storage" : "Purchase" }</span> </div>
                    </MenuItem>
                  ))
                 } 

                  </Select>
        },
        render: rowData => {
            return (
            <span>{medicineList.find((e)=> e.id === rowData?.materialID) ? 
                medicineList.find((e)=> e.id === rowData?.materialID)?.materialName:
                rowData?.materialName}
            </span>
            )
            },
        validate: (rowData) => {
            if (!rowData || !rowData.materialID) {
              return "Medicine must be selected";
            }
            return true; 
          }
    },
        { title: 'Quantity', field: 'quantity',
        validate: (rowData) => {
            const selectedMedicine = medicineList.find(
              (ele) => ele.id === rowData.materialID
            );
            const quantity = Number(rowData.quantity); 
            if (isNaN(quantity)) {
              return 'Quantity must be a number';
            }
    
            if (selectedMedicine && rowData.quantity > selectedMedicine.quantity) {
              return `Quantity should not exceed ${selectedMedicine.quantity}`;
            }
            rowData.quantity = quantity;
    
            return true;
          },
          
    },
      ];
      

    const onShedChange = (event) => {
        schema = {
            entryDate: {
                presence: { allowEmpty: false, message: 'is required' }
            },
            shedIndex: {
                presence: { allowEmpty: false, message: 'is required' }
            },
            mortality: {
                presence: { allowEmpty: false, message: 'is required' },
                numericality: {
                greaterThanOrEqualTo: 0,
                message:"must be a positive number or zero"
            }
            },
            eggSales: {
                presence: { allowEmpty: false, message: 'is required' },
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zeror"
                }
            },
            labourExpense:{
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            miscExpense:{
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            ebExpense:{
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            miscIncome:{
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            brokenEggSales:{
                presence: { allowEmpty: false, message: 'is required' },
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            brokenDiscardedSales:{
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            eggClosingStock: {
                presence: { allowEmpty: false, message: 'is required' },
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            eggBreakage: {
                presence: { allowEmpty: false, message: 'is required' },
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            damagedEggs: {
                presence: { allowEmpty: false, message: 'is required' },
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            truckBrokenEggs: {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            truckDamagedEggs: {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            closingFeed: {
                presence: { allowEmpty: false, message: 'is required' },
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            bodyWeight : {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            eggWeight: {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            uniformity : {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            noOfMaleLabours : {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"No Of Male Labour cannot be negative"
                }
            },
            noOfFemaleLabours : {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"No Of Female Labour cannot be negative"
                }
            },
            overtimeHours : {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            materialIndex: {
                presence: {
                  allowEmpty: false,
                },
                numericality: {
                  greaterThan: -1,
                },
              },
              doubleEggs:{
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            },
            dirtyEggs:{
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message:"must be a positive number or zero"
                }
            }
        }

        //lite version validation
        if (user.versionID === version.lite) {
            schema = {
                ...schema,
                feedReceived : {
                    presence: { allowEmpty: false, message: 'is required',},
                   numericality: {
                       greaterThanOrEqualTo: 0,
                       message:"must be a positive number or zero"
                   }
               },
            }
        } else {
            schema = {
                ...schema,
                feedReceived : {
                   presence: false,
                   numericality: {
                       greaterThanOrEqualTo: 0,
                       message:"must be a positive number or zero"
                   }
               },
            }
        }
        if (sheds[event.target.value].shedTypeName !== 'Growyer' && sheds[event.target.value].shedTypeName !== 'Chick') {
            schema = {
                ...schema,
                materialIndex: {
                    presence: { allowEmpty: false, message: 'is required' }
                },
                eggSales: {
                    presence: { allowEmpty: false, message: 'is required' },
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                },
                labourExpense:{
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                },
                miscExpense:{
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                },
                ebExpense:{
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                },
                miscIncome:{
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                },
                brokenEggSales:{
                    presence: { allowEmpty: false, message: 'is required' },
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                },
                brokenDiscardedSales:{
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                },
                eggClosingStock: {
                    presence: { allowEmpty: false, message: 'is required' },
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                },
                eggBreakage: {
                    presence: { allowEmpty: false, message: 'is required' },
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                },
                damagedEggs: {
                    presence: { allowEmpty: false, message: 'is required' },
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                },
                truckBrokenEggs: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                },
                truckDamagedEggs: {
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                },
                doubleEggs:{
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                },
                dirtyEggs:{
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        message:"must be a positive number or zero"
                    }
                }
            }
            console.debug(schema, "   sheds[event.target.value].shedTypeName,  ", sheds[event.target.value].shedTypeName)
        }
        if (editMode) {
            getAvailbleMedicineList(formState.values.entryDate,sheds[event.target.value].id,data?.id)
            setMedicineStockList([])
        }else{
            getAvailbleMedicineList(formState.values.entryDate,sheds[event.target.value].id,0)
        }
        
        console.debug(sheds, event, formState.values.shedIndex, sheds[event.target.value].id, formState.values.entryDate)
        if(user.versionID !== version.lite){
            getMinClosingFeed(sheds[event.target.value].id, formState.values.entryDate, sheds[event.target.value].name)
        }
    };

    const onDateChange = (event) => {
        setMedicineStockList([])
        if (editMode) {
            getAvailbleMedicineList(event.target.value,sheds[formState.values.shedIndex]?.id,data?.id)
        }else{
            getAvailbleMedicineList(event.target.value,sheds[formState.values.shedIndex]?.id,0)
        }

        if ( user.versionID !== version.lite && formState.values.shedIndex !== undefined && formState.values.shedIndex !== 0) {
            console.debug(sheds, formState.values.shedIndex, sheds[formState.values.shedIndex].id, formState.values.entryDate, event)
            getMinClosingFeed(sheds[formState.values.shedIndex].id,  event.target.value, sheds[formState.values.shedIndex].name)          
        };
    

        
        
        
        
    }

    const onClosingFeedChanges = (event) => {
        console.debug("minClosing ", minClosingFeedCheck, event)
        /*var constraints = {
            closingFeed : {
                presence: {allowEmpty: false, message: 'is required'},
                numericality: {
                    lesserThanOrEqualTo: minClosingFeedCheck
                }
            }
        }
        
        var error = validate({closingFeed:event.target.value},constraints)
        console.debug(constraints,"   ",error)*/
    }

    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title" > 
        <DialogTitle id="form-dialog-title"  >{editMode ? 'Update' : 'New'}  Daily Entry
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          <Grid>
          <div id="error">
          {errorMessage ? 
          <div className={classes.stickyContainer}>
            <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage} 
            className={classes.stickyChip}
          />
         </div>
           : ''}
          </div> 
          <br/>
          </Grid>   
            <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
                
                <div class={classes.requiredField}>
         <Grid  container spacing={2} style={{ marginBottom: '20px' }}>
              <Grid  item xs={6} sm={4} md={4}>
                <DatePicker
                   fullWidth
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Entry Date"
                    format="dd/MM/yyyy"
                    value={formState.values.entryDate || null}
                    onChange={data => {
                        handleChange({ target: { name: 'entryDate', value: data } }, onDateChange)
                    }}
                    animateYearScrolling
                    name="entryDate"
                    style={{backgroundColor:"#ffffff"}}
                />
               </Grid>
               <Grid item xs={6} sm={4} md={4}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel fullWidth id="shedName-select-outlined-label">
                            Shed Name
                        </InputLabel>
                        <Select
                            id="shedName-select"
                            value={formState.values.shedIndex !== undefined ? formState.values.shedIndex : ''}
                            onChange={event => { handleChange(event, onShedChange) }}
                            name="shedIndex"
                        >
                            {sheds !== undefined && sheds !== null ?
                                sheds.map((shed, index) =>{
                                    if( editMode || shed?.isActive){
                                        return <MenuItem key={shed.id} value={index}>{shed.name}</MenuItem>
                                    }
                                    return <></>
                                }   
                                ) : ""}
                        </Select>
                    </FormControl>
                </Grid>
               <Grid item xs={12} sm={4} md={4}fullWidth>  
                <div class={classes.requiedSwitch} >

               <p>Show required fields only</p>

               <div  class={classes.checkSwitch}>
            <Switch                  
             checked={compulsoryColSwicth}
            onChange={()=>handleCompFieldChange()}
             color="primary"
             marginLeft="-20px"
            inputProps={{ 'aria-label': 'primary checkbox' }}
             />
              </div>
               </div>
                </Grid>
              </Grid>  
                </div>

            </MuiPickersUtilsProvider>
            <Grid container spacing={2}>
                
                <Grid item xs={6} sm={4} md={4}>
                    <TextField
                        className={classes.textField}
                        error={hasError('mortality')}
                        fullWidth
                        helperText={
                            hasError('mortality') ? formState.errors.mortality[0] : null
                        }
                        label="Mortality"
                        name="mortality"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.mortality !== undefined ? formState.values.mortality : formState.values.mortality=0}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={4} style={{ marginTop: '10px' }}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="egg-type-select-label" >
                            Egg Type
                        </InputLabel>
                        <Select
                            id="egg-type-select"
                            value={formState.values.materialIndex !== undefined ? formState.values.materialIndex : formState.values.materialIndex=0}
                            onChange={handleChange}
                            name="materialIndex"
                        >
                            {materialLists !== undefined && materialLists !== null ?
                                materialLists.map((materialList, index) =>
                                    <MenuItem key={materialList.id} value={index}>{materialList.name}</MenuItem>
                                ) : ""}
                        </Select>
                        {
                            materialLists.length < 1 &&
                            <Link disabled
                            color="inherit"
                            href="#"
                            onClick={() =>{return  history.push("/material_master")}}
                            className={classes.link}
                            style={{color:"#02a6c7"}}
                            > Add Egg Type</Link>
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={4} >
                    <TextField
                        className={classes.textField}
                        error={hasError('eggClosingStock')}
                        fullWidth
                        helperText={
                            hasError('eggClosingStock') ? formState.errors.eggClosingStock[0] : null
                        }
                        label="Egg Closing Stock(in Trays)"
                        name="eggClosingStock"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.eggClosingStock !== undefined && formState.values.eggClosingStock !== 0 ? formState.values.eggClosingStock : formState.values.eggClosingStock=0}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={4} >
                    <TextField
                        className={classes.textField}
                        error={hasError('eggBreakage')}
                        fullWidth
                        helperText={
                            hasError('eggBreakage') ? formState.errors.eggBreakage[0] : null
                        }
                        label="Cage Broken Eggs"
                        name="eggBreakage"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.eggBreakage !== undefined ? formState.values.eggBreakage :formState.values.eggBreakage=0}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={4} >
                    <TextField
                        className={classes.textField}
                        error={hasError('damagedEggs')}
                        fullWidth
                        helperText={
                            hasError('damagedEggs') ? formState.errors.damagedEggs[0] : null
                        }
                        label="Cage Damaged Eggs"
                        name="damagedEggs"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.damagedEggs !== undefined ? formState.values.damagedEggs : formState.values.damagedEggs=0}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                    />
                </Grid>
               
                {
                      (!compulsoryColSwicth) &&
                      <Grid item xs={6} sm={4} md={4} >
                      <TextField
                      className={classes.textField}
                      error={hasError('truckBrokenEggs')}
                      fullWidth
                      helperText={
                          hasError('truckBrokenEggs') ? formState.errors.truckBrokenEggs[0] : null
                      }
                      label="Truck Broken Eggs"
                      name="truckBrokenEggs"
                      onChange={handleChange}
                      type="number"
                      value={formState.values.truckBrokenEggs !== undefined ? formState.values.truckBrokenEggs : formState.values.truckBrokenEggs=0}
                      variant="outlined"
                      style={{backgroundColor:"#ffffff"}}
                      InputProps={{
                          inputProps: { min: 0 }
                      }}
                  />
                      </Grid>
                }
                {
                    (!compulsoryColSwicth) && 
                    <Grid item xs={6} sm={4} md={4} >
                       <TextField
                       className={classes.textField}
                       error={hasError('truckDamagedEggs')}
                       fullWidth
                       helperText={
                           hasError('truckDamagedEggs') ? formState.errors.truckDamagedEggs[0] : null
                       }
                       label="Truck Damaged Eggs"
                       name="truckDamagedEggs"
                       onChange={handleChange}
                       type="number"
                       value={formState.values.truckDamagedEggs !== undefined ? formState.values.truckDamagedEggs : formState.values.truckDamagedEggs=0}
                       variant="outlined"
                       style={{backgroundColor:"#ffffff"}}
                       InputProps={{
                           inputProps: { min: 0 }
                       }}
                        />
                    </Grid>
                }
               
                
                <Grid item xs={6} sm={4} md={4} >
                    <TextField
                        className={classes.textField}
                        error={hasError('closingFeed')}
                        fullWidth
                        helperText={
                            hasError('closingFeed') ? formState.errors.closingFeed[0] : null
                        }
                        label="Closing Feed"
                        name="closingFeed"
                        onChange={event => { handleChange(event, onClosingFeedChanges) }}
                        type="number"
                        value={formState.values.closingFeed !== undefined ? formState.values.closingFeed : formState.values.closingFeed=0}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                    />
                </Grid>
                {
                      (!compulsoryColSwicth) &&
                      <Grid item xs={6} sm={4} md={4} >
                      <TextField
                      className={classes.textField}
                      error={hasError('doubleEggs')}
                      fullWidth
                      helperText={
                          hasError('doubleEggs') ? formState.errors.doubleEggs[0] : null
                      }
                      label="Double Eggs"
                      name="doubleEggs"
                      onChange={handleChange}
                      type="number"
                      value={formState.values.doubleEggs !== undefined ? formState.values.doubleEggs : formState.values.doubleEggs=0}
                      variant="outlined"
                      style={{backgroundColor:"#ffffff"}}
                      InputProps={{
                          inputProps: { min: 0 }
                      }}
                  />
                      </Grid>
                }
                  {
                      (!compulsoryColSwicth) &&
                      <Grid item xs={6} sm={4} md={4} >
                      <TextField
                      className={classes.textField}
                      error={hasError('dirtyEggs')}
                      fullWidth
                      helperText={
                          hasError('dirtyEggs') ? formState.errors.dirtyEggs[0] : null
                      }
                      label="Dirty Eggs"
                      name="dirtyEggs"
                      onChange={handleChange}
                      type="number"
                      value={formState.values.dirtyEggs !== undefined ? formState.values.dirtyEggs : formState.values.dirtyEggs=0}
                      variant="outlined"
                      style={{backgroundColor:"#ffffff"}}
                      InputProps={{
                          inputProps: { min: 0 }
                      }}
                  />
                      </Grid>
                }
                <Grid item xs={6} sm={4} md={4} >
                    {
                        (!compulsoryColSwicth) &&
                        <TextField
                            className={classes.textField}
                            error={hasError('bodyWeight')}
                            fullWidth
                            helperText={
                                hasError('bodyWeight') ? formState.errors.bodyWeight[0] : null
                            }
                            label="Avg. Body weight"
                            name="bodyWeight"
                            onChange={handleChange}
                            type="number"
                            value={formState.values.bodyWeight !== undefined ? formState.values.bodyWeight : formState.values.bodyWeight=0}
                            variant="outlined"
                            style={{backgroundColor:"#ffffff"}}
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                        />
                    }
                </Grid>
                <Grid item xs={6} sm={4} md={4} >
                    {
                        (!compulsoryColSwicth) &&
                        <TextField
                            className={classes.textField}
                            error={hasError('eggWeight')}
                            fullWidth
                            helperText={
                                hasError('eggWeight') ? formState.errors.eggWeight[0] : null
                            }
                            label="Avg. Egg weight"
                            name="eggWeight"
                            onChange={handleChange}
                            type="number"
                            value={formState.values.eggWeight !== undefined ? formState.values.eggWeight : formState.values.eggWeight=0}
                            variant="outlined"
                            style={{backgroundColor:"#ffffff"}}
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                        />
                    }
                </Grid>
                <Grid item xs={6} sm={4} md={4} >
                    {
                     (!compulsoryColSwicth) &&
                        <TextField
                            className={classes.textField}
                            error={hasError('uniformity')}
                            fullWidth
                            helperText={
                                hasError('uniformity') ? formState.errors.uniformity[0] : null
                            }
                            label="Uniformity"
                            name="uniformity"
                            onChange={handleChange}
                            type="number"
                            value={formState.values.uniformity !== undefined ? formState.values.uniformity : formState.values.uniformity=0}
                            variant="outlined"
                            style={{backgroundColor:"#ffffff"}}
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                        />
                    }
                </Grid>
                <Grid item xs={6} sm={4} md={4} >
                    {
                        (user.versionID === version.lite) &&
                        <TextField
                        className={classes.textField}
                        error={hasError('feedReceived')}
                        fullWidth
                        helperText={
                            hasError('feedReceived') ? formState.errors.feedReceived[0] : null
                        }
                        label="Feed Received (in Kgs)"
                        name="feedReceived"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.feedReceived !== undefined ? formState.values.feedReceived : formState.values.feedReceived=0}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                        />
                    }
                </Grid>
              </Grid>
              {
               user.versionID !== version.lite && minClosingFeed.minClosingFeed !== undefined && minClosingFeed.minClosingFeed !== 0 ?
                    <Typography className={formState.values.closingFeed > minClosingFeed.minClosingFeed ? classes.typoRed : classes.typo}>
                        Closing Feed Should be less than or equal to {minClosingFeed.minClosingFeed}
                    </Typography>
                    :
                    ""
            }
             
              <Typography display="block" variant="h3" gutterBottom>
              Sales
              </Typography>
              <Grid container spacing={2}>           
     
                <Grid item xs={6} sm={4} md={4}>
                    <TextField
                        className={classes.textField}
                        error={hasError('eggSales')}
                        fullWidth
                        helperText={
                            hasError('eggSales') ? formState.errors.eggSales[0] : null
                        }
                        label="Egg Sales(in Trays)"
                        name="eggSales"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.eggSales !== undefined ? formState.values.eggSales :formState.values.eggSales=0}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        error={hasError('brokenEggSales')}
                        helperText={
                            hasError('brokenEggSales') ? formState.errors.brokenEggSales[0] : null
                        }
                        label="Broken Eggs"
                        name="brokenEggSales"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.brokenEggSales !== undefined ? formState.values.brokenEggSales :  formState.values.brokenEggSales=0}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                {
                     (!compulsoryColSwicth) &&
                     
                     
                     <TextField
                         className={classes.textField}
                         fullWidth
                         error={hasError('brokenDiscardedSales')}
                         helperText={
                             hasError('brokenDiscardedSales') ? formState.errors.brokenDiscardedSales[0] : null
                         }
                         label="Discarded Broken Egg"
                         name="brokenDiscardedSales"
                         onChange={handleChange}
                         type="number"
                         value={formState.values.brokenDiscardedSales !== undefined ? formState.values.brokenDiscardedSales : formState.values.brokenDiscardedSales=0}
                         variant="outlined"
                         style={{backgroundColor:"#ffffff"}}
                         InputProps={{
                             inputProps: { min: 0 }
                         }}
                     />
                     
                     }
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                {
                     (!compulsoryColSwicth) &&
                     
                     
                     <TextField
                         className={classes.textField}
                         fullWidth
                         error={hasError('eggStorage')}
                         helperText={
                             hasError('eggStorage') ? formState.errors.eggStorage[0] : null
                         }
                         label="Egg Storage"
                         name="eggStorage"
                         onChange={handleChange}
                         type="number"
                         value={formState.values.eggStorage !== undefined ? formState.values.eggStorage : formState.values.eggStorage=0}
                         variant="outlined"
                         style={{backgroundColor:"#ffffff"}}
                         InputProps={{
                             inputProps: { min: 0 }
                         }}
                     />
                     
                     }
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                {
                     (!compulsoryColSwicth) &&
                     
                     
                     <TextField
                         className={classes.textField}
                         fullWidth
                         error={hasError('eggStorageRate')}
                         helperText={
                             hasError('eggStorageRate') ? formState.errors.eggStorageRate[0] : null
                         }
                         label="Egg Storage Rate/Egg"
                         name="eggStorageRate"
                         onChange={handleChange}
                         type="number"
                         value={formState.values.eggStorageRate !== undefined ? formState.values.eggStorageRate : formState.values.eggStorageRate=0}
                         variant="outlined"
                         style={{backgroundColor:"#ffffff"}}
                         InputProps={{
                             inputProps: { min: 0 }
                         }}
                     />
                     
                     }
                </Grid>
                
              </Grid>
              <Grid>              
             {
                  (!compulsoryColSwicth) &&
                  <Typography display="block" variant="h3" gutterBottom>
                  Labours
                        </Typography>
             }
            
               <Grid container spacing={2} >
               <Grid item xs={6} sm={4} md={4}>
                    { 
                    (!compulsoryColSwicth) &&
                        <TextField
                            className={classes.textField}
                            error={hasError('noOfMaleLabours')}
                            fullWidth
                            helperText={
                                hasError('noOfMaleLabours') ? formState.errors.noOfMaleLabours[0] : null
                            }
                            label="No Of Male Labours"
                            name="noOfMaleLabours"
                            onChange={handleChange}
                            type="number"
                            value={formState.values.noOfMaleLabours !== undefined ? formState.values.noOfMaleLabours : formState.values.noOfMaleLabours=0}
                            variant="outlined"
                            style={{backgroundColor:"#ffffff"}}
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                        />
                    }
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                    { 
                    (!compulsoryColSwicth) &&
                        <TextField
                            className={classes.textField}
                            error={hasError('noOfFemaleLabours')}
                            fullWidth
                            helperText={
                                hasError('noOfFemaleLabours') ? formState.errors.noOfFemaleLabours[0] : null
                            }
                            label="No Of Female Labours"
                            name="noOfFemaleLabours"
                            onChange={handleChange}
                            type="number"
                            value={formState.values.noOfFemaleLabours !== undefined ? formState.values.noOfFemaleLabours : formState.values.noOfFemaleLabours=0}
                            variant="outlined"
                            style={{backgroundColor:"#ffffff"}}
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                        />
                    }
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                    { 
                     (!compulsoryColSwicth) &&
                        <TextField
                            className={classes.textField}
                            error={hasError('overtimeHours')}
                            fullWidth
                            helperText={
                                hasError('overtimeHours') ? formState.errors.overtimeHours[0] : null
                            }
                            label="Overtime Hours"
                            name="overtimeHours"
                            onChange={handleChange}
                            type="number"
                            value={formState.values.overtimeHours !== undefined ? formState.values.overtimeHours :formState.values.overtimeHours=0}
                            variant="outlined"
                            style={{backgroundColor:"#ffffff"}}
                            InputProps={{
                                inputProps: { min: 0.1 }
                            }}
                        />
                    }
                </Grid>
               </Grid>
               {
               (!compulsoryColSwicth) &&
                <>
               <Typography display="block" variant="h3" gutterBottom>
               Expenses
                    </Typography>
              <Grid container spacing={2}>           
     
                <Grid item xs={6} sm={4} md={4}>
                <TextField
                        className={classes.textField}
                        error={hasError('labourExpense')}
                        helperText={
                            hasError('labourExpense') ? formState.errors.labourExpense[0] : null
                        }
                        fullWidth
                        label="Labour"
                        name="labourExpense"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.labourExpense !== undefined ? formState.values.labourExpense : formState.values.labourExpense=0}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        error={hasError('ebExpense')}
                        helperText={
                            hasError('ebExpense') ? formState.errors.ebExpense[0] : null
                        }
                        label="EB"
                        name="ebExpense"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.ebExpense !== undefined ? formState.values.ebExpense :  formState.values.ebExpense =0}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        error={hasError('miscExpense')}
                        helperText={
                            hasError('miscExpense') ? formState.errors.miscExpense[0] : null
                        }
                        label="Misc"
                        name="miscExpense"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.miscExpense  !== undefined ? formState.values.miscExpense :formState.values.miscExpense =0}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                    />
                </Grid>
              </Grid>
            
              <Typography display="block" variant="h3" gutterBottom>
              Income
                    </Typography>
              <Grid container spacing={2}>           
     
                <Grid item xs={6} sm={4} md={4}>
                <TextField
                        className={classes.textField}
                        fullWidth
                        error={hasError('miscIncome')}
                        helperText={
                            hasError('miscIncome') ? formState.errors.miscIncome[0] : null
                        }
                        label="Misc"
                        name="miscIncome"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.miscIncome  !== undefined ? formState.values.miscIncome :formState.values.miscIncome =0}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                    />
                </Grid>
             
              </Grid>
               
              <MaterialTable
                   title="Medicine"
                            columns={columns}
                              data={medcineStockList || []}
                              editable={{
                                onRowAdd: 
                                (newData) =>
                                    new Promise((resolve, reject) => {                                 
                                            medicineList.forEach((ele, index) => {
                                                if (ele.id === newData.materialID) {
                                                  const newMedicineData = {
                                                    materialMasterId: ele.materialMasterId,
                                                    materialName:ele.materialName,
                                                    AvailableType:ele.AvailableType,
                                                    ...newData
                                                  };
                                            
                                                if (medcineStockList) {
                                                  setMedicineStockList([...medcineStockList, newMedicineData]);
                                                  resolve()
                                                }else {
                                                    setMedicineStockList([ newMedicineData]);
                                                }
                                                resolve()
                                                 
                                                }
                                              })                                   
                                    }),
                                onRowUpdate: (newData, oldData) =>
                                     new Promise((resolve) => { 

                                                medicineList.forEach((ele,index)=>{
                                                    if(ele.id === newData.materialID){
                                                        const newMedicineData = {
                                                            ...newData,
                                                            materialMasterId: ele.materialMasterId,
                                                            materialName:ele.materialName,
                                                            AvailableType:ele.AvailableType,
                                                          
                                                          }
                                                         
                                                          if(newMedicineData.materialMasterId){
                                                            if (oldData) {
                                                                const updatedData = [...medcineStockList];
                                                                const index = medcineStockList.indexOf(oldData);
                                                                if (index !== -1) {
                                                                  updatedData[index] = newMedicineData;
                                                                  setMedicineStockList(updatedData);
                                                                
                                                                  resolve();
                                                                }
                                                              }
                                                     }

                                                    }
                                                   
                                                })
                                      }),
                                 onRowDelete: (oldData) =>
                                      new Promise((resolve) => {
                                      const deleteData=[...medcineStockList]
                                       const updateDeletedData = deleteData.filter((item) => item.materialID !== oldData.materialID);
                                       setMedicineStockList(updateDeletedData);
                                        resolve();
                                    }),
                                  }}
                                 options={{
                                         actionsColumnIndex: -1, 
                                         addRowPosition: filteredDropdown?.length === 0 ? 'none' : 'first',
                    }}
                />
                </>
               }
     
            </Grid>
            <DialogActions>
                <Button
                    className={closeButtonClass.close}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleClose}
                >
                    Close
                </Button>
                <Button
                    className={classes.button}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>

};

export default NewDailyEntry;