import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import LineSaleEntry from "./components/lineSaleEntry";
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import PaymentIcon from '@material-ui/icons/Payment';
import LineSaleEntryPaymentDetails from "./components/lineSaleEntryPaymentDetails";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import {useGlobalState} from '../../state';


const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    gridMargin: {
        margin: theme.spacing(1.5)
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        minWidth: 160,
    },
    redChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#f44336',
        backgroundColor: 'rgba(244, 67, 54, 0.08)',
    },
    greenChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#4caf50',
        backgroundColor: 'rgba(76, 175, 80, 0.08)'
    },
    searchButton: {
        color: 'white',
        width: theme.spacing(21.25),
        backgroundColor: '#EF5350',
        '&:hover': {
            backgroundColor: '#E53935',
        },
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom: theme.spacing(4)
    },
    success: {
        color: 'white',
        backgroundColor: '#4CAF50',
        '&:hover': {
            backgroundColor: '#388E3C',
        },
        width: theme.spacing(13.75),
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom: theme.spacing(4)
    },
    red: {
        color: 'red'
    },
    green: {
        color: 'green'
    },

}));

let currentLineSaleEntry = undefined;
let editMode = false;

const LineSaleEntriesView = ({ showError, showMessage, history }) => {

    const [lineSaleEntries, setLineSaleEntries] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [lineNames, setLineNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showLineSaleEntryDialog, setShowLineSaleEntryDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showLineSaleEntryPaymentDialog, setShowLineSaleEntryPaymentDialog] = useState(false);
    const [currentLineSaleEntryPayment, setCurrentLineSaleEntryPayment] = useState({});
    const [banks, setBanks] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')


    const theme = useTheme();

    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    function onNewLineSaleEntry(event) {
        editMode = false;
        event.preventDefault();
        setShowLineSaleEntryDialog(true);
    }

    function handleLineSaleEntryClose() {
        currentLineSaleEntry = undefined;
        setShowLineSaleEntryDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const onLineSaleEntryDeleteConfirm = () => {
        if (currentLineSaleEntry && currentLineSaleEntry.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`line-sale-entries/${currentLineSaleEntry.id}`);

            response.then(res => {
                if (res) {
                    let index = lineSaleEntries.findIndex((srcLineSaleEntry) => {
                        return srcLineSaleEntry.id === currentLineSaleEntry.id;
                    });
                    let newLineSaleEntries = [...lineSaleEntries];
                    newLineSaleEntries.splice(index, 1);
                    setLineSaleEntries(newLineSaleEntries);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('LineSaleEntry not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentLineSaleEntry = undefined;
            });

        }
    };

    const onDelete = (lineSaleEntry) => {
        currentLineSaleEntry = lineSaleEntry;
        setShowDeleteConfirm(true);
    };

    const onLineSaleEntrySave = (lineSaleEntry) => {
        setLoading(true);
        // showError('');
        showMessage('');
        const response = api.post('line-sale-entries', {
            ...lineSaleEntry,
        });

        response.then(res => {

            setLineSaleEntries([...lineSaleEntries, res]);
            setShowLineSaleEntryDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('LineSaleEntry not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };


    const onLineSaleEntryEdit = (lineSaleEntry) => {
        currentLineSaleEntry = {
            ...lineSaleEntry,
            lineSaleIndex: lineNames.findIndex((lineSale) => { return lineSale.id === lineSaleEntry.lineSaleID; }),
            materialNameIndex: materials.findIndex((material) => { return material.materialId === lineSaleEntry.materialNameID; }),
            customerIndex: customers.findIndex((customer) => { return customer.id === lineSaleEntry.customerID; })
        };

        editMode = true;
        setShowLineSaleEntryDialog(true);
    };

    const onLineSaleEntryUpdate = (lineSaleEntry) => {
        setLoading(true);
        // showError('');
        console.debug(lineSaleEntry, "lineSaleEntry")
        lineSaleEntry.salePrice = parseInt(lineSaleEntry.salePrice)
        const response = api.post(`line-sale-entries/${lineSaleEntry.id}`, {
            ...lineSaleEntry,

        });
        response.then(() => {
            let index = lineSaleEntries.findIndex((srcLineSaleEntry) => {
                return srcLineSaleEntry.id === lineSaleEntry.id;
            });

            setLineSaleEntries([...lineSaleEntries.slice(0, index),
            { ...lineSaleEntry },
            ...lineSaleEntries.slice(index + 1)]);
            setShowLineSaleEntryDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('LineSaleEntry not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    //Get all LineSaleEntries API Call
    const getAllLineSaleEntries = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('line-sale-entries');

        response.then(res => {
            setLineSaleEntries([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    //Get all Customers API Call
    const getAllCustomers = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('get-line-customers');

        response.then(res => {
            setCustomers([{
                name: "Other",
                id: 0
            },
            ...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    //Get all materials API Call
    const getAllMaterials = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('egg-materials');

        response.then(res => {
            setMaterials([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    //Get all IncomeType API Call
    const getAllLineSales = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('line_name');

        response.then(res => {
            setLineNames([...res]
            );

        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    function handleLineSaleEntryPaymentClose() {
        currentLineSaleEntry = undefined;
        setShowLineSaleEntryPaymentDialog(false);
    }

    const onLineSaleEntryPaymentUpdate = (payment, lineSaleEntry) => {
        //setShowLineSaleEntryPaymentDialog(false);
        setLoading(true);
        showError('');
        const response = api.post(`line-sale-entries-payment/${payment.id}`, {
            ...payment,

        });
        response.then(() => {
            let index = lineSaleEntries.findIndex((srcLineSaleEntry) => {
                return srcLineSaleEntry.id === lineSaleEntry.id;
            });

            let totalAmount = 0
            lineSaleEntry.lineSaleEntriesPayments.forEach((payment, index) => {
                totalAmount = totalAmount + payment.paymentAmount
            })
            if (totalAmount === 0) {
                lineSaleEntry = {
                    ...lineSaleEntry,
                    paymentStatus: 0
                }
            } else if (totalAmount >= lineSaleEntry.totalAmount) {
                lineSaleEntry = {
                    ...lineSaleEntry,
                    paymentStatus: 2
                }
            } else if (totalAmount < lineSaleEntry.totalAmount) {
                lineSaleEntry = {
                    ...lineSaleEntry,
                    paymentStatus: 1
                }
            }
            lineSaleEntry = {
                ...lineSaleEntry,
                remainingBalance: totalAmount
            }
            setLineSaleEntries([...lineSaleEntries.slice(0, index),
            { ...lineSaleEntry },
            ...lineSaleEntries.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('LineSaleEntry Payment not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onLineSaleEntryPaymentSave = (payment) => {
        //setShowLineSaleEntryPaymentDialog(false);
        setLoading(true);
        showError('');
        const response = api.post(`line-sale-entries-payment`, {
            ...payment,
        });
        response.then((res) => {

            let tempCurrentLineSaleEntry = currentLineSaleEntryPayment
            if (tempCurrentLineSaleEntry.lineSaleEntriesPayments !== undefined && tempCurrentLineSaleEntry.lineSaleEntriesPayments !== null) {
                tempCurrentLineSaleEntry.lineSaleEntriesPayments = [
                    ...tempCurrentLineSaleEntry.lineSaleEntriesPayments,
                    res
                ]
            } else {
                tempCurrentLineSaleEntry.lineSaleEntriesPayments = [
                    res
                ]
            }
            setCurrentLineSaleEntryPayment({ ...tempCurrentLineSaleEntry })

            let totalAmount = 0
            tempCurrentLineSaleEntry.lineSaleEntriesPayments.forEach((payment, index) => {
                totalAmount = totalAmount + payment.paymentAmount
            })
            if (totalAmount === 0) {
                tempCurrentLineSaleEntry = {
                    ...tempCurrentLineSaleEntry,
                    paymentStatus: 0
                }
            } else if (totalAmount >= tempCurrentLineSaleEntry.totalAmount) {
                tempCurrentLineSaleEntry = {
                    ...tempCurrentLineSaleEntry,
                    paymentStatus: 2
                }
            } else if (totalAmount < tempCurrentLineSaleEntry.totalAmount) {
                tempCurrentLineSaleEntry = {
                    ...tempCurrentLineSaleEntry,
                    paymentStatus: 1
                }
            }
            tempCurrentLineSaleEntry = {
                ...tempCurrentLineSaleEntry,
                remainingBalance: totalAmount
            }


            let index = lineSaleEntries.findIndex((srcLineSaleEntry) => {
                return srcLineSaleEntry.id === tempCurrentLineSaleEntry.id;
            });
            setLineSaleEntries([...lineSaleEntries.slice(0, index),
            { ...tempCurrentLineSaleEntry },
            ...lineSaleEntries.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('LineSaleEntry not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onLineSaleEntryPaymentDelete = (payment, lineSaleEntry) => {
        if (payment && payment.id) {
            //setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.post(`line-sale-entries-payment-delete/${payment.id}`, {
                ...payment,
            });

            response.then(res => {
                if (res) {
                    let index = lineSaleEntries.findIndex((srcLineSaleEntry) => {
                        return srcLineSaleEntry.id === lineSaleEntry.id;
                    });

                    let totalAmount = 0
                    lineSaleEntry.lineSaleEntriesPayments.forEach((payment, index) => {
                        totalAmount = totalAmount + payment.paymentAmount
                    })
                    if (totalAmount === 0) {
                        lineSaleEntry = {
                            ...lineSaleEntry,
                            paymentStatus: 0
                        }
                    } else if (totalAmount >= lineSaleEntry.totalAmount) {
                        lineSaleEntry = {
                            ...lineSaleEntry,
                            paymentStatus: 2
                        }
                    } else if (totalAmount < lineSaleEntry.totalAmount) {
                        lineSaleEntry = {
                            ...lineSaleEntry,
                            paymentStatus: 1
                        }
                    }

                    setLineSaleEntries([...lineSaleEntries.slice(0, index),
                    { ...lineSaleEntry },
                    ...lineSaleEntries.slice(index + 1)]);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('LineSaleEntry not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentLineSaleEntry = undefined;
            });

        }
    };

    //Get all Banks API Call
    const getAllBanks = () => {
        showError('');
        showMessage('');

        const response = api.get('bank');

        response.then(res => {
            setBanks([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }
    


    const onload = () => {
        getAllCustomers()
        getAllMaterials()
        getAllLineSales()
        getAllLineSaleEntries()
        getAllBanks()

    }
    useEffect(onload, []);

    const onPaymentEdit = (lineSaleEntry) => {
        setCurrentLineSaleEntryPayment({
            ...lineSaleEntry,
        });
        setShowLineSaleEntryPaymentDialog(true);
    };


    let actions = [
        {
            icon: () => <PaymentIcon color='error'>payment</PaymentIcon>,
            tooltip: 'Payment Details',
            onClick: (event, rowData) => onPaymentEdit(rowData)
        },

        {
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit lineSaleEntry Details',
            onClick: (event, rowData) => onLineSaleEntryEdit(rowData)
        },
        {
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete lineSaleEntry Details',
            onClick: (event, rowData) => onDelete(rowData)
        }
    ]


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
        <Grid container
            spacing={3}
            justify="flex-end"
            alignItems="center"
        >
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewLineSaleEntry}>Add Line Sale Entry</Button>
            </Grid>
        </Grid>
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Line Sale Entries
                </Typography>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'ID', field: 'id', hidden: false },
                { title: 'Date', field: 'date', hidden: !isDesktop, editable: 'never',
                    render: rowData => {
                        return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                    } 
                },
                { title: 'Line Name', field: 'lineSaleName', hidden: !isDesktop, editable: 'never' },
                { title: 'Line Customer Name', field: 'customerName', hidden: !isDesktop, editable: 'never' },
                { title: 'Egg Type', field: 'materialName', hidden: !isDesktop, editable: 'never' },
                { title: 'Quantity', field: 'quantity', hidden: !isDesktop, editable: 'never' },
                { title: 'Sale Price', field: 'salePrice', hidden: !isDesktop, editable: 'never',
                    render: rowData => {
                        return <span>{(rowData.salePrice).toLocaleString(user.locales,amountObj)}</span>
                    } 
                },
                {
                    title: 'Payment Status', field: 'paymentStatus', editable: 'never',
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    render: (rowData) => {
                        let statusIcon = <Tooltip title="Not Paid"><CloseIcon className={classes.red} /></Tooltip>
                        if (rowData.paymentStatus === 1) {
                            statusIcon = <Tooltip title="Partially Paid"><QueryBuilderIcon /></Tooltip>
                        } else if (rowData.paymentStatus === 2) {
                            statusIcon = <Tooltip title="Paid"><DoneIcon className={classes.green} /></Tooltip>
                        }
                        return <span>{statusIcon}</span>
                    }
                }

            ]}
            data={lineSaleEntries}
            title='Line Sale Entries Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,

                toolbar: true
            }}
            actions={actions}
        />
        <LineSaleEntry deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showLineSaleEntryDialog} 
            handleClose={handleLineSaleEntryClose} onSave={onLineSaleEntrySave} onEdit={onLineSaleEntryUpdate}
            editMode={editMode} data={currentLineSaleEntry} materials={materials} customers={customers} 
            lineNames={lineNames} />

        <LineSaleEntryPaymentDetails showPaymentDetailsDialog={showLineSaleEntryPaymentDialog} 
            handlePaymentDetailsClose={handleLineSaleEntryPaymentClose}
            onPaymentEdit={onLineSaleEntryPaymentUpdate} data={currentLineSaleEntryPayment} banks={banks} 
            onLineSaleEntryPaymentSave={onLineSaleEntryPaymentSave}
            onLineSaleEntryPaymentDelete={onLineSaleEntryPaymentDelete} loading={loading} />

        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onLineSaleEntryDeleteConfirm}
            title='Delete Line Sale Entry ?'
            subText='This action will delete the Line Sale Entry Details. Please confirm.' />
    </div>
}

export default LineSaleEntriesView;