
import React from 'react';
import {  MaterialTable } from '../../../components';
import {useGlobalState} from '../../../state';


import moment from 'moment';
export default function SaleMaterial({
    saleMaterials,
    actions,
    classes
}){
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }

    const fSaleMaterial = formatSaleMaterials();

    function formatSaleMaterials(){
        let tArray = [];
        for(var i in saleMaterials){
            var item = saleMaterials[i];
            var nObj = {
                date : item.date,
                ...item,
                ...item.saleMaterials[0] //Only one material per array in pro version
            };
            nObj['sID'] =  nObj.id; // temp sale `id`
            nObj['id'] = nObj.saleID;
            tArray.push(nObj);
        }
        return tArray;
    }


    

    return(
        <MaterialTable
                columns={[
                    { title: 'id', field: 'id', hidden: true },
                    { title: 'No', field: 'displayId', defaultSort: 'desc' },
                    {
                    title: 'Date', field: 'date', type: 'datetime',
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    editable: 'never',
                    render: rowData => {
                        return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                    }
                    },
                    {
                        title: 'Item Name', field: 'materialName', editable: 'never',
                        render: (rowData) => { return <span>{rowData.materialName}</span> }
                    },
                    {
                        title: 'Inventory Type', field: 'inventoryType', editable: 'never',
                        render: (rowData) => {
                            return <span>{
                                rowData.inventoryType === 1 ? 'Feed Mill'
                                    : (rowData.farmName.String + ' (' + rowData.shedName.String + ')')
                            }
                            </span>
                        }
                    },

                    {
                        title: 'Total amount', field: 'billValue', editable: 'never',
                        render: (rowData) => {
                            return <span>{(rowData.billValue).toLocaleString(user.locales,amountObj)}</span>
                        },
                    },
                ]}
                
                data={fSaleMaterial || []}
                options={{
                    actionsColumnIndex: -1,
                    search: true,
                    showTitle: true,
    
                    toolbar: true,
                }}
                //style={{boxShadow: 'unset'}}
                actions={actions}
                title='Sales Table'
            />
    );
};