import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

const useCardStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    cardActionsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    cardAction:{
        margin:theme.spacing(0),
        marginTop:theme.spacing(2),
        backgroundColor : '#ff1744',
        color : 'white'
    }
}));

const Mobile = ({breed,onDelete,onEdit,openVacSchedule,openStdProduction}) => {
    const classes = useCardStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        onDelete();
    };

    const handleEdit = () => {
        setAnchorEl(null);
        onEdit();


    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    return <Grid item>
        <Card className={classes.card}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                }
                title={breed.name}
                subheader='breed name'
            />
            <CardContent>
                <Grid
                    container
                    justify='space-between'
                    alignItems='flex-end'
                >
                    {/*<Grid item>
                        <Typography variant="h5" component="h2">
                            {breed.stdProduction}
                        </Typography>
                        <Typography color="textSecondary">
                            Std Production %
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" component="h2">
                            Weight
                        </Typography>
                        <Typography color="textSecondary">
                            {breed.weight}
                        </Typography>
                    </Grid>*/}
                </Grid>
            </CardContent>
            <CardActions className={classes.cardActionsContainer}>
                <Button onClick={openVacSchedule} size="large" color="primary" variant="contained">Vaccination Schedule</Button>
                <Button className={classes.cardAction} onClick={openStdProduction} size="large"  variant="contained">Breed Standard</Button>
            </CardActions>
        </Card>
        <Menu
            id="breeds-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
    </Grid>
};

export default Mobile;