import React, {useState, useEffect} from "react";
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/**
 * metrics: kgs, unit, trays, litres
 * 
 * inventory type: Feed Mill & Farm
 * 
 * Farm => farm, shed dropdown, storage check box
 * Feed Mill => doesn't need any other additional html elements
 * 
 * Note: cost per unit && GST is required for all types of metrics
 * 
 * kgs => display weight type drop down, text fields includes (Bill weight, delivery weight)
 * litres & units & trays => display quantity
 * 
 * based on whether the inventory type, certain html elements will be displayed
 */

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
    },
    formControl: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        minWidth: 160,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    datePicker: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth: 160,
    },
    dialogWidth: {
        minWidth: "1042px"
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },
}));

let schema = {
    materialTypeIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    materialIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },

    weightType: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    billWeight: {
        presence: { allowEmpty: false, message: 'is required' },
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    deliveryWeight: {
        presence: { allowEmpty: false, message: 'is required' },
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    farmIndex: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    shedIndex: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    storage: {
        presence: false,
    },
    quantity: {
        presence: { allowEmpty: false, message: 'is required' },
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    costPerUnit: {
        presence: { allowEmpty: false, message: 'is required' },
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    inventoryType: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    gstTaxProfileIndex: {
        presence: false,
    }
}

const presenceTrue = {
    presence: { allowEmpty: false, message: 'is required' },
}

const presenceFalse = {
    presence: false
}

const NewMaterial = ({editMode, showDialog, handleClose, materialTypes, materialNames, onGetMaterialNames, farms, sheds, onGetShed, gstTaxProfiles, data, onSave, onEdit, materialIndex}) => {

    const classes = useStyles();
    const history = useHistory();
    const [formState, hasError, handleChange, updateData, setFormState] = useFromValidator(schema);

    const [showMaterialName, setShowMaterialNames] = useState(false);
    const [showWeightType, setShowWeightType] = useState(false);
    const [showFarm, setShowFarm] = useState(false);
    const [showShed, setShowShed] = useState(false);
    const [showStorageCheckbox, setShowStorageCheckbox] = useState(false);
    const [storageChecked, setStorageChecked] = useState(false);
    const [showQuantity, setShowQuantity] = useState(false)

    const [showWeightDialog, setShowWeightDialog] = useState(false);

    useEffect(() => {
        if (showDialog) {
            if (editMode) {
                updateData(data, editMode)
                const unitID = materialNames[data.materialIndex].unitID
                const inventoryType = materialNames[data.materialIndex].inventoryType
                handleAllDropdown(editMode, unitID, inventoryType)

                if (inventoryType === 2 && data.storage === 1) {
                    setStorageChecked(true)
                    setShowShed(false)
                } else if (inventoryType === 2 ) {
                    setStorageChecked(false)
                    setShowShed(true)
                }
            } else {
                setFormState({
                    ...formState,
                    values: {
                        ...formState.values,
                        materialTypeIndex: undefined,
                        materialIndex: undefined,
                        weightType: undefined,
                        billWeight: undefined,
                        deliveryWeight: undefined,
                        farmIndex: undefined,
                        shedIndex: undefined,
                        quantity: undefined,
                        costPerUnit: undefined,
                        inventoryType: undefined,
                        storage: undefined,
                        gstTaxProfileIndex: undefined,
                    }
                })
                setStorageChecked(false)
                setShowMaterialNames(false)
                setShowWeightType(false)
                setShowQuantity(false)
                setShowShed(false)
                setShowFarm(false)
                setShowStorageCheckbox(false)
                updateData(data, editMode)
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDialog])

    const handleAllDropdown = (editMode, unitID, inventoryType) => {

        /* Inventory type
            1 - Feed Mill
            2 - Farm
        */

        setShowMaterialNames(true)
        if (unitID === 2 && inventoryType === 1) { // kgs
            /* unitID = 2 is kgs
                show - weight type, bill weight and delivery weight
                hide - farm, shed, storage and quantity
            */

            setShowWeightType(true)

            setShowFarm(false)
            setShowShed(false)
            setShowQuantity(false)
            setShowStorageCheckbox(false)
            schema = {
                ...schema,
                weightType: presenceTrue,
                billWeight: presenceTrue,
                deliveryWeight: presenceTrue,

                quantity: presenceFalse,
                storage: presenceFalse,
                farmIndex: presenceFalse,
                shedIndex: presenceFalse
            }
        } else if (unitID === 2 && inventoryType === 2) { // kgs
        
            setShowWeightType(true)

            setShowFarm(true)
            setShowShed(true)
            setShowQuantity(false)
            setShowStorageCheckbox(true)
            schema = {
                ...schema,
                weightType: presenceTrue,
                billWeight: presenceTrue,
                deliveryWeight: presenceTrue,

                quantity: presenceFalse,
                storage: presenceTrue,
                farmIndex: presenceTrue,
                shedIndex: presenceTrue
            }
        } else {
            /* 
                unitID = 1 is trays and unitID = 3 is units and unitID = 4 is litres
                    show - farm (changing farm will show shed and storage)
                    hide - weight type, bill weight and delivery weight
            */
            setShowFarm(true)
            setShowQuantity(true)

            setShowWeightType(false)
            schema = {
                ...schema,
                farmIndex: presenceTrue,
                shedIndex: presenceTrue,
                storage: presenceTrue,
                quantity: presenceTrue,

                weightType: presenceFalse,
                billWeight: presenceFalse,
                deliveryWeight: presenceFalse,
            }

            if (editMode) {

                if (formState.values.storage === 1) {
                    setShowStorageCheckbox(true)
                    setShowShed(false)
                    schema = {
                        ...schema,
                        storage: presenceTrue,
                        shedIndex: presenceFalse
                    }
                } else {
                    setShowStorageCheckbox(true)
                    setShowShed(true)
                    schema = {
                        ...schema,
                        storage: presenceFalse,
                        shedIndex: presenceTrue
                    }
                }
            }
            
        }
    }

    const handleMaterialTypeChange = (event) => {
        let materialTypeIndex = event.target.value
        let materialTypeID = materialTypes[materialTypeIndex].id

        setFormState({
            ...formState,
            values: {
                ...formState.values,
                materialTypeIndex: materialTypeIndex,
                materialIndex: undefined,
                weightType: undefined,
                billWeight: undefined,
                deliveryWeight: undefined,
                farmIndex: undefined,
                shedIndex: undefined,
                quantity: undefined,
                costPerUnit: undefined,
                inventoryType: undefined,
                gstTaxProfileIndex: undefined,
            }
        })
        
        onGetMaterialNames(materialTypeID)
        setShowMaterialNames(true)
        setShowWeightType(false)
        setShowFarm(false)
        setShowShed(false)
        setShowStorageCheckbox(false)
        setShowQuantity(false)
    }

    const handleMaterialNameChange = (event) => {
        /* 
            UNIT_ID - Names
            1 - trays
            2 - kgs
            3 - units
            4 - litres
        */
        let materialIndex = event.target.value

        setFormState({
            ...formState,
            values: {
                ...formState.values,
                materialIndex: materialIndex,
                weightType: undefined,
                billWeight: undefined,
                deliveryWeight: undefined,
                farmIndex: undefined,
                shedIndex: undefined,
                quantity: undefined,
                costPerUnit: undefined,
                inventoryType: materialNames[materialIndex].inventoryType,
            }
        })

        const unitID = materialNames[materialIndex].unitID
        const inventoryType = materialNames[materialIndex].inventoryType

        handleAllDropdown(false, unitID, inventoryType)

        
    }

    const handleFarmChange = (event) => {
        let farmIndex = event.target.value
        let farmID = farms[farmIndex].id
        
        onGetShed(farmID)
        setShowShed(true)
        setShowStorageCheckbox(true);
    }

    const handleStorageCheckboxChange = (event) => {
        let storageCheckedVal = event.target.checked
        setStorageChecked(storageCheckedVal)
        setShowShed(!storageCheckedVal)

        if (storageCheckedVal) {
            schema = {
                ...schema,
                shedIndex: presenceFalse,
            }
        } else {
            schema = {
                ...schema,
                shedIndex: presenceTrue,
                storage: presenceFalse,
            }
        }

        delete formState.values.shedId
        delete formState.values.shedIndex
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                storage: storageCheckedVal,
            }
        })

    }

    const handleShedChange = (event) => {

        schema = {
            ...schema,
            shedIndex: presenceTrue,
            storage: presenceFalse,
        }
    }

    const handleCloseWeightDialog = () => {
        setShowWeightDialog(false)
    }

    const handleSave = () => {
        onSubmit()
    }

    const hanldeSubmit = () => {
        if(((formState.values.deliveryWeight - formState.values.billWeight) > 200) || ((formState.values.billWeight - formState.values.deliveryWeight)>200)) {  
            setShowWeightDialog(true);
            return
        } else {
            onSubmit()
        }
    }

    const onSubmit = () => {
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        
        let tempData = {
            ...formState.values,
            materialTypeId: materialTypes[formState.values.materialTypeIndex].id,
            materialId: materialNames[formState.values.materialIndex].id,
            materialName: materialNames[formState.values.materialIndex].materialName,
            taxProfileID: formState.values.gstTaxProfileIndex !== undefined && formState.values.gstTaxProfileIndex !== null 
            && gstTaxProfiles[formState.values.gstTaxProfileIndex] !== undefined 
            && gstTaxProfiles[formState.values.gstTaxProfileIndex] !== null 
            ? gstTaxProfiles[formState.values.gstTaxProfileIndex].id : null,
            gst: formState.values.gstTaxProfileIndex !== undefined && formState.values.gstTaxProfileIndex !== null 
            && gstTaxProfiles[formState.values.gstTaxProfileIndex] !== undefined 
            && gstTaxProfiles[formState.values.gstTaxProfileIndex] !== null 
            ? gstTaxProfiles[formState.values.gstTaxProfileIndex].taxPercentage : null,
            storage: 0
        }
        if (showQuantity) {
            tempData = {
                ...tempData,
                billWeight: formState.values.quantity,
                deliveryWeight: formState.values.quantity,
                unitID: 1,
                storage: 0,
            }
        }
        if (showWeightType) {
            tempData = {
                ...tempData,
                quantity: formState.values.deliveryWeight,
                unitID: 2,
                storage: 0,
            }
        }

        if (storageChecked === true) {
            tempData = {
                ...tempData,
                farmId: farms[tempData.farmIndex]!==undefined && farms[tempData.farmIndex].id ? farms[tempData.farmIndex].id : 0,
                farmName: {
                    String: farms[tempData.farmIndex]!==undefined && farms[tempData.farmIndex].name ? farms[tempData.farmIndex].name : ""
                },
                shedId: -1,
                storage: 1,
            }
        } else if (tempData.inventoryType === 2) {

            tempData = {
                ...tempData,
                farmId: tempData.farmIndex !== undefined && farms[tempData.farmIndex].id ? farms[tempData.farmIndex].id : "",
                farmName: {
                    String: tempData.farmIndex !== undefined && farms[tempData.farmIndex].name ? farms[tempData.farmIndex].name : "",
                },
                shedId: tempData.shedIndex !== undefined && sheds[tempData.shedIndex] !== undefined && sheds[tempData.shedIndex] !== null ? sheds[tempData.shedIndex].id : -1,
                shedName: {
                  String: tempData.shedIndex !== undefined && sheds[tempData.shedIndex] !== undefined && sheds[tempData.shedIndex] !== null ? sheds[tempData.shedIndex].name : "",
                },
                storage: 0,
            }
        }
        let billValue = 0;

        let taxValue = 1; //If there is no Tax then multiply by 1
        // If the Tax % is greater than 0, then calculate tax in decimals
        if (formState.values.tax < 0) {
            taxValue = (1 + (formState.values.tax / 100));
        }
        if (!formState.values.weightType) {
            billValue = (formState.values.quantity * formState.values.costPerUnit) * taxValue;
        } else if (formState.values.weightType === 1) {
            billValue = (formState.values.billWeight * formState.values.costPerUnit) * taxValue;
        } else if(formState.values.weightType === 2){
            billValue = (formState.values.deliveryWeight * formState.values.costPerUnit) * taxValue;
        }

        tempData = {
            ...tempData,
            billValue: billValue,
        }

        if (editMode) {
            onEdit(tempData)
        } else {
            onSave({
                ...tempData,
                id: materialIndex - 1
            });
        }

    }

    return <Dialog disableBackdropClick maxWidth='sm' open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog- title"   >{editMode ? 'Update' : 'New'} Material
    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
        </IconButton>
    </DialogTitle>
    <DialogContent>

        {/* NOTE Material Type */}
        <FormControl className={classes.formControl}>
                <InputLabel id="farm-select-outlined-label">
                    Material Type
                </InputLabel>
                <Select
                    id="materialTypeIndex-select"
                    value={formState.values.materialTypeIndex !== undefined ? formState.values.materialTypeIndex : ''}
                    onChange={event => { handleChange(event, handleMaterialTypeChange) }}
                    name="materialTypeIndex"
                >
                    {materialTypes?.map((materialType, index) =>
                        <MenuItem key={materialType.id} value={index}>{materialType.materialType}</MenuItem>
                    )}
                </Select>
                <Link
                    color="inherit"
                    href="#"
                    onClick={() => { return history.push("/material_master") }}
                    className={classes.link}
                    style={{ color: "#02a6c7" }}
                > Add new Material</Link>
            </FormControl>
            
            {/* NOTE Material Names */}
            {showMaterialName ? 
                <FormControl className={classes.formControl}>
                    <InputLabel id="materialName-select-label">Material Name</InputLabel>
                    <Select
                        id="materialName-select"
                        value={formState.values.materialIndex !== undefined ? formState.values.materialIndex : ''}
                        onChange={event => { handleChange(event, handleMaterialNameChange )}}
                        name="materialIndex"
                    >
                        {materialNames?.map((material, index) =>
                            <MenuItem key={material.id} value={index}>{material.materialName}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            : null}

            {/* NOTE Weight Type */}
            {showWeightType ?  
                <span>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="weightType-select-outlined-label">
                            Weight Type
                        </InputLabel>
                        <Select
                            id="weightType"
                            value={formState.values.weightType !== undefined ? formState.values.weightType : ''}
                            onChange={handleChange}
                            name="weightType"
                        >
                            <MenuItem key={0} value={1}>Bill Weight</MenuItem>
                            <MenuItem key={1} value={2}>Delivery Weight</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        className={classes.textField}
                        error={hasError('billWeight')}
                        fullWidth
                        helperText={
                            hasError('billWeight') ? formState.errors.billWeight[0] : null
                        }
                        label="Bill Weight"
                        name="billWeight"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.billWeight !== undefined ? formState.values.billWeight : ''}
                        variant="outlined"
                        style={{ backgroundColor: "#ffffff" }}
                    />
                    <TextField
                        className={classes.textField}
                        error={hasError('deliveryWeight')}
                        fullWidth
                        helperText={
                            hasError('deliveryWeight') ? formState.errors.deliveryWeight[0] : null
                        }
                        label="Delivery Weight"
                        name="deliveryWeight"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.deliveryWeight !== undefined ? formState.values.deliveryWeight : ''}
                        variant="outlined"
                        style={{ backgroundColor: "#ffffff" }}
                    />
                 </span>
            : null}

            {/* NOTE Farm */}
            {showFarm && farms ?
                <FormControl className={classes.formControl}>
                    <InputLabel id="farm-select-outlined-label">Farm</InputLabel>
                    <Select
                        id="farm"
                        value={formState.values.farmIndex !== undefined ? formState.values.farmIndex : ''}
                        onChange={event => { handleChange(event, handleFarmChange) }}
                        name="farmIndex"
                    >
                        {farms.map((farm, index) =>
                            <MenuItem key={farm.id} value={index}>{farm.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            : null}
            
            {/* NOTE Storage Checkbox */}
            {showStorageCheckbox ? 
                <FormControl className={classes.formControl}>                        
                    <FormControlLabel
                        control={<Checkbox checked={storageChecked} name="storage" />}
                        label="Storage"
                        name="storage"
                        value={formState.values.storage !== undefined ? formState.values.storage : ''}
                        onChange={event=>{
                            handleChange(event, handleStorageCheckboxChange)
                        }}
                    />
                </FormControl>
            : null}

            {/* NOTE Shed */}
            {showShed && sheds !== undefined ?
                <FormControl className={classes.formControl}>
                    <InputLabel id="shed-select-outlined-label">Shed</InputLabel>
                    <Select
                        id="shed"
                        value={formState.values.shedIndex !== undefined ? formState.values.shedIndex : ''}
                        onChange={event => {
                            handleChange(event, handleShedChange)
                        }}
                        name="shedIndex"
                    >
                        {sheds.map((shed, index) =>
                            <MenuItem key={shed.id} value={index}>{shed.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            : null}

             {/* NOTE Quantity */}
             {showQuantity ? 
                <TextField
                    className={classes.textField}
                    error={hasError('quantity')}
                    fullWidth
                    helperText={
                        hasError('quantity') ? formState.errors.quantity[0] : null
                    }
                    label="Quantity"
                    name="quantity"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.quantity !== undefined ? formState.values.quantity : ''}
                    variant="outlined"
                    style={{ backgroundColor: "#ffffff" }} />
            : null}

            {/* NOTE Cost per unit */}
            <TextField
                className={classes.textField}
                error={hasError('costPerUnit')}
                fullWidth
                helperText={
                    hasError('costPerUnit') ? formState.errors.costPerUnit[0] : null
                }
                label="Cost Per Unit"
                name="costPerUnit"
                onChange={handleChange}
                type="number"
                value={formState.values.costPerUnit !== undefined ? formState.values.costPerUnit : ''}
                variant="outlined"
                style={{ backgroundColor: "#ffffff" }}
            />

            {/* NOTE GST */} 
            <FormControl className={classes.formControl}>
                <InputLabel id="gst-select-outlined-label">GST</InputLabel>
                <Select
                    id="gstTaxProfile-select"
                    value={formState.values.gstTaxProfileIndex !== undefined ? formState.values.gstTaxProfileIndex : ''}
                    onChange={handleChange}
                    name="gstTaxProfileIndex"
                >
                    {gstTaxProfiles?.map((gstTaxProfile, index) =>
                        <MenuItem key={gstTaxProfile.id} value={index}>{gstTaxProfile.taxProfileName}</MenuItem>
                    )}
                </Select>
            </FormControl>

            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={hanldeSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            
            {/* NOTE Second dialog */}
            <Dialog
              open={showWeightDialog}
              onClose={handleCloseWeightDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" >{'Warning'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                The difference between "Bill Weight" and "Delivery Weight" is greater than 200.
                Do u still want to save these values?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseWeightDialog} color="primary">
                  No
                </Button>
                <Button onClick={handleSave} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

    </DialogContent>
    </Dialog>
}

export default NewMaterial;
