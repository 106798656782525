/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../common/api';
import { populateTaxData } from '../../common/functions';
import { getAllTaxes } from '../../common/functions';
import makeStyles from "@material-ui/styles/makeStyles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';
import Grid from "@material-ui/core/Grid";
import Icon from '@material-ui/core/Icon';
import { MaterialTable } from '../../components';
import Typography from "@material-ui/core/Typography";
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Button from "@material-ui/core/Button";
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableBody';
import MaterialDialog from './components/material';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PaymentDialog from './components/payment';
import LineDialog from './components/lineDialog';
import ShareIcon from '@material-ui/icons/Share';
import {useGlobalState} from '../../state';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import Snackbar from '@material-ui/core/Snackbar';
// import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
//import InvoiceDialog from './components/invoiceDialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InvoiceView from './components/invoice';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    tableRoot: {
        marginTop:theme.spacing(2),
        overflowX: "auto"
    },
    displayNone: {
        display:"none"
    },
    textBold: {
        fontWeight:"500"
    },
    topBar: {
        marginBottom: theme.spacing(2)
    },
    spaceBar: {
        marginLeft: theme.spacing(2.7)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    addButton: {
        textAlign: "left",
        marginBottom: theme.spacing(2),
        minWidth: 160,
    },
    leftIcon: {
        marginLeft: theme.spacing(1),
    },
    red: {
        color: 'red'
    },
    green: {
        color: 'green'
    },
    tab: {
        marginBottom: theme.spacing(2),
    },
    gridMargin: {
        margin: theme.spacing(1.5)
    },
    spacing: {
        margin: theme.spacing(2)
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        minWidth: 140,
    },
    formControlLabelClass: {
        marginTop: theme.spacing(2),
    },
    chip: {
        marginBottom: theme.spacing(2)
    },
    margin: {
        margin: theme.spacing(1),
    },
    searchButton: {
        color: 'white',
        // width: theme.spacing(21.25),
        backgroundColor: '#EF5350',
        '&:hover': {
            backgroundColor: '#E53935',
        },
        // margin: theme.spacing(1),
        // padding: theme.spacing(1),
        // marginLeft: theme.spacing(1.6),
        // marginBottom: theme.spacing(4)
    },
    success: {
        color: 'white',
        backgroundColor: '#4CAF50',
        '&:hover': {
            backgroundColor: '#388E3C',
        },
        // width: theme.spacing(13.75),
        // margin: theme.spacing(1),
        // padding: theme.spacing(1),
        // marginLeft: theme.spacing(1.6),
        // marginBottom: theme.spacing(4)
    }
}));

const todaysDate = moment().format('DD/MM/YYYY')

const LineSalesDriverView = ({adminLineSales,LinesaleData,lineSaleView,lineSaleEditView}) => {
    const { t, i18n } = useTranslation();
    
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    const [showMaterialDialog, setShowMaterialDialog] = useState(false)
    const [showPaymentDialog, setShowPaymentDialog] = useState(false)
    const [materialEditMode, setMaterialEditMode] = useState(false)
    const [paymentEditMode, setPaymentEditMode] = useState(false)
    const [showLineDialog, setShowLineDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const [customers, setCustomers] = useState(undefined)
    const [lineNames, setLineNames] = useState(undefined)
    const [selectedLineID, setSelectedLineID] = useState(undefined)
    const [selectedCustomerID, setSelectedCustomerID] = useState(undefined)
    const [trucks, setTrucks] = useState(undefined)
    const [selectedTruckID, setSelectedTruckID] = useState(undefined)
    const [taxProfiles, setTaxProfiles] = useState([])
    const [eggMaterials, setEggMaterials] = useState(undefined)
    const [tabIndex, setTabIndex] = useState(0)
    const [invoiceSetting, setInvoiceSetting] = useState({});

    const [materials, setMaterials] = useState([])
    const [materialData, setMaterialData] = useState(undefined)
    const [payments, setPayments] = useState([])
    const [paymentData, setPaymentData] = useState(undefined)
    const [showTables, setShowTables] = useState(false)

    const TDS = "TDS";
    const TCS = "TCS";
    const [taxProfileHashMap, setTaxProfileHashMap] = useState(null)
    const [lineSaleID, setLineSaleID] = useState(undefined)
    const [customer, setCustomer] = useState(undefined)
    const [lineSaleDate,setLineSaleDate] = useState(undefined)

    /* setTcsTaxValue(null)
                setTdsTaxValue(null)
                setShowTcs(false)
                setShowTds(false) */
    const [showMessage, setShowMessage] = useState(false)
    const [tcsValue, setTcsValue] = useState(null)
    const [tdsValue, setTdsValue] = useState(null)
    const [showTCS, setShowTCS] = useState(false)
    const [showTDS, setShowTDS] = useState(false)
    const defaultCalcObj = {
        billAmount: 0,
        tcsAmount: 0,
        tdsAmount: 0,
        finalAmount: 0,
        paymentAmount: 0,
        closingBalance: 0,
        totalReturnTrays: 0,
        closingTrayBalance: 0,
    }
    const [calcObj, setCalcObj] = useState({
        billAmount: 0,
        tcsAmount: 0,
        tdsAmount: 0,
        finalAmount: 0,
        paymentAmount: 0,
        closingBalance: 0,
        totalReturnTrays: 0,
        closingTrayBalance: 0,
    })
    const [openingBalance, setOpeningBalance] = useState(0)
    const [openingTrayBalance, setOpeningTrayBalance] = useState(0)

    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }
    const [isAddMaterialActive,setAddMaterialActive] =  useState(false);
    const [isSearch,setIsSearch] = useState(false);

    useEffect(() => {
        if (taxProfiles !== null && taxProfiles !== undefined && Array.isArray(taxProfiles) && taxProfiles.length > 0) {
            let map = new Map()
            for (const el of taxProfiles) {
                map.set(el.id, el)
            }
            setTaxProfileHashMap(map)
        }
    }, [taxProfiles])

    useEffect(() => {

        if (adminLineSales || lineSaleView) {
            setIsTamil(false)
        }
        if (localStorage.getItem('driver_line')) {
            setShowLineDialog(false)
        } else {
            setShowLineDialog(false)
        }
    }, [])

    const handleCloseMaterialDialog = () => {
        setShowMaterialDialog(false)
    }
    const handleClosePaymentDialog = () => {
        setShowPaymentDialog(false)
    }

    const getAllLineNames = () => {
        const response = api.get('line_name');

        response.then(res => {
            setLineNames([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
    }

    const getAllInvoiceSettings = () => {
        const response = api.get('invoiceSettings');
        response.then(res => {
            setInvoiceSetting(res[0]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                console.log('No InvoiceSettings Found');
                else
                console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
    }
    
    const getAllCustomers = () => {
        const response = api.get('get-line-customers');

        response.then(res => {
            setCustomers([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
    }

    const getAllMaterials = () => {
        const response = api.get('egg-materials');

        response.then(res => {
            setEggMaterials([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
        
    }

    const getAllTrucks = () => {
        const response = api.get("trucks")

        response.then(res => {
            setTrucks(res)
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
    }

    const onLoad = () => {
        setLoading(true);
        Promise.all(
            [getAllTaxes(api), getAllLineNames(), getAllInvoiceSettings(), getAllCustomers(),  getAllMaterials(), getAllTrucks()])
            .finally(() => {
                setLoading(false)
                populateTaxData(setTaxProfiles,[TDS,TCS])
            })

    if (LinesaleData && (lineSaleView || lineSaleEditView) ) {
        setLineSaleDate(LinesaleData.date)
        setSelectedLineID(LinesaleData.lineNameID)
        setSelectedCustomerID(LinesaleData.customerID)
        setSelectedTruckID(LinesaleData.truckID)
        setAddMaterialActive(true);
        getLineSaleMaterialsAndPayments()
    }
      
    }

    useEffect(onLoad, []);

    const handleChangeCustomer = (event) => {
        let id = event.target.value
        setSelectedCustomerID(id)
        clearSearch();
    }

    const handleChangeLineName = (event) => {
        let id = event.target.value
        setSelectedLineID(id)
        clearSearch();
    }

    const handleTruckChange = (event) => {
        let id = event.target.value
        setSelectedTruckID(id)
        clearSearch();
    }

    const getTaxProfiles = () => {
        const response = api.get(`/customer-vendor-details-tax-profile/${selectedCustomerID}`)

        response.then(res => {
            let obj = {
                tcs:{
                    setState:function() {
                        setTcsValue(null)
                        setShowTCS(false)
                    },
                    set:false
                },tds:{
                    setState:function() {
                        setTdsValue(null)
                        setShowTDS(false)
                    },
                    set:false
                },
            }
            for (const el of res) {
                if (taxProfileHashMap.get(el) !== undefined && taxProfileHashMap.get(el) !== null && Object.keys(taxProfileHashMap.get(el)).length > 0) {
                    let {taxTypeId, taxPercentage} = taxProfileHashMap.get(el)
                     if (taxTypeId === 2) {
                        setTdsValue(taxPercentage)
                        setShowTDS(true)
                        obj.tds.set = true
                    }else if (taxTypeId === 3) {
                        setTcsValue(taxPercentage)
                        setShowTCS(true)
                        obj.tcs.set = true
                    }
                }
                
            }
            for (const key in obj) {
                if (obj[key]["set"] === false) {
                    obj[key]["setState"]()
                }
            }
        }).catch((err)=>{
            if (err.message.includes(404)) {
                setTcsValue(null)
                setTdsValue(null)
                setShowTCS(false)
                setShowTDS(false)
            }

        })

        populateTaxData(setTaxProfiles, ["TDS", "TCS"])
    }
    
    const getLineSaleMaterialsAndPayments = () => {
        setLoading(true)
        let input = {
            date: moment().toDate(),
            lineNameID: selectedLineID,
            customerID: selectedCustomerID,
            truckID: selectedTruckID,
        }
        if(LinesaleData  && (lineSaleView || lineSaleEditView)) {
            input ={
            date: LinesaleData.date,
            lineNameID: LinesaleData.lineNameID,
            customerID: LinesaleData.customerID,
            truckID: LinesaleData.truckID,
            }
        }

        const response = api.post("get-line-sale-materials-and-payments", input)

        response.then(res => {
            setLineSaleID(res.id)
            setOpeningBalance(res.openingBalance)
            setOpeningTrayBalance(res.trayBalance)
            console.log("get-line-sale-materials-and-payments--->", res)
            if (res.lineSaleMaterials) {
                let lsMaterials = res.lineSaleMaterials
                let lsPayments = res.lineSalePayments
                if (lsMaterials && lsMaterials.length) {
                    setMaterials([...lsMaterials])
                } else {
                    setMaterials([])
                }
                if (lsPayments) {
                    setPayments([...lsPayments])
                } else {
                    setPayments([])
                }
            } else {
                setMaterials([])
                setPayments([])
                setCalcObj(defaultCalcObj)
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found') {
                    console.log(err.message)
                }
                    
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false)
        });
    }

    /* useEffect(() => {
        if (selectedLineID && selectedCustomerID && selectedTruckID) {
            getLineSaleMaterialsAndPayments()
        }
   
    }, [selectedLineID, selectedCustomerID, selectedTruckID]) */

    useEffect(() => {
        if (selectedCustomerID) {
            getTaxProfiles()
        }
    }, [selectedCustomerID])

    useEffect(() => {
        if(selectedLineID && selectedCustomerID && selectedTruckID && lineSaleDate)
        {
            setIsSearch(true);
            getLineSaleMaterialsAndPayments();setAddMaterialActive(true);
        }
    }, [selectedLineID, selectedCustomerID, selectedTruckID, lineSaleDate])

    const handleTabChange = (_, value) => {
        setTabIndex(value)
    }

    let materialActions = [
        {
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit sale Details',
            onClick: (_, rowData) => {
                setMaterialData(rowData)
                setMaterialEditMode(true)
                setShowMaterialDialog(true)
            }
        },
        {
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete sale Details',
            onClick: (_, rowData) => onDeleteMaterial(rowData.id)
        }
    ]

    let paymentActions = [
        {
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit sale Details',
            onClick: (_, rowData) => {
                setPaymentData(rowData)
                setPaymentEditMode(true)
                setShowPaymentDialog(true)
            }
        },
        {
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete sale Details',
            onClick: (_, rowData) => onDeletePayment(rowData)
        }
    ]

    const onDeleteMaterial = (id) => {
        setLoading(true)
        const response = api.delete(`delete-line-sale-material/${id}`)

        response.then(res => {
            if (res) {
                let index = materials.findIndex((el) => {
                    return el.id === id;
                });
                setMaterials([...materials.slice(0, index), ...materials.slice(index + 1)]);
            }
        }).catch(err => {
            if (err.message) {
                console.log("err.message", err.message);
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false)
        });
    }

    const onDeletePayment = (rowData) => {
        setLoading(true)

        let lsPayment = {
            id: rowData.id,
            saleId: rowData.saleId,
            paymentAmount: rowData.paymentAmount,
            paymentMode: rowData.paymentMode
        }
        const response = api.post(`line-sale-payment-delete/${rowData.id}`, lsPayment)

        response.then(res => {
            if (res) {
                let index = payments.findIndex((el) => {
                    return el.id === rowData.id;
                });
                setPayments([...payments.slice(0, index), ...payments.slice(index + 1)]);
            }
        }).catch(err => {
            if (err.message) {
                console.log("err.message", err.message);
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false)
        });
    }

    const onSaveMaterial = (newMaterial) => {
        setLoading(true)

        let customerIndex = customers.findIndex(ele => ele.id === selectedCustomerID)
        let lineIndex = lineNames.findIndex(ele => ele.id === selectedLineID)

        let lineSaleMaterialInput = {
            lineSaleID,
            materialId: newMaterial.materialID,
            materialNameIndex: newMaterial.materialIndex,
            materialName: newMaterial.materialName,
            quantity: newMaterial.quantity,
            neccDate: newMaterial.neccDate,
            neccPrice: newMaterial.neccPrice,
            discount: newMaterial.discount,
            freeQuantity: newMaterial.freeQuantity,
            billValue: newMaterial.billValue,
            returnTrays: newMaterial.returnTrays
        }
        let totalBillValue = newMaterial.billValue
        if (materials && materials.length) {
            materials.forEach(m => totalBillValue += m.billValue)
        }

        let input = {
            id: lineSaleID ? lineSaleID : 0,
            date: lineSaleDate ? lineSaleDate : moment().toDate(),
            customerID: selectedCustomerID,
            customerName: customers[customerIndex].companyName,
            customerAddress: customers[customerIndex].address,
            customerGstNumber: customers[customerIndex].gstNumber,
            lineNameID: selectedLineID,
            lineName: lineNames[lineIndex].name,
            paymentStatus: 0,
            totalBillValue: totalBillValue,
            toatlAmountPaid: 0,
            tds: tdsValue,
            tcs: tcsValue,
            taxProfileIDs: customers[customerIndex].taxProfiles,
            truckID: selectedTruckID,
            lineSaleMaterials: [lineSaleMaterialInput]
        }
        console.log("input", input)
        const response = api.post("line-sale", {
            ...input
        })

        response.then(res => {

            if (res) {
                console.log("res", res)
                setLineSaleID(res.id)
                if (materials.length > 0) {
                    setMaterials([...materials, ...res.lineSaleMaterials])
                } else {
                    setMaterials([...res.lineSaleMaterials])
                }
            }
            
        }).catch(err => {
            if (err.message) {
                console.log("err.message", err.message);
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setShowMaterialDialog(false)
            setLoading(false)
        });
    }

    const onUpdateMaterial = (updatedMaterial) => {
        setLoading(true)
        
        let lineSaleMaterialInput = {
            id: updatedMaterial.id,
            materialId: updatedMaterial.materialID,
            materialNameIndex: updatedMaterial.materialIndex,
            materialName: updatedMaterial.materialName,
            quantity: updatedMaterial.quantity,
            neccDate: updatedMaterial.neccDate,
            neccPrice: updatedMaterial.neccPrice,
            discount: updatedMaterial.discount,
            freeQuantity: updatedMaterial.freeQuantity,
            billValue: updatedMaterial.billValue,
            returnTrays: updatedMaterial.returnTrays
        }
        let totalBillValue = 0
        materials.forEach(m => {
            if (m.id === updatedMaterial.id) {
                totalBillValue += updatedMaterial.billValue
            } else {
                totalBillValue += m.billValue
            }
        })
        let input = {
            id: lineSaleID,
            date: moment().toDate(),
            customerID: selectedCustomerID,
            lineNameID: selectedLineID,
            tds: tdsValue,
            tcs: tcsValue,
            totalBillValue: totalBillValue,
            lineSaleMaterials: [lineSaleMaterialInput]
        }
        if (lineSaleID === undefined || lineSaleID === null) {
            return
        }
        
        console.log("input", input)

        const response = api.post(`line-sale/${lineSaleID}`, input)
        
        response.then(res => {
            if (res) {
                console.log("res--->", res)
                let index = materials.findIndex((el) => {
                    return el.id === res.lineSaleMaterials[0].id;
                });
                setMaterials([...materials.slice(0, index),
                { ...res.lineSaleMaterials[0] },
                ...materials.slice(index + 1)]);
            }
        }).catch(err => {
            if (err.message) {
                console.log("err.message", err.message);
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setShowMaterialDialog(false)
            setLoading(false)
        });
    }

    useEffect(() => {
        
        if (materials && materials.length) {
            let tcsAmount = 0;
            let tdsAmount = 0;
            let billAmount = 0;
            let finalAmount = 0;
            let returnedTrays = 0;
            let quantity = 0;
            let freeQuantity = 0;
            materials.forEach(material => {
                billAmount += material.billValue
                quantity += material.quantity
                freeQuantity += material.freeQuantity
                returnedTrays += material.returnTrays
                if (showTCS) {
                    tcsAmount += (material.billValue * (tcsValue/100))
                }
                if (showTDS) {
                    tdsAmount += (material.billValue * (tdsValue/100))
                }
            })
            finalAmount = billAmount - tdsAmount + tcsAmount
            let closingBalance = openingBalance + billAmount - calcObj.paymentAmount
            let closingTrayBalance = openingTrayBalance + quantity + freeQuantity - returnedTrays
            let totalReturnTrays = returnedTrays
            setCalcObj({
                ...calcObj,
                billAmount,
                tcsAmount,
                tdsAmount,
                finalAmount,
                closingBalance,
                closingTrayBalance,
                totalReturnTrays
            })

        }
    }, [materials])

    useEffect(() => {
        if (payments && payments.length) {
            
            let sumPayAmount = 0
            payments.forEach(payment => {
                sumPayAmount += payment.paymentAmount
            })
            
            setCalcObj({
                ...calcObj,
                finalAmount: calcObj.finalAmount - sumPayAmount,
                paymentAmount: sumPayAmount,
                closingBalance: openingBalance + calcObj.billAmount - sumPayAmount
            })
        } else {
            setCalcObj({
                ...calcObj,
                finalAmount: 0,
                closingBalance: materials.length ? calcObj.billAmount + openingBalance : 0,
                paymentAmount: 0,
            })
        }
    }, [payments])

    const onSavePayment = (newPayment) => {
            
        let lineSalePaymentInput = {
            saleId: lineSaleID,
            paymentDate: moment().toDate(),
            paymentAmount: newPayment.paymentAmount,
            paymentMode: newPayment.paymentMode,
        }
        if (newPayment.paymentMode === 2) {
            lineSalePaymentInput["chequeNumber"] = newPayment.chequeNumber
        }

        const response = api.post("line-sale-payment", lineSalePaymentInput)

        response.then(res => {
            if (res) {
                if (payments.length > 0) {
                    setPayments([...payments, res])
                } else {
                    setPayments([res])
                }
            }
        }).catch(err => {
            if (err.message) {
                console.log("err.message", err.message);
            } else {
                console.log('Unknown Error')
            }
        })
        setShowPaymentDialog(false)
    }

    const onUpdatePayment = (updatedPayment) => {
        let lineSalePaymentInput = {
            saleId: lineSaleID,
            paymentDate: moment().toDate(),
            paymentAmount: updatedPayment.paymentAmount,
            paymentMode: updatedPayment.paymentMode,
        }
        if (updatedPayment.paymentMode === 2) {
            lineSalePaymentInput["chequeNumber"] = updatedPayment.chequeNumber
        }

        const response = api.post(`line-sale-payment/${updatedPayment.id}`, lineSalePaymentInput)

        response.then(res => {
            if (res) {
                let index = payments.findIndex((el) => {
                    return el.id === res.id;
                });
                setPayments([...payments.slice(0, index),
                { ...res },
                ...payments.slice(index + 1)]);
            }
        }).catch(err => {
            if (err.message) {
                console.log("err.message", err.message);
            } else {
                console.log('Unknown Error')
            }
        })
        setShowPaymentDialog(false)
    }

    const handleAddMaterial = () => {
        setMaterialEditMode(false)
        setMaterialData(undefined)
        setShowMaterialDialog(true)
    }
    const handleAddPayment = () => {
        setPaymentEditMode(false)
        setPaymentData(undefined)
        setShowPaymentDialog(true)
    }

    const getTabIndexComponent = (index) => {
        if (index === 0) {
            return <div >
            <div className={classes.addButton}>
                <Button variant="contained" color="primary" disabled={loading || !selectedLineID || !selectedCustomerID || !selectedTruckID || !isAddMaterialActive} onClick={handleAddMaterial}>{t("addMaterial")}</Button>
            </div>
            <MaterialTable
                isLoading={loading}
                localization={{ 
                    body:{ emptyDataSourceMessage:<p style={{textAlign:'center'}}>{t('noRecordsToDisplay')}</p> },
                    header: {
                        actions: t('actions')
                    },
                }}
                columns={[
                    { title: 'id', field: 'id', hidden: true,
                    cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},
                    },
                    {title: t('eggType'), field: 'materialName', editable: 'never',
                    cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},},
                    { title: t('quantityInTrays'), field: 'quantity', editable: 'never',
                    cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},},
                    {
                        title: t('neccDate'), field: 'neccDate', type: 'datetime',
                        cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},
                        editable: 'never',
                        render: rowData => {
                            return <span>{moment(rowData.neccDate).format("DD-MM-YYYY")}</span>
                        }
                    },
                    {
                        title: t('neccPrice'), field: 'neccPrice', type: 'datetime',
                        cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},
                        editable: 'never',
                        render: rowData => {
                            let price = rowData.neccPrice
                            if (price === 0) {
                                return <Tooltip title={<span style={{
                                    fontSize:"1rem"
                                }}>
                                    {t('noNeccPriceTip')}
                                </span>}>
                                    <Badge className={classes.margin} color="primary" variant="dot">
                                        <span>{price.toLocaleString(user.locales,amountObj)}</span>
                                    </Badge>
                                </Tooltip>
                            } else {
                                return <span>{price.toLocaleString(user.locales,amountObj)}</span>
                            }
                            
                        }
                    },
                    {
                        title: t('discount'), field: 'discount', editable: 'never',
                        cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},
                        render: (rowData) => {
                            return <span>{rowData.discount.toLocaleString(user.locales,amountObj)}</span>
                        }
                    },
                    {title: t('freeQuantityInTrays'), field: 'freeQuantity', editable: 'never',
                    cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},},
                    {title: t('returnTrays'), field: 'returnTrays', editable: 'never',
                    cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},},
                    {title: t('billValue'), field: 'billValue', editable: 'never',
                    cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},
                    render: rowData => {
                        if (rowData.neccPrice === 0) {
                            return <span>N/A</span>
                        }
                        return <span>{rowData.billValue.toLocaleString(user.locales,amountObj)}</span>
                    }},
                ]}
                data={materials || []}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    paging: false
                }}
                actions={materialActions}
            />
            </div>
        } else if (index === 1) {
            return <div>
            <div className={classes.addButton}>
                <Button variant="contained" color="primary" disabled={loading || !materials || materials.length === 0} onClick={handleAddPayment}>{t("addPayment")}</Button>
            </div>
            <MaterialTable
            localization={{ 
                    body:{ emptyDataSourceMessage:<p style={{textAlign:'center'}}>{t('noRecordsToDisplay')}</p> },
                    header: {
                        actions: t('actions')
                    },
                }}
                columns={[
                    { title: 'id', field: 'id', hidden: true },
                    { 
                        title: t('paymentMode'), field: 'paymentMode', editable: 'never',
                        cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},
                        render:(rowData) => {
                            let payMode = ""
                            if (rowData.paymentMode === 2) {
                                payMode = `Cheque (${rowData.chequeNumber})`
                            } else {
                                payMode = "Cash"
                            }
                            return <span>{payMode}</span>
                        }
                    },
                    { 
                        title: t('paymentAmount'), field: 'paymentAmount', editable: 'never',
                        cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},
                        render:(rowData) => {
                            return <span>{rowData.paymentAmount.toLocaleString(user.locales,amountObj)}</span>
                        }
                    },
                ]}
                data={payments || []}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    paging: false,
                }}
                actions={paymentActions}
            />
        </div>
        }
    }

    const handleShareBtn = () => {
        let customerIndex = customers.findIndex(ele => ele.id === selectedCustomerID)
        let customerName = customers[customerIndex].companyName
        let customer = customers[customerIndex]
        console.log("customers[customerIndex]", customers[customerIndex])

        let output = `Poultry Pro Plus\n\n${invoiceSetting.companyName}\n${invoiceSetting.address}\n\nDate: ${moment().format('MMMM Do YYYY, h:mm a')}\nCustomer: ${customerName}\nAddress: ${customer.address}\n\nEgg Types\n\n`
        
        materials.forEach((m, index) => {
            let obj = {
                "Egg Type": m.materialName,
                "Quantity": m.quantity,
                "NECC Date": moment(m.neccDate).format("DD/MM/YYYY"),
                "NECC Price": m.neccPrice.toLocaleString(user.locales,amountObj),
                "Discount": m.discount,
                "Bill Value": m.billValue.toLocaleString(user.locales,amountObj),
            }
            for (const property in obj) {
                output += `${property} : ${obj[property]}\n`
            }
            output += "\n"
        })

        output += "____________________________"

        payments.forEach((p, index) => {
            if (index === 0) {
                output += `\n\nPayments\n\n`
            }
            let obj = {
                "Type": p.paymentMode === 1 ? "Cash" : `Cheque (${p.chequeNumber})`,
                "Amount": p.paymentAmount.toLocaleString(user.locales,amountObj)
            }
            for (const property in obj) {
                output += `${property} : ${obj[property]}\n`
            }
            output += "\n"

        })

        output += "____________________________"

        let finalCalc = {
            "Opening Balance": openingBalance.toLocaleString(user.locales,amountObj),
            "Bill": calcObj.billAmount.toLocaleString(user.locales,amountObj),
            "TCS": calcObj.tcsAmount.toLocaleString(user.locales,amountObj),
            "TDS": calcObj.tdsAmount.toLocaleString(user.locales,amountObj),
            "Paid Amount": calcObj.paymentAmount.toLocaleString(user.locales,amountObj),
            "Pending Amount": calcObj.closingBalance.toLocaleString(user.locales,amountObj),
            "Opening Trays": openingTrayBalance,
            "Closing Trays": calcObj.closingTrayBalance,
        }
        output += `\n\nSummary\n\n`
        for (const property in finalCalc) {
            output += `${property} : ${finalCalc[property]}\n`
        }

        output += `\n\nGenerated by poultryproplus.com\n\n`
        console.log(output)

        if (navigator.share) {
            navigator.share({
                title: "Invoice",
                text: output
            })
        } else {
            setShowMessage(true)
        }

    }

    const handleGeneratePDF = () => {
        setShowTables(true)
        let index = customers.findIndex(c => c.id === selectedCustomerID)
        let customer = customers[index]
        setCustomer(customer)
    }

    const [isTamil, setIsTamil] = useState(true)

    useEffect(() => {
        if (isTamil) {
            i18n.changeLanguage("ta")
        } else {
            i18n.changeLanguage("en")
        }
    }, [isTamil])

    const clearSearch = ()=>{
        setMaterials([])
        setPayments([])
        setCalcObj(defaultCalcObj)
        setOpeningBalance(0)
        setOpeningTrayBalance(0)
        setLineSaleID(undefined);
        setAddMaterialActive(false);
    };

    return <div className={classes.root}>
        <div>
        <FormControlLabel
          control={
            <Switch
              checked={isTamil}
              onChange={() => {
                setIsTamil(!isTamil)
              }}
              value="checkedB"
              color="primary"
            />
          }
          label="Tamil"
        />
        {
            adminLineSales || lineSaleView ?
            <div>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                className={classes.spacing}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label={t("date")}
                format="dd/MM/yyyy"
                value={lineSaleDate || null}
                onChange={data => {
                    setLineSaleDate(moment(data).toDate())
                }}
                animateYearScrolling
                name="date"
                style={{backgroundColor:"#ffffff"}}
            />
          </MuiPickersUtilsProvider> 
            </div>
          :
         <Typography variant="h3" gutterBottom>
            {t("todaysDate")}: {todaysDate}
            
         </Typography>
        }
        </div>
        <Grid container
        justify='space-between'
        alignItems='center'
        >
            <Grid container alignItems='flex-start'>
                <FormControl className={classes.formControl}>
                    <InputLabel id="customerName-select-outlined-label">
                    {t("lineName")}
                    </InputLabel>
                    <Select
                        id="customerName-select"
                        value={selectedLineID ? selectedLineID : ''}
                        onChange={handleChangeLineName}
                        name="lineNameID"
                    >
                    {lineNames ? lineNames.map((lineName) =>
                            <MenuItem key={lineName.id} value={lineName.id}>{lineName.name}</MenuItem>
                        ) : null}
                    </Select>
                    {/* {selectedLineID ? (truckNumber ? <span>Truck number = {truckNumber}</span> : 'Truck number not found') : null} */}
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id="customerName-select-outlined-label">
                        {t("customerName")}
                    </InputLabel>
                    <Select
                        id="customerName-select"
                        value={selectedCustomerID ? selectedCustomerID : '' }
                        onChange={handleChangeCustomer}
                        name="customerID"
                    >
                        {customers ? customers.map((customer) =>
                            <MenuItem key={customer.id} value={customer.id}>{customer.companyName}</MenuItem>
                        ) : null}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id="customerName-select-outlined-label">
                    {t("truck")}
                    </InputLabel>
                    <Select
                        id="truck-select"
                        value={selectedTruckID ? selectedTruckID : '' }
                        onChange={handleTruckChange}
                        name="truckID"
                    >
                        {trucks ? trucks.map((truck) =>
                            <MenuItem key={truck.id} value={truck.id}>{truck.number}</MenuItem>
                        ) : null}
                    </Select>
                </FormControl>
                        <div>
                {/* <Button 
                    variant="contained"
                    disabled={!selectedLineID || !selectedCustomerID || !selectedTruckID || isSearch}
                    onClick={() => {getLineSaleMaterialsAndPayments();setAddMaterialActive(true);setIsSearch(true);}} 
                    className={classes.success}
                    style={{
                        marginRight:"8px"
                    }}
                    >
                {t("search")}
                    <SearchIcon className={classes.leftIcon} />
                </Button> */}
            
               {!lineSaleEditView && 
                <Button 
                        variant="contained"
                        disabled={!selectedLineID || !selectedCustomerID || !selectedTruckID || !isSearch }
                        onClick={() =>{
                            setSelectedCustomerID(undefined)
                            setSelectedLineID(undefined)
                            setSelectedTruckID(undefined)
                            setIsSearch(false);
                            clearSearch();
                        }} 
                        className={classes.searchButton}
                        >
                        {t("clearSearch")}
                        <CloseIcon className={classes.leftIcon}/>
                    </Button>
                }           
                <span 
                 // className={isDesktop && classes.displayNone}
                >
                    <Button variant="contained" color="primary" onClick={handleShareBtn} 
                        disabled={loading || materials?.length === 0}
                        className={classes.margin}
                        >
                        {t("share")}
                        <ShareIcon className={classes.leftIcon} />
                    </Button>
                </span>
                
                <Button disabled={!materials.length} className={classes.margin} variant="contained" color="primary" onClick={handleGeneratePDF} >
                {t("generatePDF")}
                    <PictureAsPdfIcon className={classes.leftIcon} />
                </Button>
                </div>
            </Grid>
            
            
        </Grid>

        
        {(isSearch) &&
            <Paper>        
            <Tabs
                value={tabIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                className={classes.tab}
            >
                <Tab label={t("materials")}/>
                <Tab label={t("payments")}/>
            </Tabs>
            </Paper>
        }
        
        {(isSearch) && getTabIndexComponent(tabIndex)}

        {(isSearch) &&
            <div className={classes.displayNone}>
            <Grid container justifyContent="flex-end" >
                <Grid xs={isDesktop ? 3 : 6}>
                    <Table className={classes.totalTable} size="small" >
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.totalCell}>Bill Value</TableCell>
                                <TableCell className={classes.totalCell}>{calcObj.billAmount > 0 ? calcObj.billAmount.toLocaleString(user.locales,amountObj) : null }</TableCell>
                            </TableRow>
                            {showTCS ? 
                                <TableRow className={classes.totalRow}>
                                    <TableCell className={classes.totalCell}>TCS</TableCell>
                                    <TableCell className={classes.totalCell}>{calcObj.tcsAmount > 0 ? calcObj.tcsAmount.toLocaleString(user.locales,amountObj) : null }</TableCell>
                                </TableRow>
                            : null}

                            {showTDS ? 
                                <TableRow className={classes.totalRow}>
                                    <TableCell className={classes.totalCell}>TDS</TableCell>
                                    <TableCell className={classes.totalCell}>{calcObj.tdsAmount > 0 ? calcObj.tdsAmount.toLocaleString(user.locales,amountObj) : null }</TableCell>
                                </TableRow>
                            : null}
                            {payments.length ? 
                                <TableRow className={classes.totalRow}>
                                    <TableCell className={classes.totalCell}>Paid</TableCell>
                                    <TableCell className={classes.totalCell}>{calcObj.paymentAmount.toLocaleString(user.locales,amountObj) }</TableCell>
                                </TableRow>
                            : null}
                            <TableRow>
                                <TableCell className={classes.totalCell}>Final Total</TableCell>
                                <TableCell className={classes.totalCell}>{calcObj.finalAmount > 0 ? calcObj.finalAmount.toLocaleString(user.locales,amountObj) : null }</TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </Grid>
            </Grid>
            </div>
        }
        {(isSearch) &&
            <Paper className={classes.tableRoot}>
                <Table size="small">
                <TableHead>
                <TableRow>
                    <TableCell className={classes.textBold}>{t('openingBalance')}</TableCell>
                    <TableCell className={classes.textBold}>{t('billValue')}</TableCell>
                    <TableCell className={classes.textBold}>{t('tcs')}</TableCell>
                    <TableCell className={classes.textBold}>{t('tds')}</TableCell>
                    <TableCell className={classes.textBold}>{t('paid')}</TableCell>
                    <TableCell className={classes.textBold}>{t('closingBalance')}</TableCell>
                    <TableCell className={classes.textBold}>{t('openingTrayBalance')}</TableCell>
                    <TableCell className={classes.textBold}>{t('totalReturnTrays')}</TableCell>
                    <TableCell className={classes.textBold}>{t('closingTrayBalance')}</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                    <TableCell>{openingBalance.toLocaleString(user.locales,amountObj)}</TableCell>
                    <TableCell>{calcObj.billAmount.toLocaleString(user.locales,amountObj)}</TableCell>
                    <TableCell>{calcObj.tcsAmount.toLocaleString(user.locales,amountObj)}</TableCell>
                    <TableCell>{calcObj.tdsAmount.toLocaleString(user.locales,amountObj)}</TableCell>
                    <TableCell>{calcObj.paymentAmount.toLocaleString(user.locales,amountObj)}</TableCell>
                    <TableCell>{calcObj.closingBalance.toLocaleString(user.locales,amountObj)}</TableCell>
                    <TableCell>{openingTrayBalance}</TableCell>
                    <TableCell>{calcObj.totalReturnTrays}</TableCell>
                    <TableCell>{calcObj.closingTrayBalance}</TableCell>
                </TableRow>
                </TableBody>
            </Table>
        </Paper>
        }
        
        <MaterialDialog
            t={t}
            isDesktop={isDesktop}
            showDialog={showMaterialDialog}
            handleCloseDialog={handleCloseMaterialDialog}
            editMode={materialEditMode}
            eggMaterials={eggMaterials}
            onSave={onSaveMaterial}
            onUpdate={onUpdateMaterial}
            data={materialData}
            selectedLineID={selectedLineID}
        />

        <PaymentDialog
            t={t}
            showDialog={showPaymentDialog}
            handleCloseDialog={handleClosePaymentDialog}
            editMode={paymentEditMode}
            onSave={onSavePayment}
            onUpdate={onUpdatePayment}
            data={paymentData}
        />
        {/* <InvoiceDialog 
            t={t}
            showDialog={showTables}
            handleCloseDialog={() => setShowTables(false)}
            materials={materials}
            payments={payments}
            customer={customer}
            invoiceSetting={invoiceSetting}
            calcObj={calcObj}
            openingBalance={openingBalance}
            openingTrayBalance={openingTrayBalance}
            lineSaleID={lineSaleID}
        /> */}
        <InvoiceView
            t={t}
            showDialog={showTables}
            handleClose={() => setShowTables(false)}
            materials={materials}
            payments={payments}
            customer={customer}
            invoiceSetting={invoiceSetting}
            calcObj={calcObj}
            openingBalance={openingBalance}
            openingTrayBalance={openingTrayBalance}
            lineSaleID={lineSaleID}
            />
        <LineDialog showDialog={showLineDialog} />
        <Snackbar
            open={showMessage}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={() => setShowMessage(false)}
          autoHideDuration={3000}
        message='Oops! Web share only works in mobile browsers and desktop safari browser'
          />
    </div>
}

export default LineSalesDriverView;

