import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import api from "../../common/api";
import Typography from "@material-ui/core/Typography";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MaterialTable } from '../../components';
import moment from 'moment';
import PaymentIcon from '@material-ui/icons/Payment';
import {Payment,LowStockList} from './components';
import Farms from '../Farms';
import {useGlobalState} from '../../state';
import Button from "@material-ui/core/Button";
import CustomerBulkPayment from "../CustomerBulkPayments/components/customerBulkPayment";
import VendorBulkPayment from "../VendorBulkPayments/components/vendorBulkPayment";
import { version } from '../../common/enums';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Redirect } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        // padding: theme.spacing(2)
        marginTop: '0%'
    },
    content: {
        paddingTop: 20,
        textAlign: 'center'
    },
    topBar: {
        marginBottom: theme.spacing(2)
    },
    grid: props => ({
        marginTop: theme.spacing(2),
        marginRight: props.isDesktop ? theme.spacing(2) : theme.spacing(0)
    }),
    commonGrid: {
    },
    segmentationGrid: props => ({
        width: props.isDesktop ? '33%' : '100%',
        paddingRight: props.isDesktop ? theme.spacing(2) : theme.spacing(0),
        marginTop: theme.spacing(2),
        //marginBottom: theme.spacing(4),
    }),
    saleSegmentationGrid: props => ({
        width: props.isDesktop ? '33%' : '100%',
        marginTop: theme.spacing(2),
        //marginBottom: theme.spacing(4),
    }),
    purchaseSegmentationGrid: props => ({
        width: props.isDesktop ? '33%' : '100%',
        paddingRight: props.isDesktop ? theme.spacing(2) : theme.spacing(0),
        marginTop: theme.spacing(2),
        //marginBottom: theme.spacing(4),
    }),
    searchGrid: {
        padding: theme.spacing(2)
    },
    loaderGrid: {
        verticalAlign: 'middle',
        borderRight: '1px solid #bfbfbf',
        padding: theme.spacing(2),
        textAlign: 'center',
        display: 'table-cell'
    },
    netProfitGrid: props => ({
        borderRight: props.isDesktop ? '1px solid #bfbfbf' : null,
        borderBottom: props.isDesktop ? null : '1px solid #bfbfbf',
        padding: theme.spacing(2),
        textAlign: 'center',
        display: props.isDesktop ? 'table-cell' : null
    }),
    netProfitLastGrid: props => ({
        padding: theme.spacing(2),
        textAlign: 'center',
        display: props.isDesktop ? 'table-cell' : null
    }),
    segmentationGraphGrid: {
        //borderRight: '1px solid #bfbfbf',
        padding: theme.spacing(2),
        textAlign: 'center',
        display: 'table-cell'
    },
    card: {
        minHeight: '100%',
        minWidth: '94%',
        marginTop: theme.spacing(2),
        display: 'table',
        width: '98.5%',
        tableLayout: 'fixed'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    chip: {
        color: "white",
        fontWeight: 500
    },
    tableLowStock: props => ({
        paddingTop: theme.spacing(2),
        paddingRight: props.isDesktop ? theme.spacing(3) : null,
        paddingLeft: props.isDesktop ? theme.spacing(1) : null,
        "& .MuiPaper-root": {
            height: "100%"
          },
        "& .MuiTableCell-root":{
            padding:'8px'
        }
    }),
    tablePayement: props => ({
        paddingTop: theme.spacing(2),
        paddingRight: props.isDesktop ? theme.spacing(4) : null,
        "& .MuiTableCell-root" :{
            padding:'8px'
        }
    }),
    tableReceivables: props => ({
        paddingTop: theme.spacing(2),
        paddingRight: props.isDesktop ? theme.spacing(3) : null,
        "& .MuiTableCell-root" :{
            padding:'8px'
        }
    }),
    orangeChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#ff9800',
        backgroundColor: 'rgba(255, 152, 0, 0.08)',
    },
    redChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#f44336',
        backgroundColor: theme.palette.red.light,
    },
    greenChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#4caf50',
        backgroundColor: 'rgba(76, 175, 80, 0.08)'
    },
    paddingCenter: {
        padding: theme.spacing(2),
        textAlign: 'center',
        display: 'table-cell'
    },
    tab:{
        paddingBottom:theme.spacing(2),
        paddingLeft: theme.spacing(1.37),
        width:'fit-content'
    }
}));



let currentID = undefined
let currentPaymentFor = undefined

const Dashboard = (props) => {
    const { showError } = props;
    const [payments, setPayments] = useState([]);
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [receivables, setReceivables] = useState([]);
    const [receivablesLoading, setReceivablesLoading] = useState(true);
    const [banks, setBanks] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [showCustomerBulkPaymentDialog, setShowCustomerBulkPaymentDialog] = useState(false)
    const [showVendorBulkPaymentDialog, setShowVendorBulkPaymentDialog] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [multifarm,setMultifarm] = useState(false)
    const theme = useTheme();
    const [{user}] = useGlobalState();
    const [tabValue, setTabValue] = useState(1);
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }


    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const handleMultifarm = (value) => {
        setMultifarm(value)
    }


    const getPayments = () => {
        showError('');
        setPaymentsLoading(true);
        let date = new Date()
        const payload = {
            startDate: date,
            endDate: new Date(moment(date, "YYYY-MM-DD ").add(7, 'days').format('YYYY-MM-DD'))
        };

        const response = api.post('getPayments', payload);

        response.then(res => {
            const filteredData = filterAmountDifference(res)
            setPayments(filteredData);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setPaymentsLoading(false);
        });

    }
//Show data only if difference greater than 5
const filterAmountDifference = (paymentArr) => {
    const result = paymentArr.filter(element => {
        return (element?.totalBillValue - element?.totalAmountPaid) > 5;
    });

    return result;
};
    const getReceivables = () => {
        showError('');
        setReceivablesLoading(true);
        let date = new Date()
        const payload = {
            startDate: date,
            endDate: new Date(moment(date, "YYYY-MM-DD ").add(7, 'days').format('YYYY-MM-DD'))
        };

        const response = api.post('getReceivables', payload);

        response.then(res => {
           const filteredData = filterAmountDifference(res)
            setReceivables(filteredData);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setReceivablesLoading(false);
        });

    }

    //Get all Banks API Call
    const getAllBanks = () => {
        showError('');
        const response = api.get('bank');
        response.then(res => {
            setBanks([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }

     //Get all vendors API Call
     const getAllVendors = () => {
        showError('');

        const response = api.get('vendors-list');

        response.then(res => {
            setVendors([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log('No Vendors Found');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
    }

     //Get all customers API Call
     const getAllCustomers = () => {
        showError('');
        const response = api.get('customers-list');

        response.then(res => {
            setCustomers([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Customers Found');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
    }

    const onload = () => {
        if((user.role === "ADMIN" || user.role === "STAFF") && user.versionID === version.proPlus){
            getAllCustomers()
            getAllVendors()
            getAllBanks()
            getPayments()
            getReceivables()
        }
    }


    useEffect(onload, []);



    const onPurchasePayment = (payment) => {
        setPaymentsLoading(true);
        // showError('');
        const response = api.post(`purchase-payment`, {
            ...payment,
            purchaseId: currentID
        });
        response.then((res) => {
            let index = payments.findIndex((src) => {
                return src.id === currentID;
            });

            let tempPayment = payments[index]
            tempPayment = {
                ...tempPayment,
                totalAmountPaid: tempPayment.totalAmountPaid + res.paymentAmount,
                paymentStatus: res.paymentStatus
            }

            if (tempPayment.totalAmountPaid >= tempPayment.totalBillValue) {
                let newPayments = [...payments];
                newPayments.splice(index, 1);
                setPayments(newPayments);
                setShowPaymentDialog(false);
            } else {
                setPayments([...payments.slice(0, index),
                { ...tempPayment },
                ...payments.slice(index + 1)]);
                setShowPaymentDialog(false);
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('Payment Not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setPaymentsLoading(false);
        });
    };

    const onBulkSalePaymentSave = (payment, errorElement) => {
        setReceivablesLoading(true);
        // showError('');
        const response = api.post('customerBulkPayment', {
            ...payment,
        });

        response.then(res => {
            getReceivables()
            setShowCustomerBulkPaymentDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Bank not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
            if (errorElement) {
                errorElement.scrollIntoView();
            }
        }).finally(() => {
            setReceivablesLoading(false);
        });
    };

    const onBulkPurchasePaymentSave = (payment, errorElement) => {
        setPaymentsLoading(true);
        //showError('');
        const response = api.post('vendorBulkPayment', {
            ...payment,
        });

        response.then(res => {
            getPayments()
            setShowVendorBulkPaymentDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('Bank not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
            if (errorElement) {
                errorElement.scrollIntoView();
            }
        }).finally(() => {
            setPaymentsLoading(false);
        });
    };
    const onSalePayment = (receivable) => {
        setReceivablesLoading(true);
        // showError('');
        const response = api.post(`sale-payment`, {
            ...receivable,
            saleId: currentID
        });
        response.then((res) => {
            let index = receivables.findIndex((src) => {
                return src.id === currentID;
            });

            let tempReceivable = receivables[index]
            tempReceivable = {
                ...tempReceivable,
                totalAmountPaid: tempReceivable.totalAmountPaid + res.paymentAmount,
                paymentStatus: res.paymentStatus
            }

            if (tempReceivable.totalAmountPaid >= tempReceivable.totalBillValue) {
                let newPayments = [...receivables];
                newPayments.splice(index, 1);
                setReceivables(newPayments);
            } else {
                setReceivables([...receivables.slice(0, index),
                { ...tempReceivable },
                ...receivables.slice(index + 1)]);
            }
            setShowPaymentDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Payment Not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setReceivablesLoading(false);
        });
    };

    const onPayment = (type, data) => {
        currentPaymentFor = type
        currentID = data.id
        setShowPaymentDialog(true);
    };

    function handlePaymentClose() {
        currentPaymentFor = undefined
        currentID = undefined
        setShowPaymentDialog(false);
    }

    function handleCustomerBulkPaymentClose() {
        //currentPayment = undefined;
        setShowCustomerBulkPaymentDialog(false);
    }

    const onSaleBulkPayment = (data) => {
        setShowCustomerBulkPaymentDialog(true);
    };

    function handleVendorBulkPaymentClose() {
        //currentPayment = undefined;
        setShowVendorBulkPaymentDialog(false);
    }

    const onPurchaseBulkPayment = (data) => {
        setShowVendorBulkPaymentDialog(true);
    };

    let purchaseActions = [
        {
            icon: () => <Button variant="contained" color="primary" >Bulk Payments</Button>,
            tooltip: 'Bulk Payments',
            onClick: (event, rowData) => onPurchaseBulkPayment(rowData),
              isFreeAction: true
        },
        {
            icon: () => <PaymentIcon color='error'>payment</PaymentIcon>,
            tooltip: 'Purchase Payment',
            onClick: (event, rowData) => onPayment('purchase', rowData)
        }
    ];
    let saleActions = [
        {
            icon: () => <Button variant="contained" color="primary" >Bulk Receivables</Button>,
            tooltip: 'Bulk Receivables',
            onClick: (event, rowData) => onSaleBulkPayment(rowData),
              isFreeAction: true
        },
        {
            icon: () => <PaymentIcon color='error'>payment</PaymentIcon>,
            tooltip: 'Sale Payment',
            onClick: (event, rowData) => onPayment('sale', rowData)
        }
    ];
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    if(user.versionID !== version.proPlus){
        return(
            <Redirect to={"/farm"} />
        );
    }
    return (
        <div className={classes.root}>

            <Grid className={classes.searchGrid}>
                <Grid>
                    <Typography display="block" variant="h3">
                        Dashboard
                </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.commonGrid}>
                <Grid item lg={multifarm ? "12": "4"} md={multifarm ? "12": "4"}  sm={multifarm ? "12": "10"}  sx={multifarm ? "12": "8"}>
                    <Farms multiFarmDash={handleMultifarm} {...props} isMainDash={true}/>
                </Grid>

                {
                    user.role !== "SUPER" &&  user.role !== "FEEDSUPER" &&  user.role !== "DOCTOR" 
                     && user.role !== "DRIVER"  && user.versionID === version.proPlus ?

                <Grid style={{marginTop:"25px"}} lg={8} md={multifarm ? "8": "7"} sm={multifarm ? "8": "10"} sx={8} xs={12}>
                    <Tabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                        xs={12}
                        variant="scrollable"
                    >
                        <Tab value={1} label="Low Stock List" />
                        <Tab value={2} label="Upcoming Payments" />
                        <Tab value={3} label="Upcoming Receivables" />
                    </Tabs>
                    {
                    tabValue === 1 && user.role !== "SUPER" &&  user.role !== "DOCTOR"  && user.role !== "DRIVER"  && user.versionID === version.proPlus ?
                    
                        <Grid item xs={12} md={12}  className={classes.tableLowStock}><LowStockList/></Grid>
                    :""
                    }
                    {
                        tabValue === 2 && user.role !== "SUPER" &&  user.role !== "DOCTOR" && user.role !== "DRIVER"  && user.versionID === version.proPlus ? 
                            <Grid item xs={12} md={12} className={classes.tablePayement}>
                                <MaterialTable
                                    isLoading={paymentsLoading}
                                    columns={[
                                        //{ title: 'Id', field: 'id',  editable: 'never', },
                                        {
                                            title: 'Date', field: 'date', editable: 'never',
                                            render: rowData => {
                                                return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                                            }
                                        },
                                        { title: 'Vendor', field: 'companyName', editable: 'never', },
                                        //{ title: 'Items', field: 'totalMaterials' },
                                        {
                                            title: 'Amount', field: 'totalBillValue', editable: 'never',
                                            render: rowData => {
                                                return <span>{(rowData.totalBillValue).toLocaleString(user.locales,amountObj)}</span>
                                            }
                                        },
                                        {
                                            title: 'Paid', field: 'totalAmountPaid', editable: 'never',
                                            render: rowData => {
                                                return <span>{(rowData.totalAmountPaid).toLocaleString(user.locales,amountObj)}</span>
                                            }
                                        },
                                        {
                                            title: 'Payment Status', field: 'dueDays', editable: 'never',
                                            render: (rowData) => {
                                                let label
                                                let bgColor = classes.orangeChip
                                                if (rowData.dueDays > 0) {
                                                    label = "Due in " + Math.abs(rowData.dueDays) + " days"
                                                    bgColor = classes.greenChip
                                                } else if (rowData.dueDays < 0) {
                                                    label = "Over due by " + Math.abs(rowData.dueDays) + " days"
                                                    bgColor = classes.redChip
                                                } else {
                                                    label = "Today"
                                                    bgColor = classes.orangeChip
                                                }
                                                return <div style={{ textAlign: "center", minWidth: 'fit-content' }} className={bgColor}
                                                >{label}</div>
                                            }
                                        }

                                    ]}
                                    data={payments}
                                    actions={purchaseActions}
                                    title={"Upcoming Payments"}
                                    options={{
                                        actionsColumnIndex: -1,
                                        search: true,
                                        showTitle: true,
                                        toolbar: true,
                                        pageSize:(payments.length ? (payments.length > 5 ? 5 : payments.length ): 0),
                                        emptyRowsWhenPaging: false,
                                    }}
                                />
                            </Grid>
                        :""
                    }
                    { tabValue === 3 && user.role !== "SUPER" &&  user.role !== "DOCTOR"  && user.role !== "DRIVER"  && user.versionID === version.proPlus ? 
                            <Grid item  xs={12} md={12} className={classes.tableReceivables}>
                        <MaterialTable
                            isLoading={receivablesLoading}
                            columns={[
                                //{ title: 'Id', field: 'id', editable: 'never', },
                                {
                                    title: 'Date', field: 'date', editable: 'never',
                                    render: rowData => {
                                        return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                                    }
                                },
                                { title: 'Customer', field: 'companyName', editable: 'never', },
                                //{ title: 'Items', field: 'totalMaterials', editable: 'never', },
                                {
                                    title: 'Amount', field: 'totalBillValue', editable: 'never',
                                    render: rowData => {
                                        return <span>{(rowData.totalBillValue).toLocaleString(user.locales,amountObj)}</span>
                                    }
                                },
                                {
                                    title: 'Paid', field: 'totalAmountPaid', editable: 'never',
                                    render: rowData => {
                                        return <span>{(rowData.totalAmountPaid).toLocaleString(user.locales,amountObj)}</span>
                                    }
                                },
                                {
                                    title: 'Payment Status', field: 'dueDays', editable: 'never',
                                    render: (rowData) => {
                                        let label
                                        let bgColor = classes.orangeChip

                                        if (rowData.dueDays === 1) {
                                            label = "Due in " + Math.abs(rowData.dueDays) + " day"
                                            bgColor = classes.greenChip
                                        } else if (rowData.dueDays === -1) {
                                            label = "Over due by " + Math.abs(rowData.dueDays) + " day"
                                            bgColor = classes.redChip
                                        } else if (rowData.dueDays > 0) {
                                            label = "Due in " + Math.abs(rowData.dueDays) + " days"
                                            bgColor = classes.greenChip
                                        } else if (rowData.dueDays < 0) {
                                            label = "Over due by " + Math.abs(rowData.dueDays) + " days"
                                            bgColor = classes.redChip
                                        } else {
                                            label = "Today"
                                            bgColor = classes.orangeChip
                                        }
                                        return <div style={{ textAlign: "center", minWidth: 'fit-content' }} className={bgColor}
                                        >{label}</div>
                                    }
                                }

                            ]}
                            data={receivables}
                            title='Upcoming Receivables'
                            actions={saleActions}
                            options={{
                                actionsColumnIndex: -1,
                                search: true,
                                showTitle: true,
                                pageSize:(receivables.length ? (receivables.length > 5 ? 5 : receivables.length ): 0),
                                emptyRowsWhenPaging: false,
                                toolbar: true,
                            }}
                        />
                    </Grid>
                        
                        :""   
                    }
                </Grid> : ""
                }
            </Grid>
            { 
             user.role !== "SUPER" &&  user.role !== "DOCTOR"  && user.role !== "DRIVER" && user.versionID === version.proPlus?   
                <React.Fragment>      
                    <Payment deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage}  banks={banks} onSalePayment={onSalePayment} handlePaymentClose={handlePaymentClose}
                        onPurchasePayment={onPurchasePayment} showPaymentDialog={showPaymentDialog}
                        currentPaymentFor={currentPaymentFor}>
                    </Payment>
                    <CustomerBulkPayment deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} banks={banks}  onSave={onBulkSalePaymentSave} handlePaymentClose={handleCustomerBulkPaymentClose} 
                        showPaymentDialog={showCustomerBulkPaymentDialog} customers={customers} onEdit={()=>console.debug("edit clicked!")}
                        editMode={false}   data={{}}  >
                    </CustomerBulkPayment> 
                    <VendorBulkPayment banks={banks}  onSave={onBulkPurchasePaymentSave} handlePaymentClose={handleVendorBulkPaymentClose} 
                        showPaymentDialog={showVendorBulkPaymentDialog} vendors={vendors} onEdit={()=>console.debug("edit clicked!")}
                        editMode={false}   data={{}}  >
                    </VendorBulkPayment> 
                </React.Fragment>
                :""
            }
        </div>
    );
};


export default Dashboard;