
import React, {useCallback, useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//import Mobile from './components/mobile'
import moment from 'moment';
import PaymentIcon from '@material-ui/icons/Payment';
import DoneIcon from '@material-ui/icons/Done';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import Block from '@material-ui/icons/Block';
import Tooltip from '@material-ui/core/Tooltip';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import SalePaymentDetails from "./components/salePaymentDetails";
import Icon from "@material-ui/core/Icon";
import InvoiceView from './components/invoice'
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import {useGlobalState} from '../../state';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../components";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from '@material-ui/core/IconButton';
import LineSaleDriver from '../LineSaleDriver';
import LineSalesDriverView from '../LineSaleDriver/lineSalesDriver';

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    spaceBar: {
        marginLeft: theme.spacing(2.7)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    red: {
        color: 'red'
    },
    green: {
        color: 'green'
    },
    gridMargin: {
        margin: theme.spacing(1.5)
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        minWidth: 160,
    },
    searchButton: {
        color: 'white',
        width: theme.spacing(21.25),
        backgroundColor: '#EF5350',
        '&:hover': {
            backgroundColor: '#E53935',
        },
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom: theme.spacing(4)
    },
    success: {
        color: 'white',
        backgroundColor: '#4CAF50',
        '&:hover': {
            backgroundColor: '#388E3C',
        },
        width: theme.spacing(13.75),
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom: theme.spacing(4)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
}));

let currentSale = undefined;
let currentInvoice = undefined;
let editMode = false;

const LineSalesView = ({ showError, showMessage, history }) => {

    const [currentSalePayment, setCurrentSalePayment] = useState({});
    const [sales, setSales] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSaleDialog, setShowSaleDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showSalePaymentDialog, setShowSalePaymentDialog] = useState(false);
    const [banks, setBanks] = useState([]);
    const [showSaleDetailsDialog, setShowSaleDetailsDialog] = useState(false)

    const [filterSelectedStartDate, setFilterSelectedStartDate] = useState(null);
    const [filterSelectedEndDate, setFilterSelectedEndDate] = useState(null);
    const [filterSelectedCustomerId, setFilterSelectedCustomerId] = useState('');
    const [filterSelectedPaymentStatus, setFilterSelectedPaymentStatus] = useState('');
    const [doSearch, setDoSearch] = useState(false);
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [showGenerateInvoiceDialog, setShowGenerateInvoiceDialog] = useState(false)
    const [invoiceSetting, setInvoiceSetting] = useState({});

    const [lineSales, setSaleLines] = useState([]);
    const [showDriverLineSale, setShowDriverLineSale] = useState(false)

    const theme = useTheme();

    const csvHeader = ["Date", "Customer Name", "Line Name", "Item Name", "Free Quantity", "Quantity",
        "Sale Price", "Bill Value",  "TCS(%)", "Total Bill Value", "Payment Status", "Balance",
        "Payment Date", "Payment Mode", "Payment Amount"]


    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
    const classes = useStyles({ isDesktop });

    const handleFilterStartDateChange = date => {
        setFilterSelectedStartDate(date);
    };

    const handleFilterEndDateChange = date => {
        setFilterSelectedEndDate(date);
    };

    const handleFilterCustomerChange = (event) => {
        setSelectedCustomerId(event.target.value)
        setFilterSelectedCustomerId(customers[event.target.value].id)
    }

    const handleFilterPaymentStatus = (event) => {
        setFilterSelectedPaymentStatus(event.target.value)
    }

    

    function onNewSale(event) {
        editMode = false;
        event.preventDefault();
        setShowSaleDialog(true);
    }

    function handleSaleClose() {
        currentSale = undefined;
        setShowSaleDialog(false);
        getAllSales();
    }

    function handleSaleDetailsDialogClose() {
        currentSale = undefined
        setShowSaleDetailsDialog(false);
        getAllSales();
    }

    function openSalesDetailsDialog(event, rowData) {
        currentSale = rowData
        setShowSaleDetailsDialog(true)
    }

    function handleSalePaymentClose() {
        currentSale = undefined;

        setShowSalePaymentDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    function onGenerateInvoice(rowData) {
        console.debug("sales", sales, rowData)
        currentInvoice = jsonCopy(rowData);
        //console.debug("sales", sales)
        setShowGenerateInvoiceDialog(true);
    }

    function jsonCopy(src) {
        return JSON.parse(JSON.stringify(src));
    }

    function handleGenerateInvoiceClose() {
        currentInvoice = undefined;
        //console.debug("sales", sales)
        setShowGenerateInvoiceDialog(false);
    }

    const onSaleDeleteConfirm = () => {
        if (currentSale && currentSale.id) {
            setShowDeleteConfirm(false);
           // setLoading(true);
            showError('');
            const response = api.delete(`line-sale/${currentSale.id}`);

            response.then(res => {
                if (res) {
                    let index = sales.findIndex((srcSale) => {
                        return srcSale.id === currentSale.id;
                    });
                    let newSales = [...sales];
                    newSales.splice(index, 1);
                    setSales(newSales);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Sale not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentSale = undefined;
            });

        }
    };
    
        const onDelete = (sale) => {
            currentSale = sale;
            setShowDeleteConfirm(true);
        };




    const onSaleEdit = (sale) => {
        editMode = true;
        currentSale = {
            ...sale,
            // onLineSaleSwitch: sale.onLineSale === 1 ? true : false,
            customerIndex: customers.findIndex((customer) => { return customer.id === sale.customerID; }),
            lineSaleIndex: lineSales.findIndex((lineSale) => { return lineSale.id === sale.lineNameID; }),
            onLineSaleSwitch: sale.returnsID,

        };
        console.log("LineSale",lineSales);
        setShowSaleDialog(true);
        // setShowDriverLineSale(true)
    };

    const onPaymentEdit = (sale) => {
        setCurrentSalePayment({
            ...sale,
        });
        setShowSalePaymentDialog(true);
    };


    /*const onSalePaymentUpdate = (sale) => {
        setShowSalePaymentDialog(false);
        setLoading(true);
        showError('');
        const response = api.post(`sale-payment/${sale.id}`, {
            ...sale,

        });
        response.then(() => {
            let index = sales.findIndex((srcSale) => {
                return srcSale.id === sale.id;
            });
            setSales([...sales.slice(0, index),
                {...sale},
                ...sales.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Sale not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };*/

    const onSalePaymentUpdate = (payment, sale, setPopupLoading) => {
        //setShowSalePaymentDialog(false);
        //setLoading(true);
        showError('');
        const response = api.post(`line-sale-payment/${payment.id}`, {
            ...payment,

        });
        response.then(() => {
            let index = sales.findIndex((srcSale) => {
                return srcSale.id === sale.id;
            });

            let totalAmount = 0
            sale.lineSalePayments.forEach((payment, index) => {
                totalAmount = totalAmount + payment.paymentAmount
            })
            let tdsValue = sale.totalBillValue * (sale.tds / 100)
            if (totalAmount === 0) {
                sale = {
                    ...sale,
                    paymentStatus: 0
                }
            } else if (totalAmount >= sale.totalBillValue + tdsValue) {
                sale = {
                    ...sale,
                    paymentStatus: 2
                }
            } else if (totalAmount < sale.totalBillValue + tdsValue) {
                sale = {
                    ...sale,
                    paymentStatus: 1
                }
            }
            sale = {
                ...sale,
                totalAmountPaid: totalAmount
            }
            setSales([...sales.slice(0, index),
            { ...sale },
            ...sales.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Sale Payment not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            // setPopupLoading(false);
        });
    };

    const onSalePaymentSave = (payment, setPopupLoading) => {
        //setShowSalePaymentDialog(false);
        //setLoading(true);
        showError('');
        const response = api.post(`line-sale-payment`, {
            ...payment,
        });
        response.then((res) => {

            let tempCurrentSale = currentSalePayment
            if (tempCurrentSale.lineSalePayments !== undefined && tempCurrentSale.lineSalePayments !== null) {
                tempCurrentSale.lineSalePayments = [
                    ...tempCurrentSale.lineSalePayments,
                    {...res}
                ]
            } else {
                tempCurrentSale.lineSalePayments = [
                    {...res}
                ]
            }
            console.log('tempCurrentSale',tempCurrentSale);
            setCurrentSalePayment({ ...tempCurrentSale })

            let totalAmount = 0
            tempCurrentSale.lineSalePayments.forEach((payment, index) => {
                totalAmount = totalAmount + payment.paymentAmount
            })
            let tdsValue = tempCurrentSale.totalBillValue * (tempCurrentSale.tds / 100)
            if (totalAmount === 0) {
                tempCurrentSale = {
                    ...tempCurrentSale,
                    paymentStatus: 0
                }
            } else if (totalAmount >= tempCurrentSale.totalBillValue + tdsValue) {
                tempCurrentSale = {
                    ...tempCurrentSale,
                    paymentStatus: 2
                }
            } else if (totalAmount < tempCurrentSale.totalBillValue + tdsValue) {
                tempCurrentSale = {
                    ...tempCurrentSale,
                    paymentStatus: 1
                }
            }
            tempCurrentSale = {
                ...tempCurrentSale,
                totalAmountPaid: totalAmount
            }


            let index = sales.findIndex((srcSale) => {
                return srcSale.id === tempCurrentSale.id;
            });
            setSales([...sales.slice(0, index),
            { ...tempCurrentSale },
            ...sales.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Sale not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            // setPopupLoading(false);
            
        });
    };

    const onSalePaymentDelete = (payment, sale) => {
        if (payment && payment.id) {
            //setShowDeleteConfirm(false);
           // setLoading(true);
            showError('');
            const response = api.post(`line-sale-payment-delete/${payment.id}`, {
                ...payment,
            });

            response.then(res => {
                if (res) {
                    let index = sales.findIndex((srcSale) => {
                        return srcSale.id === sale.id;
                    });

                    let totalAmount = 0
                    sale.lineSalePayments.forEach((payment, index) => {
                        totalAmount = totalAmount + payment.paymentAmount
                    })
                    
                    let tdsValue = sale.totalBillValue * (sale.tds / 100)

                    if (totalAmount === 0) {
                        sale = {
                            ...sale,
                            paymentStatus: 0
                        }
                    } else if (totalAmount >= sale.totalBillValue+ tdsValue) {
                        sale = {
                            ...sale,
                            paymentStatus: 2
                        }
                    } else if (totalAmount < sale.totalBillValue+ tdsValue) {
                        sale = {
                            ...sale,
                            paymentStatus: 1
                        }
                    }
                    sale = {
                        ...sale,
                        totalAmountPaid: totalAmount
                    }

                    setSales([...sales.slice(0, index),
                    { ...sale },
                    ...sales.slice(index + 1)]);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Sale not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentSale = undefined;
            });

        }
    };

    //Get all Sales API Call
    const getAllSales = useCallback(() => {
        if (filterSelectedEndDate !== null && filterSelectedStartDate !== null &&
            filterSelectedEndDate < filterSelectedStartDate) {
            showError('Start Date is Lesser than or Equal to End Date');
            return
        }
        let payload = {
            customerId: parseInt(filterSelectedCustomerId, 10),
            startDate: filterSelectedStartDate,
            endDate: filterSelectedEndDate,
        };
        setShowSaleDialog(false);
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.post('line-sales',{...payload});
        console.log("Response from Server",response)
        response.then(res => {
            res.forEach((value)=>{
                
            })
            
            setSales([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Sales Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [filterSelectedEndDate, filterSelectedStartDate, filterSelectedCustomerId, showError, showMessage]);

    //Get all customers API Call
    const getAllCustomers = () => {
        showError('');
        showMessage('');

        const response = api.get('get-line-customers');

        response.then(res => {
            setCustomers([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Customers Found');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
    }



   


    //Get all farms API Call
    const getAllSaleLines = () => {
        showError('');
        showMessage('');

        const response = api.get('line_name');

        response.then(res => {
            setSaleLines([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
        return response;
    }

    //Get all Banks API Call
    const getAllBanks = () => {
        showError('');
        showMessage('');

        const response = api.get('bank');

        response.then(res => {
            setBanks([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }

    const getAllInvoiceSettings = () => {
        showError('');
        showMessage('');

        const response = api.get('invoiceSettings');

        response.then(res => {
            setInvoiceSetting(res[0]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No InvoiceSettings Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }

    

    const generateExcel = function (data) {
        console.log("excel ", csvHeader, data)
        let csvData = [];

        csvData = [
            ...csvHeader,
            '\r\n'
        ];


        data.forEach((row) => {
            console.debug("csvDAta ", csvData)
            let paymentAmount = ""
            let paymentDate = ""
            let paymentMode = ""

            let paidAmount = 0;
            let balanceAmount = row.totalBillValue;
            let paymentStatus = row.paymentStatus === 1 ? "Partially Paid" : row.paymentStatus === 2 ? "Paid" : "Unpaid"

            if (row.lineSalePayments !== null) {

                row.lineSalePayments.forEach((value, index)=>{
                    paidAmount = paidAmount + value.paymentAmount

                    paymentAmount = paymentAmount + value.paymentAmount + ";"
                    paymentDate = paymentDate + moment(value.paymentDate).format("DD-MM-YYYY") + ";"
                    paymentMode = paymentMode + (value.paymentMode === 0 ? '' : (value.paymentMode === 1 ? 'Cash' : value.paymentBankName.String)) + ";"
                })

                console.debug(row.id, paidAmount)
                let tdsValue = row.totalBillValue * (row.tds / 100)
                balanceAmount = (row.totalBillValue + tdsValue) - paidAmount
            }

            let tempSaleMaterials = []
            for (let i = 0; i < row.lineSaleMaterials.length; i++) {
                let material = row.lineSaleMaterials[i]
                let index = tempSaleMaterials.findIndex((tempMaterial) => {
                    return tempMaterial.materialId === material.materialId && tempMaterial.costPerUnit === material.costPerUnit
                })
                console.debug(index)
                if (index >= 0) {
                    // console.debug(tempSaleMaterials[index].billValue, material.billValue, 
                    // tempSaleMaterials[index].billValue + material.billValue)
                    tempSaleMaterials[index].billValue = tempSaleMaterials[index].billValue + material.billValue
                    tempSaleMaterials[index].quantity = tempSaleMaterials[index].quantity + material.quantity
                } else {
                    //console.debug(tempSaleMaterials)
                    tempSaleMaterials = [
                        ...tempSaleMaterials,
                        material
                    ]
                }
            }

            tempSaleMaterials.forEach((value, index) => {
                
                
                //let inventoryType = value.inventoryType === 1 ? "FeedMill" : value.farmName.String + "(" + value.shedName.String + ")"

                let column = []

                column.push(moment(row.date).format("DD-MM-YYYY"), row.customerName, row.lineName, value.materialName,
                    value.freeQuantity, value.quantity, value.costPerUnit, value.billValue, row.tds,
                    row.totalBillValue, paymentStatus, balanceAmount,
                    paymentDate, paymentMode, paymentAmount)

                console.debug(column.toString())
                csvData = csvData + column.toString() + '\r\n';
            });

        })
        console.debug("csvData", csvData)


        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
        element.setAttribute('download', 'report.csv');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);


    }

    const clearDateRangeFilter = (sale) => {
        setFilterSelectedStartDate(null)
        setFilterSelectedEndDate(null)
        setFilterSelectedCustomerId(null)
        setSelectedCustomerId(null)
        setFilterSelectedPaymentStatus(null)
        setDoSearch(true);
    }

    useEffect(() => {
        //doSearch flag is set when search button is clicked.
        if (doSearch) {
            getAllSales();
            setDoSearch(false);
        }

    }, [doSearch, getAllSales]);

      
  
    const onload = () => {
        setLoading(true);
        Promise.all([getAllCustomers(),
        getAllBanks(),
        getAllSales(),
        getAllInvoiceSettings(),
        getAllSaleLines(),]).finally(() => {
            setLoading(false);
        });
    };

    useEffect(onload, []);



    let saleNameColumn = {
        title: 'Customer Name', field: 'customerName', editable: 'never',
        cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
        render: rowData => {
            return <span>{rowData.returnsID ? 'Returns':rowData.customerName}</span>
        }
    };
    /* <Mobile data={rowData}
             onPaymentEdit={() => {
                onPaymentEdit(rowData)
                }} />
             /*onEdit={() => {
                onSaleEdit(rowData)
                }}
                onDelete={() => {
                onDelete(rowData)*/
    let actions = [
        (rowData) => {
            return rowData.returnsID === 1 
              ? {  
                icon: () => <span className={classes.spaceBar} />,
                disabled: true, 
                }
              : {  
                icon: () => <PaymentIcon color='error'>payment</PaymentIcon> ,
                tooltip: 'Payment Details',
                onClick: (event, rowData) => onPaymentEdit(rowData)
            }
         },
        {
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit sale Details',
            onClick: (event, rowData) => onSaleEdit(rowData)
        },
        {
            icon: () => <DescriptionIcon color='primary'>Generate Invoice</DescriptionIcon>,
            tooltip: 'Generate Invoice',
            onClick: (event, rowData) => onGenerateInvoice(rowData)
        },
        {
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete Sale Details',
            onClick: (event, rowData) => onDelete(rowData)
        }
    ];
   
    /*if (isDesktop) {
        /*saleNameColumn = {title: 'Customer Name', field: 'customerName', hidden: !isDesktop, editable: 'never', defaultSort:'asc',
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'}};
         /*{
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit sale Details',
                onClick: (event, rowData) => onSaleEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete sale Details',
                onClick: (event, rowData) => onDelete(rowData)
            }
        actions = [
            {
                icon: () => <PaymentIcon color='error'>payment</PaymentIcon>,
                tooltip: 'Payment Details',
                onClick: (event, rowData) => onPaymentEdit(rowData)
            }
        ]
    }*/


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Line Sales
                </Typography>
            </Grid>
            <Grid  container
                  spacing={3}
                  justify="flex-end"
                  alignItems="center">
                <Grid>
                    <Button variant="contained" color="primary" onClick={onNewSale}>Add Line sale</Button>
                </Grid>
                <Grid item spacing={1}>
                    <Button variant="outlined" color="primary"
                        disabled={sales.length < 1}
                        onClick={() => generateExcel(sales)}
                        endIcon={<GetAppIcon />}>Download Excel</Button>
                </Grid>
            </Grid>
        </Grid>
        <Grid container
            spacing={3}
            justify="flex-start"
            alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    className={classes.formControl}
                    margin="normal"
                    autoOk
                    variant="inline"
                    label="From"
                    format="dd/MM/yyyy"
                    value={filterSelectedStartDate || null}
                    onChange={handleFilterStartDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardDatePicker
                    className={classes.formControl}
                    margin="normal"
                    autoOk
                    variant="inline"
                    label="To"
                    format="dd/MM/yyyy"
                    value={filterSelectedEndDate || null}
                    onChange={handleFilterEndDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
            <FormControl className={classes.formControl}>
                <InputLabel id="customerName-select-outlined-label">
                    Customer Name
                    </InputLabel>
                <Select
                    id="customerName-select"
                    value={selectedCustomerId !== undefined ? selectedCustomerId : ''}
                    onChange={handleFilterCustomerChange}
                    name="customerId"
                >
                    {customers !== undefined && customers !== null ?
                        customers.map((customer, index) =>
                            <MenuItem key={customer.id} value={index}>{customer.companyName}</MenuItem>
                        ) : ""}
                </Select>
            </FormControl>
            {true ?
                "" :
            <FormControl className={classes.formControl}>
                <InputLabel id="filterSelectedPaymentStatus-select-outlined-label">
                    Payment Status
                    </InputLabel>
                <Select
                    id="filterSelectedPaymentStatus"
                    value={filterSelectedPaymentStatus !== undefined ? filterSelectedPaymentStatus : ''}
                    onChange={handleFilterPaymentStatus}
                    name="filterSelectedPaymentStatus"
                >
                    <MenuItem key={0} value={3}>Paid</MenuItem>
                    <MenuItem key={1} value={2}>Partially Paid</MenuItem>
                    <MenuItem key={2} value={1}>Not Paid</MenuItem>
                </Select>
            </FormControl>
            }
            <Grid>
                <Button onClick={() => setDoSearch(true)} className={classes.success}>Search <SearchIcon /></Button>
                <Button onClick={clearDateRangeFilter} className={classes.searchButton}>Clear Search <CloseIcon /></Button>
            </Grid>
        </Grid>

        <Dialog disableBackdropClick fullWidth fullScreen
    maxWidth='xl' 
    open={showDriverLineSale} onClose={() => setShowDriverLineSale(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" >New lin sale 
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => setShowDriverLineSale(false)}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
            <LineSaleDriver />
          </DialogContent>
          </Dialog>

        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'id', field: 'id', hidden: true },
                { title: 'Id', field: 'displayId', editable: 'never' },
                {
                    title: 'Date', field: 'date', type: 'datetime', 
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    editable: 'never',
                    render: rowData => {
                        return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                    }
                },
                saleNameColumn,
                { title: 'Line Name', field: 'lineName', hidden: !isDesktop, editable: 'never' },             
                {
                    title: 'Bill Value', field: 'totalBillValue', editable: 'never',
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    render: rowData => {
                        return <span>{(rowData.totalBillValue).toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'TCS', field: 'tds', editable: 'never',
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        return <span>{(rowData.totalBillValue * (rowData.tcs / 100)).toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'TDS', field: 'tds', editable: 'never',
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        return <span>{(rowData.totalBillValue * (rowData.tds / 100)).toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'Remaining Amount', field: 'remainingAmount', editable: 'never',
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    render: rowData => {
                        let tdsValue = rowData.totalBillValue * (rowData.tds / 100)
                        let tcsValue = rowData.totalBillValue * (rowData.tcs / 100)

                        let reminingAmount = (((rowData.totalBillValue + tcsValue) - tdsValue) - rowData.totalAmountPaid);

                        if (rowData.paymentStatus === 1 && reminingAmount < 1) {
                            reminingAmount = 0
                        }

                        return <span>{reminingAmount.toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'Payment Status', field: 'paymentStatus', editable: 'never',
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    render: (rowData) => {
                        let statusIcon = [
                        <Tooltip title="Not Paid"><CloseIcon className={classes.red} /></Tooltip>,
                        <Tooltip title="Tax Not Paid"><CancelIcon style={{fontSize:"12"}}  className={classes.red} /></Tooltip>
                        ]
                        
                        let billValue = 0
                        if (rowData.lineSaleMaterials) {
                            rowData.lineSaleMaterials.forEach(material => {
                                billValue += ((material.quantity) * 30 * material.costPerUnit)
                            })
                        }
                                       

                        // let tdsValue = rowData.totalBillValue * (rowData.tds / 100)
                        let remaningBalance = (billValue + ((billValue * (rowData.tcs / 100))) - (billValue * (rowData.tds/100)) - rowData.totalAmountPaid)

                        let remaningBalanceWithoutTaxes = ((billValue * (rowData.tcs / 100)) + (billValue * (rowData.tds/100))) - rowData.totalTaxAmountPaid

                        if (rowData.paymentStatus === 1 && remaningBalance >= 1) {
                            statusIcon.shift()
                            statusIcon.unshift(<Tooltip title="Partially Paid"><QueryBuilderIcon /></Tooltip>)
                        } else if (rowData.paymentStatus === 2 || (rowData.paymentStatus === 1 && remaningBalance < 1)) {
                            statusIcon.shift()
                            statusIcon.unshift(<Tooltip title="Paid"><DoneIcon className={classes.green} /></Tooltip>)
                        }

                        if (rowData.totalTaxAmountPaid > 0 && remaningBalanceWithoutTaxes > 0) { // Partially paid
                            statusIcon.pop()
                            statusIcon.push(<Tooltip title="Tax Partially Paid"><QueryBuilderIcon style={{fontSize:"12"}}  /></Tooltip>)
                        } else if (rowData.totalTaxAmountPaid > 0 && remaningBalanceWithoutTaxes <= 0) { // Fully paid
                            statusIcon.pop()
                            statusIcon.push(<Tooltip title="Tax Paid"><CheckCircleIcon style={{fontSize:"12"}}  className={classes.green} /></Tooltip>)
                        }
                        if (rowData.tcs === 0 && rowData.tds === 0) {
                            statusIcon.pop()
                        }

                        return <div style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center"
                        }} >{statusIcon}</div>
                    }
                }

            ]}
            data={sales}
            title='Line Sales Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,

                toolbar: true,
            }}
            onRowClick={openSalesDetailsDialog}
            actions={actions}
        />
            <Dialog disableBackdropClick maxWidth='md' open={showSaleDialog} onClose={handleSaleClose}  aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog- title"   >{editMode ? 'Update' : 'New'} Line Sale
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleSaleClose}>
                    <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                     <LineSalesDriverView adminLineSales={showSaleDialog} lineSaleEditView={editMode}  LinesaleData={currentSale}/>
                </DialogContent>
            </Dialog>
        <InvoiceView showDialog={showGenerateInvoiceDialog} handleClose={handleGenerateInvoiceClose}
            data={currentInvoice}
            invoiceSetting={invoiceSetting} />

        <SalePaymentDetails showPaymentDetailsDialog={showSalePaymentDialog} handlePaymentDetailsClose={handleSalePaymentClose}
            onPaymentEdit={onSalePaymentUpdate} data={currentSalePayment} banks={banks} onSalePaymentSave={onSalePaymentSave}
            onSalePaymentDelete={onSalePaymentDelete} loading={loading} />

        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onSaleDeleteConfirm}
            title='Delete Sale ?'
            subText='This action will delete the sale Details. Please confirm.' />

        <Dialog disableBackdropClick maxWidth='md' open={showSaleDetailsDialog} onClose={handleSaleDetailsDialogClose}  aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog- title"   > Line Sale
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleSaleDetailsDialogClose}>
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <LineSalesDriverView lineSaleView={showSaleDetailsDialog}  adminLineSales={showSaleDialog} LinesaleData={currentSale}/>
            </DialogContent>
        </Dialog>
    </div>
}

export default LineSalesView;