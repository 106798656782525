import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import api from '../../common/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import {useGlobalState} from '../../state';
import { Alert } from '@material-ui/lab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';

import SubscriptionCardsAnnual from './components/SubscriptionCardsAnnual';
import SubscriptionCardsMonthly from './components/SubscriptionCardsMonthly';
import { version } from '../../common/enums';
import { isUtcDateTimeExpired } from '../../common/date';

const useStyles = makeStyles(theme => ({
    root: props=>({
        marginLeft: props.isDesktop?theme.spacing(1):theme.spacing(1),
        marginRight: props.isDesktop?theme.spacing(4):theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
}));

const planReport = {
    id:0,
    planId:"",
    planAmount:0,
    birdCountMin:0,
    birdCountMax:0,
    planType:0,

}

const Subscriptions = ({showError,history}) => {
    const [{user}, dispatch] = useGlobalState();
    const [planReports, setPlanReports] = useState({});
    const [plans, setPlans] = useState({
        [version.lite] : [],
        [version.pro] : [],
        [version.proPlus] : []
    });
    const [currentPlan, setCurrentPlan] = useState(0);
    const [trialDate,setTrialDate] = useState();
    const [activeBirdCount, setActiveBirdCount] = useState({});
    const [loading, setLoading] = useState(false);
    const subscriptionStatus= (user.subscriptionStatus)
    const [versionTab,setVersionTab] = React.useState(version.lite);
    const [tabValue, setTabValue] = React.useState(1);
    const [showBillingConform,setBillingMobileConform] = useState(false)

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});
    const billingRedirect = (e)=>{
        let billingURl =  window.location.href
        window.open(billingURl, "_blank");
        return
    }
    function handlebillingConfirmClose() {
        setBillingMobileConform(false);
      }
      function handlebillingConfirmOpen() {
        setBillingMobileConform(true);
      }
    const getAllPlans = () => {
        setLoading(true);
        showError('');
        const response = api.get('subscriptionplan');
        response.then(res => {
            var tPlans = {...plans};
            res.forEach((c)=>{
                tPlans[c.version_id].push(c);
            });
            setPlans(tPlans);
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Plans Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        return response
    };

    const getAllPlansDashboardReport = () => {
        setLoading(true);
        showError('');
        const response = api.get('getAllPlansDashboardReport');
        response.then(res => {
            setPlanReports({...res.plans});
            setCurrentPlan(res.currentPlan)
            //setActiveBirdCount(res.currentBirdCount)
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Plans Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        return response
    };

    const getActiveBirdCount = () => {
        setLoading(true);
        showError('');
        const response = api.get('getActiveBirdCount');
        response.then(res => {
            setActiveBirdCount(res.currentBirdCount)
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Birds Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        return response
    };
    

    const onload=()=>{
        setLoading(true);
            Promise.all([getAllPlans(),getActiveBirdCount(),getAllPlansDashboardReport(), getTrialDate()]).finally(()=>{
                setLoading(false);
            });
    
    }
    useEffect(onload, []);
    // async function validateSignature(request) {
    //     let response = await fetch(
    //         process.env.REACT_APP_APIURL +'/validateSignature',
    //         {
    //             method: 'POST',
    //             headers: {
    //               'Accept': 'application/json',
    //               'Content-Type': 'application/json',
    //               'Authorization': `Bearer ${localStorage.getItem('token')}`
    //             },
    //             body: JSON.stringify({
    //                 razorpaySubscriptionId: request.razorpay_subscription_id,
    //                 paymentId:request.razorpay_payment_id,
    //                 signature: request.razorpay_signature
    //             })
    //         }
    //     );
    //     let data = await response.json();
    //     return data;
    //   }
    async function verifyHostPage(hostPageId,renew) {
        let response = await fetch(
            process.env.REACT_APP_APIURL +'/verifyHostPage',
            {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    hostedPageId: hostPageId,
                    renew:renew
                })
            }
        );
        let data = await response.json();
        return data;
      }
    // var options = {
    //     "key": "",
    //     "subscription_id": "",
    //     "name": "POULTRY PRO PLUS",
    //     "description": "",
    //     "image": "/images/chick.webp",
    //     handler: async (request) => {
    //         try {
    //             console.log(request);
    //             validateSignature(request).then(data =>{
    //                 console.log(data);
    //                 if(data.validationStatus === 1){
    //                     console.log("Setting success to true");
    //                     user.subscriptionStatus = true
    //                     dispatch({
    //                         type: 'setProfile',
    //                         user: user
    //                     });
    //                     setCurrentPlan(data.planId);
    //                 }
    //                 else
    //                 alert("Failed to update subscription! Please try later")
                    
    //             });
        
    //         } catch (err) {
    //             console.log(err);
    //         }
    //     },
    //     "theme": {
    //       "color": "#39A3DD"
    //     }
    //   };

    const onUpdatePlanForUsers = (plan) => {
        setLoading(true);
        var renew = false;
        if(currentPlan?.endDate){
            if (currentPlan.endDate.Valid && !isUtcDateTimeExpired(currentPlan.endDate.Time) && plan.id === currentPlan.id) {
                     renew = true;
                }
        }
        const response = api.post(`updatePlanForUser`,{
            planId: plan.id,
            renew:renew
        });

        response.then(res => {
            
            var chbee = window.Chargebee.init({site: res.site});
            console.log(chbee);
            chbee.openCheckout({
                 hostedPage: ()=>{
                    return new Promise((resolve,reject)=>{
                        resolve(res.hostedPage)
                    })
                 },
                 success(hostedPageId) {
                    try {
                        verifyHostPage(hostedPageId,renew).then(data =>{
                            if(data.validationStatus === 1){
                                user.subscriptionStatus = true
                                dispatch({
                                    type: 'setProfile',
                                    user: user
                                });
                                setCurrentPlan(data.planId);
                            }
                            else
                            alert("Failed to update subscription! Please try later")
                            
                        });
                
                    } catch (err) {
                        console.log(err);
                    }
                },
             });
            // options.subscription_id = res.razorpaySubscriptionId
            // options.description = plan.tier
            // options.key = res.keyId
            // console.log(options);
            // let rzp1 = new window.Razorpay(options);
            // rzp1.open();
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Batch not added');
                else if(err.message ===  'Duplicate Key')
                    showError('Duplicate Name');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
       
    };
    const cancelPlanForUser = (plan) => {
        setLoading(true);
        const response = api.post(`cancelPlanForUser`);

        response.then(res => {
            user.subscriptionStatus = false
            dispatch({
                type: 'setProfile',
                user: user
            });
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Plan not cancelled');
                else if(err.message ===  'Duplicate Key')
                    showError('Duplicate Name');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setCurrentPlan(0)
        });
       
    };
    const getTrialDate = () => {
        //setLoading(true);
        const response = api.get(`getTrialDate`);

        response.then(res => {
            setTrialDate(res)
            dispatch({
                type: 'setProfile',
                user: user
            });
        }).catch(err => {
            if((currentPlan.id!==0&&currentPlan!==0)) {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Trial Date not found');
                else if(err.message ===  'Duplicate Key')
                    showError('Duplicate Name');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }
        }).finally(() => {
            setLoading(false);
        });
       
    };

    const handleVersionTabChange = (event, newValue) => {
        setVersionTab(newValue);
    };
    const handleTabChange = (event, newValue) => {
        console.debug(event,newValue)
        setTabValue(newValue);
    };
    
    return (
        <div className={classes.root}>
            <Grid
                container
                justify='space-between'
                alignItems='flex-end'
                className={classes.topBar}
            >
                

               
            </Grid>
            <Grid
            container
            spacing={3}
            variant = "h2">
                {
                subscriptionStatus?
                (currentPlan.id!==0&&currentPlan!==0)?
                <Alert severity="success" className={classes.progressContainer}>
                    <Typography display="block" variant="h3" textalign="center">
                        Subscription : Active
                    </Typography>
                </Alert>
                :
                (
                <Alert severity="success" className={classes.progressContainer}>
                    <Typography display="block" variant="h3" textalign="center">
                    Active until : {trialDate}
                    </Typography>
                </Alert>)
                :
                <Alert severity="error" className={classes.progressContainer}>
                    <Typography display="block" variant="h3" textAlign="center">
                        Your trial has ended. Please buy a subscription.
                    </Typography>
                    <Button
                    color="primary"
                    variant="contained"
                    onClick={
                        () => dispatch({type: 'logout'})
                    }
                >
                    Logout
                </Button>
                </Alert>
                }
            </Grid>
            <Grid>
            <br></br>
            <Grid>
            <Alert severity="info" className={classes.progressContainer}>
                    <Typography display="block" variant="h4" textAlign="center">
                        Active Bird Count : {activeBirdCount !== null && activeBirdCount !== undefined ? 
                            (activeBirdCount).toLocaleString(user.locales) : 0}
                    </Typography>
                </Alert>
            </Grid>
            <br></br>
    <Grid className={classes.progressContainer} >
        <Tabs
            value={versionTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleVersionTabChange}
        >
            <Tab value={version.lite} label="Lite" />
            <Tab value={version.pro} label="Pro" />
            <Tab value={version.proPlus} label="Pro +" />
        </Tabs>
    </Grid>
    <Grid className={classes.progressContainer} >
        <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
        >
            <Tab value={1} label="Monthly" />
            <Tab value={2} label="Annual" />
        </Tabs>
    </Grid>
    <br></br>
            </Grid>
            { tabValue === 1?
                loading ?
                    <div className={classes.progressContainer}>
                        <CircularProgress className={classes.progressBar}/>
                    </div> :
                    <Grid
                        container
                        spacing={3}
                    >
                        {
                            plans[versionTab].map((plan) => {
                                return <SubscriptionCardsMonthly
                                showBillingConform={showBillingConform}
                                handlebillingConfirmClose={handlebillingConfirmClose}
                                billingRedirect={billingRedirect}
                                handlebillingConfirmOpen={handlebillingConfirmOpen}
                                isDesktop={isDesktop} history={history} key={plan.id} showError={showError}
                                planReport={planReports[plan.id] ? planReports[plan.id] : planReport} 
                                plan={plan} onUpdatePlanForUsers={onUpdatePlanForUsers} cancelPlanForUser={cancelPlanForUser}
                                currentPlan={currentPlan}/>
                            })
                        }
                    </Grid>
                    :''
            }
            { tabValue === 2?
                loading ?
                    <div className={classes.progressContainer}>
                        <CircularProgress className={classes.progressBar}/>
                    </div> :
                    <Grid
                        container
                        spacing={3}
                    >
                        {
                            plans[versionTab].map((plan) => {
                                return <SubscriptionCardsAnnual
                                showBillingConform={showBillingConform}
                                handlebillingConfirmClose={handlebillingConfirmClose}
                                billingRedirect={billingRedirect}
                                handlebillingConfirmOpen={handlebillingConfirmOpen}
                                isDesktop={isDesktop} history={history} key={plan.id} showError={showError}
                                planReport={planReports[plan.id] ? planReports[plan.id] : planReport} 
                                plan={plan} onUpdatePlanForUsers={onUpdatePlanForUsers} cancelPlanForUser={cancelPlanForUser}
                                currentPlan={currentPlan}/>
                            })
                        }
                    </Grid>
                    :''
            }
        </div>
    );
};

export default Subscriptions;