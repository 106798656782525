import React, { useEffect, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import makeStyles from '@material-ui/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import api from '../../common/api';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { parse } from 'query-string';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment-timezone';
var queryString = {};
const parsed = parse(window.location.search);
console.log(parsed);
queryString = parsed;

const schema = {
    companyName: {
        presence: (queryString.e !== undefined && queryString.e !== "" ? false :
            { allowEmpty: false, message: 'is required' }),
        length: {
            maximum: 64
        }
    },
    firstName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 64
        }
    },
    lastName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 64
        }
    },
    email: {
        presence: (queryString.e !== undefined && queryString.e !== "" ? false :
            { allowEmpty: false, message: 'is required' }),
        //presence: {allowEmpty: true, message: 'is required'},
        email: (queryString.e !== undefined && queryString.e !== "" ? false : true),
        length: {
            maximum: 64
        }
    },
    phoneNumber: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 64
        }
    },
    timezone: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    designation: {
        presence: (queryString.e !== undefined && queryString.e !== "" ? false :
            { allowEmpty: false, message: 'is required' }),       // presence: {allowEmpty: true, message: 'is required'},
        length: {
            maximum: 64
        }
    },
    countryNameID: {
        presence: (queryString.r !== undefined && queryString.r !== "" ? false :
            { allowEmpty: false, message: 'is required' }),        //presence: {allowEmpty: true, message: 'is required'}
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            minimum: 8,
            maximum: 128
        }
    },
    passwordConfirm: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            minimum: 8,
            maximum: 128
        },
        equality: {
            attribute: "password",
            message: "Passwords should match"
        }
    },
    policy: {
        presence: { allowEmpty: false, message: 'is required' },
        checked: true
    }
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%'
    },
    errorText: {
        color: theme.palette.error
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    grid: {
        height: '100%'
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/images/chick.webp)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    quoteText: {
        color: theme.palette.white,
        fontWeight: 300
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    knowMoreButton:{
        color: theme.palette.primary.main,
        fontWeight:500
    },
    bio: {
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(2),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
    contentBody: {
        height: '60%',
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    form: {
        height: '100%',
        overflowY: 'scroll',
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 50,
        marginBottom: 50,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(3)
    },
    textField: {
        marginTop: theme.spacing(2),

    },
    policy: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center'
    },
    policyCheckbox: {
        marginLeft: '-14px'
    },
    signUpButton: {
        margin: theme.spacing(2, 0)
    },
    formselect: {
        marginTop: theme.spacing(2),
    },
    selectOption: {
        width: "100%",
    },
    logo: {
        width: '36.985373171051904%',
        alignSelf: 'start',
        marginBottom: '20px'
    },
    logoContainer: {
        backgroundColor: "#404040",
    }
}));



const SignUp = props => {
    const { history } = props;


    const classes = useStyles();

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });
    const [loading, setLoading] = useState(false);
    const [apiErrorText, setApiErrorText] = useState(undefined);
    const [countryNames, setCountryNames] = useState([]);
    const [isTermsOpen, setIsTermsOpen] = useState(false);

    const [timezones, setTimezones] = useState([]);
    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
        }));
    }, [formState.values]);


    const handleTermsOpenChange = (event) => {
        event.preventDefault();
        setIsTermsOpen(!isTermsOpen)
    }

    const handleChange = event => {
        event.persist();
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleBack = () => {
        history.goBack();
    };


    const handleCountryNameChange = (event) => {
        let countrycode = countryNames[event.target.value]?.countryCode
        fetchTimezones(countrycode)
        handleChange(event)
    };

    const fetchTimezones =  (code) => {
          let timezone = moment.tz.zonesForCountry(code);
          if(timezone) {
              setTimezones(timezone);
              setFormState(formState => ({
                ...formState,
                values: {
                    ...formState.values,
                    timezone:timezone[0]
                }
            }));
          }
      };

    //Get all countries API Call
    async function getAllCountryName() {

        const response = api.get('country-names');
        response.then(res => {
            setCountryNames([...res]);

        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setApiErrorText('No Country name Found Catch Error');
                else
                    setApiErrorText(err.message)
            } else {
                setApiErrorText('Unknown Error')
            }
        });

        return response;
    }
    async function readFromQueryString() {
        /* const parsed = parse(props.location.search);
         console.log(parsed);
         setQueryString(parsed);*/
    }
    useEffect(() => {
        getAllCountryName()
        readFromQueryString()
        /*if(parsed.e !== ""){
            setFormState(()=>{
                return {
                ...formState,
                email:parsed.e
            }
        })
        }*/
    }, []);



    const handleSignUp = event => {
        let tempCountryNameID = formState.values.countryNameID + 1

        setLoading(true);
        setApiErrorText(undefined);
        event.preventDefault();
        const response = api.post('sign-up', {
            "firstName": formState.values.firstName,
            "lastName": formState.values.lastName,
            "email": formState.values.email || queryString.e,
            "phoneNumber": formState.values.phoneNumber,
            "designation": formState.values.designation,
            "password": formState.values.password,
            "countryNameID": tempCountryNameID,
            "role": queryString.r !== undefined && queryString.r === "1" ? "DOCTOR" : "",
            "companyName": (queryString.e === undefined || queryString.e === "") ? formState.values.companyName : "",
            "timezone": formState.values.timezone,
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Duplicate Key' || err.message === 'duplicate key')
                    setApiErrorText('User Already Registered');
                else
                    setApiErrorText(err.message)
            } else {
                setApiErrorText('Unknown Error')
            }
            setLoading(false);
        });

        response.then(res => {
            if (res === true) {
                history.push('/success-registration');
            }
        });


    };

    const hasError = field =>
        !!(formState.touched[field] && formState.errors[field]);

    return (
        <div className={classes.root}>
            <Grid
                className={classes.grid}
                container
            >
                <Grid
                    className={classes.quoteContainer}
                    item
                    lg={5}
                >
                    <div className={classes.quote}>
                    </div>
                </Grid>
                <Grid
                    className={classes.content}
                    item
                    lg={7}
                    xs={12}
                >
                    <div className={classes.logoContainer}>
                        <Hidden lgUp>
                                <img
                                    className={classes.logo}
                                    alt="Logo"
                                    src="/images/logos/poultry-client-mobile-view.png"
                                />
                        </Hidden></div>
                    <div className={classes.content}>
                        <div className={classes.contentHeader}>
                            <IconButton onClick={handleBack}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div className={classes.contentBody}>
                            {
                                loading ?
                                    <div className={classes.progressContainer}>
                                        <CircularProgress className={classes.progressBar} />
                                    </div> :
                                    <form
                                        className={classes.form}
                                        onSubmit={handleSignUp}
                                    >
                                        {
                                            apiErrorText ?
                                                <Chip color="secondary" icon={<ErrorIcon />}
                                                    label={apiErrorText} /> :
                                                undefined
                                        }
                                        <Typography
                                            className={classes.title}
                                            variant="h2"
                                        >
                                            Create new account
                                        </Typography>
                                        <Typography
                                            color="textSecondary"
                                            gutterBottom
                                        >
                                            Use your email to create new account
                                        </Typography>
                                        {(queryString.e === undefined || queryString.e === "") ?
                                            <TextField
                                                className={classes.textField}
                                                autoComplete="companyName"
                                                error={hasError('companyName')}
                                                fullWidth
                                                helperText={
                                                    hasError('companyName') ? formState.errors.companyName[0] : null
                                                }
                                                label="Company Name"
                                                name="companyName"
                                                onChange={handleChange}
                                                type="text"
                                                value={formState.values.companyName || ''}
                                                variant="outlined"
                                            />
                                            : ""
                                        }
                                        <TextField
                                            className={classes.textField}
                                            autoComplete="firstName"
                                            error={hasError('firstName')}
                                            fullWidth
                                            helperText={
                                                hasError('firstName') ? formState.errors.firstName[0] : null
                                            }
                                            label="First Name"
                                            name="firstName"
                                            onChange={handleChange}
                                            type="text"
                                            value={formState.values.firstName || ''}
                                            variant="outlined"
                                        />
                                        <TextField
                                            className={classes.textField}
                                            autoComplete="lastName"
                                            error={hasError('lastName')}
                                            fullWidth
                                            helperText={
                                                hasError('lastName') ? formState.errors.lastName[0] : null
                                            }
                                            label="Last Name"
                                            name="lastName"
                                            onChange={handleChange}
                                            type="text"
                                            value={formState.values.lastName || ''}
                                            variant="outlined"
                                        />
                                        {(queryString.e === undefined || queryString.e === "") ?

                                            <TextField
                                                className={classes.textField}
                                                autoComplete="email"
                                                error={hasError('email')}
                                                fullWidth
                                                helperText={
                                                    hasError('email') ? formState.errors.email[0] : null
                                                }
                                                label="Email address"
                                                name="email"
                                                onChange={handleChange}
                                                type="text"
                                                defaultValue={queryString.e || ''}
                                                value={formState.values.email || ''}
                                                variant="outlined"
                                            />
                                            : ""
                                        }
                                        <TextField
                                            className={classes.textField}
                                            autoComplete="phoneNumber"
                                            error={hasError('phoneNumber')}
                                            fullWidth
                                            helperText={
                                                hasError('phoneNumber') ? formState.errors.phoneNumber[0] : null
                                            }
                                            label="Phone Number"
                                            name="phoneNumber"
                                            onChange={handleChange}
                                            type="number"
                                            value={formState.values.phoneNumber || ''}
                                            variant="outlined"
                                        />
                                        {
                                            queryString.r !== "1" ?

                                                <TextField
                                                    className={classes.textField}
                                                    autoComplete="designation"
                                                    error={hasError('designation')}

                                                    fullWidth
                                                    helperText={
                                                        hasError('designation') ? formState.errors.designation[0] : null
                                                    }
                                                    label="Designation"
                                                    name="designation"
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={formState.values.designation || ''}
                                                    variant="outlined"
                                                />
                                                : ""
                                        }
                                        {
                                            queryString.r !== "1" ?
                                                <FormControl variant="outlined" className={classes.formselect} fullWidth>
                                                    <InputLabel>Country</InputLabel>
                                                    <Select
                                                        className={classes.selectOption}
                                                        id="countryList"
                                                        value={formState.values.countryNameID !== undefined ? formState.values.countryNameID : ''}
                                                        onChange={handleCountryNameChange}
                                                        name="countryNameID"
                                                        label="Country"
                                                        fullWidth
                                                    >
                                                        {countryNames !== undefined && countryNames !== null ?
                                                            countryNames?.map((country, index) =>
                                                                <MenuItem key={country.ID} value={index}>{country.countryName}</MenuItem>
                                                            ) : ""}
                                                    </Select>
                                                </FormControl>
                                                : ""
                                        }
                                           {
                                            formState.values.countryNameID !== ""  && formState.values.countryNameID !== undefined ?
                                                <FormControl variant="outlined" className={classes.formselect} fullWidth>
                                                    <InputLabel>TimeZone</InputLabel>
                                                    <Select
                                                        className={classes.selectOption}
                                                        id="=timezonelist"
                                                        value={formState.values.timezone !== undefined ? formState.values.timezone :  (timezones && timezones[0])}
                                                        onChange={handleChange}
                                                        name="timezone"
                                                        label="timezone"
                                                        fullWidth
                                                    >

                                                            {timezones?.map((tz, index) => (
                                                            <MenuItem key={index} value={tz}>
                                                                {tz}
                                                            </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                                : ""
                                        }
                                        <TextField
                                            className={classes.textField}
                                            autoComplete="new-password"
                                            error={hasError('password')}
                                            fullWidth
                                            helperText={
                                                hasError('password') ? formState.errors.password[0] : null
                                            }
                                            label="Password"
                                            name="password"
                                            onChange={handleChange}
                                            type="password"
                                            value={formState.values.password || ''}
                                            variant="outlined"
                                        />
                                        <TextField
                                            className={classes.textField}
                                            autoComplete="new-password"
                                            error={hasError('passwordConfirm')}
                                            fullWidth
                                            helperText={
                                                hasError('passwordConfirm') ? formState.errors.passwordConfirm[0] : null
                                            }
                                            label="Confirm Password"
                                            name="passwordConfirm"
                                            onChange={handleChange}
                                            type="password"
                                            value={formState.values.passwordConfirm || ''}
                                            variant="outlined"
                                        />
                                        <div className={classes.policy}>
                                            <Checkbox
                                                checked={formState.values.policy || false}
                                                className={classes.policyCheckbox}
                                                color="primary"
                                                name="policy"
                                                onChange={handleChange}
                                            />
                                            <Typography
                                                className={classes.policyText}
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                I have read the{' '}
                                                <Link
                                                    color="primary"
                                                    component='button'
                                                    // to="#"
                                                    underline="always"
                                                    variant="h6"
                                                    onClick={handleTermsOpenChange}
                                                >
                                                    Terms and Conditions
                                                </Link>
                                            </Typography>
                                        </div>

                                        <Dialog disableBackdropClick
                                            open={isTermsOpen}
                                            aria-labelledby="form-dialog-title">
                                            <DialogTitle id="form-dialog-title" > Terms and Conditions
                                                <IconButton
                                                    aria-label="close"
                                                    className={classes.closeButton}
                                                    onClick={handleTermsOpenChange}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </DialogTitle>
                                            <DialogContent >
                                                <Typography>This privacy policy explains how we use any personal information we collect about you when
                                                    you use this website.
                                                    <br />
                                                    <br />
                                                    <h2>What information do we collect about you?</h2>
                                                    <br />
                                                    We collect information about you when you register with us or place an order for products or
                                                    services on our website. We also collect information when you voluntarily complete customer surveys, provide
                                                    feedback and participate in competitions. Website usage information can be collected using cookies,
                                                    further information about this is detailed below.
                                                    <br />
                                                    The personal data collected when you submit a form to us for processing is:
                                                    - Company Name
                                                    <br />
                                                    - First Name
                                                    <br />
                                                    - Last Name
                                                    <br />
                                                    - Email Address
                                                    <br />
                                                    - Phone Number
                                                    <br />
                                                    - Designation
                                                    <br />
                                                    - Country
                                                    <br />
                                                    <br />
                                                    <h2>How will we use the information about you?</h2>
                                                    <br />
                                                    We collect information about you to process your order, help us understand your views on our
                                                    products, manage your account and, if you consent, to email you about other products and
                                                    services we think may be of interest to you.
                                                    <br />
                                                    We use your information collected from the website to personalise your repeat visits to our website. If you consent, we shall pass on your personal information to our group of companies so that they may offer you their products and services.PoultryPro will not share your information with companies outside the organisation except where required to mailing list providers as detailed below.
                                                    <br />
                                                    In processing orders, we may send your details to, and also use information from credit
                                                    reference agencies and fraud prevention agencies.
                                                    <br />
                                                    Information provided for marketing purposes will be retained and regular consent will be sought
                                                    to continue providing you with this service. You have the right to unsubscribe and have your
                                                    data deleted at any time as defined at the end of this policy.
                                                    <br />
                                                    Personal data including first name, last name, email address access requirements and dietary
                                                    preferences may be required for attending our events, failure to provide information in these
                                                    cases may limit the ability of the organisation to run the event.
                                                    <br />
                                                    No automated decision making or processing takes place.
                                                    <br />
                                                    <br />
                                                    <h2>Marketing</h2>
                                                    <br />

                                                    We would like to send you information about products and services of ours and other companies in
                                                    our group which may be of interest to you. If you have consented to receive marketing,
                                                    you may opt out at any time. You have a right at any time to stop us from contacting you for
                                                    marketing purposes or giving your information to other members of the Retail Group.
                                                    <br />
                                                    If you no longer wish to be contacted for marketing purposes, please email us at
                                                    contact@poultryproplus.com to have your data deleted.
                                                    <br />
                                                    Access to your information and correction
                                                    <br />
                                                    We also reserve the right to charge a reasonable fee to comply with requests for further copies
                                                    of the same information. This does not mean that we are able to charge for all subsequent access
                                                    requests.
                                                    <br />
                                                    The fee will be based on the administrative cost of providing the information.
                                                    <br />
                                                    We want to make sure that your personal information is accurate and up to date. You may ask us to
                                                    correct or remove information you think is inaccurate.
                                                    <br /><br />

                                                    <h2>Cookies</h2>
                                                    <br />
                                                    Cookies are text files placed on your computer to collect standard internet log information and
                                                    visitor behaviour information. This information is used to track visitor use of the website and
                                                    to compile statistical reports on website activity.
                                                    <br />
                                                    For further information visit www.aboutcookies.org or www.allaboutcookies.org.
                                                    <br />
                                                    You can set your browser not to accept cookies and the above websites tell you how to remove
                                                    cookies from your browser. However in a few cases some of our website
                                                    <br />
                                                    features may not function as a result.
                                                    <br /><br />
                                                    <h2>Other websites</h2>
                                                    <br />

                                                    Our website contains links to other websites. This privacy policy only applies to this website
                                                    so when you link to other websites you should read their own privacy policies.
                                                    <br /><br />
                                                    <h2>Changes to our privacy policy</h2>
                                                    <br />
                                                    We keep our privacy policy under regular review and we will place any updates on this web page.
                                                    <br />
                                                    Please contact us if you have any questions about our privacy policy or information we hold about you.


                                                </Typography>
                                            </DialogContent>
                                        </Dialog>

                                        {hasError('policy') && (
                                            <FormHelperText error>
                                                {formState.errors.policy[0]}
                                            </FormHelperText>
                                        )}
                                        <Button
                                            className={classes.signUpButton}
                                            color="primary"
                                            disabled={!formState.isValid}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            Sign up now
                                        </Button>
                                        <Typography
                                            color="textSecondary"
                                            variant="body1"
                                        >
                                            Have an account?{' '}
                                            <Link
                                                component={RouterLink}
                                                to="/sign-in"
                                                variant="h6"
                                            >
                                                Sign in
                                            </Link>
                                        </Typography>
                                        {/* <Hidden lgUp>
                                            <Grid item className={classes.KnowMoreContainer}>
                                                <Typography
                                                    color="textSecondary"
                                                    variant="body1"
                                                >
                                                To know more about PoultryProPlus, {"  "}
                                                <a
                                                    href="https://www.poultryproplus.com/" target="_blank"
                                                    className={classes.knowMoreButton}
                                                        rel="noopener noreferrer" 
                                                >
                                                    Click Here
                                                </a>
                                                
                                                </Typography>
                                            </Grid>
                                        </Hidden> */}
                                    </form>
                            }

                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

SignUp.propTypes = {
    history: PropTypes.object
};

export default withRouter(SignUp);
