import React, { useState, useEffect } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from "@material-ui/core/Typography";
import TcsTdsPayment from "./components/tcstdsPayment.js"
import { MaterialTable } from '../../components';
import Icon from '@material-ui/core/Icon';
import {getAllTaxes} from '../../common/functions';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));


const TaxPayments = ({isLine,showError, showMessage, history}) => {

    const [showTcsTdsDialog, setShowTcsTdsDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    
    const [editMode, setEditMode] = useState(false)
    const [currentData, setCurrentData] = useState(undefined)
    const [currentTaxPayments, setCurrentTaxPayments] = useState(undefined)
    const [taxDatas, setTaxDatas] = useState(undefined)

    const handleTcsTdsAdd = (event) => {
        setEditMode(false)
        event.preventDefault()
        setShowTcsTdsDialog(true)
    }

    const handleTcsTdsClose = () => {
        setShowTcsTdsDialog(false)
    }

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    const onload = () => {
        setLoading(true);

        Promise.all([getAllTaxes(api), getAllTaxPayments(isLine)]).finally(() => {
            setLoading(false);
        });
    }
    useEffect(onload, [isLine]);

    const getAllTaxPayments = (isLineEnabled) => {
        setLoading(true)
        showError('')
        const response = api.get(`get-all-monthly-tcs-tds-info/${isLineEnabled}`)

        response.then(res => {
            console.log("res--->",res)
            setTaxDatas(res)
        }).catch(err => {
            setTaxDatas(undefined)
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                showError('No Customers Found');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    function onSaveData(tax, setPopupLoading) {
        setLoading(true)
        setPopupLoading(true)
        showError('')
        console.log("tax--->", tax)
        const response = api.post("/create-monthly-tcs-tds-info", tax)

        response.then(res => {

            if (taxDatas === undefined) {
                setTaxDatas([res])
            } else {
                setTaxDatas([...taxDatas, res])
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                showError('Unable to create');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setPopupLoading(false)
            setLoading(false)
            setShowTcsTdsDialog(false)
        })
        
    }

    function onUpdateData(tax, setPopupLoading) {
        setLoading(true)
        setPopupLoading(true)
        showError('')
        const response = api.post(`/update-monthly-tcs-tds-info/${tax.id}`, tax)
        response.then(res => {
            let index = taxDatas.findIndex((ele) => {
                return ele.id === tax.id;
            });
            setTaxDatas([...taxDatas.slice(0, index),
                { ...res },
                ...taxDatas.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                showError('Unable to update');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setPopupLoading(false)
            setLoading(false)
            setShowTcsTdsDialog(false)
        });
        
    }

    const onDelete = (id) => {
        setLoading(true)
        showError('')
        const response = api.post(`/delete-monthly-tcs-tds-info/${id}`)

        response.then(res => {
            if (res) {
                let index = taxDatas.findIndex((ele) => {
                    return ele.id === id;
                });
                let newTaxDatas = [...taxDatas];
                newTaxDatas.splice(index, 1);
                setTaxDatas(newTaxDatas);
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                showError('Unable to delete');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false)
        });
    }

    const onEdit = (rowData) => {
        console.log("rowData", rowData)
        setEditMode(true)
        setCurrentData({
            ...rowData
        })
        
        setCurrentTaxPayments([...rowData.monthlyTcsTdsDetails])
        setShowTcsTdsDialog(true)
    }

    const updatePayments = (id, payments) => {
        let newTaxDatas = [...taxDatas]
        newTaxDatas.forEach(ele => {
            if (ele.id === id) {
                ele.monthlyTcsTdsDetails = payments
            }
        })
        setTaxDatas(newTaxDatas)        
    }


    let actions = [
        {
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit payment details',
            onClick: (event, rowData) => {onEdit(rowData)}
        },
        {
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete payment details',
            onClick: (event, rowData) => { onDelete(rowData.id) }
        }
    ]

    return (
        <div className={classes.root}>
            <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}
        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                {isLine ? "Line" : ""} TCS/TDS Payment
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={handleTcsTdsAdd}>Add TCS/TDS Payment</Button>
            </Grid>
        </Grid>

        <MaterialTable
        isLoading={loading}
                columns={[
                    { title: 'id', field: 'id', hidden: true },
                    {
                        title: 'Date', field: 'paymentDate', hidden: false,
                        render: rowData => {
                            return <span>{moment(rowData.paymentDate).format("DD-MM-YYYY")}</span>
                        }
                    },
                    { 
                        title: 'Month', field: 'payMonth', hidden: false,
                        render: rowData => {
                            return <span>{moment(rowData.payMonth).format("MM-YYYY")}</span>
                        }
                    },
                    {
                        title: 'Tax Type', field: 'taxType', hidden: false, 
                        render: rowData => {
                            return <span>{rowData.taxType === 0 ? "TDS" : "TCS"}</span>
                        }
                    },
                    { title: 'Type', field: 'categoryType', hidden: false, 
                    render: rowData => {
                            return <span>{rowData.categoryType === 1 ? "Customer" : "Vendor"}</span>
                        }
                    },
                ]}
                data={taxDatas}
                options={{
                    actionsColumnIndex: -1,
                    search: true,
                    showTitle: true,
                    toolbar: true,
                    paging: true
                }}
                title={`${isLine ? "Line" : ""} TCS/TDS Payments`}
                // style={{boxShadow: 'unset'}}
                actions={actions}
            />
        


        {/* Dialog */}
        <TcsTdsPayment currentData={currentData} editMode={editMode} showTcsTdsDialog={showTcsTdsDialog} handleTcsTdsClose={handleTcsTdsClose} 
        isLine={isLine}
            onSave={onSaveData}
            onUpdate={onUpdateData}
            currentTaxPayments={currentTaxPayments}
            updatePayments={updatePayments}
            showError={showError}
        />
        </div>
    )
}


export default TaxPayments;
