import React, { useEffect, useState, useRef } from 'react';
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, } from '@material-ui/pickers';
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from 'html2canvas';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../state';
import Divider from '@material-ui/core/Divider';
import {populateTaxData, getAllTaxes} from '../../common/functions'

window.html2canvas = html2canvas;
const useStyles = makeStyles(theme => ({
    divider: {
        backgroundColor: '#101010'
    },
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        minWidth: 160,
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    singleLineBorder: {
        borderTop: 'solid 1px #F26522',
        borderBottom: 'solid 1px #F26522'
    },
    doubleLineBorder: {
        borderTop: 'double #F26522',
        borderBottom: 'double #F26522'
    },
    boldedText: {
        fontWeight: 'bold'
    },
    reportContainer: {

    },
    reportHeader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    rawMaterialName: {
        textAlign: 'center',
        textDecoration: 'underline'
    }
}));

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    body: {
        fontSize: '1em',
        backgroundColor: theme.palette.common.white,
        padding: '6px',
        paddingLeft: '16px'
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);


const tableHeader = JSON.parse(`[["Month","Billed TCS","Received","Pending to be received"]]`);




const generateExcel = function (header, data) {
    console.debug("excel ", header, data)
    let csvData = '';
    header.forEach((column, index) => {
        if (index === 0)
            csvData = csvData + column
        else
            csvData = csvData + ',' + column
    });
    csvData = csvData + '\r\n';
    data.forEach(row => {
        row.forEach((column, index) => {
            if (index === 0)
                csvData = csvData + column

            else
                csvData = csvData + ',' + column
        });
        csvData = csvData + '\r\n';
    });

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
    element.setAttribute('download', 'report.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);


}



const CustomerYearlyTax = function ({ isLine, showError, match }) {
    const theme = useTheme();

    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [year, setYear] = useState(undefined);
    const [data, setData] = useState([]);
    const [financialYears, setFinancialYears] = useState([]);
    const [invoiceSetting, setInvoiceSetting] = useState({});
    const [customers, setCustomers] = useState([]);
    const [customerId, setCustomerId] = useState(undefined);
    const [customerIndex, setCustomerIndex] = useState(undefined);
    const [yearIndex, setYearIndex] = useState(undefined);
    const [{user}] = useGlobalState();
    const [report, setReport] = useState({});
    const [TaxType, setTaxType] = useState([]);
    const [TaxTypeIdx, setTaxTypeIdx] = useState(0);
    const TDS = "TDS";
    const TCS = "TCS";
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }

    useEffect(() => {
        if (year !== undefined && customerId !== undefined) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [year, customerId]);

    const getAllInvoiceSettings = () => {
        showError('');
        const response = api.get('invoiceSettings');
        response.then(res => {
            setInvoiceSetting(res[0]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No InvoiceSettings Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }

    const handleTaxTypeChange = (event) => {
        setTaxTypeIdx(event.target.value);
    };

    const getCustomerList = () => {
        showError('');
        let apiRoute = isLine ? "get-line-customers" : "customers-list"
        const response = api.get(apiRoute);

        response.then(res => {
            setCustomers([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
        return response
    }

    const getFinancialYears = () => {
        showError('');

        const response = api.get('getFinancialYears');

        response.then(res => {
            setFinancialYears([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
        return response
    }

    const onLoad = () => {
        setLoading(true);
        Promise.all([getAllInvoiceSettings(), getFinancialYears(), getCustomerList(), getAllTaxes(api), populateTaxData(setTaxType,[TDS,TCS],true)]).finally(() => {
            setLoading(false);
        });
    }

    useEffect(onLoad, []);

    const numToIndianStd = function (data){
        if(!isNaN(data)){
        return data.toLocaleString(user.locales,amountObj);
        }
    };

    const parseReportFromServer = function (data) {
        const { reportsArray } = data;
        setData(reportsArray);
        setReport(data)
        console.log(data)
    };

    const generateReport = function () {
        showError('');
        setLoading(true);
        const payload = {
            year: year,
            customerID: customerId,
            taxType : (TaxType[TaxTypeIdx].taxType).toLowerCase(),
            isLine,
        };


        const response = api.post('reports/tax-yearly-customer-report', payload);

        response.then(res => {
            console.log(res, "cyt");
            parseReportFromServer(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });

    };

    const handleFilterCustomerChange = (event) => {
        console.log("event.target.value ", event.target.value)
        setCustomerId(customers[event.target.value].id);
        setCustomerIndex(event.target.value);
    };

    const handleYearChange = (event) => {
        console.log("event.target.value ", event.target.value)
        setYear(event.target.value);
        setYearIndex(event.target.value);
    };

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });


    const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
    });


    return <div className={classes.root}>
        <Grid
            container
            alignItems='flex-start'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                   {isLine && "Line"} Customer Yearly Tax Report
                </Typography>
            </Grid>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="customerName-select-outlined-label">
                        {isLine && "Line"} Customer Name
                    </InputLabel>
                        <Select
                            id="customerName-select"
                            value={customerIndex !== undefined ? customerIndex : ""}
                            onChange={handleFilterCustomerChange}
                            name="customerId"
                        >
                            {customers !== undefined && customers !== null ?
                                customers.map((customer, index) =>
                                    <MenuItem key={customer.id} value={index}>{customer.name || customer.companyName}</MenuItem>
                                ) : ""}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                <FormControl className={classes.formControl}>
                    <InputLabel id="customerName-select-outlined-label">
                        Financial Years
                    </InputLabel>
                    <Select
                        id="financialYear-select"
                        value={yearIndex !== undefined ? yearIndex : ''}
                        onChange={handleYearChange}
                        name="financialYear">
                        {financialYears !== undefined && financialYears !== null ?
                            (financialYears).map((value, index) =>
                                <MenuItem key={index} value={value}>
                                    {"April " + (value) + " to March " + (value + 1)}
                                </MenuItem>
                            ) : ""}
                    </Select>
                </FormControl>
                </Grid>
                <Grid item>
                <FormControl className={classes.formControl}>
                    <InputLabel id="vendorName-select-outlined-label">
                        TAX
                    </InputLabel>
                    <Select
                        id="Tax-select"
                        value={TaxTypeIdx !== undefined ? TaxTypeIdx : ''}
                        onChange={handleTaxTypeChange}
                        name="tax">
                             {TaxType !== undefined && TaxType !== null ?
                                TaxType.map((tax, index) =>
                                    <MenuItem key={tax.taxTypeId} value={index}>{tax.taxType}</MenuItem>
                                ) : ""}
                    </Select>
                </FormControl>
                </Grid>
                <Grid item>
                    <Button disabled={!valid} variant="contained" color="primary"
                        onClick={generateReport}>Generate</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                        onClick={generatePDF} endIcon={<PictureAsPdfIcon />}>Download
                        PDF</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                        onClick={() => generateExcel(tableHeader, data)}
                        endIcon={<GetAppIcon />}>Download Excel</Button>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
        {loading ?
            <div className={classes.progressContainer}>
                <CircularProgress className={classes.progressBar} />
            </div>
            :
            <div id="customer-invoice" ref={componentRef} style={{ paddingTop: '35px' }}>
                <div className="invoice-box" style={{
                    backgroundColor: '#ffffff',
                    fontFamily: 'Montserrat,Roboto,sans-serif',
                    color:'#263238'
                }}>
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr >
                                <td>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ width: "40%" }}><img src={invoiceSetting.companyLogo}
                                                                    style={{ //width: '50%', 
                                                                        maxWidth: '130px'
                                                                    }}
                                                                    alt="company logo"></img><br /><br />
                                                                </td>
                                                                <td style={{ width: "50%", fontSize: "13px" }}>
                                                                    <p style={{ fontSize: "14px", fontWeight: 'bolder' }}>
                                                                        {invoiceSetting.companyName}</p>
                                                                    <p style={{ whiteSpace: 'pre-wrap' }}
                                                                    // className="show-white-space"
                                                                    >{invoiceSetting.address}</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "right", fontWeight: '600', fontSize: '23px' }} colSpan="2">
                                                                {isLine && "Line"} Customer Yearly Tax Report<br />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ width: "40%" }}>
                                                                    Bill to
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <b>{customers[customerIndex] !== undefined ? customers[customerIndex].name || customers[customerIndex].companyName : ""}</b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {customers[customerIndex] !== undefined ? customers[customerIndex].primaryContact : ""}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                    {customers[customerIndex] !== undefined ? customers[customerIndex].address : ""}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style={{ textAlign: "right", width: "50%" }}>
                                                    <table style={{}}>
                                                        <thead>
                                                            <tr>
                                                                <td style={{ textAlign: "left", width: "50%"  }}>
                                                                    Financial Year
                                                                </td>
                                                                <td style={{ textAlign: "center", width: "50%"  }}>
                                                                {year !== undefined ? "April " + (year) + " to March " + (year + 1) : "-"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2" >
                                                                    <Divider />
                                                                    <br />
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "right" }}>
                                                                    Total {TaxType[TaxTypeIdx]!==undefined && TaxType[TaxTypeIdx].taxType!==undefined?TaxType[TaxTypeIdx].taxType : "TAX"} Billed
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {report.totalTDSAmount !== undefined ? report.totalTDSAmount.toLocaleString(user.locales,amountObj) : 0.00}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: "right" }}>
                                                                    Total {TaxType[TaxTypeIdx]!==undefined && TaxType[TaxTypeIdx].taxType!==undefined?TaxType[TaxTypeIdx].taxType : "TAX"} Received
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {report.totalTDSPaid !== undefined ? report.totalTDSPaid.toLocaleString(user.locales,amountObj) : 0.00}
                                                                </td>
                                                            </tr>
                                                            <tr style={{ fontWeight: 'bold' }}>
                                                                <td style={{ textAlign: "right" }}>
                                                                    Total {TaxType[TaxTypeIdx]!==undefined && TaxType[TaxTypeIdx].taxType!==undefined?TaxType[TaxTypeIdx].taxType : "TAX"} Pending
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {report.totalTDSRemaining !== undefined ? report.totalTDSRemaining.toLocaleString(user.locales,amountObj) : 0}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style={{ minHeight: "450px" }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>Month</StyledTableCell>
                                                    <StyledTableCell style={{textAlign:'center'}}>Billed {TaxType[TaxTypeIdx]!==undefined && TaxType[TaxTypeIdx].taxType!==undefined?TaxType[TaxTypeIdx].taxType : "TAX"}</StyledTableCell>
                                                    <StyledTableCell style={{textAlign:'center'}}>Received</StyledTableCell>
                                                    <StyledTableCell style={{textAlign:'center'}}>Pending to be received</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.map((row, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell 
                                                        className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left">
                                                            {row[0]}
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{textAlign:'center'}}
                                                        className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left">
                                                            {numToIndianStd(parseFloat(row[1]))}
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{textAlign:'center'}}
                                                        className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left">
                                                         {numToIndianStd(parseFloat(row[2]))}
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{textAlign:'center'}}
                                                        className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left">
                                                         {numToIndianStd(parseFloat(row[3]))}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        }
    </div>
}

export default CustomerYearlyTax;