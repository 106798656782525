import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog,MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CustomerBulkPayment from "./components/customerBulkPayment";
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import useSearchStyles from "../../common/searchStyle"
const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        minWidth: 160,
    },
}));

let currentPayment = undefined;
let editMode = false;

const CustomerBulkPayments = ({showError, showMessage, history}) => {

    const [payments, setPayments] = useState([]);
    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(false);
        const [showPaymentDialog, setShowPaymentDialog] = useState(false)
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [accountTypes, setAccountTypes]= useState([]);
    const [customers, setCustomers] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')
   // const [customerUnpaid, setCustomerUnpaid] = useState([]);
    const [filterSelectedStartDate, setFilterSelectedStartDate] = useState(null);
    const [filterSelectedEndDate, setFilterSelectedEndDate] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [filterSelectedCustomerId, setFilterSelectedCustomerId] = useState('');
    const theme = useTheme();
    const searchClass = useSearchStyles()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    function onNewBank(event) {
        editMode = false;
        console.log(editMode)
        event.preventDefault();
        setShowPaymentDialog(true);
    }

    /*function handleBankClose() {
        setShowPaymentDialog(false);
    }*/

    function handleDeleteConfirmClose() {
        setErrorMessage(null);
        setShowDeleteConfirm(false);
    }


    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onDelete = (payment) => {
        currentPayment = payment;
        setShowDeleteConfirm(true);
    };

    const onPaymentSave = (payment, errorElement, setPopupLoading) => {
        //setLoading(true);
         showError('');
        showMessage('');
        const response = api.post('customerBulkPayment', {
            ...payment,
        });

        response.then(res => {
            setPayments([...payments, res]);
            setShowPaymentDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Bank not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
            if (errorElement) {
                errorElement.scrollIntoView();
            }
        });
        response.finally(() => {
            //setLoading(false);
            setPopupLoading(false);
        });
    };


    const onPaymentEdit = (payment, errorElement) => {
        //setLoading(true);
        // showError('');
        if (payment.singlePaymentID) {
            const legacyPayment = payment.deletedPaymentInvoices.filter(invoice => invoice.isLegacy);
             payment.paymentInvoices = [...payment.paymentInvoices, ...legacyPayment];
             payment.deletedPaymentInvoices = []
          }
        payment.amount= parseInt(payment.amount)
        const response = api.post(`customerBulkPayment/${payment.id}`, {
            ...payment,
    
        });
        response.then((res) => {
            // payment data should match id and single payment id to get index
            let index = payments.findIndex((srcPayment) => {
                return srcPayment.id === payment.id  && srcPayment.singlePaymentID === payment.singlePaymentID;
            });
            setPayments([...payments.slice(0, index),
                {...res},
                ...payments.slice(index + 1)]);
            setShowPaymentDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('Payment not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
            if (errorElement) {
                errorElement.scrollIntoView();
            }
        }).finally(() => {
            setLoading(false);
            
            
        });
    };

    const onPaymentDeleteConfirm = () => {
        if (currentPayment && currentPayment.id) {
            setShowDeleteConfirm(false);
            //setLoading(true);
            showError('');
    
            const response = api.post(`deleteCustomerBulkPayment/${currentPayment.id}`,{
                ...currentPayment,
            });
    
            response.then(res => {
                if (res === 1) {
                    let index = payments.findIndex((srcPayment) => {
                        return srcPayment.id === currentPayment.id;
                    });
                    let newPayments = [...payments];
                    newPayments.splice(index, 1);
                    setPayments(newPayments);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Payment not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
               
                currentPayment = undefined;
            });
    
        }
    };

    function handlePaymentClose() {
        setErrorMessage(null);
        //currentPayment = undefined;
        setShowPaymentDialog(false);
    }
    
    const handleFilterStartDateChange = date => {
        setFilterSelectedStartDate(date);
    };

    const handleFilterEndDateChange = date => {
        setFilterSelectedEndDate(date);
    };


    //Get all Customer entry API Call
    const getAll = () => {
        setLoading(true);
        showError('');
        showMessage('');
        setLoading(false);
    }
    const clearDateRangeFilter = () => {
        setFilterSelectedStartDate(null)
        setFilterSelectedEndDate(null)
        setSelectedCustomerId('')
        setFilterSelectedCustomerId('')
        getAll()

    }
    /*const getAllCustomerUnpaid = (customerID) => {
            setShowPaymentDialog(false);
            showError('');
            showMessage('');
    
            const response = api.post(`pending-payment/${customerID}`)   
            response.then(res => {
              
                setCustomerUnpaid([...res]);
                console.log(customerUnpaid)
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('No Bank Found');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            })
            return response;
        }*/

    //Get all Banks API Call
    const getAllBanks = () => {
        setShowPaymentDialog(false);
        showError('');
        showMessage('');

        const response = api.get('bank');

        response.then(res => {
          
            setBanks([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

     //Get all customers API Call
     const getAllCustomers = () => {
        showError('');
        showMessage('');

        const response = api.get('customers-list');

        response.then(res => {
            setCustomers([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Customers Found');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
    }

    //Get All Account Types
    const getAllAccountTypes = () => {
        
        
        showError('');
        showMessage('');

        const response = api.get('accountTypes');
        response.then(res => {
            setAccountTypes([...res]);
            console.log(accountTypes)
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log('No AccountType Found Catch Error');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }
    const getAllPayments = () => {

        if (filterSelectedEndDate !== null && filterSelectedStartDate !== null &&
            filterSelectedEndDate < filterSelectedStartDate) {
            showError('Start Date is Greater than or Equal to End Date');
            return
        }
        let payload = {
            startDate: filterSelectedStartDate,
            endDate: filterSelectedEndDate,
            customerId: parseInt(filterSelectedCustomerId, 10),
        }

        setLoading(true);
        showError('');
        showMessage('');

        const response = api.post('getAllBulkPaymentsForAllCustomers',  { ...payload });
        response.then(res => {
            console.log("All customer payments: ", res);
            setPayments([...res]);
            
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found'){
                    showError('No Payments Found');
                    setPayments([])
                } 
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });

        return response;
    }

    const handleFilterCustomerChange = (event) => {
        setSelectedCustomerId(event.target.value)
        setFilterSelectedCustomerId(customers[event.target.value].id)
    }
        
     const makePaymentInvoiceSearchable =(paymentsData)=>{
        paymentsData.forEach((payment) => {
            payment.invoiceStr = payment?.paymentInvoices
            ?.map((invoice) => invoice?.invoice?.id || invoice?.id)
            .join(", ")
            .toString();
        });
        return paymentsData;
     }
    const onload=()=>{
        setLoading(true);
        Promise.all([getAllBanks(),getAllAccountTypes(),getAllCustomers(),getAllPayments()]).finally(()=>{
            setLoading(false);
        });
    }
    useEffect(onload, []);
    
    const editPayment=(data)=>{
        editMode = true
        currentPayment = data
        setShowPaymentDialog(true);
    }


    let actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Payment',
                onClick: (event, rowData) => editPayment(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Payment',
                onClick: (event, rowData) => onDelete(rowData)
            },
        ];


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Customer Payments
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewBank}>Add Payment</Button>
            </Grid>
        </Grid>
        <Grid container
                spacing={1}
                justify="flex-start"
                alignItems="center">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        className={classes.formControl}
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="From"
                        format="dd/MM/yyyy"
                        value={filterSelectedStartDate || null}
                        onChange={handleFilterStartDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    <KeyboardDatePicker
                        className={classes.formControl}
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="To"
                        format="dd/MM/yyyy"
                        value={filterSelectedEndDate || null}
                        onChange={handleFilterEndDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    <FormControl className={classes.formControl}>
                        <InputLabel id="customerName-select-outlined-label">
                        Customer Name
                        </InputLabel>
                        <Select
                        id="customerName-select"
                        value={selectedCustomerId !== undefined ? selectedCustomerId : ''}
                        onChange={handleFilterCustomerChange}
                        name="customerId"
                        >
                            {customers !== undefined && customers !== null ?
                            customers.map((customer, index) =>
                            <MenuItem key={customer.id} value={index}>{customer.name}</MenuItem>
                            ) : ""}
                        </Select>
                    </FormControl>
                </MuiPickersUtilsProvider>
                <Grid>
                    <Button 
                     onClick={(getAllPayments)}
                    className={searchClass.success}>Search <SearchIcon /></Button>
                    <Button 
                    onClick={clearDateRangeFilter}
                    className={searchClass.searchButton}>Clear Search <CloseIcon /></Button>
                </Grid>
            </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'Date', field: 'paymentDate', editable: 'never', defaultSort: 'desc',
                render: rowData => {
                    return <span>{moment(rowData.paymentDate).format("DD-MM-YYYY")}</span>
                }},
                {title: 'id', field: 'id', hidden : true},
                {title: 'Name', field: 'customerName', editable: 'never'},           
                {title: 'Amount', field: 'paymentAmount', editable: 'never'},
                {title: 'Invoices.no', field: 'paymentInvoices', editable: 'never',hidden:true,render: rowData => (
                <span>
                    {rowData?.paymentInvoices?.map(invoice => invoice?.invoice?.id || invoice?.id).join(', ')}
                </span>
            )},
              {title: 'Invoice', field: "invoiceStr", editable: 'never',hidden:false},
            ]}
            data={payments.length > 0 ? makePaymentInvoiceSearchable(payments) : []}
            title='Payments Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true,
                pageSize:20
            }}
            actions={actions}
        />
       { /**data={currentPayment}     showPaymentDialog={showPaymentDialog} editMode={editPaymentMode}*/}
        <CustomerBulkPayment deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} banks={banks}  onSave={onPaymentSave} handlePaymentClose={handlePaymentClose} 
        showPaymentDialog={showPaymentDialog} customers={customers} onEdit={onPaymentEdit}
         editMode={editMode} data={currentPayment}>
        </CustomerBulkPayment> 
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onPaymentDeleteConfirm}
                       title='Delete Payment?'
                       subText='This action will delete the Payment. Please confirm.'/>
    </div>
}

export default CustomerBulkPayments;