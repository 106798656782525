import makeStyles  from "@material-ui/styles/makeStyles";
import palette from '../theme/palettes/default';

const useSearchStyles = makeStyles(theme => ({
    searchButton: {
        color: `${palette.background.paper}`,
        width: theme.spacing(21.25),
        backgroundColor:  `${palette.buttonBackground.secondary}`,
        '&:hover': {
            backgroundColor:`${palette.buttonBackground.secondaryHover}`,
        },
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom: theme.spacing(4)
    },
    success: {
        color: `${palette.background.paper}`,
        backgroundColor: `${palette.buttonBackground.primary}`,
        '&:hover': {
            backgroundColor: `${palette.buttonBackground.primaryHover}`,
        },
        width: theme.spacing(13.75),
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom: theme.spacing(4)
    },
    close: {
        color: `${palette.background.paper}`,
        backgroundColor: `${palette.buttonBackground.secondary}`,
        '&:hover': {
          backgroundColor: `${palette.buttonBackground.secondaryHover}`,
        },
      },
}));

export default useSearchStyles;