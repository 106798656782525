import React, { useEffect, useState } from "react";
import api from "../../common/api";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from "../../components";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Mobile from "./components/mobile";
import MaterialNutritionProfile from "./components/materialNutritionProfile";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1),
  }),
  topBar: {
    marginBottom: theme.spacing(2),
  },
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

let currentMaterialnutrition = undefined;
let editMode = false;

const MaterialNutritionProfilesView = ({
  showError,
  showMessage,
  materialNames,
  history,
}) => {
  const [materialnutritionprofiles, setmaterialnutritionprofiles] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [showNutritionDialog, setShowNutritionDialog] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [errorMessage, setErrorMessage] = useState('')
  //const [materialNames, setMaterialNames] = useState([]);
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const classes = useStyles({ isDesktop });

  function onNewNutritionProfile(event) {
    editMode = false;
    event.preventDefault();
    setShowNutritionDialog(true);
  }

  function handleModalClose() {
    setErrorMessage(null);
    currentMaterialnutrition = undefined;
    setShowNutritionDialog(false);
  }

  function handleDeleteConfirmClose() {
    setShowDeleteConfirm(false);
  }

  const removeErrorMessage = value =>{
    setErrorMessage('');
  }

  const onNutritionProfileDeleteConfirm = () => {
    if (currentMaterialnutrition && currentMaterialnutrition.id) {
      setShowDeleteConfirm(false);
      //setLoading(true);
      showError("");
      const response = api.delete(
        `materialNutritionProfile/${currentMaterialnutrition.id}`
      );

      response
        .then((res) => {
          if (res) {
            let index = materialnutritionprofiles.findIndex(
              (srcNutritionProfile) => {
                return srcNutritionProfile.id === currentMaterialnutrition.id;
              }
            );
            let newNutritionProfile = [...materialnutritionprofiles];
            newNutritionProfile.splice(index, 1);
            setmaterialnutritionprofiles(newNutritionProfile);
          }
        })
        .catch((err) => {
          if (err.message) {
            if (
              err.message === "Bad Request" ||
              err.message === "No data found"
            )
              showError("Nutrition Profile not Deleted");
            else showError(err.message);
          } else {
            showError("Unknown Error");
          }
        })
        .finally(() => {
          setLoading(false);
          currentMaterialnutrition = undefined;
        });
    }
  };

  const onDelete = (materialnutritionprofile) => {
    currentMaterialnutrition = materialnutritionprofile;
    setShowDeleteConfirm(true);
  };

  const onDataSave = (materialnutritionprofile, setpopuploading) => {
    //setLoading(true);
    // showError("");
    showMessage("");
    const response = api.post("materialNutritionProfile", {
      ...materialnutritionprofile,
    });

    response
      .then((res) => {
        setmaterialnutritionprofiles([...materialnutritionprofiles, res]);
        setShowNutritionDialog(false);
      })
      .catch((err) => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            setErrorMessage("Data not added");
          else setErrorMessage(err.message);
        } else {
          setErrorMessage("Unknown Error");
        }
      })
      .finally(() => {
        setLoading(false);
        setpopuploading(false);
      });
  };

  const onDataEdit = (materialnutritionprofile) => {
    currentMaterialnutrition = {
      ...materialnutritionprofile,
      /*materialTypeIndex: materialTypes.findIndex((materialType) => {
        return materialType.ID === materialType.materialTypeId;
      }),
      materialIndex: materialNames.findIndex((material) => {
        return material.ID === material.materialId;
      }),*/
    };
    editMode = true;
    setShowNutritionDialog(true);
  };

  const onDataUpdate = (materialnutritionprofile) => {
    //setLoading(true);
    // showError("");
    console.log(currentMaterialnutrition, materialnutritionprofile);
    const response = api.post(
      `materialNutritionProfile/${currentMaterialnutrition.id}`,
      {
        ...materialnutritionprofile,
      }
    );
    response
      .then(() => {
        let index = materialnutritionprofiles.findIndex(
          (srcNutritionProfile) => {
            return srcNutritionProfile.id === materialnutritionprofile.id;
          }
        );
        setmaterialnutritionprofiles([
          ...materialnutritionprofiles.slice(0, index),
          { ...materialnutritionprofile },
          ...materialnutritionprofiles.slice(index + 1),
        ]);
        setShowNutritionDialog(false);
      })
      .catch((err) => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
          setErrorMessage("Data not Updated");
          else setErrorMessage(err.message);
        } else {
          setErrorMessage("Unknown Error");
        }
      })
      .finally(() => {
        currentMaterialnutrition = undefined;
        setLoading(false);
        
      });
  };

  //Get all Data API Call
  const getAllProfiles = () => {
    setShowNutritionDialog(false);
    showError("");
    showMessage("");

    const response = api.get("materialNutritionProfile");

    response
      .then((res) => {
        setmaterialnutritionprofiles([...res]);
      })
      .catch((err) => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError("No Data Found");
          else showError(err.message);
        } else {
          showError("Unknown Error");
        }
      });
    return response;
  };

  const getAllMaterialTypes = (materialId) => {
    showError("");
    showMessage("");

    const response = api.get(`material-types-for-raw-materials`);
    response
      .then((res) => {
        setMaterialTypes([...res]);
      })
      .catch((err) => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError("No Material Type Found Catch Error");
          else showError(err.message);
        } else {
          showError("Unknown Error");
        }
      });

    return response;
  };

  const onload = () => {
    setLoading(true);
    Promise.all([getAllProfiles(), getAllMaterialTypes()]).finally(() => {
      setLoading(false);
    });
  };
  useEffect(onload, []);

  let materialTypeColumn = {
    title: "Material Type",
    field: "materialTypeName",
    editable: "never",
    defaultSort: "asc",
    render: (rowData) => (
      <Mobile
        data={rowData}
        onEdit={() => {
          onDataEdit(rowData);
        }}
        onDelete={() => {
          onDelete(rowData);
        }}
      />
    ),
  };
  let actions = [];
  if (isDesktop) {
    materialTypeColumn = {
      title: "Material Type",
      field: "materialTypeName",
      editable: "never",
      defaultSort: "asc",
    };
    actions = [
      {
        icon: () => <Icon color="primary">edit</Icon>,
        tooltip: "Edit Data",
        onClick: (event, rowData) => onDataEdit(rowData),
      },
      {
        icon: () => <Icon color="error">delete</Icon>,
        tooltip: "Delete Data",
        onClick: (event, rowData) => onDelete(rowData),
      },
    ];
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="space-between"
        alignItems="flex-end"
        className={classes.topBar}
      >
        <Grid>
          <Typography display="block" variant="h3" gutterBottom>
            Material Nutrition Profiles
          </Typography>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={onNewNutritionProfile}
          >
            Add New Profile
          </Button>
        </Grid>
      </Grid>
      <MaterialTable
        isLoading={loading}
        columns={[
          { title: "id", field: "id", hidden: true },
          materialTypeColumn,
          {
            title: "Material Name",
            field: "materialName",
            hidden: !isDesktop,
            editable: "never",
          },
          {
            title: "M.E.",
            field: "metabolizableEnergy",
            hidden: !isDesktop,
            editable: "never",
          },
          {
            title: "Protein",
            field: "protein",
            hidden: !isDesktop,
            editable: "never",
          },
          {
            title: "Fat",
            field: "fat",
            hidden: !isDesktop,
            editable: "never",
          },
          {
            title: "Fibre",
            field: "fibre",
            hidden: !isDesktop,
            editable: "never",
          },
          {
            title: "Calcium",
            field: "calcium",
            hidden: !isDesktop,
            editable: "never",
          },
          {
            title: "Phosphorus",
            field: "phosphorus",
            hidden: !isDesktop,
            editable: "never",
          },
          {
            title: "Lysine",
            field: "lysine",
            hidden: !isDesktop,
            editable: "never",
          },
          {
            title: "Methionine",
            field: "methionine",
            hidden: !isDesktop,
            editable: "never",
          },
        ]}
        data={materialnutritionprofiles}
        title="Nutritional Profile Table"
        options={{
          actionsColumnIndex: -1,
          search: true,
          showTitle: true,

          toolbar: true,
        }}
        actions={actions}
      />
      <MaterialNutritionProfile
        deleteErrorMessage={removeErrorMessage}
        errorMessage={errorMessage}
        showDialog={showNutritionDialog}
        handleClose={handleModalClose}
        showError={showError}
        onSave={onDataSave}
        onEdit={onDataUpdate}
        editMode={editMode}
        data={currentMaterialnutrition}
        materialTypes={materialTypes}
        materialNames={materialNames}
      />
      <ConfirmDialog
        showDialog={showDeleteConfirm}
        handleClose={handleDeleteConfirmClose}
        onConfirm={onNutritionProfileDeleteConfirm}
        title="Delete Data ?"
        subText="This action will delete the Nutritional Profile Details. Please confirm."
      />
    </div>
  );
};

export default MaterialNutritionProfilesView;
