import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/styles/makeStyles';
import useFromValidator from '../../../common/formValidator';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';

var schema = {
    displayStartDate: {
        presence: { allowEmpty: false, message: 'is required' }
    },
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    button: {
        margin: theme.spacing(2, 0)
    }
}));
const BatchShedAvailability = ({deleteErrorMessage, errorMessage, onEdit, data, onSave, handleClose, showDialog, editMode = false }) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [disabledEndDate, setDisableEnddate] = useState(false)
    const resetBeforeEdit = () => {
        let displayStartDate  = ""
        let displayEndDate = ""
        setDisableEnddate(false)
        
        if (showDialog) {
            if (data.startDate.Valid) {
                displayStartDate = data.startDate.Time
            }
            if (data.endDate.Valid) {
                displayEndDate = data.endDate.Time
            }else{
                setDisableEnddate(true)
            }
            data = {
                ...data,
                displayStartDate:displayStartDate,
                displayEndDate:displayEndDate
            }
            updateData(data, editMode);
        }
    };

    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        var tempData = {
            ...formState.values
        };

        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        
        if (tempData.displayStartDate !== ""){
            tempData ={
                ...tempData,
                startDate :{
                    Time : tempData.displayStartDate,
                    Valid : true
                }
            }
        }
        if (tempData.displayEndDate !== ""){
            tempData ={
                ...tempData,
                endDate :{
                    Time : tempData.displayEndDate,
                    Valid : true
                }
            }
        }

        console.debug(tempData, hasError)
        if (editMode)
            onEdit(tempData);
        else
            onSave(tempData);
    };



    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" 
         >{editMode ? 'Update' : 'New'}  Batch Shed Availability
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
          <DialogContent>
          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
          </div> 
          <br/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Start Date"
                    format="dd/MM/yyyy"
                    value={formState.values.displayStartDate || null}
                    onChange={data => {
                        handleChange({ target: { name: 'displayStartDate', value: data } })
                    }}
                    animateYearScrolling
                    name="displayStartDate"
                    className={classes.button} 
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider><br/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                className={classes.button} 
                disabled={disabledEndDate}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="End Date"
                    format="dd/MM/yyyy"
                    value={formState.values.displayEndDate || null}
                    onChange={data => {
                        handleChange({ target: { name: 'displayEndDate', value: data } })
                    }}
                    animateYearScrolling
                    name="displayEndDate"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <DialogActions>
                <Button
                    className={classes.button}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>

};

export default BatchShedAvailability;