function RemoveYear(fullDate){
    var date = new Date(fullDate);
    return (date.getMonth()+1)+'/'+date.getDate();
}

function isUtcDateTimeExpired(utcDateTime){
    var dateObj = new Date(utcDateTime);
    var now = new Date();
    return (dateObj.getTime() < now.getTime())
}

function isToday(utcDateTime) {
    const currentDate = new Date();
    const utcDate = new Date(utcDateTime);
  
    // Set both dates to the start of the day
    // So that we can accurately compare only dates
    currentDate.setHours(0, 0, 0, 0);
    utcDate.setHours(0, 0, 0, 0);
  
    return currentDate.getTime() === utcDate.getTime();
  }

export {
    RemoveYear,
    isUtcDateTimeExpired,
    isToday
};