import React, {useEffect, useState, useRef} from 'react';
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'; 
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from 'html2canvas';
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../state';

window.html2canvas = html2canvas;
const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    formControl: {
        width: '100%'
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    singleLineBorder: {
        borderTop: 'solid 1px #F26522',
        borderBottom: 'solid 1px #F26522'
    },
    doubleLineBorder: {
        borderTop: 'double #F26522',
        borderBottom: 'double #F26522'
    },
    boldedText:{
        fontWeight: 'bold'
    },
    reportContainer:{
       
    },
    reportHeader:{
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(2)
    },
    rawMaterialName:{
        textAlign:'center',
        textDecoration:'underline'
    }
}));

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: '1em',
        backgroundColor: theme.palette.common.white,
        padding:'6px'
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);


const tableHeader = JSON.parse(`[["Material Name","Amount"]]`);




const generateExcel = function (header, data) {
    console.debug("excel ", header, data)
    let csvData = '';
    header.forEach((column, index) => {
        if (index === 0)
            csvData = csvData + column
        else
            csvData = csvData + ',' + column
    });
    csvData = csvData + '\r\n';
    data.forEach(row => {
        row.forEach((column, index) => {
            if (index === 0)
                csvData = csvData + column
            
            else
                csvData = csvData + ',' + column
        });
        csvData = csvData + '\r\n';
    });

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
    element.setAttribute('download', 'report.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);


}



const TrailBalanceReport = function ({showError, match}) {
    const theme = useTheme();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [data, setData] = useState([]);
    const [invoiceSetting, setInvoiceSetting] = useState({});

    useEffect(() => {
        if (startDate && endDate && (startDate.getTime() < endDate.getTime())) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [startDate, endDate]);

    const getAllInvoiceSettings = () => {
        showError('');
        const response = api.get('invoiceSettings');
        response.then(res => {
            setInvoiceSetting(res[0]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No InvoiceSettings Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }
    const onLoad = () => {
        setLoading(true);
        Promise.all([getAllInvoiceSettings()]).finally(() => {
            setLoading(false);
        });
    }

    useEffect(onLoad, []);

    const parseReportFromServer = function (data) {
        const {reportsArray} = data;
        setData(reportsArray);
        console.log(data)
    };

    const generateReport = function () {
        showError('');
        setLoading(true);
        const payload = {
            startDate: startDate,
            endDate: endDate
        };

        const response = api.post('reports/trail-balance-report', payload);

        response.then(res => {
            parseReportFromServer(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });

    };

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }

    const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
      });
  
    return <div className={classes.root}>
        <Grid
            container
            alignItems='flex-start'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    P/L Report
                </Typography>
            </Grid>
        </Grid>
         <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                <Grid container
                    spacing={3}
                    justify="flex-start"
                    alignItems="center"
                    >
                    
                    <Grid item>
                        <KeyboardDatePicker
                            margin="normal"
                            autoOk
                            variant="inline"
                            label="From"
                            format="dd/MM/yyyy"
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <KeyboardDatePicker
                            margin="normal"
                            variant="inline"
                            label="To"
                            format="dd/MM/yyyy"
                            value={endDate}
                            onChange={(date) => setEndDate(date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Button disabled={!valid} variant="contained" color="primary"
                                onClick={generateReport}>Generate</Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                                onClick={generatePDF} endIcon={<PictureAsPdfIcon/>}>Download
                            PDF</Button>
                            <iframe
                    id="receipt"
                    style={{ width: '100%',display: 'none' }}
                    title="Receipt"
                />
                    </Grid>
                    <Grid item>
                        <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                                onClick={() => generateExcel(tableHeader, data)}
                                endIcon={<GetAppIcon/>}>Download Excel</Button>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        {/*
            loading ?
                <div className={classes.progressContainer}>
                    <CircularProgress className={classes.progressBar}/>
                </div> : 
                <div id='report-container' className={classes.reportContainer} ref={componentRef}>
                    <div className={classes.reportHeader}>
                        <Typography variant="body1" component="p">
                            <b>Period</b> {moment(startDate).format('MM/DD/YYYY')} to {moment(endDate).format('MM/DD/YYYY')}
                        </Typography>
                        
                        <Typography variant="h4" component="h4" className={classes.rawMaterialName}>
                            Trail Balance Report
                        </Typography>
                    </div>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Material Type Name</StyledTableCell>
                                <StyledTableCell>Amount</StyledTableCell>  
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell className={
                                        row[1] === "" || row[0].includes('Total') || row[0].includes('Net')   
                                        ? classes.boldedText : undefined
                                        } align="left">
                                        {row[0]}
                                    </StyledTableCell>
                                    <StyledTableCell className={
                                        row[0].includes('Total')  || row[0].includes('Net')   
                                        ? classes.boldedText : undefined
                                        } align="left"> {row[1]}</StyledTableCell>
                                </StyledTableRow>
                            ))}       
                        </TableBody>     
                    </Table>
                </div>
                
        */}
         {loading ?
            <div className={classes.progressContainer}>
                <CircularProgress className={classes.progressBar} />
            </div>
            :
            <div id="customer-invoice" ref={componentRef} style={{ paddingTop: '35px' }}>
                <div className="invoice-box" style={{
                    backgroundColor: '#ffffff',padding:"0px",
                    fontFamily: 'Helvetica Neue, Helvetica, Helvetica, Arial, sans-serif'
                }}>
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr >
                                <td>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ width: "40%" }}><img src={invoiceSetting.companyLogo}
                                                                    style={{ //width: '50%', 
                                                                        maxWidth: '130px'
                                                                    }}
                                                                    alt="company logo"></img><br /><br />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "right", fontWeight: '600', fontSize: '23px' }} colSpan="2">
                                                                    P/L Report<br />
                                                                    <span style={{ fontWeight: '400', fontSize: '15px' }}>
                                                                        <b>Date : </b> {moment(startDate).format('MMM Do YYYY')} <b> - </b>{moment(endDate).format('MMM Do YYYY')}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style={{ minHeight: "450px" }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>Material Type Name</StyledTableCell>
                                <StyledTableCell>Amount</StyledTableCell>  
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell className={
                                        row[1] === "" || row[0].includes('Total') || row[0].includes('Net')   
                                        ? classes.boldedText : undefined
                                        } align="left">
                                        	{row[0]}
                                    </StyledTableCell>
                                    <StyledTableCell className={
                                        row[0].includes('Total')  || row[0].includes('Net')   
                                        ? classes.boldedText : undefined
                                        } align="left"> 
                                       {row[1] !== ""?"":''} {row[1]!== ""?parseFloat(row[1]).toLocaleString(user.locales,amountObj):''}</StyledTableCell>
                                </StyledTableRow>
                            ))} 
                                            </TableBody>
                                        </Table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        } 
    </div>  
}

export default TrailBalanceReport;