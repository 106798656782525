import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import MedicineStockData from "./components/medicineStockData";
import MedicineStock from './components/medicineStocks'
import Icon from '@material-ui/core/Icon';
import moment from 'moment'
import Mobile from "./components/mobile";

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentMedicineStockData = undefined;
let editMode = false;

const MedicineStockView = ({ showError, showMessage, history, match }) => {

    const [medicineStockData, setMedicineStockData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showMedicineStockDialog, setShowMedicineStockDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [Shed, setShed] = useState([])
    const [MaterialList, setMaterialList] = useState(null);
    const [MaterialTypeList, setMaterialTypeList] = useState(null);
    const [MaterialType, setMaterialType] = useState(null);
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    function onNewMedicineStockDatas(event) {
        editMode = false;
        setErrorMessage("");
        event.preventDefault();
        setShowMedicineStockDialog(true);
    }

    function handleMedicineStockClose() {
        setErrorMessage(null);
        currentMedicineStockData = undefined;
        setShowMedicineStockDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    function handleMaterialTypeChange(event) {
        setMaterialType(MaterialTypeList[event.target.value])
    }

    function getAllSheds() {
        setLoading(true);
        // showError('');
        showMessage('');
        const response = api.get(`farm/${match.params["farmID"]}/shed`);

        response.then(res => {
            console.log(res, "sheds");
            setShed(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('No Sheds');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });

        return response;
    };
    const onMedicineStockDeleteConfirm = () => {
        if (currentMedicineStockData && currentMedicineStockData.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`medicine-stock/delete/${currentMedicineStockData.id}/${match.params["farmID"]}`);

            response.then(res => {
                if (res) {
                    let index = medicineStockData.findIndex((srcMedicineStock) => {
                        return srcMedicineStock.id === currentMedicineStockData.id;
                    });
                    let newMedicineStockData = [...medicineStockData];
                    newMedicineStockData.splice(index, 1);
                    setMedicineStockData(newMedicineStockData);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('MedicineStockData not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentMedicineStockData = undefined;
            });

        }
    };

    const removeErrorMessage = value => {
        setErrorMessage('');
    }

    const onMedicineStockDataDelete = (MedicineStockData) => {
        currentMedicineStockData = MedicineStockData;
        setShowDeleteConfirm(true);
    };

    const onMedicineStockDatasSave = (MedicineStockData, setpopuploading) => {
        showMessage('');
        const response = api.post('medicine-stock/create', MedicineStockData);

        response.then(res => {
            let materials = MaterialList[res.materialType]
            let { materialName } = materials.find((el) => el.materialMasterId === res.materialMasterId)
            let { name: shedName } = Shed.find((el) => el.id === res.shedId)
            let materialType = MedicineStockData.materialType

            res.materialName = materialName
            res.shedName = shedName
            res.materialType = materialType
            setMedicineStockData([...medicineStockData, res]);
            setShowMedicineStockDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found') {
                    setErrorMessage('MedicineStockData not added');
                }
                else if (err.message === 'duplicate key') {
                    setErrorMessage("Duplicate entry. Medicine Stock already exists")
                } else if (err.message === 'no batch found') {
                    setErrorMessage(`No batch found for shed ${MedicineStockData.shedName} on ${moment(MedicineStockData.date).format("DD-MM-YYYY")}`)
                } else if (err.message === 'no stock') {
                    setErrorMessage('Material with available stock 0 cannot be added');
                }
                else {
                    setErrorMessage(err.message)
                }
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onMedicineStockDataEdit = (MedicineStockData) => {
        setErrorMessage("");
        currentMedicineStockData = {
            ...MedicineStockData,
            medicineStockTypeIdx: MaterialList[MedicineStockData.materialType].findIndex((material) => { return material.materialMasterId === MedicineStockData.materialMasterId; }),
            shedTypeIdx: Shed.findIndex((shed) => { return shed.id === MedicineStockData.shedId; }),
            materialTypeIdx: MaterialTypeList.findIndex((el) => { return el.value === MedicineStockData.materialTypeId })
        };
        setMaterialType(MaterialTypeList[MaterialTypeList.findIndex((el) => { return el.value === MedicineStockData.materialTypeId })])
        console.log(currentMedicineStockData, " editmode");
        editMode = true;

        setShowMedicineStockDialog(true);

    };

    const onMedicineStockUpdate = (MedicineStockData, setPopupLoading) => {
        const response = api.post(`medicine-stock/update/${MedicineStockData.id}/${match.params["farmID"]}`, MedicineStockData);
        response.then((res) => {
            let index = medicineStockData.findIndex((srcMedicineStock) => {
                return srcMedicineStock.id === MedicineStockData.id;
            });
            setMedicineStockData([...medicineStockData.slice(0, index),
            { ...res },
            ...medicineStockData.slice(index + 1)]);

            setShowMedicineStockDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'Not Updated') {
                    setErrorMessage('Medicine Stock Data not Updated');
                } else if (err.message === 'no batch found') {
                    setErrorMessage(`No batch found for shed ${MedicineStockData.shedName} on ${moment(MedicineStockData.date).format("DD-MM-YYYY")}`)
                }
                else if (err.message === 'no stock') {
                    setErrorMessage('Material with available stock 0 cannot be updated');
                }
                else {
                    setErrorMessage(err.message)
                }
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setPopupLoading(false)
            setLoading(false);
        })
    };

    //Get all medicineStockData API Call
    const getAllmedicineStockData = () => {
        setShowMedicineStockDialog(false);
        showError('');
        showMessage('');

        const response = api.post(`medicine-stock/data/${match.params["farmID"]}`,{});

        response.then(res => {
            setMedicineStockData([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Medicine Stock Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

    // //Get All MedicineStockData Profile Types
    const getMaterialTypes = () => {


        showError('');
        showMessage('');

        const response = api.get(`material-type`);
        response.then(res => {
            var materialTypes = []
            if (res !== undefined && res !== null
                && res.Medicine !== undefined && res.Medicine !== null
            ) {
                materialTypes.push(
                    {
                        key: 0,
                        value: res.Medicine[0].materialTypeId,
                        type: res.Medicine[0].type
                    }
                )
            }

            if (res !== undefined && res !== null
                && res.Vaccination !== undefined && res.Vaccination !== null
            ) {
                materialTypes.push(
                    {
                        key: 1,
                        value: res.Vaccination[0].materialTypeId,
                        type: res.Vaccination[0].type
                    },
                )
            }

            setMaterialTypeList(materialTypes)
            setMaterialList(res)
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Medicine List');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }

    const onload = () => {
        setLoading(true);
        Promise.all([getAllmedicineStockData(), getAllSheds(), getMaterialTypes()]).finally(() => {
            setLoading(false);
        });
    }
    useEffect(onload, []);
    let materialColumnDefaults = { title: 'Material Name', field: 'materialName', defaultSort: 'asc' }
    let materialColumn = {
        ...materialColumnDefaults,
        render: rowData => <Mobile data={rowData}
            onEdit={() => { onMedicineStockDataEdit(rowData) }}
            onDelete={() => { onMedicineStockDataDelete(rowData) }}
        />
    }
    let actions = [];
    if (isDesktop) {
        materialColumn = materialColumnDefaults
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit MedicineStockData',
                onClick: (event, rowData) => onMedicineStockDataEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete MedicineStockData',
                onClick: (event, rowData) => onMedicineStockDataDelete(rowData)
            }
        ]
    }


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Move to Shed
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewMedicineStockDatas}>Add Medicine Stock</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'No', field: 'id', hidden: false },
                {
                    title: 'Date', field: 'date', hidden: !isDesktop,
                    render: rowData => {
                        return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                    }
                },

                materialColumn,
                { title: 'Material Type', field: 'materialType', hidden: !isDesktop, editable: 'never' },
                { title: 'shed Name', field: 'shedName', hidden: !isDesktop, editable: 'never' },
                { title: 'Quantity', field: 'quantity', hidden: !isDesktop, editable: 'never' },
                {
                    title: 'Cost', field: 'cost', hidden: !isDesktop,
                    render: rowData => rowData.cost.toFixed(2)
                },
            ]}
            data={medicineStockData}
            title='Medicine Stock Data Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,

                toolbar: true
            }}
            actions={actions}
        />
        <MedicineStock errorMessage={errorMessage} showDialog={showMedicineStockDialog} handleClose={handleMedicineStockClose} onSave={onMedicineStockDatasSave} onEdit={onMedicineStockUpdate}
            editMode={editMode} data={currentMedicineStockData} deleteErrorMessage={removeErrorMessage} match={match} shed={Shed} materialList={MaterialList}
            materialTypeList={MaterialTypeList} materialType={MaterialType}
            handleMaterialType={handleMaterialTypeChange}
        />
        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onMedicineStockDeleteConfirm}
            title='Delete Medicine Stock ?'
            subText='This action will delete the Medicine Stock Details. Please confirm.' />
    </div>
}


export default MedicineStockView;