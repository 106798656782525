import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from "../../../common/formValidator";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";

const schema = {
  number: {
    presence: { allowEmpty: false, message: " is required" },
    type: "string",
  },
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.text.default,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  progressBar: {
    margin: theme.spacing(2),
    position: "absolute",
    top: "40%",
    zIndex: "9999",
  },
}));

const TruckPopup = ({
  deleteErrorMessage,
  errorMessage,
  onEdit,
  data,
  onSave,
  handleClose,
  showDialog,
  editMode = false,
}) => {
  const classes = useStyles();
  const [formState, hasError, handleChange, updateData] =
    useFromValidator(schema);
  const [popupLoading, setPopupLoading] = useState(false);

  const resetBeforeEdit = () => {
    if (editMode) {
      console.log("data", data);
    }
    if (showDialog) {
      setPopupLoading(false);
      updateData(data, editMode);
    }
  };
  useEffect(resetBeforeEdit, [showDialog]);

  const onSubmit = () => {
    setPopupLoading(true);
    const tempData = {
      ...formState.values,
    };
    var errorElement = document.getElementById("error");
    if (errorElement) {
      errorElement.scrollIntoView();
    }
    if (editMode) {
      onEdit(tempData, setPopupLoading);
      console.log("tempdata", tempData);
    } else onSave(tempData, setPopupLoading);
  };

  return (
    <Dialog
      disableBackdropClick
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog- title">
        {editMode ? "Update" : "New"} Truck Detail
      </DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {popupLoading ? (
          <div>
            <div className={classes.progressContainer}>
              <CircularProgress className={classes.progressBar} />
            </div>
            <br />
            <br />
            <TextField
              className={classes.textField}
              disabled
              error={hasError("number")}
              fullWidth
              helperText={
                hasError("number") ? formState.errors.number[0] : null
              }
              label="Truck Number"
              name="number"
              onChange={handleChange}
              type="text"
              value={formState.values.number || ""}
              variant="outlined"
              style={{ backgroundColor: "#ffffff" }}
            />
            <DialogActions>
              <Button
                className={classes.signInButton}
                color="primary"
                disabled
                size="large"
                type="submit"
                variant="contained"
                onClick={onSubmit}
              >
                Save
              </Button>
            </DialogActions>
          </div>
        ) : (
          <div>
            <div id="error">
              {errorMessage ? (
                <Chip
                  onDelete={deleteErrorMessage}
                  color="primary"
                  label={errorMessage}
                />
              ) : (
                ""
              )}
            </div>
            <br />
            <TextField
              className={classes.textField}
              error={hasError("number")}
              fullWidth
              helperText={
                hasError("number") ? formState.errors.number[0] : null
              }
              label="Truck Number"
              name="number"
              onChange={handleChange}
              type="text"
              value={formState.values.number || ""}
              variant="outlined"
              style={{ backgroundColor: "#ffffff" }}
            />
            <DialogActions>
              <Button
                className={classes.signInButton}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                disabled={!formState.isValid}
                onClick={onSubmit}
              >
                Save
              </Button>
            </DialogActions>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TruckPopup;
