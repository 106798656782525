import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import FeedformulationStorage from "./components/FeedFormulationStorage";
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    breadcrumbs: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },

    red: {
        color: '#f44336'
    },
    green:{
        color:'#4caf50'
    },
    button: {
        marginLeft: theme.spacing(2),
    },
    buttonMob: {
        marginLeft: theme.spacing(1),
    },
}));

const FeedformulationStorageView = ({ showError, showMessage, history,match }) => {
    const farmID = match.params['farmID'];
    const [feedFormulationStorage,setFeedFormulationStorage] = useState([])
    const [feedformulationStorageEditMode,setFeedFormulationStorageEditMode] = useState(false)
    const [currentFeedFormulationStorage,setCurrentFeedFormulationStorage] = useState({})
    const [loading, setLoading] = useState(false);
    const [showFeedStortageDialog, setShowFeedStortageDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);


    const [errorMessage, setErrorMessage] = useState('')
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    function onNewFeedStorage(event) {
        event.preventDefault();
        setCurrentFeedFormulationStorage({})
        setErrorMessage("")
        setShowFeedStortageDialog(true);
    }

    function handleLabourCostClose() {
        setErrorMessage(null);
        setFeedFormulationStorageEditMode(false)
        setShowFeedStortageDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onFeedStorageDeleteConform = () => {
        if (feedFormulationStorage && currentFeedFormulationStorage.id) {
            setShowDeleteConfirm(false);
            showError('');
            const response = api.delete(`/feed-formulation-storage/${currentFeedFormulationStorage.id}`);

            response.then(res => {
                if (res) {
                    let index = feedFormulationStorage.findIndex((feedStorage) => {
                        return feedStorage.id === currentFeedFormulationStorage.id;
                    });
                    let newFeedStorage = [...feedFormulationStorage];
                    newFeedStorage.splice(index, 1);
                    setFeedFormulationStorage(newFeedStorage);
                    getAllFeedFormulationStorage();
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Feed formulation Storage not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                setCurrentFeedFormulationStorage({})
            });

        }
    };

    const onFeedStorageDelete = (FeedstorageData) => {
        setCurrentFeedFormulationStorage(FeedstorageData)
        setShowDeleteConfirm(true);
    };



    const onFeedStorageSave = (FeedstorageData, setpopuploading) => {
        const response = api.post(`/feed-formulation-storage`, {
            ...FeedstorageData
        });

        response.then(res => {
            setFeedFormulationStorage([...feedFormulationStorage, res]);
            setShowFeedStortageDialog(false);
            getAllFeedFormulationStorage()
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found') {
                    setErrorMessage('Feed Storage not added');
                }
                else if (err.message === 'duplicate key') {
                    setErrorMessage('Duplicate Entry');
                }
                else {
                    setErrorMessage(err.message)
                }
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onFeedStorageEdit = (feedFormulationStorage) => {
        setCurrentFeedFormulationStorage(feedFormulationStorage)
        setFeedFormulationStorageEditMode(true)
        setErrorMessage("")
        setShowFeedStortageDialog(true)
    };

    const onFeedStorageUpdate = (FeedstorageData, setpopuploading) => {
        showError('');
        const response = api.post(`/feed-formulation-storage/${FeedstorageData.id}`, {
            ...FeedstorageData
        });
        response.then(() => {
            let index = feedFormulationStorage.findIndex((feedstorage) => {
                return feedstorage.id === FeedstorageData.id;
            });
            let newLabour = [...feedFormulationStorage.slice(0, index),
            { ...FeedstorageData },
            ...feedFormulationStorage.slice(index + 1)]

            setFeedFormulationStorage(newLabour);
            setShowFeedStortageDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Feed storage not Updated');
                else if (err.message === 'duplicate key')
                    setErrorMessage('Duplicate Entry');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setFeedFormulationStorageEditMode(false)
            setpopuploading(false);
        });
    };

    //Get all feedFormulationStorage API Call
    const getAllFeedFormulationStorage = () => {
        setShowFeedStortageDialog(false);
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.post(`/available-feed-formulation-storage`);

        response.then(res => {
            setFeedFormulationStorage([...res])
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Feed Formulation Storage');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

    const onload = () => {
        setLoading(true);
        Promise.all([getAllFeedFormulationStorage()]).finally(() => {
            setLoading(false);
        });
    }
    useEffect(onload, []);


    let FeedStorageColumn = {
        title: 'FeedFormulation Storage', field: 'date',  hidden:isDesktop, defaultSort: 'desc',
        render: rowData => <Mobile
            data={rowData}
            onEdit={() => {
                onFeedStorageEdit(rowData)
            }}
            onDelete={()=> {
                onFeedStorageDelete(rowData)
            }}
            onFeedStorageDelete={() => {
                onFeedStorageDelete(rowData)
            }}
        />
    };
    let actions = [];
    if (isDesktop) {
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Labour Cost',
                onClick: (event, rowData) => onFeedStorageEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Labour Cost',
                onClick: (event, rowData) => onFeedStorageDelete(rowData)
            }
        ]
    }


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                   Feed Formulation Storage Overview
                </Typography>
            </Grid>
            <Grid
                container
                justify={isDesktop?'flex-end':'center'}
                direction="row"
                alignItems='flex-end'
            >
                <Grid className={ isDesktop  ? classes.button : classes.buttonMob}>
                    <Button variant="contained" color="primary" onClick={onNewFeedStorage}>Add Feed Formulation Storage</Button>
                </Grid>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}


            columns={[
                { title: 'id', field: 'id', hidden: true },
                FeedStorageColumn,
                { title: 'Formula', field: 'formulaName', hidden: !isDesktop, editable: 'never' },
                { title: 'Available', field: 'availableStorage', hidden: !isDesktop, editable: 'never' },
            ]}
            title='Feed Formulation Storage  Table'
            data={feedFormulationStorage}
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                toolbar: true
            }}
           actions={actions}
        />
        <FeedformulationStorage 
            deleteErrorMessage={removeErrorMessage} 
            farmID={farmID} 
            FeedstorageData = {feedFormulationStorage}
            errorMessage={errorMessage} 
            setErrorMessage={setErrorMessage}
            showDialog={showFeedStortageDialog} 
            handleClose={handleLabourCostClose} 
            onSave={onFeedStorageSave}     
            onEdit={onFeedStorageUpdate}
            editMode={feedformulationStorageEditMode} 
            data={currentFeedFormulationStorage}
        />

        <ConfirmDialog 
            showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onFeedStorageDeleteConform}
            title='Delete Feed Storage Formula ?'
            subText='This action will delete the Storage Formula. Please confirm.' />
    </div>
}

export default FeedformulationStorageView;