import React, {useEffect,useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button"; 
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider,DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MaterialTable } from '../../../components';
//import Icon from '@material-ui/core/Icon';
import api from '../../../common/api';
import validate from "validate.js";import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useGlobalState} from '../../../state';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

const schema = {
    paymentDate: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    paymentAmount: {
        presence: {allowEmpty: false},
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 1,
        }
    },
    paymentMode: {
        presence: {allowEmpty: false, message: 'is required'},
        type: 'number',
    },
};
const paymentSchema = {
    saleId: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    otherIncomeId: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    paymentAmount: {
        presence: {allowEmpty: false},
       // type: 'number',
        numericality: {
            greaterThanOrEqualTo: 1,
        }
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },      
      noteTextField: {
        // margin: theme.spacing(1),
         marginTop: theme.spacing(0)
     },
 
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
        paddingBottom: theme.spacing(1.25)
    },
    textField: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(0)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    datePicker: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth:160
    },
    invoice: {
        float:'Left'
    },
    grid: {
        marginLeft: theme.spacing(0),
        margin: theme.spacing(2),
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '2'
    },

}));

// starting point
const LineSaleBulkPayment = ({ deleteErrorMessage, errorMessage, data ,banks, onSave, onEdit, handlePaymentClose,customerNames, showPaymentDialog, editMode ,showError, showMessage}) => {
    const classes = useStyles();
    const [formState,hasError,handleChange,updateData] = useFromValidator(schema);
    const [showBanks, setShowBanks] = useState(false)
    const [showInvoiceTable,setShowInvoiceTable] = useState(false)
    const [unPaidPayments, setUnPaidPayment] = useState([]);
    const [deletedPayments, setDeletedPayments] = useState([]);
    const [paymentTable, setPaymentTable] = useState([]);
    const [popupLoading, setPopupLoading] = useState(false) ;
    const [remainingPaymentAmount, setRemainingPaymentAmount] = useState(0);
    let tempPaymentData = [];
    const [originalUnPaidPayments, setOriginalUnPaidPayments] = useState([]);
    const [includeTDSValue, setIncludeTDSValue] = useState(false);
    let unPaidPaymentdisplayArr = [];
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }



    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        setShowInvoiceTable(false);
        setRemainingPaymentAmount(0)
        console.debug(data,showInvoiceTable)
        setUnPaidPayment([])
        setDeletedPayments([])
        setPaymentTable([])
        if(editMode && data !== undefined && data.customerId !== undefined){
            let customerIndex = customerNames.findIndex((srcData) => {
                return srcData.id === data.customerId;
            });
            if(data.paymentMode === 2){
                let bankIndex = banks.findIndex((srcData) => {
                    return srcData.id === data.paymentBankId;
                });
                data.bankIndex = bankIndex
            }
            setIncludeTDSValue(data.includeTDS === 1 ? true  : false)
            setPaymentTable(data.paymentInvoices || [])
            console.debug("paymentTable ",paymentTable)
            getAllLineSaleUnpaid(data.customerId, data.paymentInvoices || [])
            data.customerIndex = customerIndex
        }
        if(showPaymentDialog){
            setPopupLoading(false);
            updateData(data, editMode);
        }
        if(editMode){
            console.log("EDIT MODE 2");
            if( data !== undefined && data.paymentMode !== undefined && data.paymentMode === 2){
                setShowBanks(true)
            }else{
                setShowBanks(false)
            }
        }
    };
    useEffect(resetBeforeEdit, [showPaymentDialog]);
    const validatePayments = (newData) =>{
        if((newData.saleId === undefined && newData.invoice !== undefined && newData.invoice.saleId !== undefined)||(newData.otherIncomeId === undefined && newData.invoice !== undefined && newData.invoice.otherIncomeId !== undefined)){
            newData = {
                ...newData,
                saleId : newData.invoice.saleId !== undefined ? newData.invoice.saleId : "",
                otherIncomeId : newData.invoice.otherIncomeId !== undefined ? newData.invoice.otherIncomeId : ""
            }
        }
        let errors = validate(newData, paymentSchema);
        if(errors){
            return  'Add bill number and payment amount.'
        }
        let index = originalUnPaidPayments.findIndex(newData.saleId!==0?(val => val.id === newData.saleId):(val => val.id === newData.otherIncomeId))
        let paymentAmount = originalUnPaidPayments[index].remainingAmount
        if(includeTDSValue){
            paymentAmount = paymentAmount + originalUnPaidPayments[index].tdsValue
        }
        if(parseFloat(newData.paymentAmount) > paymentAmount){
            return  'Payment amount is greater than remaining amount.'
        }

        return ''
    }

    const onSubmit = () => {
        setPopupLoading(true) 
        console.log(">>>>>>ONENTER>>",tempPaymentData)
        let tempData = {
            ...formState.values,
            includeTDS :  includeTDSValue ? 1 : 0
        };
        tempData = {...tempData,
                unPaidPayments}
            console.log(">>>>>>ONSUBMIT", tempData)
        if(tempData.paymentMode === 2){
            if(formState.values.bankIndex===undefined){
                alert('select Bank Name')
                setPopupLoading(false) 
                return
            }
            else{
            tempData = {
                ...tempData,
                paymentBankId:banks[formState.values.bankIndex].id,
                paymentBankNamebanks:[formState.values.bankIndex].name
            }}
        }
        let totalPaymentAmount = 0
        paymentTable.forEach((ele)=>{
            //let index = originalUnPaidPayments.findIndex((val => val.id === ele.invoice.saleId))
            totalPaymentAmount = totalPaymentAmount + ele.paymentAmount
        })
        if((paymentTable.length !== 0) && (totalPaymentAmount !== formState.values.paymentAmount)){
            alert('Total Invoice payment is not equal to Amount.')
            setPopupLoading(false);
            return
        }
        tempData.customerId = customerNames[formState.values.customerIndex].id
        tempData.customerName = customerNames[formState.values.customerIndex].companyName
        tempData.paymentInvoices = paymentTable
        tempData.deletedPaymentInvoices = deletedPayments
        console.debug(tempData)
        var errorElement = document.getElementById("error");
        if(editMode){
            console.log("EDIT MODE");
            onEdit(tempData, errorElement, setPopupLoading);
        }else{
            onSave(tempData, errorElement, setPopupLoading);
        }
        setPaymentTable(tempData.paymentInvoices);
        setIncludeTDSValue(false)
    };


    const handleCustomer = (event) => {
        customerNames.forEach((customer,index)=>{
            if(event.target.value === index){
                getAllLineSaleUnpaid(customer.id, [])
            }
        })
    }
    const getAllLineSaleUnpaid = (customerID, table) => {      
        const response = api.get(`getUnPaidInvoicesForLineSale/${customerID}`)
        
        response.then(res => {
            res.payment = 0
            setOriginalUnPaidPayments([...res]);
            let temp = [
                ...res
            ]

            res.forEach((ele)=>{
                if (ele.totalPaymentInvoice + ele.totalSalePayment + ele.tdsValue === ele.totalBillValue){
                    let index = temp.findIndex((srcData) => {
                        return srcData.id === ele.id;
                    }); 
                    if (index > -1){
                        temp.splice(index, 1);
                    }
                }
            })
            table.forEach((ele)=>{
            let index = temp.findIndex((srcData) => {
                if(ele.invoice.saleId!==0){
                    return srcData.id === ele.invoice.saleId;}
                    else{return srcData.id === ele.invoice.otherIncomeId;}
            }); 
            if (index > -1){
                temp.splice(index, 1);
            }
            })
            setUnPaidPayment([...temp]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                   console.log("Error")
                else
                console.log(err)
                setUnPaidPayment([]);
            } else {
                console.log(err)
            }
        })
        return response;
    }

    const handleChangePaymentMode = (event) => {
        if(event.target.name === "paymentMode" && event.target.value === 2){
            console.debug("in handle ",event)
            setShowBanks(true)
        }else{
            setShowBanks(false)
        }
    }

    const handleChangeAmount = (event) =>{
        if(event.target.value !== ""){
            let totalAmount = parseFloat(event.target.value)
            let addedValue = totalAmount - (formState.values.paymentAmount || 0)
            setShowInvoiceTable(false)
            setRemainingPaymentAmount(remainingPaymentAmount + addedValue)

        
        }
        else if(event.target.value===""){
            setRemainingPaymentAmount(0)
        }
    }

    const isFormValid = () =>{
        return  formState.values.paymentAmount!==undefined &&
                formState.values.paymentMode!==undefined &&
                formState.values.paymentDate!==undefined &&
                formState.values.customerIndex!==undefined
        // return formState.isValid;
    }

    return <Dialog maxWidth='md' disableBackdropClick open={showPaymentDialog} onClose={handlePaymentClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >Add  Payment
        <IconButton aria-label="close" className={classes.closeButton} onClick={handlePaymentClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>

          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/>
                </div>
                <br/><br/>
                <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
            <DatePicker disabled
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Payment Date"
                format="dd/MM/yyyy"
                value={formState.values.paymentDate || null}
                onChange={data => {
                    handleChange({target: {name: 'paymentDate', value: data}})
                }}
                animateYearScrolling
                name = "paymentDate"
                style={{backgroundColor:"#ffffff"}}
            />
            </MuiPickersUtilsProvider>
            <TextField disabled
                className={classes.textField}
                error={hasError('paymentAmount')}
                helperText={
                    hasError('paymentAmount') ? formState.errors.paymentAmount[0] : null
                }
                label="Amount"
                name="paymentAmount"
                onChange={event =>{handleChange(event,handleChangeAmount)}}
                type="number"
                value={formState.values.paymentAmount || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <FormControl disabled className={classes.formControl}>
                  <InputLabel disabled id="customerName-select-outlined-label">
                      Customer Name
                  </InputLabel>
                  <Select disabled
                      id="customerName-select"
                      value={formState.values.customerIndex !== undefined ? formState.values.customerIndex : ''}
                      onChange={event =>{handleChange(event,handleCustomer)}}
                      name="customerIndex"
                  >
                      {customerNames !== undefined && customerNames !== null ?
                          customerNames.map((customer, index) => {
                              return <MenuItem key={customer.id} value={index}>{customer.companyName}</MenuItem>
                          }
                              
                          ) : ""}
                  </Select>
                  
              </FormControl>
            <FormControl disabled className={classes.formControl}>
                <InputLabel disabled id="paymentMode-select-outlined-label">
                Payment Mode
                </InputLabel>
                <Select disabled
                id="paymentMode"
                value={formState.values.paymentMode || ''}
                onChange={event =>{handleChange(event,handleChangePaymentMode)}}
                name="paymentMode"
                >
                    <MenuItem disabled key = {0} value={1}>Cash</MenuItem>
                    <MenuItem disabled key = {1} value={2}>Bank</MenuItem>
                </Select>
            </FormControl>
            {showBanks ? 
            <FormControl disabled className={classes.formControl}>
                <InputLabel disabled id="banks-select-label">Banks</InputLabel>
                <Select disabled
                id="banks-select"
                value={formState.values.bankIndex !== undefined ? formState.values.bankIndex : ''}
                onChange={handleChange}
                name="bankIndex"
                >
                {banks.map((bank,index)=>
                    <MenuItem key = {bank.id} value={index}>{bank.name}</MenuItem>
                )}
                </Select>
            </FormControl>
            :null}
            <TextField disabled
                multiline
                rows="3"
                className={classes.textField}
                error={hasError('paymentNote')}
                fullWidth
                helperText={
                    hasError('paymentNote') ? formState.errors.paymentNote[0] : null
                }
                label="Note"
                name="paymentNote"
                onChange={handleChange}
                type="text"
                value={formState.values.paymentNote || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <Grid disabled
                className={classes.grid}
                container
                justify='space-between'
                alignItems='flex-end'
            >  
            <Grid  item xs={6}>
                    <Typography style={{opacity: '0.6'}} variant="h5" component="h2">
                        {"Remaining Amount : "+remainingPaymentAmount.toFixed(2)}
                    </Typography>
                </Grid>   
                <Grid  item xs={6}>
                    <Typography style={{opacity: '0.6'}} variant="h5" component="h2">
                        {"Include TCS Amount"}
                        <Checkbox
                            checked={includeTDSValue}
                            onChange = {(event)=>{
                                setIncludeTDSValue(event.target.checked)}}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </Typography>
                </Grid>  

            </Grid>

            {formState.isValid && paymentTable &&  originalUnPaidPayments ?       
            <MaterialTable 
                columns={[
                    {
                        title: 'Invoice', field: 'key', editComponent: props => {
                            let index = -1
                            let saleId= 0
                            let otherIncomeId = 0
                            if(props.rowData.invoice !== undefined && props.rowData.invoice.saleId !== undefined&&props.rowData.invoice.otherIncomeId !== undefined){
                                saleId = props.rowData.invoice.saleId
                                otherIncomeId = props.rowData.invoice.otherIncomeId
                            }else if(props.rowData.saleId !== undefined||props.rowData.otherIncomeId !== undefined){
                                saleId = props.rowData.saleId
                                otherIncomeId = props.rowData.otherIncomeId
                            }
                            if(saleId !== 0||otherIncomeId!==0){
                                let tempUnPaidPaymentIndex = unPaidPaymentdisplayArr.findIndex(saleId!==0?(val => val.id === saleId):(val => val.id === otherIncomeId));

                                if (tempUnPaidPaymentIndex === -1) {
                                    let originalUnPaidPaymentsIndex = originalUnPaidPayments.findIndex(saleId!==0?(val => val.id === saleId):(val => val.id === otherIncomeId));
                                    if (originalUnPaidPaymentsIndex === -1) {
                                        unPaidPaymentdisplayArr = unPaidPayments
                                        index = '';
                                    }else{
                                        unPaidPaymentdisplayArr = ([...unPaidPayments, originalUnPaidPayments[originalUnPaidPaymentsIndex]])
                                        index = unPaidPaymentdisplayArr.length - 1
                                    }
                                }else{
                                    index = tempUnPaidPaymentIndex
                                }
                            }else{
                                unPaidPaymentdisplayArr = unPaidPayments
                                index = '';
                            }
                            return <Select disabled
                                id="materialType-select"
                                value={index}
                                required
                                onChange={e => {
                                        let outStandingAmount  = unPaidPaymentdisplayArr[e.target.value].remainingAmount 
                                        if(includeTDSValue){
                                            outStandingAmount = outStandingAmount + unPaidPaymentdisplayArr[e.target.value].tdsValue 
                                        }
                                        if(outStandingAmount > 0){
                                            /*let ptData = paymentTable
                                            let obj = null*/
                                            let rowRemainingAmount = 0
                                            let localReminingAmount = remainingPaymentAmount
    
                                            /*if (props.rowData.tableData!== undefined && props.rowData.tableData.id !== undefined){
                                                obj = paymentTable[props.rowData.tableData.id]
                                            }*/
    
                                            if(outStandingAmount > localReminingAmount && localReminingAmount !== 0){
                                                rowRemainingAmount = localReminingAmount
                                                localReminingAmount = 0
                                                //break;
                                            }else{
                                                rowRemainingAmount = outStandingAmount
                                                localReminingAmount = localReminingAmount - outStandingAmount
                                            }
                                            props.rowData.paymentAmount = rowRemainingAmount
                                            //Auto adding rowdata******************************
                                            /*if(obj === null){
                                                ptData = [...ptData,
                                                    {
                                                        id: 0,
                                                        invoice: {
                                                            id: unPaidPaymentdisplayArr[e.target.value].id, 
                                                            saleId: unPaidPaymentdisplayArr[e.target.value].id, 
                                                            outstandingAmount: 0
                                                        },
                                                        paymentAmount: rowRemainingAmount,
                                                        tableData:{
                                                            id: paymentTable.length
                                                        }
                                                    },
                                                ]
                                            }/*else
                                            ptData = [...ptData.slice(0, props.rowData.tableData.id),
                                                {...obj},
                                                ...ptData.slice(props.rowData.tableData.id + 1)]
                                                */
                                                //props.rowData.paymentAmount = rowRemainingAmount
                                                
                                                /* if(localReminingAmount === 0 && localReminingAmount === remainingPaymentAmount){
                                                    alert("Remaining Payment is Zero. So please cancel the current row addition.")
                                                }else{
                                                    let unpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                                        return src.id === unPaidPaymentdisplayArr[e.target.value].id
                                                    })
                                                    let newUnPaidPayments = [...unPaidPayments];
                                                    newUnPaidPayments.splice(unpaidPaymentIndex, 1);
                        
                                                    setUnPaidPayment([...newUnPaidPayments])
                                                //props.onRowDataChange(props.rowData)
                                                    setPaymentTable([
                                                        ...ptData])
                                                }
                                            setRemainingPaymentAmount(localReminingAmount)*/
    
                                    }
                                        
                                    console.log(e.target.value)
                                    if(unPaidPaymentdisplayArr[e.target.value].typeOfPurchase ===1){
                                    props.rowData.saleId = unPaidPaymentdisplayArr[e.target.value].id;
                                    props.rowData.otherIncomeId = 0}
                                    else
                                    {props.rowData.otherIncomeId = unPaidPaymentdisplayArr[e.target.value].id;
                                     props.rowData.saleId = 0}
                                    props.rowData.invoice = {
                                        ...props.rowData.invoice,
                                        saleId:props.rowData.saleId,
                                        otherIncomeId:props.rowData.otherIncomeId
                                    }
                                    props.rowData.remainingAmount = outStandingAmount;

                                    props.rowData.key = "Invoice #"+unPaidPaymentdisplayArr[e.target.value].dispalyID+" | "+
                                    (outStandingAmount).toLocaleString(user.locales, 
                                    amountObj)+" Outstanding"
                                    props.onChange("Invoice #"+unPaidPaymentdisplayArr[e.target.value].dispalyID+" | "+
                                    (outStandingAmount).toLocaleString(user.locales, 
                                    amountObj)+" Outstanding");
                                }}
                            >
                                {unPaidPaymentdisplayArr.map((ele, index) =>{
                                    let billValue = ele.remainingAmount
                                    if(includeTDSValue){
                                        billValue = billValue + ele.tdsValue
                                    }
                                    return <MenuItem disabled key={index} value={index}>
                                        {
                                             ele.typeOfPurchase === 1?
                                            "Invoice #"+ele.dispalyID+" | "+(billValue).toLocaleString(user.locales, 
                                            amountObj)
                                            :
                                            "Other Income #"+ele.dispalyID+" | "+(billValue).toLocaleString(user.locales, 
                                            amountObj)
                                        }
                                    </MenuItem>
                                })}
                            </Select>
                        },
                        render: rowData => {
                            let index = originalUnPaidPayments.findIndex(rowData.invoice.saleId!==0?(val => val.id === rowData.invoice.saleId):(val => val.id === rowData.invoice.otherIncomeId));
                            
                            let billValue = 0
                            if(index > -1 ){
                                billValue = originalUnPaidPayments[index].remainingAmount
                                if(includeTDSValue){
                                    billValue = billValue + originalUnPaidPayments[index].tdsValue
                                }
                            }

                            if(rowData.invoice.saleId!==0){
                            return <span>{index > -1 ? "Invoice #"+originalUnPaidPayments[index].dispalyID+
                            " | "+(billValue).toLocaleString(user.locales,amountObj) : ""}</span>}
                            else{
                            return <span>{index > -1 ? "Other Income #"+originalUnPaidPayments[index].dispalyID+
                            " | "+(billValue).toLocaleString(user.locales,amountObj) : ""}</span>}
                    }},
                    {title: 'Payment', field:'paymentAmount', type: 'numeric' }
                    
                ]}
                style={{opacity: '0.6'}}
                data={paymentTable || []}
                options={{
                    actionsColumnIndex: -1,
                    search: false,                    
                    pageSize:5,
                    toolbar: true
                }}
                editable={{
                    onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        let err = validatePayments(newData)
                        if(err){
                            alert(err)
                            reject()
                        }else{
                            setRemainingPaymentAmount(remainingPaymentAmount - newData.paymentAmount)
                            unPaidPaymentdisplayArr = []
                            newData = {
                                ...newData,
                                invoice:{
                                    saleId : newData.saleId,
                                    otherIncomeId:newData.otherIncomeId
                                },
                                paymentAmount : parseFloat(newData.paymentAmount)
                            }
                            let unpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                if(newData.invoice.saleId!==0){
                                    return src.id === newData.invoice.saleId}
                                    else
                                    {return src.id === newData.invoice.otherIncomeId}
                            })
                            let newUnPaidPayments = [...unPaidPayments];
                            newUnPaidPayments.splice(unpaidPaymentIndex, 1);

                            setUnPaidPayment([...newUnPaidPayments])
                        setPaymentTable([...paymentTable, newData]);
                        resolve();
                    }
                    console.debug(err)
                    }),
                    onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        let err = validatePayments(newData)
                        if(err){
                            alert(err)
                            reject()
                        }else{
                            setRemainingPaymentAmount(remainingPaymentAmount + (oldData.paymentAmount - newData.paymentAmount))
                            unPaidPaymentdisplayArr = []
                        const dataUpdate = [...paymentTable];
                        newData = {
                            ...newData,
                            paymentAmount : parseFloat(newData.paymentAmount)
                        }
                        if(newData.saleId !== oldData.saleId&&newData.otherIncomeId !== oldData.otherIncomeId){
                            let newUnPaidPayments = [...unPaidPayments];
                            let newUnpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                if(newData.invoice.saleId!==0){
                                    return src.id === newData.invoice.saleId}
                                    else
                                    {return src.id === newData.invoice.otherIncomeId}
                            })
                            newUnPaidPayments.splice(newUnpaidPaymentIndex, 1);

                            let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                                if(oldData.invoice.saleId!==0){
                                    return src.id === oldData.invoice.saleId}
                                    else
                                    {return src.id === oldData.invoice.otherIncomeId}
                            })
                            
                            newUnPaidPayments = [
                                ...newUnPaidPayments,
                                originalUnPaidPayments[oldUnpaidPaymentIndex]
                            ]
                            
                            setUnPaidPayment([...newUnPaidPayments])
                        }
                        if(newData.otherIncomeId !== oldData.otherIncomeId){
                            let newUnPaidPayments = [...unPaidPayments];
                            let newUnpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                if(newData.invoice.saleId!==0){
                                    return src.id === newData.invoice.saleId}
                                    else
                                    {return src.id === newData.invoice.otherIncomeId}
                            })
                            newUnPaidPayments.splice(newUnpaidPaymentIndex, 1);

                            let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                                if(oldData.invoice.saleId!==0){
                                    return src.id === oldData.invoice.saleId}
                                    else
                                    {return src.id === oldData.invoice.otherIncomeId}
                            })
                            
                            newUnPaidPayments = [
                                ...newUnPaidPayments,
                                originalUnPaidPayments[oldUnpaidPaymentIndex]
                            ]
                            
                            setUnPaidPayment([...newUnPaidPayments])
                        }
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        setPaymentTable([...dataUpdate]);
                        resolve();
                    }
                    }),
                    onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        unPaidPaymentdisplayArr = []
                        const dataDelete = [...paymentTable];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        setDeletedPayments([...deletedPayments, oldData])
                        setPaymentTable([...dataDelete]);
                        setRemainingPaymentAmount( remainingPaymentAmount + oldData.paymentAmount)
                        let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                            if(oldData.invoice.saleId!==0){
                                return src.id === oldData.invoice.saleId}
                                else
                                {return src.id === oldData.invoice.otherIncomeId}
                        })
                        let newUnPaidPayments = [
                            ...unPaidPayments
                        ]
                        if(oldUnpaidPaymentIndex > -1){
                            newUnPaidPayments = [
                                ...newUnPaidPayments,
                                originalUnPaidPayments[oldUnpaidPaymentIndex]
                            ]
                        }
                        setUnPaidPayment([...newUnPaidPayments])

                        resolve();
                    }),
                }}
                title='Invoices and Other Income'
            /> :""}
             </div>
                :
                <div>

          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
          </div> 
          <br/>
            <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
            <DatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Payment Date"
                format="dd/MM/yyyy"
                value={formState.values.paymentDate || null}
                onChange={data => {
                    handleChange({target: {name: 'paymentDate', value: data}})
                }}
                animateYearScrolling
                name = "paymentDate"
                style={{backgroundColor:"#ffffff"}}
            />
            </MuiPickersUtilsProvider>
            <TextField
                className={classes.textField}
                error={hasError('paymentAmount')}
                helperText={
                    hasError('paymentAmount') ? formState.errors.paymentAmount[0] : null
                }
                label="Amount"
                name="paymentAmount"
                onChange={event =>{handleChange(event,handleChangeAmount)}}
                type="number"
                value={formState.values.paymentAmount || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <FormControl className={classes.formControl}>
                  <InputLabel id="customerName-select-outlined-label">
                      Customer Name
                  </InputLabel>
                  <Select
                      id="customerName-select"
                      value={formState.values.customerIndex !== undefined ? formState.values.customerIndex : ''}
                      onChange={event =>{handleChange(event,handleCustomer)}}
                      name="customerIndex"
                  >
                      {customerNames !== undefined && customerNames !== null ?
                          customerNames.map((customer, index) =>
                              <MenuItem key={customer.id} value={index}>{customer.companyName}</MenuItem>
                          ) : ""}
                  </Select>
                  
              </FormControl>
            <FormControl  className={classes.formControl}>
                <InputLabel id="paymentMode-select-outlined-label">
                Payment Mode
                </InputLabel>
                <Select
                id="paymentMode"
                value={formState.values.paymentMode || ''}
                onChange={event =>{handleChange(event,handleChangePaymentMode)}}
                name="paymentMode"
                >
                    <MenuItem key = {0} value={1}>Cash</MenuItem>
                    <MenuItem key = {1} value={2}>Bank</MenuItem>
                </Select>
            </FormControl>
            {showBanks ? 
            <FormControl className={classes.formControl}>
                <InputLabel id="banks-select-label">Banks</InputLabel>
                <Select
                id="banks-select"
                value={formState.values.bankIndex !== undefined ? formState.values.bankIndex : ''}
                onChange={handleChange}
                name="bankIndex"
                >
                {banks.map((bank,index)=>
                    <MenuItem key = {bank.id} value={index}>{bank.name}</MenuItem>
                )}
                </Select>
            </FormControl>
            :null}
            <TextField
                multiline
                rows="3"
                className={classes.textField}
                error={hasError('paymentNote')}
                fullWidth
                helperText={
                    hasError('paymentNote') ? formState.errors.paymentNote[0] : null
                }
                label="Note"
                name="paymentNote"
                onChange={handleChange}
                type="text"
                value={formState.values.paymentNote || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <Grid
                className={classes.grid}
                container
                justify='space-between'
                alignItems='flex-end'
            >
                <Grid  item xs={6}>
                    <Typography variant="h5" component="h2">
                        {"Remaining Amount : "+remainingPaymentAmount.toFixed(2)}
                    </Typography>
                </Grid>   
                <Grid  item xs={6}>
                    <Typography variant="h5" component="h2">
                        {"Include TCS Amount"}
                        <Checkbox
                            checked={includeTDSValue}
                            onChange = {(event)=>{
                                setIncludeTDSValue(event.target.checked)}}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </Typography>
                </Grid>   
            </Grid>

            {isFormValid() && paymentTable &&  originalUnPaidPayments ?       
            <MaterialTable
                columns={[
                    {
                        title: 'Invoice', field: 'key', editComponent: props => {
                            let index = -1
                            let saleId= 0
                            let otherIncomeId = 0
                            if(props.rowData.invoice !== undefined && props.rowData.invoice.saleId !== undefined&&props.rowData.invoice.otherIncomeId !== undefined){
                                saleId = props.rowData.invoice.saleId
                                otherIncomeId = props.rowData.invoice.otherIncomeId
                            }else if(props.rowData.saleId !== undefined||props.rowData.otherIncomeId !== undefined){
                                saleId = props.rowData.saleId
                                otherIncomeId = props.rowData.otherIncomeId
                            }
                            if(saleId !== 0||otherIncomeId!==0){
                                let tempUnPaidPaymentIndex = unPaidPaymentdisplayArr.findIndex(saleId!==0?(val => val.id === saleId):(val => val.id === otherIncomeId));

                                if (tempUnPaidPaymentIndex === -1) {
                                    let originalUnPaidPaymentsIndex = originalUnPaidPayments.findIndex(saleId!==0?(val => val.id === saleId):(val => val.id === otherIncomeId));
                                    if (originalUnPaidPaymentsIndex === -1) {
                                        unPaidPaymentdisplayArr = unPaidPayments
                                        index = '';
                                    }else{
                                        unPaidPaymentdisplayArr = ([...unPaidPayments, originalUnPaidPayments[originalUnPaidPaymentsIndex]])
                                        index = unPaidPaymentdisplayArr.length - 1
                                    }
                                }else{
                                    index = tempUnPaidPaymentIndex
                                }
                            }else{
                                unPaidPaymentdisplayArr = unPaidPayments
                                index = '';
                            }
                            return <Select
                                id="materialType-select"
                                value={index}
                                required
                                onChange={e => {
                                        let outStandingAmount  = unPaidPaymentdisplayArr[e.target.value].remainingAmount 
                                        if(includeTDSValue){
                                            outStandingAmount = outStandingAmount + unPaidPaymentdisplayArr[e.target.value].tdsValue 
                                        }
                                        if(outStandingAmount > 0){
                                            /*let ptData = paymentTable
                                            let obj = null*/
                                            let rowRemainingAmount = 0
                                            let localReminingAmount = remainingPaymentAmount
    
                                            /*if (props.rowData.tableData!== undefined && props.rowData.tableData.id !== undefined){
                                                obj = paymentTable[props.rowData.tableData.id]
                                            }*/
    
                                            if(outStandingAmount > localReminingAmount && localReminingAmount !== 0){
                                                rowRemainingAmount = localReminingAmount
                                                localReminingAmount = 0
                                                //break;
                                            }else{
                                                rowRemainingAmount = outStandingAmount
                                                localReminingAmount = localReminingAmount - outStandingAmount
                                            }
                                            props.rowData.paymentAmount = rowRemainingAmount
                                            //Auto adding rowdata******************************
                                            /*if(obj === null){
                                                ptData = [...ptData,
                                                    {
                                                        id: 0,
                                                        invoice: {
                                                            id: unPaidPaymentdisplayArr[e.target.value].id, 
                                                            saleId: unPaidPaymentdisplayArr[e.target.value].id, 
                                                            outstandingAmount: 0
                                                        },
                                                        paymentAmount: rowRemainingAmount,
                                                        tableData:{
                                                            id: paymentTable.length
                                                        }
                                                    },
                                                ]
                                            }/*else
                                            ptData = [...ptData.slice(0, props.rowData.tableData.id),
                                                {...obj},
                                                ...ptData.slice(props.rowData.tableData.id + 1)]
                                                */
                                                //props.rowData.paymentAmount = rowRemainingAmount
                                                
                                                /* if(localReminingAmount === 0 && localReminingAmount === remainingPaymentAmount){
                                                    alert("Remaining Payment is Zero. So please cancel the current row addition.")
                                                }else{
                                                    let unpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                                        return src.id === unPaidPaymentdisplayArr[e.target.value].id
                                                    })
                                                    let newUnPaidPayments = [...unPaidPayments];
                                                    newUnPaidPayments.splice(unpaidPaymentIndex, 1);
                        
                                                    setUnPaidPayment([...newUnPaidPayments])
                                                //props.onRowDataChange(props.rowData)
                                                    setPaymentTable([
                                                        ...ptData])
                                                }
                                            setRemainingPaymentAmount(localReminingAmount)*/
    
                                    }
                                        
                                    console.log(e.target.value)
                                    if(unPaidPaymentdisplayArr[e.target.value].typeOfPurchase ===1){
                                    props.rowData.saleId = unPaidPaymentdisplayArr[e.target.value].id;
                                    props.rowData.otherIncomeId = 0}
                                    else
                                    {props.rowData.otherIncomeId = unPaidPaymentdisplayArr[e.target.value].id;
                                     props.rowData.saleId = 0}
                                    props.rowData.invoice = {
                                        ...props.rowData.invoice,
                                        saleId:props.rowData.saleId,
                                        otherIncomeId:props.rowData.otherIncomeId
                                    }
                                    props.rowData.remainingAmount = outStandingAmount;

                                    props.rowData.key = "Invoice #"+unPaidPaymentdisplayArr[e.target.value].dispalyID+" | "+
                                    (outStandingAmount).toLocaleString(user.locales, 
                                    amountObj)+" Outstanding"
                                    props.onChange("Invoice #"+unPaidPaymentdisplayArr[e.target.value].dispalyID+" | "+
                                    (outStandingAmount).toLocaleString(user.locales, 
                                    amountObj)+" Outstanding");
                                }}
                            >
                                {unPaidPaymentdisplayArr.map((ele, index) =>{
                                    let billValue = ele.remainingAmount
                                    if(includeTDSValue){
                                        billValue = billValue + ele.tdsValue
                                    }
                                    return <MenuItem key={index} value={index}>
                                        {
                                             ele.typeOfPurchase === 1?
                                            "Invoice #"+ele.dispalyID+" | "+(billValue).toLocaleString(user.locales, 
                                            amountObj)
                                            :
                                            "Other Income #"+ele.dispalyID+" | "+(billValue).toLocaleString(user.locales, 
                                            amountObj)
                                        }
                                    </MenuItem>
                                })}
                            </Select>
                        },
                        render: rowData => {
                            let index = originalUnPaidPayments.findIndex(rowData.invoice.saleId!==0?(val => val.id === rowData.invoice.saleId):(val => val.id === rowData.invoice.otherIncomeId));
                            
                            let billValue = 0
                            if(index > -1 ){
                                billValue = originalUnPaidPayments[index].remainingAmount
                                if(includeTDSValue){
                                    billValue = billValue + originalUnPaidPayments[index].tdsValue
                                }
                            }

                            if(rowData.invoice.saleId!==0){
                            return <span>{index > -1 ? "Invoice #"+originalUnPaidPayments[index].dispalyID+
                            " | "+(billValue).toLocaleString(user.locales,amountObj) : ""}</span>}
                            else{
                            return <span>{index > -1 ? "Other Income #"+originalUnPaidPayments[index].dispalyID+
                            " | "+(billValue).toLocaleString(user.locales,amountObj) : ""}</span>}
                    }},
                    {title: 'Payment', field:'paymentAmount', type: 'numeric' }
                    
                ]}
                data={paymentTable || []}
                options={{
                    actionsColumnIndex: -1,
                    search: false,                    
                    pageSize:5,
                    toolbar: true
                }}
                editable={{
                    onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        let err = validatePayments(newData)
                        if(err){
                            alert(err)
                            reject()
                        }else{
                            setRemainingPaymentAmount(remainingPaymentAmount - newData.paymentAmount)
                            unPaidPaymentdisplayArr = []
                            newData = {
                                ...newData,
                                invoice:{
                                    saleId : newData.saleId,
                                    otherIncomeId:newData.otherIncomeId
                                },
                                paymentAmount : parseFloat(newData.paymentAmount)
                            }
                            let unpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                if(newData.invoice.saleId!==0){
                                    return src.id === newData.invoice.saleId}
                                    else
                                    {return src.id === newData.invoice.otherIncomeId}
                            })
                            let newUnPaidPayments = [...unPaidPayments];
                            newUnPaidPayments.splice(unpaidPaymentIndex, 1);

                            setUnPaidPayment([...newUnPaidPayments])
                        setPaymentTable([...paymentTable, newData]);
                        resolve();
                    }
                    console.debug(err)
                    }),
                    onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        let err = validatePayments(newData)
                        if(err){
                            alert(err)
                            reject()
                        }else{
                            setRemainingPaymentAmount(remainingPaymentAmount + (oldData.paymentAmount - newData.paymentAmount))
                            unPaidPaymentdisplayArr = []
                        const dataUpdate = [...paymentTable];
                        newData = {
                            ...newData,
                            paymentAmount : parseFloat(newData.paymentAmount)
                        }
                        if(newData.saleId !== oldData.saleId&&newData.otherIncomeId !== oldData.otherIncomeId){
                            let newUnPaidPayments = [...unPaidPayments];
                            let newUnpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                if(newData.invoice.saleId!==0){
                                    return src.id === newData.invoice.saleId}
                                    else
                                    {return src.id === newData.invoice.otherIncomeId}
                            })
                            newUnPaidPayments.splice(newUnpaidPaymentIndex, 1);

                            let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                                if(oldData.invoice.saleId!==0){
                                    return src.id === oldData.invoice.saleId}
                                    else
                                    {return src.id === oldData.invoice.otherIncomeId}
                            })
                            
                            newUnPaidPayments = [
                                ...newUnPaidPayments,
                                originalUnPaidPayments[oldUnpaidPaymentIndex]
                            ]
                            
                            setUnPaidPayment([...newUnPaidPayments])
                        }
                        if(newData.otherIncomeId !== oldData.otherIncomeId){
                            let newUnPaidPayments = [...unPaidPayments];
                            let newUnpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                if(newData.invoice.saleId!==0){
                                    return src.id === newData.invoice.saleId}
                                    else
                                    {return src.id === newData.invoice.otherIncomeId}
                            })
                            newUnPaidPayments.splice(newUnpaidPaymentIndex, 1);

                            let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                                if(oldData.invoice.saleId!==0){
                                    return src.id === oldData.invoice.saleId}
                                    else
                                    {return src.id === oldData.invoice.otherIncomeId}
                            })
                            
                            newUnPaidPayments = [
                                ...newUnPaidPayments,
                                originalUnPaidPayments[oldUnpaidPaymentIndex]
                            ]
                            
                            setUnPaidPayment([...newUnPaidPayments])
                        }
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        setPaymentTable([...dataUpdate]);
                        resolve();
                    }
                    }),
                    onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        unPaidPaymentdisplayArr = []
                        const dataDelete = [...paymentTable];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        setDeletedPayments([...deletedPayments, oldData])
                        setPaymentTable([...dataDelete]);
                        setRemainingPaymentAmount( remainingPaymentAmount + oldData.paymentAmount)
                        let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                            if(oldData.invoice.saleId!==0){
                                return src.id === oldData.invoice.saleId}
                                else
                                {return src.id === oldData.invoice.otherIncomeId}
                        })
                        let newUnPaidPayments = [
                            ...unPaidPayments
                        ]
                        if(oldUnpaidPaymentIndex > -1){
                            newUnPaidPayments = [
                                ...newUnPaidPayments,
                                originalUnPaidPayments[oldUnpaidPaymentIndex]
                            ]
                        }
                        setUnPaidPayment([...newUnPaidPayments])

                        resolve();
                    }),
                }}
                title='Invoices and Other Income'
            /> :""}
             </div>

}
        </DialogContent>
        <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!isFormValid() || popupLoading}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
           
    </Dialog>
};

export default LineSaleBulkPayment;