const schema = {
    materialTypeIndex: {
      presence: { allowEmpty: false, message: "is required" },
    },
    materialIndex: {
      presence: { allowEmpty: false, message: "is required" },
    },
};

const weightTypeSchema = {
    deliveryWeight: {
      presence: { allowEmpty: false, message: "" },
      type: "number",
      numericality: {
        greaterThanOrEqualTo: 0,
      },
    },
    costPerUnit: {
      presence: { allowEmpty: false, message: "" },
      type: "number",
      numericality: {
        greaterThanOrEqualTo: 0,
      },
    },
    billWeight: {
      presence: { allowEmpty: false, message: "is required" },
      type: "number",
      numericality: {
        greaterThanOrEqualTo: 0,
      },
    },
    weightType: {
      presence: { allowEmpty: false, message: "is required" },
      type: "number",
      numericality: {
        greaterThanOrEqualTo: -1,
      },
    },
    storage: {
      type: "boolean",
    },
};

const shedSchema = {
    shedIndex: {
      presence: { allowEmpty: false, message: "is required" },
      type: "number",
      numericality: {
        greaterThanOrEqualTo: 0,
      },
    },
};

const farmSchema = {
    farmIndex: {
      presence: { allowEmpty: false, message: "is required" },
      type: "number",
      numericality: {
        greaterThanOrEqualTo: 0,
      },
    },
};

const nonweightTypeSchema = {
    costPerUnit: {
      presence: { allowEmpty: false, message: "" },
      type: "number",
      numericality: {
        greaterThanOrEqualTo: 0,
      },
    },
    quantity: {
      presence: { allowEmpty: false, message: "is required" },
      type: "number",
      numericality: {
        greaterThanOrEqualTo: 0,
      },
    },
};

const proSchema = {
    date : { presence: { allowEmpty: false, message: 'is required' }},
    materialTypeIndex: {
      presence: { allowEmpty: false, message: "is required" },
    },
    materialIndex: {
      presence: { allowEmpty: false, message: "is required" },
    },
  };

const proWeightTypeSchema = {
    deliveryWeight: {
      presence: { allowEmpty: false, message: "" },
      type: "number",
      numericality: {
        greaterThanOrEqualTo: 0,
      },
    },
    costPerUnit: {
      presence: { allowEmpty: false, message: "" },
      type: "number",
      numericality: {
        greaterThanOrEqualTo: 0,
      },
    },
  };

const getAllSchema = (isPro)=>{
    var allSchema = {
        schema : schema,
        weightTypeSchema : weightTypeSchema,
        shedSchema : shedSchema,
        farmSchema : farmSchema,
        nonweightTypeSchema : nonweightTypeSchema
    };
    if(isPro){
        allSchema = {...allSchema,schema : proSchema,weightTypeSchema:proWeightTypeSchema};
    }
    return allSchema;
};

export default getAllSchema;