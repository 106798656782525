import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MaterialTable } from '../../../components';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

const schema = {
    farmIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    shedIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    date: {
        presence: { allowEmpty: false, message: 'is required' }
    }
};

const useStyles = makeStyles(theme => ({
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '2'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    grid: {
        marginLeft: theme.spacing(0),
        margin: theme.spacing(2),
    },
    
    
}));
let allMaterial = [];
var energyValues= {};
const CalculateFeedFormulation = ({ deleteErrorMessage, errorMessage, handleClose, showDialog, editMode = false, farms, sheds, onFarmChange, action,
    createCalculatedFeedFormulation, energies: materialNutritionMap, materials }) => {
    const classes = useStyles();
    // eslint-disable-next-line
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [data, setData] = useState([])
    const [popupLoading, setPopupLoading] = useState(false) ;
    const [totalQuantity, setTotalQuantity] = useState(0.0)

    const resetBeforeEdit = () => {
        if (showDialog) {
            allMaterial = [...materials]
            energyValues = ({
                metabolizableEnergy: {
                    name: "Metabolizable Energy",
                    value: 0
                },
                protein: {
                    name: "Protein",
                    value: 0
                },
                fat: {
                    name: "Fat",
                    value: 0
                },
                fibre: {
                    name: "Fibre",
                    value: 0
                },
                calcium: {
                    name: "Calcium",
                    value: 0
                },
                phosphorus: {
                    name: "Phosphorus",
                    value: 0
                },
                lysine: {
                    name: "Lysine",
                    value: 0
                },
                methionine: {
                    name: "Methionine",
                    value: 0
                }
            })
            setData([])
            setTotalQuantity(0.0)
            updateData(data, editMode);
           // getAllMaterials()
        }
    };

    const validate = (newData) =>{
        if(newData ===undefined || newData === null || newData.materialID === undefined ||newData.materialID < 0 || newData.quantity === undefined || newData.quantity < 0){
            return false
        }
        return true
    }

    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        setPopupLoading(true) 
        const tempData = {
            ...formState.values,
            farmID: farms[formState.values.farmIndex].id,
            farmName: farms[formState.values.farmIndex].name,
            shedID: sheds[formState.values.shedIndex].id,
            shedName: sheds[formState.values.shedIndex].name,
            materials:[...data]
        };
        if(data.length<=0){
            alert("Add any one material")
            return
        }else{
            createCalculatedFeedFormulation(tempData, setPopupLoading) 
        }
        console.debug("tempData",tempData)
        console.debug("formState",formState)
        //onSave(tempData); 
        setPopupLoading(false) 
    };

    const calculateSumProduct = (energyItem) =>{
        let sumProduct = 0.0
        data.map((row) =>{
            let materialID = row.materialID;
            let quantity = row.quantity;
            sumProduct = sumProduct + materialNutritionMap[materialID][energyItem] * quantity
            return 0
        })
        return sumProduct
    }

    const calculateEnergyData = () =>{
        let localTotal = 0.0
        data.map((material) => {
            if(material.quantity !== undefined && material.quantity !== null && !isNaN(material.quantity)){
                localTotal = localTotal + material.quantity;                
            }
            return 0
        })
        setTotalQuantity(localTotal)

        let newEnergy = {}
        Object.keys(energyValues).forEach(function (energyItem) {
            let tempEnergy = energyValues[energyItem]
            tempEnergy.value = calculateSumProduct(energyItem) /localTotal
            console.debug("tempEnergy.value ",tempEnergy.value)
            newEnergy = {
                ...newEnergy,
                [energyItem]:{
                    ...tempEnergy
                }
            }
        });
        energyValues= ({...newEnergy})
    }

    const onFarmChangeLocal = function (event) {
        onFarmChange(farms[event.target.value].id);
    }

    useEffect(calculateEnergyData, [data])

    return <Dialog maxWidth='lg' disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >{action} Feed Formulation
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
        {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={event => {
                        handleChange({ target: { name: 'date', value: event } })
                    }}
                    helperText={
                        hasError('date') ? formState.errors.date[0] : null
                    }
                    animateYearScrolling
                    name="date"
                    style={{ backgroundColor: "#ffffff" }}
                />
            </MuiPickersUtilsProvider>
            <FormControl className={classes.formControl}>
                <InputLabel id="farm-select-outlined-label">
                    Farm
                </InputLabel>
                <Select
                    id="farmIndex"
                    value={formState.values.farmIndex !== undefined ? formState.values.farmIndex : ''}
                    onChange={event => {
                        handleChange(event, onFarmChangeLocal)
                    }}
                    helperText={
                        hasError('farmIndex') ? formState.errors.farmIndex[0] : null
                    }
                    name="farmIndex"
                >

                    {farms !== undefined && farms !== null ?
                        farms.map((farm, index) => {
                            return <MenuItem key={farm.id} value={index}>{farm.name}</MenuItem>
                        }
                        ) : []}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="shed-select-outlined-label">
                    Shed
                </InputLabel>
                <Select
                    id="shedIndex"
                    value={formState.values.shedIndex !== undefined ? formState.values.shedIndex : ''}
                    onChange={handleChange}
                    name="shedIndex"
                    helperText={
                        hasError('shedIndex') ? formState.errors.shedIndex[0] : null
                    }
                >
                    {sheds !== undefined && sheds !== null ?
                        sheds.map((shed, index) =>
                            <MenuItem key={shed.id} value={index}>{shed.name}</MenuItem>
                        ) : []}
                </Select>
            </FormControl>
                    <Grid
                        className={classes.grid}
                        container
                        justify='space-between'
                        alignItems='flex-end'
                    >
                       
                       {Object.keys(energyValues).map((name, key) => {
                           let temp = (energyValues[name].value)
                           return (
                                        <Grid className={classes.grid} key={key} id={key} item xs={2}>
                                            <Typography color="textSecondary">
                                                {energyValues[name].name}
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                {isNaN(temp) ? 0 : temp.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    )})}
                        <Grid className={classes.grid} item xs={4}>
                                <Typography color="textSecondary">
                                    Total Quantity
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    {totalQuantity}
                                </Typography>
                            </Grid>
                    </Grid>
                <React.Fragment>
                    <MaterialTable
                        isLoading={false}
                        title="Energy Calculation"
                        columns={[
                            {
                                title: 'Material Name', field: 'materialName',
                                editComponent: props => {
                                    let index = allMaterial.findIndex((val => val.id === props.rowData.materialID));
                                    if (index === -1) {
                                        index = '';
                                    }
                                    ;
                                    return <Select
                                        id="material-select"
                                        value={index}
                                        onChange={e => {
                                            props.rowData.materialID = allMaterial[e.target.value].id;
                                            props.rowData.materialTypeID = allMaterial[e.target.value].materialTypeID;
                                            //props.onChange(e.target.value);
                                            props.onChange(allMaterial[e.target.value].materialName);
                                        }}
                                    >
                                        {allMaterial.map((material, index) =>
                                            <MenuItem key={material.id} value={index}>{material.materialName}</MenuItem>
                                        )}
                                    </Select>
                                }/*,render: rowData => {
                                let index = allMaterial.findIndex((val => val.id === rowData.materialID));
                                return <span>{index > -1 ? allMaterial[index].materialName : ""}</span>
                        },
                            lookup: {materialMap}*/
                            },
                            { 
                                title: 'Quantity', field: 'quantity', type: 'numeric',
                                render: rowData => {
                                    return <div class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth 
                                        MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl" style={{fontSize: 13 + 'px'}}>
                                        <input aria-invalid="false" class="MuiInputBase-input MuiInput-input" 
                                        placeholder="Quantity" type="number" aria-label="Quantity" 
                                   defaultValue = {rowData.quantity} 
                                    
                                    onChange = {
                                        (e)=>{
                                            if(e.target.value === ""){
                                                return
                                            }
                                            rowData.quantity = parseFloat(e.target.value);
                                            let energyIndex = materialNutritionMap[rowData.materialID];
                                            if (energyIndex !== undefined && energyIndex !== null) {

                                                setData([...data]);
                                            }
                                    }
                                }/>  
                                </div>
                                }               
                        }
                        ]}
                        data={data}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    let materialIndex = data.findIndex((val => val.materialID === newData.materialID))
                                    if(materialIndex> -1){
                                        alert(newData.materialName+" is already added in the list")
                                        reject()
                                    } else if(!validate(newData)){
                                        alert("Fill all the feilds")
                                        reject()
                                    }else{
                                        let energyIndex = materialNutritionMap[newData.materialID];
                                        if (energyIndex !== undefined && energyIndex !== null) {
                                            setData([...data, newData]);
                                                resolve();
                                        }else{
                                            alert("No Nutrition profile found for "+newData.materialName)
                                            reject();
                                        }
                                    }
                                }),
                              
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    
                                    const dataDelete = [...data];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    setData([...dataDelete]);

                                    resolve();
                                })
                        }}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            showTitle: true,
                            toolbar: true,
                            draggable:true,
                        }}
                    />
                </React.Fragment>
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
                :
                <div>
        <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={event => {
                        handleChange({ target: { name: 'date', value: event } })
                    }}
                    helperText={
                        hasError('date') ? formState.errors.date[0] : null
                    }
                    animateYearScrolling
                    name="date"
                    style={{ backgroundColor: "#ffffff" }}
                />
            </MuiPickersUtilsProvider>
            <FormControl className={classes.formControl}>
                <InputLabel id="farm-select-outlined-label">
                    Farm
                </InputLabel>
                <Select
                    id="farmIndex"
                    value={formState.values.farmIndex !== undefined ? formState.values.farmIndex : ''}
                    onChange={event => {
                        handleChange(event, onFarmChangeLocal)
                    }}
                    helperText={
                        hasError('farmIndex') ? formState.errors.farmIndex[0] : null
                    }
                    name="farmIndex"
                >

                    {farms !== undefined && farms !== null ?
                        farms.map((farm, index) => {
                            return <MenuItem key={farm.id} value={index}>{farm.name}</MenuItem>
                        }
                        ) : []}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="shed-select-outlined-label">
                    Shed
                </InputLabel>
                <Select
                    id="shedIndex"
                    value={formState.values.shedIndex !== undefined ? formState.values.shedIndex : ''}
                    onChange={handleChange}
                    name="shedIndex"
                    helperText={
                        hasError('shedIndex') ? formState.errors.shedIndex[0] : null
                    }
                >
                    {sheds !== undefined && sheds !== null ?
                        sheds.map((shed, index) =>
                            <MenuItem key={shed.id} value={index}>{shed.name}</MenuItem>
                        ) : []}
                </Select>
            </FormControl>
                    <Grid
                        className={classes.grid}
                        container
                        justify='space-between'
                        alignItems='flex-end'
                    >
                       
                       {Object.keys(energyValues).map((name, key) => {
                           let temp = (energyValues[name].value)
                           return (
                                        <Grid className={classes.grid} key={key} id={key} item xs={4} sm={2}>
                                            <Typography color="textSecondary">
                                                {energyValues[name].name}
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                {isNaN(temp) ? 0 : temp.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    )})}
                        <Grid className={classes.grid} item xs={4}>
                                <Typography color="textSecondary">
                                    Total Quantity
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    {totalQuantity}
                                </Typography>
                            </Grid>
                    </Grid>
                <React.Fragment>
                    <MaterialTable
                        isLoading={false}
                        title="Energy Calculation"
                        columns={[
                            {
                                title: 'Material Name', field: 'materialName',
                                editComponent: props => {
                                    let index = allMaterial.findIndex((val => val.id === props.rowData.materialID));
                                    if (index === -1) {
                                        index = '';
                                    }
                                    ;
                                    return <Select
                                        id="material-select"
                                        value={index}
                                        onChange={e => {
                                            props.rowData.materialID = allMaterial[e.target.value].id;
                                            props.rowData.materialTypeID = allMaterial[e.target.value].materialTypeID;
                                            //props.onChange(e.target.value);
                                            props.onChange(allMaterial[e.target.value].materialName);
                                        }}
                                    >
                                        {allMaterial.map((material, index) =>
                                            <MenuItem key={material.id} value={index}>{material.materialName}</MenuItem>
                                        )}
                                    </Select>
                                }/*,render: rowData => {
                                let index = allMaterial.findIndex((val => val.id === rowData.materialID));
                                return <span>{index > -1 ? allMaterial[index].materialName : ""}</span>
                        },
                            lookup: {materialMap}*/
                            },
                            { 
                                title: 'Quantity', field: 'quantity', type: 'numeric',
                                render: rowData => {
                                    return <div class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth 
                                        MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl" style={{fontSize: 13 + 'px'}}>
                                        <input aria-invalid="false" class="MuiInputBase-input MuiInput-input" 
                                        placeholder="Quantity" type="number" aria-label="Quantity" 
                                   defaultValue = {rowData.quantity} 
                                    
                                    onChange = {
                                        (e)=>{
                                            if(e.target.value === ""){
                                                return
                                            }
                                            rowData.quantity = parseFloat(e.target.value);
                                            let energyIndex = materialNutritionMap[rowData.materialID];
                                            if (energyIndex !== undefined && energyIndex !== null) {

                                                setData([...data]);

                                                // calculateEnergyData()
                                                //setTotalQuantity(totalQuantity + (rowData.quantity-oldValue))
                                                /*newData = {
                                                    ...newData,
                                                    ...results
                                                }
                                                const dataUpdate = [...data];
                                                const index = oldData.tableData.id;
                                                dataUpdate[index] = rowData;
                                                setData([...dataUpdate]);*/
                                            }
                                    }
                                }/>  
                                </div>
                                }               

                        }
                        ]}
                        data={data}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    let materialIndex = data.findIndex((val => val.materialID === newData.materialID))
                                    if(materialIndex> -1){
                                        alert(newData.materialName+" is already added in the list")
                                        reject()
                                    } else if(!validate(newData)){
                                        alert("Fill all the feilds")
                                        reject()
                                    }else{
                                        let energyIndex = materialNutritionMap[newData.materialID];
                                        if (energyIndex !== undefined && energyIndex !== null) {
                                            setData([...data, newData]);

                                            // calculateEnergyData()

                                                /* newData = {
                                                    ...newData,
                                                    ...results
                                                }
                                                console.debug("allMaterial", allMaterial)
                                                let newEnergy = {}
                                                Object.keys(energyValues).forEach(function (key) {
                                                    let tempEnergy = energyValues[key]
                                                    // console.debug(key,tempEnergy.value, parseFloat((results[key]).toFixed(2)) , parseFloat((newData.quantity).toFixed(2)))
                                                   // console.debug("multipled ", parseFloat(results[key] * newData.quantity).toFixed(2))
                                                   let multipledValue = parseFloat((results[key] * newData.quantity).toFixed(2))
                                                    tempEnergy.value = parseFloat((tempEnergy.value + multipledValue).toFixed(2))
                                                    console.debug("tempEnergy.value ",tempEnergy.value)
                                                    newEnergy = {
                                                        ...newEnergy,
                                                        [key]:{
                                                            ...tempEnergy
                                                        }
                                                    }
                                                });
                                                energyValues= ({...newEnergy})
                                                setTotalQuantity(totalQuantity + newData.quantity)*/
                                                resolve();
                                            /*} else {
                                                alert("No Nutrition profile found for "+newData.materialName)
                                                reject();
                                            }*/
                                        }else{
                                            alert("No Nutrition profile found for "+newData.materialName)
                                            reject();
                                        }
                                    }
                                }),
                                /*onBulkUpdate: changes =>
                                new Promise((resolve, reject) => {
                                    console.debug("changes", changes)
                                    resolve();
                                }),
                                onBulkEditRowChanged: changes =>
                                new Promise((resolve, reject) => {
                                    console.debug("changes", changes)
                                    resolve();
                                }),*/

                             /* onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    let materialIndex = data.findIndex((val => val.materialID === newData.materialID))
                                    if(newData.materialID !== oldData.materialID&& materialIndex> -1){
                                        alert(newData.materialName+" Already Exists.")
                                        reject()
                                    } else if(!validate(newData)){
                                        alert("Fill all the feilds")
                                        reject()
                                    }else{
                                        let energyIndex = energies.findIndex((val => val.materialID === newData.materialID));
                                        if(energyIndex > -1){
                                            let results =  energies[energyIndex]
                                            if (results) {
                                                let newEnergy = {}
                                                Object.keys(energyValues).forEach(function (key) {
                                                    let tempEnergy = energyValues[key]
                                                    let oldMultipledValue = parseFloat((oldData[key] * oldData.quantity).toFixed(2))
                                                    let newMultipledValue = parseFloat((results[key] * newData.quantity).toFixed(2))
                                                    console.debug(key,tempEnergy.value, oldMultipledValue , newMultipledValue)
                                                    tempEnergy.value = parseFloat((tempEnergy.value +  (newMultipledValue - oldMultipledValue)).toFixed(2))
                                                    console.debug("tempEnergy.value ",tempEnergy.value)
                                                    newEnergy = {
                                                        ...newEnergy,
                                                        [key]:{
                                                            ...tempEnergy
                                                        }
                                                    }
                                                });
                                                energyValues= ({...newEnergy})
                                                setTotalQuantity(totalQuantity + (newData.quantity-oldData.quantity))
                                                newData = {
                                                    ...newData,
                                                    ...results
                                                }
                                                const dataUpdate = [...data];
                                                const index = oldData.tableData.id;
                                                dataUpdate[index] = newData;
                                                setData([...dataUpdate]);
                                                resolve();
                                            } else {
                                                alert("No Nutrition profile found for "+newData.materialName)
                                                reject();
                                            }
                                        }else{
                                            alert("No Nutrition profile found for "+newData.materialName)
                                            reject();
                                        }
                                    }
                                }),*/
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    /*let newEnergy = {}
                                    Object.keys(energyValues).forEach(function (key) {
                                        let tempEnergy = energyValues[key]
                                        console.debug(key,tempEnergy.value, parseFloat((oldData[key]).toFixed(2)) , parseFloat((oldData.quantity).toFixed(2)))
                                        let multipledVAlue = parseFloat((oldData[key] * oldData.quantity).toFixed(2))
                                        tempEnergy.value = parseFloat((tempEnergy.value - multipledVAlue).toFixed(2))
                                        console.debug("tempEnergy.value ",tempEnergy.value)
                                        newEnergy = {
                                            ...newEnergy,
                                            [key]:{
                                                ...tempEnergy
                                            }
                                        }
                                    });
                                    energyValues= ({...newEnergy})
                                    setTotalQuantity(totalQuantity - oldData.quantity)*/
                                    const dataDelete = [...data];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    setData([...dataDelete]);

                                    resolve();
                                })
                        }}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            showTitle: true,
                            toolbar: true,
                            draggable:true,
                        }}
                    />
                </React.Fragment>
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>

          }
        </DialogContent>
    </Dialog>
};

export default CalculateFeedFormulation;