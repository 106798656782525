import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import {useGlobalState} from '../../../state';



const useStyles = makeStyles(theme => ({
   
    loaderGrid: {
        verticalAlign: 'middle',
        borderRight: '1px solid #bfbfbf',
        padding: theme.spacing(2),
        textAlign: 'center',
        display: 'table-cell'
    },
    netProfitGrid: props => ({
        borderRight: props.isDesktop ? '1px solid #bfbfbf' : null,
        borderBottom: props.isDesktop ? null : '1px solid #bfbfbf',
        padding: theme.spacing(2),
        textAlign: 'center',
        display: props.isDesktop ? 'table-cell' : null
    }),
    netProfitLastGrid: props => ({
        padding: theme.spacing(2),
        textAlign: 'center',
        display: props.isDesktop ? 'table-cell' : null
    }),
    card: {
        minHeight: '100%',
        minWidth: '94%',
        marginTop: theme.spacing(2),
        display: 'table',
        width: '98.5%',
        tableLayout: 'fixed'
    }
}));



const Summary = ({ netProfitLoading, netProfitData }) => {
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }



    return <>
        {netProfitLoading ?
            <Card className={classes.card}>
                <Grid className={classes.loaderGrid} item xs={12}>
                    <CircularProgress className={classes.progressBar} />
                </Grid>
            </Card>
            :
            <Card className={classes.card}>
                <Grid className={classes.netProfitGrid} item xs={isDesktop ? 4 : 12}>
                    <Typography color="textSecondary">
                        Total Receipts
                                </Typography>
                    <Typography variant="h3" component="h2">
                        {netProfitData.totalIncome.toLocaleString(user.locales,amountObj)}
                    </Typography>
                </Grid>
                <Grid className={classes.netProfitGrid} item xs={isDesktop ? 4 : 12}>
                    <Typography color="textSecondary">
                        Total Expense
                                </Typography>
                    <Typography variant="h3" component="h2">
                        {netProfitData.totalExpense.toLocaleString(user.locales,amountObj)}
                    </Typography>
                </Grid>
                <Grid className={classes.netProfitLastGrid} item xs={isDesktop ? 4 : 12}>
                    <Typography color="textSecondary">
                    Surplus/Deficit
                                </Typography>
                    <Typography variant="h3" component="h2">
                        {netProfitData.netProfit.toLocaleString(user.locales,amountObj)}
                    </Typography>
                </Grid>
            </Card>
        }
    </ >
};


export default Summary;