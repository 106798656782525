import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import { MaterialTable } from '../../../components';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableBody';
import {useGlobalState} from '../../../state';

const useStyles = makeStyles(theme => ({
    grid: {
        marginBottom: theme.spacing(4),
        marginRight: theme.spacing(3)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.primary.default,
    },
    totalTable: {
        float: 'right',
        width: theme.spacing(28.6)
    },
    totalCell: {
        width: theme.spacing(14.5),
        paddingLeft: theme.spacing(0)
    }
}));

const LinePurchaseDetails = ({ data, handlePurchaseDetailsDialogClose, showPurchaseDetailsDialog }) => {
    const classes = useStyles();
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
    /* const [billValueSum, setBillValueSum] = useState(0.0)
 
     function calculateBillTotal() {
         let total = 0.0
         let billValue = 0.0
         if(data !== undefined && data !== null && data.purchaseMaterials !== undefined && data.purchaseMaterials !== null){
             data.purchaseMaterials.forEach((purchaseMaterial,index)=>{
                 if (purchaseMaterial.weightType === 1){
                     billValue = (purchaseMaterial.billWeight*purchaseMaterial.costPerUnit)+(purchaseMaterial.billWeight*purchaseMaterial.costPerUnit*(data.tax/100))
                 }else{
                     billValue = (purchaseMaterial.deliveryWeight*purchaseMaterial.costPerUnit)+(purchaseMaterial.deliveryWeight*purchaseMaterial.costPerUnit*(data.tax/100))
                 }
 
                 total = total + billValue
             })
         }
         setBillValueSum(total)
     }
 
     useEffect(calculateBillTotal, [data]);
 */

     const calculateTotalValue =()=>{
        let total = 0.0
        if(data.linePurchaseMaterials !== undefined){
            data.linePurchaseMaterials.forEach((linePurchaseMaterial, index) => {
                if (linePurchaseMaterial.billValue > 0.0) {
                    total = total + linePurchaseMaterial.billValue
                }
            })
        }
        return (total)
    }

    return data ? <Dialog maxWidth='md' open={showPurchaseDetailsDialog} onClose={handlePurchaseDetailsDialogClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog- title"   >Purchase Details
    {handlePurchaseDetailsDialogClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={handlePurchaseDetailsDialogClose}>
                <CloseIcon />
            </IconButton>
        ) : null}
    </DialogTitle>
    <DialogContent dividers  >
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
        >
            <Grid item className={classes.grid}>
                <Typography variant="h5" component="h2">
                    {moment(data.date).format("DD-MM-YYYY")}
                </Typography>
                <Typography color="textSecondary">
                    Date
                    </Typography>
            </Grid>
            <Grid item className={classes.grid}>
                <Typography variant="h5" component="h2">
                    {data.vendorName}
                </Typography>
                <Typography color="textSecondary">
                    Vendor Name
                    </Typography>
            </Grid>
            <Grid item className={classes.grid}>
                <Typography variant="h5" component="h2">
                    {data.lineName}
                </Typography>
                <Typography color="textSecondary">
                    Line Name
                    </Typography>
            </Grid>
            <Grid item className={classes.grid}>
                <Typography variant="h5" component="h2">
                    {data.truckNumber}
                </Typography>
                <Typography color="textSecondary">
                    Truck Number
                    </Typography>
            </Grid>
        </Grid>
        <Grid>
            <MaterialTable
                columns={[
                    { title: 'id', field: 'id', hidden: true },
                    { title: 'Item Name', field: 'materialName', editable: 'never' },
                    { title: 'Number of Trays', field: 'quantity', editable: 'never' },
                    { title: 'Cost Per Unit', field: 'costPerUnit', editable: 'never' },
                    { title: 'Bill Value', field: 'billValue',  editable: 'never', 
                   cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                   render: rowData => {
                    return <span>{(rowData.billValue).toLocaleString(user.locales,amountObj)}</span>
                   }},
                ]}
                data={data.linePurchaseMaterials || []}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,                       
                    toolbar: false,
                    paging: false
                }}
            />
            <Table className={classes.totalTable} >
                <TableHead  >
                <TableRow>
                    <TableCell className={classes.totalCell}  >Total Bill Value </TableCell>
                    <TableCell className={classes.totalCell}>{(calculateTotalValue()).toLocaleString(user.locales,amountObj) }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.totalCell}  >TCS</TableCell>
                    <TableCell>{(data.totalBillValue * (data.tds / 100)).toLocaleString(user.locales,amountObj)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.totalCell}  >Total With TCS</TableCell>
                    <TableCell>{(data.totalBillValue + (data.totalBillValue * (data.tds / 100))).toLocaleString(user.locales,amountObj)}</TableCell>
                </TableRow>
                </TableHead  >
            </Table>
        </Grid>
    </DialogContent>
</Dialog>
    : null
};

export default LinePurchaseDetails;
