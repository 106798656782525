import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import moment from 'moment';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useCardStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    cardActionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    cardAction:{
        margin:theme.spacing(0),
        // marginTop:theme.spacing(2),
        backgroundColor : '#ff1744',
        color : 'white'
    },
    red:{
        color:'#f44336'
    },
    green:{
        color:'#4caf50'
    }
}));

const Mobile = ({data,onDelete,onEdit,openFeedFormulationMaterial,openFeedFormulationMedicine, onCopy, farmShedHash}) => {
    const classes = useCardStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        onDelete();
    };

    /*const handleEdit = () => {
        setAnchorEl(null);
        onEdit();
    };*/

    const handleCopy = event => {
        setAnchorEl(null);
        onCopy(event);
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    return <Grid item>
        <Card className={classes.card}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                }
                title={`Date : ${moment(data.date).format("DD-MM-YYYY")}`}
            />
            <CardContent>
                <Grid
                    container
                    justify='space-between'
                    alignItems='flex-end'
                >
                    <Grid item>
                        <Typography variant="h5" component="h2">
                            {data.farmName}
                        </Typography>
                        <Typography color="textSecondary">
                            Farm Name
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" component="h2">
                            {data.shedName}
                        </Typography>
                        <Typography color="textSecondary">
                            Shed Name
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" component="h2">
                            {moment(data.date) >= farmShedHash[data.farmID+"_"+data.shedID] ? 
                        <FiberManualRecordIcon  className={classes.green}/> 
                        : 
                        <FiberManualRecordIcon className={classes.red}/>
                        }
                        </Typography>
                        <Typography color="textSecondary">
                            Active
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions className={classes.cardActionsContainer}>
                <Button onClick={openFeedFormulationMaterial} size="large" color="primary" variant="contained">Materials</Button>
                <Button className={classes.cardAction} onClick={openFeedFormulationMedicine} size="large"  variant="contained">Medicines</Button>
            </CardActions>
        </Card>
        <Menu
            id="breeds-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {/*<MenuItem onClick={handleEdit}>Edit</MenuItem>*/}
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
            <MenuItem onClick={handleCopy}>Copy</MenuItem>
        </Menu>
    </Grid>
};

export default Mobile;