import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from "../../../common/formValidator";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

const schema = {
  tier: {
    presence: { allowEmpty: false, message: "is required" },
    length:{
      maximum:100,
      tooLong: "name should not exceed 100 characters",
    }
  },
  planAmount: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThan: 	2147483647,
    },
  },
  usd: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThan: 	2147483647,
    },
  },
  birdCountMin: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThan: 	2147483647,
    },
  },
  birdCountMax: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThan: 	2147483647,
    },
  },
  planType: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  version_id: {
    presence: { allowEmpty: false, message: 'is required' }
  },
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.text.default,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  progressContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
  },
  progressBar: {
      margin: theme.spacing(2),
      position: 'absolute',
      top: '40%',
      zIndex: '9999'
  },
}));

const PlansCRUD = ({
  deleteErrorMessage, errorMessage,
  planTypes,
  onEdit,
  data,
  onSave,
  handleClose,
  showError,
  showDialog,
  versions,
  editMode = false,
}) => {
  const classes = useStyles();
  const [popupLoading, setPopupLoading] = useState(false);
  const [
    formState,
    hasError,
    handleChange,
    updateData,
  ] = useFromValidator(schema);


  const resetBeforeEdit = () => {
    if (showDialog) {
      setPopupLoading(false);
      if (editMode) {
          data = {
            ...data,
          };
          updateData(data, editMode);
          console.log("data", data);
        }
      } else {
        updateData(data, editMode);
      }
  };
  useEffect(resetBeforeEdit, [showDialog]);

  const onSubmit = () => {
    setPopupLoading(true)
    var versionName;
    versions.forEach((v)=>{
      if(v.versionId === formState.values.version_id){
        versionName = v.versionName;
      }
    });

    let tempData = {
      ...formState.values,
      version_name:(versionName !== undefined) ? versionName : ""
    };
    console.debug("tempdata", tempData);
    if (editMode) {
      onEdit(tempData, setPopupLoading);
      console.log("tempdata", tempData);
    } else onSave(tempData, setPopupLoading);
  };

  return (
    <Dialog
      disableBackdropClick
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog- title">
        {editMode ? "Update" : "New"} Plan
      </DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
      {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>
                <TextField disabled
          className={classes.textField}
          error={hasError("tier")}
          fullWidth
          helperText={
            hasError("tier")  ? formState.errors.tier[0]  : null
          }
          label="Plan Name"
          name="tier"
          onChange={handleChange}
          type="text"
          value={formState.values.tier !== undefined ? formState.values.tier : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField disabled
          className={classes.textField}
          error={hasError("planAmount")}
          fullWidth
          helperText={hasError("planAmount") ? formState.errors.planAmount[0] : null}
          label="Plan Amount"
          name="planAmount"
          onChange={handleChange}
          type="number"
          value={formState.values.planAmount !== undefined ? formState.values.planAmount : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
         <TextField disabled
          className={classes.textField}
          error={hasError("usd")}
          fullWidth
          helperText={hasError("usd") ? formState.errors.usd[0] : null}
          label="Plan Amount in USD"
          name="usd"
          onChange={handleChange}
          type="number"
          value={formState.values.usd !== undefined ? formState.values.usd : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField disabled
          className={classes.textField}
          error={hasError("birdCountMin")}
          fullWidth
          helperText={hasError("birdCountMin") ? formState.errors.birdCountMin[0] : null}
          label="Minimum of Birds"
          name="birdCountMin"
          onChange={handleChange}
          type="number"
          value={formState.values.birdCountMin !== undefined ? formState.values.birdCountMin : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField disabled
          className={classes.textField}
          error={hasError("birdCountMax")}
          fullWidth
          helperText={hasError("birdCountMax") ? formState.errors.birdCountMax[0] : null}
          label="Maximum of Birds"
          name="birdCountMax"
          onChange={handleChange}
          type="number"
          value={formState.values.birdCountMax !== undefined ? formState.values.birdCountMax : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <FormControl disabled  className={classes.formControl}>
                <InputLabel id="planType-select-outlined-label">
                Plan Type
                </InputLabel>
                <Select
                id="planType"
                value={formState.values.planType || ''}
                onChange={event =>{handleChange(event)}}
                name="planType"
                >
                    <MenuItem key = {1} value={1}>Monthly</MenuItem>
                    <MenuItem key = {2} value={2}>Annual</MenuItem>
                </Select>
            </FormControl>
        <DialogActions>
          <Button
            className={classes.signInButton}
            color="primary"
            disabled
            size="large"
            type="submit"
            variant="contained"
            onClick={onSubmit}
          >
            Save
          </Button>
        </DialogActions>
        </div>
        :
        <div>

      <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
        <TextField
          className={classes.textField}
          error={hasError("tier")}
          fullWidth
          helperText={
            hasError("tier")  ? formState.errors.tier[0]  : null
          }
          label="Plan Name"
          name="tier"
          onChange={handleChange}
          type="text"
          value={formState.values.tier !== undefined ? formState.values.tier : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField
          className={classes.textField}
          error={hasError("planAmount")}
          fullWidth
          helperText={hasError("planAmount") ? formState.errors.planAmount[0] : null}
          label="Plan Amount in INR"
          name="planAmount"
          onChange={handleChange}
          type="number"
          value={formState.values.planAmount !== undefined ? formState.values.planAmount : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField
          className={classes.textField}
          error={hasError("usd")}
          fullWidth
          helperText={hasError("usd") ? formState.errors.usd[0] : null}
          label="Plan Amount in USD"
          name="usd"
          onChange={handleChange}
          type="number"
          value={formState.values.usd !== undefined ? formState.values.usd : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField
          className={classes.textField}
          error={hasError("birdCountMin")}
          fullWidth
          helperText={hasError("birdCountMin") ? formState.errors.birdCountMin[0] : null}
          label="Minimum of Birds"
          name="birdCountMin"
          onChange={handleChange}
          type="number"
          value={formState.values.birdCountMin !== undefined ? formState.values.birdCountMin : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField
          className={classes.textField}
          error={hasError("birdCountMax")}
          fullWidth
          helperText={hasError("birdCountMax") ? formState.errors.birdCountMax[0] : null}
          label="Maximum of Birds"
          name="birdCountMax"
          onChange={handleChange}
          type="number"
          value={formState.values.birdCountMax !== undefined ? formState.values.birdCountMax : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <FormControl  className={classes.formControl}>
                <InputLabel id="planType-select-outlined-label">
                Plan Type
                </InputLabel>
                <Select
                id="planType"
                value={formState.values.planType || ''}
                onChange={event =>{handleChange(event)}}
                name="planType"
                >
                    <MenuItem key = {1} value={1}>Monthly</MenuItem>
                    <MenuItem key = {2} value={2}>Annual</MenuItem>
                </Select>
            </FormControl>
        <FormControl  className={classes.formControl}>
                <InputLabel id="planType-select-outlined-label">
                   Version
                </InputLabel>
                <Select
                id="version_id"
                value={formState.values.version_id || ''}
                onChange={event =>{handleChange(event)}}
                name="version_id"
                >
                    {
                      versions.map((v)=>{
                        return(
                          <MenuItem key = {v.versionId+'-id'} value={v.versionId}>{v.versionName}</MenuItem>
                        );
                      })
                    }
                </Select>
        </FormControl>
        <DialogActions>
          <Button
            className={classes.signInButton}
            color="primary"
            disabled={!formState.isValid}
            size="large"
            type="submit"
            variant="contained"
            onClick={onSubmit}
          >
            Save
          </Button>
        </DialogActions>
        </div>}
      </DialogContent>
    </Dialog>
  );
};
export default PlansCRUD;
