import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog,MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VendorBulkPayment from "./components/vendorBulkPayment";
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import {useGlobalState} from '../../state';



const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentPayment = undefined;
let editMode = false;

const VendorBulkPayments = ({showError, showMessage, history}) => {
    const [{user}] = useGlobalState();

    const [payments, setPayments] = useState([]);
    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(false);
        const [showPaymentDialog, setShowPaymentDialog] = useState(false)
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    //const [accountTypes, setAccountTypes]= useState([]);
    const [vendors, setVendors] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')
   // const [vendorUnpaid, setVendorUnpaid] = useState([]);


    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    function onNewBank(event) {
        editMode = false;
        console.log(editMode)
        event.preventDefault();
        setShowPaymentDialog(true);
    }

    /*function handleBankClose() {
        setShowPaymentDialog(false);
    }*/

    function handleDeleteConfirmClose() {
        setErrorMessage(null);
        setShowDeleteConfirm(false);
    }




    const onDelete = (payment) => {
        currentPayment = payment;
        setShowDeleteConfirm(true);
    };

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onPaymentSave = (payment, errorElement) => {
        //setLoading(true);
        //showError('');
        showMessage('');  
        const response = api.post('vendorBulkPayment', {
            ...payment,
        });

        response.then(res => {
            setPayments([...payments, res]);
            setShowPaymentDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('Bank not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
            // if (errorElement) {
            //     errorElement.scrollIntoView();
            // }
        }).finally(() => {
            setLoading(false);
        });
    };


    const onPaymentEdit = (payment, errorElement) => {
        //setLoading(true);
        //showError('');
       
        if (payment.singlePaymentID) {
            const legacyPayment = payment.deletedPurchasesForPaymentsMade.filter(invoice => invoice.isLegacy);
            payment.purchasesForPaymentsMade = [...payment.purchasesForPaymentsMade, ...legacyPayment];
            payment.deletedPurchasesForPaymentsMade = []
        }
    
        payment.amount= parseInt(payment.amount)
        const response = api.post(`vendorBulkPayment/${payment.id}`, {
            ...payment,
    
        });
        response.then((res) => {
            let index = payments.findIndex((srcPayment) => {
                return srcPayment.id === payment.id && srcPayment.singlePaymentID === payment.singlePaymentID;
            });
            setPayments([...payments.slice(0, index),
                {...res},
                ...payments.slice(index + 1)]);
                setShowPaymentDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('VendorBulkPayment not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
            if (errorElement) {
                errorElement.scrollIntoView();
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onPaymentDeleteConfirm = () => {
        if (currentPayment && currentPayment.id) {
            setShowDeleteConfirm(false);
           // setLoading(true);
            showError('');
         
            const response = api.post(`deleteVendorBulkPayment/${currentPayment.id}`,{
                ...currentPayment
            });
    
            response.then(res => {
                if (res === 1) {
                    let index = payments.findIndex((srcPayment) => {                     
                        return srcPayment.id === currentPayment.id && srcPayment.singlePaymentID === currentPayment.singlePaymentID;
                    });
                    let newPayments = [...payments];
                    newPayments.splice(index, 1);
                    setPayments(newPayments);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('VendorBulkPayment not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentPayment = undefined;
            });
    
        }
    };

    function handlePaymentClose() {
        //currentPayment = undefined;
        setErrorMessage(null);
        setShowPaymentDialog(false);
    }
    const makePaymentInvoiceSearchable =(paymentsData)=>{
        paymentsData.forEach((payment) => {
            payment.invoiceStr = payment?.purchasesForPaymentsMade
            ?.map((invoice) => (invoice?.invoice?.billNumber === "0" ?  invoice?.invoice?.id : invoice?.invoice?.billNumber ))
            .join(", ")
            .toString();
        });
        return paymentsData;
     }
    /*const getAllVendorUnpaid = (vendorID) => {
            setShowPaymentDialog(false);
            showError('');
            showMessage('');
    
            const response = api.post(`pending-payment/${vendorID}`)   
            response.then(res => {
              
                setVendorUnpaid([...res]);
                console.log(vendorUnpaid)
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('No Bank Found');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            })
            return response;
        }*/

    //Get all Banks API Call
    const getAllBanks = () => {
        setShowPaymentDialog(false);
        showError('');
        showMessage('');

        const response = api.get('bank');

        response.then(res => {
          
            setBanks([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

     //Get all vendors API Call
     const getAllVendors = () => {
        showError('');
        showMessage('');

        const response = api.get('vendors-list');

        response.then(res => {
            setVendors([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log('No Vendors Found');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
    }

    const getAllPayments = () => {        
        showError('');
        showMessage('');

        const response = api.get('getAllBulkPaymentsForAllVendors');
        response.then(res => {
            setPayments([...res]);
            
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Payments Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }


    const onload=()=>{
        setLoading(true);
        Promise.all([getAllBanks(),getAllVendors(),getAllPayments()]).finally(()=>{
            setLoading(false);
        });
    }
    useEffect(onload, []);
    
    const editPayment=(data)=>{
        editMode = true
        currentPayment = data
        setShowPaymentDialog(true);
    }


    let actions = [
            rowData => ({
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Vendor Payment',
                onClick: () => editPayment(rowData),
                hidden: (user.role === "STAFF" && !moment(rowData.createDate).isSame(moment(), "date") ? true : false)
            }),
            rowData => ({
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Vendor Payment',
                onClick: (event, rowData) => onDelete(rowData),
                hidden: (user.role === "STAFF" && !moment(rowData.createDate).isSame(moment(), "date") ? true : false)
            })
        ];


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
    
        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Vendor Payment
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewBank}>Add  Payment</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'Date', field: 'paymentDate', editable: 'never', defaultSort: 'desc',
                render: rowData => {
                    return <span>{moment(rowData.paymentDate).format("DD-MM-YYYY")}</span>
                }},

                {title: 'Name', field: 'vendorName', editable: 'never'},       
                {title: 'Amount', field: 'paymentAmount', editable: 'never', render:rd=>rd.paymentAmount.toFixed(2)},
                {title: 'Invoice', field: "invoiceStr", editable: 'never',hidden:false},

            ]}
            data={payments.length >0 ?makePaymentInvoiceSearchable(payments):[]}
            title='Vendor Payment Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true,
                pageSize:20
            }}
            actions={actions}
        />
       { /**data={currentPayment}     showPaymentDialog={showPaymentDialog} editMode={editPaymentMode}*/}
        <VendorBulkPayment deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} banks={banks}  onSave={onPaymentSave} handlePaymentClose={handlePaymentClose} 
        showPaymentDialog={showPaymentDialog} vendors={vendors} onEdit={onPaymentEdit}
         editMode={editMode}   data={currentPayment}>
                </VendorBulkPayment> 
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onPaymentDeleteConfirm}
                       title='Delete Vendor Payment ?'
                       subText='This action will delete the Vendor Payment Details. Please confirm.'/>
    </div>
}

export default VendorBulkPayments;