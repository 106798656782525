import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from "../../../common/formValidator";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from '@material-ui/core/Chip';
import { MaterialTable } from "../../../components";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';

const schema = {
  adjustmentDate: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.text.default,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  progressContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
  },
  progressBar: {
      margin: theme.spacing(2),
      position: 'absolute',
      top: '40%',
      zIndex: '9999'
  },
}));

const StockAdjustments = ({
  deleteErrorMessage, errorMessage,
  onEdit,
  materialTypes,
  materialMaster,
  data,
  onSave,
  handleClose,
  showDialog,
  editMode = false,
}) => {
  const classes = useStyles();
  const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
  const [stockAdjustments, setStockAdjustments] = useState([]);
  const [popupLoading, setPopupLoading] = useState(false);
  const [deletedStockAdjustments, setDeletedStockAdjustments] = useState([]);

  //Called if the Profile is Set for Editing
  const resetBeforeEdit = () => {
    setStockAdjustments([])
    setDeletedStockAdjustments([])
    if (showDialog) {
      setPopupLoading(false);
      if (editMode) {
        setStockAdjustments([...data.stockAdjustments])
      }
      updateData(data, editMode);

    }
  };
  useEffect(resetBeforeEdit, [showDialog]);

  const onSubmit = () => {
    setPopupLoading(true)
    let tempData = {
      ...formState.values,
      deletedStockAdjustments: deletedStockAdjustments,
      stockAdjustments: stockAdjustments
    };
    console.debug("tempdata", tempData);
    if (editMode) {
      onEdit(tempData, setPopupLoading);
      console.log("tempdata", tempData);
    } else onSave(tempData, setPopupLoading);
  };

  return (
    <Dialog maxWidth='lg' disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog- title">{editMode ? "Update" : "New"} Stock Adjustment</DialogTitle>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
      {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker disabled
            className={classes.textField}
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="Date"
            format="dd/MM/yyyy"
            value={formState.values.adjustmentDate || null}
            onChange={data => {
              handleChange({ target: { name: 'adjustmentDate', value: data } })
            }}
            helperText={
                hasError('adjustmentDate') ? formState.errors.adjustmentDate[0] : null
            }
            animateYearScrolling
            name="adjustmentDate"
            style={{ backgroundColor: "#ffffff" }}
          />
        </MuiPickersUtilsProvider>
        <MaterialTable 
          isLoading={false}
          title="Materials"
          columns={[
            {
              title: 'Material Type', field: 'materialTypeName',
              editComponent: props => {
                let index = materialTypes.findIndex((val => val.id === props.rowData.materialTypeID));
                if (index === -1) {
                  index = '';
                }
                return <Select id="materialType-select"
                  value={index}
                  onChange={e => {
                    props.rowData.materialTypeID = materialTypes[e.target.value].id;
                    props.rowData.materialTypeName = materialTypes[e.target.value].materialType;
                    props.rowData.materialID = -1;
                    props.rowData.materialName = "";
                    props.onChange(materialTypes[e.target.value].materialType);
                  }}>
                  {materialTypes.map((materialType, index) =>
                    <MenuItem key={materialType.id} value={index}>{materialType.materialType}</MenuItem>
                  )}
                </Select>
              }
            },
            {
              title: 'Material Name', field: 'materialName',
              editComponent: props => {
                let index = materialMaster.findIndex((val => val.id === props.rowData.materialID));
                if (index === -1) {
                  index = '';
                }
                return <Select id="material-name-select"
                  value={index}
                  onChange={e => {
                    props.rowData.materialID = materialMaster[e.target.value].id;
                    props.rowData.materialName = materialMaster[e.target.value].materialName;
                    props.onChange(materialMaster[e.target.value].materialName);
                  }}
                >
                  {materialMaster.map((material, index) =>
                    props.rowData.materialTypeID !== undefined && material.materialTypeID === props.rowData.materialTypeID ?
                      <MenuItem key={material.id} value={index}>{material.materialName}</MenuItem>
                      : ""
                  )}
                </Select>
              }
            },
            { title: 'Quantity', field: 'quantity', type: 'numeric' }
          ]}
          style={{opacity: '0.6'}}
          data={stockAdjustments}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                setStockAdjustments([
                  ...stockAdjustments,
                  {
                    materialID: newData.materialID,
                    materialName: newData.materialName,
                    materialTypeID: newData.materialTypeID,
                    materialTypeName: newData.materialTypeName,
                    quantity: newData.quantity
                  }
                ])
                resolve();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                console.debug(oldData)
                let index = stockAdjustments.indexOf(oldData)
                setStockAdjustments([
                  ...stockAdjustments.slice(0, index),
                  {
                    materialID: newData.materialID,
                    materialName: newData.materialName,
                    materialTypeID: newData.materialTypeID,
                    materialTypeName: newData.materialTypeName,
                    quantity: newData.quantity,
                    oldMaterialID: oldData.oldMaterialID,
                  },
                  ...stockAdjustments.slice(index + 1),
                ]);
                resolve();
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                let index = stockAdjustments.indexOf(oldData)
                let newstockAdjustments = stockAdjustments
                newstockAdjustments.splice(index, 1)
                setStockAdjustments([
                  ...newstockAdjustments
                ])
                setDeletedStockAdjustments([
                  ...deletedStockAdjustments,
                  { ...oldData }
                ])
                resolve();
              })
          }}
          options={{
            actionsColumnIndex: -1,
            search: true,
            showTitle: true,
            toolbar: true,
            draggable: true,
          }}
        />
        <DialogActions>
          <Button
            className={classes.signInButton}
            color="primary"
            disabled
            size="large"
            type="submit"
            variant="contained"
            onClick={onSubmit}
          >
            Save
          </Button>
        </DialogActions>
        </div>
        :
        <div>
        <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage} /> : ''}
        </div>
        <br />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            className={classes.textField}
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="Date"
            format="dd/MM/yyyy"
            value={formState.values.adjustmentDate || null}
            onChange={data => {
              handleChange({ target: { name: 'adjustmentDate', value: data } })
            }}
            helperText={
                hasError('adjustmentDate') ? formState.errors.adjustmentDate[0] : null
            }
            animateYearScrolling
            name="adjustmentDate"
            style={{ backgroundColor: "#ffffff" }}
          />
        </MuiPickersUtilsProvider>
        <MaterialTable
          isLoading={false}
          title="Materials"
          columns={[
            {
              title: 'Material Type', field: 'materialTypeName',
              editComponent: props => {
                let index = materialTypes.findIndex((val => val.id === props.rowData.materialTypeID));
                if (index === -1) {
                  index = '';
                }
                return <Select id="materialType-select"
                  value={index}
                  onChange={e => {
                    props.rowData.materialTypeID = materialTypes[e.target.value].id;
                    props.rowData.materialTypeName = materialTypes[e.target.value].materialType;
                    props.rowData.materialID = -1;
                    props.rowData.materialName = "";
                    props.onChange(materialTypes[e.target.value].materialType);
                  }}>
                  {materialTypes.map((materialType, index) =>
                    <MenuItem key={materialType.id} value={index}>{materialType.materialType}</MenuItem>
                  )}
                </Select>
              }
            },
            {
              title: 'Material Name', field: 'materialName',
              editComponent: props => {
                let index = materialMaster.findIndex((val => val.id === props.rowData.materialID));
                if (index === -1) {
                  index = '';
                }
                return <Select id="material-name-select"
                  value={index}
                  onChange={e => {
                    props.rowData.materialID = materialMaster[e.target.value].id;
                    props.rowData.materialName = materialMaster[e.target.value].materialName;
                    props.onChange(materialMaster[e.target.value].materialName);
                  }}
                >
                  {materialMaster.map((material, index) =>
                    props.rowData.materialTypeID !== undefined && material.materialTypeID === props.rowData.materialTypeID ?
                      <MenuItem key={material.id} value={index}>{material.materialName}</MenuItem>
                      : ""
                  )}
                </Select>
              }
            },
            { title: 'Quantity', field: 'quantity', type: 'numeric' }
          ]}
          data={stockAdjustments}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                setStockAdjustments([
                  ...stockAdjustments,
                  {
                    materialID: newData.materialID,
                    materialName: newData.materialName,
                    materialTypeID: newData.materialTypeID,
                    materialTypeName: newData.materialTypeName,
                    quantity: newData.quantity
                  }
                ])
                resolve();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                console.debug(oldData)
                let index = stockAdjustments.indexOf(oldData)
                setStockAdjustments([
                  ...stockAdjustments.slice(0, index),
                  {
                    materialID: newData.materialID,
                    materialName: newData.materialName,
                    materialTypeID: newData.materialTypeID,
                    materialTypeName: newData.materialTypeName,
                    quantity: newData.quantity,
                    oldMaterialID: oldData.oldMaterialID,
                  },
                  ...stockAdjustments.slice(index + 1),
                ]);
                resolve();
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                let index = stockAdjustments.indexOf(oldData)
                let newstockAdjustments = stockAdjustments
                newstockAdjustments.splice(index, 1)
                setStockAdjustments([
                  ...newstockAdjustments
                ])
                setDeletedStockAdjustments([
                  ...deletedStockAdjustments,
                  { ...oldData }
                ])
                resolve();
              })
          }}
          options={{
            actionsColumnIndex: -1,
            search: true,
            showTitle: true,
            toolbar: true,
            draggable: true,
          }}
        />
        <DialogActions>
          <Button
            className={classes.signInButton}
            color="primary"
            disabled={!formState.isValid}
            size="large"
            type="submit"
            variant="contained"
            onClick={onSubmit}
          >
            Save
          </Button>
        </DialogActions>
        </div>
      }
      </DialogContent>
    </Dialog>
  );
};

export default StockAdjustments;
