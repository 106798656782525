import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/styles/makeStyles';
import useFromValidator from '../../../common/formValidator';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ConfirmDialog } from '../../../components';


let schema = {
    movementDate: {
        presence: { allowEmpty: false, message: 'is required' }
    },
};

const useStyles = makeStyles(theme => ({
    dialogBox:{
        minWidth: '120px'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.secondary,
    }, 
      MuiCircularProgress: {
        left: '43%',
        position: 'absolute',
        top: '43%',
        zIndex: '100',
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    }
}));

const Batch = ({ deleteErrorMessage, errorMessage, loading, onEdit, data,  handleClose, showDialog, editMode = false }) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const resetBeforeEdit = () => {
        if(data !== undefined && data.movementDate !== undefined){
            data.movementDate = ""
        }
        console.debug(hasError)
        if (showDialog) {
            updateData(data, editMode);
        }
    };

    useEffect(resetBeforeEdit, [showDialog]);

    const handleDateSubmit = () => {
        setShowConfirmDialog(true);
    };

    const handleDeactivateConfirm = () => {
        onEdit(formState.values);
        setShowConfirmDialog(false); 
    };

    return <>
        <Dialog maxWidth='xs' fullWidth disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" >Batch Deactivation Date
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
            {loading ? <CircularProgress className={classes.MuiCircularProgress} /> : '' } 
            <div id="error">
            {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
            </div> 
            <br/>  
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        fullWidth
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Deactivation Date"
                        format="dd/MM/yyyy"
                        value={formState.values.movementDate || null}
                        onChange={data => {
                            handleChange({ target: { name: 'movementDate', value: data } })
                        }}
                        animateYearScrolling
                        name="movementDate"
                        style={{backgroundColor:"#ffffff"}}
                    />
                </MuiPickersUtilsProvider>
                <DialogActions>
                    <Button
                        className={classes.signInButton}
                        color="primary"
                        disabled={!formState.isValid}
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleDateSubmit}
                    >
                    Save
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
        <ConfirmDialog
            showDialog={showConfirmDialog}
            handleClose={() => setShowConfirmDialog(false)}
            onConfirm={handleDeactivateConfirm}
            title="Confirm Deactivation"
            subText="Are you sure you want to deactivate the selected batch?"
        />
    </>

};

export default Batch;