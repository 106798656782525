import React, {useRef } from 'react';
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/styles/makeStyles';
//import jsPDF from 'jspdf';
import Button from "@material-ui/core/Button";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
//import 'jspdf-autotable';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../../state';

const useStyles = makeStyles(theme => ({
    generatePDF:{
        position: 'relative',
        left: theme.spacing(70),
        backgroundColor: theme.palette.background.paper

    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.text.default,
      }, 
    divider: {
        backgroundColor: '#101010'
    },
    heading: {
        textAlign: "center",
        marginBottom: "1rem"
    },
    textCenter: {
        textAlign: "center"
    }}))


const InvoiceView = ({t, invoiceSetting, showDialog, handleClose, customer, lineSaleID, materials, payments, calcObj, openingBalance, openingTrayBalance}) => {
    const classes = useStyles();
    const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
    });
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }
    
    return <Dialog disableBackdropClick maxWidth="md" open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title" style={{backgroundColor:'#bac8d6'}}>

        <Button  variant="outlined" color="primary" id="printpagebutton" className={classes.generatePDF}
            onClick={generatePDF} endIcon={<PictureAsPdfIcon />}>Download
            PDF</Button>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
        </IconButton>
    </DialogTitle>
    <DialogContent dividers style={{backgroundColor:'#f4f6f8'}}>
<iframe
    id="receipt"
    //style={{ display: 'none' }}
    style={{ width: '100%',display: 'none' }}
    title="Receipt"
/>
<div id="sale-invoice" ref={componentRef}  >
{showDialog ?
    <div className="invoice-box" style={{fontFamily: 'Montserrat,Roboto,sans-serif'}}>
        <table cellPadding="0" cellSpacing="0">
            <tbody>
                <tr >
                    <td>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <table>
                                            <tbody>
                                                <tr><td><img src={invoiceSetting.companyLogo}
                                                style={{width:'100%', maxWidth:'150px'}} alt="company logo"></img><br/><br/></td></tr>
                                                <tr><td><b>{t('billTo')}</b></td></tr>
                                                <tr><td>{customer?.companyName}</td></tr>
                                                <tr><td>{customer?.primaryContact}</td></tr>
                                                <tr><td style={{whiteSpace: 'pre-wrap'}} 
                                                >{customer?.address}</td></tr>
                                                <tr><td>
                                                    <b>GST No. </b>{customer?.gstNumber ? customer?.gstNumber :"N/A"}<br/><br/>
                                                </td></tr>
                                                
                                            </tbody>
                                        </table>
                                    </td>
                                    <td style={{ width: "40%", textAlign: "right" }}>
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontWeight: '400', fontSize: '39px' }} colSpan="3">
                                                        {t('invoice')}<br />
                                                    </td>
                                                </tr>
                                                <tr><td colSpan="2"><h3>{invoiceSetting.companyName}</h3></td></tr>
                                                <tr><td colSpan="2"  style={{whiteSpace: 'pre-wrap'}} >{invoiceSetting.address}</td></tr>
                                                <tr><td colSpan="2"><b>GST No. </b>{invoiceSetting.gstNumber}<br /><br /></td></tr>
                                                <tr>
                                                    <td style={{width: '80px'}}><b>{t('invoice')}</b></td>
                                                    <td>{lineSaleID && lineSaleID}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>{t('date')}</b></td>
                                                    <td>{moment().format('DD/MM/YYYY')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            <h3 className={classes.heading}>{t('summary')}</h3>
                            <table style={{fontSize:"14px", marginBottom:"2rem"}}>
                                <tbody>
                                    <tr className="heading" style={{fontWeight: "bold", backgroundColor: "#eeeeee"}}>
                                        <td>{t('openingBalance')}</td>
                                        <td>{t('tcs')}</td>
                                        <td>{t('tds')}</td>
                                        <td>{t('billValue')}</td>
                                        <td>{t('paid')}</td>
                                        <td>{t('openingTrayBalance')}</td>
                                        <td>{t('totalReturnTrays')}</td>
                                        <td>{t('closingTrayBalance')}</td>
                                        <td>{t('closingBalance')}</td>
                                    </tr>
                                    <tr>
                                        <td>{openingBalance?.toLocaleString(user.locales,amountObj)}</td>
                                        <td>{calcObj?.tcsAmount.toLocaleString(user.locales,amountObj)}</td>
                                        <td>{calcObj?.tdsAmount.toLocaleString(user.locales,amountObj)}</td>
                                        <td>{calcObj?.billAmount === 0 ?  "N/A"  :calcObj?.billAmount.toLocaleString(user.locales,amountObj)}</td>
                                        <td>{calcObj?.paymentAmount.toLocaleString(user.locales,amountObj)}</td>
                                        <td>{openingTrayBalance}</td>
                                        <td>{calcObj?.totalReturnTrays}</td>
                                        <td>{calcObj?.closingTrayBalance}</td>
                                        <td>{calcObj?.closingBalance.toLocaleString(user.locales,amountObj)+ ` + ${t('invoice')} ${lineSaleID} ${t('value')}`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div >
                        <h3 className={classes.heading}>{t('materials')}</h3>
                        <table style={{ width: "100%", marginBottom:"2rem"}}>
                            <tbody>
                                <tr className="heading" style={{fontWeight: "bold", backgroundColor: "#eeeeee"}}>
                                    <td style={{padding:"0.5rem"}}>{t('eggType')}</td>
                                    <td style={{padding:"0.5rem"}}>{t('quantityInTrays')}</td>
                                    <td style={{padding:"0.5rem"}}>{t('neccDate')}</td>
                                    <td style={{padding:"0.5rem"}}>{t('neccPrice')}</td>
                                    <td style={{padding:"0.5rem"}}>{t('discount')}</td>
                                    <td style={{padding:"0.5rem"}}>{t('freeQuantityInTrays')}</td>
                                    <td style={{padding:"0.5rem"}}>{t('returnTrays')}</td>
                                    <td style={{padding:"0.5rem"}}>{t('billValue')}</td>
                                </tr>
                                {materials?.map((material, index) => {
                                    return <tr className='item' key={index}>
                                        <td>{material.materialName}</td>
                                        <td>{material.quantity}</td>
                                        <td>{moment(material.neccDate).format("DD/MM/YYYY")}</td>
                                        <td>{material.neccPrice.toLocaleString(user.locales,amountObj)}</td>
                                        <td>{material.discount.toLocaleString(user.locales,amountObj)}</td>
                                        <td>{material.freeQuantity}</td>
                                        <td>{material.returnTrays}</td>
                                        <td>
                                        {material.billValue === 0 ? "N/A" : 
                                        material.billValue.toLocaleString(user.locales,amountObj)}
                                        </td>
                                        
                                    </tr>
                                })}
                                <tr>

                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                        <h3 className={classes.heading}>{t('payments')}</h3>
                        <table style={{ width: "100%"}}>
                            <tbody>
                                <tr className="heading" style={{fontWeight: "bold", backgroundColor: "#eeeeee"}}>
                                <td>{t('paymentMode')}</td>
                                                    <td>{t('paymentAmount')}</td>
                                </tr>
                                {payments?.map((payment, index) => {
                                    return <tr className='item' key={index}>
                                    <td style={{padding:"0.5rem"}}>{payment.paymentMode === 1 ? t('cash') : `${t('cheque')} (${payment.chequeNumber})`}</td>
                                    <td style={{padding:"0.5rem"}}>{payment.paymentAmount.toLocaleString(user.locales,amountObj)}</td>
                                        
                                    </tr>
                                })}
                                <tr>

                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </td>
                </tr>
            </tbody>
                <tfoot>
                <tr>
                    <td>
                        <table style={{ fontSize: '11px', marginTop:"4rem" }}>
                            <tbody>
                                <tr>
                                    <td> <b>Terms & Conditions:</b></td>
                                </tr>
                                <tr>
                                    <td>  {invoiceSetting.termsAndConditions}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <br />
                        <Divider className={classes.divider} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <table  style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td style={{ textAlign: "center" }}>for <b>{invoiceSetting.companyName}</b></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table style={{ width: "100%" }}> 
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "center" }}>
                                                        <br /><br /><br /><br />
                                            Receiver Signature
                                            </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>
                                        <table  style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "center" }}>
                                                        <br /><br /><br /><br />
                                            Authorised Signatory
                                            </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr><td><Divider className={classes.divider} /></td></tr>
                <tr>
                    <td>
                        <table  style={{ width: "100%" }}>
                            <tbody>
                                <tr style={{ textAlignLast: "center" }}>
                                    <td><b>Bank:</b>&nbsp;
                                    {invoiceSetting.bankName} &nbsp;| &nbsp;
                                    {invoiceSetting.accountNumber} &nbsp;| &nbsp;
                                    {invoiceSetting.ifscCode} &nbsp;| &nbsp;
                                    {invoiceSetting.branchName}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    : ""}
</div>                </DialogContent>
</Dialog>
}

export default InvoiceView