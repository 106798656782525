import React,{useState,useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from '@material-ui/styles/makeStyles';
import api from "../../../common/api";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ShedDetails from './shedDetails';
import VaccineNotification from './VaccinationNotification';
import {MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import useFromValidator from '../../../../src/common/formValidator';
import { useLocation } from "react-router-dom";

const schema = {    
    entryDate:{
        presence: {allowEmpty: false, message: 'is required'}
    }
};

const useStyles = makeStyles(theme => ({
    root: props=>({
        margin: props.isDesktop?theme.spacing(4):theme.spacing(1)
    }),
    gridRoot:{
        width:'100%'
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    cardContainer:{
        paddingBottom:theme.spacing(2),
        paddingLeft: theme.spacing(1.37),
        width:'min-content'
    },
    tab:{
        paddingBottom:theme.spacing(2),
        paddingLeft: theme.spacing(1.37),
        width:'fit-content'
    },
    gridContainer:{
        paddingTop: "4%",
        justifyContent:'space-between',
        alignItems:'flex-end',
        ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
            justifyContent: 'center'
          }
    },
    datePicker:{
        justifyContent:'center',
        ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
            justifyContent: 'center'
          }
    }
}));

const FarmDashboard = ({farmID,showError}) => {

    const classes = useStyles({});
    const [loading, setLoading] = useState(false);
    const [farmDashboards,setFarmDashboards] = useState([]);
    const [tabValue, setTabValue] = React.useState(1);
    const [yesterdayDate, setYesterdayDate] = useState(new Date());
    /* const [formState, hasError, handleChange] = useFromVealidator(schema); */
    // const [valid, setValid] = useState(false);
    const [dailyDateState, hasDailyDateChangeError, handleDailyDateChange] = useFromValidator(schema);
    
    const queryParam = useLocation().search;
    const notificationToggle= new URLSearchParams(queryParam).get("notification");

    useEffect(()=> {
        if (notificationToggle) {
            setTabValue(3)
        }
    },[notificationToggle])
    const loadFarmDashboard = function (tempDataToDisplay) {
       ///farm/:id/running-cost
       setFarmDashboards([]);
       showError('');
       setLoading(true);
       var request = {
            farmID : parseInt(farmID, 10),
             dataToDisplay :tempDataToDisplay,
            date :yesterdayDate
        }
       const response = api.post(`farm/${farmID}/farm-dashboard`, {
            ...request
        });
        response.then(res => {
            setFarmDashboards([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const loadDailyorWeeklyFarmDashoard = function(tempDataToDisplay) {
        setFarmDashboards([]);
        showError('');
        setLoading(true);
        var request = {
            farmID : parseInt(farmID, 10),
             dataToDisplay : tabValue,
             date: dailyDateState.values.entryDate || yesterdayDate
        }
/*         if (tempDataToDisplay === 1) {
            request = {
                farmID : parseInt(farmID, 10),
                 dataToDisplay : tempDataToDisplay,
                 date: dailyDateState.values.entryDate
           }
        }
        else if (tempDataToDisplay === 2) {
            request = {
                farmID : parseInt(farmID, 10),
                 dataToDisplay : tempDataToDisplay,
                 date: endDate
           }
        } */
        const response = api.post(`farm/${farmID}/farm-dashboard`, {
             ...request
         });
         response.then(res => {
             setFarmDashboards([...res]);
         }).catch(err => {
             if (err.message) {
                 if (err.message === 'Bad Request' || err.message === 'No data found')
                     showError(err.message)
             } else {
                 showError('Unknown Error')
             }
         }).finally(() => {
             setLoading(false);
         });
         console.log('loadweeklyordaily');
    };

    useEffect(() => {
    const loadFarmDashboard = function (tempDataToDisplay) {
       ///farm/:id/running-cost
       showError('');
       var defaultDate;
       defaultDate = new Date();
       defaultDate.setDate(defaultDate.getDate() - 1);
       setYesterdayDate(defaultDate);
       setLoading(true);
       var request = {
           date:defaultDate,
           farmID : parseInt(farmID, 10),
            dataToDisplay :tempDataToDisplay
       }
       const response = api.post(`farm/${farmID}/farm-dashboard`, {
            ...request
        });
        response.then(res => {
            setFarmDashboards([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };
    loadFarmDashboard(1);
    }, [farmID, showError])

    if (loading) {
        return <div className={classes.progressContainer}>
                    <CircularProgress className={classes.progressBar}/>
                </div>
    }

    const handleTabChange = (event, newValue) => {
        var yesterdayDate;
        yesterdayDate = new Date();
        yesterdayDate.setDate(yesterdayDate.getDate() - 1);
        console.debug(event,newValue)
        setTabValue(newValue);
        if(newValue === 1 || newValue === 2){
            handleDailyDateChange({target: {name: 'entryDate', value: yesterdayDate}});
            loadFarmDashboard(newValue)
        }
    };

    return <Grid className={classes.gridRoot} >
    <Grid className={classes.tab} >
        <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
        >
            <Tab value={1} label="Daily" />
            <Tab value={2} label="Weekly" />
            <Tab value={3} label="Reminders Notifications" />
        </Tabs>
    </Grid>
    <Grid>
    {tabValue === 2 ?
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container
                    className={classes.datePicker}
                    spacing={3}
                    justifyContent="flex-start"
                    alignItems="center">

                    <Grid item>
                    <DatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="End Date"
                        format="dd/MM/yyyy"
                        value={dailyDateState.values.entryDate || null}
                        onChange={data => {
                            handleDailyDateChange({target: {name: 'entryDate', value: data}});
                        }}
                        onFocus={e => e.target.blur()} 
                        animateYearScrolling
                        name="entryDate"
                        error={hasDailyDateChangeError('entryDate')}
                    />
                    </Grid>
                    <Grid item >
                        <Button variant="contained" color="primary" onClick={loadDailyorWeeklyFarmDashoard}>Generate</Button>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>: ''}
        </Grid>
    <Grid>
        {tabValue === 1 ?
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container className={classes.datePicker} spacing={3} justify="flex-start" alignItems="center">
              <Grid item >
                    <DatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Entry Date"
                        format="dd/MM/yyyy"
                        value={dailyDateState.values.entryDate || yesterdayDate}
                        onChange={data => {
                            handleDailyDateChange({target: {name: 'entryDate', value: data}});
                        }}
                        onFocus={e => e.target.blur()} 
                        animateYearScrolling
                        name="entryDate"
                        error={hasDailyDateChangeError('entryDate')}
                    />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={loadDailyorWeeklyFarmDashoard}
                  >
                  Generate
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider> : ''}
    </Grid>
    <Grid className={classes.gridContainer} container>
    {tabValue === 1 || tabValue === 2 ?
        (farmDashboards.map((row ,index) => (
        <Grid item key={index} className={classes.cardContainer} >
            <ShedDetails row = {row} tabValue={tabValue}/>
        </Grid>
        )))
        :
        ""
     }
     {
         tabValue === 3 ? 
         <Grid  className={classes.gridRoot}>
        <VaccineNotification showError={showError} farmID={farmID}/>
        </Grid>
        :""
     }
     </Grid>
    </Grid>
}


export default FarmDashboard;