import React, {useEffect,useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button"; 
import PurchasePayment from "./purchasePayment";
import {MaterialTable} from '../../../components';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(2, 0)
    }
}));

let currentPayment = undefined

const PurchasePayementDetails = ({onPaymentEdit, data ,banks,   handlePaymentDetailsClose, showPaymentDetailsDialog, 
    onPurchasePaymentSave, onPurchasePaymentDelete, loading}) => {
    const classes = useStyles();
    const [showPaymentDialog, setShowPaymentDialog] = useState(false)
    const [purchasePayments, setPurchasePayments] = useState([])
    const [editPayment, setEditPayment] = useState(false)
    const [payment, setPayment] = useState(undefined)

    const reset = () => {
        currentPayment = data
        if(showPaymentDetailsDialog && data !== undefined && data.purchasePayments !== undefined && data.purchasePayments !== null){
            console.debug(data)
            setPurchasePayments(data.purchasePayments);
        }else{
            setPurchasePayments([])
        }
    };
    useEffect(reset, [showPaymentDetailsDialog,data]);


    function onNewPayment(event) {
        setEditPayment(false)
        setPayment(undefined)
        setShowPaymentDialog(true);
    } 

    function onEdit(rowData) {
        rowData = {
            ...rowData,
            bankIndex:banks.findIndex((bank)=>{return bank.id === rowData.paymentBankId;})
        };
        setEditPayment(true)
        setPayment(rowData)
        setShowPaymentDialog(true);
    }

    function onPaymentDelete(rowData) {
        console.debug(rowData,purchasePayments)
        let index = purchasePayments.findIndex((srcPurchasePayment) => {
            return srcPurchasePayment.id === rowData.id;
        });
        let newPurchasePayments = purchasePayments;
        newPurchasePayments.splice(index, 1); 
        console.debug(newPurchasePayments)
        setPurchasePayments(newPurchasePayments)
        let tempCurrentData = {
            ...data,
            purchasePayments :newPurchasePayments
        }
        onPurchasePaymentDelete(rowData, tempCurrentData)
        console.debug(purchasePayments)
    }


    function onPaymentSave(payment) {
        console.debug(payment)
        console.debug(purchasePayments)
        if(editPayment){
            let index = purchasePayments.findIndex((srcPayement) => {
                return srcPayement.id === payment.id;
            });
            setPurchasePayments([...purchasePayments.slice(0, index),
                {...payment},
                ...purchasePayments.slice(index + 1)])
    
            let tempCurrentData ={
                ...data,
                purchasePayments :[...purchasePayments.slice(0, index),
                    {...payment},
                    ...purchasePayments.slice(index + 1)]
            }
            onPaymentEdit(payment, tempCurrentData)
        }else{
            payment = {
                ...payment,
                purchaseId : data.id
            }
            onPurchasePaymentSave(payment)
        }
        setShowPaymentDialog(false);
        console.debug(purchasePayments)
    }


    function handlePaymentClose() {
        setEditPayment(false)
        setPayment(undefined)
        setShowPaymentDialog(false);
    }

    let actions = [
        rowData => ({
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit purchase Details',
            onClick: (event, rowData) => onEdit(rowData),
            hidden: rowData.paymentNote === "Bulk Payment" || rowData.paymentNote === "Payment received for TDS" || rowData.paymentNote === "Payment made for TCS" ? true : false
        }),
        rowData => ({
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete purchase Details',
            onClick: (event, rowData) => onPaymentDelete(rowData),
            hidden: rowData.paymentNote === "Bulk Payment" || rowData.paymentNote === "Payment received for TDS" || rowData.paymentNote === "Payment made for TCS" ? true : false
        })
    ]
    return <Dialog maxWidth='md' open={showPaymentDetailsDialog} onClose={handlePaymentDetailsClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >Purchase Payments</DialogTitle>
          <DialogContent  >
            <div className={classes.button} >
                <Button variant="contained" color="primary" onClick={onNewPayment}>Add Payment</Button>
            </div>

            <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'id', hidden: true},
                {title: 'Payment Date', field: 'paymentDate',  editable: 'never',
                render: rowData => {
                    return <span>{moment(rowData.paymentDate).format("DD-MM-YYYY")}</span>
                }
                },
                {title: 'Payment Amount', field: 'paymentAmount',  editable: 'never'},
                {title: 'Payment Mode', field: 'paymentMode', editable: 'never',
                render: rowData => {
                    return <span>{rowData.paymentMode === 0 ? '' : (rowData.paymentMode === 1 ? 'Cash' : rowData.paymentBankName.String)}</span>
                }
                },
                {title: 'paymentBankId', field: 'paymentBankId', editable: 'never', hidden: true},
                {title: 'Payment Note', field: 'paymentNote', editable: 'never'},
            ]}
            data={purchasePayments}
            options={{
                actionsColumnIndex: -1,
                search: false,
                showTitle: false,
                
                toolbar: false,
                paging:false
            }} 
            //style={{boxShadow: 'unset'}}
            actions={actions}
            /> 


            <PurchasePayment
            banks={banks} 
            data={currentPayment}
            paymentData={payment}
            onSave={onPaymentSave}
            handlePaymentClose={handlePaymentClose} 
            showPaymentDialog={showPaymentDialog}
            editMode={editPayment} >
            </PurchasePayment> 

            <DialogActions>
                <Button
                    className={classes.button}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handlePaymentDetailsClose}
                >
                    Close
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
};

export default PurchasePayementDetails;