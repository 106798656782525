import React,{useState, useEffect} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import { MaterialTable } from '../../../components';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableBody';
import {useGlobalState} from '../../../state';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Icon from "@material-ui/core/Icon";
import DescriptionIcon from '@material-ui/icons/Description';
import PaymentIcon from '@material-ui/icons/Payment';

const useStyles = makeStyles(theme => ({
    grid: {
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    totalTable: {
        float: 'right',
        width: theme.spacing(28.6)
    },
    totalCell: {
        width: theme.spacing(16.25),
        paddingLeft: theme.spacing(0)
    },
    center: {
        marginRight: theme.spacing(1),
        textAlign: 'center'
    }
}));

const SaleDetails = ({ data,trucks,lineSales, handleSaleDetailsDialogClose, showSaleDetailsDialog, onPaymentEdit, onSaleEdit, onGenerateInvoice, onDelete }) => {
    console.log("42", JSON.stringify(data));
    const classes = useStyles();
    const [{user}] = useGlobalState();
    const [quantityColumn, setQuantityColumn] = useState([])

    const [billValue, setBillValue] = useState(0)
    const [tdsValue, setTdsValue] = useState(0)
    const [tcsValue, setTcsValue] = useState(0)
    const [gstValue, setGstValue] = useState(0)
    const [linesale,setLineCheck] = useState(false)

    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
    /*const [billValueSum, setBillValueSum] = useState(0.0)

    function calculateBillTotal() {
        let total = 0.0
        let billValue = 0.0
        if(data !== undefined && data !== null && data.saleMaterials !== undefined && data.saleMaterials !== null){
            data.saleMaterials.forEach((saleMaterial,index)=>{
                billValue = (saleMaterial.quantity * saleMaterial.costPerUnit)
                total = total + billValue
            })
        }
        setBillValueSum(total)
    }
    useEffect(calculateBillTotal, [data]);*/

    function calculateTotalOfSaleMaterial(data){
        let billAmt = 0
        let tdsAmt = 0
        let tcsAmt = 0
        let gstAmt = 0
        let saleMaterials = data.saleMaterials 
        if(saleMaterials !== undefined){
            saleMaterials.forEach((saleMaterial) => {
                billAmt += saleMaterial.billValue
                let tdsTaxAmt = saleMaterial.billValue * ((data.tds ? data.tds : 0)) / 100
                let tcsTaxAmt = saleMaterial.billValue * ((data.tcs ? data.tcs : 0)) / 100
                let gstTaxAmt = saleMaterial.billValue * ((saleMaterial.gst ? saleMaterial.gst : 0)) / 100
                tdsAmt += tdsTaxAmt
                tcsAmt += tcsTaxAmt
                gstAmt += gstTaxAmt
            })
        }

        setBillValue(billAmt)
        setTdsValue(tdsAmt)
        setTcsValue(tcsAmt)
        setGstValue(gstAmt)
    }
    useEffect(()=>{
        if (data?.onLineSale === 1) {
            setLineCheck(true)
            let truckName = trucks?.find(truck => truck.id === data?.truckID)
            let linesaleName = lineSales?.find(lineSale => lineSale.id === data?.lineSaleID)
            data.truckName = truckName?.number
            data.lineSaleName = linesaleName?.name
        } else {
            setLineCheck(false)
        }
        let isBird = false
        if(data !== undefined && data.saleMaterials !== undefined && data.saleMaterials !== null && data.saleMaterials.length > 0)
            isBird = (data.saleMaterials).find(material => material.materialTypeId === 7)
            

        if(isBird){
            setQuantityColumn([
                {
                    title: 'Number Of Birds', field: 'numberOfBirds', editable: 'numberOfBirds',
                    render: (rowData) => {
                        return <span>{ rowData.materialTypeId === 7 ? rowData.numberOfBirds : 0 } </span>
                    }
                }
            ])
        }else{
            setQuantityColumn([])
        }

        if (data && data.saleMaterials && Array.isArray(data.saleMaterials) && (data.saleMaterials).length > 0) {
            calculateTotalOfSaleMaterial(data)
        }
    }, [data,trucks,lineSales])

    return data ? <Dialog maxWidth='md' open={showSaleDetailsDialog} onClose={handleSaleDetailsDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >Sale Details
        {handleSaleDetailsDialogClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleSaleDetailsDialogClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
        <DialogContent dividers>
            <Grid
                container
                justify='space-between'
                alignItems='flex-end'
            >
                <Grid item className={classes.grid}>
                    <Typography variant="h5" component="h2">
                        {moment(data.date).format("DD-MM-YYYY")}
                    </Typography>
                    <Typography color="textSecondary">
                        Date
                        </Typography>
                </Grid>
                <Grid item className={classes.grid}>
                    <Typography variant="h5" component="h2">
                        {data.paymentDueDate}
                    </Typography>
                    <Typography color="textSecondary">
                        Payment Due(in Days)
                        </Typography>
                </Grid>
                {
                    !linesale ?
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.truckNumber}
                        </Typography>
                        <Typography color="textSecondary">
                            Truck Number
                            </Typography>
                    </Grid> :
                    
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data?.truckName}
                        </Typography>
                        <Typography color="textSecondary">
                            Truck 
                            </Typography>
                    </Grid> 
                }
                 {
                    !linesale ?
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.customerName}
                        </Typography>
                        <Typography color="textSecondary">
                            Customer Name
                        </Typography>
                    </Grid> :
                        
                        <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data?.lineSaleName}
                        </Typography>
                        <Typography color="textSecondary">
                          Line Name
                        </Typography>
                    </Grid>
                    }
                
                <Grid item className={classes.grid}>
                    <Typography variant="h5" component="h2">
                        { data.onLineSale===1? <CheckIcon style={{color:'green'}}/> : <ClearIcon/>}
                    </Typography>
                    <Typography color="textSecondary">
                        Line Sale
                    </Typography>
                </Grid>
            </Grid>
            <Grid container
                justify='flex-start'
                alignItems='center'
                style={{
                    marginBottom:"0.5rem",
                }}>
                <div className={classes.center}
                    style={{
                        display: (user.role === "STAFF" && !moment(data.createTime).isSame(moment(), "date") ? "none" : "block")
                    }}>
                    <IconButton onClick={() => onPaymentEdit(data)} aria-label="Delete" className={classes.margin}>
                        <PaymentIcon color='error'>payment</PaymentIcon>
                    </IconButton>
                    <Typography color="textSecondary">
                        Payment edit
                    </Typography>
                </div>
                <div className={classes.center}
                    style={{
                        display: (user.role === "STAFF" && !moment(data.createTime).isSame(moment(), "date") ? "none" : "block")
                    }}>
                    <IconButton onClick={() => onSaleEdit(data)} aria-label="Delete" className={classes.margin}>
                        <Icon color='primary'>edit</Icon>
                    </IconButton>
                    <Typography color="textSecondary">
                        Edit
                    </Typography>
                </div>
                <div className={classes.center}>
                    <IconButton onClick={() => onGenerateInvoice(data)} aria-label="Delete" className={classes.margin}>
                    <DescriptionIcon color='primary'>Generate Invoice</DescriptionIcon>
                    </IconButton>
                    <Typography color="textSecondary">
                    Generate Invoice
                    </Typography>
                </div>
                <div className={classes.center} 
                    style={{
                        display: (user.role === "STAFF" && !moment(data.createTime).isSame(moment(), "date") ? "none" : "block")
                    }}>
                    <IconButton onClick={() => onDelete(data)} aria-label="Delete" className={classes.margin}>
                        <Icon color='error'>delete</Icon>
                    </IconButton>
                    <Typography color="textSecondary">
                        Delete
                    </Typography>
                </div>
            </Grid>
            <Grid>
                <MaterialTable
                    columns={[
                        { title: 'id', field: 'id', hidden: true },
                        { title: 'Item Name', field: 'materialName', editable: 'never' },
                        {
                            title: 'Inventory Type', field: 'inventoryType', editable: 'never',
                            render: (rowData) => {
                                return <span>{rowData.inventoryType === 1 ? 'Feed Mill' : 
                                (rowData.farmName.String + ' (' + rowData.shedName.String + ')')}</span>
                            }
                        },
                        {
                            title: 'Quantity', field: 'quantity', editable: 'never',
                            render: (rowData) => {
                                return <span>{rowData.quantity + " (In " + rowData.materialUnit + ")"}</span>
                            }
                        },
                        ...quantityColumn,
                        { title: 'Sale Price', field: 'costPerUnit', editable: 'never' },
                        { title: 'Discount Per Unit', field: 'discountPerUnit', editable: 'never' },
                        {
                            title: 'Bill Value', field: 'billValue', editable: 'never',
                            render: (rowData) => {
                                return <span>{(rowData.billValue).toLocaleString(user.locales,amountObj)}</span>
                            },
                        },
                        { title: 'GST', field: 'gst', editable: 'never' ,
                          render: (rowData) => {
                            let gstTaxAmt = ((rowData.gst || 0)/100) * rowData.billValue
                            return <span>{gstTaxAmt.toLocaleString(user.locales,amountObj) }</span>
                         }
                        },
                    ]}
                    data={data.saleMaterials || []}
                   
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        showTitle: false,
                        
                        toolbar: false,
                        paging: false
                    }}
                />
                <Table className={classes.totalTable} >
                    <TableHead>

                        <TableRow>
                            <TableCell className={classes.totalCell}>Bill Value</TableCell>
                            <TableCell>{(billValue).toLocaleString(user.locales,amountObj)}</TableCell>
                        </TableRow>
                        {/* commenting for now */}
                        {gstValue && gstValue > 0 ? 
                            <TableRow>
                            <TableCell className={classes.totalCell}>GST</TableCell>
                            <TableCell>{(gstValue).toLocaleString(user.locales,amountObj)}</TableCell>
                        </TableRow>
                        : null}
                        {tdsValue && tdsValue > 0 ? <TableRow>
                            <TableCell className={classes.totalCell}>TDS </TableCell>
                            <TableCell>{(tdsValue).toLocaleString(user.locales,amountObj)}</TableCell>
                        </TableRow> : null}
                        {tcsValue && tcsValue > 0 ? <TableRow>
                            <TableCell className={classes.totalCell}  >TCS </TableCell>
                            <TableCell>{(tcsValue).toLocaleString(user.locales,amountObj)}</TableCell>
                        </TableRow>: null}
                        
                        <TableRow>
                            <TableCell className={classes.totalCell} >Final Total </TableCell>
                            <TableCell>{(data.totalBillValue + tcsValue + gstValue - tdsValue).toLocaleString(user.locales,amountObj)}</TableCell>
                        </TableRow>
                        {/* commenting for now */}
                        {/* <TableRow>
                            <TableCell className={classes.totalCell}  >Total With TDS</TableCell>
                            <TableCell>{
                            data.totalBillValue === 0 ?
                            (0).toLocaleString(user.locales,amountObj): 
                            (data.totalBillValue+(((data.tds || 0)*data.totalBillValue)/100)).toLocaleString(user.locales,amountObj) 
                        }</TableCell>
                        </TableRow> */}
                    </TableHead>
                </Table>
            </Grid>
        </DialogContent>
    </Dialog>
        : null
};

export default SaleDetails;