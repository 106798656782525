import React, {useEffect, useState} from 'react';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import makeStyles from '@material-ui/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import api from '../../common/api';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const schema = {
    password: {
        presence: {allowEmpty: false, message: 'is required'},
        length: {
            minimum: 8,
            maximum: 128
        }
    },
    passwordConfirm: {
        presence: {allowEmpty: false, message: 'is required'},
        length: {
            minimum: 8,
            maximum: 128
        },
        equality: {
            attribute: "password",
            message: "Passwords should match"
        }
    }
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%'
    },
    errorText: {
        color: theme.palette.error
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    grid: {
        height: '100%'
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/images/chick.webp)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    quoteText: {
        color: theme.palette.white,
        fontWeight: 300
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    bio: {
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(3)
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    policy: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center'
    },
    policyCheckbox: {
        marginLeft: '-14px'
    },
    signUpButton: {
        margin: theme.spacing(2, 0)
    }
}));

const ChangePassword = ({match, history}) => {


    const classes = useStyles();
    const key = match.params['key'];
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    const [loading, setLoading] = useState(false);
    const [apiErrorText, setApiErrorText] = useState(undefined);
    const [apiMessageText, setApiMessageText] = useState(undefined);

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
        }));
    }, [formState.values]);

    const handleChange = event => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleChangePassword = event => {
        setLoading(true);
        setApiErrorText(undefined);
        setApiMessageText(undefined);

        //Reset Form
        setFormState(() => ({
            isValid: false,
            touched: {},
            errors: {},
            values: {}
        }));

        event.preventDefault();
        const response = api.post('change-password', {
            "resetKey": key,
            "password": formState.values.password
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'No data found')
                    setApiErrorText('Password Change Failed, Invalid Reset Key');
                else
                    setApiErrorText('Password Change Failed,' + err.message);
            } else {
                setApiErrorText('Password Change Failed,Unknown Error');
            }

        });

        response.then(res => {
            if (res === true) {
                setApiMessageText('Password changes Successfully!, You will be refirected to the login page...');
                setTimeout(() => {
                    history.push('/sign-in');
                }, 2000);
            }
        });

        response.finally(() => {
            setLoading(false);
        });


    };

    const hasError = field =>
        !!(formState.touched[field] && formState.errors[field]);

    return (
        <div className={classes.root}>
            <Grid
                className={classes.grid}
                container
            >
                <Grid
                    className={classes.quoteContainer}
                    item
                    lg={5}
                >
                    <div className={classes.quote}>

                    </div>
                </Grid>
                <Grid
                    className={classes.content}
                    item
                    lg={7}
                    xs={12}
                >

                    <div className={classes.content}>
                        <div className={classes.contentBody}>
                            {
                                loading ?
                                    <div className={classes.progressContainer}>
                                        <CircularProgress className={classes.progressBar}/>
                                    </div> :
                                    <form
                                        className={classes.form}
                                        onSubmit={handleChangePassword}
                                    >
                                        {
                                            apiErrorText ?
                                                <Chip color="secondary" icon={<ErrorIcon/>}
                                                      label={apiErrorText}/> :
                                                undefined
                                        }
                                        {
                                            apiMessageText ?
                                                <Chip color="primary" icon={<CheckCircleIcon/>}
                                                      label={apiMessageText}/> :
                                                undefined
                                        }
                                        <Typography
                                            className={classes.title}
                                            variant="h2"
                                        >
                                            Change your password
                                        </Typography>
                                        <Typography
                                            color="textSecondary"
                                            gutterBottom
                                        >
                                            reset your account's password
                                        </Typography>
                                        <TextField
                                            className={classes.textField}
                                            autoComplete="new-password"
                                            error={hasError('password')}
                                            fullWidth
                                            helperText={
                                                hasError('password') ? formState.errors.password[0] : null
                                            }
                                            label="Password"
                                            name="password"
                                            onChange={handleChange}
                                            type="password"
                                            value={formState.values.password || ''}
                                            variant="outlined"
                                        />
                                        <TextField
                                            className={classes.textField}
                                            autoComplete="new-password"
                                            error={hasError('passwordConfirm')}
                                            fullWidth
                                            helperText={
                                                hasError('passwordConfirm') ? formState.errors.passwordConfirm[0] : null
                                            }
                                            label="Confirm Password"
                                            name="passwordConfirm"
                                            onChange={handleChange}
                                            type="password"
                                            value={formState.values.passwordConfirm || ''}
                                            variant="outlined"
                                        />

                                        <Button
                                            className={classes.signUpButton}
                                            color="secondary"
                                            disabled={!formState.isValid}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            Reset Password
                                        </Button>
                                        <Typography
                                            color="textSecondary"
                                            variant="body1"
                                        >
                                            Remember your password?{' '}
                                            <Link
                                                component={RouterLink}
                                                to="/sign-in"
                                                variant="h6"
                                            >
                                                Sign in
                                            </Link>
                                        </Typography>
                                    </form>
                            }

                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

ChangePassword.propTypes = {
    history: PropTypes.object
};

export default withRouter(ChangePassword);
