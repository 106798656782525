import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_APIURL,
    responseType: "json"
});

api.defaults.timeout = 1555500;
export default api;

api.interceptors.request.use(function (config) {

    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

    return config;
});

api.interceptors.response.use(function (response) {
    if (response.data) {
        return response.data;
    }
    //If there is no Data, then it is not a  Proper Server Reponse
    return Promise.reject({"message": "Invalid Server Response"});
}, function (error) {

    //Go to Login if it is 401 error
    if(error.response && error.response.status === 401){
        window.localStorage.clear();
        window.location.reload();
    }
    if(error.response && error.response.status === 403){
        window.location = '/forbidden';
    }

    if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
    }
    if (error.message && error.message) {
        return Promise.reject({'message': error.message});
    }

    //If there is no Data, then it is not a  Proper Server Reponse
    return Promise.reject({"message": "Invalid Server Response"});
});

