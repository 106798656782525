import React, { useEffect, useState } from "react";
import api from "../../common/api";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from "../../components";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Mobile from "./components/mobile";
import moment from "moment";
import LabourPayment from "./components/labourPayment";
import Icon from "@material-ui/core/Icon";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useGlobalState } from "../../state";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1),
  }),
  topBar: {
    marginBottom: theme.spacing(2),
  },
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  redChip: {
    justifyContent: "center",
    fontSize: "0.85rem",
    letterSpacing: "0.5px",
    padding: "4px 8px",
    color: "#f44336",
    backgroundColor: "rgba(244, 67, 54, 0.08)",
  },
  greenChip: {
    justifyContent: "center",
    fontSize: "0.85rem",
    letterSpacing: "0.5px",
    padding: "4px 8px",
    color: "#4caf50",
    backgroundColor: "rgba(76, 175, 80, 0.08)",
  },
  red: {
    color: "red",
  },
  green: {
    color: "green",
  },
}));

const LabourPaymentView = ({ showError, showMessage, history, match }) => {
  const farmID = match.params["farmID"];

  const [labourPayments, setLaboutPayment] = useState([]);
  const [Labours, setLabours] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLabourPaymentDialog, setShowLabourPayment] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentLabourpayment, setCurrentLabourpayment] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [banks, setBanks] = useState([]);

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const [{ user }] = useGlobalState();
  const amountObj = {
    style: "currency",
    currency: user.currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const classes = useStyles({ isDesktop });

  const csvHeader = ["ID", "Date", "Labour", "Amount(in Rs.)"];

  function onNewLabourPayment(event) {
    setEditMode(false);
    event.preventDefault();
    setShowLabourPayment(true);
  }

  function handleOtherExpenseClose() {
    setCurrentLabourpayment(undefined);
    setErrorMessage(null);
    setShowLabourPayment(false);
  }

  function handleDeleteConfirmClose() {
    setShowDeleteConfirm(false);
  }

  const removeErrorMessage = (value) => {
    setErrorMessage("");
  };

   //Get all Banks API Call
   const getAllBanks = () => {
    showError('');
    showMessage('');

    const response = api.get('bank');

    response.then(res => {
        setBanks([...res]);
    }).catch(err => {
        if (err.message) {
            if (err.message === 'Bad Request' || err.message === 'No data found')
                showError('No Bank Found');
            else
                showError(err.message)
        } else {
            showError('Unknown Error')
        }
    });

    return response;
}
  const onLabourPaymentDeleteConform = () => {
    if (currentLabourpayment && currentLabourpayment.id) {
      setShowDeleteConfirm(false);
      showError("");
      const response = api.delete(
        `/farm/${farmID}/labourPayment/${currentLabourpayment.id}`
      );

      response
        .then((res) => {
          if (res) {
            let index = labourPayments.findIndex((srcLabourPayments) => {
              return srcLabourPayments.id === currentLabourpayment.id;
            });
            let newlabourPayments = [...labourPayments];
            newlabourPayments.splice(index, 1);
            setLaboutPayment(newlabourPayments);
          }
        })
        .catch((err) => {
          if (err.message) {
            if (
              err.message === "Bad Request" ||
              err.message === "No data found"
            )
              showError("Labour PAyments not Deleted");
            else showError(err.message);
          } else {
            showError("Unknown Error");
          }
        })
        .finally(() => {
          setLoading(false);
          setCurrentLabourpayment(undefined);
        });
    }
  };

  const onDelete = (otherExpense) => {
    setCurrentLabourpayment(otherExpense);
    setShowDeleteConfirm(true);
  };

  const onLabourPaymentSave = (labourPayment, setPopupLoading) => {
    //setLoading(true);
    // showError('');
    showMessage("");
    console.log("labourPayment", labourPayment);
    const response = api.post(`farm/${farmID}/labourPayment`, {
      ...labourPayment,
    });

    response
      .then((res) => {
        setLaboutPayment([...labourPayments, res]);
        setShowLabourPayment(false);
      })
      .catch((err) => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            setErrorMessage("labourPayment not added");
          else setErrorMessage(err.message);
        } else {
          setErrorMessage("Unknown Error");
        }
      })
      .finally(() => {
        setLoading(false);
        setPopupLoading(false);
      });
  };

  const onLabourPaymentEdit = (labourPayment) => {
    handlePaymentDateChange(labourPayment.paymentDate, true, labourPayment);
  };

  const labourPaymentEdit = (labourPayment, setPopupLoading) => {
    labourPayment.amount = parseInt(labourPayment.amount);
    const response = api.post(
      `/farm/${farmID}/labourPayment/${labourPayment.id}`,
      {
        ...labourPayment,
      }
    );
    response
      .then(() => {
        let index = labourPayments.findIndex((srcLabourPayment) => {
          return srcLabourPayment.id === labourPayment.id;
        });

        setLaboutPayment([
          ...labourPayments.slice(0, index),
          { ...labourPayment },
          ...labourPayments.slice(index + 1),
        ]);
        setShowLabourPayment(false);
      })
      .catch((err) => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            setErrorMessage("labourPayment not Updated");
          else setErrorMessage(err.message);
        } else {
          setErrorMessage("Unknown Error");
        }
      })
      .finally(() => {
        setPopupLoading(false);
        setLoading(false);
      });
  };

  //Get all labourPayments API Call
  const GetAllLabourPayment = () => {
    setShowLabourPayment(false);
    showError("");
    showMessage("");

    const response = api.get(`/farm/${farmID}/getAllLabourPayment`);

    response
      .then((res) => {
        if (res?.length) {
          setLaboutPayment([...res]);
        }
      })
      .catch((err) => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError("No labourPayments Found");
          else showError(err.message);
        } else {
          showError("Unknown Error");
        }
      });
    return response;
  };


  function handlePaymentDateChange(date, editMode, labourPayment) {
    let payload = {
      AttendanceDate: date,
      farmId: parseInt(farmID, 10),
    };
    const response = api.post(`farm/${farmID}/getActivelabourByDate`, {
      ...payload,
    });
    response
      .then((res) => {
        let tempLabours = [...res];
        setLabours([...res]);
        if (editMode) {
          let tempcurrentLabourpayment = {
            ...labourPayment,
            labourIndex: tempLabours.findIndex((labour) => {
              return labour.id === labourPayment.labourID;
            }),
            bankIndex: banks.findIndex((bank) => {
              return bank.id === labourPayment.bankID;
            }),
            amount: labourPayment.amount,
          };
          setCurrentLabourpayment(tempcurrentLabourpayment);
          setEditMode(true);
          setShowLabourPayment(true);
        }
      })
      .catch((err) => {
        setLabours([]);
        console.log(err);
      })
      .finally(() => {});
  }

  const generateExcel = function (header, data) {
    let csvData = "";

    header.forEach((column, index) => {
      if (index === 0) csvData = csvData + column;
      else csvData = csvData + "," + column;
    });

    csvData = csvData + "\r\n";

    data.map((row, index) => {
      let column = [];
      let tempDate;
      tempDate = moment(row.paymentDate).format("DD-MM-YYYY");
      column.push(row.id, tempDate, row.labourName, row.amount);

      column.forEach((column, index) => {
        if (index === 0) csvData = csvData + column;
        else csvData = csvData + "," + column;
      });
      csvData = csvData + "\r\n";

      return "";
    });

    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvData)
    );
    element.setAttribute("download", "report.csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const onload = () => {
    setLoading(true);
    Promise.all([GetAllLabourPayment(),getAllBanks()]).finally(() => {
      setLoading(false);
    });
  };
  useEffect(onload, []);

  let labourPaymentNameColumn = {
    title: "Labour Payment",
    editable: "never",
    defaultSort: "asc",
    render: (rowData) => (
      <Mobile
        data={rowData}
        onEdit={() => {
          onLabourPaymentEdit(rowData);
        }}
        onDelete={() => {
          onDelete(rowData);
        }}
      />
    ),
  };

  let actions = [];
  if (isDesktop) {
    labourPaymentNameColumn = {
      title: "Date",
      field: "date",
      type: "datetime",
      editable: "never",
      hidden: !isDesktop,
      render: (rowData) => {
        return <span>{moment(rowData.paymentDate).format("DD-MM-YYYY")}</span>;
      },
      defaultSort: "asc",
    };
    actions = [
      (rowData) => ({
        icon: () => <Icon color="primary">edit</Icon>,
        tooltip: "Edit Labour Payment Detail",
        onClick: () => onLabourPaymentEdit(rowData),
        hidden:
          user.role === "STAFF" &&
          !moment(rowData.createTime).isSame(moment(), "date")
            ? true
            : false,
      }),
      (rowData) => ({
        icon: () => <Icon color="error">delete</Icon>,
        tooltip: "Delete Labour Payment Details",
        onClick: (event, rowData) => onDelete(rowData),
        hidden:
          user.role === "STAFF" &&
          !moment(rowData.createTime).isSame(moment(), "date")
            ? true
            : false,
      }),
    ];
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="space-between"
        alignItems="flex-end"
        className={classes.topBar}
      >
        <Grid>
          <Typography display="block" variant="h3" gutterBottom>
            Labour Payments
          </Typography>
        </Grid>
        <Grid container spacing={3} justify="flex-end" alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onNewLabourPayment}
            >
              Add Labour payment
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={labourPayments.length < 1}
              onClick={() => generateExcel(csvHeader, labourPayments)}
              endIcon={<GetAppIcon />}
            >
              Download Excel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <MaterialTable
        isLoading={loading}
        columns={[
          { title: "ID", field: "id", hidden:!isDesktop },
          labourPaymentNameColumn,
          {
            title: "Labour",
            field: "labourName",
            hidden: !isDesktop,
            editable: "never",
          },
          {
            title: "Amount (in Rs.)",
            field: "amount",
            hidden: !isDesktop,
            editable: "never",
            render: (rowData) => {
              return (
                <span>
                  {rowData.amount.toLocaleString(user.locales, amountObj)}
                </span>
              );
            },
          },
        ]}
        data={labourPayments}
        title="Labour payment Table"
        options={{
          actionsColumnIndex: -1,
          search: true,
          showTitle: true,

          toolbar: true,
        }}
        actions={actions}
      />
      <LabourPayment
        deleteErrorMessage={removeErrorMessage}
        errorMessage={errorMessage}
        banks={banks}
        farmID={farmID}
        showDialog={showLabourPaymentDialog}
        handleClose={handleOtherExpenseClose}
        onSave={onLabourPaymentSave}
        onEdit={labourPaymentEdit}
        editMode={editMode}
        data={currentLabourpayment}
        Labours={Labours}
        onPaymentDateChange={handlePaymentDateChange}
      />

      <ConfirmDialog
        showDialog={showDeleteConfirm}
        handleClose={handleDeleteConfirmClose}
        onConfirm={onLabourPaymentDeleteConform}
        title="Delete Labour Payment ?"
        subText="This action will delete the Labour Payment Details. Please confirm."
      />
    </div>
  );
};

export default LabourPaymentView;
