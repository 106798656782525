import {blue, blueGrey, green, grey, orange, red} from '@material-ui/core/colors';


const white = '#FFFFFF';
const black = '#000000';

/*
#035A81 - DarkBlue

#0185C1 - Light Blue

#F26522 - Orange

 */
export default {
    black,
    white,
    primary: {
        contrastText: white,
        main: '#39A3DD',
        secondary:'#39A3DD',
        background:'#39A3DD'
    },
    secondary: {
        contrastText: white,
        main: '#035A81',
        
    },
    success: {
        contrastText: white,
        dark: green[900],
        main: green[600],
        light: green[400]
    },
    info: {
        contrastText: white,
        main: '#0185C1'
    },
    warning: {
        contrastText: white,
        dark: orange[900],
        main: orange[600],
        light: orange[400]
    },
    error: {
        contrastText: white,
        dark: red[900],
        main: red[600],
        light: red[400]
    },
    text: {
        primary: blueGrey[900],
        secondary: blueGrey[600],
        link: blue[600],
        main: '#c5c7c9',
        default: '#FFFFFF'
    },
    background: {
        default: '#ebeff4',
        paper: white
    },
    navBackground: {
        default: '#ffffff',
        paper: '#d7e0ea'
    },
    buttonColor: {
        primary:blueGrey[800]
    },
    icon: blueGrey[600],
    divider: grey[200]
};
