import React, {useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/styles/makeStyles';
import useFromValidator from '../../../common/formValidator';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const schema = {
    day: {
        presence: {allowEmpty: false, message: 'is required'},
        type: "integer"
    },
    name: {
        presence: {allowEmpty: false, message: 'is required'}
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    }
}));

const Schedule = ({onEdit, data, onSave, handleClose, showDialog, editMode = false}) => {
    const classes = useStyles();

    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);


    const resetBeforeEdit = () => {
        if (showDialog) {
            updateData(data, editMode);
        }
    };

    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        if (editMode)
            onEdit(formState.values);
        else
            onSave(formState.values);
    };

    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >{editMode?'Update':'New'} Schedule
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent  >
            <TextField
                className={classes.textField}
                error={hasError('day')}
                fullWidth
                helperText={
                    hasError('day') ? formState.errors.day[0] : null
                }
                label="Day"
                name="day"
                onChange={handleChange}
                type="number"
                value={formState.values.day !== undefined ? formState.values.day : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}

                
            />
            <TextField
                className={classes.textField}
                error={hasError('name')}
                fullWidth
                helperText={
                    hasError('name') ? formState.errors.name[0] : null
                }
                label="Vaccine names"
                name="name"
                onChange={handleChange}
                type="text"
                value={formState.values.name || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}

            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>

};

export default Schedule;