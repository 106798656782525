import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/styles/makeStyles";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import api from "../../common/api";
import {MaterialTable} from "../../components";
import Link from "@material-ui/core/Link";
import HomeIcon from '@material-ui/icons/Home';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import PetsIcon from '@material-ui/icons/Pets';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { TablePagination } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    breadcrumbs: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    tableFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 16px',
    },
    tableFooterCaption: props => ({
        width: 200,
        display: props.isSmallScreen ? 'none' : 'block'
    })
}));


const FeedFormulationMaterialsView = ({showError, showMessage, match, history}) => {
    const feedFormulationID = match.params['feedFormulationID'];
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop, isSmallScreen});

    const [materialTypes, setMaterialType] = useState([]);

    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(false);

    const totalQuantity =
        dataList?.reduce((accumulator, currentRow) => accumulator + currentRow.quantity, 0) || 0;

    const onEditHandler = function (data, oldData) {
        
        return new Promise((resolve, reject) => {
            showError('');
            const response = api.post(`feed-formulation/${feedFormulationID}/feed-formulation-material/${data.id}`, {
                ...data,
                quantity: parseFloat(data.quantity, 10),
            });
            response.then(() => {
                let index = dataList.findIndex((srcData) => {
                    return srcData.id === data.id;
                });
                setDataList([...dataList.slice(0, index),
                    {...data},
                    ...dataList.slice(index + 1)]);
                resolve();
            }).catch(err => {

                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        alert('Material not updated');
                    else if (err.message === 'duplicate key')
                        alert('Duplicate Entry');
                    else
                        alert(err.message)
                } else {
                    alert('Unknown Error')
                }
                reject();
            })

        });
    }

    //Get all Standard Production API Call
    const getFeedFormulationMat = () => {
        showError('');
        showMessage('');

        const response = api.get(`feed-formulation/${feedFormulationID}/feed-formulation-material`);

        response.then(res => {
            setDataList([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Material is empty');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

    const getAllData = ()=>{
        setLoading(true);
        Promise.all([getFeedFormulationMat(),getAllMaterialType()]).finally(()=>{
            setLoading(false);
        });
    }

    useEffect(getAllData, []);

    //Get all Material Type API Call
    const getAllMaterialType = () => {
        
        showError('');
        showMessage('');

        const response = api.get(`material-type/1`);

        response.then(res => {
            setMaterialType([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Material Type');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    };

    const onCreateHandler = (data) => {
        return new Promise((resolve, reject) => {
            showError('');
            showMessage('');
            const response = api.post(`feed-formulation/${feedFormulationID}/feed-formulation-material`, {
                ...data,
                quantity: parseFloat(data.quantity, 10),
            });

            response.then(res => {
                setDataList([...dataList, res]);
                resolve();
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        alert('Material not added');
                    else if (err.message === 'duplicate key')
                        alert('Duplicate Entry');
                    else
                        alert(err.message)
                } else {
                    alert('Unknown Error')
                }
                reject();
            })
        });
    }

    const onDeleteHandler = (data) => {

        return new Promise((resolve, reject) => {

            if (data && data.id) {
                api.delete(`feed-formulation/${feedFormulationID}/feed-formulation-material/${data.id}`)
                    .then(res => {
                        if (res) {
                            let index = dataList.findIndex((src) => {
                                return src.id === data.id;
                            });
                            let newData = [...dataList];
                            newData.splice(index, 1);
                            setDataList(newData);
                            resolve();
                        }
                    }).catch(err => {
                    if (err.message) {
                        if (err.message === 'Bad Request' || err.message === 'No data found')
                            alert('Material not Deleted');
                        else
                            alert(err.message)
                    } else {
                        alert('Unknown Error')
                    }
                    reject();
                })

            }

        });
    }


    return <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
            <Link color="inherit" href="#" onClick={() => history.push('/')} className={classes.link}>
                <HomeIcon className={classes.icon}/>
                Home
            </Link>
            <Link color="inherit" href="#" onClick={() => history.push('/feed-formulation')} className={classes.link}>
                <PetsIcon className={classes.icon}/>
                Feed Formulation
            </Link>
            <Typography color="textPrimary" className={classes.link}>
                <LocalHospitalIcon className={classes.icon}/>
                Materials
            </Typography>
        </Breadcrumbs>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Materials
                </Typography>
            </Grid>

        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'feedFormulationID', field: 'feedFormulationID', hidden: true},
                {title: 'id', field: 'id', hidden: true},
                {
                    title: 'Material Name', field: 'materialTypeName', editComponent: props => {
                        let index = materialTypes.findIndex((val => val.id === props.rowData.materialTypeID));
                        if (index === -1) {
                            index = '';
                        }
                        ;
                        return <Select
                            id="materialType-select"
                            value={index}
                            onChange={e => {
                                props.rowData.materialTypeID = materialTypes[e.target.value].id;
                                props.onChange(materialTypes[e.target.value].materialType);
                            }}
                        >
                            {materialTypes.map((materialType, index) =>
                                <MenuItem key={materialType.id} value={index}>{materialType.materialType} ({materialType.currentStock})</MenuItem>
                            )}
                        </Select>
                    }
                },
                {title: 'Quantity', field: 'quantity'},
            ]}
            data={dataList}
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: isDesktop,
                
                pageSize:20,
                toolbar: true
            }}
            title='Materials'
            editable={{
                onRowDelete: onDeleteHandler,
                onRowUpdate: onEditHandler,
                onRowAdd: onCreateHandler
            }}
            components={{
                Pagination: props => (
                    <div className={classes.tableFooter}>
                        <Typography variant="h6" className={classes.tableFooterCaption}>
                            Total Quantity: {totalQuantity}
                        </Typography>
                        <TablePagination {...props} />
                    </div>
                ),
            }}
        />
    </div>;
};

export default FeedFormulationMaterialsView;