import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import { MuiPickersUtilsProvider,DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

let schema = {
    date: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    neccPrice: {
        presence: {allowEmpty: false, message: 'is required'}
    },
};

const useStyles = makeStyles(theme => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      color: theme.palette.text.default,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    datePicker: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth:160
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '2'
    },
}));

const DailyEggRate = ({deleteErrorMessage, errorMessage, onEdit, data, onSave, handleClose, showDialog, eggMaterials, editMode}) => {
    const classes = useStyles();
    const [popupLoading, setPopupLoading] = useState(false) ;
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
     const resetBeforeEdit = () => {
         
        if(showDialog){
            eggMaterials.forEach((element, index)=>{
                schema = {
                    ...schema,
                    [element.materialName]:{
                        presence: {allowEmpty: false, message: 'is required'}
                    }
                }
            })
            updateData(data, editMode);
        };
    }

    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        setPopupLoading(true) 
        for (var key in formState.values) {
            for(let index =0 ; index < eggMaterials.length; index++){
            //eggMaterials.forEach((element, index)=>{
                    if(key === eggMaterials[index].materialName){
                        eggMaterials[index].eggRate=formState.values[key];
                    }
                //})
            }
          }
        console.debug(eggMaterials)
        
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView()
        }
        if (editMode)
            onEdit({
               ...formState.values,
               eggRates:eggMaterials
            } , setPopupLoading);
        else
            onSave({
                ...formState.values,
                eggRates:eggMaterials
            } , setPopupLoading);
    };

    return <Dialog  disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle  id="form-dialog-title">{editMode?'Update':'New'} Daily Egg Rate
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker disabled
                className={classes.textField} 
                autoOk
                id="dates"
                variant="inline"
                inputVariant="outlined"
                label="Date"
                format="dd/MM/yyyy"
                value={formState.values.date || null}
                onChange={data => {
                    handleChange({target: {name: 'date', value: data}})
                }}
                animateYearScrolling
                name = "date"
                style={{backgroundColor:'#ffffff'}}
            />
            </MuiPickersUtilsProvider>
            
            <TextField disabled
                className={classes.textField}
                error={hasError('neccPrice')}
                fullWidth
                helperText={
                    hasError('neccPrice') ? formState.errors.neccPrice[0] : null
                }
                label="NECC Price"
                name="neccPrice"
                onChange={handleChange}
                type="number"
                value={formState.values.neccPrice  !== undefined ? formState.values.neccPrice : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            {eggMaterials !== undefined && eggMaterials !== null ?
            eggMaterials.map((element, index) => 
                <TextField disabled
                    key = {index}
                    className={classes.textField}
                    error={hasError(element.materialName)}
                    fullWidth
                    helperText={ hasError(element.materialName) ? formState.errors[element.materialName] : null}
                    label={element.materialName}
                    name={element.materialName}
                    onChange={handleChange}
                    type="number"
                    value={formState.values[element.materialName] !== undefined ? formState.values[element.materialName] : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}} />
                )
                :""
            }
            <DialogActions>
                <Button 
                    className={classes.signInButton}
                    color="primary"
                    disabled
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
                :
                <div>


          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                className={classes.textField} 
                autoOk
                id="dates"
                variant="inline"
                inputVariant="outlined"
                label="Date"
                format="dd/MM/yyyy"
                value={formState.values.date || null}
                onChange={data => {
                    handleChange({target: {name: 'date', value: data}})
                }}
                animateYearScrolling
                name = "date"
                style={{backgroundColor:'#ffffff'}}
            />
            </MuiPickersUtilsProvider>
            
            <TextField
                className={classes.textField}
                error={hasError('neccPrice')}
                fullWidth
                helperText={
                    hasError('neccPrice') ? formState.errors.neccPrice[0] : null
                }
                label="NECC Price"
                name="neccPrice"
                onChange={handleChange}
                type="number"
                value={formState.values.neccPrice  !== undefined ? formState.values.neccPrice : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            {eggMaterials !== undefined && eggMaterials !== null ?
            eggMaterials.map((element, index) => 
                <TextField
                    key = {index}
                    className={classes.textField}
                    error={hasError(element.materialName)}
                    fullWidth
                    helperText={ hasError(element.materialName) ? formState.errors[element.materialName] : null}
                    label={element.materialName}
                    name={element.materialName}
                    onChange={handleChange}
                    type="number"
                    value={formState.values[element.materialName] !== undefined ? formState.values[element.materialName] : ''}
                    variant="outlined"
                    style={{backgroundColor:"#ffffff"}} />
                )
                :""
            }
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>

}
        </DialogContent>
    </Dialog>
};

export default DailyEggRate;