import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from "../../../common/formValidator";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";

const schema = {
  amount: {
    presence: { allowEmpty: false, message: "is required" },
    numericality: {
      greaterThanOrEqualTo: 0,
    },
  },
  paymentDate: {
    presence: { allowEmpty: false, message: "is required" },
  },
  labourIndex: {
    presence: { allowEmpty: false, message: "is required" },
  },
  paymentMode: {
    presence: {allowEmpty: false, message: 'is required'},
    type: 'number',
},
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.text.default,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  progressBar: {
    margin: theme.spacing(2),
    position: "absolute",
    top: "50%",
    zIndex: "9999",
  },
}));

const LabourPayment = ({
  deleteErrorMessage,
  errorMessage,
  farms,
  Labours,
  onEdit,
  data,
  onSave,
  handleClose,
  showDialog,
  editMode = false,
  onPaymentDateChange,
  banks,
  farmID
}) => {
  const classes = useStyles();
  const [formState, hasError, handleChange, updateData] =
    useFromValidator(schema);
  const [popupLoading, setPopupLoading] = useState(false);
  const [showBanks, setShowBanks] = useState(false)
  //Called if the Farm Property is Set for Editing
  const resetBeforeEdit = () => {
    if (showDialog) {
      updateData(data, editMode);
    }
    if(editMode){
      if( data !== undefined && data.paymentMode !== undefined && data.paymentMode === 2){
          setShowBanks(true)
      }else{
          setShowBanks(false)
      }
  }
  };
  useEffect(resetBeforeEdit, [showDialog]);
  const isFormValid = () =>{
    //payment mode validation
    if (formState.values.paymentMode === 2) {
      return   banks.length > 0 && formState.values.bankIndex !==undefined && formState.values.bankIndex !== "" && formState.values.bankIndex !== -1
    }
    //labour and labour index validation
    return   Labours.length > 0 && formState.values.labourIndex!==undefined && formState.values.labourIndex !== ""
}

const handleChangePaymentMode = (event) => {
  if(event.target.name === "paymentMode" && event.target.value === 2){
      setShowBanks(true)
  }else{
      setShowBanks(false)
  }
}

  const onSubmit = () => {
    var errorElement = document.getElementById("error");
    if (errorElement) {
      errorElement.scrollIntoView();
    }
    const tempData = {
      ...formState.values,
      labourID: Labours[formState.values.labourIndex]?.id,
      labourName: Labours[formState.values.labourIndex]?.name,
      bankID: banks[formState.values.bankIndex]?.id,
      bankName: banks[formState.values.bankIndex]?.name,
      farmID:parseInt(farmID),
    };
    setPopupLoading(true);
    if (editMode) onEdit(tempData, setPopupLoading);
    else onSave(tempData, setPopupLoading);
  };

  return (
    <Dialog
      disableBackdropClick
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog- title">
        {editMode ? "Update" : "New"} Labour Payment
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {}

        <div>
          <div id="error">
            {errorMessage ? (
              <Chip
                onDelete={deleteErrorMessage}
                color="primary"
                label={errorMessage}
              />
            ) : (
              ""
            )}
          </div>
          {popupLoading ? (
            <div className={classes.progressContainer}>
              <CircularProgress className={classes.progressBar} />
            </div>
          ) : (
            ""
          )}
          <br />
          <MuiPickersUtilsProvider
            class={classes.datePicker}
            utils={DateFnsUtils}
          >
            <DatePicker
              autoOk
              halfWidth
              variant="inline"
              inputVariant="outlined"
              label="Date"
              format="dd/MM/yyyy"
              value={formState.values.paymentDate || null}
              onChange={(data) => {
                onPaymentDateChange(data);
                handleChange({ target: { name: "paymentDate", value: data } });
              }}
              animateYearScrolling
              name="date"
              style={{ backgroundColor: "#ffffff" }}
            />
          </MuiPickersUtilsProvider>

          <FormControl className={classes.formControl}>
            <InputLabel id="vendorName-select-outlined-label">
              Labour Name
            </InputLabel>
            <Select
              id="vendorName-select"
              value={
                formState.values.labourIndex !== undefined
                  ? formState.values.labourIndex
                  : ""
              }
              onChange={(data) => {
                handleChange(data);
              }}
              name="labourIndex"
            >
              {Labours !== undefined && Labours !== null
                ? Labours.map((labour, index) => (
                    <MenuItem key={labour.id} value={index}>
                      {labour.name}
                    </MenuItem>
                  ))
                : ""}
            </Select>
          </FormControl>

          <FormControl  className={classes.formControl}>
                <InputLabel id="paymentMode-select-outlined-label">
                Payment Mode
                </InputLabel>
                <Select
                id="paymentMode"
                value={formState.values.paymentMode || ''}
                onChange={event =>{handleChange(event,handleChangePaymentMode)}}
                name="paymentMode"
                >
                    <MenuItem key = {0} value={1}>Cash</MenuItem>
                    <MenuItem key = {1} value={2}>Bank</MenuItem>
                </Select>
            </FormControl>
            {showBanks ? 
            <FormControl className={classes.formControl}>
                <InputLabel id="banks-select-label">Banks</InputLabel>
                <Select
                id="banks-select"
                value={formState.values.bankIndex !== undefined ? formState.values.bankIndex : ''}
                onChange={handleChange}
                name="bankIndex"
                >
                {banks.map((bank,index)=>
                    <MenuItem key = {bank.id} value={index}>{bank.name}</MenuItem>
                )}
                </Select>
            </FormControl>
            :null}
          <TextField
            className={classes.textField}
            error={hasError("amount")}
            fullWidth
            helperText={hasError("amount") ? formState.errors.amount[0] : null}
            label="Amount (in Rs.)"
            name="amount"
            onChange={handleChange}
            type="number"
            value={formState.values.amount || ""}
            variant="outlined"
            style={{ backgroundColor: "#ffffff" }}
          />
          <DialogActions>
            <Button
              className={classes.signInButton}
              color="primary"
              disabled={!isFormValid() || !formState.isValid}
              size="large"
              type="submit"
              variant="contained"
              onClick={onSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LabourPayment;
