import React, { useEffect, useState, useRef } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { MaterialTable } from '../../../components';
import Grid from '@material-ui/core/Grid';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../../state';
import { generateExcel} from '../../../common/export';

const useStyles = makeStyles(theme => ({
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    downloadPdfButton: {
        margin: theme.spacing(2, 0, 2, 2),
    },
    totalTable: {
        float: 'right',
        width: theme.spacing(44.5)
    },
    totalCell: {
        width: theme.spacing(16.37),
        paddingLeft: theme.spacing(8.125)
    },
}));

const tableHeader = JSON.parse(`[["Material Name","Quantity","Rate", "Sum"]]`);


const FeedMaterials = ({ showFeedMaterialsDialog, handleFeedMaterialsClose, data }) => {
    const classes = useStyles();
    // eslint-disable-next-line
    const [feedMaterials, setFeedMaterials] = useState([])
    const resetBeforeEdit = () => {
        console.debug("data fedmarterials ", data)
        if (showFeedMaterialsDialog) {
            if (data.feedMaterial !== undefined && data.feedMaterial !== null) {
                setFeedMaterials([...data.feedMaterial,
                {
                    id: -1,
                    name: "Total",
                    rate: "",
                    quantity: "",
                    sum: data.feedCost
                }]);
            }
        }
    };
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }

    const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
      });

    useEffect(resetBeforeEdit, [showFeedMaterialsDialog]);

    return <Dialog disableBackdropClick open={showFeedMaterialsDialog} onClose={handleFeedMaterialsClose} aria-labelledby="form-dialog-title">
        <DialogContent>
            <Grid item>
                <Button variant="outlined" color="primary" className={classes.signInButton}
                    onClick={() => generateExcel(tableHeader, feedMaterials)}
                    endIcon={<GetAppIcon />}>Download Excel</Button>

                <Button variant="outlined" color="primary" className={classes.downloadPdfButton}
                    onClick={generatePDF} endIcon={<PictureAsPdfIcon />}>Download
                        PDF</Button>
            </Grid>
            <div id='report-container' className={classes.reportContainer} ref={componentRef}>
                <MaterialTable
                    columns={[
                        { title: 'id', field: 'id', hidden: true },
                        {
                            title: 'Material Name', field: 'name', editable: 'never',
                            render: rowData => {
                                return <span style={rowData.name === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                                    {rowData.name}
                                </span>
                            }
                        },
                        {
                            title: 'Quantity', field: 'quantity', editable: 'never',
                            render: rowData => {
                                return <span>{rowData.quantity !== "" ? (rowData.quantity).toFixed(2) : ""}</span>
                            }
                        },
                        {
                            title: 'Rate', field: 'rate', editable: 'never',
                            render: rowData => {
                                return <span>
                                    {rowData.rate !== "" ?'':''}
                                    {rowData.rate !== "" ? (rowData.rate).toLocaleString(user.locales,amountObj) : ""}</span>
                            }
                        },
                        {
                            title: 'Cost', field: 'sum', editable: 'never',
                            render: rowData => {
                                return <span style={rowData.name === "Total" ? { fontWeight: 'bold' } : { fontWeight: 'unset' }}>
                                    {rowData.sum !== undefined ?'':''}
                                    {rowData.sum !== undefined ? (rowData.sum).toLocaleString(user.locales,amountObj) : ""}
                                </span>
                            }
                        },
                    ]}
                    data={feedMaterials || []}
                    title='Feed Materials Table'
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        
                        paging: false

                    }}
                />
            </div>
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={handleFeedMaterialsClose}
                >
                    Close
            </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
};

export default FeedMaterials;