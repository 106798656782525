import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
// import useFromValidator from '../../../common/formValidator';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Select, MenuItem } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

// const schema = {
//     trialDate: {
//         presence: {allowEmpty: false, message: 'is required'}
//     }
// };

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        display: 'flex',
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },
}));

const ExtendDemo = ({ deleteErrorMessage, errorMessage, data, versions, onExtendDemo, handleClose, showDialog, editMode = true }) => {
    const classes = useStyles();
    // const [formState, updateData] = useFromValidator(schema);
    const [valueDate, setValueDate] = useState(null);
    const [isErr, setIsErr] = useState(false)
    const [errMsg, setErrMsg] = useState('');
    const [popupLoading, setPopupLoading] = useState(false);
    const [accountType, setAccountType] = useState(0);

    let version_Id = data?.versionId
    let trial_date = data?.trialDate.Time
    useEffect(()=>{
        setAccountType(version_Id)
        setValueDate(trial_date)
    },[version_Id,trial_date])
    
    
    const handleChangeType = (event) => {
        setAccountType(event.target.value);
    };
    const handleChange = (event) =>{
        setValueDate(event)
    };

    const resetBeforeEdit = () => {
        if(showDialog){
            setPopupLoading(false);
            if(data.trialDate.Valid){
                data = {
                    ...data,
                    trialDate : data.trialDate.Time
                } 
            }else{
                data = {
                    ...data,
                    trialDate : null
                } 
                editMode = false
            }
            // updateData(data, editMode);
        }
    };
    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        let bool = accountType===data.versionId && moment(valueDate).format('LL')===moment(data.trialDate.Time).format('LL');
        if(bool){
            setIsErr(true);
            setErrMsg("Please modify anything to update");
        }
        else{
            let tempData={};
            if(accountType!==data.versionId && moment(valueDate).format('LL')!==moment(data.trialDate.Time).format('LL')){
                tempData = {
                    trialDate: {
                        Time: valueDate,
                        Valid: true
                    },
                    versionId: accountType ? accountType : data?.versionId,
                    versionName: versions.find(e => e.versionId === (accountType ? accountType : data?.versionId))?.versionName 
                };
            }
            else if(accountType!==data.versionId){
                tempData = {
                    versionId: accountType ? accountType : data?.versionId,
                    versionName: versions.find(e => e.versionId === (accountType ? accountType : data?.versionId))?.versionName 
                };
            }
            else if(moment(valueDate).format('LL')!==moment(data.trialDate.Time).format('LL')){
                tempData = {
                    trialDate: {
                        Time: valueDate,
                        Valid: true
                    }
                };
            }
            // updateData({}, false);
            onExtendDemo(tempData);
            var errorElement = document.getElementById("error");
            if (errorElement) {
                errorElement.scrollIntoView();
            }
        }
    };

    const removeErrMsg = () =>{
        setErrMsg('');
        setIsErr(false)
    }

    const onClose = () =>{
        // updateData({}, false);
        handleClose()
    }


    return <Dialog disableBackdropClick open={showDialog} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"> Actions
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
          <DialogContent>
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker disabled
                    className={classes.textField}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Trail Date"
                    format="dd/MM/yyyy"
                    minDate={data?.trialDate.Time}
                    value={valueDate!==null ? valueDate : data?.trialDate.Time}
                    onChange={event => { handleChange(event) }}
                    animateYearScrolling
                    name="trialDate"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
                :
                <div>
          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
          </div>
          <div id="error">
          {isErr ? <Chip onDelete={removeErrMsg} color="primary" label={errMsg}/> : ''}
          </div> 
         <br/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    className={classes.textField}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Trail Date"
                    format="dd/MM/yyyy"
                    minDate={data?.trialDate.Time}
                    value={valueDate!==null ? valueDate : data?.trialDate.Time}
                    onChange={event => { handleChange(event) }}
                    animateYearScrolling
                    name="trialDate"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="planType-select-outlined-label">
                                Account Type
                            </InputLabel>
                            <Select
                                id=""
                                value={accountType!==0 ? accountType : data?.versionId}
                                onChange={event => handleChangeType(event) }
                                name="accountType"
                            >
                                {versions!== undefined && versions!== null?
                                    versions.map((version) => (
                                        <MenuItem key={version.versionId} value={version.versionId} >{version.versionName}</MenuItem>
                                    ))
                                    :
                                    ""
                                }
                            </Select>
                        </FormControl>
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    // disabled={isSubmitDisabled}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
          }
        </DialogContent>
    </Dialog>
};

export default ExtendDemo;