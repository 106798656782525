import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {MaterialTable} from '../../../components';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from 'moment';


const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    paper: { 
        minWidth: "1024px", 
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            minWidth: '90vw'
          },
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    gridMargin :{
        margin: theme.spacing(2),
    },
    Accstatus:{
        textAlign: "center", 
        margin: theme.spacing(2),
        minWidth: 'fit-content'
    },
    redChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#f44336',
        backgroundColor: 'rgba(244, 67, 54, 0.08)',
    },
    greenChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#4caf50',
        backgroundColor: 'rgba(76, 175, 80, 0.08)'
    }
})); 

const SubscriptionDetails = ({subscriptions, handleClose, showDialog}) => {
    
    const classes = useStyles();
    const [listData, setListData] = useState([])
    const resetBeforeEdit = () => {
        if (subscriptions.userSubscriptions ) {
            setListData(subscriptions.userSubscriptions)
        }
    };

    useEffect(resetBeforeEdit, [showDialog]);


    return <Dialog disableBackdropClick classes={{ paper: classes.paper}} open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"> Account Details
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        
          <DialogContent> 
          <Grid
          
                        container
                        justify='space-between'
                        alignItems='flex-end'
                    >
                    <Grid item class={classes.gridMargin}>
                    <Typography variant="h5" component="h2">
                        {subscriptions !== undefined && subscriptions.Name !== undefined ? subscriptions.Name : ""}
                    </Typography>
                    <Typography color="textSecondary">
                        Name
                    </Typography>
                </Grid>
                    <Grid item class={classes.gridMargin}>
                    <Typography variant="h5" component="h2">
                        {subscriptions !== undefined && subscriptions.Email !== undefined ? subscriptions.Email : ""}
                    </Typography>   
                    <Typography color="textSecondary">
                        Email
                    </Typography>
                    
                </Grid>
                 <Grid item class={classes.gridMargin}>
                    <Typography variant="h5" component="h2">
                        {subscriptions !== undefined && subscriptions.phoneNumber !== undefined ? subscriptions.phoneNumber : ""}
                    </Typography>   
                    <Typography color="textSecondary">
                        Phone Number
                    </Typography> 
                    
                </Grid> 
                <Grid item class={classes.gridMargin}>
                    <Typography variant="h5" component="h2">
                        {subscriptions !== undefined && subscriptions.userCount !== undefined ? subscriptions.userCount : ""}
                    </Typography>   
                    <Typography color="textSecondary">
                        User Count
                    </Typography> 
                    
                </Grid> 
                <Grid item class={classes.gridMargin}>
                    <Typography variant="h5" component="h2">
                        {subscriptions !== undefined && subscriptions.birdCount !== undefined ? subscriptions.birdCount : ""}
                    </Typography>   
                    <Typography color="textSecondary">
                        Bird Count
                    </Typography> 
                    
                </Grid> 
             <Grid item class={classes.gridMargin}>
                    <Typography variant="h5" component="h2">
                        {subscriptions.registeredDate ? moment(subscriptions.registeredDate.Time).format("DD-MM-YYYY") : ""}
                    </Typography>   
                    <Typography color="textSecondary">
                    Registered Date
                    </Typography>
                </Grid> 
                <Grid item class={classes.Accstatus}>
                    <Typography variant="h5" component="h2">
                    {
                    subscriptions !== undefined && subscriptions.isActive !== undefined 
                    ? 
                        subscriptions.isActive===1 
                        ? 
                            <div className={classes.greenChip}>
                                Active
                            </div>
                        :
                            <div className={classes.redChip}>
                            Not Active
                            </div>
                    :""
                    }
                    </Typography>   
                    <Typography color="textSecondary">
                    Account Status
                    </Typography>
                </Grid> 
                <Grid item class={classes.gridMargin}>
                    <Typography variant="h5" component="h2">
                        {subscriptions.versionName ? subscriptions.versionName : ""}
                    </Typography>   
                    <Typography color="textSecondary">
                    Account Type
                    </Typography>
                </Grid> 
                <Grid item class={classes.gridMargin}>
                    <Typography variant="h5" component="h2">
                        {subscriptions.countryName ? subscriptions.countryName : ""}
                    </Typography>   
                    <Typography color="textSecondary">
                    Country
                    </Typography>
                </Grid> 
                
                <Grid item class={classes.gridMargin}>
                    <Typography variant="h5" component="h2" >
                        {
                        subscriptions.trialDate && subscriptions.trialDate.Time!=="0001-01-01T00:00:00Z" 
                         ?  
                            moment(subscriptions.trialDate.Time).format("DD-MM-YYYY") :""
                        }
                    </Typography>   
                    <Typography color="textSecondary">
                    Trial End Date
                    </Typography>
                </Grid> 
                <Grid item class={classes.gridMargin}>
                    <Typography variant="h5" component="h2">
                        {subscriptions.designation ? subscriptions.designation : ""}
                    </Typography>   
                    <Typography color="textSecondary">
                    Designation
                    </Typography>
                </Grid> 
                    </Grid>
            <MaterialTable  
                columns={[
                    {title: 'id', field: 'accountID', hidden: true},
                    {title: 'Active', field: 'isActive', editable: 'never',
                    defaultSort:'desc',
                    render: rowData => {
                        return <span>{
                            rowData.isActive === 1 ?  <CheckCircleOutlineIcon 
                            style={{color: '#4caf50'}}/> : ""
                            }</span>
                    }},
                    {title: 'Plan Name', field: 'tier', editable: 'never'},
                    {title: 'Start Date', field: 'startDate', editable: 'never'},
                    {title: 'End Date', field: 'endDate', editable: 'never'},
                ]}
                    data={listData[0] !== undefined && listData[0].id > 0 ? listData : []}
                    title='Subscription History'
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        toolbar: true
                    }}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    size="large"
                    type="onClose"
                    variant="contained"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
};

export default SubscriptionDetails;