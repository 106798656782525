import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, Chip, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import api from '../../../common/api';
import { calculateEnergyData, formatEnergyProfileValue } from '../utils/nutritionCalculations';
import { minChipLabel } from '../utils/constants';

const EnergyProfileDisplay = ({ rowData, materialNutritionMap, updatedFeedFormulationMaterial }) => {
    const initialEnergyValues = {
        metabolizableEnergy: {
            name: "Metabolizable Energy",
            shortName:'ME',
            value: 0
        },
        protein: {
            name: "Protein",
            shortName: "Pr",
            value: 0
        },
        fat: {
            name: "Fat",
            shortName: "Fa",
            value: 0
        },
        fibre: {
            name: "Fibre",
            shortName: "Fi",
            value: 0
        },
        calcium: {
            name: "Calcium",
            shortName: "Ca",
            value: 0
        },
        phosphorus: {
            name: "Phosphorus",
            shortName: "P",
            value: 0
        },
        lysine: {
            name: "Lysine",
            shortName: "Ly",
            value: 0
        },
        methionine: {
            name: "Methionine",
            shortName: "Me",
            value: 0
        }
    }

   const [energyValues, setEnergyValues] = useState(initialEnergyValues)
   const [showEnergyProfile, setShowEnergyProfile] = useState(false)
   const [materials, setMaterials] = useState([])

   const materialsRef = useRef();

   useEffect(() => {
    materialsRef.current = materials;
}, [materials]);
   
   const getMaterialData = useCallback(() => {
    api.get(`feed-formulation/${rowData.id}/feed-formulation-material`)
        .then(materials => { 
            if (materials !== null && materials !== undefined && materials.length) {
                setMaterials(materials);
            }
        })
        .catch(err => {
            console.error(err);
        });
}, [rowData.id, setMaterials]);

   const setEnergyData = () => {
      if(materials.length === 0){
          setEnergyValues(initialEnergyValues);
          return;
      }
      setEnergyValues(calculateEnergyData({ data: materials, energyValues, materialNutritionMap }));
  
      // checking all energyValues are zero
      // calculate total energy, if it is not zero then show 3 items
      const totalEnergy = Object.keys(energyValues).reduce((accumulator, currentKey) => {
          return accumulator + energyValues[currentKey].value;
      }, 0);
      if (totalEnergy > 0) {
         setShowEnergyProfile(true);
      }else{
         setShowEnergyProfile(false);
      }
   }
  
   useEffect(setEnergyData, [materials]);
   
   useEffect(()=>{
        // Check if it's the first render by verifying if updatedFeedFormulationMaterial.feedFormulationId is null
        // or if updatedFeedFormulationMaterial belongs to the same feedFormulationId
       if (updatedFeedFormulationMaterial.feedFormulationId === null || 
          (updatedFeedFormulationMaterial.feedFormulationId === rowData.id && updatedFeedFormulationMaterial.materialData !== materialsRef.current)) {
            getMaterialData();
       }
   }, [rowData, updatedFeedFormulationMaterial, getMaterialData]);

   const EnergyTooltip = withStyles((theme) => ({
       tooltip: {
           backgroundColor: '#f7f7f8',
           padding: theme.spacing(2),
           // add some box shadow, there is no shadow in 'theme'
           boxShadow: `0px 0px 2px 0px ${theme.palette.primary.main}`,
       },
       arrow: {
           '&::before': {
               color: '#f7f7f8',
               boxShadow: `0px 0px 2px 0px ${theme.palette.primary.main}`,
           }
       },
   }))(Tooltip);

   return (
       <Grid container spacing={1} justifyContent='space-between' wrap='nowrap'>
         {showEnergyProfile ?
            <>
            {Object.keys(energyValues).map((key, index) => {
               if(index < minChipLabel){
                   return (
                       <Grid item key={index}>
                           <Chip 
                               size="small"
                               color="primary"
                               label={`${energyValues[key].shortName}: ${formatEnergyProfileValue(energyValues[key].value)}`} 
                           />
                       </Grid>
                   )
               }
               return null;
           })}
           <EnergyTooltip 
               arrow={true}
               title = {
                   <Grid container spacing={1}>
                       {Object.keys(energyValues).map((key, index) => {
                           return (
                               <Grid item key={index}>
                                   <Chip 
                                       size="small"
                                       color="primary"
                                       label={`${energyValues[key].name}: ${formatEnergyProfileValue(energyValues[key].value)}`} 
                                   />
                               </Grid>
                           )
                       })}
                   </Grid>
               }
           >
               <Grid item>
                   <Chip size="small" color="primary" label={`+ ${Object.keys(energyValues).length - minChipLabel}`} />
               </Grid>
           </EnergyTooltip></>
         
         : <Typography variant="body1" color="textPrimary">No Energy Data</Typography>
         }

       </Grid>
   );
};

export default EnergyProfileDisplay;