import React, {useEffect,useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button"; 
import OtherExpensePayment from "./otherExpensePayment";
import {MaterialTable} from '../../../components';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(2, 0)
    }
}));

let currentPayment = undefined
let editPaymentMode = false
const OtherExpensePaymentDetails = ({onPaymentEdit, data ,banks,   handlePaymentDetailsClose, showPaymentDetailsDialog, onOtherExpensePaymentSave,
     onOtherExpensePaymentDelete, loading}) => {
    const classes = useStyles();
    const [showPaymentDialog, setShowPaymentDialog] = useState(false)
    const [otherExpensePayments, setOtherExpensePayments] = useState([])

     const reset = () => {
        console.debug(data)
        if(showPaymentDetailsDialog && data !== undefined && data.otherExpensePayments !== undefined && data.otherExpensePayments !== null){
            console.debug(data)
            setOtherExpensePayments(data.otherExpensePayments);
        }else{
            setOtherExpensePayments([])
        }
    };
    useEffect(reset, [showPaymentDetailsDialog,data]);


    function onNewPayment(event) {
        editPaymentMode = false;
        event.preventDefault();
        setShowPaymentDialog(true);
    } 

    function onEdit(rowData) {
        rowData = {
            ...rowData,
            bankIndex:banks.findIndex((bank)=>{return bank.id === rowData.paymentBankId;})
        };
        currentPayment = rowData
        editPaymentMode = true
        setShowPaymentDialog(true);
    }

    function onPaymentDelete(rowData) {
        console.debug(rowData,otherExpensePayments)
        let index = otherExpensePayments.findIndex((srcOtherExpensePayment) => {
            return srcOtherExpensePayment.id === rowData.id;
        });
        let newOtherExpensePayments = otherExpensePayments;
        newOtherExpensePayments.splice(index, 1); 
        console.debug(newOtherExpensePayments)
        setOtherExpensePayments(newOtherExpensePayments)
        let tempCurrentData = {
            ...data,
            otherExpensePayments :newOtherExpensePayments
        }
        onOtherExpensePaymentDelete(rowData, tempCurrentData)
        console.debug(otherExpensePayments)
    }


    function onPaymentSave(payment) {
        console.debug(payment,data)
        console.debug(otherExpensePayments)
        if(editPaymentMode){
            let index = otherExpensePayments.findIndex((srcPayement) => {
                return srcPayement.id === payment.id;
            });
            setOtherExpensePayments([...otherExpensePayments.slice(0, index),
                {...payment},
                ...otherExpensePayments.slice(index + 1)])
    
            let tempCurrentData ={
                ...data,
                otherExpensePayments :[...otherExpensePayments.slice(0, index),
                    {...payment},
                    ...otherExpensePayments.slice(index + 1)]
            }

            onPaymentEdit(payment, tempCurrentData)
        }else{
            payment = {
                ...payment,
                otherExpenseId : data.id
            }
            onOtherExpensePaymentSave(payment)
        }
        setShowPaymentDialog(false);
        console.debug(otherExpensePayments)
    }


    function handlePaymentClose() {
        currentPayment = undefined;
        setShowPaymentDialog(false);
    }

    let actions = [
        rowData =>({
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit otherExpense Details',
            onClick: (event, rowData) => onEdit(rowData),
            hidden:rowData.paymentNote === "Bulk Payment" ? true : false
        }),
        rowData =>({
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete otherExpense Details',
            onClick: (event, rowData) => onPaymentDelete(rowData),
            hidden:rowData.paymentNote === "Bulk Payment" ? true : false
        })
    ]
    return <Dialog maxWidth='md' open={showPaymentDetailsDialog} onClose={handlePaymentDetailsClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >OtherExpense Payments</DialogTitle>
          <DialogContent  >
            <div className={classes.button} >
                <Button variant="contained" color="primary" onClick={onNewPayment}>Add Payment</Button>
            </div>

            <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'id', hidden: true},
                {title: 'Payment Date', field: 'paymentDate',  editable: 'never',
                render: rowData => {
                    return <span>{moment(rowData.paymentDate).format("DD-MM-YYYY")}</span>
                }
                },
                {title: 'Payment Amount', field: 'paymentAmount',  editable: 'never'},
                {title: 'Payment Mode', field: 'paymentMode', editable: 'never',
                render: rowData => {
                    return <span>{rowData.paymentMode === 0 ? '' : (rowData.paymentMode === 1 ? 'Cash' : rowData.paymentBankName.String)}</span>
                }
                },
                {title: 'paymentBankId', field: 'paymentBankId', editable: 'never', hidden: true},
                {title: 'Payment Note', field: 'paymentNote', editable: 'never'},
            ]}
            data={otherExpensePayments}
            options={{
                actionsColumnIndex: -1,
                search: false,
                showTitle: false,
                
                toolbar: false,
                paging:false
            }} 
            //style={{boxShadow: 'unset'}}
            actions={actions}
            /> 

 {showPaymentDialog ? 

                 <OtherExpensePayment banks={banks} data={currentPayment} onSave={onPaymentSave} handlePaymentClose={handlePaymentClose} 
                showPaymentDialog={showPaymentDialog} editMode={editPaymentMode} >
                </OtherExpensePayment>  

                : null
            }
            <DialogActions>
                <Button
                    className={classes.button}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handlePaymentDetailsClose}
                >
                    Close
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
};

export default OtherExpensePaymentDetails;