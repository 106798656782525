/*
 Global State Management - Using React Context.
 Refer to https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c
*/

import React, {createContext, useContext, useReducer} from 'react';
import PropTypes from 'prop-types';

export const StateContext = createContext();

export const StateProvider = ({reducer, initialState, children}) => (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </StateContext.Provider>
);

StateProvider.propTypes = {
    /**
     * @return {React.ReactNode}
     */
    children: PropTypes.node.isRequired,

    /**
     * Object containing initial state value.
     */
    initialState: PropTypes.shape({}).isRequired,

    /**
     *
     * @param {object} state
     * @param {object} action
     */
    reducer: PropTypes.func.isRequired
};

export const useGlobalState = () => useContext(StateContext);