import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from "../../components";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExtendDemo from "./components/extendDemo";
import SubscriptionDetails from "./components/subscriptionDetails";
import PlansCRUD from "./components/plansCRUD";
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    redChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#f44336',
        backgroundColor: 'rgba(244, 67, 54, 0.08)',
    },
    greenChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#4caf50',
        backgroundColor: 'rgba(76, 175, 80, 0.08)'
    }
}));

let currentSubscriber = undefined;
let editMode = false;
let currentPlan = undefined;

const subscriptionsState = {
  Name: "",
  Email: "",
  phoneNumber:"",
  versionName:"",
  countryName:"",
  designation:"",
  registeredDate:null,
  trialDate:null,
  userSubscriptions: [{
    id: 0, 
    subscriptionID: 0,
    tier: "",
    isActive: 0,
    startDate: "",
    endDate: ""
  }]
}

const SubscribersView = ({showError, showMessage, history}) => {

    const [subscribers, setSubscribers] = useState([]);
    const [subscriptions, setSubscriptions] = useState(subscriptionsState);
    const [loading, setLoading] = useState(false);
    const [showSubscriberDialog, setShowSubscriberDialog] = useState(false);
    const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [plans, setPlans] = useState([]);
    const [showPlanDialog, setShowPlanDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [versions, setVersions] = useState([]);
    const [paymentSite,setSite] = useState("");

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});


    function handleSubscriberClose() {
        currentSubscriber = undefined;
        setErrorMessage(null);
        setShowSubscriberDialog(false);
    }

    function handleSubscriptionClose() {
        currentSubscriber  = undefined;
        setShowSubscriptionDialog(false);
    }

    //Get all versions API Call
    const getAllVersions = () => {
      const response = api.get('getAllVersions')
      response.then(res => {
          setVersions(res)
      }).catch(err => {
          if (err.message) {
              if (err.message === 'Bad Request' || err.message === 'No data found')
                  showError('No Data Found');
              else
                  showError(err.message)
          } else {
              showError('Unknown Error')
          }
      })
      return response;
  }

    const onSubscriberEdit = (subscriber) => {
        currentSubscriber = {
            ...subscriber,
        };
        if(versions.length===0){
          getAllVersions();
        }
        editMode = true;
        setShowSubscriberDialog(true);
        
    };

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onExtendDemo = (subscriber) => {
      showError('');
      const response = api.post(`accountDetailsUpdate`, {
          ...subscriber,
          accountID : currentSubscriber.accountID
      });
      response.then(() => {
          let index = subscribers.findIndex((srcSubscriber) => {
              return srcSubscriber.accountID === currentSubscriber.accountID;
          });
          setSubscribers(prevState=>{
            if(subscriber.versionId && subscriber.trialDate){
              return [...prevState.slice(0, index),
                {
                    ...prevState[index],
                    trialDate:subscriber.trialDate,
                    versionId:subscriber.versionId,
                    versionName:subscriber.versionName,
                },
                ...prevState.slice(index + 1)]
            }
            if(subscriber.versionId){
              return [...prevState.slice(0, index),
                {
                    ...prevState[index],
                    versionId:subscriber.versionId,
                    versionName:subscriber.versionName,
                },
                ...prevState.slice(index + 1)]
            }
            else if(subscriber.trialDate){
              return [...prevState.slice(0, index),
                {
                    ...prevState[index],
                    trialDate:subscriber.trialDate,
                },
                ...prevState.slice(index + 1)]
            }
          })
          setShowSubscriberDialog(false);
      }).catch(err => {
          if (err.message) {
              if (err.message === 'Bad Request' || err.message === 'No data found')
                  setErrorMessage('Subscriber not Updated');
              else
                  setErrorMessage(err.message)
          } else {
              setErrorMessage('Unknown Error')
          }
      }).finally(()=>{
          currentSubscriber = undefined;
          setLoading(false);
      })
  };

    const getSiteName = ()=>{
      const response = api.get('getPaymentSiteName');
      response.then(res => {
        setSite(res.site)
    }).catch(err => {
        if (err.message) {
            if (err.message === 'Bad Request' || err.message === 'No data found')
                showError('No Data Found');
            else
                showError(err.message)
        } else {
            showError('Unknown Error')
        }
    })
    return response;
    };

    //Get all Subscribers API Call
    const getAllSubscribers = () => {
        setShowSubscriberDialog(false);
        showError('');
        showMessage('');

        const response = api.get('getAllSubscribers');

        response.then(res => {
            setSubscribers([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Data Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }


    //Get all getAllSubscriptions API Call
    const getAllSubscriptions = (event, rowData) => {
        showError('');
        showMessage('');
        setLoading(true)
        

        const response = api.get(`getAllSubscriptions/${rowData.accountID}`);

        response.then(res => {
            setSubscriptions(res);
            currentSubscriber = rowData;
            setShowSubscriptionDialog(true);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Data Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(()=>{
            setLoading(false)
        })
        return response;
    }

    
    const onload=()=>{
        setLoading(true);
        Promise.all([getAllSubscribers(),getAllPlans(),getAllVersions(),getSiteName()]).finally(()=>{
            setLoading(false);
        });
    }
    useEffect(onload, []);
    
    let actions =  [
            {
                icon: () => <Icon color='primary'>edit trail date</Icon>,
                tooltip: 'Actions',
                onClick: (event, rowData) => onSubscriberEdit(rowData)
            }
        ]

    let deleteactions =  [
        {
            icon: () => <Icon color="error">delete</Icon>,
            tooltip: "Delete Data",
            onClick: (event, rowData) => onDelete(rowData),
          }
    ]

    const onDelete = (plan) => {
        currentPlan = plan;
        setShowDeleteConfirm(true);
    };

    function onNewPlan(event) {
        editMode = false;
        event.preventDefault();
        setShowPlanDialog(true);
    }
    
    function handleModalClose() {
        currentPlan = undefined;
        setErrorMessage(null);
        setShowPlanDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
      }

    const onDataSave = (plan, setpopuploading) => {
        //setLoading(true);
        // showError("");
        showMessage("");
        const response = api.post("plan", {
          ...plan,
        });
    
        response
          .then((res) => {
            setPlans([...plans, res]);
            setShowPlanDialog(false);
          })
          .catch((err) => {
            if (err.message) {
              if (err.message === "Bad Request" || err.message === "No data found")
                setErrorMessage("Data not added");
              else setErrorMessage(err.message);
            } else {
              setErrorMessage("Unknown Error");
            }
          })
          .finally(() => {
            setLoading(false);
            setpopuploading(false);
          });
      };

      const onDataUpdate = (plan, setpopuploading) => {
        const response = api.post(
          `plan/${currentPlan.id}`,
          {
            ...plan,
          }
        );
        response
          .then(() => {
            let index = plans.findIndex(
              (srcPlan) => {
                return srcPlan.id === plan.id;
              }
            );
            setPlans([
              ...plans.slice(0, index),
              { ...plan },
              ...plans.slice(index + 1),
            ]);
            setShowPlanDialog(false);
          })
          .catch((err) => {
            if (err.message) {
              if (err.message === "Bad Request" || err.message === "No data found")
              setErrorMessage("Data not Updated");
              else setErrorMessage(err.message);
            } else {
              setErrorMessage("Unknown Error");
            }
          })
          .finally(() => {
            currentPlan = undefined;
            setLoading(false);
            setpopuploading(false);
          });
      };

      const getAllPlans = () => {
        //setLoading(true);
        showError('');
        const response = api.get('subscriptionplan');
        response.then(res => {
            setPlans([...res]);
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Plans Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        return response
    };

    const onPlanDeleteConfirm = () => {
        if (currentPlan && currentPlan.id) {
          setShowDeleteConfirm(false);
          setLoading(true);
          showError("");
          const response = api.delete(
            `plan/${currentPlan.id}`
          );
    
          response
            .then((res) => {
             
              if (res) {
                let index = plans.findIndex(
                  (srcPlan) => {
                    return srcPlan.id === currentPlan.id;
                  }
                );
                let newPlan = [...plans];
                newPlan.splice(index, 1);
                setPlans(newPlan);
              }
            })
            .catch((err) => {
              if (err.message) {
                if (
                  err.message === "Bad Request" ||
                  err.message === "No data found"
                )
                  showError("Plan not Deleted");
                else showError(err.message);
              } else {
                showError("Unknown Error");
              }
            })
            .finally(() => {
              setLoading(false);
              currentPlan = undefined;
            });
        }
      };

    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}
        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Cpanel
                </Typography>
            </Grid>
            <Grid>
            
          <Button
            variant="contained"
            color="primary"
            onClick={()=> window.open(paymentSite+"/subscriptions")}
          >
            Chargebee Subscriptions page
          </Button>
        </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'accountID', hidden: true},
                {title: 'Name', field: 'accountName',  editable: 'never'},
                {title: 'Account Type', field:'versionName',editable: 'never'},
                {title: 'Email', field: 'accountEmail',  editable: 'never'},
                {title: 'Bird Count', field: 'birdCount',  editable: 'never',
              render: rowData => {
                return <span>
                  {rowData.birdCount ? rowData.birdCount : 0}
                </span>
              }},
              {title: 'User Count', field: 'userCount',  editable: 'never',
              render: rowData => {
                return <span>
                  {rowData.userCount ? rowData.userCount : 0}
                </span>
              }},
                {title: 'Trial End Date', field: 'trialDate', type: 'datetime', editable: 'never', 
                    render: rowData => {
                        return <span>{
                            rowData.trialDate.Valid ? moment(rowData.trialDate.Time).format("DD-MM-YYYY") : ""
                            }</span>
                    }
                },
                {title: 'Account Status', field: 'isActive',  editable: 'never',
                    render: rowData => {
                        let label
                        let bgColor = classes.orangeChip
                        if (rowData.subscriberStatus === 1) {
                            label = "Active"
                            bgColor = classes.greenChip
                        } else {
                            label = "Not Active"
                            bgColor = classes.redChip
                        } 
                        return <div style={{ textAlign: "center", minWidth: 'fit-content' }} className={bgColor}
                        >{label}</div>
                    }
                },
            ]}
            data={subscribers}
            onRowClick={getAllSubscriptions}
            title='Accounts'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,  
                toolbar: true,
            }}
            actions={actions}
        />
        <br></br>
        <br></br>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}
        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Plans
                </Typography>
            </Grid>
            <Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={onNewPlan}
          >
            Add New Plan
          </Button>
        </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'id', hidden: true},
                {title: 'Plan Name', field: 'tier',  editable: 'never'},
                {title: 'Plan Amount', field: 'planAmount',  editable: 'never',render:(planData)=>{
                  if (planData["usd"] === undefined) {
                    return planData['planAmount'];
                  }
                  return planData['planAmount']+" ($"+planData["usd"]+")";
                }},
                {title: 'Minimum Bird Limit', field: 'birdCountMin',  editable: 'never'},
                {title: 'Maximum Bird Limit', field: 'birdCountMax',  editable: 'never'},
                {title: 'Plan Type', field: 'planType',  editable: 'never'},
                {title: 'Version', field: 'version_name',  editable: 'never'},
            ]}
            data={plans}
            title='Plans Table'
            options={{
                actionsColumnIndex: -1,
                search: false,
                showTitle: false,
                toolbar: false
            }}
            actions={deleteactions}
        />
        <ExtendDemo deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} data={currentSubscriber} versions={versions} showDialog={showSubscriberDialog} handleClose={handleSubscriberClose}
                onExtendDemo={onExtendDemo} editMode={editMode} />

        <SubscriptionDetails data={currentSubscriber} showDialog={showSubscriptionDialog} handleClose={handleSubscriptionClose}
                subscriptions={subscriptions}/>
        <PlansCRUD
        deleteErrorMessage={removeErrorMessage}
        errorMessage={errorMessage}
        showDialog={showPlanDialog}
        handleClose={handleModalClose}
        showError={showError}
        onSave={onDataSave}
        onEdit={onDataUpdate}
        editMode={editMode}
        data={currentPlan}
        versions={versions}
      />
      <ConfirmDialog
        showDialog={showDeleteConfirm}
        handleClose={handleDeleteConfirmClose}
        onConfirm={onPlanDeleteConfirm}
        title="Delete Data ?"
        subText="This action will delete the Plan. Please confirm."
      />
    </div>
}

export default SubscribersView;