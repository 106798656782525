import React,{useState,useEffect} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from "@material-ui/core/Typography";
import { Divider } from '@material-ui/core';
import api from "../../../common/api";
import { Bar } from 'react-chartjs-2'

const financialGraphLabels = ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'];


const useStyles = makeStyles(theme => ({
    grid: props => ({
        marginTop: theme.spacing(2),
        marginRight: props.isDesktop ? theme.spacing(2) : theme.spacing(0)
    }),
    commonGrid: {
        paddingTop: theme.spacing(2),
        marginTop: theme.spacing(3),
    },
    loaderGrid: {
        verticalAlign: 'middle',
        borderRight: '1px solid #bfbfbf',
        padding: theme.spacing(2),
        textAlign: 'center',
        display: 'table-cell'
    },
    card: {
        minHeight: '100%',
        minWidth: '94%',
        marginTop: theme.spacing(2),
        display: 'table',
        width: '98.5%',
        tableLayout: 'fixed'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));


const FinancialStats = ({showError, match}) => {
    const theme = useTheme();
    const [incomeExpenseGraphLoading, setIncomeExpenseGraphLoading] = useState(true);
    const [incomeExpenseGraph, setIncomeExpenseGraphData] = useState({});
    const [currentFinancialYear, setCurrentFinancialYear] = useState(0);

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    const onFinancialYearChange = (event) => {
        if (event.target.value !== undefined && event.target.value !== null)
            getIncomeExpenseGraph(event.target.value)
    }

    const onload = () => {
        let year = new Date().getFullYear()

        getIncomeExpenseGraph(year);
    }
    
    useEffect(onload, []);
    const getIncomeExpenseGraph = (year) => {
        // showError('');
        setIncomeExpenseGraphLoading(true);
        setCurrentFinancialYear(year)
        const payload = {
            year: year
        };

        const response = api.post('getIncomeExpenseGraph', payload);

        response.then(res => {
            setIncomeExpenseGraphData(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setIncomeExpenseGraphLoading(false);
        });

    }


    return <Grid container style={{ minHeight: '350px' }}>
        <Grid item xs={12} className={classes.grid} style={{ minWidth: '100%' }}>
            {incomeExpenseGraphLoading ?
                <Card className={classes.card}>
                    <Grid className={classes.loaderGrid} item xs={12}>
                        <CircularProgress className={classes.progressBar} />
                    </Grid>
                </Card>
                :
                <Card className={classes.card}>
                    <CardActions>
                        <Typography variant="h5" component="h2">
                            {"Financial Stats - "}
                        </Typography>
                        <FormControl className={classes.formControl}>
                            <Select
                                id="financialYear-select"
                                value={currentFinancialYear !== undefined ? currentFinancialYear : ''}
                                onChange={onFinancialYearChange}
                                name="financialYear">
                                {incomeExpenseGraph.financialYears !== undefined && incomeExpenseGraph.financialYears !== null ?
                                    (incomeExpenseGraph.financialYears).map((value, index) =>
                                        <MenuItem key={index} value={value}>
                                            {"April " + (value) + " to March " + (value + 1)}
                                        </MenuItem>
                                    ) : ""}
                            </Select>
                        </FormControl>
                    </CardActions>
                    <Divider />
                    <CardContent>
                        <FinancialGraph data={incomeExpenseGraph} />
                    </CardContent>
                </Card>
            }
        </Grid >

    </Grid>
};


const FinancialGraph = (props) => {
    const dualBarsChartData = {
        labels: financialGraphLabels,
        datasets: [
            {
                label: "Total Income",
                backgroundColor: 'rgba(27, 58, 86, 1)',
                data: props.data.totalIncome,
                borderWidth: 0,
            },
            {
                label: "Total Expense",
                backgroundColor: 'rgba(243, 181, 107, 1)',
                data: props.data.totalExpense,
                borderWidth: 0,
            }
        ]
    }
    const dualChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        barPercentage: 3,
        legend: {
            display: true
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display: false
                }
            }
            ],
            yAxes: [
                {
                    ticks: {
                        callback: function (value, index, array) {
                            return (value < 1000000) ? ((value >= 1000) ? value / 1000 + 'K' : value) : value / 1000000 + 'M';
                        }
                    },
                    gridLines: {
                        display: false
                    },
                    display: true
                }
            ]
        }
    }

    return <Bar width={300} height={400} data={dualBarsChartData} options={dualChartOptions} />

}

export default FinancialStats;