import React, {useEffect,useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button"; 
import SalePayment from "./salePayment";
import {MaterialTable} from '../../../components';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(2, 0)
    }
}));

let currentPayment = undefined
let currRow = undefined
let editPaymentMode = false
const SalePayementDetails = ({onPaymentEdit, data ,banks,   handlePaymentDetailsClose, showPaymentDetailsDialog, onSalePaymentSave,
     onSalePaymentDelete, loading}) => {
    const classes = useStyles();
    const [showPaymentDialog, setShowPaymentDialog] = useState(false)
    const [salePayments, setSalePayments] = useState([])
    const [, setPopupLoading] = useState(false) ;

    const reset = () => {
        currentPayment = data
        if(showPaymentDetailsDialog && data !== undefined && data.salePayments !== undefined && data.salePayments !== null){
            console.debug(data)
            setSalePayments(data.salePayments);
        }else{
            setSalePayments([])
        }
    };
    useEffect(reset, [showPaymentDetailsDialog,data]);


    function onNewPayment(event) {
        editPaymentMode = false;
        event.preventDefault();
        setShowPaymentDialog(true);
    } 

    function onEdit(rowData) {
        rowData = {
            ...rowData,
            bankIndex:banks.findIndex((bank)=>{return bank.id === rowData.paymentBankId;}),
            tcs: data.tcs,
            tds: data.tds,
            totalBillValue: data.totalBillValue,
            totalAmountPaid: data.totalAmountPaid,
            saleMaterials:[...currentPayment.saleMaterials]
        };
        currRow = rowData
        editPaymentMode = true
        setShowPaymentDialog(true);
    }

    function onPaymentDelete(rowData) {
        console.debug(rowData,salePayments)
        let index = salePayments.findIndex((srcSalePayment) => {
            return srcSalePayment.id === rowData.id;
        });
        let newSalePayments = salePayments;
        newSalePayments.splice(index, 1); 
        console.debug(newSalePayments)
        setSalePayments(newSalePayments)
        let tempCurrentData = {
            ...data,
            salePayments :newSalePayments
        }
        onSalePaymentDelete(rowData, tempCurrentData)
        console.debug(salePayments)
    }


    function onPaymentSave(payment) {
        console.debug(payment,data)
        console.debug(salePayments)
        if(editPaymentMode){
            let index = salePayments.findIndex((srcPayement) => {
                return srcPayement.id === payment.id;
            });
            setSalePayments([...salePayments.slice(0, index),
                {...payment},
                ...salePayments.slice(index + 1)])
    
            let tempCurrentData ={
                ...data,
                salePayments :[...salePayments.slice(0, index),
                    {...payment},
                    ...salePayments.slice(index + 1)]
            }

            onPaymentEdit(payment, tempCurrentData, setPopupLoading)
        }else{
            payment = {
                ...payment,
                saleId : data.id
            }
            onSalePaymentSave(payment, setPopupLoading)
        }
        setShowPaymentDialog(false);
        console.debug(salePayments)
    }


    function handlePaymentClose() {
        currRow = undefined;
        setShowPaymentDialog(false);
    }

    let actions = [
        rowData => ({
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit sale Details',
            onClick: (event, rowData) => onEdit(rowData),
            hidden:rowData.paymentNote === "Bulk Payment" || rowData.paymentNote === "Payment received for TDS" || rowData.paymentNote === "Payment made for TCS" ? true : false
        }),
        rowData => ({
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete sale Details',
            onClick: (event, rowData) => onPaymentDelete(rowData),
            hidden:rowData.paymentNote === "Bulk Payment" || rowData.paymentNote === "Payment received for TDS" || rowData.paymentNote === "Payment made for TCS" ? true : false
        })
    ]
    return <Dialog maxWidth='md' open={showPaymentDetailsDialog} onClose={handlePaymentDetailsClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >Sale Payments</DialogTitle>
          <DialogContent  >
            <div className={classes.button} >
                <Button variant="contained" color="primary" onClick={onNewPayment}>Add Payment</Button>
            </div>

            <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'id', hidden: true},
                {title: 'Payment Date', field: 'paymentDate',  editable: 'never',
                render: rowData => {
                    return <span>{moment(rowData.paymentDate).format("DD-MM-YYYY")}</span>
                }
                },
                {title: 'Payment Amount', field: 'paymentAmount',  editable: 'never', render: rowData => {
                    return <span>{Math.round(rowData.paymentAmount)}</span>
                }},
                {title: 'Payment Mode', field: 'paymentMode', editable: 'never',
                render: rowData => {
                    return <span>{rowData.paymentMode === 0 ? '' : (rowData.paymentMode === 1 ? 'Cash' : rowData.paymentBankName.String)}</span>
                }
                },
                {title: 'paymentBankId', field: 'paymentBankId', editable: 'never', hidden: true},
                {title: 'Payment Note', field: 'paymentNote', editable: 'never'},
            ]}
            data={salePayments}
            options={{
                actionsColumnIndex: -1,
                search: false,
                showTitle: false,
                
                toolbar: false,
                paging:false
            }} 
            //style={{boxShadow: 'unset'}}
            actions={actions}
            /> 

            {showPaymentDialog ? 

                <SalePayment banks={banks} data={(editPaymentMode) ? currRow : currentPayment} onSave={onPaymentSave} handlePaymentClose={handlePaymentClose} 
                showPaymentDialog={showPaymentDialog} editMode={editPaymentMode} >
                </SalePayment> 

                : null
            }
            <DialogActions>
                <Button
                    className={classes.button}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handlePaymentDetailsClose}
                >
                    Close
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
};

export default SalePayementDetails;