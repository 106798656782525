import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import moment from 'moment';
import OtherIncome from "./components/otherIncome";
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import PaymentIcon from '@material-ui/icons/Payment';
import OtherIncomePaymentDetails from "./components/otherIncomePaymentDetails";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
/*import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';*/
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {useGlobalState} from '../../state';
import { version } from '../../common/enums';

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    gridMargin: {
        margin: theme.spacing(1.5)
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        minWidth: 160,
    },
    redChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#f44336',
        backgroundColor: 'rgba(244, 67, 54, 0.08)',
    },
    greenChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#4caf50',
        backgroundColor: 'rgba(76, 175, 80, 0.08)'
    },
    searchButton: {
        color: 'white',
        width: theme.spacing(21.25),
        backgroundColor: '#EF5350',
        '&:hover': {
            backgroundColor: '#E53935',
        },
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom: theme.spacing(4)
    },
    success: {
        color: 'white',
        backgroundColor: '#4CAF50',
        '&:hover': {
            backgroundColor: '#388E3C',
        },
        width: theme.spacing(13.75),
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom: theme.spacing(4)
    },
    red: {
        color: 'red'
    },
    green: {
        color: 'green'
    },

}));

let currentOtherIncome = undefined;
let editMode = false;

const OtherIncomesView = ({ showError, showMessage, history }) => {

    const [otherIncomes, setOtherIncomes] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [farms, setFarms] = useState([]);
    const [incomeTypes, setIncomeTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showOtherIncomeDialog, setShowOtherIncomeDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showOtherIncomePaymentDialog, setShowOtherIncomePaymentDialog] = useState(false);
    const [currentOtherIncomePayment, setCurrentOtherIncomePayment] = useState({});
    const [banks, setBanks] = useState([]);
    const [filterSelectedStartDate, setFilterSelectedStartDate] = useState(null);
    const [filterSelectedEndDate, setFilterSelectedEndDate] = useState(null);
    const [errorMessage, setErrorMessage] = useState('')


    const theme = useTheme();

    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });
    const csvHeader = ["Date", "Amount(in Rs.)", "Customer", "Farm", "Income Type", "Payment Status"]

    const csvProHeader = ["Date", "Amount(in Rs.)", "Farm", "Expense Type"]

    const handleFilterStartDateChange = date => {
        setFilterSelectedStartDate(date);
    };

    const handleFilterEndDateChange = date => {
        setFilterSelectedEndDate(date);
    };

    const clearDateRangeFilter = (sale) => {
        setFilterSelectedStartDate(null)
        setFilterSelectedEndDate(null)
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    function onNewOtherIncome(event) {
        editMode = false;
        event.preventDefault();
        setShowOtherIncomeDialog(true);
    }

    function handleOtherIncomeClose() {
        currentOtherIncome = undefined;
        setShowOtherIncomeDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
        setErrorMessage(null) ;
    }

    const onOtherIncomeDeleteConfirm = () => {
        if (currentOtherIncome && currentOtherIncome.id) {
            setShowDeleteConfirm(false);
           // setLoading(true);
            showError('');
            const response = api.delete(`other-income/${currentOtherIncome.id}`);

            response.then(res => {
                if (res) {
                    let index = otherIncomes.findIndex((srcOtherIncome) => {
                        return srcOtherIncome.id === currentOtherIncome.id;
                    });
                    let newOtherIncomes = [...otherIncomes];
                    newOtherIncomes.splice(index, 1);
                    setOtherIncomes(newOtherIncomes);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('OtherIncome not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentOtherIncome = undefined;
            });

        }
    };

    const onDelete = (otherIncome) => {
        currentOtherIncome = otherIncome;
        setShowDeleteConfirm(true);
    };

    const onOtherIncomeSave = (otherIncome, setPopupLoading) => {
       // setLoading(true);
        // showError('');
        showMessage('');
        const response = api.post('other-income', {
            ...otherIncome,
        });

        response.then(res => {

            setOtherIncomes([...otherIncomes, res]);
            setShowOtherIncomeDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('OtherIncome not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };


    const onOtherIncomeEdit = (otherIncome) => {
        currentOtherIncome = {
            ...otherIncome,
            farmIndex: farms.findIndex((farm) => { return farm.id === otherIncome.farmId; }),
            customerIndex: customers.findIndex((customer) => { return customer.id === otherIncome.customerId; }),
            incomeTypeIndex: incomeTypes.findIndex((incomeType) => { return incomeType.id === otherIncome.incomeTypeId; }),
            amount: otherIncome.amount
        };
        editMode = true;
        setShowOtherIncomeDialog(true);
    };

    const onOtherIncomeUpdate = (otherIncome, setPopupLoading) => {
        //setLoading(true);
        // showError('');
        console.debug(otherIncome, "otherIncome")
        otherIncome.amount = parseInt(otherIncome.amount)
        const response = api.post(`other-income/${otherIncome.id}`, {
            ...otherIncome,

        });
        response.then(() => {
            let index = otherIncomes.findIndex((srcOtherIncome) => {
                return srcOtherIncome.id === otherIncome.id;
            });

            setOtherIncomes([...otherIncomes.slice(0, index),
            { ...otherIncome },
            ...otherIncomes.slice(index + 1)]);
            setShowOtherIncomeDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('OtherIncome not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };

    //Get all OtherIncomes API Call
    const getAllOtherIncomes = () => {

        if (filterSelectedEndDate !== null && filterSelectedStartDate !== null &&
            filterSelectedEndDate < filterSelectedStartDate) {
            showError('Start Date is Greater than or Equal to End Date');
            return
        }
        let payload = {
            startDate: filterSelectedStartDate,
            endDate: filterSelectedEndDate,
        }

        setShowOtherIncomeDialog(false);
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.post('other-income-with-date', { ...payload });

        response.then(res => {
            res.forEach((value) => {
                let totalAmount = 0
                if (value.otherIncomePayments !== null) {
                    value.otherIncomePayments.forEach((payment) => {
                        totalAmount += payment.paymentAmount
                    })
                }
                value.remainingBalance = totalAmount
            })

            setOtherIncomes([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No OtherIncomes Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    //Get all Customers API Call
    const getAllCustomers = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('customers-list');

        response.then(res => {
            setCustomers([{
                name: "Other",
                id: 0
            },
            ...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    //Get all farms API Call
    const getAllFarms = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('farm');

        response.then(res => {
            setFarms([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    //Get all IncomeType API Call
    const getAllIncomeTypes = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('income-type');

        response.then(res => {
            setIncomeTypes([
                {
                    incomeTypeName: "Other",
                    id: 0
                },
                ...res]
            );

        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const generateExcel = function (header, data) {
        console.log("excel ", header, data)
        let csvData = '';
        header.forEach((column, index) => {
            if (index === 0)
                csvData = csvData + column
            else
                csvData = csvData + ',' + column
        });

        csvData = csvData + '\r\n';


        data.map((row, index) => {
            let tempDate;
            let tempPaymentStatus;
            let column = []
            tempDate = moment(row.date).format("DD-MM-YYYY")
            if (row.paymentStatus === 1)
                tempPaymentStatus = "Paid"
            else
                tempPaymentStatus = "Unpaid"

            if (user.versionID === version.pro) {
                column.push(tempDate, row.amount, row.farmName, row.incomeTypeName)

            }else {
                column.push(tempDate, row.amount, row.customerName, row.farmName, row.incomeTypeName, tempPaymentStatus)
            }

            column.forEach((column, index) => {
                if (index === 0)
                    csvData = csvData + column

                else
                    csvData = csvData + ',' + column
            });
            csvData = csvData + '\r\n';

            return "";
        });

        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
        element.setAttribute('download', 'report.csv');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);


    }
    function handleOtherIncomePaymentClose() {
        currentOtherIncome = undefined;
        setShowOtherIncomePaymentDialog(false);
    }

    const onOtherIncomePaymentUpdate = (payment, otherIncome) => {
        //setShowOtherIncomePaymentDialog(false);
        setLoading(true);
        showError('');
        const response = api.post(`other-income-payment/${payment.id}`, {
            ...payment,

        });
        response.then(() => {
            let index = otherIncomes.findIndex((srcOtherIncome) => {
                return srcOtherIncome.id === otherIncome.id;
            });

            let totalAmount = 0
            otherIncome.otherIncomePayments.forEach((payment, index) => {
                totalAmount = totalAmount + payment.paymentAmount
            })
            if (totalAmount === 0) {
                otherIncome = {
                    ...otherIncome,
                    paymentStatus: 0
                }
            } else if (totalAmount >= otherIncome.amount) {
                otherIncome = {
                    ...otherIncome,
                    paymentStatus: 2
                }
            } else if (totalAmount < otherIncome.amount) {
                otherIncome = {
                    ...otherIncome,
                    paymentStatus: 1
                }
            }
            otherIncome = {
                ...otherIncome,
                remainingBalance: totalAmount
            }
            setOtherIncomes([...otherIncomes.slice(0, index),
            { ...otherIncome },
            ...otherIncomes.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('OtherIncome Payment not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onOtherIncomePaymentSave = (payment) => {
        //setShowOtherIncomePaymentDialog(false);
        setLoading(true);
        showError('');
        const response = api.post(`other-income-payment`, {
            ...payment,
        });
        response.then((res) => {

            let tempCurrentOtherIncome = currentOtherIncomePayment
            if (tempCurrentOtherIncome.otherIncomePayments !== undefined && tempCurrentOtherIncome.otherIncomePayments !== null) {
                tempCurrentOtherIncome.otherIncomePayments = [
                    ...tempCurrentOtherIncome.otherIncomePayments,
                    res
                ]
            } else {
                tempCurrentOtherIncome.otherIncomePayments = [
                    res
                ]
            }
            setCurrentOtherIncomePayment({ ...tempCurrentOtherIncome })

            let totalAmount = 0
            tempCurrentOtherIncome.otherIncomePayments.forEach((payment, index) => {
                totalAmount = totalAmount + payment.paymentAmount
            })
            if (totalAmount === 0) {
                tempCurrentOtherIncome = {
                    ...tempCurrentOtherIncome,
                    paymentStatus: 0
                }
            } else if (totalAmount >= tempCurrentOtherIncome.amount) {
                tempCurrentOtherIncome = {
                    ...tempCurrentOtherIncome,
                    paymentStatus: 2
                }
            } else if (totalAmount < tempCurrentOtherIncome.amount) {
                tempCurrentOtherIncome = {
                    ...tempCurrentOtherIncome,
                    paymentStatus: 1
                }
            }
            tempCurrentOtherIncome = {
                ...tempCurrentOtherIncome,
                remainingBalance: totalAmount
            }


            let index = otherIncomes.findIndex((srcOtherIncome) => {
                return srcOtherIncome.id === tempCurrentOtherIncome.id;
            });
            setOtherIncomes([...otherIncomes.slice(0, index),
            { ...tempCurrentOtherIncome },
            ...otherIncomes.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('OtherIncome not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onOtherIncomePaymentDelete = (payment, otherIncome) => {
        if (payment && payment.id) {
            //setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.post(`other-income-payment-delete/${payment.id}`, {
                ...payment,
            });

            response.then(res => {
                if (res) {
                    let index = otherIncomes.findIndex((srcOtherIncome) => {
                        return srcOtherIncome.id === otherIncome.id;
                    });

                    let totalAmount = 0
                    otherIncome.otherIncomePayments.forEach((payment, index) => {
                        totalAmount = totalAmount + payment.paymentAmount
                    })
                    if (totalAmount === 0) {
                        otherIncome = {
                            ...otherIncome,
                            paymentStatus: 0
                        }
                    } else if (totalAmount >= otherIncome.totalBillValue) {
                        otherIncome = {
                            ...otherIncome,
                            paymentStatus: 2
                        }
                    } else if (totalAmount < otherIncome.totalBillValue) {
                        otherIncome = {
                            ...otherIncome,
                            paymentStatus: 1
                        }
                    }

                    setOtherIncomes([...otherIncomes.slice(0, index),
                    { ...otherIncome },
                    ...otherIncomes.slice(index + 1)]);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('OtherIncome not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentOtherIncome = undefined;
            });

        }
    };

    //Get all Banks API Call
    const getAllBanks = () => {
        showError('');
        showMessage('');

        const response = api.get('bank');

        response.then(res => {
            setBanks([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }


    const onload = () => {
        getAllCustomers()
        getAllFarms()
        getAllIncomeTypes()
        getAllOtherIncomes()
        getAllBanks()

    }
    useEffect(onload, []);


    let otherIncomeNameColumn = {
        title: 'Other Income', field: 'date', editable: 'never', defaultSort: 'asc',
        render: rowData => <Mobile data={rowData}
            onEdit={() => {
                onOtherIncomeEdit(rowData)
            }}
            onDelete={() => {
                onDelete(rowData)
            }}
        />
    };

    const onPaymentEdit = (otherIncome) => {
        setCurrentOtherIncomePayment({
            ...otherIncome,
        });
        setShowOtherIncomePaymentDialog(true);
    };


    let actions = [];
    if (isDesktop) {
        otherIncomeNameColumn = {
            title: 'Date', field: 'date', type: 'datetime',
            editable: 'never',
            hidden: !isDesktop,
            render: rowData => {
                return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
            }, defaultSort: 'asc'
        };
        actions = [
            {
                icon: () => <PaymentIcon color='error'>payment</PaymentIcon>,
                tooltip: 'Payment Details',
                onClick: (event, rowData) => onPaymentEdit(rowData),
                hidden:(user.versionID === version.pro),
            },
            rowData => ({
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit otherIncome Details',
                onClick: () => onOtherIncomeEdit(rowData),
                hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
            }),
            rowData => ({
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete otherIncome Details',
                onClick: (event, rowData) => onDelete(rowData),
                hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
            }),
        ]
    }


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Other Income
                </Typography>
            </Grid>
            <Grid container
                spacing={3}
                justify="flex-end"
                alignItems="center"
            >
                <Grid item>
                    <Button variant="contained" color="primary" onClick={onNewOtherIncome}>Add other Income</Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" color="primary"
                        disabled={otherIncomes.length < 1}
                        onClick={() => generateExcel(user.versionID === version.proPlus ? csvHeader : csvProHeader, otherIncomes)}
                        endIcon={<GetAppIcon />}>Download Excel</Button>
                </Grid>
            </Grid>
            <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        className={classes.formControl}
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="From"
                        format="dd/MM/yyyy"
                        value={filterSelectedStartDate || null}
                        onChange={handleFilterStartDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    <KeyboardDatePicker
                        className={classes.formControl}
                        margin="normal"
                        variant="inline"
                        autoOk
                        label="To"
                        format="dd/MM/yyyy"
                        value={filterSelectedEndDate || null}
                        onChange={handleFilterEndDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>


        </Grid>
        <Grid>
            <Button onClick={getAllOtherIncomes} className={classes.success}>Search <SearchIcon /></Button>
            <Button onClick={clearDateRangeFilter} className={classes.searchButton}>Clear Search <CloseIcon /></Button>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'ID', field: 'id', hidden:(!isDesktop ? true :false ) },

                otherIncomeNameColumn,
                { title: 'Customer', field: 'customerName', hidden:(!isDesktop ||  (user.versionID === version.pro)), editable: 'never' },
                { title: 'Farm', field: 'farmName', hidden: !isDesktop, editable: 'never' },
                { title: 'Income Type', field: 'incomeTypeName', hidden: !isDesktop, editable: 'never' },
                { title: 'Amount (in Rs.)', field: 'amount', hidden: !isDesktop, editable: 'never',
                    render: rowData => {
                        return <span>{(rowData.amount).toLocaleString(user.locales,amountObj)}</span>
                    } 
                },
                {
                    title: 'Remaining Balance', field: 'remainingBalance', editable: 'never',   hidden:(user.versionID === version.pro),
                    render: rowData => {
                        let paidAmount = 0
                        if (rowData.otherIncomePayments) {
                            rowData.otherIncomePayments.forEach(ele => {
                                paidAmount += ele.paymentAmount
                            })
                        }
                        let remaningBalance = (rowData.amount - paidAmount)
                        if (rowData.paymentStatus === 1 && remaningBalance < 1) {
                            remaningBalance = 0
                        }
                        return <span>{(remaningBalance).toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'Payment Status', field: 'paymentStatus', editable: 'never',  hidden:(user.versionID === version.pro),
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    render: (rowData) => {
                        let remaningBalance = rowData.amount - rowData.remainingBalance

                        let statusIcon = <Tooltip title="Not Paid"><CloseIcon className={classes.red} /></Tooltip>
                        if (rowData.paymentStatus === 1 && remaningBalance >= 1) {
                            statusIcon = <Tooltip title="Partially Paid"><QueryBuilderIcon /></Tooltip>
                        } else if (rowData.paymentStatus === 2 || (rowData.paymentStatus === 1 && remaningBalance < 1)) {
                            statusIcon = <Tooltip title="Paid"><DoneIcon className={classes.green} /></Tooltip>
                        }

                        return <span>{statusIcon}</span>
                    }
                }

            ]}
            data={otherIncomes}
            title='Other Income Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,

                toolbar: true
            }}
            actions={actions}
        />
        <OtherIncome deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showOtherIncomeDialog} handleClose={handleOtherIncomeClose} onSave={onOtherIncomeSave} onEdit={onOtherIncomeUpdate}
            editMode={editMode} data={currentOtherIncome} farms={farms} customers={customers} incomeTypes={incomeTypes} />

        <OtherIncomePaymentDetails showPaymentDetailsDialog={showOtherIncomePaymentDialog} handlePaymentDetailsClose={handleOtherIncomePaymentClose}
            onPaymentEdit={onOtherIncomePaymentUpdate} data={currentOtherIncomePayment} banks={banks} onOtherIncomePaymentSave={onOtherIncomePaymentSave}
            onOtherIncomePaymentDelete={onOtherIncomePaymentDelete} loading={loading} />

        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onOtherIncomeDeleteConfirm}
            title='Delete Other Income ?'
            subText='This action will delete the Other Income Details. Please confirm.' />
    </div>
}

export default OtherIncomesView;