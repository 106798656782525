import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from '@material-ui/core/Button';
import {ConfirmDialog, MaterialTable} from "../../components";
import api from "../../common/api";
import Icon from "@material-ui/core/Icon";
import NewShed from './components/shed';
import Mobile from "./components/mobile";



const useStyles = makeStyles(theme => ({
    root: props=>({
        margin: props.isDesktop?theme.spacing(4):theme.spacing(1),
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    breadcrumbs:{
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentData = undefined;
let editMode = false;
const Shed = ({showError, showMessage, match}) => {
    const farmID = match.params['farmID'];

    const [loading, setLoading] = useState(false);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});
    const [sheds, setShed] = useState([]);
    const [showShedDialog, setShowShedDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [shedType, setShedType] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')

    const handleShedClose = () => {
        currentData = undefined;
        setShowShedDialog(false);
    };

    const onShedEdit = (shed) => {
        currentData = {...shed,
            shedTypeIndex:shedType.findIndex((type)=>{return type.id === shed.shedTypeID;}),
        };;
        editMode = true;
        setShowShedDialog(true);
    };

    function onNewShed(event) {
        editMode = false;
        event.preventDefault();
        removeErrorMessage();
        setShowShedDialog(true);
    };

    const onDelete = (data) => {
        currentData = data;
        setShowDeleteConfirm(true);
    };

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    
    
    const onShedSave = (shed) => {
        setLoading(true);
        // showError('');
        showMessage('');
        const response = api.post(`farm/${farmID}/shed`, {
            ...shed
        });

        response.then(res => {
            //console.debug("shed",shed,res)
            setShed([...sheds, res]);
            setShowShedDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Shed not added');
                else if(err.message ===  'Duplicate Key')
                setErrorMessage('Duplicate Name');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onShedUpdate = (shed) => {
        setLoading(true);
        // showError('');
        const response = api.post(`farm/${farmID}/shed/${shed.id}`, {
            ...shed
        });
        response.then(() => {
            let index = sheds.findIndex((srcData) => {
                return srcData.id === shed.id;
            });
            setShed([...sheds.slice(0, index),
                {...shed},
                ...sheds.slice(index + 1)]);
            setShowShedDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('Shed not updated');
                else if(err.message ===  'Duplicate Key')
                setErrorMessage('Duplicate Name');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    function onDeleteConfirm (){
        if (currentData && currentData.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`farm/${farmID}/shed/${currentData.id}`);

            response.then(res => {
                if (res) {
                    let index = sheds.findIndex((src) => {
                        return src.id === currentData.id;
                    });
                    let newData = [...sheds];
                    newData.splice(index, 1);
                    setShed(newData);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Shed not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentData = undefined;
            });

        }
    }

    //Get all Shed API Call
    const getAllSheds = () => {
        showError('');
        showMessage('');

        const response = api.get(`farm/${farmID}/shed`);

        response.then(res => {
            setShed([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Sheds');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }

    //Get all Shed Type API Call
    const getAllShedType = () => {
        showError('');
        showMessage('');

        const response = api.get(`shed_type`);

        response.then(res => {
            setShedType([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Sheds Type');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    };

    const getAllData = ()=>{
        setLoading(true);
        Promise.all([getAllSheds(),getAllShedType()]).finally(()=>{
            setLoading(false);
        });
    }
    
    useEffect(getAllData, []);

    let shedColumnDefaults = {title: 'Name',field: 'name', defaultSort:'asc'};
    let shedColumn = {...shedColumnDefaults,
        render: rowData => <Mobile data={rowData}
                                   onEdit={()=>{onShedEdit(rowData)}}
                                   onDelete={()=>{onDelete(rowData)}}
        />};
    let actions = [];
    if (isDesktop) {
        shedColumn = shedColumnDefaults;
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Shed',
                onClick: (event, rowData) => {
                    onShedEdit(rowData);
                }
            },
           {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Shed',
                onClick: (event, rowData) => {
                    onDelete(rowData);
                }
            }
        ]
    }
    return (
        <div className={classes.root}>
            <Grid
                container
                justify='space-between'
                alignItems='flex-end'
                className={classes.topBar}
            >
                <Grid>
                    <Typography display="block" variant="h3" gutterBottom>
                        Sheds
                    </Typography>
                </Grid>

                <Grid>
                    <Button variant="contained" color="primary"  onClick={onNewShed}>Add Shed</Button>
                </Grid>
            </Grid>
                <MaterialTable

                    isLoading={loading}
                    columns={[
                        {title: 'id', field: 'id', hidden: true},
                        shedColumn,
                        {title: 'Shed Type', field: 'shedTypeName', hidden: !isDesktop},
                    ]}
                    data={sheds}
                    options={{
                        actionsColumnIndex: -1,
                        search: true,
                        showTitle: true,
                        
                        toolbar: true
                    }}
                    actions={actions}
                    title='Sheds Table'
                />           
            <NewShed deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showShedDialog} handleClose={handleShedClose} onSave={onShedSave}
                  onEdit={onShedUpdate} shedType = {shedType} 
                  editMode={editMode} data={currentData}/>
             <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onDeleteConfirm}
                       title='Delete Shed ?'
                       subText='This action will delete the shed.'/>
        </div>
    );
};

export default Shed;