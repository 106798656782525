import React, { useEffect, useState, useRef } from 'react';
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from 'html2canvas';
import moment from "moment";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { useReactToPrint } from 'react-to-print';
import { useGlobalState } from '../../state';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

window.html2canvas = html2canvas;
const useStyles = makeStyles(theme => ({
    divider: {
        backgroundColor: '#101010'
    },
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        minWidth: 160,
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    singleLineBorder: {
        borderTop: 'solid 1px #F26522',
        borderBottom: 'solid 1px #F26522'
    },
    doubleLineBorder: {
        borderTop: 'double #F26522',
        borderBottom: 'double #F26522'
    },
    boldedText: {
        fontWeight: 'bold'
    },
    reportContainer: {

    },
    reportHeader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    rawMaterialName: {
        textAlign: 'center',
        textDecoration: 'underline'
    }
}));

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: '3px',
        fontSize: "12px",
    },
    body: {
        fontSize: '12px',
        backgroundColor: theme.palette.common.white,
        padding: '3px'
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);


const tableHeader = JSON.parse(`[["Item","Date","Bill Amount","Paid","Balance"]]`);




const generateExcel = function (header, data) {
    console.debug("excel ", header, data)
    let csvData = '';
    header.forEach((column, index) => {
        if (index === 0)
            csvData = csvData + column
        else
            csvData = csvData + ',' + column
    });
    csvData = csvData + '\r\n';
    data.forEach(row => {
        row.forEach((column, index) => {
            if (index === 3 || index === 4 || index === 5) {
                return;
              }
            if (index === 0)
                csvData = csvData + column

            else
                csvData = csvData + ',' + column
        });
        csvData = csvData + '\r\n';
    });

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
    element.setAttribute('download', 'report.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);


}



const VendorReport = function ({ showError, match, isLine }) {
    const theme = useTheme();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [vendorId, setVendorId] = useState(undefined);
    const [data, setData] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [invoiceSetting, setInvoiceSetting] = useState({});
    const [vendorIndex, setVendorIndex] = useState(undefined);
    const [report, setReport] = useState({});

    useEffect(() => {
        if (startDate && endDate && (startDate.getTime() < endDate.getTime()) && vendorId !== undefined) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [startDate, endDate, vendorId]);


    const getAllInvoiceSettings = () => {
        showError('');
        const response = api.get('invoiceSettings');
        response.then(res => {
            setInvoiceSetting(res[0]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No InvoiceSettings Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }
    //Get all vendors API Call

    const getVendorList = () => {
        showError('');
        let apiName = "vendors-list"
        if (isLine) {
            apiName = "get-line-vendors"
        }

        const response = api.get(apiName);

        response.then(res => {
            setVendors([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
        return response
    }

    const onLoad = () => {
        setLoading(true);
        Promise.all([getAllInvoiceSettings(), getVendorList()]).finally(() => {
            setLoading(false);
        });
    }
    const [{ user }] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }

    useEffect(onLoad, []);

    const numToIndianStd = function (data) {
        if (!isNaN(data)) {
            return data.toLocaleString(user.locales, amountObj);
        }
    };


    const parseReportFromServer = function (data) {
        const { reportsArray } = data;

        // for (let index = 0; index < reportsArray.length; index++) {
        //     if (reportsArray[index][1] !== "Opening Balance") {
        //         console.log();
        //         let currentIdx = index;
        //         let previousIdx = --index;
        //         //calculating TDS value
        //         //TDS Amount = Bill value * TDS/100

        //         //commenting for now.
        //         // reportsArray[currentIdx][3] = (Number(reportsArray[currentIdx][2]) * Number(reportsArray[currentIdx][3]))/100;
        //         // reportsArray[currentIdx][3] = String(reportsArray[currentIdx][3]);

        //         //modifying balance column
        //         //currentRowDifference = Bill Anount - TDS - Paid  
        //         let currentRowDifference
        //         if (reportsArray[currentIdx][1].includes("TDS") || reportsArray[currentIdx][1].includes("TCS")) {
        //             currentRowDifference = reportsArray[currentIdx][1].includes("TDS") ? Number(-reportsArray[currentIdx][4]) : Number(reportsArray[currentIdx][4])
        //         } else {
        //             currentRowDifference = Number(reportsArray[currentIdx][2]) - Number(reportsArray[currentIdx][3]) - Number(reportsArray[currentIdx][4]);
        //         }
        //         //Balance = Prev Row’s balance + current row’s difference
        //         reportsArray[currentIdx][5] = Number(reportsArray[previousIdx][5]) + currentRowDifference;
        //         reportsArray[currentIdx][5] = String(reportsArray[currentIdx][5]);

        //         index = currentIdx;
        //     }
        // }

        // //Total balance = last row balance value
        // reportsArray[reportsArray.length - 1][5] = reportsArray[reportsArray.length - 2][5];
        setData(reportsArray);
        setReport(data)

    };

    const generateReport = function () {
        showError('');
        setLoading(true);
        const payload = {
            startDate: startDate,
            endDate: endDate,
            id: vendorId,
            isLine,
        };

        const response = api.post('reports/vendor-report', payload);

        response.then(res => {
            console.log("res---->", res)
            parseReportFromServer(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });

    };

    const handleFilterVendorChange = (event) => {
        console.log("event.target.value ", event.target.value)
        setVendorId(vendors[event.target.value].id);
        setVendorIndex(event.target.value);
    };

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });


    const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
    });


    return <div className={classes.root}>
        <Grid
            container
            alignItems='flex-start'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                   {isLine ? "Line ": ""} Vendor Report
                </Typography>
            </Grid>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">

                <Grid item>
                    <KeyboardDatePicker
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="From"
                        format="dd/MM/yyyy"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>

                <Grid item>
                    <KeyboardDatePicker
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="To"
                        format="dd/MM/yyyy"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="vendorName-select-outlined-label">
                           {isLine ? "Line " : ""} Vendor Name
                        </InputLabel>
                        <Select
                            id="vendorName-select"
                            value={vendorIndex !== undefined ? vendorIndex : ""}
                            onChange={handleFilterVendorChange}
                            name="vendorId"
                        >
                            {vendors !== undefined && vendors !== null ?
                                vendors.map((vendor, index) =>
                                    <MenuItem key={vendor.id} value={index}>{vendor.name || vendor.companyName}</MenuItem>
                                ) : ""}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Button disabled={!valid} variant="contained" color="primary"
                        onClick={generateReport}>Generate</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                        onClick={generatePDF} endIcon={<PictureAsPdfIcon />}>Download
                        PDF</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                        onClick={() => generateExcel(tableHeader, data)}
                        endIcon={<GetAppIcon />}>Download Excel</Button>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
        {/*
            loading ?
                <div className={classes.progressContainer}>
                    <CircularProgress className={classes.progressBar} />
                </div> :
                <div id='report-container' className={classes.reportContainer}// ref={componentRef}
                >
                    <div className={classes.reportHeader}>
                        <Typography variant="body1" component="p">
                            <b>Period</b> {moment(startDate).format('MM/DD/YYYY')} to {moment(endDate).format('MM/DD/YYYY')}
                        </Typography>

                        <Typography variant="h4" component="h4" className={classes.rawMaterialName}>
                            Vendor Report
                        </Typography>
                    </div>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Date</StyledTableCell>
                                <StyledTableCell>Item</StyledTableCell>
                                <StyledTableCell>Bill Amount</StyledTableCell>
                                <StyledTableCell>Paid</StyledTableCell>
                                <StyledTableCell>Balance</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell className={
                                        row[0].includes('Total')
                                            ? classes.boldedText : undefined
                                    } align="left">
                                        {row[0]}
                                    </StyledTableCell>
                                    <StyledTableCell className={
                                        row[0].includes('Total')
                                            ? classes.boldedText : undefined
                                    } align="left">
                                        {row[1]}
                                    </StyledTableCell>
                                    <StyledTableCell className={
                                        row[0].includes('Total')
                                            ? classes.boldedText : undefined
                                    } align="left">
                                        {row[2]}
                                    </StyledTableCell>
                                    <StyledTableCell className={
                                        row[0].includes('Total')
                                            ? classes.boldedText : undefined
                                    } align="left">
                                        {row[3]}
                                    </StyledTableCell>
                                    <StyledTableCell className={
                                        row[0].includes('Total')
                                            ? classes.boldedText : undefined
                                    } align="left">
                                        {row[4]}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>*/
        }
        {loading ?
            <div className={classes.progressContainer}>
                <CircularProgress className={classes.progressBar} />
            </div>
            :
            <div id="vendor-invoice" ref={componentRef} style={{ paddingTop: '35px' }}>
                <div className="invoice-box" style={{
                    backgroundColor: '#ffffff',
                    fontFamily: 'Montserrat,Roboto,sans-serif',
                    color: '#263238'
                }}>
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr >
                                <td>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ width: "2%" }}><img src={invoiceSetting.companyLogo}
                                                                    style={{ //width: '50%', 
                                                                        maxWidth: '130px'
                                                                    }}
                                                                    alt="company logo"></img><br /><br />
                                                                </td>
                                                                <td style={{ width: "50%", fontSize: "13px" }}>
                                                                    <p style={{ fontSize: "14px", fontWeight: 'bolder' }}>
                                                                        {invoiceSetting.companyName}</p>
                                                                    <p style={{ whiteSpace: 'pre-wrap' }}
                                                                    // className="show-white-space"
                                                                    >{invoiceSetting.address}</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                </tr>
                                                <tr>
                                                <td style={{ textAlign: "right" }}>
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                                {/* <td style={{ textAlign: "right", fontWeight: '600', fontSize: '23px' }} colSpan="2">
                                                                    Statement of Account<br />
                                                                    <span style={{ fontWeight: '400', fontSize: '18px' }}>
                                                                        Account activity</span>
                                                                </td> */}
                                                                 <tr>
                                                                <td style={{ textAlign: "center", fontWeight: '600', fontSize: '23px' }} colSpan="2">
                                                                        <b>{vendors[vendorIndex] !== undefined ? vendors[vendorIndex].name : ""}</b>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                <td style={{ textAlign: "center", fontWeight: '400', fontSize: '14px' }} colSpan="2">
                                                                        {vendors[vendorIndex] !== undefined ? vendors[vendorIndex].primaryContact : ""}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ whiteSpace: 'pre-wrap',textAlign: "center", fontWeight: '400', fontSize: '14px'}} colSpan="2">
                                                                        {vendors[vendorIndex] !== undefined ? vendors[vendorIndex].address : ""}
                                                                    </td>
                                                            </tr>
                                                            <tr>

                                                            <td style={{ textAlign: "right" }}>
                                                                <table style={{ width: "100%" }}>
                                                                    <tbody>
                                                                            <tr>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                    
                                                                                </td>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                {"(" + moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY') + ")"}
                                                                                </td>
                                                                            </tr>
                                                                    
                                                                    
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', margin: '0', padding: '0' }}>
                                            <tr style={{ margin: '0', padding: '0' }}>
                                                <td style={{ border: "1px solid #DFE2E7" }}>
                                                    <table style={{ width: '100%', borderCollapse: 'collapse', margin: '0', padding: '0' }}>
                                                    <tr style={{ margin: '0', padding: '0' }}>
                                                <td style={{ textAlign: "center",width:"25%", padding: "10px 5px", borderRight: "1px solid #DFE2E7" }}>
                                                    <table>
                                                        <tr>
                                                                    <td style={{ textAlign: "center",fontWeight: '400', fontSize: '14px' }}>
                                                                        Opening Balance
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "center",fontWeight: '600', fontSize: '23px' }}>
                                                                        {report.openingAmount !== undefined ? report.openingAmount.toLocaleString(user.locales, amountObj) : 0.00}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                        <td style={{ textAlign: "center",width:"25%", padding: "10px 5px", borderRight: "1px solid #DFE2E7" }}>
                                                            <table>
                                                                <tr>
                                                                    <td style={{ textAlign: "center",fontWeight: '400', fontSize: '14px' }}>
                                                                    Total Credit (+)
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "center",fontWeight: '600', fontSize: '23px',color:"green" }}>
                                                                       
                                                                        {report.totalBillAmount !== undefined ? report.totalBillAmount.toLocaleString(user.locales, amountObj) : 0.00}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                        <td style={{ textAlign: "center",width:"25%", padding: "10px 5px", borderRight: "1px solid #DFE2E7" }}>
                                                            <table>
                                                                <tr>
                                                                    <td style={{ textAlign: "center",fontWeight: '400', fontSize: '14px'}}>
                                                                    Total Debit (-)
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "center",fontWeight: '600', fontSize: '23px',color:"red" }}>
                                                                    {report.totalPaid !== undefined ? report.totalPaid.toLocaleString(user.locales, amountObj) : 0.00}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                       
                                                        <td style={{ textAlign: "center", width:"25%",padding: "10px 5px" }}>
                                                            <table>
                                                                <tr>
                                                                    <td style={{ textAlign: "center",fontWeight: '400', fontSize: '14px' }}>
                                                                        Closing Balance
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "center",fontWeight: '600', fontSize: '23px',color:"red" }}>
                                                                        {report.closingAmount !== undefined ? report.closingAmount.toLocaleString(user.locales, amountObj) : 0}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                    </table>
                                    <br />
                            </tr>
                            <tr>
                                <td>
                                    <br />
                                    <div style={{ minHeight: "450px" }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell align="left">#id</StyledTableCell>
                                                    <StyledTableCell align="center">Date</StyledTableCell>
                                                    <StyledTableCell align="right">Bill Amount</StyledTableCell>
                                                    {/* <StyledTableCell>TDS</StyledTableCell>
                                                    <StyledTableCell>TCS</StyledTableCell>
                                                    <StyledTableCell>GST</StyledTableCell> */}
                                                    <StyledTableCell align="right">Paid</StyledTableCell>
                                                    <StyledTableCell align="right">
                                                    <div style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}} >
                                                        Balance
                                                        <Tooltip title="Row balance = Previous balance + Bill Amount - TDS + TCS  + GST - Paid">
                                                            <IconButton size="small">
                                                            <InfoIcon style={{ color: "white",fontSize:"12px" }} />
                                                            </IconButton>
                                                    
                                                        </Tooltip>
                                                        </div>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.map((row, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left">

                                                            <div style={{display: 'flex'}}>
                                                                <div style={{fontWeight: '600'}}>
                                                                {((row[2] !== "" || row[6] !== "" ) && !row[0].includes("Total")) ? row[0] + "," :row[0]}
                                                                </div>
                                                                <div>
                                                                {row[0].includes("Total") ||
                                                                row[0].includes("Opening")
                                                                    ? ""
                                                                    : row[2] && row[2] !== ""
                                                                    ? String.fromCharCode(160) + `Purchase`
                                                                    : String.fromCharCode(160) +
                                                                    "Payment Out"}
                                                                </div>
                                                             
                                                             

                                                            </div>
                                                            
                                                        </StyledTableCell>
                                                        <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="center">
                                                            {row[1]}
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{fontWeight: '600',background:"#E3F8EE"}} className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="right">
                                                            {numToIndianStd(parseFloat(row[2]))}
                                                        </StyledTableCell>
                                                        {/* <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left">
                                                            {numToIndianStd(parseFloat(row[3]))}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left">
                                                            {numToIndianStd(parseFloat(row[4]))}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left">
                                                            {numToIndianStd(parseFloat(row[5]))}
                                                        </StyledTableCell> */}
                                                        <StyledTableCell  style={{fontWeight: '600',background:"#FBE4E3"}}  className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="right">
                                                            {numToIndianStd(parseFloat(row[6]))}
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{fontWeight: '600',color:"red"}} className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="right">
                                                            {numToIndianStd(parseFloat(row[7]))}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        }
    </div>
}

export default VendorReport;