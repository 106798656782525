import React, { useEffect, useState, useRef } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from "html2canvas";
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../state';
import useFromValidator from '../../common/formValidator';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { changeToLocalString } from '../../common/functions';
import Input from '@material-ui/core/Input';

import { version } from "../../common/enums";
import { Redirect } from "react-router-dom";

window.html2canvas = html2canvas;
const schema = {    
  entryDate:{
      presence: {allowEmpty: false, message: 'is required'}
  },
  periodIndex:{
      presence: {allowEmpty: false, message: 'is required'}
  },
};
const useStyles = makeStyles(theme => ({
  root: props => ({
    margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
  }),
  topBar: {
    marginBottom: theme.spacing(2)
  },
  link: {
    display: "flex"
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  formControl: {
    width: "100%"
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  progressBar: {
    margin: theme.spacing(2)
  },
  singleLineBorder: {
    borderTop: "solid 1px #F26522",
    borderBottom: "solid 1px #F26522"
  },
  doubleLineBorder: {
    borderTop: "double #F26522",
    borderBottom: "double #F26522"
  },
  reportContainer: {},
  reportHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  rawBatchName: {
    textAlign: "center",
    textDecoration: "underline",
    marginBottom: theme.spacing(2)
  },
  reminderTables: {
    padding: theme.spacing(2),
  },
  doctorNotesTables: {
    padding: theme.spacing(2),
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding:"6px",
    overflow: "hidden", 
    width:"90px"
  },
  body: {
    fontSize: "1em",
    backgroundColor: theme.palette.common.white,
    padding:"6px",
    overflow: "hidden", 
    width:"90px"
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

const tableHeader = JSON.parse(
  `[["Batch","Shed", "Age", "Egg Production", "Feed In(in KG)", "Feed Cost", "Cost per Egg", "Other Costs"]]`
);
//const purchasesTableHeader = "Date, Item, Bill Value"
const generateExcel = function(header, data/*, purchases*/) {
  console.debug(header, data)
  let csvData = "";
  header.forEach((column, index) => {
    if (index === 0) csvData = csvData + column;
    else csvData = csvData + "," + column;
  });
  console.debug(csvData)
  csvData = csvData + "\r\n";
  console.debug(csvData)
  data.forEach(row => {
    row.forEach((column, index) => {
      if (index === 0) csvData = csvData + column;
      //else if (index === 1) csvData = csvData + "";
      else csvData = csvData + "," + column;
    });
    csvData = csvData + "\r\n";
  });

  
/*if(purchases.purchaseOnBatchID !== undefined){
  if(purchases.purchaseOnBatchID.length >0){
      csvData = csvData + "\r\n";
      csvData = csvData + purchasesTableHeader + "\r\n";
      (purchases.purchaseOnBatchID).forEach((row, index)=>{ 
        csvData = csvData + (row.date.Valid ? moment(row.date.Time).format("DD-MM-YYYY") : "") + "," +
        row.item +","+ row.billValue + "\r\n";
      })
    }
  }*/
  console.debug(csvData)

  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csvData)
  );
  element.setAttribute("download", "report.csv");

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const EggsProduction = ({ showError, match }) => {
  const theme = useTheme();
  const [period, setPeriod] = useState(0);
  const [loading, setLoading] = useState(false);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [expense, setExpense] = useState([]);
  //const [valid, setValid] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  //const [showPurchases, setShowPurchases] = useState(false);
    //const [purchases, setPurchases] = useState({});
    const [entryDate, setEntryDate] = useState(null);
    const [expenseData, setExpenseData] = useState([])


    const [formState, hasError, handleChange] = useFromValidator(schema);


  const parseReportFromServer = function(data) {
    const { reportsArray } = data;
    setData(reportsArray);
    console.log(data);
  };


  const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }

  /*const numToIndianStd = function (data, digits){
    console.debug(data,"data")
    if(!isNaN(data)){
    return data.toLocaleString(user.locales,amountObj);
    }
    };*/

    const onload = () => {
      if(user.versionID === version.proPlus){
        setLoading(true);
        Promise.all([  
            getAllExpenseTypes(),
        ]).finally(()=>{
            setLoading(false);
        });
      }
  }
  useEffect(onload, []);

  const generateReport = function() {
    getExpenseForPreviousMonth(expense)
    showError("");
    setLoading(true);
    //onLoadReminder()
    const payload = {
      farmID: parseInt(match.params["farmID"]),
      periodID: period,
      expenseTypes: expense, 
      date: formState.values.entryDate
    };

    const response = api.post("reports/eggs-production-report", payload);

    response
      .then(res => {
        parseReportFromServer(res);
      })
      .catch(err => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError(err.message);
        } else {
          showError("Unknown Error");
        }
      })
      .finally(() => {
        setLoading(false);
        setEntryDate(new Date(payload.date))
      });
  };

   //Get all ExpenseType API Call
   const getAllExpenseTypes = () => {
    showError('');

    const response = api.get('expense-type');

    response.then(res => {
        setExpenseTypes([
            {
                expenseTypeName: "Other",
                id: 0
            },
            ...res]
        );
        
    }).catch(err => {
        if (err.message) {
            if (err.message === 'Bad Request' || err.message === 'No data found')
                console.log(err.message)
        } else {
            console.log('Unknown Error')
        }
    })
    return response
}

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  });
  const classes = useStyles({ isDesktop });

  const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
      });
;

   /* const onLoadReminder = () => {
        setLoading(true);
        setShowPurchases(false)
        setPurchases([])
        showError('');
        const getAllPurchasesOnBatchIDResponse = api.post(`farm/${farmID}/purchasesOnBatchID`,{
            batchId : batch.id
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Purchases Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        
        Promise.all([getAllPurchasesOnBatchIDResponse]).then(results => {
            if (results[2]) {
                setPurchases({...results[2]})
                setShowPurchases(true)
            }
        }).finally(() => {
            setLoading(false);
        });
    }*/
    
    const onChangeOfPeriod = function (event) {
        setPeriod(event.target.value);
      };
      const onChangeOfExpense = function (event) {
        //console.log("event.target.value", event.target.value)
        setSelected(event.target.value);
        const tempExp = event.target.value.map(index => expenseTypes[index].id);
        setExpense(tempExp);
        // getExpenseForPreviousMonth(tempExp)
      };

      const getExpenseForPreviousMonth = (tempExp) => {
        
        const payLoad = {
          farmID: parseInt(match.params["farmID"]),
          expenseTypes: tempExp, 
          date: formState.values.entryDate
        }

        const response = api.post("reports/expense-report", payLoad)

        response.then(res => {
          const { reportsArray } = res;
          setExpenseData(reportsArray);
        }).catch(err => {
          if (err.message) {
              if (err.message === 'Bad Request' || err.message === 'No data found')
                  showError(err.message)
          } else {
              showError('Unknown Error')
          }
      })

      }

  if(user.versionID !== version.proPlus){
        return(
            <Redirect to="/not-found" />
        );
  }
  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-start" className={classes.topBar}>
        <Grid>
          <Typography display="block" variant="h3" gutterBottom>
          Egg Production Cost Report
          </Typography>
        </Grid>
      </Grid>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={3} justify="flex-start" alignItems="center">
        <Grid item xs={6}>
                <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Entry Date"
                    format="dd/MM/yyyy"
                    value={formState.values.entryDate || null}
                    onChange={data => {
                        setEntryDate(data)
                        handleChange({target: {name: 'entryDate', value: data}});
                    }}
                    onFocus={e => e.target.blur()} 
                    animateYearScrolling
                    name="entryDate"
                    error={hasError('entryDate')}
                />
          </Grid>
          <Grid item   xs={isDesktop ? 2 :12}>
          <FormControl className={classes.formControl}>
          <InputLabel id="period-select-label">Period</InputLabel>
          <Select
            id="period-select"
            value={formState.values.periodIndex !== undefined ? formState.values.periodIndex : ""}
            //onChange={event => onChangeOfPeriod(event.target.value)}
            onChange={event =>{handleChange(event,onChangeOfPeriod)}}
            name="periodIndex"
          >
              <MenuItem key={0} value={1}>Daily</MenuItem>
              <MenuItem key={1} value={2}>Weekly</MenuItem>
              <MenuItem key={2} value={3}>Monthly</MenuItem>
          </Select>
        </FormControl>
        </Grid>
        <Grid item   xs={isDesktop ? 2 :12}>
        <FormControl className={classes.formControl}>
                <InputLabel id="expenseTypeName-select-label">Expense Type</InputLabel>
                <Select
                id="expenseTypeName-select"
                multiple
                input={<Input />}
                renderValue={selected => selected.map(expenseType => expenseTypes[expenseType].expenseTypeName).join(", ")}
                value={selected}
                onChange={event =>{handleChange(event,onChangeOfExpense)}}
                name="expenseTypeIndex"
                >
                {expenseTypes.map((expenseType,index)=>
                    <MenuItem key = {expenseType.id} value={index}>{expenseType.expenseTypeName}</MenuItem>
                )}
                </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              disabled={!formState.isValid}
              variant="contained"
              color="primary"
              onClick={generateReport}
            >
              Generate
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!formState.isValid || data.length < 1}
              variant="outlined"
              color="primary"
              onClick={generatePDF}
              endIcon={<PictureAsPdfIcon />}
            >
              Download PDF
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!formState.isValid || data.length < 1}
              variant="outlined"
              color="primary"
              onClick={() => generateExcel(tableHeader, data/* purchases*/)}
              endIcon={<GetAppIcon />}
            >
              Download Excel
            </Button>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      {loading ? (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progressBar} />
        </div>
      ) : (
        <div id="report-container" className={classes.reportContainer} ref={componentRef}>
          <div className={classes.reportHeader}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.rawBatchName}
            >
              Eggs Production {period === 2 ? "Weekly" : period === 3? "Monthly" : "Daily"} Report <br></br>
              {entryDate !== null ? "" + (entryDate.getDate()) + "/"+(entryDate.getMonth()+1) + "/" + entryDate.getFullYear() : ""}
            </Typography>
          </div>
          <Table 
          stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                {/* <StyledTableCell>Batch</StyledTableCell> */}
                <StyledTableCell>Shed Name</StyledTableCell>
                <StyledTableCell>Age</StyledTableCell>
                <StyledTableCell>Egg production</StyledTableCell>
                <StyledTableCell>Feed In Take (KG)</StyledTableCell>
                <StyledTableCell>Feed Cost</StyledTableCell>
                <StyledTableCell>Other Costs</StyledTableCell>
                <StyledTableCell>Cost/Egg</StyledTableCell>
              </StyledTableRow>
            </TableHead>
           <TableBody>
              {data.map((row, index) => (
                <StyledTableRow key={index}>
                {row.map((cols, col) => {
                    if(col ===0){
                      return null
                    }                      

                  return(
                  <StyledTableCell align="left" key = {col}
                  className={
                      row[0] === "" ? classes.singleLineBorder : undefined
                    }>
                    {
                        col === 5 || col === 6 || col === 7?
                        (
                        (!isNaN(cols) && cols.toString().indexOf('.') !== -1)?
                            changeToLocalString(cols, user.locales,amountObj)
                            :
                            cols.toLocaleString(user.locales,amountObj))
                            :
                            (
                            (!isNaN(cols) && cols.toString().indexOf('.') !== -1)?
                            changeToLocalString(cols, user.locales)
                            :
                            cols.toLocaleString(user.locales))
                    }
                  </StyledTableCell>
                  )})}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>

          <div className={classes.reportHeader}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.rawBatchName}
            >
            Expense Report
            </Typography>
          </div>
          <Table
          stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Expense</StyledTableCell>
                <StyledTableCell>Amount</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
                    {expenseData !== null ? expenseData.map((row, index) => (
                      <StyledTableRow key={index}>
                        {row.map((cols, col) => (
                          <StyledTableCell align="left" key = {col}>
                            {
                              col === 2 ? changeToLocalString(cols, user.locales,amountObj) : cols
                            }
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    )):null}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default EggsProduction;
