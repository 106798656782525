import React, {useEffect, useState, useRef} from 'react';
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'; 
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from 'html2canvas';
import moment from "moment";
import BatchesListBox from "./components/batchesListBox.js";
import { useReactToPrint } from 'react-to-print';


window.html2canvas = html2canvas;
const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    formControl: {
        width: '100%'
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    singleLineBorder: {
        borderTop: 'solid 1px #F26522',
        borderBottom: 'solid 1px #F26522'
    },
    doubleLineBorder: {
        borderTop: 'double #F26522',
        borderBottom: 'double #F26522'
    },
    boldedText:{
        fontWeight: 'bold'
    },
    reportContainer:{
       
    },
    reportHeader:{
        textalign:'right',
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(2)
    },
    rawMaterialName:{
        textAlign:'center',
        textDecoration:'underline'
    }
}));

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        overflow: "hidden", 
    },
    body: {
        fontSize: '1em',
        backgroundColor: theme.palette.common.white,
        padding:"6px",
        overflow: "hidden", 
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);


const tableHeader = JSON.parse(`
[
    {
        "birdsData" :["Opening Birds", "Culls", "Mortality", "Closing Birds", "Running Cost", "Value"]
    },
    {
        "eggsData" :["Closing", "Total Cost", "Value"]
    },
    {
        "feedData" :["Closing Feed", "Cost per tonne", "Value"]
    }
    
]`);

const generateCSVForSection = function (csvData, heading, header,data){
    csvData = csvData + '\r\n' + heading + '\r\n';

    header.forEach((column, index) => {
        if (index === 0)
            csvData = csvData + column
        else
            csvData = csvData + ',' + column
    });
    csvData = csvData + '\r\n';
    if (data !== null && data !== undefined){
        data.forEach(row => {
            row.forEach((column, index) => {
                if (index === 0)
                    csvData = csvData + column
                
                else
                    csvData = csvData + ',' + column
            });
            csvData = csvData + '\r\n';
        });
    }
    return csvData;
}

const generateExcel = function (label, header, data) {
    console.debug("excel ", header, data)
    let csvData = '' + label + " Stock Statement" ;
    csvData = generateCSVForSection(csvData, "Birds", header[0].birdsData, data.birdsData);

    csvData = generateCSVForSection(csvData, "Eggs", header[1].eggsData, data.eggsData);

    csvData = generateCSVForSection(csvData, "Feed",header[2].feedData, data.feedData);

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
    element.setAttribute('download', 'report.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);


}

const BatchStockStatement = function ({showError, match}) {
    const theme = useTheme();
    const [batch, setBatch] = useState("");

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        if (startDate && endDate && (startDate.getTime() <= endDate.getTime())) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [startDate, endDate]);


    const getChosenBatch = function(chosenValue) {
        setBatch(chosenValue);
    };


    const parseReportFromServer = function (data) {
        //const {reportsArray} = data;
        //console.log(reportsArray);
        setData(data);
        console.log(data)
    };

    const generateReport = function () {
        showError('');
        setLoading(true);
        const payload = {
            batchID:batch.id,
            startDate: startDate,
            endDate: endDate
        };

        const response = api.post('reports/batch-stock-statement', payload);

        response.then(res => {
            parseReportFromServer(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });

    };

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
      });



    return <div className={classes.root}>
        <Grid
            container
            alignItems='flex-start'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Stock Statement
                </Typography>
            </Grid>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container
                  spacing={3}
                  justify="flex-start"
                  alignItems="flex-start"
                  >
                  <Grid item   xs={isDesktop ? 3 :6}>
                <BatchesListBox 
                showError={showError}
                match={match}
                setValid={setValid}
                getChosenBatch={getChosenBatch}
                setLoading={setLoading}
                />               
                </Grid>
                <Grid container xs={isDesktop ? 6 :12}>
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="From"
                        format="dd/MM/yyyy"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="To"
                        format="dd/MM/yyyy"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
                </Grid>
                <Grid spacing={3}
                    style={{marginLeft: "2px"}}
                  justify="flex-start"
                  alignItems="flex-end" container xs={12}>
                <Grid item>
                    <Button disabled={!valid} variant="contained" color="primary"
                            onClick={generateReport}>Generate</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid } variant="outlined" color="primary"
                            onClick={generatePDF} endIcon={<PictureAsPdfIcon/>}>Download
                        PDF</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid } variant="outlined" color="primary"
                            onClick={() => generateExcel(batch.name, tableHeader, data)}
                            endIcon={<GetAppIcon/>}>Download Excel</Button>
                </Grid>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
        {
            loading ?
                <div className={classes.progressContainer}>
                    <CircularProgress className={classes.progressBar}/>
                </div> : 
                <div id='report-container' className={classes.reportContainer} ref={componentRef}>
                        <Typography style={{marginTop: "2rem"}} variant="body1" component="p">
                            <b>Period</b> {moment(startDate).format('DD/MM/YYYY')} to {moment(endDate).format('DD/MM/YYYY')}
                        </Typography>
                    <div className={classes.reportHeader}>    
                        <Typography variant="h3" component="h3" className={classes.rawMaterialName}>
                            {batch.name} Stock Statement 
                        </Typography>
                    </div>
                        <Typography variant="h4" component="h4" className={classes.rawMaterialName}>
                            Birds
                        </Typography>                    
                        <Table style={{ tableLayout: "fixed"}} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Opening Birds</StyledTableCell>
                                
                                <StyledTableCell>Culls</StyledTableCell>
                                <StyledTableCell>Mortality</StyledTableCell>
                                <StyledTableCell>Closing Birds</StyledTableCell>
                                <StyledTableCell>Running Cost</StyledTableCell>
                                <StyledTableCell>Value</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {data !== null && data !== undefined &&
                            data.birdsData !== null && data.birdsData !== undefined &&
                            data.birdsData.map((row, index) => (
                                <StyledTableRow key={index}>
                                    {row.map((col,inx)=>(
                                        <StyledTableCell key ={inx}align="left"> {col}</StyledTableCell>

                                    ))}

                                </StyledTableRow>
                            ))}       
                        </TableBody>     
                    </Table>
                    <br/><br/><br/>
                    <Typography variant="h4" component="h4" className={classes.rawMaterialName}>
                            Eggs
                    </Typography>
                    <Table style={{ tableLayout: "fixed"}} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>                                
                                <StyledTableCell>Closing</StyledTableCell>
                                <StyledTableCell>Cost</StyledTableCell>
                                <StyledTableCell>Value</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {data !== null && data !== undefined &&
                            data.eggsData !== null && data.eggsData !== undefined &&
                            data.eggsData.map((row, index) => (
                                <StyledTableRow key={index}>
                                    {row.map((col,inx)=>(
                                        <StyledTableCell key ={inx}align="left"> {col}</StyledTableCell>

                                    ))}

                                </StyledTableRow>
                            ))}       
                        </TableBody>     
                    </Table>
                    <br/><br/><br/>
                    <Typography variant="h4" component="h4" className={classes.rawMaterialName}>
                            Feed
                    </Typography>
                    <Table style={{ tableLayout: "fixed"}} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Closing Feed</StyledTableCell>
                                
                                <StyledTableCell>Cost per tonne</StyledTableCell>
                                <StyledTableCell>Value</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {data !== null && data !== undefined &&
                            data.feedData !== null && data.feedData !== undefined &&
                            data.feedData.map((row, index) => (
                                <StyledTableRow key={index}>
                                    {row.map((col,inx)=>(
                                        <StyledTableCell key ={inx}align="left"> {col}</StyledTableCell>

                                    ))}

                                </StyledTableRow>
                            ))}       
                        </TableBody>     
                    </Table>
                </div>
        }
    </div>
}

export default BatchStockStatement;