import React, { useEffect, useState } from "react";
import api from "../../common/api";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from "../../components";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import StockAdjustments from "./components/stockAdjustment";
import Icon from "@material-ui/core/Icon";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1),
  }),
  topBar: {
    marginBottom: theme.spacing(2),
  },
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

let currentStock = undefined;
let editMode = false;

const StockAdjustmentsView = ({
  showError,
  showMessage,
  materialNames,
  history,
}) => {
  const [stockadjustments, setstockadjustments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showStockDialog, setshowStockDialog] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [materialMaster, setMaterialMaster] = useState([]);
  const [errorMessage, setErrorMessage] = useState('')
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const classes = useStyles({ isDesktop });

  function onNewStockAdjustment(event) {
    editMode = false;
    event.preventDefault();
    setshowStockDialog(true);
  }

  function handleModalClose() {
    setErrorMessage(null);
    currentStock = undefined;
    setshowStockDialog(false);
  }

  function handleDeleteConfirmClose() {
    setShowDeleteConfirm(false);
  }

  const removeErrorMessage = value =>{
    setErrorMessage('');
  }

  const onStockAdjustmentDeleteConfirm = () => {
    setShowDeleteConfirm(false);
    setLoading(true);
    showError("");
    const response = api.post(`deleteStockAdjustment`, {
        ...currentStock,
    });

    response.then((res) => {
        let index = stockadjustments.findIndex(
          (srcStock) => {
            return srcStock.adjustmentDate === currentStock.oldAdjustmentDate;
          }
        );

        let newStock = [...stockadjustments];
        newStock.splice(index, 1);
        setstockadjustments(newStock);
    }).catch((err) => {
      if (err.message) {
        if (err.message === "Bad Request" || err.message === "No data found")
          showError("Stock Adjustments not Deleted");
        else 
          showError(err.message);
      } else {
        showError("Unknown Error");
      }
    }).finally(() => {
      setLoading(false);
      currentStock = undefined;
    });
  };

  const onDelete = (stockadjustment) => {
    currentStock = stockadjustment;
    setShowDeleteConfirm(true);
  };

  const onDataSave = (stockadjustment, setpopuploading) => {
    //setLoading(true);
    showError("");
    showMessage("");
    const response = api.post("createStockAdjustment", {
      ...stockadjustment,
    });

    response.then((res) => {
      setstockadjustments([...stockadjustments, res]);
      setshowStockDialog(false);
    }).catch((err) => {
      if (err.message) {
        if (err.message === "Bad Request" || err.message === "No data found")
          setErrorMessage("Data not added");
        else setErrorMessage(err.message);
      } else {
        setErrorMessage("Unknown Error");
      }
    }).finally(() => {
      setLoading(false);
      setpopuploading(false);
    });
  };

  const onDataEdit = (stockadjustment) => {
    currentStock = {
      ...stockadjustment,
    };
    editMode = true;
    setshowStockDialog(true);
  };

  const onDataUpdate = (stockadjustment, setpopuploading) => {
    //setLoading(true);
    console.log(currentStock, stockadjustment);
    const response = api.post(`updateStockAdjustment`,{
      ...stockadjustment,
    });

    response.then((res) => {
      let index = stockadjustments.findIndex(
        (srcStock) => {
          return srcStock.adjustmentDate === stockadjustment.oldAdjustmentDate;
        }
      );
      setstockadjustments([
        ...stockadjustments.slice(0, index),
        { ...res },
        ...stockadjustments.slice(index + 1),
      ]);
      setshowStockDialog(false);
    }).catch((err) => {
      if (err.message) {
        if (err.message === "Bad Request" || err.message === "No data found")
        setErrorMessage("Data not Updated");
        else setErrorMessage(err.message);
      } else {
        setErrorMessage("Unknown Error");
      } 
    }).finally(() => {
      currentStock = undefined;
      setLoading(false);
      setpopuploading(false);
    });
  };

  //Get all Data API Call
  const getAllAdjustments = () => {
    setshowStockDialog(false);
    showError("");
    showMessage("");

    const response = api.get("getStockAdjustments");

    response.then((res) => {
      setstockadjustments([...res]);
    }).catch((err) => {
      if (err.message) {
        if (err.message === "Bad Request" || err.message === "No data found")
          showError("No Data Found");
        else showError(err.message);
      } else {
        showError("Unknown Error");
      }
    });
    return response;
  };

  const getAllMaterialTypes = (materialId) => {
    showError("");
    showMessage("");

    const response = api.get(`material-types`);
    response.then((res) => {
        setMaterialTypes([...res]);
    }).catch((err) => {
      if (err.message) {
        if (err.message === "Bad Request" || err.message === "No data found")
          showError("No Material Type Found Catch Error");
        else showError(err.message);
      } else {
        showError("Unknown Error");
      }
    });

    return response;
  };

  const getAllMaterialMaster = () => {
    showError("");
    showMessage("");

    const response = api.get(`material-master`);
    response.then((res) => {
      setMaterialMaster([...res]);
    }).catch((err) => {
      if (err.message) {
        if (err.message === "Bad Request" || err.message === "No data found")
          showError("No Materials Found Catch Error");
        else showError(err.message);
      } else {
        showError("Unknown Error");
      }
    });

    return response;
  };
  
  const onload = () => {
    setLoading(true);
    Promise.all([getAllAdjustments(), getAllMaterialTypes(), getAllMaterialMaster()]).finally(() => {
      setLoading(false);
    });
  };
  useEffect(onload, []);

  let actions =  [
      {
        icon: () => <Icon color="primary">edit</Icon>,
        tooltip: "Edit Data",
        onClick: (event, rowData) => onDataEdit(rowData),
      },
      {
        icon: () => <Icon color="error">delete</Icon>,
        tooltip: "Delete Data",
        onClick: (event, rowData) => onDelete(rowData),
      },
    ];

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="space-between"
        alignItems="flex-end"
        className={classes.topBar}
      >
          
        <Grid>
          <Typography display="block" variant="h3" gutterBottom>
            Stock Adjustments
          </Typography>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={onNewStockAdjustment}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <MaterialTable
        isLoading={loading}
        columns={[
          //{ title: "id", field: "id", hidden: true },
          {
            title: 'Date', field: 'adjustmentDate', type: 'datetime', //defaultSort: 'desc',
            //cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},
            editable: 'never',
            render: rowData => {
                return <span>{moment(rowData.adjustmentDate).format("DD-MM-YYYY")}</span>
            }
        },
        ]}
        data={stockadjustments}
        title="Stock Adjustment Table"
        options={{
          actionsColumnIndex: -1,
          search: true,
          showTitle: true,

          toolbar: true,
        }}
        actions={actions}
      />
      <StockAdjustments
        deleteErrorMessage={removeErrorMessage}
        errorMessage={errorMessage}
        showDialog={showStockDialog}
        handleClose={handleModalClose}
        showError={showError}
        onSave={onDataSave}
        onEdit={onDataUpdate}
        editMode={editMode}
        data={currentStock}
        materialTypes={materialTypes}
        materialNames={materialNames}
        materialMaster={materialMaster}
      />
      <ConfirmDialog
        showDialog={showDeleteConfirm}
        handleClose={handleDeleteConfirmClose}
        onConfirm={onStockAdjustmentDeleteConfirm}
        title="Delete Data ?"
        subText="This action will delete the Nutritional Profile Details. Please confirm."
      />
    </div>
  );
};

export default StockAdjustmentsView;
