import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";

import Typography from "@material-ui/core/Typography";
import moment from 'moment';

const useCardStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    cardActionsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    cardAction: {
        margin: theme.spacing(0),
        marginTop: theme.spacing(2),
        backgroundColor: '#ff1744',
        color: 'white'
    },
    grid: {
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3)
    }
}));

const Mobile = ({ data }) => {
    const classes = useCardStyles();

    return <Grid item>
        <Card className={classes.card}>
            <CardHeader
                title={`Date : ${moment(data.date).format("DD-MM-YYYY")}`}
            />
            <CardContent>
                <Grid
                    container
                    justify='space-between'
                    alignItems='flex-end'
                >
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.farmName}
                        </Typography>
                        <Typography color="textSecondary">
                            Farm Name
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.shedName}
                        </Typography>
                        <Typography color="textSecondary">
                            Shed Name
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {moment(data.date).format("DD-MM-YYYY")}
                        </Typography>
                        <Typography color="textSecondary">
                            Date
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {(data.quantity).toFixed(2)}
                        </Typography>
                        <Typography color="textSecondary">
                            Quantity(in tonnes)
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {(data.deliveryWeight).toFixed(2)}
                        </Typography>
                        <Typography color="textSecondary">
                            Delivery Weight
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {(data.costPerTonne).toFixed(2)}
                        </Typography>
                        <Typography color="textSecondary">
                            Cost Per Tonne
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Grid>
};

export default Mobile;