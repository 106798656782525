import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog,MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import InvoiceSetting from "./components/invoiceSetting";
import Icon from '@material-ui/core/Icon';



const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentInvoiceSettings = undefined;
let editMode = false;

const InvoiceSettingsView = ({showError, showMessage, history}) => {

    const [invoiceSettings, setInvoiceSettings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showInvoiceSettingsDialog, setShowInvoiceSettingsDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    function onNewInvoiceSettings(event,) {
        editMode = false;
        event.preventDefault();
        setShowInvoiceSettingsDialog(true);

    }

    function handleInvoiceSettingsClose() {
        currentInvoiceSettings = undefined;
        setShowInvoiceSettingsDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onInvoiceSettingsDeleteConfirm = () => {
        if (currentInvoiceSettings && currentInvoiceSettings.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`invoiceSettings/${currentInvoiceSettings.id}`);

            response.then(res => {
                if (res) {
                    let index = invoiceSettings.findIndex((srcInvoiceSettings) => {
                        return srcInvoiceSettings.id === currentInvoiceSettings.id;
                    });
                    let newInvoiceSettings = [...invoiceSettings];
                    newInvoiceSettings.splice(index, 1);
                    setInvoiceSettings(newInvoiceSettings);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('InvoiceSettings not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentInvoiceSettings = undefined;
            });

        }
    };

    const onDelete = (invoiceSettings) => {
        currentInvoiceSettings = invoiceSettings;
        setShowDeleteConfirm(true);
    };

    const onInvoiceSettingsSave = (invoiceSettingsJson) => {
        setLoading(true);
        // showError('');
        showMessage('');

        const response = api.post('invoiceSettings', {
            ...invoiceSettingsJson,
        });

        response.then(res => {
            setInvoiceSettings([...invoiceSettings, res]);
            setShowInvoiceSettingsDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('InvoiceSettings not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        })
    };


    const onInvoiceSettingsEdit = (invoiceSettings) => {
        console.log(invoiceSettings);
        currentInvoiceSettings = {
            ...invoiceSettings,
        };
        editMode = true;
        setShowInvoiceSettingsDialog(true);
    };

    const onInvoiceSettingsUpdate = (invoiceSettingsJson) => {
        setLoading(true);
        // showError('');
        const response = api.post(`invoiceSettings/${invoiceSettingsJson.id}`, {
            ...invoiceSettingsJson,

        });
        response.then(() => {
            let index = invoiceSettings.findIndex((srcinvoiceSettings) => {
                return srcinvoiceSettings.id === invoiceSettingsJson.id;
            });
            setInvoiceSettings([...invoiceSettings.slice(0, index),
                {...invoiceSettingsJson},
                ...invoiceSettings.slice(index + 1)]);
            setShowInvoiceSettingsDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('InvoiceSettings not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };
    //Button handler
     

    //Get all Banks API Call
    const getAllInvoiceSettings = () => {
        setShowInvoiceSettingsDialog(false);
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('invoiceSettings');

        response.then(res => {
            setInvoiceSettings([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No InvoiceSettings Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onload=()=>{
        getAllInvoiceSettings()
    }
    useEffect(onload, []);
    

    let invoiceSettingsNameColumn = {title: 'InvoiceSettings', field: 'name', editable: 'never', defaultSort:'asc',
        render: rowData => <Mobile data={rowData}
                                   onEdit={() => {
                                       onInvoiceSettingsEdit(rowData)
                                   }}
                                   onDelete={() => {
                                       onDelete(rowData)
                                   }}
        />
    };
    let actions = [];
    if (isDesktop) {
        invoiceSettingsNameColumn = {title: 'Name', field: 'name', editable: 'never', defaultSort:'asc', hidden:true,};
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit InvoiceSettings',
                onClick: (event, rowData) => onInvoiceSettingsEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete InvoiceSettings',
                onClick: (event, rowData) => onDelete(rowData)
            }
        ]
    }


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                Invoice Settings
                </Typography>
            </Grid>
            {invoiceSettings.length < 1 ?
                <Grid>
                    <Button variant="contained" color="primary" onClick={onNewInvoiceSettings}>Add Invoice Settings</Button>
                </Grid>
                :""
            }
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'id', hidden: true},
                {title: 'Company Name', field: 'companyName', hidden: !isDesktop, editable: 'never'},
                {title: 'Address', field: 'address', hidden: !isDesktop, editable: 'never'},
                {title: 'Payment Terms', field: 'paymentTerms', hidden: !isDesktop, editable: 'never'},
                {title: 'Terms And Conditions', field: 'termsAndConditions', hidden: !isDesktop, editable: 'never'},
                {title: 'GST Number', field: 'gstNumber', hidden: !isDesktop, editable: 'never'},
                {title: 'Bank Name', field: 'bankName', hidden: !isDesktop, editable: 'never'},
                {title: 'Account Number', field: 'accountNumber', hidden: !isDesktop, editable: 'never'},
                {title: 'IFSC Code', field: 'ifscCode', hidden: !isDesktop, editable: 'never'},
                {title: 'Branch Name', field: 'branchName', hidden: !isDesktop, editable: 'never'},
                invoiceSettingsNameColumn,

            ]}
            data={invoiceSettings}
            title='InvoiceSettings Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true
            }}
            actions={actions}
        />
        <InvoiceSetting deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showInvoiceSettingsDialog} 
            handleClose={handleInvoiceSettingsClose} onSave={onInvoiceSettingsSave} 
            onEdit={onInvoiceSettingsUpdate}
               editMode={editMode} data={currentInvoiceSettings} />
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onInvoiceSettingsDeleteConfirm}
                       title='Delete InvoiceSettings ?'
                       subText='This action will delete the InvoiceSettings Details. Please confirm.'/>
    </div>
}

export default InvoiceSettingsView;