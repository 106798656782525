import React, { useState, useEffect } from "react"
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import useFromValidator from '../../../common/formValidator';
import Button from "@material-ui/core/Button";
import api from '../../../common/api'; 

var schema = {
    customerVendor: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    amount: {
        presence: {allowEmpty: false, message: 'is required',
        numericality: {
            greaterThanOrEqualTo: 1,
        }}
    }
};

const useStyles = makeStyles(theme => ({
    marginRight: {
        marginRight: theme.spacing(2),
    },
    marginTop: {
        marginTop: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
    },
    addButton: {
        textAlign: "right",
        marginBottom: theme.spacing(2),
        minWidth: 160,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
        paddingBottom: theme.spacing(1.25)
    },
    textField: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        minWidth: 160
    },
}));

const Payment = ({showPaymentDialog, handlePaymentClose, type, customerVendorList, month, taxType, onSave, onEdit, data, editMode, invoiceReqInput, showError, isLine}) => {

    const classes = useStyles();
    const [formState,hasError,handleChange,updateData, setFormState] = useFromValidator(schema);
    const [customerVendor, setCustomerVendor] = useState(undefined)
    const [pendingAmount, setPendingAmount] = useState(undefined)
    const [originalPendingAmt, setOriginalPendingAmt] = useState(0)
    const [credits, setCredits] = useState(undefined)
    const [checkMaxAmt, setCheckMaxAmt] = useState(false)
    const [amount, setAmount] = useState(undefined)

    const resetBeforeEdit = () => {
        if (showPaymentDialog && !editMode) {
            setCustomerVendor(undefined)
            setPendingAmount(undefined)
            setCredits(undefined)
            setCheckMaxAmt(true)
            setAmount(undefined)
            console.log("customerVendorList", customerVendorList)
        } else if (showPaymentDialog && editMode) {
            getPendingAmountAndCredit({
                ...invoiceReqInput,
                isLine,
            })
            setCheckMaxAmt(true)
            let cv = data.customerID && data.customerID !== 0 ? data.customerID : data.vendorID
            let editData = {
                amount: data.amount,
                customerVendor: cv
            }
            updateData(editData, editMode)
            setAmount(data.amount)
            setCustomerVendor(cv)
        }
    }
    
    useEffect(resetBeforeEdit ,[showPaymentDialog])

    const handleNameChange = (event) => {
        let id = event.target.value
        const request = {
            type,
            taxType,
            payMonth: month,
            categoryID: id, // CategoryID is either customer_id or vendor_id
            isLine,
        };
        setCustomerVendor(id)
        getPendingAmountAndCredit(request)
    }

    const handleAmountChange = (event) => {
        let amt = event.target.value
        // console.log(amt, pendingAmount, formState.values.amount)
        setPendingAmount((formState.values.amount ? formState.values.amount : 0) + (+pendingAmount) - (+amt))
        setFormState({
            ...formState,
            values:{
                ...formState.values,
                amount: +amt,
            }
        })
        setAmount(amt)
    }

    // Get the sum of tcs/tds Pending Amount of customer between the month
    const getPendingAmountAndCredit = (request) => {
        showError('')
        console.log("request", request)
        const response = api.post('pending-amount-and-credits', request);

        response.then(res => {
            if (res) {

                let pendingAmt = res.pendingAmount ? (res.pendingAmount - (data.amount ? data.amount : 0)) : 0
                setPendingAmount(pendingAmt)
                setOriginalPendingAmt(res.pendingAmount)
                setCredits(res.credit)
            }
            
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request') {
                    showError('No Customers Found');
                }
                else {
                    setPendingAmount(0)
                    setCredits(0)
                }
            } else {
                console.log('Unknown Error')
            }
        });

    }

    const handleSavePayment = () => {
        
        let index = customerVendorList.findIndex(ele => ele.id === formState.values.customerVendor)
        let state = {
            customerVendor: customerVendorList[index],
            amount: parseInt(amount),
        }

        if (editMode) {
            onEdit(state, data.id)
        } else {
            onSave(state)
        }
        
        handlePaymentClose()
    }

    return <Dialog maxWidth='md' disableBackdropClick open={showPaymentDialog} onClose={handlePaymentClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title">Add  Payment
            <IconButton aria-label="close" className={classes.closeButton} onClick={handlePaymentClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">{type === 1 ? "Customer" : "Vendor"}</InputLabel>
              <Select
                id="customerVendor"
                name="customerVendor"
                onChange={event => handleChange(event, handleNameChange)}
                value={
                  customerVendor !== undefined
                    ? customerVendor
                    : ""
                }
              >
                {customerVendorList ? 
                customerVendorList.map(data => (
                    <MenuItem key={data.id} value={data.id}>
                    {data.name || data.companyName}
                    </MenuItem>
                )):null}
              </Select>
            </FormControl>
            <InputLabel className={classes.marginTop} id="type-select-outlined-label">
                {pendingAmount !== undefined
                  ? "Pending amount: " + (pendingAmount.toFixed(2)) 
                  : null}
              </InputLabel>
 
              <InputLabel className={classes.marginTop} id="type-select-outlined-label">
                {credits !== undefined
                  ? `Credits available: ${credits.toFixed(2)}`
                  : null}
              </InputLabel>
            
            <TextField
                error={hasError('amount')}
                className={classes.textField}
                label="Amount"
                name="amount"
                type="number"
                value={amount !== undefined ? amount : ''}
                variant="outlined"
                onChange={(event) => {
                    if (checkMaxAmt) {
                        let newTI = originalPendingAmt + (editMode ? data.amount : 0)
                        console.log(newTI, data)
                        if (event.target.value <= Math.abs(newTI)) {
                            handleChange(event, handleAmountChange)
                        }
                    } else {
                        handleChange(event, handleAmountChange)
                    }
                }}
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSavePayment}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
        
    </Dialog>
}

export default Payment
