import React from 'react';
import moment from 'moment';
import {useGlobalState} from '../../../state';

import { MaterialTable } from '../../../components';
import { version } from 'react';

const PurchaseMaterialTable = ({
    purchaseMaterials,
    actions
})=>{
    const [{user}] = useGlobalState();


    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }

    function formatPurchaseMaterials(){
        let tArray = [];
        for(var i in purchaseMaterials){
            var item = purchaseMaterials[i];
            var nObj = {
                date : item.date,
                ...item,
                ...item.purchaseMaterials[0] //Only one material per array in pro version
            };
            nObj['mID'] =  nObj.id; // temp `id`
            nObj['id'] = nObj.purchaseID;
            tArray.push(nObj);
        }
        return tArray;
    }
    
    return(
        <MaterialTable 
        columns={[
            { title: 'id', field: 'id', hidden: true },
            {
                title: 'No', field: 'purchaseID', editable: 'never',
                render: (rowData) => {
                    return <span>{rowData.purchaseID}</span>
                }
            },
            {
                title: 'Date', field: 'date', type: 'datetime', defaultSort: 'desc',
                cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},
                editable: 'never',
                render: rowData => {
                    return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                }
            },
            { title: 'Item Name', field: 'materialName', editable: 'never' },
            {
                title: 'Inventory Type', field: 'inventoryType', editable: 'never', hidden : user.versionId===version.pro,
                render: (rowData) => {
                    return <span>{rowData.inventoryType === 1 ? 'Feed Mill' : (`${rowData.farmName!==undefined &&rowData.farmName.String !== undefined ? rowData.farmName.String : ""}  (  ${rowData.shedName!==undefined && rowData.shedName.String !== undefined ? rowData.shedName.String : "" } )`)}</span>
                }
            },
            {
                title: 'Total amount', field: 'billValue', editable: 'never',
                render: (rowData) => {
                    let billValue = 0;

                    let taxValue = 1; //If there is no Tax then multiply by 1

                    // If the Tax % is greater than 0, then calculate tax in decimals
                    // if (formState.values.tax < 0) {
                    //     taxValue = (1 + (formState.values.tax / 100));
                    // }

                    if (rowData.weightType === 1) {
                        billValue = (rowData.billWeight * rowData.costPerUnit) * taxValue;
                    } else {
                        billValue = (rowData.deliveryWeight * rowData.costPerUnit) * taxValue;
                    }

                    return <span>{billValue.toLocaleString(user.locales,amountObj) }</span>
                },
            },
            // {
            //     title: 'Remaining Balance', field: 'remainingBalance', editable: 'never',
            //     cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
            //     render: rowData => {
            //         let totalAmount = 0
            //         let gstAmount = 0
            //         let tdsAmount = 0
            //         let tcsAmount = 0
            //         let billValue = 0
            //         let totalAmountPaid = 0

            //         if(!isPro){
            //             if (rowData && rowData.purchaseMaterials) {
            //                 let purchaseMaterials = rowData.purchaseMaterials
            //                 purchaseMaterials.forEach(purchaseMaterial => {
            //                     let gstTaxAmt = parseFloat((purchaseMaterial.billValue * (purchaseMaterial.gst ? purchaseMaterial.gst : 0) / 100).toFixed(2))
            //                     let tdsTaxAmt = parseFloat((purchaseMaterial.billValue * (rowData.tds ? rowData.tds : 0) / 100).toFixed(2))
            //                     let tcsTaxAmt = parseFloat((purchaseMaterial.billValue * (rowData.tcs ? rowData.tcs : 0) / 100).toFixed(2))
            //                     gstAmount += gstTaxAmt
            //                     tdsAmount += tdsTaxAmt
            //                     tcsAmount += tcsTaxAmt
            //                     billValue += purchaseMaterial.billValue
            //                 })
            //             }
                       
            //             let gstTCSVal = gstAmount * (rowData.tcs/100)
            //             let totalTCS = Math.ceil(tcsAmount + gstTCSVal)
            //             totalAmount = billValue + totalTCS + gstAmount - tdsAmount
            //         }
            //         else{
            //             totalAmount = rowData.billValue;
            //         }
                    
            //         if (rowData && rowData.purchasePayments && rowData.purchasePayments.length) {
            //             let purchasePayments = rowData.purchasePayments
            //             purchasePayments.forEach(ele => {
            //                 totalAmountPaid += ele.paymentAmount
            //             })
            //         }

            //         let reminingAmount = totalAmount - totalAmountPaid;
            //         if (rowData.paymentStatus === 2 && reminingAmount < 1) {
            //             reminingAmount = 0
            //         }

            //         return <span>{reminingAmount.toLocaleString(user.locales,amountObj)}</span>

            //     }
            // },
            // {
            //     title: 'Payment Status', field: 'paymentStatus', editable: 'never',
            //     cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
            //     render: (rowData) => {
            //         let statusIcon = [
            //         <Tooltip title="Not Paid"><CloseIcon className={classes.red} /></Tooltip>,
            //         <Tooltip title="Tax Not Paid"><CancelIcon style={{fontSize:"12"}}  className={classes.red} /></Tooltip>
            //         ]
                    
                    
            //         // let tdsValue = rowData.totalBillValue * (rowData.tds / 100)
            //         let remaningBalance;
            //         if(!isPro){
            //             remaningBalance = (rowData.totalBillValue - ((rowData.totalBillValue * (rowData.tcs / 100))) - (rowData.totalBillValue * (rowData.tds/100)) - rowData.totalAmountPaid).toFixed(2);
            //         }
            //         else{
            //             remaningBalance = rowData.totalBillValue - rowData.totalAmountPaid;
            //         }

            //         let remaningBalanceWithoutTaxes = ((rowData.totalBillValue * (rowData.tcs / 100)) + (rowData.totalBillValue * (rowData.tds/100))) - rowData.totalTaxAmountPaid;


            //         if (rowData.paymentStatus === 1 && remaningBalance >= 1) {
            //             statusIcon.shift()
            //             statusIcon.unshift(<Tooltip title="Partially Paid"><QueryBuilderIcon /></Tooltip>)
            //         } else if (rowData.paymentStatus === 2 || (rowData.paymentStatus === 1 && remaningBalance < 1)) {
            //             statusIcon.shift()
            //             statusIcon.unshift(<Tooltip title="Paid"><DoneIcon className={classes.green} /></Tooltip>)
            //         }

            //         if(!isPro){
            //             if (rowData.totalTaxAmountPaid > 0 && remaningBalanceWithoutTaxes >= 1) { // Partially paid
            //                 statusIcon.pop()
            //                 statusIcon.push(<Tooltip title="Tax Partially Paid"><QueryBuilderIcon style={{fontSize:"12"}}  /></Tooltip>)
            //             } else if (rowData.totalTaxAmountPaid > 0 && remaningBalanceWithoutTaxes < 1) { // Fully paid
            //                 statusIcon.pop()
            //                 statusIcon.push(<Tooltip title="Tax Paid"><CheckCircleIcon style={{fontSize:"12"}}  className={classes.green} /></Tooltip>)
            //             }
            //         }

            //         if ((rowData.tcs === 0 && rowData.tds === 0)) {
            //             statusIcon.pop()
            //         }

            //         return <div style={{
            //             display:"flex",
            //             justifyContent:"center",
            //             alignItems:"center"
            //         }} >{statusIcon}</div>
            //     }
            // }
        ]}
        data={formatPurchaseMaterials()}
        options={{
            actionsColumnIndex: -1,
            search: true,
            showTitle: true,
            
            toolbar: true,
        }}
        //style={{boxShadow: 'unset'}}
        title='Purchase Table'
        actions={actions}
    />
    );
};

export default PurchaseMaterialTable;