import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {useGlobalState} from '../../../../state';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content',
        backgroundColor: theme.palette.profile.box,
        padding: '1em',
        borderRadius: '1em',
        marginTop: '1em'
    },
    avatar: {
        width: 60,
        height: 60
    },
    name: {
        textAlign: "center",
        marginTop: theme.spacing(1),
        color:theme.palette.profile.name
    },
    bio: {
        color: theme.palette.profile.bio
    }
}));

const Profile = props => {
    const {className, ...rest} = props;
    const [{user}] = useGlobalState();

    const classes = useStyles();

    const profile = {
        name: user.firstName +" " + user.lastName,
        avatar: user.profilePic || '/images/chick.webp',
        bio: user.role ||  'Managing Director'
    };

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Avatar
                alt="Person"
                className={classes.avatar}
                component={RouterLink}
                src={profile.avatar}
                to="/settings"
            />
            <Typography
                className={classes.name}
                variant="h4"
            >
                {profile.name}
            </Typography>
            <Typography className={classes.bio} variant="body2">{profile.bio} ( {user.versionName} )</Typography>
        </div>
    );
};

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;