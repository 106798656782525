/*
Wrapper to lazy load Material Table, this allows code splitting and reduces the initial package size.
 */
import React, {Suspense,useState,useRef, useEffect} from 'react';
import AppProgressBar from '../Progress';
import {TableIcons} from "../index";
import useTheme from '@material-ui/styles/useTheme';


const MaterialTable = React.lazy(() => import('material-table'));

const Table = (props) => {
    const { options, columns, ...rest } = props;
    const {pageSize} = options;
    const [currentPage,setCurrentPage] = useState(0);
    const defaultPageSize = 5
    const [rowsPerPage,setRowsPerPage] = useState(pageSize || defaultPageSize); 
    const tableRef = useRef(null);
    const theme = useTheme();

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    const onChangeRowsPerPage = (rows) => {
        setRowsPerPage(rows);
    }
    
    useEffect(() => {
        if (!isNaN(pageSize) && pageSize > 0)
           setRowsPerPage(pageSize);
       
        if (tableRef.current){
            tableRef.current.dataManager.changePageSize(pageSize || defaultPageSize);
                // tableRef.current.onChangePage(null, currentPage);
        }
    }, [pageSize]);

    const renderColumn = (column) => {
        return column?.map((col) => ({
            ...col
        }))
    }
    return <Suspense fallback={<AppProgressBar loading={true}/>}>
                <MaterialTable
                    tableRef={tableRef}
                    icons={
                        TableIcons
                    }
                    {...rest}
                    columns={renderColumn(columns)}
                    options={{
                            ...options,
                            initialPage:currentPage,
                            pageSize:rowsPerPage,
                            headerStyle: {
                                backgroundColor: theme.palette.primary.secondary,
                                color: theme.palette.text.default
                            },
                            rowStyle: rowData => {
                                if(rowData?.rowType === 'Footer'){
                                    return {fontWeight: 'bold'}
                                }
                                return {}
                            }
                        }
                    }
                    onPageChange={onPageChange}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                />
            </Suspense>
};

export default Table;
//export default Table;