import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

const schema = {

    materialNameIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    
    costPerUnit: {
        presence: { allowEmpty: false, message: '' },
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    quantity: {
        presence: { allowEmpty: false, message: 'is required' },
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    /*inventoryType: {
        presence: { allowEmpty: false, message: 'is required' }
    },*/
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
    },
    formControl: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        minWidth: 160,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    datePicker: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth: 160,
    },
    dialogWidth: {
        minWidth: "1042px"
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '2'
    },
}));

const Material = ({ deleteErrorMessage, errorMessage, materialTypes, materialNames, materials, farms, sheds, onGetShed, onGetMaterialNames, onEdit, data, onSave, handleClose, showDialog, editMode = false, materialIndex }) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const[popupLoading, setPopupLoading]= useState(false);

    //const [weightType,setweightType]= useState(0)
    // const [sheds,setSheds]= useState([])
    //Called if the Farm Property is Set for Editing

    const resetBeforeEdit = () => {
        if (showDialog) {
            updateData(data, editMode);
        }
    };

    useEffect(resetBeforeEdit, [showDialog]);
    

    const onSubmit = () => {
        setPopupLoading(true)
        console.log("materials During Save", formState.values);
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        let tempData = {
            ...formState.values,
            materialNameID: materials[formState.values.materialNameIndex].materialId,
            materialName: materials[formState.values.materialNameIndex].materialName,
        }
        
        let billValue = 0;

        billValue = ( formState.values.quantity * 30 ) * formState.values.costPerUnit

        tempData = {
            ...tempData,
            billValue: billValue
        }
        console.log("tempdata when save", tempData)
        if (editMode) {
            onEdit(tempData, setPopupLoading);
        }
        else
            onSave({
                ...tempData,
                id: materialIndex - 1
            }, setPopupLoading);
    };

    
    return <Dialog disableBackdropClick maxWidth='sm' open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >{editMode ? 'Update' : 'New'} Material
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {
                popupLoading ?
                <div>
                <div className={classes.progressContainer}>
                    <CircularProgress className={classes.progressBar}/>
                </div>
                <br></br>

                <FormControl className={classes.formControl}>
                <InputLabel id="materialName-select-label">Egg Type</InputLabel>
                <Select disabled
                    id="materialName-select"
                    value={formState.values.materialNameIndex !== undefined ? formState.values.materialNameIndex : ''}
                    onChange={handleChange}
                    name="materialNameIndex"
                >
                    {materials.map((material, index) =>
                        <MenuItem key={material.materialId} value={index}>{material.materialName}</MenuItem>
                    )}
                </Select>
            </FormControl>
          
            <TextField disabled
                className={classes.textField}
                error={hasError('Quantity')}
                fullWidth
                helperText={
                    hasError('Quantity') ? formState.errors.quantity[0] : null
                }
                label="Number of Trays"
                name="quantity"
                onChange={handleChange}
                type="number"
                value={formState.values.quantity !== undefined ? formState.values.quantity : ''}
                variant="outlined"
                style={{ backgroundColor: "#ffffff" }}
            />
            <TextField disabled
                className={classes.textField}
                error={hasError('costPerUnit')}
                fullWidth
                helperText={
                    hasError('costPerUnit') ? formState.errors.costPerUnit[0] : null
                }
                label="Cost Per Unit"
                name="costPerUnit"
                onChange={handleChange}
                type="number"
                value={formState.values.costPerUnit !== undefined ? formState.values.costPerUnit : ''}
                variant="outlined"
                style={{ backgroundColor: "#ffffff" }}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
                </div>
                
                :
                <div>
        <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
        <FormControl className={classes.formControl}>
                <InputLabel id="materialName-select-label">Egg Type</InputLabel>
                <Select
                    id="materialName-select"
                    value={formState.values.materialNameIndex !== undefined ? formState.values.materialNameIndex : ''}
                    onChange={handleChange}
                    name="materialNameIndex"
                >
                    {materials.map((material, index) =>
                        <MenuItem key={material.materialId} value={index}>{material.materialName}</MenuItem>
                    )}
                </Select>
            </FormControl>
          
            <TextField
                className={classes.textField}
                error={hasError('Quantity')}
                fullWidth
                helperText={
                    hasError('Quantity') ? formState.errors.quantity[0] : null
                }
                label="Number of Trays"
                name="quantity"
                onChange={handleChange}
                type="number"
                value={formState.values.quantity !== undefined ? formState.values.quantity : ''}
                variant="outlined"
                style={{ backgroundColor: "#ffffff" }}
            />
            <TextField
                className={classes.textField}
                error={hasError('costPerUnit')}
                fullWidth
                helperText={
                    hasError('costPerUnit') ? formState.errors.costPerUnit[0] : null
                }
                label="Cost Per Unit"
                name="costPerUnit"
                onChange={handleChange}
                type="number"
                value={formState.values.costPerUnit !== undefined ? formState.values.costPerUnit : ''}
                variant="outlined"
                style={{ backgroundColor: "#ffffff" }}
            />
            {/*<TextField
                disabled
                className={classes.textField}
                fullWidth
                label="Bill Value"
                name="billValue"
                onChange={handleChange}
                type="number"
                value={weightType === 1 ?
                    ((formState.values.billWeight * formState.values.costPerUnit)  + 
                    (formState.values.billWeight * formState.values.costPerUnit * (formState.values.tax/100)))
                    :
                    ((formState.values.deliveryWeight * formState.values.costPerUnit)  + 
                    (formState.values.deliveryWeight * formState.values.costPerUnit * (formState.values.tax/100)))
                    || ''}
                variant="outlined"
            />*/}
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
}
        </DialogContent>
    </Dialog>
};


export default Material;