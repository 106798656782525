import React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    dialogBackground: {
        backgroundColor:theme.palette.background.default
    },
    dialogHeader: {
        backgroundColor:theme.palette.primary.background,
        '& h2' :{
            color: theme.palette.text.default
           }
    }
}));

const CustomDialogTitle = (props) => {
const classes = useStyles();
return <DialogTitle {...props} className={classes.dialogHeader}>{props.children}</DialogTitle>
};

export default CustomDialogTitle;