import React from 'react';
import Icon from '@material-ui/core/Icon';

const StyledIcons = {
    
    Delete: React.forwardRef((props, ref) => <Icon color='error' {...props} ref={ref}>delete</Icon>),
    Edit:React.forwardRef((props, ref) => <Icon color='primary' {...props} ref={ref}>edit</Icon>), 
    Add:React.forwardRef((props, ref) =>   <Icon color='primary' {...props} ref={ref}>add</Icon>),
    Check: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>check</Icon>),
    Clear: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>clear</Icon>),
    DetailPanel: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>chevron_right</Icon>),
    Export: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>save_alt</Icon>),
    Filter: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>filter_list</Icon>),
    FirstPage: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>first_page</Icon>),
    LastPage: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>last_page</Icon>),
    NextPage: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>chevron_right</Icon>),
    PreviousPage: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>chevron_left</Icon>),
    ResetSearch: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>clear</Icon>),
    Search: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>search</Icon>),
    SortArrow: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>arrow_downward</Icon>),
    ThirdStateCheck: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>remove</Icon>),
    ViewColumn: React.forwardRef((props, ref) => <Icon {...props} ref={ref}>view_column</Icon>)
}

export default StyledIcons;