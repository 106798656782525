import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    resources: {
        en: {
            translation: {
                date: "Date",
                todaysDate: "Today's Date",
                lineName: "Line Name",
                customerName: "Customer Name",
                truck: "Truck",
                search: "SEARCH",
                clearSearch: "CLEAR SEARCH",
                share: "SHARE",
                generatePDF:"Generate PDF",
                materials:"Materials",
                payments:"Payments",
                addMaterial: "ADD MATERIAL",
                addPayment: "ADD PAYMENT",

                newMaterial: "New Material",
                updateMaterial: "Update Material",
                eggType: "Egg Type",
                quantityInTrays: "Quantity in Trays",
                neccDate: "NECC Date",
                neccPrice: "NECC Price",
                discount: "Discount",
                freeQuantityInTrays: "Free Quantity (In Trays)",
                returnTrays: "Return Trays",
                billValue: "Bill Value",

                newPayment: "New Payment",
                updatePayment: "Update Payment",
                paymentMode: "Payment Mode",
                paymentAmount: "Payment Amount",
                cash: "Cash",
                cheque: "Cheque",
                chequeNumber: "Cheque Number",

                save: "Save",
                actions: "Actions",

                openingBalance: "Opening Balance",
                paid: "Paid",
                closingBalance: "Closing Balance",
                tcs: "TCS",
                tds: "TDS",
                openingTrayBalance: "Opening Tray Balance",
                totalReturnTrays: "Total Return Trays",
                closingTrayBalance: "Closing Tray Balance",

                customerInvoice:"Customer Invoice",
                billTo: "Bill To",
                print: "Print",
                noNeccPrice:"No NECC Price",
                noNeccPriceTip: "There's no NECC price for this material. It will be autopicked when NECC price is added.",
                noRecordsToDisplay: "No records to display",
                invoice:"Invoice",
                invoiceDate: "Invoice Date",
                value: "Value",
                summary: "Summary"
            }
        },
        ta: {
            translation: {
                date: "தேதி",
                todaysDate: "இன்றைய தேதி",
                lineName: "வரியின் பெயர்",
                customerName: "வாடிக்கையாளர் பெயர்",
                truck: "டிரக்",
                search: "தேடு",
                clearSearch: "தேடலை அழிக்கவும்",
                share: "பகிர்",
                generatePDF:"பிடிஎப் உருவாக்கவும்",
                materials:"பொருட்கள்",
                payments:"கொடுப்பனவுகள்",
                addMaterial: "பொருள் சேர்க்க",
                addPayment: "கட்டணம் சேர்க்க",

                newMaterial: "புதிய பொருள்",
                updateMaterial: "பொருள் புதுப்பி",
                eggType: "முட்டை வகை",
                quantityInTrays: "அளவு (தட்டுகளில்)",
                neccDate: "என்இசிசி தேதி",
                neccPrice: "என்இசிசி விலை",
                discount: "தள்ளுபடி",
                freeQuantityInTrays: "இலவச அளவு (தட்டுகளில்)",
                returnTrays: "திரும்ப தட்டுகள்",
                billValue: "பில் மதிப்பு",

                newPayment: "புதிய கட்டணம்",
                updatePayment: "கட்டணம் புதுப்பி",
                paymentMode: "கட்டண முறை",
                paymentAmount: "கட்டண தொகை",
                cash: "பணம்",
                cheque: "காசோலை",
                chequeNumber :"காசோலை எண்",

                save: "சேமி",
                actions: "செயல்கள்",

                openingBalance: "ஆரம்ப இருப்பு",
                paid: "செலுத்திய தொகை",
                closingBalance: "முடிவிருப்பு",
                tcs: "டிசிஎஸ்",
                tds: "டிடிஎஸ்",
                openingTrayBalance: "ஆரம்ப தட்டு இருப்பு",
                totalReturnTrays: "மொத்த திரும்ப தட்டுகள்",
                closingTrayBalance: "முடிவு தட்டு இருப்பு",

                customerInvoice:"வாடிக்கையாளர் விலைப்பட்டியல்",
                billTo: "ரசிது",
                print: "அச்சு",
                noNeccPrice: "என்இசிசி விலை இல்லை",
                noNeccPriceTip: "இந்த பொருளுக்கு எந்த விலையும் இல்லை. என்இசிசி விலை சேர்க்கப்படும் போது அது தானாகவே தேர்ந்தெடுக்கப்படும்.",
                noRecordsToDisplay: "காண்பிக்க வேண்டிய ஆவணம் எதுவும் இல்லை",
                invoice:"விலைப்பட்டியல்",
                invoiceDate: "விலைப்பட்டியல் தேதி",
                value: "மதிப்பு",
                summary: "சுருக்கம்"

            }
        }
    }
  });

export default i18n;
