import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import api from "../../common/api";
import { ConfirmDialog, MaterialTable } from "../../components";
import Icon from "@material-ui/core/Icon";
import StdProduction from "./components/stdProduction";
import Mobile from "./components/mobile";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import PetsIcon from "@material-ui/icons/Pets";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { CSVReader } from 'react-papaparse';
import BreedStandardCSV from './templateFile/breed_standard.csv';

const buttonRef = React.createRef()

const useStyles = makeStyles(theme => ({
  root: props => ({
    margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
  }),
  topBar: {
    marginBottom: theme.spacing(2)
  },
  breadcrumbs: {
    marginBottom: theme.spacing(2)
  },
  link: {
    display: "flex"
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  spacing: {
    marginLeft: theme.spacing(2),
},
  download: {
    marginLeft: theme.spacing(1)
  }
}));

let currentData = undefined;
let editMode = false;

const StandardProductionView = ({ showError, showMessage, match, history }) => {
  const breedID = match.params["breedID"];
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  });
  const classes = useStyles({ isDesktop });

  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDialogClose = () => {
    currentData = undefined;
    setShowEditDialog(false);
  };

  const onSave = data => {
    setLoading(true);
    setShowEditDialog(false);
    showError("");
    showMessage("");
    const response = api.post(`breed/${breedID}/std-production`, {
      ...data,
      stdProduction: data.stdProduction,
      stdMortalityRate: data.stdMortalityRate,
      stdFeedIntake: data.stdFeedIntake,
      weight: data.weight,
      week: data.week,
      hhew: data.hhew,
      hhet: data.hhet
    });

    response
      .then(res => {
        setDataList([...dataList, res]);
      })
      .catch(err => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError("Breed Standard not added");
          else showError(err.message);
        } else {
          showError("Unknown Error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onUpdate = data => {
    console.log(data);
    setShowEditDialog(false);
    setLoading(true);
    showError("");
    const response = api.post(`breed/${breedID}/std-production/${data.id}`, {
      ...data,
      stdProduction: data.stdProduction,
      stdMortalityRate: data.stdMortalityRate,
      stdFeedIntake: data.stdFeedIntake,
      weight: data.weight,
      week: data.week,
      hhew: data.hhew,
      hhet: data.hhet
    });
    response
      .then(() => {
        let index = dataList.findIndex(srcData => {
          return srcData.id === data.id;
        });
        setDataList([
          ...dataList.slice(0, index),
          { ...data },
          ...dataList.slice(index + 1)
        ]);
      })
      .catch(err => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError("Breed Standard not updated");
          else showError(err.message);
        } else {
          showError("Unknown Error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onNew(event) {
    editMode = false;
    event.preventDefault();
    setShowEditDialog(true);
  }

  const onEdit = data => {
    currentData = data;
    editMode = true;
    setShowEditDialog(true);
  };

  //Get all Standard Production API Call
  const getAll = () => {
    setLoading(true);
    showError("");
    showMessage("");

    const response = api.get(`breed/${breedID}/std-production`);

    response
      .then(res => {
        setDataList([...res]);
      })
      .catch(err => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError("Breed Standard empty");
          else showError(err.message);
        } else {
          showError("Unknown Error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(getAll, []);

  const onDelete = data => {
    currentData = data;
    setShowDeleteConfirm(true);
  };

  function handleDeleteConfirmClose() {
    setShowDeleteConfirm(false);
  }

  function onDeleteConfirm() {
    if (currentData && currentData.id) {
      setShowDeleteConfirm(false);
      setLoading(true);
      showError("");
      const response = api.delete(
        `breed/${breedID}/std-production/${currentData.id}`
      );

      response
        .then(res => {
          if (res) {
            let index = dataList.findIndex(src => {
              return src.id === currentData.id;
            });
            let newData = [...dataList];
            newData.splice(index, 1);
            setDataList(newData);
          }
        })
        .catch(err => {
          if (err.message) {
            if (
              err.message === "Bad Request" ||
              err.message === "No data found"
            )
              showError("Breed Standard not Deleted");
            else showError(err.message);
          } else {
            showError("Unknown Error");
          }
        })
        .finally(() => {
          setLoading(false);
          currentData = undefined;
        });
    }
  }

  let weekColumnDefaults = { title: "Week", field: "week", defaultSort: "asc" };
  let datColumn = {
    ...weekColumnDefaults,
    render: rowData => (
      <Mobile
        data={rowData}
        onEdit={() => {
          onEdit(rowData);
        }}
        onDelete={() => {
          onDelete(rowData);
        }}
      />
    )
  };
  let actions = [];
  if (isDesktop) {
    datColumn = weekColumnDefaults;
    actions = [
      {
        icon: () => <Icon color="primary">edit</Icon>,
        tooltip: "Edit Breed",
        onClick: (event, rowData) => {
          onEdit(rowData);
        }
      },
      {
        icon: () => <Icon color="error">delete</Icon>,
        tooltip: "Delete Breed",
        onClick: (event, rowData) => {
          onDelete(rowData);
        }
      }
    ];
  }

  const handleOnFileLoad = (datas) => {
    setLoading(true)
    let stdProdDatas = []
    datas.forEach((singleData, index) => {
      let stdProd = {}

      if (index > 0 && singleData.data.length === 7) {
        
        stdProd.week = parseInt(singleData.data[0])
        stdProd.weight = parseInt(singleData.data[1])
        stdProd.stdProduction = parseFloat(singleData.data[2])
        stdProd.stdMortalityRate = parseFloat(singleData.data[3])
        stdProd.stdFeedIntake = parseFloat(singleData.data[4])
        stdProd.hhew = parseFloat(singleData.data[5])
        stdProd.hhet = parseFloat(singleData.data[6])
        
        stdProdDatas.push(stdProd)
      }
    })

    const response = api.post(`/breed/${breedID}/bulk-std-production`, stdProdDatas)

    response.then(res => {
      setDataList([...(res.reverse()), ...dataList])
    }).catch(err => {
      if (err.message) {
          if (err.message === 'Bad Request' || err.message === 'No data found')
            showError('Create Bulk MaterialMaster not added');
          else
          showError(err.message)
      } else {
        showError('Unknown Error')
      }
  }).finally(() => {
      setLoading(false);
  });

  }

  const handleOnError = (err) => {
    showError(err);
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        <Link
          color="inherit"
          href="#"
          onClick={() => history.push("/")}
          className={classes.link}
        >
          <HomeIcon className={classes.icon} />
          Home
        </Link>
        <Link
          color="inherit"
          href="#"
          onClick={() => history.push("/breed")}
          className={classes.link}
        >
          <PetsIcon className={classes.icon} />
          Breeds
        </Link>
        <Typography color="textPrimary" className={classes.link}>
          <LocalHospitalIcon className={classes.icon} />
          Breed Standard
        </Typography>
      </Breadcrumbs>
      <Grid
        container
        justify="space-between"
        alignItems="flex-end"
        className={classes.topBar}
      >
        <Grid>
          <Typography display="block" variant="h3" gutterBottom>
            Breed Standard
          </Typography>
        </Grid>
        <Grid style={{display:"flex"}}>
        <div>
                <CSVReader
                    ref={buttonRef}
                    onFileLoad={handleOnFileLoad}
                    onError={handleOnError}
                    noProgressBar
                    // onRemoveFile={handleOnRemoveFile}
                >
                    {({file}) => (
                        <div>
                            <Button onClick={(e) => {
                                if (buttonRef.current) {
                                    buttonRef.current.open(e)
                                }
                                }}
                                variant="contained" color="primary">Bulk Import
                            </Button>
                        </div>
                    )}
              </CSVReader>
              <a className={classes.download} href={BreedStandardCSV} color="primary" download>Download Template</a>
        </div>
                <Button className={classes.spacing} variant="contained" color="primary" onClick={onNew}>Add</Button>
        </Grid>
      </Grid>
      <MaterialTable
        isLoading={loading}
        columns={[
          { title: "id", field: "id", hidden: true },
          datColumn,
          { title: "Weight (gms)", field: "weight", hidden: !isDesktop },
          {
            title: "Standard Production (%)",
            field: "stdProduction",
            hidden: !isDesktop
          },
          {
            title: "Standard Mortality Rate (%)",
            field: "stdMortalityRate",
            hidden: !isDesktop
          },
          {
            title: "Standard Feed Intake (in gms)",
            field: "stdFeedIntake",
            hidden: !isDesktop
          }, 
          {
            title: "HHEW",
            field: "hhew",
            hidden: !isDesktop
          }, 
          {
            title: "HHET",
            field: "hhet",
            hidden: !isDesktop
          }, 
          /*,
          {
            title: "Modified By",
            field: "modifiedBy",
            hidden: !isDesktop,
            editable: "never"
          },
          {
            title: "Modified",
            field: "modifiedTime",
            type: "datetime",
            editable: "never",
            hidden: !isDesktop,
            render: rowData => {
              return (
                <span>
                  {moment(rowData.modifiedTime).format("DD-MM-YYYY HH:mm")}
                </span>
              );
            }
          }*/
        ]}
        data={dataList}
        options={{
          actionsColumnIndex: -1,
          search: true,
          showTitle: isDesktop,
          
          toolbar: true
        }}
        actions={actions}
        title="Breed Standard"
      />
      <StdProduction
        showDialog={showEditDialog}
        handleClose={handleDialogClose}
        onSave={onSave}
        onEdit={onUpdate}
        editMode={editMode}
        data={currentData}
      />
      <ConfirmDialog
        showDialog={showDeleteConfirm}
        handleClose={handleDeleteConfirmClose}
        onConfirm={onDeleteConfirm}
        title="Delete Breed Standard ?"
        subText="This action will delete the breed Standard."
      />
    </div>
  );
};

export default StandardProductionView;
