import React, { useEffect, useState } from 'react';
import api from '../../../common/api';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MaterialTable } from '../../../components';
// import Material from "./material";
import Icon from '@material-ui/core/Icon';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import {useGlobalState} from '../../../state';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { populateTaxData } from '../../../common/functions';
import { isArray } from 'validate.js';
import NewMaterial from './newMaterial';
import ParentMaterial from '../components/parentMaterial';

import { version } from '../../../common/enums';

const schema = {
    billNumber: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    // tax: {
    //     presence: false,
    //     type: 'number',
    //     numericality: {
    //         greaterThanOrEqualTo: 0,
    //         lesserThanOrEqualTo: 100
    //     }
    // },
    // tds: {
    //     presence: false,
    //     type: 'number',
    //     numericality: {
    //         greaterThanOrEqualTo: 0,
    //         lesserThanOrEqualTo: 100
    //     }
    // },
    paymentDueDate: {
        presence: { allowEmpty: false, message: 'is required' },
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 0,
        }
    },
    truckNumber: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    vendorIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    date: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    purchaseMaterials: {
        presence: { allowEmpty: false, message: 'is required' }
    },

};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2.7),
        marginBottom: theme.spacing(2),
        minWidth: 160
    },
    textField: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        minWidth: 160

    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    datePicker: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth: 160
    },
    addButton: {
        textAlign: "right",
        marginBottom: theme.spacing(2),
        minWidth: 160,
    },
    totalTable: {
        float: 'right',
        width: theme.spacing(37.5)
    },
    totalCell: {
        tableLayout:'fixed',
        width: 500,
        paddingBottom: 0,
        paddingLeft: theme.spacing(0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },
}));
let materialIndex = 0
let currentMaterial = undefined
let editMaterialMode = false
const Purchase = ({ deleteErrorMessage, purchaseerrorMessage, vendors, materialTypes, showError, showMessage, farms, onEdit, data, onSave, handleClose, showDialog, editMode = false, resetBillTotal, setResetBillTotal}) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData, setFormState] = useFromValidator(schema);
    const [showMaterialDialog, setShowMaterialDialog] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [purchaseMaterials, setPurchaseMaterials] = useState([])
    const [billValueSum, setBillValueSum] = useState(0.0)
    const [sheds, setShed] = useState([])
    const [loading, setLoading] = useState(false);
    const [popupLoading, setPopupLoading] = useState(false) ;
    const [materialNames, setMaterialNames] = useState([]);
    const [taxValue, setTaxValue] = useState(0.0)
    const [TcsTaxValue, setTcsTaxValue] = useState(null)
    const [TdsTaxValue, setTdsTaxValue] = useState(null)
    const history = useHistory();
    const [{user}] = useGlobalState();
    const [TaxProfiles, setTaxProfiles] = useState([]);
    const [taxProfileHashMap, setTaxProfileHashMap] = useState(null);
    const [FinalTotalBillAmt, setFinalTotalBillAmt] = useState(0);
    // const [TotalMaterial, setTotalMaterial] = useState(0);
    const [showTcs, setShowTcs] = useState(false)
    const [showTds, setShowTds] = useState(false)
    const [taxProfileIDs, setTaxProfileIDs] = useState([]);
    const [gstTaxProfiles, setGstTaxProfiles] = useState([])
    const [reRenderMaterial,setReRender] = useState(false);
    const GST = "GST";
    const TDS = "TDS";
    const TCS = "TCS";

    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }


    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if (showDialog) {
            setTaxProfileIDs([]);
            setPopupLoading(false);
            setPurchaseMaterials([]);
            setBillValueSum(0.0)
            setTcsTaxValue(undefined);
            setTdsTaxValue(undefined);
            setShowTds(false);
            setShowTcs(false);
            // setTotalMaterial(0);
            setFinalTotalBillAmt(0)
            setTaxValue(0)
            if (editMode) {
                if(user.versionID === version.pro){
                    currentMaterial = data;
                    setPurchaseMaterials(data);
                    onMaterialEdit(data);
                }
                else{
                    setBillValueSum(0.0)
                    setTaxValue(data.tax)
                    if (data.tcs && data.tcs > 0) {
                        setTcsTaxValue(data.tcs)
                        setShowTcs(true);
                    }
                    if (data.tds && data.tds > 0) {
                        setTdsTaxValue(data.tds)
                        setShowTds(true);
                    }
                    setPurchaseMaterials(data.purchaseMaterials)
                    setResetBillTotal(true)
                }
            }
            updateData(data, editMode);
            populateTaxData(setTaxProfiles,[GST,TDS,TCS],false)
        }
    };

    useEffect(resetBeforeEdit, [showDialog]);
    useEffect(alterFormStateValuesWhenTaxValueChanges,[TcsTaxValue])

    useEffect(() => {
        // calculateTotalOfPurchaseMaterial(purchaseMaterials);
        calculateBillTotal(purchaseMaterials)
        // eslint-disable-next-line
    }, [resetBillTotal])

    const onProSubmit = (formData) =>{
        setPopupLoading(true);
        if (editMode || editMaterialMode)
        {
            onEdit(formData, setPopupLoading);
        }else{
            onSave(formData, setPopupLoading);
        }
    };

    const onSubmit = () => {
        setPopupLoading(true)
        if (resetBillTotal === true) {
            setResetBillTotal(false)
        }
        let tempData = {
            ...formState.values,
            vendorId: vendors[formState.values.vendorIndex].id,
            vendorName: vendors[formState.values.vendorIndex].name,
            totalBillValue: billValueSum,
            tcs:showTcs !== null && showTcs !== undefined && showTcs === true? TcsTaxValue : 0,
            tds:showTds !== null && showTds !== undefined && showTds === true? TdsTaxValue : 0,
            taxProfileIDs: taxProfileIDs
        };
        if (editMode)
        {
            onEdit(tempData, setPopupLoading);
        }else{
            onSave(tempData, setPopupLoading);
        }
        currentMaterial = undefined
        setPurchaseMaterials(formState.values.purchaseMaterials);
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
    };

    //calling this in use effect, as traditionally all the values are stored in formState, to further save it in DB
    //so when the user picks one of tcs or tds, the alternate tax value in formState should be set to 0.
    function alterFormStateValuesWhenTaxValueChanges() {
        if (TcsTaxValue && formState.values.tds) {
            setFormState({
                ...formState,
                values:{
                    ...formState.values,
                    tds:0
                },
                touched: {
                    tds: true
                }
            })
        }
        else if (TdsTaxValue && formState.values.tcs) {
            setFormState({
                ...formState,
                values:{
                    ...formState.values,
                    tcs:0
                },
                touched: {
                    tcs: true
                }
            })
        }
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const changeToUpperCase = (event) => {
        let tempTruckNumber = (event.target.value).toUpperCase()
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                truckNumber: tempTruckNumber
            }
        })
    }

        useEffect(() => {
            if (TaxProfiles !== null && TaxProfiles !== undefined && Array.isArray(TaxProfiles) && TaxProfiles.length > 0) {
                let map = new Map()
                let gstTaxProfileArray = [];
                for (const el of TaxProfiles) {
                    if (el.taxTypeId === 1) {
                        gstTaxProfileArray.push(el)
                    } else {
                        map.set(el.id, el)                        
                    }
                }
                setTaxProfileHashMap(map);
                setGstTaxProfiles(gstTaxProfileArray);
            }
        }, [TaxProfiles])

    function onNewMaterial(event) {
        editMaterialMode = false;
        event.preventDefault();
        setShowMaterialDialog(true);
    }

   async function onMaterialEdit(rowData) {
      let gstTaxProfileIndex = (user.versionID !== version.pro) ? gstTaxProfiles?.findIndex((el)=>{return el.id === rowData.taxProfileID}) : null;

        if (rowData.inventoryType === 2) {
            currentMaterial = {
                ...rowData,
                materialTypeIndex: materialTypes.findIndex((materialType) => { return materialType.id === rowData.materialTypeId }),
                farmIndex: farms.findIndex((farm) => { return farm.id === rowData.farmId; }),
                gstTaxProfileIndex : gstTaxProfileIndex
            }
            /*onGetMaterialNames(rowData.materialTypeId)
            getAllSheds(rowData.farmId)*/
            editMaterialMode = true
           await GetMultipleApis(rowData)
        } else {
            currentMaterial = {
                ...rowData,
                materialTypeIndex: materialTypes.findIndex((materialType) => { return materialType.id === rowData.materialTypeId }),
                gstTaxProfileIndex : gstTaxProfileIndex
            }
            editMaterialMode = true
           await onGetMaterialNames(rowData.materialTypeId)
        }
        if(user.versionID !== version.pro){
            setShowMaterialDialog(true)
        }
        setReRender(!reRenderMaterial);
    }

    const GetMultipleApis = async(rowData) => {
        showError('');
        try {
            const materialResponse = await api.get(`materials-of-type/${rowData.materialTypeId}`)
            const ShedResponse = await api.get(`farm/${rowData.farmId}/shed`)
            currentMaterial = {
                ...currentMaterial,
                materialIndex: materialResponse.findIndex((material) => { return material.id === rowData.materialId; }),
                shedIndex: ShedResponse.findIndex((shed) => { return shed.id === rowData.shedId; }),
            }
            setMaterialNames(materialResponse)
            setShed(ShedResponse)
            if(user.versionID !== version.pro){
                setShowMaterialDialog(true);
            }   
        } catch (err) {
            if (err.message) {
                showError(err.message);
            } else {
                showError('Unknown Error');
            }
        }

    //     let request = [materialResponse, ShedResponse]

    //    return Promise.all(request).then(function (res) {
    //         console.debug(res)
    //         setMaterialNames([...res[0]]);
    //         setShed([...res[1]]);
    //         currentMaterial = {
    //             ...currentMaterial,
    //             materialIndex: res[0].findIndex((material) => { return material.id === rowData.materialId; }),
    //             shedIndex: res[1].findIndex((shed) => { return shed.id === rowData.shedId; }),
    //         }

    //         setShowMaterialDialog(true);
    //     }).catch(function (err) {
    //         if (err.message) {
    //             showError(err.message);
    //         } else {
    //             showError('Unknown Error');
    //         }
    //     });

    }

    function onMaterialDelete(rowData) {
        //editMode = true
        let index = (formState.values.purchaseMaterials).findIndex((srcPurchaseMaterial) => {
            return srcPurchaseMaterial.id === rowData.id;

        });

        let newPurchaseMaterials = [...formState.values.purchaseMaterials];
        let newPurchase = {}
        if (editMode) {
            let newDeletedPurchaseMaterials = []
            if (formState.values.deletedPurchaseMaterials !== undefined && formState.values.deletedPurchaseMaterials !== null) {
                newDeletedPurchaseMaterials = [...formState.values.deletedPurchaseMaterials]
            }
            newPurchaseMaterials.splice(index, 1);
            newDeletedPurchaseMaterials = [
                ...newDeletedPurchaseMaterials,
                rowData
            ]
            newPurchase = {
                ...formState.values,
                purchaseMaterials: newPurchaseMaterials,
            }
            if (rowData.id > 0) {
                newPurchase = {
                    ...newPurchase,
                    deletedPurchaseMaterials: newDeletedPurchaseMaterials
                }
            }
        } else {
            newPurchaseMaterials.splice(index, 1);
            newPurchase = {
                ...formState.values,
                purchaseMaterials: newPurchaseMaterials
            }
        }
        //Reducing the material index only if the deleted material is newly entered material
        if (rowData.id < 0) {
            materialIndex++
        }
        setPurchaseMaterials(newPurchaseMaterials)
        calculateBillTotal(newPurchaseMaterials, formState.values.tax, formState.values.tcs, formState.values.tds)
        updateData(newPurchase, true);
    }



    function onMaterialUpdate(purchaseMaterial) {

        let index = (formState.values.purchaseMaterials).findIndex((srcPurchaseMaterial) => {
            return srcPurchaseMaterial?.tableData.id === purchaseMaterial?.tableData.id;
        });
        let tempPurchaseMaterials = [...(formState.values.purchaseMaterials).slice(0, index),
        { ...purchaseMaterial },
        ...(formState.values.purchaseMaterials).slice(index + 1)]

        let tempFormStateValues = {
            ...formState.values,
            purchaseMaterials: tempPurchaseMaterials
        }
        setPurchaseMaterials(tempPurchaseMaterials)
        updateData(tempFormStateValues, true);
        calculateBillTotal(tempPurchaseMaterials, formState.values.tax, formState.values.tcs, formState.values.tds)
        setShowMaterialDialog(false);
    }

    function onMaterialSave(material) {
        let tempFormState = {}
        let tempPurchaseMaterial = []

        if (formState.values.purchaseMaterials === undefined) {
            tempPurchaseMaterial = [
                material
            ]
            tempFormState = {
                ...formState.values,
                purchaseMaterials: [
                    material
                ]
            }
        } else {
            tempPurchaseMaterial = [
                ...formState.values.purchaseMaterials,
                material]
            tempFormState = {
                ...formState.values,
                purchaseMaterials: tempPurchaseMaterial
            }
        }
        materialIndex--
        updateData(tempFormState, true);
        setPurchaseMaterials(tempFormState.purchaseMaterials)
        // calculateTotalOfPurchaseMaterial(tempFormState.purchaseMaterials)
        calculateBillTotal(tempFormState.purchaseMaterials, formState.values.tax, formState.values.tcs, formState.values.tds)
        setShowMaterialDialog(false);
    }

    //Get all Shed API Call
    const getAllSheds = (farmID) => {
        setShed([]);
        setLoading(true);
        // showError('');
        showMessage('');
        const response = api.get(`farm/${farmID}/shed`);

        response.then(res => {
            setShed([...res]);
            /*if (editMaterialMode && currentMaterial !== undefined) {
                currentMaterial = {
                    ...currentMaterial,
                    shedIndex: res.findIndex((shed) => { return shed.id === currentMaterial.shedId; })
                }
                setShowMaterialDialog(true);
            }*/
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('No Sheds');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    /**
     * Note: Switching this function to async await pattern to accomodate the new changes
     * as the function caller relies upon the material index  and material names 
     * immediately during edit mode.
     */
    const onGetMaterialNames = async(materialId) => {
        // showError('');
        //
//        setMaterialNames([])

        try {
            const response = await api.get(`materials-of-type/${materialId}`);
            if (editMaterialMode === true) {
                currentMaterial = {
                    ...currentMaterial,
                    materialIndex: response.findIndex((material) => { return material.id === currentMaterial.materialId; }),
                }
                if (currentMaterial.inventoryType === 1 && user.versionID !== version.pro) {
                    setShowMaterialDialog(true);
                }
            }
            setMaterialNames(response)

        } catch (err) {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found') {
                    setErrorMessage('No Materials found for the chosen material type');
                }
                else {
                    setErrorMessage(err.message);
                }
            } else {
                setErrorMessage('Unknown Error');
            }
        }
        //commenting for now

        // response.then(res => {
        //     console.log("511#",res);
        //     setMaterialNames([...res]);
        //     if (editMaterialMode === true) {
        //         currentMaterial = {
        //             ...currentMaterial,
        //             materialIndex: res.findIndex((material) => { return material.id === currentMaterial.materialId; }),
        //         }
        //         console.log("518#", currentMaterial);
        //         if (currentMaterial.inventoryType === 1) {
        //             setShowMaterialDialog(true);
        //         }
        //     }
        // }).catch(err => {
        //     if (err.message) {
        //         if (err.message === 'Bad Request' || err.message === 'No data found') {
        //             setErrorMessage('No Materials found for the chosen material type');
        //         }
        //         else {
        //             setErrorMessage(err.message);
        //         }
        //     } else {
        //         setErrorMessage('Unknown Error');
        //     }
        // });

    }


    function handleMaterialClose() {
        currentMaterial = undefined;
        editMaterialMode = false;
        if(user.versionID !== version.pro){
            setShowMaterialDialog(false);
        }
        else{
            handleClose();
        }
        if(errorMessage){
            deleteErrorMessage();
        }
    }

    //Commenting for now - function used in old logic
    // const changeToAddTax = (event) => {
    //     // console.log(taxValue)
    //     setTaxValue(event.target.value)
    //     if (formState.values.purchaseMaterials !== undefined) {
    //         let total = 0.0
    //         formState.values.purchaseMaterials.forEach((purchaseMaterial, index) => {

    //             if (purchaseMaterial.billValue > 0.0) {
    //                 total = total + purchaseMaterial.billValue
    //             }
    //         })
    //         let finalTotal = total + ((total * (event.target.value / 100))  + (total * ((TcsTaxValue || 0) / 100))) - (total * ((TdsTaxValue || 0)/100));
    //         setBillValueSum(finalTotal);

    //         //commenmting for now
    //         // total = total + (total * (event.target.value / 100)) 
    //         // total = parseFloat(total.toFixed(2))
    //         // setBillValueSum(total)
    //     }
    //     //setBillValueSum(billValueSum-(billValueSum*(taxValue/100)))   
    // }
    
    
    function calculateBillTotal(tempPurchaseMaterials, tax, tcs, tds) {

        /**
         * 
         * 1 - GST
         * 2 - TDS
         * 3 - TCS
         * 
         * As per tax type values set in DB
         */


        let total = 0.0
        let gst = 0.0;
        if (tempPurchaseMaterials && isArray(tempPurchaseMaterials) && tempPurchaseMaterials.length !== 0) {
            tempPurchaseMaterials.forEach((purchaseMaterial, index) => {

                if (purchaseMaterial.billValue > 0.0) {
                    total = total + purchaseMaterial.billValue
                }
                if (purchaseMaterial.gst !== null && purchaseMaterial.gst !== undefined ) {
                    let gstTaxPercentage = purchaseMaterial.gst;
                    let gstTaxAmt = (gstTaxPercentage/100) * purchaseMaterial.billValue;
                    gst += gstTaxAmt
                }
            })
            setTaxValue(gst)

            /**
             * 
             * billVal - 100 - Gst - 1%
             * billVal - 100 - Gst - 1%
             * billVal - 100 - Gst - 1%
             * 
             */
            /*if (formState.values.tax !== undefined) {
                tax = formState.values.tax
            }else if (data !== undefined && data.tax !== undefined){
                tax = data.tax
            }*/
            if(tax === undefined){
                tax = 0.0
            }

            setBillValueSum(total)
            //commenting for now to check if the logic implemented is correct
            // total = total + (total * (tax / 100))
            // total = parseFloat(total.toFixed(2))
            
        //Final Total = Bill Value + Tax% +TCS% - TDS%
        // (billValueSum-(((formState.values.tds || 0)*billValueSum)/100)).toLocaleString(user.locales,amountObj)
       
       
        let TCS = showTcs !== null && showTcs !== undefined && showTcs === true? (total * (TcsTaxValue / 100)):0;
        let TDS = showTds !== null && showTds !== undefined && showTds === true?(total * (TdsTaxValue / 100)):0;
        let finalTotal = (total + gst  + TCS) - TDS;
        setFinalTotalBillAmt(finalTotal);
        } else {
            setBillValueSum(0)
            setFinalTotalBillAmt(0)
            setTaxValue(0)
            // setTotalMaterial(0)
        }
    }

    //Commenting for now - functions used in old logic of purchase material total calculation

    // function calculateTotalOfPurchaseMaterial(purchaseMaterials){
    //     let total = 0.0
    //     if(purchaseMaterials !== undefined){
    //         purchaseMaterials.forEach((purchaseMaterial, index) => {
    //             if (purchaseMaterial.billValue > 0.0) {
    //                 total = total + purchaseMaterial.billValue
    //             }
    //         })
    //     }
    //     setTotalMaterial(total);
    // }

    //Commenting for now - functions used in old logic of tcs and tds 
    
    // function onChangeTcsTaxValue(event) {
    //     setTcsTaxValue(event.target.value);
    //     setShowTds(false);
    //     setShowTcs(true);
    //     setTdsTaxValue(undefined);
    //     calculateBillTotal(formState.values.purchaseMaterials, taxValue ,event.target.value,TdsTaxValue)
    // }
    
    // function onChangeTdsTaxValue(event) {
    //     setTdsTaxValue(event.target.value);
    //     setShowTds(true);
    //     setShowTcs(false);
    //     setTcsTaxValue(undefined);
    //     calculateBillTotal(formState.values.purchaseMaterials, taxValue ,TcsTaxValue, event.target.value)
    // }


    let actions = [
        {
            icon: () => <Icon color='primary'>edit</Icon>,

            tooltip: 'Edit purchase Details',
            onClick: (event, rowData) => onMaterialEdit(rowData)
        },
        {
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete purchase Details',
            onClick: (event, rowData) => onMaterialDelete(rowData)
        }
    ]

    function getTaxProfile(e) {
        let vendorID = vendors[e.target.value].id
        /**
         * Note: The below condition is executed upon editing an old record, so the old tax values are used
         */
        if (data !== undefined && data !== null && vendorID === data.vendorId) {
            setTcsTaxValue(data.tcs)
            setShowTds(data.tds > 0 ? true : false)
            setTdsTaxValue(data.tds)
            setShowTcs(data.tcs > 0 ? true : false)
            let total = (billValueSum + taxValue  + (billValueSum * (data.tcs / 100))) - (billValueSum * (data.tds / 100));
            setFinalTotalBillAmt(total)
            return;
        }
        api.get(`/customer-vendor-details-tax-profile/${vendorID}`)
        .then((res)=>{
            setTaxProfileIDs(res)
            let TCS=0;
            let TDS=0;
            let obj = {
                tcs:{
                    setState:function() {
                        setTcsTaxValue(null)
                        setShowTcs(false)
                    },
                    set:false
                },tds:{
                    setState:function() {
                        setTdsTaxValue(null)
                        setShowTds(false)
                    },
                    set:false
                },

            }
            for (const el of res) {
                if (taxProfileHashMap.get(el) !== undefined && taxProfileHashMap.get(el) !== null && Object.keys(taxProfileHashMap.get(el)).length > 0) {
                    let {taxTypeId, taxPercentage} = taxProfileHashMap.get(el)
                     if (taxTypeId === 2) {
                        TDS = (billValueSum * (taxPercentage / 100));
                        setTdsTaxValue(taxPercentage)
                        setShowTds(true)
                        obj.tds.set = true
                    }else if (taxTypeId === 3){
                        TCS = (billValueSum * (taxPercentage / 100));
                        setTcsTaxValue(taxPercentage)
                        setShowTcs(true)
                        obj.tcs.set = true
                    }
                }
            }
            for (const key in obj) {
                if (obj[key]["set"] === false) {
                    obj[key]["setState"]()
                }
            }
            if (billValueSum > 0) {
                    let finalTotal = (billValueSum + taxValue  + TCS) - TDS;
                    setFinalTotalBillAmt(finalTotal);
            }

        })
        .catch((err)=>{
            if (err.message.includes(404)) {
                setTaxProfileIDs([])
                setTcsTaxValue(null)
                setTdsTaxValue(null)
                setShowTcs(false)
                setShowTds(false)
                let billVal = billValueSum + taxValue;
                setFinalTotalBillAmt(billVal)
            }

        })
    }

    return <Dialog disableBackdropClick maxWidth='md' open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title" >{editMode ? 'Update' : 'New'} Purchase
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
            {
                (user.versionID === version.pro) ?
                <ParentMaterial deleteErrorMessage={deleteErrorMessage} errorMessage={purchaseerrorMessage} materialTypes={materialTypes} farms={farms} sheds={sheds} onGetShed={getAllSheds} materialNames={materialNames}
                onGetMaterialNames={onGetMaterialNames} onEdit={onProSubmit} data={currentMaterial} onSave={onProSubmit} handleClose={handleMaterialClose} gstTaxProfiles={gstTaxProfiles} setMaterialNames={setMaterialNames}
                showDialog={showDialog} editMode={editMaterialMode} materialIndex={materialIndex} loading={loading} render={reRenderMaterial}>
                </ParentMaterial>
               :
               <>
            {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/>
                </div>
                <br/><br/> 
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker disabled
                    className={classes.textField}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <TextField disabled
                className={classes.textField}
                error={hasError('billNumber')}
                helperText={
                    hasError('billNumber') ? formState.errors.billNumber[0] : null
                }
                label="Bill Number"
                name="billNumber"
                onChange={handleChange}
                type="text"
                value={formState.values.billNumber !== undefined ? formState.values.billNumber : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField disabled
                className={classes.textField}
                error={hasError('truckNumber')}
                helperText={
                    hasError('truckNumber') ? formState.errors.truckNumber[0] : null
                }
                label="Truck Number"
                name="truckNumber"
                onChange={event => { handleChange(event, changeToUpperCase) }}
                type="text"
                value={formState.values.truckNumber !== undefined ? formState.values.truckNumber : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField disabled
                className={classes.textField}
                error={hasError('paymentDueDate')}
                helperText={
                    hasError('paymentDueDate') ? formState.errors.paymentDueDate[0] : null
                }
                label="Payment Due (in Days)"
                name="paymentDueDate"
                onChange={handleChange}
                type="number"
                value={formState.values.paymentDueDate || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />

            <FormControl disabled className={classes.formControl}>
                <InputLabel id="vendorName-select-outlined-label">
                    Vendor Name
                </InputLabel>
                <Select disabled
                    id="vendorName-select"
                    value={formState.values.vendorIndex !== undefined ? formState.values.vendorIndex : ''}
                    onChange={handleChange}
                    name="vendorIndex"
                >
                    {vendors !== undefined && vendors !== null ?
                        vendors.map((vendor, index) =>
                            <MenuItem key={vendor.id} value={index}>{vendor.name}</MenuItem>
                        ) : ""}
                </Select>
                    <Link disabled
                    color="inherit"
                    href="#"
                    onClick={() =>{return  history.push("/customer-vendor-details")}}
                    className={classes.link}
                    style={{color:"#02a6c7"}}
                    > Add new Vendor</Link>
            </FormControl>
            <div className={classes.addButton} >
                <Button disabled variant="contained" color="primary" onClick={onNewMaterial}>Add Material</Button>
            </div>
            <MaterialTable 
                columns={[
                    { title: 'id', field: 'id', hidden: true },
                    { title: 'Item Name', field: 'materialName', editable: 'never' },
                    {
                        title: 'Weight Type', field: 'weightType', editable: 'never',
                        render: (rowData) => {
                            return <span>{rowData.weightType === 1 ? 'Bill Weight' : 'Delivery Weight'}</span>
                        }
                    },
                    {
                        title: 'Inventory Type', field: 'inventoryType', editable: 'never',
                        render: (rowData) => {
                            return <span>{rowData.inventoryType === 1 ? 'Feed Mill' : (`${rowData.farmName!==undefined &&rowData.farmName.String !== undefined ? rowData.farmName.String : ""}  (  ${rowData.shedName!==undefined && rowData.shedName.String !== undefined ? rowData.shedName.String : "" } )`)}</span>
                        }
                    },
                    { title: 'Bill Weight', field: 'billWeight', editable: 'never' },
                    { title: 'Delivery Weight', field: 'deliveryWeight', editable: 'never' },
                    { title: 'Cost Per Unit', field: 'costPerUnit', editable: 'never' },
                    {
                        title: 'Bill Value', field: 'billValue', editable: 'never',
                        render: (rowData) => {
                            let billValue = 0;

                            let taxValue = 1; //If there is no Tax then multiply by 1

                            // If the Tax % is greater than 0, then calculate tax in decimals
                            if (formState.values.tax < 0) {
                                taxValue = (1 + (formState.values.tax / 100));
                            }

                            if (rowData.weightType === 1) {
                                billValue = (rowData.billWeight * rowData.costPerUnit) * taxValue;
                            } else {
                                billValue = (rowData.deliveryWeight * rowData.costPerUnit) * taxValue;
                            }

                            return <span>{billValue.toLocaleString(user.locales,amountObj) }</span>
                        },
                    }
                ]}
                style={{opacity: '0.6'}}
                data={purchaseMaterials}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    
                    toolbar: false,
                    paging: false
                }}
                //style={{boxShadow: 'unset'}}
                actions={actions}
            />
            <Table disabled className={classes.totalTable} >
                <TableHead  > 
                <TableRow>
                    <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >Bill Value</TableCell>
                    <TableCell style={{opacity: '0.6'}} className={classes.totalCell} >{billValueSum.toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >GST Total</TableCell>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}>{taxValue.toLocaleString(user.locales,amountObj) }</TableCell>
                    </TableRow>
                    {/* <TableRow>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >Total with Tax</TableCell>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}>{billValueSum.toLocaleString(user.locales,amountObj) }</TableCell>
                    </TableRow> */}
                    {
                        showTcs?
                    <TableRow>
                        <TableCell className={classes.totalCell}  >TCS</TableCell>
                        <TableCell className={classes.totalCell}>BLAH{(
                            (billValueSum * (TcsTaxValue || 0) / 100) + (taxValue * (TcsTaxValue || 0) / 100)
                        ).toLocaleString(user.locales,amountObj) }</TableCell>
                    </TableRow> : ''
                    }
                    {/* <TableRow>
                        <TableCell className={classes.totalCell}  >Total with TCS</TableCell>
                        <TableCell className={classes.totalCell}>{billValueSum.toLocaleString(user.locales,amountObj) }</TableCell>
                    </TableRow> */}
                    {
                    showTds?
                    <TableRow>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >TDS </TableCell>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}>{(billValueSum * ( (TdsTaxValue || 0) / 100)).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>:''
                    }

                    {/* <TableRow>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >Total with TDS </TableCell>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}>                        {
                        billValueSum === 0 ?
                        (0).toLocaleString(user.locales,amountObj): 
                        (billValueSum-(((formState.values.tds || 0)*billValueSum)/100)).toLocaleString(user.locales,amountObj)
                        }
                        </TableCell>
                    </TableRow> */}
                    <TableRow>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >Final Total </TableCell>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}>{(FinalTotalBillAmt + Math.ceil(taxValue * (TcsTaxValue || 0) / 100)).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                </TableHead>
            </Table>

            <NewMaterial materialTypes={materialTypes} farms={farms} sheds={sheds} onGetShed={getAllSheds} materialNames={materialNames}
                onGetMaterialNames={onGetMaterialNames} onEdit={onMaterialUpdate} data={currentMaterial} onSave={onMaterialSave} handleClose={handleMaterialClose}
                showDialog={showMaterialDialog} editMode={editMaterialMode} materialIndex={materialIndex} 
                deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} loading={loading}
                />

{/*             {showMaterialDialog ?

            <Material deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} materialTypes={materialTypes} farms={farms} sheds={sheds} onGetShed={getAllSheds} materialNames={materialNames}
                onGetMaterialNames={onGetMaterialNames} onEdit={onMaterialUpdate} data={currentMaterial} onSave={onMaterialSave} handleClose={handleMaterialClose}
                showDialog={showMaterialDialog} editMode={editMaterialMode} materialIndex={materialIndex} loading={loading} >
            </Material>

            : null
            } */}
            </div>
            :
            <div>
   
        <div id="error">
          {purchaseerrorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={purchaseerrorMessage}/> : ''}
        </div> 
        <br/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    className={classes.textField}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <TextField
                className={classes.textField}
                error={hasError('billNumber')}
                helperText={
                    hasError('billNumber') ? formState.errors.billNumber[0] : null
                }
                label="Bill Number"
                name="billNumber"
                onChange={handleChange}
                type="text"
                value={formState.values.billNumber !== undefined ? formState.values.billNumber : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('truckNumber')}
                helperText={
                    hasError('truckNumber') ? formState.errors.truckNumber[0] : null
                }
                label="Truck Number"
                name="truckNumber"
                onChange={event => { handleChange(event, changeToUpperCase) }}
                type="text"
                value={formState.values.truckNumber !== undefined ? formState.values.truckNumber : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('paymentDueDate')}
                helperText={
                    hasError('paymentDueDate') ? formState.errors.paymentDueDate[0] : null
                }
                label="Payment Due (in Days)"
                name="paymentDueDate"
                onChange={handleChange}
                type="number"
                value={formState.values.paymentDueDate || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
                        {/* <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">Tax Profiles</InputLabel>
              <Select
                id="taxProfileNameIdx"
                value={
                  formState.values.taxProfileNameIdx !== undefined
                    ? formState.values.taxProfileNameIdx
                    : ""
                }
                onChange={event => { handleChange(event, setTaxValueFromTaxProfile(event, TaxProfiles, setFormState, formState,(taxPercentage)=>{
                    //adding a callback and receiving taxPercentage from it to display the tax value, as the tax field's onchange
                    //is not triggered when tax profile is selected
                   let obj = {
                       target:{
                           value:taxPercentage
                       }
                   }
                   if (TaxProfiles[event.target.value]["taxType"] === "TCS") {
                       onChangeTcsTaxValue(obj);
                   }
                   else if (TaxProfiles[event.target.value]["taxType"] === "TDS") {
                    onChangeTdsTaxValue(obj);
                    }
                   else
                   {
                    changeToAddTax(obj);
                   }
                })) }}
                name="taxProfileNameIdx"
              >
                {TaxProfiles !== undefined && TaxProfiles !== null
                  ? TaxProfiles.map((taxProfile, index) => (
                      <MenuItem key={taxProfile.id} value={index}>
                        {taxProfile.taxProfileName}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>

            <InputLabel
                id="tax"
            >

                Tax(%) {formState.values.tax !== undefined ? formState.values.tax : ''}
            </InputLabel>
            </FormControl>
            {
            showTds?    
            <FormControl className={classes.formControl}>

            <InputLabel 
                id="tds"
            >
                TDS(%) {formState.values.tds !== undefined ? formState.values.tds : ''}
            </InputLabel>
            </FormControl>
            : ''
            }

            {
             showTcs?   
            <FormControl className={classes.formControl}>

            <InputLabel 
            id="tcs"
            >
                TCS(%) {formState.values.tcs !== undefined ? formState.values.tcs : ''}
            </InputLabel>
            </FormControl>
            : ''
            } */}

            <FormControl className={classes.formControl}>
                <InputLabel id="vendorName-select-outlined-label">
                    Vendor Name
                </InputLabel>
                <Select
                    id="vendorName-select"
                    value={formState.values.vendorIndex !== undefined ? formState.values.vendorIndex : ''}
                    onChange={(e)=>{handleChange(e,getTaxProfile)} }
                    name="vendorIndex"
                >
                    {vendors !== undefined && vendors !== null ?
                        vendors.map((vendor, index) =>
                            <MenuItem key={vendor.id} value={index}>{vendor.name}</MenuItem>
                        ) : ""}
                </Select>
                    <Link
                    color="inherit"
                    href="#"
                    onClick={() =>{return  history.push("/customer-vendor-details")}}
                    className={classes.link}
                    style={{color:"#02a6c7"}}
                    > Add new Vendor</Link>
            </FormControl>
           
            
            <div className={classes.addButton} >
                <Button variant="contained" color="primary" onClick={onNewMaterial}>Add Material</Button>
            </div>

            <MaterialTable
                columns={[
                    { title: 'id', field: 'id', hidden: true },
                    { title: 'Item Name', field: 'materialName', editable: 'never' },
                    {
                        title: 'Weight Type', field: 'weightType', editable: 'never',
                        render: (rowData) => {
                            return <span>{rowData.weightType === 1 ? 'Bill Weight' : 'Delivery Weight'}</span>
                        }
                    },
                    {
                        title: 'Inventory Type', field: 'inventoryType', editable: 'never',
                        render: (rowData) => {
                            window.rd = rowData;

                            return <span>{rowData.inventoryType === 1 ? 'Feed Mill' : (`${rowData.farmIndex !== undefined 
                                && rowData.farmIndex !== null && farms[rowData.farmIndex] !== undefined
                                 && farms[rowData.farmIndex] !== null ? farms[rowData.farmIndex]["name"] 
                                 : rowData.farmName !== undefined && rowData.farmName !== null ? rowData.farmName.String :""}  
                             (  ${ rowData.shedIndex !== undefined && rowData.shedIndex !== null 
                                &&  sheds[rowData.shedIndex] !== undefined
                                 && sheds[rowData.shedIndex] !== null ? sheds[rowData.shedIndex]["name"] 
                                 :rowData.shedName !== undefined && rowData.shedName !== null ? rowData.shedName.String :""} )`)}</span>
                        }
                    },
                    { title: 'Bill Weight', field: 'billWeight', editable: 'never' },
                    { title: 'Delivery Weight', field: 'deliveryWeight', editable: 'never' },
                    { title: 'Cost Per Unit', field: 'costPerUnit', editable: 'never' },
                    { title: 'GST', field: 'gst', editable: 'never' ,
                    render: (rowData) => {
                        let gstTaxAmt = ((rowData.gst || 0)/100) * rowData.billValue
                        return <span>{gstTaxAmt.toLocaleString(user.locales,amountObj) }</span>
                    }
                    },
                    {
                        title: 'Bill Value', field: 'billValue', editable: 'never',
                        render: (rowData) => {
                            let billValue = 0;

                            let taxValue = 1; //If there is no Tax then multiply by 1

                            // If the Tax % is greater than 0, then calculate tax in decimals
                            if (formState.values.tax < 0) {
                                taxValue = (1 + (formState.values.tax / 100));
                            }

                            if (rowData.weightType === 1) {
                                billValue = (rowData.billWeight * rowData.costPerUnit) * taxValue;
                            } else {
                                billValue = (rowData.deliveryWeight * rowData.costPerUnit) * taxValue;
                            }

                            return <span>{billValue.toLocaleString(user.locales,amountObj) }</span>
                        },
                    }
                ]}
                data={purchaseMaterials}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    
                    toolbar: false,
                    paging: false
                }}
                //style={{boxShadow: 'unset'}}
                actions={actions}
            />
            <Table className={classes.totalTable} >
            {/* /**
         * 
         * 1 - GST
         * 2 - TDS
         * 3 - TCS
         * 
         * As per tax type values set in DB
         */ }
                <TableHead  >
                    <TableRow>
                    <TableCell className={classes.totalCell}  >Bill Value</TableCell>
                    <TableCell className={classes.totalCell} >{billValueSum.toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    {taxValue && taxValue > 0 ? 
                        <TableRow hidden={true}>
                        <TableCell className={classes.totalCell}  >GST</TableCell>
                        <TableCell className={classes.totalCell}>{taxValue.toLocaleString(user.locales,amountObj) }</TableCell>
                    </TableRow>
                    : null}
                    {/* <TableRow>
                        <TableCell className={classes.totalCell}  >Total with Tax</TableCell>
                        <TableCell className={classes.totalCell}>{billValueSum.toLocaleString(user.locales,amountObj) }</TableCell>
                    </TableRow> */}
                    {
                        showTcs?
                    <TableRow>
                        <TableCell className={classes.totalCell}  >TCS</TableCell>
                        <TableCell className={classes.totalCell}>{(
                            Math.ceil((billValueSum * (TcsTaxValue || 0) / 100) + (taxValue * (TcsTaxValue || 0) / 100))
                        ).toLocaleString(user.locales,amountObj) }</TableCell>
                    </TableRow> : ''
                    }

                    {/* <TableRow>
                        <TableCell className={classes.totalCell}  >Total with TCS</TableCell>
                        <TableCell className={classes.totalCell}>{billValueSum.toLocaleString(user.locales,amountObj) }</TableCell>
                    </TableRow> */}
                    {
                    showTds?
                    <TableRow>
                        <TableCell className={classes.totalCell}  >TDS </TableCell>
                        <TableCell className={classes.totalCell}>{(billValueSum * ( (TdsTaxValue || 0) / 100)).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>:''
                    }

                    {/* <TableRow>
                        <TableCell className={classes.totalCell}  >Total with TDS </TableCell>
                        <TableCell className={classes.totalCell}>                        
                        {
                        billValueSum === 0 ?
                        (0).toLocaleString(user.locales,amountObj): 
                        (billValueSum-(((formState.values.tds || 0)*billValueSum)/100)).toLocaleString(user.locales,amountObj)
                        }
                        </TableCell>
                    </TableRow> */}
                    <TableRow>
                        <TableCell className={classes.totalCell}  >Final Total</TableCell>
                        <TableCell className={classes.totalCell}>{(FinalTotalBillAmt + Math.ceil(taxValue * (TcsTaxValue || 0) / 100)).toLocaleString(user.locales,amountObj) }</TableCell>
                    </TableRow>
                </TableHead>
            </Table>
            </div>
              }
               </>
            }
        </DialogContent>

        {/* <NewMaterial editMode={editMaterialMode} showDialog={showMaterialDialog} handleClose={handleMaterialClose} materialTypes={materialTypes} onGetMaterialNames={onGetMaterialNames} materialNames={materialNames} farms={farms} sheds={sheds} onGetShed={getAllSheds} gstTaxProfiles={gstTaxProfiles} data={currentMaterial} onSave={onMaterialSave} onEdit={onMaterialUpdate} materialIndex={materialIndex} deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} loading={loading} /> */}
        {/* {showMaterialDialog ?

            <Material deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} materialTypes={materialTypes} farms={farms} sheds={sheds} onGetShed={getAllSheds} materialNames={materialNames}
                onGetMaterialNames={onGetMaterialNames} onEdit={onMaterialUpdate} data={currentMaterial} onSave={onMaterialSave} handleClose={handleMaterialClose} gstTaxProfiles={gstTaxProfiles}
                showDialog={showMaterialDialog} editMode={editMaterialMode} materialIndex={materialIndex} loading={loading} >
            </Material>

            : null
        } */}
        {
        showMaterialDialog ?

            <ParentMaterial deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} materialTypes={materialTypes} farms={farms} sheds={sheds} onGetShed={getAllSheds} materialNames={materialNames}
                onGetMaterialNames={onGetMaterialNames} onEdit={onMaterialUpdate} data={currentMaterial} onSave={onMaterialSave} handleClose={handleMaterialClose} gstTaxProfiles={gstTaxProfiles} setMaterialNames={setMaterialNames}
                showDialog={showMaterialDialog} editMode={editMaterialMode} materialIndex={materialIndex} loading={loading} >
            </ParentMaterial>

            : null
        }

        <DialogActions>
            <Button
                className={classes.signInButton}
                color="primary"
                disabled={!formState.isValid || popupLoading}
                size="large"
                type="submit"
                variant="contained"
                onClick={onSubmit}
            >
                Save
            </Button>
        </DialogActions>

    </Dialog>
};


export default Purchase;