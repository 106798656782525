import React, { useEffect, useState } from 'react';
import api from '../../../../common/api';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Icon from '@material-ui/core/Icon';
import VaccinationRemainder from "./components/vaccinationRemainder";
import CompletedVacination from "./components/completedVaccination";
import Mobile from "./components/mobile";
import TodayIcon from '@material-ui/icons/Today';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import moment from "moment";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DoctorNoteItem from "./components/doctorNoteItem";

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    chip: {
        color: "white",
        fontWeight: 500
    },
    addButton: {
        paddingLeft: theme.spacing(100)
    },
    doctorTypo: {
        padding: theme.spacing(2),
    },
    card: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    orangeChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#ff9800',
        backgroundColor: 'rgba(255, 152, 0, 0.08)',
    },
    redChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#f44336',
        backgroundColor: 'rgba(244, 67, 54, 0.08)',
    },
    greenChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#4caf50',
        backgroundColor: 'rgba(76, 175, 80, 0.08)'
    },
}));

let currentVaccineNotification = undefined;
let editMode = false;
let currentDoctorNoteItem = undefined;
let editModeDoctorNoteItem = false;

const VaccineNotification = ({ showError, showVaccineNotification = true, farmID }) => {

    const [vaccineNotifications, setVaccineNotifications] = useState([]);
    const [batches, setBatches] = useState([]);
    const [doctorNotes, setDoctorNotes] = useState({});
    const [loading, setLoading] = useState(false);
    const [showVaccineNotificationDialog, setShowVaccineNotificationDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [doctorNotesKey, setDoctorNotesKey] = useState(0);
    const [doctorNotesIndex, setDoctorNotesIndex] = useState(0);
    const [label, setLabel] = useState("")
    const [showDoctorNoteItemDialog, setShowDoctorNoteItemDialog] = useState(false);
    const [loadingDoctorNotes, setLoadingDoctorNotes] = useState(false);

    const [showVaccineCompletedDialog, setShowVaccineCompletedDialog] = useState(false);
    const [showDoctorNotesItemDeleteConfirm, setShowDoctorNotesItemDeleteConfirm] = useState(false);
    const [doctorNotesSortedArray, setDoctorNotesSortedArray] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    function onNewVaccineNotification(event) {
        console.debug(doctorNotes, "doctor notes")
        editMode = false;
        event.preventDefault();
        setShowVaccineNotificationDialog(true);
    }

    function handleVaccineNotificationClose() {
        currentVaccineNotification = undefined;
        setShowVaccineNotificationDialog(false);
    }

    function handleVaccineCompletedClose() {
        currentVaccineNotification = undefined;
        setShowVaccineCompletedDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }
    function handleDoctorNotesItemDeleteConfirmClose() {
        setShowDoctorNotesItemDeleteConfirm(false);
        currentDoctorNoteItem = undefined
    }

    /*const getDoctorNotes = () => {
        setLoadingDoctorNotes(true);
        showError('');

        const getAllDoctorNotesResponse = api.post(`farm/${farmID}/getAlldoctorNotes`,{
            batchId : 0
        })

        getAllDoctorNotesResponse.then(res => {
            setDoctorNotesKey(res.sortedByDateArray[0])
            setDoctorNotes(res.doctorNotesMap)
            setDoctorNotesSortedArray(res.sortedByDateArray)
            setLoadingDoctorNotes(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Doctor Notes Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
    }
    const getCompletedVaccinations = () => {
        setLoading(true);
        showError('');

        const getCompletedVaccinationsResponse = api.post(`farm/${farmID}/in-complete-vaccinations`,{
            batchId : 0
        })
        getCompletedVaccinationsResponse.then(res => {
            setVaccineNotifications([...res]);
            setLoading(false)
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Reminder Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
    }*/

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onLoad = () => {
        setLoading(true);
        setLoadingDoctorNotes(true);
        showError('');
        const getAllBatchesResponse = api.get(`farm/${farmID}/batch`).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Batches');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        const getCompletedVaccinationsResponse = api.get(`farm/${farmID}/in-complete-vaccinations`).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No vaccine Notifications Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        const getAllDoctorNotesResponse = api.get(`farm/${farmID}/doctorNotes`).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Doctor Notes');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        Promise.all([getAllBatchesResponse, getCompletedVaccinationsResponse, getAllDoctorNotesResponse]).then(results => {
            if (results[0])
                setBatches([...results[0]]);
            if (results[1])
                setVaccineNotifications([...results[1]]);
            if (results[2]) {
                setDoctorNotesKey(results[2].sortedByDateArray[0])
                setDoctorNotes(results[2].doctorNotesMap)
                setDoctorNotesSortedArray(results[2].sortedByDateArray)
                setLoadingDoctorNotes(false);
            }

        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(onLoad, [showVaccineNotification]);

    const onVaccineReminderDeleteConfirm = () => {
        if (currentVaccineNotification && currentVaccineNotification.vaccineId && currentVaccineNotification.vaccinationType === 1) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`vaccination-remainder/${currentVaccineNotification.vaccineId}`);

            response.then(res => {
                if (res) {
                    let index = vaccineNotifications.findIndex((srcVaccineNotification) => {
                        return srcVaccineNotification.vaccineId === currentVaccineNotification.vaccineId && currentVaccineNotification.vaccinationType === 1;
                    });
                    let newVaccineNotifications = [...vaccineNotifications];
                    newVaccineNotifications.splice(index, 1);
                    setVaccineNotifications(newVaccineNotifications);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Vaccine Reminder not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentVaccineNotification = undefined;
            });

        }
    };

    const onDelete = (vaccineNotification) => {
        currentVaccineNotification = vaccineNotification;
        setShowDeleteConfirm(true);
    };

    const onVaccineCompletedNotificationSave = (vaccineNotification) => {
        setLoading(true);
        showError('');
        console.log(vaccineNotifications)
        const response = api.post('completed-vaccination', {
            ...vaccineNotification,
        });

        response.then(res => {
            if (res) {
                let index = vaccineNotifications.findIndex((srcVaccineNotification) => {
                    return srcVaccineNotification.vaccineId === vaccineNotification.vaccineId &&
                        srcVaccineNotification.vaccinationType === vaccineNotification.vaccinationType;
                });
                let newVaccineNotifications = [...vaccineNotifications];
                newVaccineNotifications.splice(index, 1);
                setVaccineNotifications(newVaccineNotifications);
                setShowVaccineNotificationDialog(false);
                setShowVaccineCompletedDialog(false);
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Reminder Notification not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onVaccineRemainderSave = (vaccineNotification) => {
        setLoading(true);
        // showError('');
        const response = api.post('vaccination-remainder', {
            ...vaccineNotification,
        });

        response.then(res => {
            setVaccineNotifications([...vaccineNotifications, res]);
            setShowVaccineNotificationDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Reminder not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };
    const onVaccineRemainderUpdate = (vaccineNotification) => {
        setLoading(true);
        // showError('');
        console.debug(vaccineNotification, "vaccineNotification")
        const response = api.post(`vaccination-remainder/${vaccineNotification.vaccineId}`, {
            ...vaccineNotification,

        });
        response.then((res) => {
            let index = vaccineNotifications.findIndex((srcVaccineNotification) => {
                return (srcVaccineNotification.vaccineId === res.vaccineId &&
                    srcVaccineNotification.vaccinationType === res.vaccinationType);
            });
            setVaccineNotifications([...vaccineNotifications.slice(0, index),
            { ...res },
            ...vaccineNotifications.slice(index + 1)]);
            setShowVaccineNotificationDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Reminder not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onEdit = (vaccineNotification) => {
        currentVaccineNotification = {
            ...vaccineNotification
        };
        editMode = true;
        setShowVaccineNotificationDialog(true);
    };

    const onCompletedVaccination = (vaccineNotification) => {
        currentVaccineNotification = {
            ...vaccineNotification,
        };
        editMode = true;
        setShowVaccineCompletedDialog(true);
        setLabel("reminder")
    };

    const onAddDoctorNote = () => {
        setShowVaccineCompletedDialog(true);
        setLabel("doctorNoteCreation")
    }

    const onDoctorNotesItemCompleted = (doctorNotesItem) => {
        currentVaccineNotification = {
            ...doctorNotesItem,
        };
        editMode = true;
        setShowVaccineCompletedDialog(true);
        setLabel("doctorNotesItemCompleted")
    };

    const onDeleteDoctorNotesItem = (doctorNotesItem) => {
        currentDoctorNoteItem = doctorNotesItem;
        setShowDoctorNotesItemDeleteConfirm(true);
    }

    const onPreviousDoctorNote = () => {
        console.debug("doctor notes onPreviousDoctorNote ")
        if (doctorNotesIndex !== 0) {
            setDoctorNotesIndex(doctorNotesIndex - 1)
            setDoctorNotesKey(doctorNotesSortedArray[doctorNotesIndex - 1])
        }
    }

    const onNextDoctorNote = () => {
        console.debug("doctor notes onNextDoctorNote ")
        setDoctorNotesIndex(doctorNotesIndex + 1)
        setDoctorNotesKey(doctorNotesSortedArray[doctorNotesIndex + 1])
    }


    const onDoctorNoteCreation = (date) => {
        setLoadingDoctorNotes(true);
        showError('');
        const response = api.post(`farm/${farmID}/doctorNotes`, {
            date: date,
            farmId:parseInt(farmID, 10)
        });

        response.then(res => {
            if (res) {
                let docNotes = {
                    [res.id]: res,
                    ...doctorNotes
                }
                setDoctorNotes(docNotes)
                setDoctorNotesSortedArray([
                    res.id,
                    ...doctorNotesSortedArray
                ])
                setDoctorNotesKey(res.id)
                setDoctorNotesIndex(0)
                setShowVaccineCompletedDialog(false);
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Doctor Note not added');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoadingDoctorNotes(false);
        });
    };

    const onAddDoctorNoteItem = () => {
        setShowDoctorNoteItemDialog(true);
        editModeDoctorNoteItem = false
    }

    const onEditDoctorNoteItem = (data) => {
        console.debug("data ", data)
        currentDoctorNoteItem = data;
        setShowDoctorNoteItemDialog(true);
        editModeDoctorNoteItem = true
    }

    function handleDoctorNoteItemClose() {
        currentDoctorNoteItem = undefined;
        setShowDoctorNoteItemDialog(false);
    }

    const onDoctorNoteItemSave = (doctorNoteItem) => {
        setLoadingDoctorNotes(true);
        // showError('');
        console.debug(doctorNotesKey, doctorNoteItem)
        const response = api.post(`farm/${farmID}/doctorNotesItems`, {
            ...doctorNoteItem,
            doctorNotesId: parseInt(doctorNotesKey, 10)
        });

        response.then(res => {
            let docNotesItem = []
            if (doctorNotes[res.doctorNotesId].doctorNotesItems !== null && doctorNotes[res.doctorNotesId].doctorNotesItems !== undefined) {
                docNotesItem = [
                    ...doctorNotes[res.doctorNotesId].doctorNotesItems,
                    res
                ]
            } else {
                docNotesItem = [
                    res
                ]
            }
            console.debug("docNotesItem", docNotesItem)
            let docNotes = {
                ...doctorNotes,
                [res.doctorNotesId]: {
                    ...doctorNotes[res.doctorNotesId],
                    doctorNotesItems: docNotesItem
                }
            }
            setDoctorNotes({ ...docNotes });
            setShowDoctorNoteItemDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Doctor Note Item not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoadingDoctorNotes(false);
        });
    };
    const onDoctorNoteItemUpdate = (doctorNoteItem) => {
        setLoadingDoctorNotes(true);
        // showError('');
        console.debug(doctorNoteItem, "doctorNotesItems")
        const response = api.post(`farm/${farmID}/doctorNotesItems/${doctorNoteItem.id}`, {
            ...doctorNoteItem,

        });
        response.then((res) => {
            let index = (doctorNotes[res.doctorNotesId].doctorNotesItems).findIndex((srcDoc) => {
                return (srcDoc.id === res.id);
            });
            console.debug("index", index, doctorNotes[res.doctorNotesId].doctorNotesItems)
            let docNotes = {
                ...doctorNotes,
                [res.doctorNotesId]: {
                    ...doctorNotes[res.doctorNotesId],
                    doctorNotesItems: [
                        ...doctorNotes[res.doctorNotesId].doctorNotesItems.slice(0, index),
                        { ...res },
                        ...doctorNotes[res.doctorNotesId].doctorNotesItems.slice(index + 1)
                    ]
                }
            }
            console.debug(docNotes)
            setDoctorNotes({ ...docNotes });
            setShowDoctorNoteItemDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('Doctor Notes Item not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            currentVaccineNotification = undefined
            currentDoctorNoteItem = undefined
            setLoadingDoctorNotes(false);
            setShowVaccineCompletedDialog(false)
        });
    };

    const onDoctorNotesItemDeleteConfirm = () => {
        if (currentDoctorNoteItem && currentDoctorNoteItem.id !== undefined) {
            setShowDoctorNotesItemDeleteConfirm(false);
            setLoadingDoctorNotes(true);
            showError('');
            const response = api.delete(`farm/${farmID}/doctorNotesItems/${currentDoctorNoteItem.id}`);

            response.then(res => {
                if (res) {
                    let index = (doctorNotes[currentDoctorNoteItem.doctorNotesId].doctorNotesItems).findIndex((srcDoc) => {
                        return (srcDoc.id === currentDoctorNoteItem.id);
                    });
                    console.debug("index", index, doctorNotes[currentDoctorNoteItem.doctorNotesId].doctorNotesItems)
                    let docNotesItem = [...doctorNotes[currentDoctorNoteItem.doctorNotesId].doctorNotesItems]
                    docNotesItem.splice(index, 1)
                    let docNotes = {
                        ...doctorNotes,
                        [currentDoctorNoteItem.doctorNotesId]: {
                            ...doctorNotes[currentDoctorNoteItem.doctorNotesId],
                            doctorNotesItems: [
                                ...docNotesItem
                            ]
                        }
                    }
                    console.debug(docNotes)
                    setDoctorNotes({ ...docNotes });

                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Doctor Notes Item not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoadingDoctorNotes(false);
                currentDoctorNoteItem = undefined;
            });

        }
    };


    let vaccinationNotificationColumnDefaults = { title: 'Shed Name', field: 'shedName', editable: 'never' }
    let vaccinationNotificationColumn = {
        ...vaccinationNotificationColumnDefaults,
        render: rowData => <Mobile data={rowData}
            onCompletedVaccination={() => { onCompletedVaccination(rowData) }}
            onEdit={() => { onEdit(rowData) }}
            onDelete={() => { onDelete(rowData) }}
        />
    }
    let actions = [];
    let DoctorNotesAction = [
        rowData => ({
            icon: () => <TodayIcon color='primary'>completed Date</TodayIcon>,
            tooltip: 'Completed Date',
            onClick: (event, rowData) => onDoctorNotesItemCompleted(rowData),
            hidden: rowData.completionDate.Valid
        }),
        rowData => ({
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit Doctor Notes Item Details',
            onClick: (event, rowData) => onEditDoctorNoteItem(rowData),
            hidden: rowData.completionDate.Valid
        }),
        rowData => ({
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete Doctor Notes Item Details',
            onClick: (event, rowData) => onDeleteDoctorNotesItem(rowData),
            hidden: rowData.completionDate.Valid
        })
    ]
    if (isDesktop) {
        vaccinationNotificationColumn = vaccinationNotificationColumnDefaults;
        actions = [
            {
                icon: () => <TodayIcon color='primary'>edit</TodayIcon>,
                tooltip: 'Reminder Completed',
                onClick: (event, rowData) => onCompletedVaccination(rowData)
            },
            rowData => ({
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Reminder Details',
                onClick: (event, rowData) => onEdit(rowData),
                hidden: rowData.vaccinationType !== 1
            }),
            rowData => ({
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Reminder Details',
                onClick: (event, rowData) => onDelete(rowData),
                hidden: rowData.vaccinationType !== 1
            })
        ]
    }


    if (loading) {
        return <div className={classes.progressContainer}>
            <CircularProgress className={classes.progressBar} />
        </div>
    }

    return <div className={classes.cardContainer} >
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Reminder Notification
        </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewVaccineNotification}>Add Reminder</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                vaccinationNotificationColumn,
                { title: 'Batch Name', field: 'batchName', hidden: !isDesktop, editable: 'never' },
                { title: 'Details', field: 'vaccineName', hidden: !isDesktop, editable: 'never' },
                {
                    title: 'Due (In Days)', field: 'dueDays', hidden: !isDesktop, editable: 'never',
                    render: (rowData) => {
                        let label
                        let bgColor = classes.redChip
                        if (rowData.dueDays === 1) {
                            label = "Due in " + Math.abs(rowData.dueDays) +" day"
                            bgColor = classes.greenChip
                        } else if (rowData.dueDays === -1) {
                            label = "Over due by " + Math.abs(rowData.dueDays) +" day"
                            bgColor = classes.redChip
                        } else if (rowData.dueDays > 0) {
                            label = "Due in " + rowData.dueDays + " days"
                            bgColor = classes.greenChip
                        } else if (rowData.dueDays < 0) {
                            label = "Over due by " + Math.abs(rowData.dueDays) + " days"
                            bgColor = classes.redChip
                        } else {
                            label = "Today"
                            bgColor =  classes.orangeChip
                        }
                        return <div style={{ textAlign: "center" }} className={bgColor}>
                            {label}
                            </div>
                    }
                },
            ]}
            data={vaccineNotifications}
            title='Reminder Notification Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true
            }}
            actions={actions}
        />


            <Card className={classes.card}>
                <Grid>
                    <Typography className={classes.doctorTypo} display="block" variant="h3" gutterBottom>
                        Doctor Notes
                </Typography>
                </Grid>
                <CardActions>
                    <Grid className={classes.doctorTypo} >
                        <Button variant="contained" color="primary" onClick={onAddDoctorNote}>Add Doctor Note</Button>
                    </Grid>

                    <Grid className={classes.doctorTypo} >
                        <Button variant="contained" color="primary" disabled = {doctorNotesSortedArray.length ===0} onClick={onAddDoctorNoteItem}>Add Doctor Note Item</Button>
                    </Grid>
                </CardActions>
        {doctorNotes[doctorNotesKey] !== undefined ?
                <CardContent>
                    <MaterialTable
                        isLoading={loadingDoctorNotes}
                        columns={[
                            { title: 'Batch Name', field: 'batchName', editable: 'never',
                        render:rowData=>{
                        return <span>{rowData.batchName}({rowData.shedName})</span>
                        } },
                            { title: 'Notes', field: 'notes', editable: 'never',
                            },
                            /*{
                                title: 'Created Date', field: 'createTime', editable: 'never',
                                render: (rowData) => {
                                    return <span>{rowData.createTime.Valid ?
                                        moment(rowData.createTime.Time).format("DD-MM-YYYY")
                                    :
                                    ""
                                    }</span>
                                }
                            },*/
                            {
                                title: 'Completion Date', field: 'completionDate', editable: 'never',
                                render: (rowData) => {
                                    return <span>{rowData.completionDate.Valid ?
                                        moment(rowData.completionDate.Time).format("DD-MM-YYYY")
                                        : ""
                                    }</span>
                                }
                            }
                        ]}
                        data={doctorNotes[doctorNotesKey].doctorNotesItems !== null ? doctorNotes[doctorNotesKey].doctorNotesItems : []}
                        title={'Doctor Note Items Table - (Created On : ' + moment(doctorNotes[doctorNotesKey].date).format("DD-MM-YYYY") + ')'}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            showTitle: true,
                            
                            toolbar: true,
                        }}
                        actions={DoctorNotesAction}
                    />
                </CardContent>
            :
            null
        }

                <CardActions>
                    <Grid className={classes.doctorTypo}>
                        <Button variant="contained" color="primary" disabled={doctorNotesIndex === 0} onClick={onPreviousDoctorNote}><ArrowBackIosIcon /></Button>
                    </Grid>
                    {(doctorNotesSortedArray.length !== 0 ? (doctorNotesIndex + 1) : "0") + " of " + (doctorNotesSortedArray.length)}
                    <Grid className={classes.doctorTypo}>
                        <Button variant="contained" color="primary" 
                        disabled={((doctorNotesIndex+1) === doctorNotesSortedArray.length )||(doctorNotesSortedArray.length === 0)}
                        onClick={onNextDoctorNote}><ArrowForwardIosIcon /></Button>
                    </Grid>
                </CardActions>
            </Card>
        <CompletedVacination deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showVaccineCompletedDialog} handleClose={handleVaccineCompletedClose}
            onVaccineCompletedNotificationSave={onVaccineCompletedNotificationSave}
            editMode={editMode} data={currentVaccineNotification} from={label}
            onDoctorNoteCreation={onDoctorNoteCreation} onDoctorNoteItemUpdate={onDoctorNoteItemUpdate} />


        <VaccinationRemainder deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showVaccineNotificationDialog} handleClose={handleVaccineNotificationClose}
            editMode={editMode} data={currentVaccineNotification} batches={batches}
            onRemainderSave={onVaccineRemainderSave} onRemainderUpdate={onVaccineRemainderUpdate} />

        <DoctorNoteItem deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showDoctorNoteItemDialog} handleClose={handleDoctorNoteItemClose}
            editMode={editModeDoctorNoteItem} data={currentDoctorNoteItem} batches={batches}
            onDoctorNoteItemSave={onDoctorNoteItemSave} onDoctorNoteItemUpdate={onDoctorNoteItemUpdate} />


        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onVaccineReminderDeleteConfirm}
            title='Delete Reminder Notification ?'
            subText='This action will delete the Reminder Notification Details. Please confirm.' />

        <ConfirmDialog showDialog={showDoctorNotesItemDeleteConfirm}
            handleClose={handleDoctorNotesItemDeleteConfirmClose}
            onConfirm={onDoctorNotesItemDeleteConfirm}
            title='Delete Doctor Notes Item ?'
            subText='This action will delete the Doctor Notes Item Details. Please confirm.' />
    </div>
}

export default VaccineNotification;