import React from 'react';
import api from '../../../common/api';
import { MaterialTable } from '../../../components';
import { MenuItem, Select } from '@material-ui/core';
import { MTableBody } from 'material-table';

const FeedMaterialTable = ({ feedFormulationId, dataList, setDataList, materialTypes, tableFooterClass, totalQuantity, tableEndpoint, materialTypeColumnTitle, setUpdatedFeedFormulationMaterial, ...rest })=> {
    const TABLE_URL = `feed-formulation/${feedFormulationId}/${tableEndpoint}`;

    // this function will update updatedFeedFormulationMaterial state,
    // using this state change, we can update energy profile from parent component's table
    const handleMaterialDataChange = (materialData) => {
        // if table is for materials, then update energy profile
        if(tableEndpoint === "feed-formulation-material"){
            setUpdatedFeedFormulationMaterial({feedFormulationId, materialData});
        }
    }

    const onEditHandler = async (data) => {
        try {
            await api.post(`${TABLE_URL}/${data.id}`, {
                ...data,
                quantity: parseFloat(data.quantity, 10),
            });
            
            let index = dataList.findIndex((srcData) => srcData.id === data.id);
            setDataList([
                ...dataList.slice(0, index),
                {...data},
                ...dataList.slice(index + 1)
            ]);
            handleMaterialDataChange(dataList);
        } catch (err) {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    alert('Material not updated');
                else if (err.message === 'duplicate key')
                    alert('Duplicate Entry');
                else
                    alert(err.message);
            } else {
                alert('Unknown Error');
            }
        }
    }

    const onCreateHandler = async (data) => {
        try {
            const res = await api.post(TABLE_URL, {
                ...data,
                quantity: parseFloat(data.quantity, 10),
            });
            setDataList([...dataList, res]);
            handleMaterialDataChange(dataList);
        } catch (err) {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    alert('Material not added');
                else if (err.message === 'duplicate key')
                    alert('Duplicate Entry');
                else
                    alert(err.message);
            } else {
                alert('Unknown Error');
            }
        }
    }

    const onDeleteHandler = async (data) => {
        if (data && data.id) {
            try {
                const res = await api.delete(`${TABLE_URL}/${data.id}`);
                if (res) {
                    let index = dataList.findIndex((src) => src.id === data.id);
                    let newData = [...dataList];
                    newData.splice(index, 1);
                    setDataList(newData);
                    handleMaterialDataChange(newData);
                }
            } catch (err) {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        alert('Material not Deleted');
                    else
                        alert(err.message);
                } else {
                    alert('Unknown Error');
                }
            }
        }
    }

   return(
           <MaterialTable
               columns={[
                   {title: 'feedFormulationID', field: 'feedFormulationID', hidden: true},
                   {title: 'id', field: 'id', hidden: true},
                   {title: materialTypeColumnTitle, field: 'materialTypeName', editComponent: props => {
                       let index = materialTypes.findIndex((val => val.id === props.rowData.materialTypeID));
                       if (index === -1) {
                           index = '';
                       };
                       return <Select
                           id="materialType-select"
                           value={index}
                           onChange={e => {
                               props.rowData.materialTypeID = materialTypes[e.target.value].id;
                               props.onChange(materialTypes[e.target.value].materialType);
                           }}
                       >
                           {materialTypes.map((materialType, index) =>
                               <MenuItem key={materialType.id} value={index}>{materialType.materialType} ({materialType.currentStock})</MenuItem>
                           )}
                       </Select>
                   }},
                   {title: 'Quantity', field: 'quantity'},
               ]}
               data={ dataList || [] }
               options={{
                   actionsColumnIndex: -1,
                   search: true,
                   toolbar: true,
                   paging: false
               }}
               components={{
                   Body: props => (
                     <>
                       <MTableBody {...props} />
                       <tr className={tableFooterClass}>
                         <td>Total Quantity</td>
                         <td>{totalQuantity}</td>
                       </tr>
                     </>
                   ),
               }}
               editable = {{
                  onRowDelete: onDeleteHandler,
                  onRowUpdate: onEditHandler,
                  onRowAdd: onCreateHandler
               }}
               {...rest}
           />
   );
};

export default FeedMaterialTable;