import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import {useGlobalState} from '../../../../state';

const useStyles = makeStyles(theme => ({
    actions: {
        padding: theme.spacing(1, 2),
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        background: theme.palette.logoutSidebar.background,
        color: theme.palette.logoutSidebar.text
    }
}));

const BottomMenu = props => {
    const {className, ...rest} = props;
    // eslint-disable-next-line
    const [, dispatch] = useGlobalState();
    const classes = useStyles();

    return (
        <div
            {...rest}
            className={clsx(className)}
        >
            <div className={classes.actions}>
                <Button
                    className={classes.button}
                    onClick={
                        () => dispatch({type: 'logout'})
                    }
                >
                    Logout
                </Button>
            </div>
        </div>
    );
};

BottomMenu.propTypes = {
    className: PropTypes.string
};

export default BottomMenu;
