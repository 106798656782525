import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FarmDashboard from './components/farmDashboard';
// import FarmDashboardforTable from './components/farmDashboardforTable';
//import ShedDetailsinTable from './shedDetailsinTable';
// import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
    root: props=>({
        margin: props.isDesktop?theme.spacing(4):theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
}));

const Dashboard = ({showError,match, history}) => {


    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});
    const farmID = match.params['farmID'];
    // const [tableView,setTableView] = useState(false);
    // const viewChange = (event,togglestate)=> {
    //     console.debug(event,togglestate)
    //     setTableView(togglestate)
    // }

    return (
        <div className={classes.root}>
            <Grid
                container
                justify='space-between'
                alignItems='flex-end'
                className={classes.topBar}
            >
                <Grid>
                    <Typography variant="overline" display="block" gutterBottom>
                        FARM
                    </Typography>
                    <Typography display="block" variant="h3" gutterBottom>
                        Farm Overview-&nbsp;{ window.localStorage.getItem("farmName")}
                    </Typography>
                    <Grid item>
              {/* <b>TABLE VIEW</b>{
            <Switch
              //checked={0}
              onChange={viewChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />} */}
              </Grid>
                </Grid>
               
                <Grid>
                    {/*<Button variant="contained" color="primary" onClick={onNewFarm}>Add Farm</Button>*/}
                </Grid>
            </Grid>
            {/* {tableView ?
            <Grid container
            spacing={3} >
                <FarmDashboardforTable showError={showError} farmID={farmID}/>
            </Grid>
            
                : ""
            } */}
            <Grid
                container
                spacing={3}
            >
                <FarmDashboard showError={showError} farmID={farmID}/>
            </Grid>
        </div>
    );
};

export default Dashboard;