import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    content: {
        paddingTop: 150,
        textAlign: 'center'
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 560
    },
    textMiddle: {
        marginTop: theme.spacing(2)
    },
    textBotton: {
        marginTop: theme.spacing(3)
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
}));

const SuccessRegistration = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
        defaultMatches: true
    });
    const { history } = props;

    const handleBack = () => {
        history.goBack();
    };

    return (
        <div className={classes.root}>
            <Grid
                container
                justify="center"
                spacing={4}
            >
                <Grid
                    item
                    lg={6}
                    xs={12}
                >
                     <div className={classes.contentHeader}>
                                <IconButton onClick={handleBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                     </div>
                    <div className={classes.content}>
                        <Typography variant="h3" color="textPrimary">
                            Thank you for Registering.
                        </Typography>
                        {!isDesktop ? 
                            <Typography className={classes.textMiddle} variant="h2" color="textPrimary">
                                You will receive an email <br/><br/> with a link to activate your account.
                            </Typography>
                            :
                            <Typography className={classes.textMiddle} variant="h2" color="textPrimary">
                                You will receive an email with a link to activate your account.
                            </Typography>
                        }
                        <br/>
                        <Typography className={classes.textBotton} variant="h1" color="secondary">
                            Please Check your email.
                        </Typography>
                        <img
                            alt="Success"
                            className={classes.image}
                            src="/images/undraw_mail_box_kd5i.svg"
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default SuccessRegistration;