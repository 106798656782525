import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { MaterialTable } from "../../components";
import api from "../../common/api";
import Mobile from "./components/mobile";
import moment from "moment";
import BatchShedAvailability from "./components/batchShedAvailability";
import Icon from "@material-ui/core/Icon";



const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1),
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    }
}));

let currentData = undefined;
let editMode = false;
const BatchShedAvailabilities = ({ showError, showMessage, match }) => {
    const farmID = match.params['farmID'];
    const [loading, setLoading] = useState(false);
    const [showBatchShedAvailabilityDialog, setShowBatchShedAvailabilityDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });
    const [batchShedAvailabilities, setBatchShedAvailabilities] = useState([]);

    const handleBatchShedAvailabilityClose = () => {
        currentData = undefined;
        setShowBatchShedAvailabilityDialog(false);
    };

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onBatchShedAvailabilityEdit = (batchShedAvailability) => {
        currentData = {
            ...batchShedAvailability
        };
        editMode = true;
        setShowBatchShedAvailabilityDialog(true);
    };

    const getAll = () => {
        setLoading(true);
        showError('');
        showMessage('');
        const response = api.get(`farm/${farmID}/batch-shed-availability`);

        response.then(res => {
            setBatchShedAvailabilities([...res]);
        }).catch(err => {
            if (err.message) {
                console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const onBatchShedAvailabilityUpdate = (batchShedAvailability) => {
        setLoading(true);
        // showError('');
        const response = api.post(`farm/${farmID}/batch-shed-availability/${batchShedAvailability.id}`, {
            ...batchShedAvailability
        });
        response.then((res) => {
            let tempBatchShedAvailabilities = batchShedAvailabilities;

            if (res.previousId.Valid && res.previousIdEndDate.Valid) {
                let previousIdIndex = tempBatchShedAvailabilities.findIndex((srcData) => {
                    return srcData.id === res.previousId.Int64;
                });

                let previousElement = {
                    ...tempBatchShedAvailabilities[previousIdIndex],
                    endDate: res.previousIdEndDate,
                    oldEndDate: res.previousIdEndDate
                }

                tempBatchShedAvailabilities = ([...tempBatchShedAvailabilities.slice(0, previousIdIndex),
                { ...previousElement },
                ...tempBatchShedAvailabilities.slice(previousIdIndex + 1)]);
            }

            if (res.nextId.Valid && res.nextIdStartDate.Valid) {
                let nextIdIndex = tempBatchShedAvailabilities.findIndex((srcData) => {
                    return srcData.id === res.nextId.Int64;
                });

                let nextElement = {
                    ...tempBatchShedAvailabilities[nextIdIndex],
                    startDate: res.nextIdStartDate,
                    oldStartDate: res.nextIdStartDate
                }

                tempBatchShedAvailabilities = ([...tempBatchShedAvailabilities.slice(0, nextIdIndex),
                { ...nextElement },
                ...tempBatchShedAvailabilities.slice(nextIdIndex + 1)]);
            }

            let index = tempBatchShedAvailabilities.findIndex((srcData) => {
                return srcData.id === res.id;
            });

            tempBatchShedAvailabilities = ([...tempBatchShedAvailabilities.slice(0, index),
            { ...res },
            ...tempBatchShedAvailabilities.slice(index + 1)]);

            setBatchShedAvailabilities([...tempBatchShedAvailabilities])
            setShowBatchShedAvailabilityDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Batch Shed Availability not updated');
                else if (err.message === 'Duplicate Key')
                setErrorMessage('Duplicate Entry');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };


    useEffect(getAll, []);

    let batchShedAvailabilityColumnDefaults = { title: 'Batch Name', field: 'batchName' };
    let batchShedAvailabilityColumn = {
        ...batchShedAvailabilityColumnDefaults,
        render: rowData => <Mobile data={rowData} onEdit={()=>{onBatchShedAvailabilityEdit(rowData)}}
        />
    };
    let actions = []
    if (isDesktop) {
        batchShedAvailabilityColumn = batchShedAvailabilityColumnDefaults;
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Batch Shed Availability',
                onClick: (event, rowData) => {
                    onBatchShedAvailabilityEdit(rowData);
                }
            }
        ]
    }
    return (
        <div className={classes.root}>
            <Grid
                container
                justify='space-between'
                alignItems='flex-end'
                className={classes.topBar}
            >
                <Grid>
                    <Typography display="block" variant="h3" gutterBottom>
                        Batch Shed Availability
                    </Typography>
                </Grid>
            </Grid>
            <MaterialTable
                spacing={5}
                isLoading={loading}
                columns={[
                    { title: 'id', field: 'id', hidden: true },
                    batchShedAvailabilityColumn,
                    { title: 'Shed Name', field: 'shedName', hidden: !isDesktop },
                    {
                        title: 'Start Date', field: 'startDate.Time', type: 'datetime',
                        editable: 'never',
                        hidden: !isDesktop,
                        render: rowData => {
                            return <span>{rowData.startDate.Valid ? moment(rowData.startDate.Time).format("DD-MM-YYYY") : ""}</span>
                        }
                    },
                    {
                        title: 'End Date', field: 'endDate.Time', type: 'datetime',
                        editable: 'never',
                        hidden: !isDesktop,
                        render: rowData => {
                            return <span>{rowData.endDate.Valid ? moment(rowData.endDate.Time).format("DD-MM-YYYY") : ""}</span>
                        }
                    },
                ]}
                data={batchShedAvailabilities}
                options={{
                    actionsColumnIndex: -1,
                    search: true,
                    showTitle: true,
                    
                    toolbar: true
                }}
                actions={actions}
                title='Batch Shed Availability Table'
            />
            <BatchShedAvailability deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showBatchShedAvailabilityDialog} handleClose={handleBatchShedAvailabilityClose}
                onEdit={onBatchShedAvailabilityUpdate} editMode={editMode} data={currentData} />
        </div>
    );
};

export default BatchShedAvailabilities;