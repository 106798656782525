import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
let schema = {
    farmIndex: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    shedIndex: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    date: {
        presence: {allowEmpty: false, message: 'is required'}
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    formControlSwitch: {
        display: 'flex',
        fontSize:"15px"
    },
    formControlSwitchLabel: {
        marginTop: "10px",
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '2'
    },
}));

const FeedFormulation = ({ deleteErrorMessage, errorMessage, onEdit, data, onSave, handleClose, showDialog, editMode = false, farms, sheds, onFarmChange, action,storageformula}) => {
    const classes = useStyles();
    // eslint-disable-next-line
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [popupLoading, setPopupLoading] = useState(false) ;
    const [isStorage,setIstorage] = useState(false)
    const resetBeforeEdit = () => {
        if(showDialog){
            updateData(data, editMode);
            setPopupLoading(false);
        }
    };

    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        setPopupLoading(true)
        let tempData;
        if (isStorage) {
            tempData = {
                ...formState.values,
                feedStorageID:storageformula[formState.values.feedStorageIndex].id,
                feedStorageName:storageformula[formState.values.feedStorageIndex].formulaName,
                isStorage:isStorage
            };
        } else {
            tempData = {
                ...formState.values,
                farmID: farms[formState.values.farmIndex].id,
                farmName: farms[formState.values.farmIndex].name,
                shedID: sheds[formState.values.shedIndex].id,
                shedName: sheds[formState.values.shedIndex].name,
            };
        }
        if (editMode)
            onEdit(tempData, setPopupLoading);
        else
            onSave(tempData, setPopupLoading);
        
    };

    const onFarmChangeLocal = function (event) {
        onFarmChange(farms[event.target.value].id);
    }

    useEffect(()=>{
        if (isStorage) {
            schema = {
                feedStorageIndex: {
                    presence: {allowEmpty: false, message: 'is required'}
                },
                date: {
                    presence: {allowEmpty: false, message: 'is required'}
                }
            };
        }else {
            schema = {
                farmIndex: {
                    presence: {allowEmpty: false, message: 'is required'}
                },
                shedIndex: {
                    presence: {allowEmpty: false, message: 'is required'}
                },
                date: {
                    presence: {allowEmpty: false, message: 'is required'}
                }
            };
        }
    },[isStorage])

    const onFeedStorageChange = function (event) {
        setIstorage(!isStorage)
        resetBeforeEdit()
    }

    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >{action} Feed Formulation
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>
                <FormControl disabled className={classes.formControl}>
                <InputLabel id="farm-select-outlined-label">
                    Farm
                </InputLabel>
                <Select disabled
                    id="farmIndex"
                    value={formState.values.farmIndex !== undefined ? formState.values.farmIndex : ''}
                    onChange={event => {
                        handleChange(event, onFarmChangeLocal)
                    }}
                    helperText={
                        hasError('farmIndex') ? formState.errors.farmIndex[0] : null
                    }
                    name="farmIndex"
                >

                    {farms !== undefined && farms !== null ?
                        farms.map((farm, index) => {
                                return <MenuItem key={farm.id} value={index}>{farm.name}</MenuItem>
                            }
                        ) : []}
                </Select>
            </FormControl>
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="shed-select-outlined-label">
                    Shed
                </InputLabel>
                <Select disabled
                    id="shedIndex"
                    value={formState.values.shedIndex !== undefined ? formState.values.shedIndex : ''}
                    onChange={handleChange}
                    name="shedIndex"
                    helperText={
                        hasError('shedIndex') ? formState.errors.shedIndex[0] : null
                    }
                >
                    {sheds !== undefined && sheds !== null ?
                        sheds.map((shed, index) =>
                            <MenuItem key={shed.id} value={index}>{shed.name}</MenuItem>
                        ) : []}
                </Select>
            </FormControl>
            <MuiPickersUtilsProvider disabled utils={DateFnsUtils}>
                <DatePicker disabled
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({target: {name: 'date', value: data}})
                    }}
                    helperText={
                        hasError('date') ? formState.errors.date[0] : null
                    }
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
                :
            <div>
          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
                <div  className={classes.formControlSwitch}>
                    <div className={classes.formControlSwitchLabel}>
                        Storage
                    </div>
                    <div>   
                        <Switch
                            checked={isStorage}
                            onChange={(event)=>{
                                onFeedStorageChange(event)
                              
                            }}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                </div>
                <br/>
                {
                    isStorage ? 
                    <FormControl className={classes.formControl}>
                    <InputLabel id="farm-select-outlined-label">
                        Storage
                    </InputLabel>
                    <Select
                        id="feedStorageIndex"
                        value={formState.values.feedStorageIndex !== undefined ? formState.values.feedStorageIndex : ''}
                        onChange={handleChange}
                        helperText={
                            hasError('feedStorageIndex') ? formState.errors.feedStorageIndex[0] : null
                        }
                        name="feedStorageIndex"
                    >
    
                        {storageformula !== undefined && storageformula !== null ?
                            storageformula.map((formula, index) => {
                                    return <MenuItem key={formula.id} value={index}>{formula.formulaName}</MenuItem>
                                }
                            ) : []}
                    </Select>
                </FormControl>
                 :
                 <>
                             <FormControl className={classes.formControl}>
                                <InputLabel id="farm-select-outlined-label">
                                    Farm
                                </InputLabel>
                                <Select
                                    id="farmIndex"
                                    value={formState.values.farmIndex !== undefined ? formState.values.farmIndex : ''}
                                    onChange={event => {
                                        handleChange(event, onFarmChangeLocal)
                                    }}
                                    helperText={
                                        hasError('farmIndex') ? formState.errors.farmIndex[0] : null
                                    }
                                    name="farmIndex"
                                >

                                    {farms !== undefined && farms !== null ?
                                        farms.map((farm, index) => {
                                                return <MenuItem key={farm.id} value={index}>{farm.name}</MenuItem>
                                            }
                                        ) : []}
                                </Select>
                            </FormControl>

                            <FormControl  className={classes.formControl}>
                                <InputLabel id="shed-select-outlined-label">
                                    Shed
                                </InputLabel>
                                <Select
                                    id="shedIndex"
                                    value={formState.values.shedIndex !== undefined ? formState.values.shedIndex : ''}
                                    onChange={handleChange}
                                    name="shedIndex"
                                    helperText={
                                        hasError('shedIndex') ? formState.errors.shedIndex[0] : null
                                    }
                                >
                                    {sheds !== undefined && sheds !== null ?
                                        sheds.map((shed, index) =>
                                            <MenuItem key={shed.id} value={index}>{shed.name}</MenuItem>
                                        ) : []}
                                </Select>
                            </FormControl>
                 </>
                }
            
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({target: {name: 'date', value: data}})
                    }}
                    helperText={
                        hasError('date') ? formState.errors.date[0] : null
                    }
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>

          }
        </DialogContent>
    </Dialog>
};

export default FeedFormulation;