import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog,MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LineName from "./components/line_name";
import Icon from '@material-ui/core/Icon';



const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentLineName = undefined;
let editMode = false;

const LineNameView = ({showError, showMessage, history}) => {

    const [line_name, setLineName] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showLineNameDialog, setShowLineNameDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    function onNewLineName(event) {
        editMode = false;
        event.preventDefault();
        setShowLineNameDialog(true);
    }

    function handleLineNameClose() {
        currentLineName = undefined;
        setErrorMessage(null);
        setShowLineNameDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const onLineNameDeleteConfirm = () => {
        if (currentLineName && currentLineName.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`line_name/${currentLineName.id}`);

            response.then(res => {
                if (res) {
                    let index = line_name.findIndex((srcLineName) => {
                        return srcLineName.id === currentLineName.id;
                    });
                    let newLineName = [...line_name];
                    newLineName.splice(index, 1);
                    setLineName(newLineName);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('LineName not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentLineName = undefined;
            });

        }
    };

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onDelete = (line_name) => {
        currentLineName = line_name;
        setShowDeleteConfirm(true);
    };

    const onLineNameSave = (ln, setpopuploading) => {
        //setLoading(true);
        // setShowLineNameDialog(false);
        // setErrorMessage('');
        showMessage('');
        const response = api.post('line_name', {
            ...ln,
        });

        response.then(res => {
            setLineName([...line_name, res]);
            setShowLineNameDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('LineName not added');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false)
        });
    };


    const onLineNameEdit = (ln) => {
        currentLineName = {
            ...ln,
        };
        editMode = true;
        setShowLineNameDialog(true);
        
    };

    const onLineNameUpdate = (ln) => {
        //setLoading(true);
        // showError('');
        const response = api.post(`line_name/${ln.id}`, {
            ...ln,
        });
        response.then(() => {
            setLoading(false);
            let index = line_name.findIndex((srcLineName) => {
                return srcLineName.id === ln.id;
            });
            setLineName([...line_name.slice(0, index),
                {...ln},
                ...line_name.slice(index + 1)]);
            setShowLineNameDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('LineName not Updated');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        })
    };

    //Get all LineName API Call
    const getAllLineName = () => {
        setShowLineNameDialog(false);
        showError('');
        showMessage('');

        const response = api.get('line_name');

        response.then(res => {
          
            setLineName([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No LineName Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }



    const onload=()=>{
        setLoading(true);
        Promise.all([getAllLineName()]).finally(()=>{
            setLoading(false);
        });
    }
    useEffect(onload, []);
    
    let actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit LineName',
                onClick: (event, rowData) => onLineNameEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete LineName',
                onClick: (event, rowData) => onDelete(rowData)
            }
        ]


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Lines
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewLineName}>Add Line</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'id', hidden: true},
                {title: 'account_id', field: 'account_id', hidden: true},
                {title: 'Line Name', field: 'name', editable: 'never', defaultSort:'asc'},
            ]}
            data={line_name}
            title='Lines Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true
            }}
            actions={actions}
        />
        <LineName errorMessage={errorMessage} showDialog={showLineNameDialog} handleClose={handleLineNameClose}
         onSave={onLineNameSave} onEdit={onLineNameUpdate}
               editMode={editMode} data={currentLineName}
               deleteErrorMessage={removeErrorMessage} />
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onLineNameDeleteConfirm}
                       title='Delete Line ?'
                       subText='This action will delete the Line. Please confirm.'/>
    </div>
}

export default LineNameView;