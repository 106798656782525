import React,{useState, useEffect} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Container,
    TextField,
    Typography,
    CardActions,
    DialogActions,
  } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { Avatar } from '@material-ui/core';
import api from '../../common/api';
import { useGlobalState } from '../../state';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from "@material-ui/core/DialogContent";
import Chip from "@material-ui/core/Chip";
import UpdateIcon from '@material-ui/icons/Update';
import {parse} from 'query-string';
const parsed = parse(window.location.search);
console.log(parsed);

  const useStyles = makeStyles(theme => ({
    avatar: {
      width: 100,
      height: 100,
  },
  root:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name:{
    textAlign:'center',
    paddingTop:10
  },
  bioo:{
    textAlign:'center',
  },
  gridpadding:{
    width: 250 ,
    paddingRight:theme.spacing(2),
    paddingBottom:theme.spacing(2)
  },
  selectOption: {
    width:235
},
progressContainer: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%'
},
progressBar: {
  margin: theme.spacing(2),
  position: 'absolute',
  top: '65%',
  zIndex: '9999'
},
ChipPadding:{
  padding: '15px',
}
}));

const userDetailState = {
  profilePic:"",
	firstName: "",
	lastName: "",
	email: "",
	phoneNumber: "",
}
  
const DoctorProfilepage = () => {
  
  const [baseImage, setBaseImage] = useState(userDetailState.profilePic);
  const uploadImage = async(event)=>{
    const file = event.target.files[0];
   const base64 = await convertBase64(file) 
   console.log("base64",base64)
    setBaseImage(base64)
    console.log("baseimage",baseImage)
  }
  
  const convertBase64=(file)=>{
    return new Promise((resolve,reject)=>{
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload=()=>{
          resolve(fileReader.result);
        }
        fileReader.onerror =(error)=>{
          reject(error)
        }
      })
  }
  const classes = useStyles();
  const [values, setValues] = useState(userDetailState);
      const handleChange = (event) => {
        setValues({
          ...values,
          [event.target.name]:event.target.value
      })
    };
    
    const getUserDetail = () => {
      const response = api.get('user-detail');
      response.then((res) => {
        console.log("RESPONSE", res)
        setValues(res)
      })
    }
    

    const onLoad = () => {
      Promise.all([
          getUserDetail()
      ]).finally(() => {
      });
  };
  
  const [{user}] = useGlobalState();
useEffect(onLoad, [])
async function readFromQueryString(){
  /* const parsed = parse(props.location.search);
   console.log(parsed);
   setQueryString(parsed);*/
}
useEffect(()=>{
  readFromQueryString()
},[]);
const [popupLoading, setPopupLoading] = useState(false);
      const onSubmit = (event) => {
        setUpdateMessage(true)
        setPopupLoading(true)
        event.preventDefault();
        console.log("baseImage",baseImage)
        const response = api.post('profilepage', {
          profilePic:baseImage,    
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email ,
          phoneNumber: values.phoneNumber,
        }); 
        response.then(res => {
          getUserDetail()
        }).catch(err => {
          if (err.message) {
              if (err.message === 'Bad Request' || err.message === 'No data found')
                  setUpdateMessage('Not Updated');
          }
      }).finally(() => {
        setPopupLoading(false);
      });
       }
      const [updateMessage, setUpdateMessage] = useState('')
      const deleteUpdateMessage = () =>{
      setUpdateMessage('');
  }
  
      return (
<DialogContent>
        {
          popupLoading ?
          <div>
            <div className={classes.progressContainer}>
                    <CircularProgress  className={classes.progressBar}/ >
              </div>
              <br/><br/>
              <form
          autoComplete="off"
          noValidate
        >
          <Container maxWidth="sm"> 
            <Grid
              item
              xs
            >
              <Card
               >
          <CardContent>
      <Grid
      className={classes.root}
      >
      <Avatar
      src={baseImage}
      style={{opacity: '0.6'}}
      alt="Profile"
      className={classes.avatar}
    />
    </Grid>
 <Grid
 className={classes.name}>
    <Typography
      color="textPrimary"
      gutterBottom
      variant="h3"
      style={{opacity: '0.6'}}
    >
      {values.firstName +" " + values.lastName}
    </Typography>
    </Grid>
  
    <Grid className={classes.bioo}>
      <Typography
      disabled
      color="textSecondary"
      variant="body1"
    >
      { user.role ||  'Managing Director'}
    </Typography>
                </Grid>
            </CardContent>  
    <Divider />
    <CardActions>
    <input  style={{opacity: '0.6'}} name="profilePic"  type="file" accept="image/*" onChange={(event)=>{
      uploadImage(event);
    }} />
      </CardActions>
      </Card>
          <Grid
            item  
            xs
          >
          <Card>
            <CardHeader
              // subheader="The information can be edited"
            />
            <Divider />
            <CardContent>
              
                <Grid container direction="row"
                >
                  <TextField
                    helperText="Please specify the first name"
                    label="First name"
                    name="firstName"
                    onChange={handleChange}
                    disabled
                    required
                    className={classes.gridpadding}
                    value={values.firstName}
                    variant="outlined"
                  />
                  <TextField
                    label="Last name"
                    name="lastName"
                    onChange={handleChange}
                    disabled
                    required
                    className={classes.gridpadding}
                    value={values.lastName}
                    variant="outlined"
                  />
                 <TextField
                    label="Email Address"
                    name="email"
                    disabled
                    // onChange={handleChange}
                    required
                    className={classes.gridpadding}
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    label="Phone Number"
                    name="phoneNumber"
                    disabled
                    onChange={handleChange}
                    // type="number"
                    className={classes.gridpadding}
                    value={values.phoneNumber}
                    variant="outlined"
                  />
                </Grid>
                  
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <DialogActions>
              <Button
                color="primary"
                variant="contained"
                disabled
                onClick={onSubmit}
              >
                Save details
              </Button>
              </DialogActions>
            </Box>
          </Card>
          </Grid>
        </Grid>
        </Container>
  </form> 
              </div>
              :
              <>
             <div className={classes.ChipPadding}>
{updateMessage?<Chip  color="secondary" onDelete={deleteUpdateMessage} icon={<UpdateIcon/>} label="Updated. Please logout and login again for your changes to become effective" /> : ""}
</div>
        <form
          autoComplete="off"
          noValidate
        >
          
          <Container maxWidth="sm"> 
            <Grid
              item
              xs
            >
              <Card
               >
          <CardContent>
      <Grid
      className={classes.root}
      >
        
        <Avatar
      
        src={baseImage}
        alt="Profile"
        className={classes.avatar}
      />
    </Grid>
    
 <Grid
 className={classes.name}>
    <Typography
      color="textPrimary"
      gutterBottom
      variant="h3"
    >
      {values.firstName +" " + values.lastName}
    </Typography>
    </Grid>
  
    <Grid className={classes.bioo}>
      <Typography
      color="textSecondary"
      variant="body1"
    >
      { user.role ||  'Managing Director'}
    </Typography>
                </Grid>  
    
            </CardContent>
    <Divider />
    <CardActions>
      <form enctype="multipart/form-data">
      <input name="profilePic"  type='file' accept="image/*" onChange={(event)=>{
      uploadImage(event);
    }} />
      </form>
      </CardActions>
      </Card>
          <Grid
            item  
            xs
          >
          <Card>
            <CardHeader
              // subheader="The information can be edited"
            />
            <Divider />
            <CardContent>
              
                <Grid container direction="row"
                >
                  <TextField
                    helperText="Please specify the first name"
                    label="First name"
                    name="firstName"
                    onChange={handleChange}
                    required
                    className={classes.gridpadding}
                    value={values.firstName}
                    variant="outlined"
                  />
                  <TextField
                    label="Last name"
                    name="lastName"
                    onChange={handleChange}
                    required
                    className={classes.gridpadding}
                    value={values.lastName}
                    variant="outlined"
                  />
                 <TextField
                    label="Email Address"
                    name="email"
                    onChange={handleChange}
                    required
                    className={classes.gridpadding}
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    label="Phone Number"
                    name="phoneNumber"
                    onChange={handleChange}
                    // type="number"
                    className={classes.gridpadding}
                    value={values.phoneNumber}
                    variant="outlined"
                  />
                </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={onSubmit}
              >
                Save details
              </Button>
              </DialogActions>
            </Box>
          </Card>
          </Grid>
        </Grid>
        </Container>
  </form> 
  </>
            } 
            </DialogContent>
      );
    };
export default DoctorProfilepage;