import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FeedStorageStock from './components/FeedStorageStock'
import Icon from '@material-ui/core/Icon';
import moment from 'moment'
import Mobile from "./components/mobile";

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));


const FeedStorageView = ({ showError, showMessage, history, match }) => {

    const [FeedStorageStockData, setFeedStorageData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentFeedStrage,setCurrentFeedStorage] =useState({})
    const [showFeedStorageDialog, setShowFeedStorageDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [Shed, setShed] = useState([])
    const [storageFormula, setStorageFormula] = useState([]);
    const [farms, setFarms] = useState([]);
    const [editMode,setEditMode] = useState(false)
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    function onNewFeedStorageDatas(event) {
        setEditMode(false)
        setErrorMessage("");
        event.preventDefault();
        getAllStorageFormula()
        setShowFeedStorageDialog(true);
    }

    function handleMedicineStockClose() {
        setErrorMessage(null);
        setCurrentFeedStorage({});
        setShowFeedStorageDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const getAllStorageFormula = (shedStorageID,date) => {
        setLoading(true);
        showError('');
        let tempData ={
            date:date,
            shedFeedStorageID:shedStorageID
        }
        const response = api.post('/available-feed-formulation-storage',tempData);
        response.then(res => {
            let storageData = [...res]
            setStorageFormula(storageData);
            setLoading(false)
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log("No storage Formula Found")
            } else {
                showError('Unknown Error')
            }
            setLoading(false)
        });
        return response;
    };
    

    const getAllFarms = () => {
        
        showError('');
        const response = api.get('farm');
        response.then(res => {
            setFarms([...res]);
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Farms Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        
        return response;
    };

    const onHandleFarmChange = (farmID) => {
        getAllSheds(farmID)
    }
    function getAllSheds(farmID) {
        setLoading(true);
        showMessage('');
        const response = api.get(`farm/${farmID}/shed`);

        response.then(res => {
            setShed(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('No Sheds');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });

        return response;
    };
    const onFeedStorageDeleteConfirm = () => {
        if (currentFeedStrage && currentFeedStrage.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`/move-to-shed-feed-storage/${currentFeedStrage.id}`);

            response.then(res => {
                if (res) {
                    let index = FeedStorageStockData.findIndex((srcMedicineStock) => {
                        return srcMedicineStock.id === currentFeedStrage.id;
                    });
                    let newStorageStockData = [...FeedStorageStockData];
                    newStorageStockData.splice(index, 1);
                    setFeedStorageData(newStorageStockData);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('FeedStorageStockData not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                setCurrentFeedStorage({})
            });

        }
    };

    const removeErrorMessage = value => {
        setErrorMessage('');
    }

    const onFeedStorageDataDelete = (shedFeedStorageData) => {
        setCurrentFeedStorage(shedFeedStorageData)
        setShowDeleteConfirm(true);
    };

    const onFeedStorageDatasSave = (shedFeedStorageData, setpopuploading) => {
        showMessage('');
        const response = api.post('/move-to-shed-feed-storage', shedFeedStorageData);

        response.then(res => {
            let { name: farmName } = farms.find((el) => el.id === res.farmId)
            let { formulaName } = storageFormula.find((el) => el.id === res.formulaId)
            let { name: shedName } = Shed.find((el) => el.id === res.shedId)

            res.formulaName = formulaName
            res.farmName = farmName
            res.shedName = shedName
            setFeedStorageData([...FeedStorageStockData, res]);
            setShowFeedStorageDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found') {
                    setErrorMessage('FeedStorageStockData not added');
                }
                else if (err.message === 'duplicate key') {
                    setErrorMessage("Duplicate entry. Medicine Stock already exists")
                } else if (err.message === 'no batch found') {
                    setErrorMessage(`No batch found for shed ${shedFeedStorageData.shedName} on ${moment(shedFeedStorageData.date).format("DD-MM-YYYY")}`)
                } else if (err.message === 'no stock') {
                    setErrorMessage('Material with available stock 0 cannot be added');
                }
                else {
                    setErrorMessage(err.message)
                }
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onFeedStorageDataEdit = async (shedFeedStorageData) => {
        try {
            setErrorMessage("");
    
            await getAllStorageFormula(shedFeedStorageData.id);
            const sheds = await getAllSheds(shedFeedStorageData.farmId);
    
            const tempCurrentFeedStorage = {
                ...shedFeedStorageData,
                formulaNameIdx: storageFormula?.findIndex((formula) => formula.id === shedFeedStorageData.formulaId),
                shedTypeIdx: sheds.findIndex((shed) => shed.id === shedFeedStorageData.shedId),
                farmIdx: farms.findIndex((farm) => farm.id === shedFeedStorageData.farmId),
            };
    
            setCurrentFeedStorage(tempCurrentFeedStorage);
            setEditMode(true);
            setShowFeedStorageDialog(true);
            setLoading(false)
        } catch (error) {
            setErrorMessage("An error occurred while editing feed storage data.");
            console.error(error);
            setLoading(false)
        }
    };

    const onShedFeedStroageUpdate = (shedFeedStorageData, setPopupLoading) => {
        const response = api.post(`/move-to-shed-feed-storage/${shedFeedStorageData.id}`, shedFeedStorageData);
        response.then((res) => {
            let index = FeedStorageStockData.findIndex((srcStorageData) => {
                return srcStorageData.id === shedFeedStorageData.id;
            });
            setFeedStorageData([...FeedStorageStockData.slice(0, index),
            { ...res },
            ...FeedStorageStockData.slice(index + 1)]);

            setShowFeedStorageDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'Not Updated') {
                    setErrorMessage('Shed Feed Storage not Updated');
                } else if (err.message === 'no stock') {
                    setErrorMessage('Formula with available stock 0 cannot be updated');
                }
                else {
                    setErrorMessage(err.message)
                }
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setPopupLoading(false)
            setLoading(false);
        })
    };

    const handleMoveToshedDateChange = async event => {
        console.log("ascoinacsbniajsciasc")
        try{
            await getAllStorageFormula(0,event.target.value)
        }catch(err){
            showError('Unknown Error')
        }
    }
    //Get all FeedStorageStockData API Call
    const getAllFeedStorageData = () => {
        setShowFeedStorageDialog(false);
        showError('');
        showMessage('');

        const response = api.get(`/move-to-shed-feed-storage`,{});

        response.then(res => {
            setFeedStorageData([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Feed storage Stock Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

    const onload = () => {
        setLoading(true);
        Promise.all([getAllFeedStorageData(),getAllStorageFormula(),getAllFarms()]).finally(() => {
            setLoading(false);
        });
    }
    useEffect(onload, []);
    let formulaColumnDefaults = { title: 'Formula Name', field: 'formulaName', defaultSort: 'asc' }
    let formulaColumn = {
        render: rowData => <Mobile data={rowData}
            onEdit={() => { onFeedStorageDataEdit(rowData) }}
            onDelete={() => { onFeedStorageDataDelete(rowData) }}
        />
    }
    let actions = [];
    if (isDesktop) {
        formulaColumn = formulaColumnDefaults
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Feed storage',
                onClick: (event, rowData) => onFeedStorageDataEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Feed storage',
                onClick: (event, rowData) => onFeedStorageDataDelete(rowData)
            }
        ]
    }


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Move to Shed
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewFeedStorageDatas}>Add Move to Shed</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'No', field: 'id', hidden: !isDesktop },
                {
                    title: 'Date', field: 'date', hidden: !isDesktop,
                    render: rowData => {
                        return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                    }
                },

                formulaColumn,
                { title: 'Farm Name', field: 'farmName', hidden: !isDesktop, editable: 'never' },
                { title: 'Shed Name', field: 'shedName', hidden: !isDesktop, editable: 'never' },
                { title: 'Quantity', field: 'quantity', hidden: !isDesktop, editable: 'never' },
                {
                    title: 'Cost', field: 'cost', hidden: !isDesktop,
                    render: rowData => rowData.cost.toFixed(2)
                },
            ]}
            data={FeedStorageStockData}
            title='Feed storage Stock Data Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,

                toolbar: true
            }}
            actions={actions}
        />
        <FeedStorageStock errorMessage={errorMessage} showDialog={showFeedStorageDialog} handleClose={handleMedicineStockClose} onSave={onFeedStorageDatasSave} onEdit={onShedFeedStroageUpdate}
            editMode={editMode} data={currentFeedStrage} deleteErrorMessage={removeErrorMessage} match={match} shed={Shed} 
            handleShedDateChange={handleMoveToshedDateChange}
           storageFormulaData={storageFormula}
            farms={farms} onHandleFarmChange={onHandleFarmChange}
        />
        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onFeedStorageDeleteConfirm}
            title='Delete Shed Feed Storage ?'
            subText='This action will delete the Shed Feed Storage Details. Please confirm.' />
    </div>
}


export default FeedStorageView;