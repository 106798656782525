import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button"; 
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider,DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Icon from '@material-ui/core/Icon';
import { MaterialTable } from '../../../components';
import Payment from './payment';
import api from '../../../common/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import {populateTaxData} from '../../../common/functions';
import moment from "moment";

var schema = {
    paymentDate: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    payMonth: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    taxType: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    categoryType: {
        presence: {allowEmpty: false, message: 'is required'}
    }
};

const useStyles = makeStyles(theme => ({
    marginRight: {
        marginRight: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
    },
    addButton: {
        textAlign: "right",
        marginBottom: theme.spacing(2),
        minWidth: 160,
    },
    formControl: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth: 150,
    },
    datePicker: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth:160
    },
    grid: {
        marginLeft: theme.spacing(0),
        margin: theme.spacing(2),
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '99'
    },
}));

const TcsTdsPayment = ({isLine, showTcsTdsDialog, handleTcsTdsClose, editMode, currentData, onSave, onUpdate, currentTaxPayments, updatePayments, showError}) => {

    const maxDate = moment() // Current Year
    const minDate = moment().add(-1, "month") // Backward 2 months from current year
    const classes = useStyles();

    const [formState,hasError,handleChange,updateData,setFormState] = useFromValidator(schema);

    // const [paymentDate, setPaymentDate] = useState(undefined)
    const [payMonth, setPayMonth] = useState(undefined)
    const [taxType, setTaxType] = useState(undefined)
    const [categoryType, setCategoryType] = useState(undefined) // type - either customer or vendor
    const [payments, setPayments] = useState(undefined)
    const [customerVendorList, setCustomerVendorList] = useState(undefined)

    const [showPaymentDialog, setShowPaymentDialog] = useState(false)
    const [editPaymentMode, setEditPaymentMode] = useState(false)

    const [currentPaymentData, setCurrentPaymentData] = useState(undefined)
    const [invoiceReqInput, setInvoiceReqInput] = useState(undefined)
    
    const TDS = "TDS";
    const TCS = "TCS";
    const [popupLoading, setPopupLoading] = useState(false)
    const [taxProfiles, setTaxProfiles] = useState(undefined)
    
    const resetBeforeEdit = () => {
        if (showTcsTdsDialog) {
            populateTaxData(setTaxProfiles,[TDS,TCS],true)
        }
        if (showTcsTdsDialog && !editMode) {
            // setPaymentDate(undefined)
            setPayMonth(undefined)
            setTaxType(undefined)
            setCategoryType(undefined)
            setCustomerVendorList(undefined)
            setPayments(undefined)
        } else if (showTcsTdsDialog && editMode) {
            // categoryType = 1 -> Customer, 2 -> Vendor
            setPayMonth(currentData.payMonth)
            setTaxType(currentData.taxType)
            setCategoryType(currentData.categoryType)
            if (currentData.categoryType === 1) {
                getAllCustomers()
            } else {
                getAllVendors()
            }

            let req = {
                payMonth: currentData.payMonth,
                taxType: currentData.taxType,
                type: currentData.categoryType,
            }
            setInvoiceReqInput(req)
            setPayments(currentTaxPayments)
        }

        updateData(currentData, editMode)
    }

    useEffect(resetBeforeEdit, [showTcsTdsDialog])

    const handlePaymentAdd = (event) => {
        setEditPaymentMode(false)
        setInvoiceReqInput({
            ...invoiceReqInput,
        })
        setCurrentPaymentData(undefined)
        setShowPaymentDialog(true)
    }

    const handlePaymentClose = () => {
        setShowPaymentDialog(false)
    }

    const handleTypeChange = (e) => {
        let typeValue = e.target.value
        setCategoryType(typeValue)
        getAllpendingTaxPayments(taxType,typeValue)
    }

    const handleMonthChange = (e) => {
        let month = e.target.value
        setPayMonth(month)
    }

    const handleTaxTypeChange = (e) => {
        let tax = e.target.value
        let typeValue
        setTaxType(tax)
        // make default category type based on tax type
        if (tax === 0) {
            //TDS
            typeValue = 1 //customer
            setCategoryType(typeValue) 
            setFormState({
                ...formState,
                values : {
                    ...formState.values,
                    categoryType:typeValue,
                    taxType:tax
                }
            })
            getAllCustomers()
        } else {
            //TCS
            typeValue = 2 //Vendor
            setCategoryType(typeValue) 
            setFormState({
                ...formState,
                values : {
                    ...formState.values,
                    categoryType:typeValue,
                    taxType:tax
                }
            })
            getAllVendors()
        }
        getAllpendingTaxPayments(tax,typeValue)
    }

    //Get all customers API Call
    const getAllCustomers = () => {
        showError('')
        let apiRoute = isLine ? "get-line-customers" : "customers-list"
        const response = api.get(apiRoute);

        response.then(res => {
            setCustomerVendorList([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                showError('No Customers Found');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
    }

    //Get all pending Tax API Call
    const getAllpendingTaxPayments = (taxtype,typeValue) => {
        showError('')
        const payload = {
            payMonth:payMonth,
            taxType:taxtype,
            isLine,
            type:typeValue
        };

        const response = api.post("/pending-tax-amount",payload);

        response.then(res => {
            let tempPayments = [...res.pendingTaxAmounts]
            tempPayments.forEach((payment) => {
                //AutoFill amount
                payment.amount = payment.pendingAmount
            })
            setPayments([...res.pendingTaxAmounts]);
        }).catch(err => {
            setPayments([]);
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                showError('No Data Found');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
    }
    
    //Get all vendors API Call
    const getAllVendors = () => {
        let apiRoute = isLine ? "get-line-vendors" : "vendors-list"
        const response = api.get(apiRoute);

        response.then(res => {
            setCustomerVendorList([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
     };

     function onPaymentEdit(rowData) {
         setEditPaymentMode(true)
         let req = {
            payMonth: payMonth,
            taxType: taxType,
            type: categoryType,
            categoryID: rowData.customerID === 0 ? rowData.vendorID : rowData.customerID
        }
         setInvoiceReqInput(req)
         setCurrentPaymentData({
             ...rowData
         })
         setShowPaymentDialog(true)
     }

     function onPaymentDelete(rowData) {

        if (editMode) {
            setPopupLoading(true)
            showError('')
            const response = api.post(`/delete-monthly-tcs-tds-detail/${rowData.id}`)

            response.then(res => {
                if (res) {
                    let index = payments.findIndex((ele) => {
                        return ele.id === rowData.id;
                    });
                    let newPayments = [...payments];
                    newPayments.splice(index, 1);
                    setPayments(newPayments)
                    updatePayments(currentData.id, newPayments)
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Unable to delete');
                    else
                        console.log(err.message)
                } else {
                    console.log('Unknown Error')
                }
            }).finally(() => {
                setPopupLoading(false)
            })
            return
        }
         let index = payments.findIndex((ele) => {
            return ele.id === rowData.id;
        });
        let newPayments = [...payments];
        newPayments.splice(index, 1);
        setPayments(newPayments)
     }

    let actions = [
        {
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit sale Details',
            onClick: (event, rowData) => {onPaymentEdit(rowData)}
        },
        {
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete sale Details',
            onClick: (event, rowData) => {onPaymentDelete(rowData)}
        }
    ]

    function onSavePayment(value) {

        let newPay = {
            id: -1,
            customerID: categoryType === 1 ? value.customerVendor.id : null,
            vendorID: categoryType === 2 ? value.customerVendor.id : null,
            companyName: value.customerVendor.name || value.customerVendor.companyName,
            amount: value.amount
        }
        if (payments === undefined) {
            setPayments([newPay])
        } else {
            setPayments([...payments, newPay])
        }
    }

    function onEditPayment(value, id) {
        let index = payments.findIndex(ele => {
            return ele.id === id;
        });
        
        let updatedPayment = {
            id,
            customerID: categoryType === 1 ? value.customerVendor.id : null,
            vendorID: categoryType === 2 ? value.customerVendor.id : null,
            companyName: value.customerVendor.name || value.customerVendor.companyName,
            amount: value.amount
        }
        let tempPayments = [...payments.slice(0, index),
            { ...updatedPayment },
            ...payments.slice(index + 1)]
            
        setPayments(tempPayments)
    }

    const handleSave = () => {
        let tcsTdsDetails = []
        payments.forEach(payment => {
            tcsTdsDetails.push({
                id: payment.id,
                customerID: categoryType === 1 ? payment.customerID: null,
                vendorID: categoryType === 2 ? payment.vendorID: null,
                companyName: payment.companyName,
                amount: payment.amount
            })
        })
        let tax = {
            ...formState.values,
            isLine,
            monthlyTcsTdsDetails: tcsTdsDetails
        }
        if (editMode) {
            onUpdate(tax, setPopupLoading)
        } else {
            onSave(tax, setPopupLoading)
        }
        
    }

    return <Dialog maxWidth='md' disableBackdropClick open={showTcsTdsDialog} onClose={handleTcsTdsClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog- title">Add  Payment
    <IconButton aria-label="close" className={classes.closeButton} onClick={handleTcsTdsClose}>
      <CloseIcon />
    </IconButton>
    </DialogTitle>
        <DialogContent>
        {popupLoading ?
            <div className={classes.progressContainer}>
                <CircularProgress  className={classes.progressBar}/>
            </div>
        : null}
        
        <MuiPickersUtilsProvider className={classes.datePicker} utils={DateFnsUtils}>
            <DatePicker
                disabled={popupLoading}
                className={classes.datePicker}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Payment Date"
                format="dd/MM/yyyy"
                value={formState.values.paymentDate || null}
                onChange={data => handleChange({target: {name: 'paymentDate', value: data}})}
                animateYearScrolling
                name = "paymentDate"
                style={{backgroundColor:"#ffffff", width:"fit-content" }}
            />
            <DatePicker
                error={hasError('payMonth')}
                disabled={popupLoading || editMode}
                className={classes.datePicker}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Till Month"
                format="MM/yyyy"
                views={["year", "month"]}
                openTo="month"
                minDate={minDate}
                maxDate={maxDate}
                value={formState.values.payMonth || null}
                onChange={data => handleChange({target: {name: 'payMonth', value: data}}, handleMonthChange)}
                animateYearScrolling
                name = "payMonth"
                style={{backgroundColor:"#ffffff"}}
            />
            </MuiPickersUtilsProvider>

            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">Tax Type</InputLabel>
              <Select
                disabled={popupLoading || editMode}
                id="taxType"
                value={
                  formState.values.taxType !== undefined
                    ? formState.values.taxType
                    : ""
                }
                onChange={event => handleChange(event, handleTaxTypeChange)}
                name="taxType"
              >
                {taxProfiles !== undefined && taxProfiles !== null
                  ? taxProfiles?.map((tax, index) => (
                      <MenuItem key={tax.id} value={index}>
                        {tax.taxType}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">Customer/Vendor</InputLabel>
              <Select
                disabled={popupLoading  || editMode}
                error={hasError('categoryType')}
                id="categoryType"
                name="categoryType"
                value={
                  formState.values.categoryType !== undefined
                    ? formState.values.categoryType : '' 
                }
                onChange={event => handleChange(event, handleTypeChange)}
              >
                <MenuItem key={1} value={1}>
                    Customer
                </MenuItem>
                <MenuItem key={2} value={2}>
                    Vendor
                </MenuItem>
              </Select>
            </FormControl>

            {
                showPaymentDialog ? //Hide Add payment dialog and actions
                <div className={classes.addButton} >
                <Button variant="contained" color="primary" onClick={handlePaymentAdd} 
                 action={actions}
                 >Add Payment</Button>
               </div> : ''
            }

            <MaterialTable
                columns={[
                    { title: 'id', field: 'id', hidden: true },
                    {
                        title: 'Name', field: 'companyName', editable: 'never',
                        render: (rowData) => {
                            console.log("rowData", rowData) 
                            return <span>{rowData.companyName}</span> 
                            }
                    },
                    {
                        title: 'Amount', field: 'amount',
                        render: (rowData) => { return <span>{rowData.amount}</span> }
                    },
                ]}
                data={payments}
                title="Add Payments"
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    paging: false
                }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => { 
                        let index;
                        if (categoryType ===  1) {//customer
                            index = payments.findIndex(ele => {
                                return ele.customerID === oldData.customerID;
                            });
                        }else {
                            index = payments.findIndex(ele => {
                                return ele.vendorID === oldData.vendorID;
                            });
                        }
                        let updatedPayment = {
                            id:newData.id,
                            customerID: categoryType === 1 ? newData.customerID : null,
                            vendorID: categoryType === 2 ? newData.vendorID : null,
                            companyName: newData.companyName,
                            amount: parseFloat(newData.amount)
                        }
                        let tempPayments = [...payments.slice(0, index),
                            { ...updatedPayment },
                            ...payments.slice(index + 1)]
                            
                        setPayments(tempPayments)
                        resolve();
                    })
                }}
                //style={{boxShadow: 'unset'}}
                //actions={actions}
            />

            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid || payments === undefined || !payments.length || popupLoading }
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSave}
                >
                    Save
                </Button>
            </DialogActions>

                {/* Payment Dialog */}
                <Payment 
                isLine={isLine}
                editMode={editPaymentMode}
                showError={showError}
                data={currentPaymentData}
                onSave={onSavePayment}
                onEdit={onEditPayment}
                month={payMonth}
                taxType={taxType}
                type={categoryType}
                invoiceReqInput={invoiceReqInput}
                customerVendorList={customerVendorList} showPaymentDialog={showPaymentDialog} handlePaymentClose={handlePaymentClose} />

        </DialogContent>
    </Dialog>
}

export default TcsTdsPayment