import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import moment from 'moment';
import BankTransaction from "./components/BankTransaction";
import Icon from '@material-ui/core/Icon';
import GetAppIcon from '@material-ui/icons/GetApp';
import {useGlobalState} from '../../state';


const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },

    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        minWidth: 160,
    },

}));


const BankTransactionView = ({ showError, showMessage, history }) => {

    const [bankTransaction, setBankTransaction] = useState([]);
    const [transactionTypes, setTransactionTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showBankTransactionDialog, setBankTransactionDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [banks, setBanks] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')
    const [bankTransactionData,setBankTransactionData] = useState({})
    const [transactionEditMode,setTransactionEditMode] = useState(false)


    const theme = useTheme();

    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });
    const csvHeader = ["Date","Transaction Type", "Bank", "Amount(in Rs.)"]


    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    function onNewbankTransaction(event) {
        setTransactionEditMode(false)
        event.preventDefault();
        setBankTransactionDialog(true);
    }

    function handleBankTransactionClose() {
        setBankTransactionData({})
        setBankTransactionDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
        setErrorMessage(null) ;
    }

    const onBankTransactionDeleteConform = () => {
        if (bankTransactionData && bankTransactionData.id) {
            setShowDeleteConfirm(false);
            showError('');
            const response = api.delete(`/delete-bank-transaction/${bankTransactionData.id}`);

            response.then(res => {
                if (res) {
                    let index = bankTransaction.findIndex((transaction) => {
                        return transaction.id === bankTransactionData.id;
                    });
                    let newbankTransaction = [...bankTransaction];
                    newbankTransaction.splice(index, 1);
                    setBankTransaction(newbankTransaction);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Bank Transaction not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                setBankTransactionData({})
            });

        }
    };

    const onDelete = (transaction) => {
        setBankTransactionData(transaction)
        setShowDeleteConfirm(true);
    };

    const onOtherTransactionSave = (transaction, setPopupLoading) => {
        showMessage('');
        showError('');
        const response = api.post('/bank-transaction', {
            ...transaction,
        });

        response.then(res => {

            setBankTransaction([...bankTransaction, res]);
            setBankTransactionDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Bank Transaction not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };


    const onTransactionEdit = (transaction) => {
       let  currentBankTransaction = {
            ...transaction,
            bankIndex: banks.findIndex((bank) => { return bank.id === transaction.bankId; }),
            transactionTypeIndex: transactionTypes.findIndex((types) => { return types.id === transaction.transactionTypeId; }),
            amount: transaction.amount
        };
        setBankTransactionData(currentBankTransaction)
        setTransactionEditMode(true)
        setBankTransactionDialog(true);
    };

    const onBankTransactionUpdate = (bankTransactionData, setPopupLoading) => {

        bankTransactionData.amount = parseInt(bankTransactionData.amount)
        const response = api.post(`update-bank-transaction/${bankTransactionData.id}`, {
            ...bankTransactionData,

        });
        response.then(() => {
            let index = bankTransaction.findIndex((transaction) => {
                return transaction.id === bankTransactionData.id;
            });

            setBankTransaction([...bankTransaction.slice(0, index),
            { ...bankTransactionData },
            ...bankTransaction.slice(index + 1)]);
            setBankTransactionDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('Bank Transaction not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };

    //Get all bankTransaction API Call
    const getAllbankTransaction = () => {
        setBankTransactionDialog(false);
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.post('/get-bank-transaction');

        response.then(res => {
            setBankTransaction([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No bankTransaction Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }



    //Get all Transaction API Call
    const getAllTransactionTypes = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('/bank-transaction-type');

        response.then(res => {
            setTransactionTypes([...res]);

        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
                    showError('No Transaction Types Found');
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    //Generate csv file
    const generateExcel = function (header, data) {
        let csvData = '';
        header.forEach((column, index) => {
            if (index === 0)
                csvData = csvData + column
            else
                csvData = csvData + ',' + column
        });

        csvData = csvData + '\r\n';


        data.map((row, index) => {
            let tempDate;
            let column = []
            tempDate = moment(row.date).format("DD-MM-YYYY")

            column.push(tempDate, row.transactionTypeName,row.bankName, row.amount)

            column.forEach((column, index) => {
                if (index === 0)
                    csvData = csvData + column

                else
                    csvData = csvData + ',' + column
            });
            csvData = csvData + '\r\n';

            return "";
        });

        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
        element.setAttribute('download', 'BankTransaction.csv');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);


    }

    //Get all Banks API Call
    const getAllBanks = () => {
        showError('');
        showMessage('');

        const response = api.get('bank');

        response.then(res => {
            setBanks([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }


    const onload = () => {
        getAllTransactionTypes()
        getAllbankTransaction()
        getAllBanks()

    }
    useEffect(onload, []);


    let bankTransactionColumn = {
        title: 'Bank Transaction', field: 'date', editable: 'never', defaultSort: 'asc',
        render: rowData => <Mobile data={rowData}
            onEdit={() => {
                onTransactionEdit(rowData)
            }}
            onDelete={() => {
                onDelete(rowData)
            }}
        />
    };



    let actions = [];
    if (isDesktop) {
        bankTransactionColumn = {
            title: 'Date', field: 'date', type: 'datetime',
            editable: 'never',
            hidden: !isDesktop,
            render: rowData => {
                return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
            }, defaultSort: 'asc'
        };
        actions = [
            rowData => ({
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Bank Transaction Details',
                onClick: () => onTransactionEdit(rowData),
                hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
            }),
            rowData => ({
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Bank Transaction Details',
                onClick: (event, rowData) => onDelete(rowData),
                hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
            }),
        ]
    }


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                   Bank Transaction
                </Typography>
            </Grid>
            <Grid container
                spacing={3}
                justify="flex-end"
                alignItems="center"
            >
                <Grid item>
                    <Button variant="contained" color="primary" onClick={onNewbankTransaction}>Add Bank Transaction</Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" color="primary"
                        disabled={bankTransaction.length < 1}
                        onClick={() => generateExcel(csvHeader, bankTransaction)}
                        endIcon={<GetAppIcon />}>Download Excel</Button>
                </Grid>
            </Grid>



        </Grid>

        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'ID', field: 'id', hidden: isDesktop ? false : true },
                bankTransactionColumn,
                { title: 'Transaction Type', field: 'transactionTypeName', hidden: !isDesktop, editable: 'never' },
                { title: 'Bank', field: 'bankName', hidden: !isDesktop, editable: 'never' },
                { title: 'Amount (in Rs.)', field: 'amount', hidden: !isDesktop, editable: 'never',
                    render: rowData => {
                        return <span>{(rowData.amount).toLocaleString(user.locales,amountObj)}</span>
                    } 
                }

            ]}
            data={bankTransaction}
            title='Bank Transaction Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,

                toolbar: true
            }}
            actions={actions}
        />
        <BankTransaction deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showBankTransactionDialog} handleClose={handleBankTransactionClose} onSave={onOtherTransactionSave} onEdit={onBankTransactionUpdate}
            editMode={transactionEditMode} data={bankTransactionData}  banks={banks}  transactionTypes={transactionTypes} />

        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onBankTransactionDeleteConform}
            title='Delete Bank Transaction?'
            subText='This action will delete the Bank Transaction Details. Please confirm.' />
    </div>
}

export default BankTransactionView;