import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';

const useCardStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    cardActionsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    cardAction:{
        margin:theme.spacing(0),
        marginTop:theme.spacing(2),
        backgroundColor : '#ff1744',
        color : 'white'
    },
    grid :{
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2)
    }
}));

const Mobile = ({data,onDelete,onEdit,openVacSchedule,openStdProduction}) => {
    const classes = useCardStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        onDelete();
    };

    const handleEdit = () => {
        setAnchorEl(null);
        onEdit();


    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    return <Grid item>
        <Card className={classes.card}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                }
                title={`Primary Contact : ${data.primaryContact}`}
            />
            <CardContent>
                <Grid
                    container
                    justify='space-between'
                    alignItems='flex-end'
                >
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.isLineEnabled ? 
                                <CheckIcon style={{ color: green[600] }} /> :
                                ""
                            }
                        </Typography>
                        <Typography color="textSecondary">
                            Line
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.companyName}
                        </Typography>
                        <Typography color="textSecondary">
                            Company Name
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.type === 1 ? 'Customer' : 'Vendor'}
                        </Typography>
                        <Typography color="textSecondary">
                            Type
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.gstNumber}
                        </Typography>
                        <Typography color="textSecondary">
                            GST Number
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.phoneNumber}
                        </Typography>
                        <Typography color="textSecondary">
                            Phone Number
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.emailAddress}
                        </Typography>
                        <Typography color="textSecondary">
                          Email Address    
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.address}
                        </Typography>
                        <Typography color="textSecondary">
                            Address
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Menu
            id="customerVendorDetails-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
    </Grid>
};

export default Mobile;