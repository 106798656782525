import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from "../../../common/formValidator";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FormControl, FormControlLabel } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { MaterialTable } from "../../../components";
import { useGlobalState } from "../../../state";
import Chip from "@material-ui/core/Chip";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableBody";
import Checkbox from "@material-ui/core/Checkbox";
var schema = {
  date: {
    presence: { allowEmpty: false, message: "is required" },
  },
  lineNameIdx: {
    presence: { allowEmpty: false },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: -1,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.text.default,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    paddingBottom: theme.spacing(1.25),
    marginLeft: "20px",
  },
  textField: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(0),
  },
  noteTextField: {
    marginTop: theme.spacing(0),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  datePicker: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    minWidth: 160,
  },
  invoice: {
    float: "Left",
  },
  grid: {
    marginLeft: theme.spacing(0),
    margin: theme.spacing(2),
  },
  totalCell: {
    tableLayout: "fixed",
    paddingBottom: 0,
    paddingLeft: theme.spacing(0),
  },
  totalTable: {
    float: "right",
    width: theme.spacing(37.5),
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  progressBar: {
    margin: theme.spacing(2),
    position: "absolute",
    top: "40%",
    zIndex: "2",
  },
}));
const ExpensesPopup = ({
  deleteErrorMessage,
  errorMessage,
  data,
  banks,
  onSave,
  onEdit,
  handlePaymentClose,
  vendors,
  showPaymentDialog,
  editMode,
  lineData,
  expenseTypes,
}) => {
  const classes = useStyles();
  const [formState, hasError, handleChange, updateData, setFormState] =
    useFromValidator(schema);
  const [expenseTable, setExpenseTable] = useState([]);
  const [popupLoading, setPopupLoading] = useState(false);
  const [{ user }] = useGlobalState();
  const [deletedExpense, setDeletedExpense] = useState([]);
  const [includeFreight, setIncludeFreight] = useState(false);
  const [expensesTotalSum, setExpensesTotalSum] = useState(0.0);
  const [freightCost, setFreightCost] = useState(0);
  const [expenses, setExpenses] = useState(0.0);
  const amountObj = {
    style: "currency",
    currency: user.currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const resetBeforeEdit = () => {
    if (editMode) {
      setExpenseTable(
        data.lineSaleExpenseItems !== undefined &&
          data.lineSaleExpenseItems !== null
          ? data.lineSaleExpenseItems
          : []
      );
      calculateExpenseTotal(data);
    }
    if (showPaymentDialog) {
      updateData(data, editMode);
      setPopupLoading(false);

      if (editMode === false) {
        setExpenseTable([]);
        setIncludeFreight(false);
        setExpensesTotalSum(0.0);
        setExpenses(0.0);
        setFreightCost(0.0);
      }
    }
  };
  useEffect(resetBeforeEdit, [showPaymentDialog]);

  const onSubmit = () => {
    setPopupLoading(true);
    var errorElement = document.getElementById("error");
    if (errorElement) {
      errorElement.scrollIntoView();
    }
    let tempData = {
      ...formState.values,
      lineID: lineData[formState.values.lineNameIdx].id,
      lineName: lineData[formState.values.lineNameIdx].name,
      lineSaleExpenseItems: expenseTable,
      deletedLineSaleExpenseItems: deletedExpense,
      freightIncluded: includeFreight === true ? 1 : 0,
      openingKM: includeFreight === true ? formState.values.openingKM : 0,
      closingKM: includeFreight === true ? formState.values.closingKM : 0,
      costPerKM: includeFreight === true ? formState.values.costPerKM : 0,
    };
    if (editMode) {
      onEdit(tempData, setPopupLoading);
    } else {
      onSave(tempData, setPopupLoading);
    }
  };

  const calculateExpenseTotal = (data) => {
    if (editMode === true) {
      if (
        data.lineSaleExpenseItems !== undefined &&
        data.lineSaleExpenseItems !== null &&
        Array.isArray(data.lineSaleExpenseItems) &&
        data.lineSaleExpenseItems.length > 0
      ) {
        let expensetotal = 0;
        for (const el of data.lineSaleExpenseItems) {
          expensetotal += el.amount;
        }
        setExpenses(expensetotal);
        let freightCharge = (data.closingKM - data.openingKM) * data.costPerKM;
        if (data.freightIncluded === 1) {
          expensetotal += freightCharge;
          setIncludeFreight(true);
        } else {
          setIncludeFreight(false);
        }
        setFreightCost(freightCharge);
        setExpensesTotalSum(expensetotal);
      }
    }
  };

  function handleFreightCharge(e) {
    setIncludeFreight(e.target.checked);
    if (e.target.checked === true) {
      let freightFeildSchema = {
        openingKM: {
          type: "number",
          presence: { allowEmpty: false },
          numericality: {
            greaterThanOrEqualTo: 0,
          },
        },
        closingKM: {
          type: "number",
          presence: { allowEmpty: false },
          numericality: {
            greaterThanOrEqualTo: 0,
          },
        },
        costPerKM: {
          type: "number",
          presence: { allowEmpty: false },
          numericality: {
            greaterThanOrEqualTo: 0,
          },
        },
      };

      Object.assign(schema, freightFeildSchema);
      setExpensesTotalSum((prev) => prev + freightCost);
    } else {
      delete schema.openingKM;
      delete schema.closingKM;
      delete schema.costPerKM;

      setFreightCost(0.0);
      setFormState({
        ...formState,
        values: {
          ...formState.values,
          openingKM: "",
          closingKM: "",
          costPerKM: "",
        },
      });

      setExpensesTotalSum((prev) => prev - freightCost);
    }
  }

  useEffect(() => {
    if (
      Object.keys(formState.values).length > 0 &&
      typeof formState.values.closingKM === "number" &&
      typeof formState.values.openingKM === "number" &&
      typeof formState.values.costPerKM === "number"
    ) {
      let freightCharge =
        (formState.values.closingKM - formState.values.openingKM) *
        formState.values.costPerKM;

      let prevFreightcost = freightCost;
      setFreightCost(freightCharge);
      if (includeFreight) {
        let freightCostDifference = freightCharge - prevFreightcost;
        let prevExpenseTotal = expensesTotalSum + freightCostDifference;
        setExpensesTotalSum(prevExpenseTotal);
      }
    }
    // eslint-disable-next-line
  }, [
    formState.values.costPerKM,
    formState.values.openingKM,
    formState.values.closingKM,
  ]);

  return (
    <Dialog
      maxWidth="md"
      disableBackdropClick
      open={showPaymentDialog}
      onClose={handlePaymentClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog- title">
        Add Payment
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handlePaymentClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {popupLoading ? (
          <div>
            <div id="error">
              {errorMessage ? (
                <Chip
                  onDelete={deleteErrorMessage}
                  color="primary"
                  label={errorMessage}
                />
              ) : (
                ""
              )}
            </div>
            <br />
            <MuiPickersUtilsProvider
              class={classes.datePicker}
              utils={DateFnsUtils}
            >
              <DatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Date"
                format="dd/MM/yyyy"
                value={formState.values.date || null}
                onChange={(data) => {
                  handleChange({ target: { name: "date", value: data } });
                }}
                animateYearScrolling
                name="date"
                style={{ backgroundColor: "#ffffff" }}
              />
            </MuiPickersUtilsProvider>

            <FormControl className={classes.formControl}>
              <InputLabel id="lineName-select-outlined-label">
                Line Name
              </InputLabel>
              <Select
                disabled
                id="lineName-select"
                value={
                  formState.values.lineNameIdx !== undefined
                    ? formState.values.lineNameIdx
                    : ""
                }
                onChange={handleChange}
                name="lineNameIdx"
              >
                {lineData !== undefined && lineData !== null
                  ? lineData.map((lineData, index) => (
                      <MenuItem key={lineData.id} value={index}>
                        {lineData.name}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>
            {includeFreight === true ? (
              <>
                <FormControl className={classes.formControl}>
                  <TextField
                    disabled
                    className={classes.textField}
                    error={hasError("openingKM")}
                    helperText={
                      hasError("openingKM")
                        ? formState.errors.openingKM[0]
                        : null
                    }
                    label="Opening KM"
                    name="openingKM"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.openingKM || ""}
                    variant="outlined"
                    style={{ backgroundColor: "#ffffff", marginLeft: "-23px" }}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <TextField
                    disabled
                    className={classes.textField}
                    error={hasError("closingKM")}
                    helperText={
                      hasError("closingKM")
                        ? formState.errors.closingKM[0]
                        : null
                    }
                    label="Closing KM"
                    name="closingKM"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.closingKM || ""}
                    variant="outlined"
                    style={{ backgroundColor: "#ffffff", marginLeft: "-21px" }}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <TextField
                    disabled
                    className={classes.textField}
                    error={hasError("costPerKM")}
                    helperText={
                      hasError("costPerKM")
                        ? formState.errors.costPerKM[0]
                        : null
                    }
                    label="Cost/KM"
                    name="costPerKM"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.costPerKM || ""}
                    variant="outlined"
                    style={{ backgroundColor: "#ffffff", marginLeft: "-15px" }}
                  />
                </FormControl>
              </>
            ) : (
              ""
            )}

            <MaterialTable
              columns={[
                {
                  title: "Expense Type",
                  field: "expenseType",
                  editComponent: (props) => {
                    let index = expenseTypes.findIndex(
                      (el) => el.id === props.rowData.lineSaleExpenseTypeID
                    );
                    return (
                      <Select
                        id="expenseType-select"
                        value={props.value === undefined ? index : props.value}
                        required={true}
                        onChange={(e) => {
                          props.onChange(e.target.value);
                        }}
                      >
                        {expenseTypes.map((el, index) => {
                          return (
                            <MenuItem key={el.id} value={index}>
                              {el.expenseTypeName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    );
                  },
                  render: (rowData) => {
                    let index = expenseTypes.findIndex((el) => {
                      return el.id === rowData.lineSaleExpenseTypeID;
                    });
                    return <span>{expenseTypes[index].expenseTypeName}</span>;
                  },
                  validate: function (rowData) {
                    if (
                      rowData.expenseType !== undefined ||
                      rowData.lineSaleExpenseTypeID !== undefined
                    ) {
                      let data =
                        rowData.lineSaleExpenseTypeID || rowData.expenseType;
                      if (
                        rowData.expenseType === 0 &&
                        expenseTypes[rowData.expenseType] !== undefined &&
                        expenseTypes[rowData.expenseType] !== null &&
                        expenseTypes[rowData.expenseType].id !== null &&
                        expenseTypes[rowData.expenseType].id !== undefined
                      ) {
                        return { isValid: true, helperText: "" };
                      }
                      return Boolean(data)
                        ? { isValid: true, helperText: "" }
                        : {
                            isValid: false,
                            helperText: "expense type required",
                          };
                    }
                  },
                },
                {
                  title: "Payment",
                  field: "amount",
                  type: "numeric",
                  render: function (rowData) {
                    return (
                      <span>
                        {rowData.amount.toLocaleString(user.locales, amountObj)}
                      </span>
                    );
                  },
                  validate: function (rowData) {
                    if (rowData.amount !== undefined) {
                      return Boolean(rowData.amount)
                        ? { isValid: true, helperText: "" }
                        : { isValid: false, helperText: "amount required" };
                    }
                  },
                },
              ]}
              data={expenseTable || []}
              options={{
                actionsColumnIndex: -1,
                search: false,
                pageSize: 5,
                toolbar: true,
              }}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    if (newData.amount === 0) {
                      reject();
                    }
                    let obj = {
                      lineSaleExpenseTypeID:
                        expenseTypes[newData.expenseType].id,
                      amount: newData.amount,
                    };
                    setExpensesTotalSum((prev) => prev + newData.amount);
                    setExpenses((prev) => prev + newData.amount);
                    setExpenseTable([...expenseTable, obj]);
                    resolve();
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    let amountDifference = newData.amount - oldData.amount;
                    setExpensesTotalSum((prev) => prev + amountDifference);
                    setExpenses((prev) => prev + amountDifference);
                    let newExpensetypeID =
                      newData.expenseType !== undefined &&
                      newData.expenseType !== null
                        ? expenseTypes[newData.expenseType].id
                        : oldData.expenseTypeID;
                    let obj = {
                      ...oldData,
                      lineSaleExpenseTypeID: newExpensetypeID,
                      amount: newData.amount,
                    };
                    let index = oldData.tableData.id;
                    setExpenseTable([
                      ...expenseTable.slice(0, index),
                      { ...obj },
                      ...expenseTable.slice(index + 1),
                    ]);
                    resolve();
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setExpensesTotalSum((prev) => prev - oldData.amount);
                    setExpenses((prev) => prev - oldData.amount);
                    let index = oldData.tableData.id;
                    setExpenseTable([
                      ...expenseTable.slice(0, index),
                      ...expenseTable.slice(index + 1),
                    ]);
                    if (oldData.id !== undefined && oldData.id !== null) {
                      setDeletedExpense([...deletedExpense, { ...oldData }]);
                    } else {
                      setDeletedExpense([]);
                    }
                    resolve();
                  }),
              }}
              title="Expenses"
            />

            <Table className={classes.totalTable}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.totalCell}>
                    Expense Total
                  </TableCell>
                  <TableCell className={classes.totalCell}>
                    {expenses.toLocaleString(user.locales, amountObj)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.totalCell}>
                    Freight Total
                  </TableCell>
                  <TableCell className={classes.totalCell}>
                    {freightCost.toLocaleString(user.locales, amountObj)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.totalCell}>Total</TableCell>
                  <TableCell className={classes.totalCell}>
                    {expensesTotalSum.toLocaleString(user.locales, amountObj)}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </div>
        ) : (
          <div>
            <div id="error">
              {errorMessage ? (
                <Chip
                  onDelete={deleteErrorMessage}
                  color="primary"
                  label={errorMessage}
                />
              ) : (
                ""
              )}
            </div>
            <br />
            <MuiPickersUtilsProvider
              class={classes.datePicker}
              utils={DateFnsUtils}
            >
              <DatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Date"
                format="dd/MM/yyyy"
                value={formState.values.date || null}
                onChange={(data) => {
                  handleChange({ target: { name: "date", value: data } });
                }}
                animateYearScrolling
                name="date"
                style={{ backgroundColor: "#ffffff" }}
              />
            </MuiPickersUtilsProvider>

            <FormControl className={classes.formControl}>
              <InputLabel id="lineName-select-outlined-label">
                Line Name
              </InputLabel>
              <Select
                id="lineName-select"
                value={
                  formState.values.lineNameIdx !== undefined
                    ? formState.values.lineNameIdx
                    : ""
                }
                onChange={handleChange}
                name="lineNameIdx"
              >
                {lineData !== undefined && lineData !== null
                  ? lineData.map((lineData, index) => (
                      <MenuItem key={lineData.id} value={index}>
                        {lineData.name}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeFreight}
                    onChange={handleFreightCharge}
                    name="includeFreightcharge"
                  />
                }
                label="Freight Charge"
                style={{ marginTop: "14px", marginLeft: "-15px" }}
              />
            </FormControl>
            {includeFreight === true ? (
              <>
                <FormControl className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    error={hasError("openingKM")}
                    helperText={
                      hasError("openingKM")
                        ? formState.errors.openingKM[0]
                        : null
                    }
                    label="Opening KM"
                    name="openingKM"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.openingKM || ""}
                    variant="outlined"
                    style={{ backgroundColor: "#ffffff", marginLeft: "-23px" }}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    error={hasError("closingKM")}
                    helperText={
                      hasError("closingKM")
                        ? formState.errors.closingKM[0]
                        : null
                    }
                    label="Closing KM"
                    name="closingKM"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.closingKM || ""}
                    variant="outlined"
                    style={{ backgroundColor: "#ffffff", marginLeft: "-21px" }}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    error={hasError("costPerKM")}
                    helperText={
                      hasError("costPerKM")
                        ? formState.errors.costPerKM[0]
                        : null
                    }
                    label="Cost/KM"
                    name="costPerKM"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.costPerKM || ""}
                    variant="outlined"
                    style={{ backgroundColor: "#ffffff", marginLeft: "-15px" }}
                  />
                </FormControl>
              </>
            ) : (
              ""
            )}

            <MaterialTable
              columns={[
                {
                  title: "Expense Type",
                  field: "expenseType",
                  editComponent: (props) => {
                    let index = expenseTypes?.findIndex(
                      (el) => el.id === props.rowData.lineSaleExpenseTypeID
                    );
                    return (
                      <Select
                        id="expenseType-select"
                        value={props.value === undefined ? index : props.value}
                        required={true}
                        onChange={(e) => {
                          props.onChange(e.target.value);
                        }}
                      >
                        {expenseTypes?.map((el, index) => {
                          return (
                            <MenuItem key={el.id} value={index}>
                              {el.expenseTypeName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    );
                  },
                  render: (rowData) => {
                    let index = expenseTypes.findIndex((el) => {
                      return el.id === rowData.lineSaleExpenseTypeID;
                    });
                    return <span>{expenseTypes[index].expenseTypeName}</span>;
                  },
                  validate: function (rowData) {
                    if (
                      rowData.expenseType !== undefined ||
                      rowData.lineSaleExpenseTypeID !== undefined
                    ) {
                      let data =
                        rowData.lineSaleExpenseTypeID || rowData.expenseType;
                      if (
                        rowData.expenseType === 0 &&
                        expenseTypes[rowData.expenseType] !== undefined &&
                        expenseTypes[rowData.expenseType] !== null &&
                        expenseTypes[rowData.expenseType].id !== null &&
                        expenseTypes[rowData.expenseType].id !== undefined
                      ) {
                        return { isValid: true, helperText: "" };
                      }
                      return Boolean(data)
                        ? { isValid: true, helperText: "" }
                        : {
                            isValid: false,
                            helperText: "expense type required",
                          };
                    }
                  },
                },
                {
                  title: "Payment",
                  field: "amount",
                  type: "numeric",
                  render: function (rowData) {
                    return (
                      <span>
                        {rowData.amount.toLocaleString(user.locales, amountObj)}
                      </span>
                    );
                  },
                  validate: function (rowData) {
                    if (rowData.amount !== undefined) {
                      return Boolean(rowData.amount)
                        ? { isValid: true, helperText: "" }
                        : { isValid: false, helperText: "amount required" };
                    }
                  },
                },
              ]}
              data={expenseTable || []}
              options={{
                actionsColumnIndex: -1,
                search: false,
                pageSize: 5,
                toolbar: true,
              }}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    if (newData.amount === 0) {
                      reject();
                    }
                    let obj = {
                      lineSaleExpenseTypeID:
                        expenseTypes[newData.expenseType].id,
                      amount: newData.amount,
                    };
                    setExpensesTotalSum((prev) => prev + newData.amount);
                    setExpenses((prev) => prev + newData.amount);
                    setExpenseTable([...expenseTable, obj]);
                    resolve();
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    let amountDifference = newData.amount - oldData.amount;
                    setExpensesTotalSum((prev) => prev + amountDifference);
                    setExpenses((prev) => prev + amountDifference);
                    let newExpensetypeID =
                      newData.expenseType !== undefined &&
                      newData.expenseType !== null
                        ? expenseTypes[newData.expenseType].id
                        : oldData.lineSaleExpenseTypeID;
                    let obj = {
                      ...oldData,
                      lineSaleExpenseTypeID: newExpensetypeID,
                      amount: newData.amount,
                    };
                    let index = oldData.tableData.id;
                    setExpenseTable([
                      ...expenseTable.slice(0, index),
                      { ...obj },
                      ...expenseTable.slice(index + 1),
                    ]);
                    resolve();
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setExpensesTotalSum((prev) => prev - oldData.amount);
                    setExpenses((prev) => prev - oldData.amount);
                    let index = oldData.tableData.id;
                    setExpenseTable([
                      ...expenseTable.slice(0, index),
                      ...expenseTable.slice(index + 1),
                    ]);
                    if (oldData.id !== undefined && oldData.id !== null) {
                      setDeletedExpense([...deletedExpense, { ...oldData }]);
                    } else {
                      setDeletedExpense([]);
                    }
                    resolve();
                  }),
              }}
              title="Expenses"
            />

            <Table className={classes.totalTable}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.totalCell}>
                    Expense Total
                  </TableCell>
                  <TableCell className={classes.totalCell}>
                    {expenses.toLocaleString(user.locales, amountObj)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.totalCell}>
                    Freight Total
                  </TableCell>
                  <TableCell className={classes.totalCell}>
                    {freightCost.toLocaleString(user.locales, amountObj)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.totalCell}>Total</TableCell>
                  <TableCell className={classes.totalCell}>
                    {expensesTotalSum.toLocaleString(user.locales, amountObj)}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.signInButton}
          color="primary"
          disabled={!formState.isValid}
          size="large"
          type="submit"
          variant="contained"
          onClick={onSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExpensesPopup;
