import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from "../../../common/formValidator";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import api from "../../../common/api";
import red from "@material-ui/core/colors/red";

const schema = {
  quantity: {
    presence: { allowEmpty: false, message: " is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
    },
  },
  medicineStockTypeIdx: {
    presence: { allowEmpty: false, message: " is required" },
  },
  materialTypeIdx: {
    presence: { allowEmpty: false, message: " is required" },
  },
  shedTypeIdx: {
    presence: { allowEmpty: false, message: " is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.text.default,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  progressBar: {
    margin: theme.spacing(2),
    position: "absolute",
    top: "40%",
    zIndex: "9999",
  },
  typo: {
    marginLeft: theme.spacing(0.25),
    marginTop: theme.spacing(-1),
    fontSize: "initial",
  },
  typoRed: {
    marginLeft: theme.spacing(0.25),
    marginTop: theme.spacing(-1),
    fontSize: "initial",
    color: red[900],
  },
}));

const MedicineStock = ({
  deleteErrorMessage,
  errorMessage,
  onEdit,
  data,
  onSave,
  handleClose,
  showDialog,
  editMode = false,
  match,
  shed,
  materialTypeList,
  materialList,
  handleMaterialType,
  materialType,
}) => {
  const classes = useStyles();
  const [formState, hasError, handleChange, updateData, setFormState] =
    useFromValidator(schema);
  const [popupLoading, setPopupLoading] = useState(false);
  const [availableStock, setavailableStock] = useState(0);
  const [availableStockErr, setaavailableStockErr] = useState(null);
  const [ShowQuantityMessage, setShowQuantityMessage] = useState(null);
  const [showNotification, setshowNotification] = useState(null);
  //Called if the Farm Property is Set for Editing
  const resetBeforeEdit = () => {
    setaavailableStockErr(null);
    if (editMode) {
      // console.log("data", data);
    }
    if (showDialog) {
      setShowQuantityMessage(null);
      setshowNotification(null);
      setPopupLoading(false);
      updateData(data, editMode);
      if (data !== undefined && data !== null && Object.keys(data).length > 0) {
        let event = {
          materialMasterId: data.materialMasterId,
          date: data.date,
        };
        handleMedicineChange(event);
      }
    }
  };
  useEffect(resetBeforeEdit, [showDialog]);

  const handlematerialChange = (event) => {
    if (
      formState.values !== undefined &&
      formState.values !== null &&
      formState.values.materialTypeIdx !== undefined &&
      formState.values.materialTypeIdx !== null &&
      formState.values.materialTypeIdx !== ""
    ) {
      setFormState({
        ...formState,
        values: {
          ...formState.values,
          medicineStockTypeIdx: "",
        },
      });
    }
    handleMaterialType(event)
  }
  const onSubmit = () => {
    setPopupLoading(true);
    const tempData = {
      ...formState.values,
      materialMasterId:
        materialList[materialType.type][formState.values.medicineStockTypeIdx]
          .materialMasterId,
      farmId: Number(match.params["farmID"]),
      materialName:
        materialList[materialType.type][formState.values.medicineStockTypeIdx]
          .materialName,
      shedId: shed[formState.values.shedTypeIdx].id,
      shedName: shed[formState.values.shedTypeIdx].name,
      materialType: materialType.type,
      materialTypeId: materialType.value,
    };
    //available stock validation
    if (tempData.quantity > availableStock) {
      setPopupLoading(false);
      return
    }
    var errorElement = document.getElementById("error");
    if (errorElement) {
      errorElement.scrollIntoView();
    }
    if (editMode) {
      onEdit(tempData, setPopupLoading);
      console.log("tempdata", tempData);
    } else onSave(tempData, setPopupLoading);
  };

  function handleMedicineChange(event) {
    let date;
    let materialMasterId;
    let MedicineStockID;
    if (editMode) {
      MedicineStockID = data?.id
    } else {
      MedicineStockID = 0;
    }
    setshowNotification((prevNotification) =>
      prevNotification === true ? null : prevNotification
    );
    if (
      materialType !== undefined &&
      materialType !== null &&
      materialType.type !== undefined &&
      materialType.type !== null
    ) {
      if (
        event.target &&
        event.target.name === "date" &&
        formState.values.medicineStockTypeIdx !== undefined &&
        formState.values.medicineStockTypeIdx !== null &&
        formState.values.medicineStockTypeIdx !== ""
      ) {
        date = event.target.value;
        materialMasterId =
          materialList[materialType.type][formState.values.medicineStockTypeIdx]
            .materialMasterId;
        getAvailableStock(date, materialMasterId,MedicineStockID);
      } else if (event.materialMasterId && event.date) {
        date = event.date;
        materialMasterId = event.materialMasterId;
        getAvailableStock(date, materialMasterId,MedicineStockID);
      } else if (event.target && event.target.name === "medicineStockTypeIdx") {
        date = formState.values.date;
        materialMasterId =
          materialList[materialType.type][event.target.value].materialMasterId;
        getAvailableStock(date, materialMasterId,MedicineStockID);
      }
    }
  }

  function getAvailableStock(date, materialMasterId,id) {
    let requestObj = {
      farmId: Number(match.params["farmID"]),
      materialMasterId: materialMasterId,
      date: date,
      editmode:editMode,
      id:id,
    };
    api
      .post("medicine-stock/available-stock", requestObj)
      .then((res) => {
        setavailableStock(res.toFixed(2));
        setShowQuantityMessage(
            `The quantity should be less than or equal to ${res.toFixed(2)}`
        );
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "no stock") {
          setShowQuantityMessage(null);
          setavailableStock(0);
        } else {
          setaavailableStockErr("unable to fetch available stock");
        }
      });
  }

  function deleteNotification() {
    setshowNotification(null);
  }

  useEffect(() => {
    if (
      availableStock > 0 &&
      (formState.values.quantity < 0 ||
        formState.values.quantity > availableStock)
    ) {
      setshowNotification(true);
    } else {
      setshowNotification(null);
    }
    if (availableStock === 0 && showNotification === true) {
      setshowNotification(null);
    }
    // eslint-disable-next-line
  }, [formState.values.quantity, availableStock]);

  return (
    <Dialog
      disableBackdropClick
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog- title">
        {editMode ? "Update" : "New"} Medicine Stock
      </DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {popupLoading ? (
          <div>
            <div className={classes.progressContainer}>
              <CircularProgress className={classes.progressBar} />
            </div>
            <br />
            <br />
            <InputLabel
              id="type-select-outlined-label"
              className={
                formState.values.quantity > availableStock
                  ? classes.typoRed
                  : classes.typo
              }
            >
              {ShowQuantityMessage !== null && ShowQuantityMessage !== undefined
                ? ShowQuantityMessage
                : ""}
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                disabled
                className={classes.textField}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Date"
                format="dd/MM/yyyy"
                value={formState.values.date || null}
                onChange={(data) => {
                  handleChange(
                    { target: { name: "date", value: data } },
                    handleMedicineChange
                  );
                }}
                animateYearScrolling
                name="date"
                style={{ backgroundColor: "#ffffff" }}
              />
            </MuiPickersUtilsProvider>
            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">
                Material Type
              </InputLabel>
              <Select
                disabled
                id="materialType"
                value={
                  formState.values.materialTypeIdx !== undefined
                    ? formState.values.materialTypeIdx
                    : ""
                }
                onChange={(e) => {
                  handleChange(e, handlematerialChange(e));
                  setavailableStock(null);
                }}
                name="materialTypeIdx"
              >
                {materialTypeList !== null &&
                materialTypeList !== undefined &&
                Array.isArray(materialTypeList)
                  ? materialTypeList.map((material, idx) => {
                      return (
                        <MenuItem key={material.key} value={idx}>
                          {material.type}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">
                Material Name
              </InputLabel>
              <Select
                disabled
                id="medicineType"
                value={
                  formState.values.medicineStockTypeIdx !== undefined
                    ? formState.values.medicineStockTypeIdx
                    : ""
                }
                onChange={handleChange}
                name="medicineStockTypeIdx"
              >
                {materialType !== undefined &&
                materialType !== null &&
                materialType.type &&
                materialList[materialType.type] !== undefined &&
                materialList[materialType.type] !== null
                  ? materialList[materialType.type].map((material, index) => (
                      <MenuItem key={material.id} value={index}>
                        {material.materialName}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">Sheds</InputLabel>
              <Select
                disabled
                id="shedType"
                value={
                  formState.values.shedTypeIdx !== undefined
                    ? formState.values.shedTypeIdx
                    : ""
                }
                onChange={handleChange}
                name="shedTypeIdx"
              >
                {shed !== undefined && shed !== null
                  ? shed.map((shed, index) => (
                      <MenuItem key={shed.id} value={index}>
                        {shed.name}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>
            <InputLabel id="type-select-outlined-label">
              {formState.values !== undefined &&
              formState.values !== null &&
              formState.values.medicineStockTypeIdx !== undefined &&
              formState.values.medicineStockTypeIdx !== null &&
              formState.values.medicineStockTypeIdx !== "" &&
              availableStock !== null
                ? `Available Stock: ${availableStock}`
                : availableStockErr}
            </InputLabel>

            <TextField
              disabled
              className={classes.textField}
              error={hasError("quantity")}
              fullWidth
              helperText={
                hasError("quantity") ? formState.errors.quantity[0] : null
              }
              label="Quantity"
              name="quantity"
              onChange={handleChange}
              type="number"
              value={formState.values.quantity || ""}
              variant="outlined"
              style={{ backgroundColor: "#ffffff" }}
            />
            <DialogActions>
              <Button
                className={classes.signInButton}
                color="primary"
                disabled
                size="large"
                type="submit"
                variant="contained"
                onClick={onSubmit}
              >
                Save
              </Button>
            </DialogActions>
          </div>
        ) : (
          <div>
            <div id="error">
              {errorMessage ? (
                <Chip
                  onDelete={deleteErrorMessage}
                  color="primary"
                  label={errorMessage}
                />
              ) : (
                ""
              )}
            </div>
            <br />
            <InputLabel
              id="type-select-outlined-label"
              className={
                formState.values.quantity > availableStock
                  ? classes.typoRed
                  : classes.typo
              }
            >
              {showNotification === true ? (
                <Chip
                  color="primary"
                  size="small"
                  onDelete={deleteNotification}
                  label={ShowQuantityMessage}
                  className={classes.chip}
                />
              ) : (
                ""
              )}
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                className={classes.textField}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Date"
                format="dd/MM/yyyy"
                value={formState.values.date || null}
                onChange={(data) => {
                  handleChange(
                    { target: { name: "date", value: data } },
                    handleMedicineChange
                  );
                }}
                animateYearScrolling
                name="date"
                style={{ backgroundColor: "#ffffff" }}
              />
            </MuiPickersUtilsProvider>

            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">
                Material Type
              </InputLabel>
              <Select
                id="materialType"
                value={
                  formState.values.materialTypeIdx !== undefined
                    ? formState.values.materialTypeIdx
                    : ""
                }
                onChange={(e) => {
                  handleChange(e, handlematerialChange(e));
                  setavailableStock(null);
                }}
                name="materialTypeIdx"
              >
                {materialTypeList !== null &&
                materialTypeList !== undefined &&
                Array.isArray(materialTypeList)
                  ? materialTypeList.map((material, idx) => {
                      return (
                        <MenuItem key={material.key} value={idx}>
                          {material.type}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">
                Material Name
              </InputLabel>
              <Select
                id="medicineType"
                value={
                  formState.values.medicineStockTypeIdx !== undefined
                    ? formState.values.medicineStockTypeIdx
                    : ""
                }
                onChange={(event) => {
                  handleChange(event, handleMedicineChange);
                }}
                name="medicineStockTypeIdx"
              >
                {materialType !== undefined &&
                materialType !== null &&
                materialType.type &&
                materialList[materialType.type] !== undefined &&
                materialList[materialType.type] !== null
                  ? materialList[materialType.type].map((material, index) => (
                      <MenuItem key={material.id} value={index}>
                        {material.materialName}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">Sheds</InputLabel>
              <Select
                id="shedType"
                value={
                  formState.values.shedTypeIdx !== undefined
                    ? formState.values.shedTypeIdx
                    : ""
                }
                onChange={handleChange}
                name="shedTypeIdx"
              >
                {shed !== undefined && shed !== null
                  ? shed.map((shed, index) => (
                      <MenuItem key={shed.id} value={index}>
                        {shed.name}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>
            <InputLabel id="type-select-outlined-label">
              {formState.values !== undefined &&
              formState.values !== null &&
              formState.values.medicineStockTypeIdx !== undefined &&
              formState.values.medicineStockTypeIdx !== null &&
              formState.values.medicineStockTypeIdx !== "" &&
              availableStock !== null
                ? `Available Stock: ${availableStock}`
                : availableStockErr}{" "}
            </InputLabel>

            <TextField
              className={classes.textField}
              error={hasError("quantity")}
              fullWidth
              helperText={
                hasError("quantity") ? formState.errors.quantity[0] : null
              }
              label="Quantity"
              name="quantity"
              onChange={handleChange}
              type="number"
              value={formState.values.quantity || ""}
              variant="outlined"
              style={{ backgroundColor: "#ffffff" }}
            />

            <DialogActions>
              <Button
                className={classes.signInButton}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                disabled={!formState.isValid}
                onClick={onSubmit}
              >
                Save
              </Button>
            </DialogActions>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MedicineStock;
