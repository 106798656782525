import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog,MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import SubUser from "./components/subuser";
import Icon from '@material-ui/core/Icon';



const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentSubUser = undefined;
let editMode = false;

const SubUsersView = ({showError, showMessage, history}) => {

    const [subusers, setSubUsers] = useState([]);
    const [farms, setFarms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSubUserDialog, setShowSubUserDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [roles,setRoles]= useState([]);

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    function onNewSubUser(event) {
        editMode = false;
        event.preventDefault();
        setShowSubUserDialog(true);
        setErrorMessage('');
    }

    function handleSubUserClose() {
        currentSubUser = undefined;
        setErrorMessage(null);
        setShowSubUserDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    
    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onSubUserDeleteConfirm = () => {
        if (currentSubUser && currentSubUser.email) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.post(`deleteSubuser`,{
                ...currentSubUser,
            });

            response.then(res => {
                if (res) {
                    let index = subusers.findIndex((srcSubUser) => {
                        return srcSubUser.email === currentSubUser.email;
                    });
                    let newSubUsers = [...subusers];
                    newSubUsers.splice(index, 1);
                    setSubUsers(newSubUsers);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('SubUser not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentSubUser = undefined;
            });

        }
    };

    const onDelete = (subuser) => {
        currentSubUser = subuser;
        setShowDeleteConfirm(true);
    };

    const onSubUserSave = (subuser, setpopuploading) => {
        //setLoading(true);
        // showError('');
        showMessage('');
        const response = api.post('subuser', {
            ...subuser,
        });

        response.then(res => {
            console.log("res=",res);
           
            if(res){
                var tempData = {
                    ...subuser,
                    "role":subuser.role
                }
                setSubUsers([...subusers,tempData ]);
                setShowSubUserDialog(false);
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('SubUser not added');
                else if(err.message === 'duplicate key')
                setErrorMessage('user already exists')
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onSubUserEdit = (subuser) => {
        console.log("subuser=",subuser)
       
        currentSubUser = {
            ...subuser,
            roleIndex:subuser.role,
        };
        editMode = true;
        setShowSubUserDialog(true);
        
    };

    const onSubUserUpdate = (subuser, setpopuploading) => {
        setShowSubUserDialog(false);
        //setLoading(true);
        // showError('');
        const response = api.post(`updateSubuser`, {
            ...subuser,
        });
        response.then((res) => {
            if(res){
                var tempData = {
                    ...subuser,
                    "role":subuser.role
                    }
                let index = subusers.findIndex((srcSubUser) => {
                    return srcSubUser.email === subuser.email;
                });
                setSubUsers([...subusers.slice(0, index),
                    {...tempData},
                    ...subusers.slice(index + 1)]);
            }
            
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('SubUser not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };

    const getAllUserRoles = ()=>{
        setShowSubUserDialog(false);
        showError('');
        showMessage('');

        const response = api.get('getUserRoles');

        response.then(res => {
            var userRoles = [];
            res.forEach((r)=>{
                r['ID'] = r.name;
                r['Name'] = r.name;
                userRoles.push(r);
            });
            setRoles(userRoles);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No User roles  Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    };

    //Get all SubUsers API Call
    const getAllSubUsers = () => {
        setShowSubUserDialog(false);
        showError('');
        showMessage('');

        const response = api.get('getAllSubusers');

        response.then(res => {
            setSubUsers([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No SubUsers Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

    const getAllFarms = () => {
        setShowSubUserDialog(false);
        showError('');
        showMessage('');

        const response = api.get('farm');

        response.then(res => {
            setFarms([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No SubUsers Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

    


    const onload=()=>{
        setLoading(true);
        Promise.all([getAllUserRoles(),getAllSubUsers(),getAllFarms()]).finally(()=>{
            setLoading(false);
        });
    }
    useEffect(onload, []);
    

    let subuserNameColumn = {title: 'SubUser', field: 'name', editable: 'never', defaultSort:'asc',
        render: rowData => <Mobile data={rowData}
                                   onEdit={() => {
                                       onSubUserEdit(rowData)
                                   }}
                                   onDelete={() => {
                                       onDelete(rowData)
                                   }}
        />
    };
    let actions = [];
    if (isDesktop) {
        subuserNameColumn = {title: 'Email', field: 'email', editable: 'never', defaultSort:'asc'};
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit SubUser',
                onClick: (event, rowData) => onSubUserEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete SubUser',
                onClick: (event, rowData) => onDelete(rowData)
            }
        ]
    }


    return <div className={classes.root}>
        <Grid
            container
            justifyContent='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    SubUsers
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewSubUser}>Add SubUser</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'id', hidden: true},
                subuserNameColumn,
                {title: 'Role', field: 'role', hidden: !isDesktop, editable: 'never'},
            ]}
            data={subusers}
            title='SubUser Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true
            }}
            actions={actions}
        />
        <SubUser deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showSubUserDialog} handleClose={handleSubUserClose} onSave={onSubUserSave} onEdit={onSubUserUpdate}
               editMode={editMode} data={currentSubUser} roles={roles} farms={farms} />
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onSubUserDeleteConfirm}
                       title='Delete SubUser ?'
                       subText='This action will delete the SubUser Details. Please confirm.'/>
    </div>
}

export default SubUsersView;