import React, { useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';

let schema = {
    batchIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    notes: {
        presence: { allowEmpty: false, message: 'is required' }
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(0.75),
        minWidth: 180,
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    }
}));

const DoctorNoteItem = ({ deleteErrorMessage, errorMessage, batches, data, handleClose, showDialog, onDoctorNoteItemSave, onDoctorNoteItemUpdate,
    editMode = false }) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);

    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if (showDialog) {
            console.debug(data)
            if (editMode) {
                data = {
                    ...data,
                    batchIndex: batches.findIndex((batch) => { return batch.id === data.batchId; })
                }
            }
            updateData(data, editMode);
        }
    };
    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        var tempData = {
            ...formState.values,
            batchId: batches[formState.values.batchIndex].id,
            batchName: batches[formState.values.batchIndex].name,
            shedId: batches[formState.values.batchIndex].shedId,
            shedName: batches[formState.values.batchIndex].shedName,
        }

        if (editMode)
            onDoctorNoteItemUpdate(tempData)
        else
            onDoctorNoteItemSave(tempData);
    };


    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >{editMode ? 'Update' : 'New'}  Doctor Note Item
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>

            <FormControl className={classes.formControl}>
                <InputLabel id="batchName-select-outlined-label">
                    Batch Name
                        </InputLabel>
                <Select
                    id="batchName-select"
                    value={formState.values.batchIndex !== undefined ? formState.values.batchIndex : ''}
                    onChange={handleChange}
                    name="batchIndex"
                >
                    {batches !== undefined && batches !== null ?
                        batches.map((batch, index) =>
                            <MenuItem key={batch.id} value={index}>{batch.name}({batch.shedName})</MenuItem>
                        ) : ""}
                </Select>
            </FormControl>
            <TextField
                className={classes.textField}
                error={hasError('notes')}
                fullWidth
                multiline
                rows="3"
                helperText={
                    hasError('notes') ? formState.errors.notes[0] : null
                }
                label="Notes"
                name="notes"
                onChange={handleChange}
                type="text"
                value={formState.values.notes || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
};

export default DoctorNoteItem;