import React, {  useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { BottomMenu,Profile, SidebarNav } from "../../../components/Sidebar";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import { NavLink as RouterLink } from "react-router-dom";
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import {MainMenuPages} from "../../../Main/components"
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import SystemUpdateOutlinedIcon from '@material-ui/icons/SystemUpdateOutlined';
import {useGlobalState} from '../../../../state';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { version } from "../../../../common/enums";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import WorkIcon from '@material-ui/icons/Work';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      height: "100%"
    }
  },
  root: {
    backgroundColor: theme.palette.navBackground.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
    overflowY: "auto"
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.buttonColor.primary,
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontSize:'15px',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      color: theme.palette.navHover.text
    }
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  },
  logo: {
    width: 60
  },
  logoContainer: {
    textAlign: "center"
  }
}));

/* const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
)); */

export let pages = ({ user, match }) => {
  
  let pages = [];

  if (user.versionID === version.lite) {
    switch(user.role){
      case "SUPER":
        pages = [
          {
            title: "Daily Entries",
            href: `/farm/${match.params["farmID"]}/daily-entry`,
            icon: <SystemUpdateOutlinedIcon />
          }
        ]
        break;

      case "DRIVER":
        pages = []
        break;

      case "DOCTOR":
        pages = [
          {
            title: "Farm Dashboard",
            href: `/farm/${match.params["farmID"]}/dashboard`,
            icon: <DashboardOutlinedIcon />
          },
          {
            nested: true,
            title: "Reports",
            icon: <AssessmentOutlinedIcon />,
            subMenus: [
              {
                title: "Single Batch Stock",
                href: `/farm/${match.params["farmID"]}/reports/single-batch-stock`,
              
              },
              {
                title: "Single Batch KPI Report",
                href: `/farm/${match.params["farmID"]}/reports/single-batch`,
                
              },
              {
                title: "Single Batch Weekly Report",
                href: `/farm/${match.params["farmID"]}/reports/single-batch-weekly`,
                
              },
              {
                title: "All Batch KPI Report",
                href: `/farm/${match.params["farmID"]}/reports/all-batch-daily`,
                
              }
            ]
          }
        ]
        break;

      default:
        pages = [
          {
            title: "Farm Dashboard",
            href: `/farm/${match.params["farmID"]}/dashboard`,
            icon: <DashboardOutlinedIcon />
          },
          {
            title: "Daily Entries",
            href: `/farm/${match.params["farmID"]}/daily-entry`,
            icon: <SystemUpdateOutlinedIcon />
          },
          {
            title: "Sheds",
            href: `/farm/${match.params["farmID"]}/shed`,
            icon: <HomeOutlinedIcon />
          },
          {
            title: "Batches",
            href: `/farm/${match.params["farmID"]}/batch`,
            icon: <GridOnOutlinedIcon />
          },
          {
            nested: true,
            title: "Reports",
            icon: <AssessmentOutlinedIcon />,
            subMenus: [
              {
                title: "Single Batch Stock",
                href: `/farm/${match.params["farmID"]}/reports/single-batch-stock`,
              
              },
              {
                title: "Single Batch KPI Report",
                href: `/farm/${match.params["farmID"]}/reports/single-batch`,
                
              },
              {
                title: "Single Batch Weekly Report",
                href: `/farm/${match.params["farmID"]}/reports/single-batch-weekly`,
                
              },
              {
                title: "All Batch KPI Report",
                href: `/farm/${match.params["farmID"]}/reports/all-batch-daily`,
                
              }
            ]
          }
        ]
        break;
    }

    return pages
  }

  if (user.versionID === version.pro) {
    switch(user.role){
      case "SUPER":
        pages = [
          {
            title: "Daily Entries",
            href: `/farm/${match.params["farmID"]}/daily-entry`,
            icon: <SystemUpdateOutlinedIcon />
          }
        ]
        break;

      case "DRIVER":
        pages = []
        break;

      case "DOCTOR":
        pages = [
          {
            title: "Farm Dashboard",
            href: `/farm/${match.params["farmID"]}/dashboard`,
            icon: <DashboardOutlinedIcon />
          },
          {
            nested: true,
            title: "Reports",
            icon: <AssessmentOutlinedIcon />,
            subMenus: [
              {
                title: "Single Batch Stock",
                href: `/farm/${match.params["farmID"]}/reports/single-batch-stock`,
              
              },
              {
                title: "Single Batch KPI Report",
                href: `/farm/${match.params["farmID"]}/reports/single-batch`,
                
              },
              {
                title: "Single Batch Weekly Report",
                href: `/farm/${match.params["farmID"]}/reports/single-batch-weekly`,
                
              },
              {
                title: "All Batch KPI Report",
                href: `/farm/${match.params["farmID"]}/reports/all-batch-daily`,
                
              }
            ]
          }
        ]
        break;
      case "STAFF":
          pages =[
            {
              title: "Farm Dashboard",
              href: `/farm/${match.params["farmID"]}/dashboard`,
              icon: <DashboardOutlinedIcon />
            },
            {
              title: "Daily Entries",
              href: `/farm/${match.params["farmID"]}/daily-entry`,
              icon: <SystemUpdateOutlinedIcon />
            },
            {
              title: "Sheds",
              href: `/farm/${match.params["farmID"]}/shed`,
              icon: <HomeOutlinedIcon />
            },
            {
              title: "Batches",
              href: `/farm/${match.params["farmID"]}/batch`,
              icon: <GridOnOutlinedIcon />
            },
            {
              title: "Move to Shed",
              icon: <LocalHospitalIcon />,
              href: `/farm/${match.params["farmID"]}/medicine-stock`,
            },
            {
              nested: true,
              title: "Reports",
              icon: <AssessmentOutlinedIcon />,
              subMenus: [
                {
                  title: "Single Batch Stock",
                  href: `/farm/${match.params["farmID"]}/reports/single-batch-stock`,
                
                },
                {
                  title: "Single Batch KPI Report",
                  href: `/farm/${match.params["farmID"]}/reports/single-batch`,
                  
                },
                {
                  title: "All Batch KPI Report",
                  href: `/farm/${match.params["farmID"]}/reports/all-batch-daily`,
                  
                },
                {
                  title: "All Batch Stock Report",
                  href: `/farm/${match.params["farmID"]}/reports/all-batch-stock`,
                  
                },
               
              ]
            },
            {
              nested: true,
              title: "Settings",
              icon: <SettingsOutlinedIcon />,
              subMenus: [
                {
                  title: "Labours Page",
                  href: `/farm/${match.params["farmID"]}/labour-page`
                
                }
              ]
            },
          ]
          break;
      default:
        pages = [
          {
            title: "Farm Dashboard",
            href: `/farm/${match.params["farmID"]}/dashboard`,
            icon: <DashboardOutlinedIcon />
          },
          {
            title: "Daily Entries",
            href: `/farm/${match.params["farmID"]}/daily-entry`,
            icon: <SystemUpdateOutlinedIcon />
          },
          {
            title: "Sheds",
            href: `/farm/${match.params["farmID"]}/shed`,
            icon: <HomeOutlinedIcon />
          },
          {
            title: "Batches",
            href: `/farm/${match.params["farmID"]}/batch`,
            icon: <GridOnOutlinedIcon />
          },
          {
            title: "Move to Shed",
            icon: <LocalHospitalIcon />,
            href: `/farm/${match.params["farmID"]}/medicine-stock`,
          },
          {
            nested: true,
            title: "Reports",
            icon: <AssessmentOutlinedIcon />,
            subMenus: [
              {
                title: "Single Batch Stock",
                href: `/farm/${match.params["farmID"]}/reports/single-batch-stock`,
              
              },
              {
                title: "Single Batch KPI Report",
                href: `/farm/${match.params["farmID"]}/reports/single-batch`,
                
              },
              {
                title: "Single Batch Weekly Report",
                href: `/farm/${match.params["farmID"]}/reports/single-batch-weekly`,
                
              },
              {
                title: "All Batch KPI Report",
                href: `/farm/${match.params["farmID"]}/reports/all-batch-daily`,
                
              },
              {
                title: "Daily P/L Report",
                href: `/farm/${match.params["farmID"]}/reports/daily-profit-loss`,
              
              },
              {
                title: "All Batch Stock Report",
                href: `/farm/${match.params["farmID"]}/reports/all-batch-stock`,
                
              },
            ]
          },
          {
            nested: true,
            title: "Settings",
            icon: <SettingsOutlinedIcon />,
            subMenus: [
              {
                title: "Labours Page",
                href: `/farm/${match.params["farmID"]}/labour-page`
              
              }
            ]
          },
        ]
        break;
    }

    return pages
  }

  if(user.role === "SUPER"){
    pages =[
      {
        title: "Daily Entries",
        href: `/farm/${match.params["farmID"]}/daily-entry`,
        icon: <SystemUpdateOutlinedIcon />
      },
    ]
  }
  else if(user.role === "DRIVER"){
    pages =[
      {
        title: "Add line sales",
        href: "/line-sale-driver",
        icon: <PictureAsPdfOutlinedIcon />,
        isBookmarked: false,
      },
    ]
  }
  else if(user.role === "DOCTOR"){
    pages =[
      {
        title: "Farm Dashboard",
        href: `/farm/${match.params["farmID"]}/dashboard`,
        icon: <SystemUpdateOutlinedIcon />
      },
      {
        nested: true,
        title: "Reports",
        icon: <AssessmentOutlinedIcon />,
        subMenus: [
          {
            title: "Single Batch Stock",
            href: `/farm/${match.params["farmID"]}/reports/single-batch-stock`,
          
          },
          {
            title: "Single Batch Report",
            href: `/farm/${match.params["farmID"]}/reports/single-batch`,
            
          },
          {
            title: "Single Batch Weekly Report",
            href: `/farm/${match.params["farmID"]}/reports/single-batch-weekly`,
            
          },
          {
            title: "All Batch Daily Report",
            href: `/farm/${match.params["farmID"]}/reports/all-batch-daily`,
            
          },
          {
            title: "All Batch Stock Report",
            href: `/farm/${match.params["farmID"]}/reports/all-batch-stock`,
            
          },
          {
            title: "All Batch Weekly Report",
            href: `/farm/${match.params["farmID"]}/reports/all-batch-weekly`,
          
          },
          {
            title: "Daily P/L Report",
            href: `/farm/${match.params["farmID"]}/reports/daily-profit-loss`,
          
          },
          // {
          //   title: "Stock Statement",
          //   href: `/farm/${match.params["farmID"]}/reports/batch-stock-statement`,
          
          // },
          {
            title: "All Batch Stock Statement",
            href: `/farm/${match.params["farmID"]}/reports/all-batch-stock-statement`,
            
          },
          {
            title: "Egg Production Cost Report",
            href: `/farm/${match.params["farmID"]}/reports/eggs-production`,
          }
        ]
      }
    ]
  }
  else{
    pages = [  {
        title: "Farm Dashboard",
        href: `/farm/${match.params["farmID"]}/dashboard`,
        icon: <DashboardOutlinedIcon />
      },
      {
        title: "Sheds",
        href: `/farm/${match.params["farmID"]}/shed`,
        icon: <HomeOutlinedIcon />
      },
      {
        title: "Batches",
        href: `/farm/${match.params["farmID"]}/batch`,
        icon: <GridOnOutlinedIcon />
      },
      {
        title: "Daily Entries",
        href: `/farm/${match.params["farmID"]}/daily-entry`,
        icon: <SystemUpdateOutlinedIcon />
      },
      {
        nested: true,
        title: "Storage",
        icon: <HomeOutlinedIcon />,
        subMenus: [
          {
            title: "Eggs",
            href: `/farm/${match.params["farmID"]}/farmStorage`,
          },
          {
            title: "Eggs Storage Report",
            href: `/farm/${match.params["farmID"]}/farmStorageReport`
          },
        ]
      },
      {
        nested: true,
        title: "Medicines",
        icon: <LocalHospitalIcon />,
        subMenus: [
          {
              title: "Move to Shed",
              href: `/farm/${match.params["farmID"]}/medicine-stock`,
          },
          {
            title: "Available Stock",
            href: `/farm/${match.params["farmID"]}/available-stock`,
        },
        ]
      },
      {
        nested: true,
        title: "Labour",
        icon: <WorkIcon />,
        subMenus: [
          {
            title: "Create Labour A/C",
            href: `/farm/${match.params["farmID"]}/labour`,
          },
          {
            title: "Attendance",
            href: `/farm/${match.params["farmID"]}/labour-Attendance`,
          },
          {
            title: "Labour Payment",
            href: `/farm/${match.params["farmID"]}/labour-payment`,
          },
          {
            title: "Labour Report",
            href: `/farm/${match.params["farmID"]}/labour-report`,
          },
        ]
      },
      {
        title: "Batch Shed Availability",
        href: `/farm/${match.params["farmID"]}/batch-shed-availability`,
        icon: <AssignmentTurnedInOutlinedIcon />
      },
      {
        nested: true,
        title: "Reports",
        icon: <AssessmentOutlinedIcon />,
        subMenus: [
          {
            title: "Single Batch Stock",
            href: `/farm/${match.params["farmID"]}/reports/single-batch-stock`,
          
          },
          {
            title: "Single Batch KPI Report",
            href: `/farm/${match.params["farmID"]}/reports/single-batch`,
            
          },
          {
            title: "Single Batch Weekly Report",
            href: `/farm/${match.params["farmID"]}/reports/single-batch-weekly`,
            
          },
          {
            title: "All Batch KPI Report",
            href: `/farm/${match.params["farmID"]}/reports/all-batch-daily`,
            
          },
          {
            title: "All Batch Stock Report",
            href: `/farm/${match.params["farmID"]}/reports/all-batch-stock`,
            
          },
          {
            title: "All Batch Weekly Report",
            href: `/farm/${match.params["farmID"]}/reports/all-batch-weekly`,
          
          },
          {
            title: "Daily P/L Report",
            href: `/farm/${match.params["farmID"]}/reports/daily-profit-loss`,
          
          },
          // {
          //   title: "Stock Statement",
          //   href: `/farm/${match.params["farmID"]}/reports/batch-stock-statement`,
          
          // },
          {
            title: "All Batch Stock Statement",
            href: `/farm/${match.params["farmID"]}/reports/all-batch-stock-statement`,
            
          },
          // {
          //   title: "Egg Production Cost Report",
          //   href: `/farm/${match.params["farmID"]}/reports/eggs-production`,
          // }
        ]
      },
      {
        nested: true,
        title: "Settings",
        icon: <SettingsOutlinedIcon />,
        subMenus: [
          {
            title: "Labours Page",
            href: `/farm/${match.params["farmID"]}/labour-page`,
          
          }
        ]
      },
    ];
  }


  return pages
}

const Sidebar = props => {
  const { open, variant, onClose, className, match, ...rest } = props;
  const classes = useStyles();
  const [showMainMenu,setShowMainMenu] = useState(false);
  const [{user}] = useGlobalState();


  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <RouterLink className={classes.logoContainer} to="/">
          <img
            className={classes.logo}
            alt="Logo"
            src="/images/logos/poultry-client-logo.png"
          />
        </RouterLink>
        <Profile />

        {
          showMainMenu?null:
          <div>
            <Divider className={classes.divider} />
            <ListItem className={classes.item} disableGutters key="backToMain">
              <Button
                className={classes.button}
                onClick={()=>setShowMainMenu(true)}
              >
                <div className={classes.icon}>
                  <ArrowBackIcon />
                </div>
                Back to Main Menu
              </Button>
            </ListItem>
          </div>
        }
        <Divider className={classes.divider} />
        <SidebarNav open={open} className={classes.nav} pages={showMainMenu?MainMenuPages({user}):pages({user, match})} onClose={onClose} showMainMenu={showMainMenu}/>
        <BottomMenu/>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
