import React, { useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';

const schema = {
    salePrice: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    date: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    lineSaleIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    materialNameIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    customerIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    }
}));

const LineSaleEntry = ({ deleteErrorMessage, errorMessage, lineNames, customers, materials, onEdit, data, onSave, 
    handleClose, showDialog, editMode = false }) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);

    const resetBeforeEdit = () => {
        if (showDialog) {
            updateData(data, editMode);
        }
    };
    useEffect(resetBeforeEdit, [showDialog]);


    const onSubmit = () => {
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        const tempData = {
            ...formState.values,
            customerID: customers[formState.values.customerIndex].id,
            lineSaleID: lineNames[formState.values.lineSaleIndex].id,
            materialNameID: materials[formState.values.materialNameIndex].materialId,
            customerName: customers[formState.values.customerIndex].companyName,
            lineSaleName: lineNames[formState.values.lineSaleIndex].name,
            materialName: materials[formState.values.materialNameIndex].materialName,
            amount: formState.values.amount
        };
        if (editMode)
            onEdit(tempData);
        else
            onSave(tempData);
    };

    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title" >{editMode ? 'Update' : 'New'} Other Income
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
        <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
            <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    halfWidth
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    animateYearScrolling
                    name="date"
                    style={{ backgroundColor: "#ffffff" }}
                />
            </MuiPickersUtilsProvider>
            <FormControl className={classes.formControl}>
                <InputLabel id="lineSaleName-select-outlined-label">
                    Line Name
                </InputLabel>
                <Select
                id="lineSaleIndex"
                value={formState.values.lineSaleIndex !== undefined ? formState.values.lineSaleIndex : ''}
                onChange={handleChange}
                name="lineSaleIndex"
                >
                      {lineNames !== undefined && lineNames !== null ?
                    lineNames.map((lineSale,index)=>
                        <MenuItem key = {lineSale.id} value={index}>{lineSale.name}</MenuItem>
                ):""}     
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customerName-select-outlined-label">
                    Customer Name
                </InputLabel>
                <Select
                    id="customerName-select"
                    value={formState.values.customerIndex !== undefined ? formState.values.customerIndex : ''}
                    onChange={handleChange}
                    name="customerIndex"
                >
                    {customers !== undefined && customers !== null ?
                       customers.map((customer, index) =>
                           <MenuItem key={customer.id} value={index}>{customer.primaryContact}</MenuItem>
                    ) : ""}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="materialName-select-label">Egg Type</InputLabel>
                <Select
                    id="materialName-select"
                    value={formState.values.materialNameIndex !== undefined ? formState.values.materialNameIndex : ''}
                    onChange={handleChange}
                    name="materialNameIndex"
                >
                    {materials.map((material, index) =>
                        <MenuItem key={material.materialId} value={index}>{material.materialName}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <TextField
                className={classes.textField}
                error={hasError('salePrice')}
                fullWidth
                helperText={
                    hasError('salePrice') ? formState.errors.salePrice[0] : null
                }
                label="Sale Price"
                name="salePrice"
                onChange={event => {
                    let temp = parseFloat(event.target.value)
                    handleChange(event)
                    handleChange({ target: { name: 'totalAmount', value: temp*(formState.values.quantity*30) } })
                    }
                }
                type="number"
                value={formState.values.salePrice || ''}
                variant="outlined"
                style={{ backgroundColor: "#ffffff" }}
            />
            <TextField
                className={classes.textField}
                error={hasError('quantity')}
                fullWidth
                helperText={
                    hasError('quantity') ? formState.errors.quantity[0] : null
                }
                label="Quantity (In Trays)"
                name="quantity"
                onChange={event => {
                    let temp = parseFloat(event.target.value)
                    handleChange(event)
                    handleChange({ target: { name: 'totalAmount', value: formState.values.salePrice*(temp*30) } })
                }}
                type="number"
                value={formState.values.quantity || ''}
                variant="outlined"
                style={{ backgroundColor: "#ffffff" }}
            />
            <TextField
                disabled
                className={classes.textField}
                error={hasError('totalAmount')}
                fullWidth
                helperText={
                    hasError('totalAmount') ? formState.errors.totalAmount[0] : null
                }
                label="Total Amount"
                name="totalAmount"
                type="number"
                value={formState.values.totalAmount || ''}
                variant="outlined"
                style={{ backgroundColor: "#ffffff" }}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
};

export default LineSaleEntry;