import React, { useEffect,useState } from 'react';
import api from "../../../common/api";
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/styles/makeStyles';
import {MaterialTable} from '../../../components';

const useStyles = makeStyles(theme => ({
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    }
}));

const LowStockList = ({ showError }) => {

    const [materialMasters, setMaterialMasters] = useState([]);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const getLowStock = () => {
        setLoading(true);
        const response = api.get('get-low-stock');

        response.then(res => {
            setMaterialMasters(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(getLowStock, []);

    if (loading) {
        return <div className={classes.progressContainer}>
            <CircularProgress className={classes.progressBar} />
        </div>
    }
    return <MaterialTable
        isLoading={loading}
        columns={[
            { title: 'id', field: 'id', hidden: true },
            { title: 'Name', field: 'materialName', editable: 'never' },
            { title: 'Min Stock', field: 'minimumStockValue', editable: 'never', defaultSort: 'desc'},
            { title: 'Current Stock', field: 'currentStock', editable: 'never' },
        ]}
        data={materialMasters}
        title='Low Stock List'
        options={{
            actionsColumnIndex: -1,
            search: true,
            showTitle: true,
            toolbar: true,
            pageSize:(materialMasters.length ? (materialMasters.length > 5 ? 5 : materialMasters.length ): 0),
            emptyRowsWhenPaging: false,
        }}
        
    />
};

export default LowStockList;