import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { populateTaxData } from '../../../common/functions';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MaterialTable } from '../../../components';
import Material from "./material";
import Icon from '@material-ui/core/Icon';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableBody';
import api from '../../../common/api';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import {useGlobalState} from '../../../state';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import { version } from "../../../common/enums";
const schema = {
    paymentDueDate: {
        presence: { allowEmpty: false, message: 'is required' },
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 1,
        }
    },
    date: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    truckNumber: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    // tds: {
    //     presence: false,
    //     type: 'number',
    //     numericality: {
    //         greaterThanOrEqualTo: 0,
    //         lesserThanOrEqualTo: 100
    //     }
    // },
    saleMaterials: {
        presence: { allowEmpty: false, message: 'is required' }
    }

};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2.7),
        marginBottom: theme.spacing(2),
        minWidth: 160
    },
    textField: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        minWidth: 160

    },
    switchClass: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    formControlLabelClass: {
        marginTop: theme.spacing(2),
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    datePicker: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth: 160
    },
    addButton: {
        textAlign: "right",
        marginBottom: theme.spacing(2),
        minWidth: 160,
    },
    totalTable: {
        float: 'right',
        width: theme.spacing(44.5)
    },
    totalRow:{
        paddingBottom: theme.spacing(0)
    },
    totalCell: {
        width: theme.spacing(16.37),
        paddingBottom: 0,
        paddingLeft: theme.spacing(0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },

}));
let materialIndex = 0
let currentMaterial = undefined
let eggDetail = undefined
let editMaterialMode = false
const Sale = ({ deleteErrorMessage, errorMessage, customers, materialTypes, farms, showError, 
    onEdit, data, onSave, handleClose, showDialog, editMode = false, lineSales, resetBillTotal, setResetBillTotal, trucks}) => {
    const classes = useStyles();
    const [sheds, setShed] = useState([]);
    const [materialNames, setMaterialNames] = useState([]);
    const [formState, hasError, handleChange, updateData, setFormState] = useFromValidator(schema);
    const [showMaterialDialog, setShowMaterialDialog] = useState(false)
    const [saleMaterials, setSaleMaterials] = useState([])
    const [billValueSum, setBillValueSum] = useState(0.0)
    const [unInvoicedQuantity, setUnInvoicedQuantity] = useState(0)
    const history = useHistory();
    const [popupLoading, setPopupLoading] = useState(false) ;
    const [quantityColumn, setQuantityColumn] = useState([])
    const [TaxProfiles, setTaxProfiles] = useState([]);
    const [FinalTotalBillAmt, setFinalTotalBillAmt] = useState(0);
    const [TcsTaxValue, setTcsTaxValue] = useState(undefined)
    const [TdsTaxValue, setTdsTaxValue] = useState(undefined)
    // const [TotalMaterial, setTotalMaterial] = useState(0);
    const [showTcs, setShowTcs] = useState(false)
    const [showTds, setShowTds] = useState(false)
    const [taxProfileHashMap, setTaxProfileHashMap] = useState(null);
    const [taxProfileIDs, setTaxProfileIDs] = useState([]);
    const [gstTaxProfiles, setGstTaxProfiles] = useState([])
    const [taxValue, setTaxValue] = useState(0.0)
    const [farmStorageStock,setFarmStorageStock] = useState(0)

    const [reload,setReload ] = useState(false);
    const GST = "GST";
    const TDS = "TDS";
    const TCS = "TCS";
    //const lineSaleCheck = useState(formState.values.onLineSaleSwitch)
    const [lineSaleCheck , setLineSaleCheck] = useState(false)

    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }

    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if (showDialog) {
            setBillValueSum(0.0)
            setSaleMaterials([]);
            // setTotalMaterial(0);
            setFinalTotalBillAmt(0)
            setTaxValue(0)
            setTcsTaxValue(undefined);
            setTdsTaxValue(undefined);
            setShowTds(false);
            setShowTcs(false);
            setLineSaleCheck(false)
            setTaxProfileIDs([]);
            if (editMode) {
                if(user.versionID === version.pro){
                    currentMaterial = data;
                    setLineSaleCheck(data.onLineSale === 1 ? true : false)
                    setSaleMaterials(data.saleMaterials);
                    setResetBillTotal(true);
                    onMaterialEdit(data);
                }
                else{
                // if (data.onLineSale !== undefined && data.onLineSale !== null && data.onLineSale === 1) {
                //     setLineSaleCheck(true)
                // }
                setTaxValue(data.tax)
                setLineSaleCheck(data.onLineSale === 1 ? true : false)
                if (data.tcs && data.tcs > 0) {
                    setTcsTaxValue(data.tcs)
                    setShowTcs(true);
                }
                if (data.tds && data.tds > 0) {
                    setTdsTaxValue(data.tds)
                    setShowTds(true);
                }
                setSaleMaterials(data.saleMaterials)
                setResetBillTotal(true)
                }
            }
            updateData(data, editMode);
            populateTaxData(setTaxProfiles,[GST,TDS,TCS],false)
        }
    };

    useEffect(resetBeforeEdit, [showDialog]);
    useEffect(alterFormStateValuesWhenTaxValueChanges,[TcsTaxValue])

    useEffect(() => {
        // calculateTotalOfSaleMaterial(saleMaterials);
        calculateBillTotal(saleMaterials, TcsTaxValue, TdsTaxValue)
        // eslint-disable-next-line
    }, [resetBillTotal])


    const onSubmit = (formData=false) => {
        //Broken Egg Sale
        if (formData?.eggTypeName === "DEFAULT BROKENEGGS") {
            formData.isBrokenEggsEnabled = true
        }
        if(user.versionID === version.pro){
            setPopupLoading(true);
            if (editMode || editMaterialMode)
            {
                onEdit(formData, setPopupLoading);
            }else{
                onSave(formData, setPopupLoading);
            }
            handleMaterialClose()
        }
        else{
        if (resetBillTotal === true) {
            setResetBillTotal(false)
        }
        let taxObj = {
            tcs: TcsTaxValue !== null && TcsTaxValue !== undefined? TcsTaxValue : 0,
            tds: TdsTaxValue !== null && TdsTaxValue !== undefined? TdsTaxValue : 0,
            taxProfileIDs: taxProfileIDs
        }
        setPopupLoading(true) 
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        let tempData = {
            ...formState.values,
            truckNumber:lineSaleCheck === false ? formState.values.truckNumber : "",
            truckID : lineSaleCheck === true && formState.values.truckIndex !== undefined 
            && formState.values.truckIndex !== null && trucks[formState.values.truckIndex] !== undefined 
            && trucks[formState.values.truckIndex] !== null? trucks[formState.values.truckIndex].id : -1,
            onLineSale: formState.values.onLineSaleSwitch ? 1 : 0,
            totalBillValue: billValueSum,
        };
        
        if(formState.values.customerIndex > -1 && lineSaleCheck === false){
            tempData = {
            ...tempData,
                customerId: customers[formState.values.customerIndex].id,
                customerName: customers[formState.values.customerIndex].name,
                customerAddress: customers[formState.values.customerIndex].address,
                customerGstNumber: customers[formState.values.customerIndex].gstNumber,
            }
        }

        if (editMode === true && lineSaleCheck === true) {
            tempData = {
                ...tempData,
                    customerId: 0,
                    customerName: "",
                    customerAddress: "",
                    customerGstNumber: "",
                }
        }
        
        if(formState.values.lineSaleIndex > -1){
            tempData = {
            ...tempData,
                lineSaleID: lineSales[formState.values.lineSaleIndex].id,
                saleLinesName: lineSales[formState.values.lineSaleIndex].name,
            }
        }
        tempData = {
            ...tempData,
            ...taxObj
        }

        if (editMode)
        {
            onEdit(tempData, setPopupLoading);
        }
        else
        {
            onSave(tempData, setPopupLoading);
        }

        currentMaterial = undefined
    }
    };

    //calling this in use effect, as traditionally all the values are stored in formState, to further save it in DB
    //so when the user picks one of tcs or tds, the alternate tax value in formState should be set to 0.
    function alterFormStateValuesWhenTaxValueChanges() {
        if (TcsTaxValue && formState.values.tds) {
            setFormState({
                ...formState,
                values:{
                    ...formState.values,
                    tds:0
                },
                touched: {
                    tds: true
                }
            })
        }
        else if (TdsTaxValue && formState.values.tcs) {
            setFormState({
                ...formState,
                values:{
                    ...formState.values,
                    tcs:0
                },
                touched: {
                    tcs: true
                }
            })
        }
    }

    useEffect(() => {
        if (lineSaleCheck === true) {

            let obj = {};

            if (editMode === true) {
                obj.truckIndex = formState.values.truckIndex === -1 ? '' : formState.values.truckIndex
                obj.lineSaleIndex = formState.values.lineSaleIndex === -1 ? '' : formState.values.lineSaleIndex 
            } else {
                obj.truckIndex = ''
                obj.lineSaleIndex = ''
            }
            delete schema.truckNumber
            let truckIndex = {
                    presence: { allowEmpty: false, message: 'is required' },
                    type: 'number',
            }
            schema.truckIndex = truckIndex

            let lineSaleIndex = {
                presence: { allowEmpty: false, message: 'is required' },
                type: 'number',
            }

            schema.lineSaleIndex = lineSaleIndex

            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    customerIndex:-1,
                    truckNumber:'',
                    ...obj
                }
            })

            return;
        } else {
            let truckNumber = {
                presence: { allowEmpty: false, message: 'is required' }
            }
            schema.truckNumber = truckNumber

            delete schema.truckIndex

            let customerIndex = {
                presence: { allowEmpty: false, message: 'is required' },
                type: 'number',
            }

            schema.customerIndex = customerIndex

            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    customerIndex:'',
                    truckNumber: '',
                    truckIndex: -1,
                    lineSaleIndex: -1
                }
            })

            return;
        }
 // eslint-disable-next-line
    }, [lineSaleCheck])

    function onNewMaterial(event) {
        editMaterialMode = false;
        event.preventDefault();
        setShowMaterialDialog(true);
    }

    function onMaterialEdit(rowData) {
        let gstTaxProfileIndex = (user.versionID !== version.pro) ? gstTaxProfiles?.findIndex((el)=>{return el.id === rowData.taxProfileID}) : null;
        setShed([])
        if (rowData.inventoryType === 2) {
            currentMaterial = {
                ...rowData,
                materialTypeIndex: materialTypes.findIndex((materialType) => { return materialType.id === rowData.materialTypeId }),
                farmIndex: farms.findIndex((farm) => { return farm.id === rowData.farmId; }),
                unitID: rowData.unitID,
                gstTaxProfileIndex : gstTaxProfileIndex
            }
            editMaterialMode = true
            GetMultipleApis(rowData)
            /* onGetUnInvoicedQuantity(rowData.farmId, rowData.shedId)
             onGetMaterialNames(rowData.materialTypeId)
             onGetShed(rowData.farmId)*/


        } else {
            currentMaterial = {
                ...rowData,
                materialTypeIndex: materialTypes.findIndex((materialType) => { return materialType.id === rowData.materialTypeId }),
                gstTaxProfileIndex : gstTaxProfileIndex
            }
            editMaterialMode = true
            onGetMaterialNames(rowData.materialTypeId)
        }

        
    }

    const GetMultipleApis = (rowData) => {
        showError('');
        let date = formState.values.date ? formState.values.date : new Date()
        const UnInvoicedQuantityResponse = api.post(`get-uninvoiced-quantity`, {
            farmId: rowData.farmId,
            shedId: rowData.shedId,
            date: date
        }).catch(err => {showError(err.message);});

        // If rowData.materialTypeId == -1 then materialTypeID should be 6, because id of 6 is Eggs in material type table
        let materialTypeID = rowData.materialTypeId !== -1 ? rowData.materialTypeId : 6
        
        const materialResponse = api.get(`materials-of-type/${materialTypeID}`).catch(err => {showError(err.message);});
        const ShedResponse = api.get(`farm/${rowData.farmId}/shed`).catch(err => {showError(err.message);});

        let request = [materialResponse, ShedResponse]
        if(currentMaterial.materialTypeId === 6){
            request=[
                ...request,
                UnInvoicedQuantityResponse
            ]
        }

        Promise.all(request).then(function (res) {
            setMaterialNames([...res[0]]);
            setShed([...res[1]]);
            currentMaterial = {
                ...currentMaterial,
                materialIndex: res[0].findIndex((material) => { return material.id === rowData.materialId; }),
                shedIndex: res[1].findIndex((shed) => { return shed.id === rowData.shedId; }),
            }
            if (currentMaterial.materialTypeId === 6) {
                setUnInvoicedQuantity(res[2].unInvoicedQuantity)
                eggDetail = {
                    ...eggDetail,
                    eggID: currentMaterial.materialId,
                    eggDate: moment(formState.values.date).format("YYYY-MM-DD")
                }
            }
            if(user.versionID === version.pro){
                setReload(!reload);
            }
            else{
                setShowMaterialDialog(true);
            }
        }).catch(function (err) {
            if (err.message) {
                showError(err.message);
            } else {
                showError('Unknown Error');
            }
        });

    }

    function onMaterialDelete(rowData) {
        let index = (formState.values.saleMaterials).findIndex((srcSaleMaterial) => {
            return srcSaleMaterial.id === rowData.id;
        });
        let newSaleMaterilas = [...formState.values.saleMaterials];
        let newSale = {}
        if (editMode) {
            let newDeletedSaleMaterials = []
            if (formState.values.deletedSaleMaterials !== undefined && formState.values.deletedSaleMaterials !== null) {
                newDeletedSaleMaterials = [...formState.values.deletedSaleMaterials]
            }
            newSaleMaterilas.splice(index, 1);
            newDeletedSaleMaterials = [
                ...newDeletedSaleMaterials,
                rowData
            ]
            newSale = {
                ...formState.values,
                saleMaterials: newSaleMaterilas,
            }
            if (rowData.id > 0) {
                newSale = {
                    ...newSale,
                    deletedSaleMaterials: newDeletedSaleMaterials
                }
            }
        } else {
            newSaleMaterilas.splice(index, 1);
            newSale = {
                ...formState.values,
                saleMaterials: newSaleMaterilas,
            }

        }
        if (rowData.id < 0) {
            materialIndex++
        }
        setSaleMaterials(newSaleMaterilas)
        calculateBillTotal(newSaleMaterilas, TcsTaxValue, TdsTaxValue)
        updateData(newSale, true);
    }


    //Get all materialType API Call
    const onGetMaterialNames = (materialId) => {
        setMaterialNames([])
        showError('');
        const response = api.get(`materials-of-type/${materialId}`);
        response.then(res => {
            setMaterialNames([...res]);
            if (editMaterialMode === true) {
                currentMaterial = {
                    ...currentMaterial,
                    materialIndex: res.findIndex((material) => { return material.id === currentMaterial.materialId; }),
                }
                if (currentMaterial.materialTypeId === 6) {
                    eggDetail = {
                        ...eggDetail,
                        eggID: currentMaterial.materialId,
                        eggDate: moment(formState.values.Date).format("YYYY-MM-DD"),
                    }
                }
            }
            if (editMaterialMode === true) {
                if (currentMaterial.inventoryType === 1) {
                    if(user.versionID === version.pro){
                        setReload(!reload);
                    }
                    else{
                        setShowMaterialDialog(true);
                    }
                }
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found') {
                    showError('No Materials found for the chosen material type');
                }
                else {
                    showError(err.message);
                }
            } else {
                showError('Unknown Error');
            }
        });

    }

    useEffect(() => {
        if (TaxProfiles !== null && TaxProfiles !== undefined && Array.isArray(TaxProfiles) && TaxProfiles.length > 0) {
            let map = new Map()
            let gstTaxProfileArray = [];

            for (const el of TaxProfiles) {
                if (el.taxTypeId === 1) {
                    gstTaxProfileArray.push(el)
                } else {
                    map.set(el.id, el)                        
                }
            }
            setTaxProfileHashMap(map)
            setGstTaxProfiles(gstTaxProfileArray);
        }
    }, [TaxProfiles])

    //Get all Shed API Call
    const onGetShed = (farmId) => {
        setShed([])
        showError('');
        const response = api.get(`farm/${farmId}/shed`);
        response.then(res => {
            setShed([...res]);
            /* if (editMaterialMode === true) {
                 currentMaterial = {
                     ...currentMaterial,
                     farmIndex: farms.findIndex((farm) => { return farm.id === farmId; }),
                     shedIndex: res.findIndex((shed) => { return shed.id === currentMaterial.shedId; }),
                 }
                 setShowMaterialDialog(true);
             }*/
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Sheds');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
    }

    //get farm storage
    const onGetFarmStorage = (farmId) => {
        setFarmStorageStock([])
        showError('');
        const response = api.get(`/farm/${farmId}/farmStorage`);
        response.then(res => {
            setFarmStorageStock([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found' || err.message === 'no stock')
                    showError('No Farm Storage');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
    }

    const onGetUnInvoicedQuantity = (farmId, shedId, isBrokenEggs, eggTypeName,dateSelected=null,saleID,salematerialID) => {
        showError('');
        let date = dateSelected || (formState.values.date ? formState.values.date : new Date());
        const response = api.post(`get-uninvoiced-quantity`, {
            farmId: farmId,
            shedId: shedId,
            date: date,
            isBrokenEggs: isBrokenEggs,
            eggTypeName: eggTypeName,
            saleID:saleID,
            salematerialID:salematerialID
        });
        response.then(res => {
            setUnInvoicedQuantity(res.unInvoicedQuantity)
        }).catch(err => {
            if (err.message) {
                showError(err.message)
            } else {
                showError('Unknown Error')
            }
            setUnInvoicedQuantity(0)
        });
    }

    function onMaterialUpdate(saleMaterial) {
        //Broken Egg Sale
        if (saleMaterial?.eggTypeName === "DEFAULT BROKENEGGS") {
            saleMaterial.isBrokenEggsEnabled = true
        }

        let index = (formState.values.saleMaterials).findIndex((srcSaleMaterial) => {
            return srcSaleMaterial.id === saleMaterial.id;
        });
        let tempSaleMaterials = [...(formState.values.saleMaterials).slice(0, index),
        { ...saleMaterial },
        ...(formState.values.saleMaterials).slice(index + 1)]
        let tempFormStateValues = {
            ...formState.values,
            saleMaterials: tempSaleMaterials
        }
        setSaleMaterials(tempSaleMaterials)
        updateData(tempFormStateValues, true);
        calculateBillTotal(tempSaleMaterials, TcsTaxValue, TdsTaxValue)
        setShowMaterialDialog(false);
    }

    function onMaterialSave(material) {
        //Broken Egg Sale
        if (material?.eggTypeName === "DEFAULT BROKENEGGS") {
            material.isBrokenEggsEnabled = true
        }
        let tempFormState = {}
        let tempSaleMaterial = []
        if (formState.values.saleMaterials === undefined || formState.values.saleMaterials === null) {
            tempSaleMaterial = [
                material
            ]
            tempFormState = {
                ...formState.values,
                saleMaterials: [
                    material
                ]
            }
        } else {

            tempSaleMaterial = [
                ...formState.values.saleMaterials,
                material
            ]
            tempFormState = {
                ...formState.values,
                saleMaterials: tempSaleMaterial
            }
        }
        materialIndex--
        updateData(tempFormState, true);
        setSaleMaterials(tempFormState.saleMaterials)
        // calculateTotalOfSaleMaterial(tempFormState.saleMaterials)
        calculateBillTotal(tempFormState.saleMaterials,TcsTaxValue, TdsTaxValue)
        setShowMaterialDialog(false);
    }

    function handleMaterialClose() {
        currentMaterial = undefined;
        if(user.versionID === version.pro){
            editMaterialMode = false;
            handleClose();
        }
        else{
            setShowMaterialDialog(false);
        }
        if(errorMessage){
            deleteErrorMessage();
        }
        setUnInvoicedQuantity(0)
    }

    function handlechangeStorageStock() {
        setFarmStorageStock([])
    }

    function calculateBillTotal(tempSaleMaterials, tcs, tds) {
        let total = 0
        let gst = 0.0;
        if (tempSaleMaterials !== undefined && tempSaleMaterials !== null) {
            if (tempSaleMaterials.length !== 0) {
                tempSaleMaterials.forEach((saleMaterial, index) => {
                    if (saleMaterial.billValue > 0)
                        total = total + saleMaterial.billValue
        
                    if (saleMaterial.gst !== null && saleMaterial.gst !== undefined ) {
                            let gstTaxPercentage = saleMaterial.gst;
                            let gstTaxAmt = (gstTaxPercentage/100) * saleMaterial.billValue;
                            gst += gstTaxAmt
                    }
                })
                setTaxValue(gst)
            }
        }

        setBillValueSum(total);
        //Final Total = Bill Value+TCS% - TDS%
        let TCS = tcs?(total * (tcs / 100)):0;
        let TDS = tds?(total * (tds/100)):0;
        let finalTotal = (total + gst + TCS) - TDS;
        setFinalTotalBillAmt(finalTotal);
    }

    // commenting for now, function used in old logic of sale total material calcualtion
    // function calculateTotalOfSaleMaterial(saleMaterials){
    //     let total = 0.0
    //     if(saleMaterials !== undefined){
    //         saleMaterials.forEach((saleMaterial, index) => {
    //             if (saleMaterial.billValue > 0.0) {
    //                 total = total + saleMaterial.billValue
    //             }
    //         })
    //     }
    //     setTotalMaterial(total);
    // }

    const changeToUpperCase = (event) => {
        let tempTruckNumber = (event.target.value).toUpperCase()
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                truckNumber: tempTruckNumber
            }
        })
    }
    let actions = [
        {
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit sale Details',
            onClick: (event, rowData) => onMaterialEdit(rowData)
        },
        {
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete sale Details',
            onClick: (event, rowData) => onMaterialDelete(rowData)
        }
    ]

    
    useEffect(()=>{
        let isBird = false
        if(saleMaterials !== undefined && saleMaterials !== null && saleMaterials.length > 0)
            isBird = saleMaterials.find(material => material.materialTypeId === 7)

        if(isBird){
            setQuantityColumn([
                {
                    title: 'Number Of Birds', field: 'numberOfBirds', editable: 'never',
                    render: (rowData) => {
                        return <span>{ rowData.materialTypeId === 7 ? rowData.numberOfBirds : 0 } </span>
                    }
                }
            ])
        }else{
            setQuantityColumn([])
        }
    }, [saleMaterials])

    function onLineSaleChange(event) {
        setLineSaleCheck(event.target.checked);
        if (event.target.checked === true) {
            delete schema.truckNumber
            let truckIndex = {
                    presence: { allowEmpty: false, message: 'is required' },
                    type: 'number',
            }
            schema.truckIndex = truckIndex
        } else {
            delete schema.truckIndex
            let truckNumber = {
                presence: { allowEmpty: false, message: 'is required' },
                type: 'string',
            }

            schema.truckNumber = truckNumber
        }
    }

    //commenting for now - functions used in old logic for tcs and tds
    
    // function onChangeTcsTaxValue(event) {
    //     setTcsTaxValue(event.target.value);
    //     setShowTds(false);
    //     setShowTcs(true);
    //     setTdsTaxValue(undefined);
    //     calculateBillTotal(formState.values.saleMaterials,event.target.value,TdsTaxValue)
    // }
    
    // function onChangeTdsTaxValue(event) {
    //     setTdsTaxValue(event.target.value);
    //     setShowTds(true);
    //     setShowTcs(false);
    //     setTcsTaxValue(undefined);
    //     calculateBillTotal(formState.values.saleMaterials,TcsTaxValue,event.target.value)
    // }

    function getTaxProfile(e) {
        let customerID = customers[e.target.value].id
        /**
         * Note: The below condition is executed upon editing an old record, so the old tax values are used
         */
        if (data !== undefined && data !== null && customerID === data.customerId) {
            setTcsTaxValue(data.tcs)
            setShowTds(data.tds > 0 ? true : false)
            setTdsTaxValue(data.tds)
            setShowTcs(data.tcs > 0 ? true : false)
            let total = (billValueSum +  (billValueSum * (data.tcs / 100))) - (billValueSum * (data.tds / 100));
            setFinalTotalBillAmt(total)
            return;
        }
        api.get(`/customer-vendor-details-tax-profile/${customerID}`)
        .then((res)=>{
            setTaxProfileIDs(res)
            let TCS=0;
            let TDS=0;
            let obj = {
                tcs:{
                    setState:function() {
                        setTcsTaxValue(null)
                        setShowTcs(false)
                    },
                    set:false
                },tds:{
                    setState:function() {
                        setTdsTaxValue(null)
                        setShowTds(false)
                    },
                    set:false
                },

            }
            for (const el of res) {
                if (taxProfileHashMap.get(el) !== undefined && taxProfileHashMap.get(el) !== null && Object.keys(taxProfileHashMap.get(el)).length > 0) {
                    let {taxTypeId, taxPercentage} = taxProfileHashMap.get(el)
                     if (taxTypeId === 2) {
                        TDS = (billValueSum * (taxPercentage / 100));
                        setTdsTaxValue(taxPercentage)
                        setShowTds(true)
                        obj.tds.set = true
                    }else if (taxTypeId === 3) {
                        TCS = (billValueSum * (taxPercentage / 100));
                        setTcsTaxValue(taxPercentage)
                        setShowTcs(true)
                        obj.tcs.set = true
                    }
                }
            }
            for (const key in obj) {
                if (obj[key]["set"] === false) {
                    obj[key]["setState"]()
                }
            }
            if (billValueSum > 0) {
                    let finalTotal = (billValueSum + TCS) - TDS;
                    setFinalTotalBillAmt(finalTotal);
            }

        })
        .catch((err)=>{
            if (err.message.includes(404)) {
                setTaxProfileIDs([])
                setTcsTaxValue(null)
                setTdsTaxValue(null)
                setShowTcs(false)
                setShowTds(false)
                let billVal = billValueSum
                setFinalTotalBillAmt(billVal)
            }

        })
    }

    return <Dialog disableBackdropClick maxWidth='md' open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >{editMode ? 'Update' : 'New'} Sale
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
            {
                (user.versionID === version.pro) ? 
                <Material farms={farms} materialNames={materialNames} showError={showError}
                sheds={sheds} onGetShed={onGetShed}
                farmStorageStock={farmStorageStock}
                handlechangeStorageStock={handlechangeStorageStock}
                onGetFarmStorage ={onGetFarmStorage}
                onGetMaterialNames={onGetMaterialNames} materialTypes={materialTypes} eggDetail={eggDetail}
                onGetUnInvoicedQuantity={onGetUnInvoicedQuantity}
                lineSaleCheck={formState.values.onLineSaleSwitch }
                onEdit={onSubmit} data={currentMaterial} onSave={onSubmit} handleClose={handleMaterialClose}
                showDialog={showDialog} editMode={editMaterialMode} materialIndex={materialIndex} unInvoicedQuantity={unInvoicedQuantity}
                reload={reload} errorMessage={errorMessage} deleteErrorMessage={deleteErrorMessage}>
               </Material>
                :
            <>
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/>
                </div>
                <br/><br/>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker disabled
                    className={classes.textField}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        eggDetail = {
                            ...eggDetail,
                            eggDate: moment(data).format("YYYY-MM-DD")
                        }
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <TextField disabled
                className={classes.textField}
                error={hasError('paymentDueDate')}
                helperText={
                    hasError('paymentDueDate') ? formState.errors.paymentDueDate[0] : null
                }
                label="Payment Due (in Days)"
                name="paymentDueDate"
                onChange={handleChange}
                type="number"
                value={formState.values.paymentDueDate || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            {formState.values.onLineSaleSwitch ?
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="lineSale-select-outlined-label">
                    Line Name
                </InputLabel>
                <Select
                    id="customerName-select"
                    value={formState.values.lineSaleIndex !== undefined ? formState.values.lineSaleIndex : ''}
                    onChange={handleChange}
                    name="lineSaleIndex"
                >
                    {lineSales !== undefined && lineSales !== null ?
                        lineSales.map((lineSale, index) =>
                            <MenuItem key={lineSale.id} value={index}>{lineSale.name}</MenuItem>
                        ) : ""}
                </Select>
            </FormControl>
            :
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="customerName-select-outlined-label">
                    Customer Name
                </InputLabel>
                <Select
                    id="customerName-select"
                    value={formState.values.customerIndex !== undefined ? formState.values.customerIndex : ''}
                    onChange={handleChange}
                    name="customerIndex"
                >
                    {customers !== undefined && customers !== null ?
                        customers.map((customer, index) =>
                            <MenuItem key={customer.id} value={index}>{customer.name}</MenuItem>
                        ) : ""}
                </Select>
                <Link
                    color="inherit"
                    href="#"
                    onClick={() =>{return  history.push("/customer-vendor-details")}}
                    className={classes.link}
                    style={{color:"#02a6c7"}}
                    > Add new Customer</Link>
            </FormControl>
            }
            <TextField disabled
                className={classes.textField}
                error={hasError('truckNumber')}
                helperText={
                    hasError('truckNumber') ? formState.errors.truckNumber[0] : null
                }
                label="Truck Number"
                name="truckNumber"
                onChange={event => { handleChange(event, changeToUpperCase) }}
                type="text"
                value={formState.values.truckNumber !== undefined ? formState.values.truckNumber : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            {/* <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">Tax Profiles</InputLabel>
              <Select
                disabled
                id="taxProfileNameIdx"
                value={
                  formState.values.taxProfileNameIdx !== undefined
                    ? formState.values.taxProfileNameIdx
                    : ""
                }
                onChange={event => { handleChange(event, setTaxValueFromTaxProfile(event, TaxProfiles, setFormState, formState,(taxPercentage)=>{
                    //adding a callback and receiving taxPercentage from it to display the tax value, as the tax field's onchange
                    //is not triggered when tax profile is selected
                   let obj = {
                       target:{
                           value:taxPercentage
                       }
                   }
                   if (TaxProfiles[event.target.value]["taxType"] === "TCS") {
                       onChangeTcsTaxValue(obj);
                   }
                   else {
                    onChangeTdsTaxValue(obj);
                    }
                })) }}
                name="taxProfileNameIdx"
              >
                {TaxProfiles !== undefined && TaxProfiles !== null
                  ? TaxProfiles.map((taxProfile, index) => (
                      <MenuItem key={taxProfile.id} value={index}>
                        {taxProfile.taxProfileName}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>
            {
            showTds?    
            <FormControl className={classes.formControl}>

            <InputLabel 
                id="tds"
            >
                TDS(%) {formState.values.tds !== undefined ? formState.values.tds : ''}
            </InputLabel>
            </FormControl>
            : ''
            }

            {
             showTcs?   
            <FormControl className={classes.formControl}>

            <InputLabel 
            id="tcs"
            >
                TCS(%) {formState.values.tcs !== undefined ? formState.values.tcs : ''}
            </InputLabel>
            </FormControl>
            : ''
            } */}
            <FormControlLabel  disabled
                className={classes.formControlLabelClass}
                control={
                    <Switch
                        // className={classes.switchClass}
                        checked={formState.values.onLineSaleSwitch}
                        //disabled={!rowData.isActive}
                        onChange={event => { handleChange(event,onLineSaleChange)}}
                        value={formState.values.onLineSale}
                        color="primary"
                        name="onLineSaleSwitch"
                        inputProps={{ 'aria-label': 'primary checkbox' }}/>
                }
                label="Line Sales"
            />
                
            <div className={classes.addButton} >
                <Button disabled variant="contained" color="primary" onClick={onNewMaterial}>Add Material</Button>
            </div>
            <MaterialTable
                columns={[
                    { title: 'id', field: 'id', hidden: true },
                    {
                        title: 'Item Name', field: 'materialName', editable: 'never',
                        render: (rowData) => { return <span>{rowData.materialName}</span> }
                    },
                    {
                        title: 'Inventory Type', field: 'inventoryType', editable: 'never',
                        render: (rowData) => {
                            return <span>{
                                rowData.inventoryType === 1 ? 'Feed Mill'
                                    : (rowData.farmName.String + ' (' + rowData.shedName.String + ')')
                            }
                            </span>
                        }
                    },
                    {
                        title: 'Quantity', field: 'quantity', editable: 'never',
                        render: (rowData) => {
                            return <span>{rowData.quantity + " (In " + rowData.materialUnit + ")"}</span>
                        }
                    },
                    ...quantityColumn,
                    { title: 'Sale Price', field: 'costPerUnit', editable: 'never' },
                    { title: 'Discount Per Unit', field: 'discountPerUnit', editable: 'never' },
                    {
                        title: 'Bill Value', field: 'billValue', editable: 'never',
                        render: (rowData) => {
                            return <span>{(rowData.billValue).toLocaleString(user.locales,amountObj)}</span>
                        },
                    }
                ]}
                style={{opacity: '0.6'}}
                
                data={saleMaterials || []}
                title='Sales Table'
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    
                    toolbar: false,
                    paging: false
                }}
                //style={{boxShadow: 'unset'}}
                actions={actions}
            />
            <Table className={classes.totalTable} >
                <TableHead>
                    <TableRow className={classes.totalRow}  >
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >Total Bill Value</TableCell>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalRow}>{billValueSum.toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >GST Total</TableCell>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}>{taxValue.toLocaleString(user.locales,amountObj) }</TableCell>
                    </TableRow>
                    {
                    showTds?
                    <TableRow className={classes.totalRow}  >
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >TDS</TableCell>
                        <TableCell className={classes.totalRow} >{(billValueSum * ( (TdsTaxValue || 0) / 100)).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>:''
                    }
                    {
                    showTcs?
                    <TableRow className={classes.totalRow}  >
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >TCS</TableCell>
                        <TableCell className={classes.totalRow} >{(billValueSum * ( (TcsTaxValue || 0) / 100)).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>:''
                    }
                    <TableRow className={classes.totalRow}  >
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >Final Total</TableCell>
                        <TableCell className={classes.totalRow} >{FinalTotalBillAmt.toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    {/* commenting for now */}
                    {/* <TableRow className={classes.totalRow}  >
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >Total With TDS</TableCell>
                        <TableCell className={classes.totalRow} >
                        {
                        billValueSum === 0 ?
                        (0).toLocaleString(user.locales,amountObj): 
                        (billValueSum+(((formState.values.tds || 0)*billValueSum)/100)).toLocaleString(user.locales,amountObj)
                        }
                        </TableCell>                    
                        </TableRow> */}
                </TableHead>
            </Table>

            </div>
                :
                <div>


          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    className={classes.textField}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        eggDetail = {
                            ...eggDetail,
                            eggDate: moment(data).format("YYYY-MM-DD")
                        }
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <TextField
                className={classes.textField}
                error={hasError('paymentDueDate')}
                helperText={
                    hasError('paymentDueDate') ? formState.errors.paymentDueDate[0] : null
                }
                label="Payment Due (in Days)"
                name="paymentDueDate"
                onChange={handleChange}
                type="number"
                value={formState.values.paymentDueDate || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            {formState.values.onLineSaleSwitch ?
            <FormControl className={classes.formControl}>
                <InputLabel id="lineSale-select-outlined-label">
                    Line Name
                </InputLabel>
                <Select
                    id="customerName-select"
                    value={formState.values.lineSaleIndex !== undefined ? formState.values.lineSaleIndex : ''}
                    onChange={handleChange}
                    name="lineSaleIndex"
                >
                    {lineSales !== undefined && lineSales !== null ?
                        lineSales.map((lineSale, index) =>
                            <MenuItem key={lineSale.id} value={index}>{lineSale.name}</MenuItem>
                        ) : ""}
                </Select>
            </FormControl>
            :
            <FormControl className={classes.formControl}>
                <InputLabel id="customerName-select-outlined-label">
                    Customer Name
                </InputLabel>
                <Select
                    id="customerName-select"
                    value={formState.values.customerIndex !== undefined ? formState.values.customerIndex : ''}
                    onChange={(e)=>{handleChange(e,getTaxProfile)}}
                    name="customerIndex"
                >
                    {customers !== undefined && customers !== null ?
                        customers.map((customer, index) =>
                            <MenuItem key={customer.id} value={index}>{customer.name}</MenuItem>
                        ) : ""}
                </Select>
                <Link
                    color="inherit"
                    href="#"
                    onClick={() =>{return  history.push("/customer-vendor-details")}}
                    className={classes.link}
                    style={{color:"#02a6c7"}}
                    > Add new Customer</Link>
            </FormControl>
            }
            {
            lineSaleCheck === false?
            <TextField
            className={classes.textField}
            error={hasError('truckNumber')}
            helperText={
                hasError('truckNumber') ? formState.errors.truckNumber[0] : null
            }
            label="Truck Number"
            name="truckNumber"
            onChange={event => { handleChange(event, changeToUpperCase) }}
            type="text"
            value={formState.values.truckNumber !== undefined ? formState.values.truckNumber : ''}
            variant="outlined"
            style={{backgroundColor:"#ffffff"}}
            />
            :
            <FormControl className={classes.formControl}>
            <InputLabel id="customerName-select-outlined-label">
                Truck
            </InputLabel>
            <Select
                id="truck-select"
                value={formState.values.truckIndex !== undefined ? formState.values.truckIndex : ''}
                onChange={handleChange}
                name="truckIndex"
            >
                {trucks !== undefined && trucks !== null ?
                    trucks.map((truck, index) =>
                        <MenuItem key={truck.id} value={index}>{truck.number}</MenuItem>
                    ) : ""}
            </Select>
        </FormControl>
            }

            {/* <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">Tax Profiles</InputLabel>
              <Select
                id="taxProfileNameIdx"
                value={
                  formState.values.taxProfileNameIdx !== undefined
                    ? formState.values.taxProfileNameIdx
                    : ""
                }
                onChange={event => { handleChange(event, setTaxValueFromTaxProfile(event, TaxProfiles, setFormState, formState,(taxPercentage)=>{
                    //adding a callback and receiving taxPercentage from it to display the tax value, as the tax field's onchange
                    //is not triggered when tax profile is selected
                   let obj = {
                       target:{
                           value:taxPercentage
                       }
                   }
                   if (TaxProfiles[event.target.value]["taxType"] === "TCS") {
                       onChangeTcsTaxValue(obj);
                   }
                   else {
                    onChangeTdsTaxValue(obj);
                    }
                })) }}
                name="taxProfileNameIdx"
              >
                {TaxProfiles !== undefined && TaxProfiles !== null
                  ? TaxProfiles.map((taxProfile, index) => (
                      <MenuItem key={taxProfile.id} value={index}>
                        {taxProfile.taxProfileName}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>
            {
            showTds?    
            <FormControl className={classes.formControl}>

            <InputLabel 
                id="tds"
            >
                TDS(%) {formState.values.tds !== undefined ? formState.values.tds : ''}
            </InputLabel>
            </FormControl>
            : ''
            }

            {
             showTcs?   
            <FormControl className={classes.formControl}>

            <InputLabel 
            id="tcs"
            >
                TCS(%) {formState.values.tcs !== undefined ? formState.values.tcs : ''}
            </InputLabel>
            </FormControl>
            : ''
            } */}

            <FormControlLabel 
                className={classes.formControlLabelClass}
                control={
                    <Switch
                        // className={classes.switchClass}
                        checked={formState.values.onLineSaleSwitch}
                        //disabled={!rowData.isActive}
                        onChange={event => { handleChange(event,onLineSaleChange)}}
                        value={formState.values.onLineSale}
                        color="primary"
                        name="onLineSaleSwitch"
                        inputProps={{ 'aria-label': 'primary checkbox' }}/>
                }
                label="Line Sales"
            />
                
            <div className={classes.addButton} >
                <Button variant="contained" color="primary" onClick={onNewMaterial}>Add Material</Button>
            </div>
            <MaterialTable
                columns={[
                    { title: 'id', field: 'id', hidden: true },
                    {
                        title: 'Item Name', field: 'materialName', editable: 'never',
                        render: (rowData) => { return <span>{rowData.materialName}</span> }
                    },
                    {
                        title: 'Inventory Type', field: 'inventoryType', editable: 'never',
                        render: (rowData) => {
                            return <span>{
                                rowData.inventoryType === 1 ? 'Feed Mill'
                                    : (rowData.farmName.String + ' (' + rowData.shedName.String + ')')
                            }
                            </span>
                        }
                    },
                    {
                        title: 'Quantity', field: 'quantity', editable: 'never',
                        render: (rowData) => {
                            return <span>{rowData.quantity + " (In " + rowData.materialUnit + ")"}</span>
                        }
                    },
                    ...quantityColumn,
                    { title: 'Sale Price', field: 'costPerUnit', editable: 'never' },
                    { title: 'Discount Per Unit', field: 'discountPerUnit', editable: 'never' },
                    {
                        title: 'Bill Value', field: 'billValue', editable: 'never',
                        render: (rowData) => {
                            return <span>{(rowData.billValue).toLocaleString(user.locales,amountObj)}</span>
                        },
                    },
                    { title: 'GST', field: 'gst', editable: 'never' ,
                    render: (rowData) => {
                        let gstTaxAmt = ((rowData.gst || 0)/100) * rowData.billValue
                        return <span>{gstTaxAmt.toLocaleString(user.locales,amountObj) }</span>
                    }
                    },
                ]}
                data={saleMaterials || []}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    
                    toolbar: false,
                    paging: false
                }}
                //style={{boxShadow: 'unset'}}
                actions={actions}
            />
            <Table className={classes.totalTable} >
                <TableHead>
                    <TableRow className={classes.totalRow}  >
                        <TableCell className={classes.totalCell} colSpan={2}>Bill Value</TableCell>
                        <TableCell className={classes.totalRow} colSpan={2}>{billValueSum.toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    {(taxValue > 0) &&
                        <TableRow className={classes.totalRow}>
                          <TableCell className={classes.totalCell}  colSpan={2}>GST</TableCell>
                          <TableCell className={classes.totalRow}   colSpan={2}>{taxValue.toLocaleString(user.locales,amountObj) }</TableCell>
                        </TableRow>
                    }
                    {
                    showTds &&
                    <TableRow className={classes.totalRow}  >
                        <TableCell className={classes.totalCell}  colSpan={2}>TDS</TableCell>
                        <TableCell className={classes.totalRow}   colSpan={2}>{(billValueSum * ( (TdsTaxValue || 0) / 100)).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    }

                    {
                    showTcs &&
                    <TableRow className={classes.totalRow}  >
                        <TableCell className={classes.totalCell}  colSpan={2}>TCS</TableCell>
                        <TableCell className={classes.totalRow}   colSpan={2}>{(billValueSum * ( (TcsTaxValue || 0) / 100)).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    }

                    <TableRow className={classes.totalRow}  >
                        <TableCell className={classes.totalCell} colSpan={2} >Final Total</TableCell>
                        <TableCell className={classes.totalRow}  colSpan={2} >{FinalTotalBillAmt.toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    {/* <TableRow className={classes.totalRow}  >
                        <TableCell className={classes.totalCell}  >TDS</TableCell>
                        <TableCell className={classes.totalRow} >{(((formState.values.tds || 0)*billValueSum)/100).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    <TableRow className={classes.totalRow}  >
                        <TableCell className={classes.totalCell}  >Total With TDS</TableCell>
                        <TableCell className={classes.totalRow} >
                        {
                        billValueSum === 0 ?
                        (0).toLocaleString(user.locales,amountObj): 
                        (billValueSum+(((formState.values.tds || 0)*billValueSum)/100)).toLocaleString(user.locales,amountObj)
                        }
                        </TableCell>
                    </TableRow> */}
                </TableHead>
            </Table>
            </div>
          }
          </>
         }
        </DialogContent>

        {showMaterialDialog ?

            <Material farms={farms} materialNames={materialNames} showError={showError}
                sheds={sheds} onGetShed={onGetShed}
                onGetFarmStorage ={onGetFarmStorage}
                farmStorageStock={farmStorageStock}
                onGetMaterialNames={onGetMaterialNames} materialTypes={materialTypes} eggDetail={eggDetail}
                onGetUnInvoicedQuantity={onGetUnInvoicedQuantity}
                lineSaleCheck={formState.values.onLineSaleSwitch }
                onEdit={onMaterialUpdate} data={currentMaterial} onSave={onMaterialSave} handleClose={handleMaterialClose}
                showDialog={showMaterialDialog} editMode={editMaterialMode} materialIndex={materialIndex} unInvoicedQuantity={unInvoicedQuantity} 
                gstTaxProfiles={gstTaxProfiles}
                handlechangeStorageStock={handlechangeStorageStock}
                >
            </Material>

            : null
        }

        <DialogActions>
            <Button
                className={classes.signInButton}
                color="primary"
                disabled={!formState.isValid || popupLoading}
                size="large"
                type="submit"
                variant="contained"
                onClick={onSubmit}
            >
                Save
            </Button>
        </DialogActions>

    </Dialog>
};


export default Sale;