import React, {useCallback, useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog, MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//import Mobile from './components/mobile'
import moment from 'moment';
import Purchase from "./components/purchase";
//import Icon from '@material-ui/core/Icon';
import PaymentIcon from '@material-ui/icons/Payment';
import PurchasePaymentDetails from "./components/purchasePaymentDetails";
import PurchaseDetails from './components/purchaseDetails'
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Icon from "@material-ui/core/Icon";
import GetAppIcon from '@material-ui/icons/GetApp';
import {useGlobalState} from '../../state';

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    red:{
        color:'red'
    },
    green:{
        color: 'green'
    },
    gridMargin:{
        margin:theme.spacing(4)
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        minWidth: 160,
    },
    searchButton:{
        color:'white',
        width:theme.spacing(21.25),
        backgroundColor:'#EF5350',
        '&:hover':{
            backgroundColor:'#E53935',
        },
        margin:theme.spacing(1),
        padding:theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom:theme.spacing(4)
    },
    success: {
        color:'white',
        backgroundColor:'#4CAF50',
        '&:hover':{
            backgroundColor:'#388E3C',
        },
        width:theme.spacing(13.75),
        margin:theme.spacing(1),
        padding:theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom:theme.spacing(4)
    }
}));
let currentPurchase = undefined;
let editMode = false;
const PurchasesView = ({showError, showMessage}) => {
    const [currentPurchasePayment, setCurrentPurchasePayment] = useState({});
    const [purchases, setPurchases] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [lineNames, setLineNames] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showPurchasePaymentDialog, setShowPurchasePaymentDialog] = useState(false);
    const [showPurchaseDetailsDialog, setShowPurchaseDetailsDialog] = useState(false);
    const theme = useTheme();

    const [filterSelectedStartDate, setFilterSelectedStartDate] = useState(null);
    const [filterSelectedEndDate, setFilterSelectedEndDate] = useState(null);
    const [filterSelectedVendorId, setFilterSelectedVendorId] = useState('');
    const [filterSelectedPaymentStatus, setFilterSelectedPaymentStatus] = useState('');
    const [selectedVendorId, setSelectedVendorId] = useState('');
    const [doSearch, setDoSearch] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [resetBillTotal, setResetBillTotal] = useState(false)


    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }

    const csvHeader = ["Date","Vendor Name","Line Name","Item Name", "Quantity(Trays)", "Cost Per Unit","Bill Value","TCS(%)","Total Bill Value","Payment Status","Balance"]


    const handleFilterStartDateChange = date => {
        setFilterSelectedStartDate(date);
    };

    const handleFilterEndDateChange = date => {
        setFilterSelectedEndDate(date);
    };

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const handleFilterVendorChange = (event) => {
        setSelectedVendorId(event.target.value);
        setFilterSelectedVendorId(vendors[event.target.value].id);
    };

    const handleFilterPaymentStatus = (event) => {
        setFilterSelectedPaymentStatus(event.target.value);
    };

    function onNewPurchase(event) {
        editMode = false;
        event.preventDefault();
        setShowPurchaseDialog(true);
        removeErrorMessage();       
    }

    function handlePurchaseClose() {
        currentPurchase = undefined;
        setErrorMessage(null);
        setShowPurchaseDialog(false);
        setResetBillTotal(false)
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);

    }

    function handlePurchasePaymentClose() {
        currentPurchase = undefined;
        setErrorMessage(null);
        setShowPurchasePaymentDialog(false);
    }

    function handlePurchaseDetailsDialogClose() {
        currentPurchase = undefined;
        setErrorMessage(null);
        setShowPurchaseDetailsDialog(false);
    }

    function openPurchasesDetailsDialog (event,rowData) {
        currentPurchase = rowData;
        setShowPurchaseDetailsDialog(true);
    }

    const onPurchaseDeleteConfirm = () => {
        if (currentPurchase && currentPurchase.id) {
            setShowDeleteConfirm(false);
            //setLoading(true);
            showError('');
            const response = api.delete(`line-purchase/${currentPurchase.id}`);

            response.then(res => {
                if (res) {
                    let index = purchases.findIndex((srcPurchase) => {
                        return srcPurchase.id === currentPurchase.id;
                    });
                    let newPurchases = [...purchases];
                    newPurchases.splice(index, 1);
                    setPurchases(newPurchases);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Purchase not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentPurchase = undefined;
            });

        }
    };

    const onDelete = (purchase) => {
        currentPurchase = purchase;
        setShowDeleteConfirm(true);
    };

    const onPurchaseSave = (purchase, setPopupLoading) => {
        console.debug(purchase);
       //setLoading(true);


        const response = api.post('line-purchase', {
            ...purchase,
        });

        response.then(res => {
            let totalAmount = 0
            if(res.linePurchasePayments !== undefined && res.linePurchasePayments !== null){
                res.linePurchasePayments.forEach((payment,index)=>{
                    totalAmount = totalAmount + payment.paymentAmount
                })
            }
            
            let tdsValue = res.totalBillValue * (res.tds / 100)

            if(totalAmount === 0){
                res = {
                    ...res,
                    paymentStatus : 0
               }
            }else if(totalAmount >= (res.totalBillValue + tdsValue)){
                res = {
                    ...res,
                    paymentStatus : 2
                }
            }else if(totalAmount < (res.totalBillValue + tdsValue)){
                res = {
                    ...res,
                    paymentStatus : 1
                }
            }
            res = {
                ...res,
                remainingAmount: totalAmount
            }
            setPurchases([...purchases, res]);
            setShowPurchaseDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Purchase not added');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };


   const onPurchaseEdit = (purchase) => {
        editMode = true;
            currentPurchase = {
                ...purchase,
                vendorIndex:vendors.findIndex((vendor)=>{return vendor.id === purchase.vendorId;}),
                linePurchaseIndex:lineNames.findIndex((linePurchase)=>{return linePurchase.name === purchase.lineName}),
                onLinePurchaseSwitch: purchase.movedStorageID,
            };
            console.log("on Purchase Edit Current Data", currentPurchase)
            setShowPurchaseDialog(true);
    };


    const onPaymentEdit = (purchase) => {
       setCurrentPurchasePayment({
            ...purchase
        })
        setShowPurchasePaymentDialog(true);
    };

    const onPurchaseUpdate = (purchase) => {
        setLoading(true);

        const response = api.post(`line-purchase/${purchase.id}`, {
            ...purchase,

        });
        response.then((res) => {
            let index = purchases.findIndex((srcPurchase) => {
                return srcPurchase.id === res.id;
            });
            let totalAmount = 0
            if(res.linePurchasePayments !== undefined && res.linePurchasePayments !== null){
                res.linePurchasePayments.forEach((payment,index)=>{
                    totalAmount = totalAmount + payment.paymentAmount
                })
            }
            let tdsValue = res.totalBillValue * (res.tds / 100)
            if(totalAmount === 0){
                res = {
                    ...res,
                    paymentStatus : 0
               }
            }else if(totalAmount >= (res.totalBillValue + tdsValue)){
                res = {
                    ...res,
                    paymentStatus : 2
                }
            }else if(totalAmount < (res.totalBillValue + tdsValue)){
                res = {
                    ...res,
                    paymentStatus : 1
                }
            }
            res = {
                ...res,
                remainingBalance: totalAmount
            }
            setPurchases([...purchases.slice(0, index),
                {...res},
                ...purchases.slice(index + 1)]);
            setShowPurchaseDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Purchase not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onPurchasePaymentUpdate = (payment, purchase,) => {
        //setShowPurchasePaymentDialog(false);
        setLoading(true);
        showError('');
        const response = api.post(`line-purchase-payment/${payment.id}`, {
            ...payment,

        });
        response.then(() => {
            let index = purchases.findIndex((srcPurchase) => {
                return srcPurchase.id === purchase.id;
            });
            let totalAmount = 0
            purchase.linePurchasePayments.forEach((payment,index)=>{
                totalAmount = totalAmount + payment.paymentAmount
            })

            let tdsValue = purchase.totalBillValue * (purchase.tds / 100)
            if(totalAmount === 0){
                purchase = {
                    ...purchase,
                    paymentStatus : 0
               }
            }else if(totalAmount >= (purchase.totalBillValue + tdsValue)){
                purchase = {
                    ...purchase,
                    paymentStatus : 2
                }
            }else if(totalAmount < (purchase.totalBillValue + tdsValue)){
                purchase = {
                    ...purchase,
                    paymentStatus : 1
                }
            }
            purchase = {
                ...purchase,
                totalAmountPaid: totalAmount
            }
    console.debug(purchase)
            setPurchases([...purchases.slice(0, index),
                {...purchase},
                ...purchases.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Purchase Payment not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onPurchasePaymentSave = (payment, setPopupLoading) => {
        //setShowPurchasePaymentDialog(false);
        //setLoading(true);
        showError('');
        const response = api.post(`line-purchase-payment`, {
            ...payment,

        });
        response.then((res) => {
            console.debug(res)
            let tempCurrentPurchase = currentPurchasePayment
           // tempCurrentPurchase.linePurchasePayments.push(res)
            if( tempCurrentPurchase.linePurchasePayments !== undefined &&  tempCurrentPurchase.linePurchasePayments !== null){
                tempCurrentPurchase.linePurchasePayments = [
                    ...tempCurrentPurchase.linePurchasePayments,
                    {...res}
                ]
            }else{
                tempCurrentPurchase.linePurchasePayments = [
                    {...res}
                ]
            }
            setCurrentPurchasePayment({...tempCurrentPurchase})
            let totalAmount = 0
            tempCurrentPurchase.linePurchasePayments.forEach((payment,index)=>{
            
                totalAmount = totalAmount + payment.paymentAmount
            })
            
            
            let tdsValue = tempCurrentPurchase.totalBillValue * (tempCurrentPurchase.tds / 100)
            if(totalAmount === 0){
                tempCurrentPurchase = {
                    ...tempCurrentPurchase,
                    paymentStatus : 0
               }
            }else if(totalAmount >= (tempCurrentPurchase.totalBillValue + tdsValue)){
                tempCurrentPurchase = {
                    ...tempCurrentPurchase,
                    paymentStatus : 2
                }
            }else if(totalAmount < (tempCurrentPurchase.totalBillValue + tdsValue)){
                tempCurrentPurchase = {
                    ...tempCurrentPurchase,
                    paymentStatus : 1
                }
            }
            tempCurrentPurchase = {
                ...tempCurrentPurchase,
                totalAmountPaid: totalAmount
            }
            console.debug(totalAmount, tempCurrentPurchase)

            let index = purchases.findIndex((srcPurchase) => {
                return srcPurchase.id === tempCurrentPurchase.id;
            });
            setPurchases([...purchases.slice(0, index),
                {...tempCurrentPurchase},
                ...purchases.slice(index + 1)]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('Purchase not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            // setPopupLoading(false);
        });
    };

    const onPurchasePaymentDelete = (payment, purchase) => {
        if (payment && payment.id) {
            //setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.post(`line-purchase-payment-delete/${payment.id}`, {
                ...payment,
            });
            response.then(res => {
                if (res) {
                    let index = purchases.findIndex((srcPurchase) => {
                        return srcPurchase.id === purchase.id;
                    });
                    let totalAmount = 0
                    purchase.linePurchasePayments.forEach((payment,index)=>{
                        totalAmount = totalAmount + payment.paymentAmount
                    })
                    let tdsValue = purchase.totalBillValue * (purchase.tds / 100)
                    if(totalAmount === 0){
                        purchase = {
                            ...purchase,
                            paymentStatus : 0
                        }
                    }else if(totalAmount >= (purchase.totalBillValue + tdsValue)){
                        purchase = {
                            ...purchase,
                            paymentStatus : 2
                        }
                    }else if(totalAmount < (purchase.totalBillValue + tdsValue)){
                        purchase = {
                            ...purchase,
                            paymentStatus : 1
                        }
                    }
                    purchase = {
                        ...purchase,
                        totalAmountPaid: totalAmount
                    }

                    setPurchases([...purchases.slice(0, index),
                        {...purchase},
                        ...purchases.slice(index + 1)]);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Purchase not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentPurchase = undefined;
            });

        }
    };
    //Get all Purchases API Call
    const getAllPurchases = useCallback(() => {
        if (filterSelectedEndDate !== null && filterSelectedStartDate !== null &&
            filterSelectedEndDate < filterSelectedStartDate) {
            showError('Start Date is Lesser than or Equal to End Date');
            return
        }
        let payload = {
            vendorId: parseInt(filterSelectedVendorId, 10),
            startDate: filterSelectedStartDate,
            endDate: filterSelectedEndDate,
        };
        setShowPurchaseDialog(false);
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.post('line-purchases',{...payload});
        console.log("Response from Server",response)
        response.then(res => {
            res.forEach((value)=>{
                
            })
            
            setPurchases([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Purchases Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [filterSelectedEndDate, filterSelectedStartDate, filterSelectedVendorId, showError, showMessage]);

    //Get all Banks API Call
    const getAllBanks = () => {
        showError('');
        showMessage('');

        const response = api.get('bank');

        response.then(res => {
            setBanks([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }

    //Get all vendors API Call
     const getAllVendors = () => {
        
        showError('');
        showMessage('');

        const response = api.get('get-line-vendors');

        response.then(res => {
            setVendors([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
     };

     //Get all IncomeType API Call
    const getAllLineSales = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('line_name');

        response.then(res => {
            setLineNames([...res]
            );

        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
     } 

     //Get all materials API Call
    const getAllMaterials = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('egg-materials');

        response.then(res => {
            setMaterials([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }
 
     const generateExcel = function (header, data) {
        console.log("excel ", header, data)
         let csvData = '';
        header.forEach((column, index) => {
            if (index === 0)
                csvData = csvData + column
            else
                csvData = csvData + ',' + column
        });
        
        csvData = csvData + '\r\n';
        
         
        data.map((row) => {
        
             let totalPaymentAmount = 0;
             let tempDate;
             let tempPaymentStatus;
             tempDate=moment(row.date).format("DD-MM-YYYY")
            if(row.paymentStatus === 1){
                tempPaymentStatus = "Partially Paid"
            }else if(row.paymentStatus === 2){
                tempPaymentStatus = "Paid"
            }else{
                tempPaymentStatus = "Unpaid"
            }
                if(row.linePurchasePayments !== null){
                    row.linePurchasePayments.map((value,index)=>{
                        totalPaymentAmount += parseInt(value.paymentAmount)
                        totalPaymentAmount = (row.totalBillValue + row.tds)-totalPaymentAmount
                        return "";
                    })
                }
            row.linePurchaseMaterials.map((value,index)=>{
                row.itemName = value.materialName
                row.quantity = value.quantity
                row.costPerUnit = value.costPerUnit
                row.billValue = value.billValue
                if(row.paymentStatus === 0){  
                    row.paymentAmount = row.totalBillValue + row.tds
                }else if(row.paymentStatus === 2){
                    row.paymentAmount = 0
                }else{
                    row.paymentAmount = totalPaymentAmount
                }
               /*  materialTypes.map((material)=>{
                    if(material.id === value.materialTypeId){
                        row.materialType = material.materialType
                    }
                    return "";
                }) */
                let column=[]

                column.push(tempDate,row.vendorName,row.lineName,row.itemName,row.quantity, row.costPerUnit,row.billValue,row.tds,
                    row.totalBillValue,tempPaymentStatus,row.paymentAmount)
               
                column.forEach((col, index) => {
                    if (index === 0)
                    csvData = csvData + col
    
                    else
                    csvData = csvData + ',' + col
    
                }); 
                csvData = csvData + '\r\n';
    
              
               return "";
            }); 

            return "";

    
            })
           
        //excel button
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
        element.setAttribute('download', 'report.csv');
    
        element.style.display = 'none';
        document.body.appendChild(element);
    
        element.click();
    
        document.body.removeChild(element);
    
    
    }

    

    const clearSearchFilter = () => {
        setFilterSelectedStartDate(undefined);
        setFilterSelectedEndDate(undefined);
        setFilterSelectedVendorId(undefined);
        setSelectedVendorId(undefined);
        setFilterSelectedPaymentStatus(undefined);
        setDoSearch(true);
    };

    useEffect(() => {
        //doSearch flag is set when search button is clicked.
        if (doSearch) {
            getAllPurchases();
            setDoSearch(false);
        }

    }, [doSearch, getAllPurchases]);

    const onload = () => {
        setLoading(true);
        Promise.all([getAllVendors(),
        getAllPurchases(),
        getAllLineSales(),
        getAllBanks(),
        getAllMaterials(),]).finally(()=>{
        setLoading(false);
        });
    };

    useEffect(onload, []);


    let purchaseNameColumn = {
        title: 'Vendor Name', field: 'vendorName', editable: 'never', defaultSort: 'asc',
        cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'}
    };
    let actions = [
        {
            icon: () => <PaymentIcon color='error'>payment</PaymentIcon>,
            tooltip: 'Payment Details',
            onClick: (event, rowData) => onPaymentEdit(rowData)
        },
        {
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit Purchase Details',
            onClick: (event, rowData) => onPurchaseEdit(rowData)
        },
        {
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete Purchase Details',
            onClick: (event, rowData) => onDelete(rowData)
        }
    ];


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Line Purchase Entries
                </Typography>
            </Grid>
            <Grid container
                  spacing={3}
                  justify="flex-end"
                  alignItems="center"
            >
                <Grid>
                    <Button variant="contained" color="primary" onClick={onNewPurchase}>Add Line Purchase</Button>
                </Grid>
                <Grid item spacing={1}>
                        <Button  variant="outlined" color="primary"
                            disabled={purchases.length < 1}
                            onClick={() => generateExcel( csvHeader,purchases)}
                            endIcon={<GetAppIcon />}>Download Excel</Button>
                    </Grid>
            </Grid>
        </Grid>
        <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    className={classes.formControl}
                    margin="normal"
                    autoOk
                    variant="inline"
                    label="From"
                    format="dd/MM/yyyy"
                    value={filterSelectedStartDate || null}
                    onChange={handleFilterStartDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardDatePicker
                    className={classes.formControl}
                    margin="normal"
                    autoOk
                    variant="inline"
                    label="To"
                    format="dd/MM/yyyy"
                    value={filterSelectedEndDate  || null}
                    onChange={handleFilterEndDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
                <FormControl  className={classes.formControl}>
                    <InputLabel id="vendorName-select-outlined-label">
                        Vendor Name
                    </InputLabel>
                    <Select
                    id="vendorName-select"
                    value={selectedVendorId  !== undefined ? selectedVendorId : ''}
                    onChange={handleFilterVendorChange}
                    name="vendorId"
                    >
                    {vendors !== undefined && vendors !== null ?
                        vendors.map((vendor,index)=>
                        <MenuItem key = {vendor.id} value={index}>{vendor.companyName}</MenuItem>
                    ):""}         
                    </Select>
                </FormControl>
                {true ?
                "" :
                <FormControl  className={classes.formControl}>
                    <InputLabel id="filterSelectedPaymentStatus-select-outlined-label">
                        Payment Status
                    </InputLabel>
                    <Select
                        id="filterSelectedPaymentStatus"
                        value={filterSelectedPaymentStatus !== undefined ? filterSelectedPaymentStatus : ''}
                        onChange={handleFilterPaymentStatus}
                        name="filterSelectedPaymentStatus"
                    >
                         <MenuItem key = {0} value={3}>Paid</MenuItem>
                        <MenuItem key = {1} value={2}>Partially Paid</MenuItem>
                        <MenuItem key = {2} value={1}>Not Paid</MenuItem>
                    </Select>
                </FormControl>
                }
            <Grid>
                <Button onClick={() => setDoSearch(true)} className={classes.success}>Search <SearchIcon/></Button>
                <Button onClick={clearSearchFilter} className={classes.searchButton}>Clear Search <CloseIcon/></Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {
                    title: 'Date', field: 'date', type: 'datetime', defaultSort: 'desc',
                    cellStyle: {textAlign: 'center'}, headerStyle: {textAlign: 'center'},
                    editable: 'never',
                    render: rowData => {
                        return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                    }
                },
                {title: 'id', field: 'id', hidden: true},
                purchaseNameColumn,
                { title: 'Line Name', field: 'lineName', hidden: !isDesktop, editable: 'never' },
                { title: 'Bill Value', field: 'totalBillValue', hidden: !isDesktop, editable: 'never', 
                cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                render: rowData => {
                    return <span>{(rowData.totalBillValue).toLocaleString(user.locales,amountObj)}</span>
                }},
                {
                    title: 'TCS', field: 'tcs', editable: 'never',
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        return <span>{(parseFloat((rowData.totalBillValue * (rowData.tcs / 100)).toFixed(2))).toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'TDS', field: 'tds', editable: 'never',
                    cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
                    render: rowData => {
                        return <span>{(parseFloat((rowData.totalBillValue * (rowData.tds / 100)).toFixed(2))).toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'Remaining Amount', field: 'remainingAmount', editable: 'never',
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    render: rowData => {
                        let tdsValue = rowData.totalBillValue * (rowData.tds / 100)
                        let tcsValue = rowData.totalBillValue * (rowData.tcs / 100)
                        let taxValue = rowData.totalBillValue * (rowData.tax / 100)

                        let reminingAmount = (((rowData.totalBillValue + tcsValue + taxValue)-tdsValue) - rowData.totalAmountPaid);

                        if (rowData.paymentStatus === 1 && reminingAmount < 1) {
                            reminingAmount = 0
                        }

                        return <span>{reminingAmount.toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'Payment Status', field: 'paymentStatus', editable: 'never',
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    render: (rowData) => {
                        let statusIcon = <Tooltip title="Not Paid"><CloseIcon className={classes.red} /></Tooltip>
                        
                        let tdsValue = rowData.totalBillValue * (rowData.tds / 100)
                        let tcsValue = rowData.totalBillValue * (rowData.tcs / 100)
                        let taxValue = rowData.totalBillValue * (rowData.tax / 100)
                        let remaningBalance = (((rowData.totalBillValue + tcsValue + taxValue)-tdsValue) - rowData.totalAmountPaid);

                        if (rowData.paymentStatus === 1 && remaningBalance >= 1) {
                            statusIcon = <Tooltip title="Partially Paid"><QueryBuilderIcon /></Tooltip>
                        } else if (rowData.paymentStatus === 2 || (rowData.paymentStatus === 1 && remaningBalance < 1)) {
                            statusIcon = <Tooltip title="Paid"><DoneIcon className={classes.green} /></Tooltip>
                        }

                        return <span>{statusIcon}</span>
                    }
                } 
            ]}
            data={purchases}
            title='Line Purchase Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true,
            }}
            onRowClick={openPurchasesDetailsDialog }
            actions={actions}
        />
        <Purchase deleteErrorMessage={removeErrorMessage} purchaseerrorMessage={errorMessage} showDialog={showPurchaseDialog} handleClose={handlePurchaseClose}  onSave={onPurchaseSave} onEdit={onPurchaseUpdate}
               editMode={editMode} data={currentPurchase} vendors={vendors}  lineNames={lineNames} materials = {materials} resetBillTotal={resetBillTotal} setResetBillTotal={setResetBillTotal}
              showError={showError} showMessage={showMessage} loading={loading} />

        <PurchasePaymentDetails showPaymentDetailsDialog={showPurchasePaymentDialog} handlePaymentDetailsClose={handlePurchasePaymentClose} 
               onPaymentEdit={onPurchasePaymentUpdate} data={currentPurchasePayment} banks={banks} onPurchasePaymentSave={onPurchasePaymentSave}
               onPurchasePaymentDelete = {onPurchasePaymentDelete}  loading={loading}/>

        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onPurchaseDeleteConfirm}
                       title='Delete Purchase ?'
                       subText='This action will delete the Purchase Details. Please confirm.'/>

        <PurchaseDetails handlePurchaseDetailsDialogClose={handlePurchaseDetailsDialogClose}
                         showPurchaseDetailsDialog={showPurchaseDetailsDialog}
                         data={currentPurchase}/>
    </div>
};

export default PurchasesView;