import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';

const schema = {
    amount: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    date: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    transactionTypeIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    bankIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },

    formControlMobile: {
        minWidth: 140,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '2'
    },
}));

const BankTransaction = ({ deleteErrorMessage, banks,errorMessage, farms, customers, transactionTypes, onEdit, data, onSave, handleClose, showDialog, editMode = false }) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [popupLoading, setPopupLoading] = useState(false) ;

    //update data for Editmode
    const resetBeforeEdit = () => {
        if (showDialog) {
            updateData(data, editMode);
        }
    };
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        setPopupLoading(true) 
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        const tempData = {
            ...formState.values,
            bankId: banks[formState.values.bankIndex].id,
            transactionTypeId: transactionTypes[formState.values.transactionTypeIndex].id,
            bankName: banks[formState.values.bankIndex].name,
            transactionTypeName: transactionTypes[formState.values.transactionTypeIndex].transactionTypeName,
            amount: formState.values.amount
        };
        if (editMode)
            onEdit(tempData, setPopupLoading);
        else
            onSave(tempData, setPopupLoading);
    };




    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title" >{editMode ? 'Update' : 'New'} Bank Transaction
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
        {
           
        <div>
             { popupLoading ?
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/>
                </div> : ""}


        <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
            <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    halfWidth
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    animateYearScrolling
                    name="date"
                    style={{ backgroundColor: "#ffffff" }}
                />
            </MuiPickersUtilsProvider>
            <FormControl className={isDesktop ? classes.formControl : classes.formControlMobile}>
                <InputLabel id="farmName-select-outlined-label">
                   Transaction Type
                </InputLabel>
                <Select
                    id="farmName-select"
                    value={formState.values.transactionTypeIndex !== undefined ? formState.values.transactionTypeIndex : ''}
                    onChange={handleChange}
                    name="transactionTypeIndex"
                >
                    {transactionTypes !== undefined && transactionTypes !== null ?
                        transactionTypes.map((transac, index) =>
                            <MenuItem key={transac.id} value={index}>{transac.transactionTypeName}</MenuItem>
                        ) : ""}
                </Select>
            </FormControl>
            <FormControl className={isDesktop ? classes.formControl : classes.formControlMobile}>
                <InputLabel id="customerName-select-outlined-label">
                    Bank
                </InputLabel>
                <Select
                    id="customerName-select"
                    value={formState.values.bankIndex !== undefined ? formState.values.bankIndex : ''}
                    onChange={data => {
                        handleChange(data)
                    }}
                    name="bankIndex"
                >
                    {banks !== undefined && banks !== null ?
                        banks.map((banks, index) =>
                            <MenuItem key={banks.id} value={index}>{banks.name}</MenuItem>
                        ) : ""}
                </Select>
            </FormControl>
            <TextField
                className={classes.textField}
                error={hasError('amount')}
                fullWidth
                helperText={
                    hasError('amount') ? formState.errors.amount[0] : null
                }
                label="Amount (in Rs.)"
                name="amount"
                onChange={handleChange}
                type="number"
                value={formState.values.amount || ''}
                variant="outlined"
                style={{ backgroundColor: "#ffffff" }}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>

}
{}

        </DialogContent>
    </Dialog>
};

export default BankTransaction;