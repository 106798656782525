import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog,MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import Bank from "./components/bank";
import Icon from '@material-ui/core/Icon';



const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentBank = undefined;
let editMode = false;

const BanksView = ({showError, showMessage, history}) => {

    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showBankDialog, setShowBankDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [accountTypes, setAccountTypes]= useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const [accountTypesObj,setAccountTypesObj] = useState({});
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    function onNewBank(event) {
        editMode = false;
        event.preventDefault();
        setShowBankDialog(true);
    }

    function handleBankClose() {
        setErrorMessage(null);
        currentBank = undefined;
        setShowBankDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const onBankDeleteConfirm = () => {
        if (currentBank && currentBank.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`bank/${currentBank.id}`);

            response.then(res => {
                if (res) {
                    let index = banks.findIndex((srcBank) => {
                        return srcBank.id === currentBank.id;
                    });
                    let newBanks = [...banks];
                    newBanks.splice(index, 1);
                    setBanks(newBanks);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Bank not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentBank = undefined;
            });

        }
    };

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onDelete = (bank) => {
        currentBank = bank;
        setShowDeleteConfirm(true);
    };

    const onBankSave = (bank, setpopuploading) => {
        //setLoading(true);
        // setShowBankDialog(false);
        // setErrorMessage('');
        showMessage('');
        const response = api.post('bank', {
            ...bank,
        });

        response.then(res => {
            setBanks([...banks, res]);
            setShowBankDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Bank not added');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onBankEdit = (bank) => {
        currentBank = {
            ...bank,
            accountTypeIndex: accountTypes.findIndex((account)=>{return account.ID === bank.accountTypeId;}),
        };
        editMode = true;
        setShowBankDialog(true);
        
    };

    const onBankUpdate = (bank) => {
        //setLoading(true);
        // showError('');
        const response = api.post(`bank/${bank.id}`, {
            ...bank,
        });
        response.then(() => {
            let index = banks.findIndex((srcBank) => {
                return srcBank.id === bank.id;
            });
            setBanks([...banks.slice(0, index),
                {...bank},
                ...banks.slice(index + 1)]);
            setShowBankDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Bank not Updated');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        })
    };

    //Get all Banks API Call
    const getAllBanks = () => {
        setShowBankDialog(false);
        showError('');
        showMessage('');

        const response = api.get('bank');

        response.then(res => {
          
            setBanks([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

    //Get All Account Types
    const getAllAccountTypes = () => {
        
        
        showError('');
        showMessage('');

        const response = api.get('accountTypes');
        response.then(res => {
            var localAccObj = {};
            res.forEach((rData)=>{
                localAccObj[rData.ID] = rData.Name;
            });

            setAccountTypesObj(localAccObj);
            setAccountTypes([...res]);

        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No AccountType Found Catch Error');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }


    const onload=()=>{
        setLoading(true);
        Promise.all([getAllBanks(),getAllAccountTypes()]).finally(()=>{
            setLoading(false);
        });
    }
    useEffect(onload, []);
    

    let bankNameColumn = {title: 'Bank', field: 'name', editable: 'never', defaultSort:'asc',
        render: rowData => <Mobile data={rowData}
                                   onEdit={() => {
                                       onBankEdit(rowData)
                                   }}
                                   onDelete={() => {
                                       onDelete(rowData)
                                   }}
        />
    };
    let actions = [];
    if (isDesktop) {
        bankNameColumn = {title: 'Name', field: 'name', editable: 'never', defaultSort:'asc'};
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Bank',
                onClick: (event, rowData) => onBankEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Bank',
                onClick: (event, rowData) => onDelete(rowData)
            }
        ]
    }


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Banks
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewBank}>Add Bank</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'id', hidden: true},
                bankNameColumn,
                {title: 'Account Number', field: 'bankAccountNumber', hidden: !isDesktop, editable: 'never'},
                {title: 'Account Type', field: 'accountType', hidden: !isDesktop, editable: 'never',render:(aData)=>{
                    try {
                        return <span>{accountTypesObj[aData.accountTypeId]}</span>
                    } catch (error) {
                        console.error('account type error : ',error);
                    }
                    return <span></span>
                }},
                {title: 'Opening Balance', field: 'openingBalance', hidden: !isDesktop, editable: 'never'},
                {title: 'Description', field: 'description', hidden: !isDesktop, editable: 'never'},
            ]}
            data={banks}
            title='Bank Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true
            }}
            actions={actions}
        />
        <Bank errorMessage={errorMessage} showDialog={showBankDialog} handleClose={handleBankClose} onSave={onBankSave} onEdit={onBankUpdate}
               editMode={editMode} data={currentBank} accountTypes={accountTypes} deleteErrorMessage={removeErrorMessage} />
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onBankDeleteConfirm}
                       title='Delete Bank ?'
                       subText='This action will delete the Bank Details. Please confirm.'/>
    </div>
}

export default BanksView;