import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import moment from 'moment';
import LabourCost from "./components/labourCost";
import Icon from '@material-ui/core/Icon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    breadcrumbs: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },

    red: {
        color: '#f44336'
    },
    green:{
        color:'#4caf50'
    },
    button: {
        marginLeft: theme.spacing(2),
        ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
            marginBottom: theme.spacing(1)
          }
    },
}));

let action = 'New';
const LabourPageView = ({ showError, showMessage, history,match }) => {
    const farmID = match.params['farmID'];
    const [labourCost,setLabourCost] = useState([])
    const [labourCostEditMode,setLabourCostEdit] = useState(false)
    const [currentLabourCost,setCurrentLabourCost] = useState({})
    const [loading, setLoading] = useState(false);
    const [showLabourCostDialog, setshowLabourCostDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);


    const [errorMessage, setErrorMessage] = useState('')
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    function onNewLabourCost(event) {
        action = 'New';
        event.preventDefault();
        setCurrentLabourCost({})
        setErrorMessage("")
        setshowLabourCostDialog(true);
    }

    function handleLabourCostClose() {
        setErrorMessage(null);
        setLabourCostEdit(false)
        setshowLabourCostDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onLabourCostDeleteConform = () => {
        if (labourCost && currentLabourCost.id) {
            setShowDeleteConfirm(false);
            //setLoading(true);
            showError('');
            const response = api.delete(`/farm/${farmID}/labour-cost/${currentLabourCost.id}`);

            response.then(res => {
                if (res) {
                    let index = labourCost.findIndex((labour) => {
                        return labour.id === currentLabourCost.id;
                    });
                    let newlabour = [...labourCost];
                    newlabour.splice(index, 1);
                    setLabourCost(newlabour);
                    getAllLabourCost();
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Labour cost not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                setCurrentLabourCost({})
            });

        }
    };

    const onLabourCostDelete = (labourCostData) => {
        setCurrentLabourCost(labourCostData)
        setShowDeleteConfirm(true);
    };



    const onLabourCostSave = (labourCostData, setpopuploading) => {
        const response = api.post(`/farm/${farmID}/labour-cost`, {
            ...labourCostData
        });

        response.then(res => {
            setLabourCost([...labourCost, res]);
            setshowLabourCostDialog(false);
            getAllLabourCost()
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found') {
                    setErrorMessage('Labour Cost not added');
                }
                else if (err.message === 'duplicate key') {
                    setErrorMessage('Duplicate Entry');
                }
                else {
                    setErrorMessage(err.message)
                }
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
            action = 'New'
        });
    };


    const onLabourCostEdit = (labourCost) => {
        setCurrentLabourCost(labourCost)
        action = 'Update';
        setLabourCostEdit(true)
        setErrorMessage("")
        setshowLabourCostDialog(true)
    };

    const onLabourCostUpdate = (labourCostData, setpopuploading) => {
        //setLoading(true);
        showError('');
        const response = api.post(`/farm/${farmID}/update-labour-cost`, {
            ...labourCostData
        });
        response.then(() => {
            let index = labourCost.findIndex((labour) => {
                return labour.id === labourCostData.id;
            });
            let newLabour = [...labourCost.slice(0, index),
            { ...labourCostData },
            ...labourCost.slice(index + 1)]

            setLabourCost(newLabour);
            setshowLabourCostDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('labour Cost not Updated');
                else if (err.message === 'duplicate key')
                    setErrorMessage('Duplicate Entry');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setLabourCostEdit(false)
            setpopuploading(false);
            action = 'New'
        });
    };

    //Get all LabourCost API Call
    const getAllLabourCost = () => {
        setshowLabourCostDialog(false);
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get(`/farm/${farmID}/labour-cost`);

        response.then(res => {
            setLabourCost([...res])
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Labour Cost Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }

    const onload = () => {
        setLoading(true);
        Promise.all([getAllLabourCost()]).finally(() => {
            setLoading(false);
        });
    }
    useEffect(onload, []);


    let labourCostColumn = {
        title: 'Labour Cost', field: 'date', defaultSort: 'desc',
        render: rowData => <Mobile
            data={rowData}
            onEdit={() => {
                onLabourCostEdit(rowData)
            }}
            onDelete={()=> {
                onLabourCostDelete(rowData)
            }}
            onLabourCostDelete={() => {
                onLabourCostDelete(rowData)
            }}
        />
    };
    let actions = [];
    if (isDesktop) {
        labourCostColumn = {
            title: 'Date', field: 'date', defaultSort: 'desc',
            type: 'datetime',
            editable: 'never',
            hidden: !isDesktop,
            render: rowData => {
                return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
            }
        };
        actions = [
            {
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Labour Cost',
                onClick: (event, rowData) => onLabourCostEdit(rowData)
            },
            {
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Labour Cost',
                onClick: (event, rowData) => onLabourCostDelete(rowData)
            }
        ]
    }


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Labour Cost Overview
                </Typography>
            </Grid>
            <Grid
                container
                justify={isDesktop?'flex-end':'center'}
                direction="row"
                alignItems='flex-end'
            >
                <Grid className={classes.button}>
                    <Button variant="contained" color="primary" onClick={onNewLabourCost}>Add Labour Cost</Button>
                </Grid>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}


            columns={[
                { title: 'id', field: 'id', hidden: true },
                labourCostColumn,
                { title: 'Male Labour Cost', field: 'maleCost', hidden: !isDesktop, editable: 'never' },
                { title: 'Female Labour Cost', field: 'femaleCost', hidden: !isDesktop, editable: 'never' },
                { title: 'Overtime Cost', field: 'overtimeCost', hidden: !isDesktop, editable: 'never' },

                {
                    title: 'Active', field: 'status', hidden: !isDesktop, editable: 'never',
                    render: rowData => {
                        return (
                            (rowData.status) ?
                                <FiberManualRecordIcon className={classes.green} />
                                :
                                <FiberManualRecordIcon className={classes.red} />
                        )
                    }
                },
            ]}
            title='Labour Cost Table'
            data={labourCost}
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                toolbar: true
            }}
            actions={actions}
        />
        <LabourCost 
            deleteErrorMessage={removeErrorMessage} 
            farmID={farmID} 
            labourCostData = {labourCost}
            errorMessage={errorMessage} 
            setErrorMessage={setErrorMessage}
            showDialog={showLabourCostDialog} 
            handleClose={handleLabourCostClose} 
            onSave={onLabourCostSave}     
            onEdit={onLabourCostUpdate}
            editMode={labourCostEditMode} 
            data={currentLabourCost}
            action={action} 
        />

        <ConfirmDialog 
            showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onLabourCostDeleteConform}
            title='Delete Labour Cost ?'
            subText='This action will delete the Labour Cost and Active labour cost will be based on the latest Date on record. Please confirm.' />
    </div>
}

export default LabourPageView;