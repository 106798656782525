import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
const useCardStyles = makeStyles((theme) => ({
  card: {
    minWidth: 275,
  },
  cardActionsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  cardAction: {
    margin: theme.spacing(0),
    marginTop: theme.spacing(2),
    backgroundColor: "#ff1744",
    color: "white",
  },
  gridMargin: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
}));

const Mobile = ({
  data,
  onDelete,
  onEdit,
  openVacSchedule,
  openStdProduction,
}) => {
  const classes = useCardStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    onDelete();
  };

  const handleEdit = () => {
    setAnchorEl(null);
    onEdit();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Grid item>
      <Card className={classes.card}>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          }
          title={data.name}
        />
        <CardContent>
          <Grid container justify="space-between" alignItems="flex-end">
            <Grid item class={classes.gridMargin}>
              <Typography variant="h5" component="h2">
                {data.materialTypeName}
              </Typography>
              <Typography color="textSecondary">Material Type</Typography>
            </Grid>
            <Grid item class={classes.gridMargin}>
              <Typography variant="h5" component="h2">
                {data.materialName}
              </Typography>
              <Typography color="textSecondary">Material Name</Typography>
            </Grid>
            <Grid item class={classes.gridMargin}>
              <Typography variant="h5" component="h2">
                {data.metabolizableEnergy}
              </Typography>
              <Typography color="textSecondary">M.E.</Typography>
            </Grid>
            <Grid item class={classes.gridMargin}>
              <Typography variant="h5" component="h2">
                {data.protein}
              </Typography>
              <Typography color="textSecondary">Protein</Typography>
            </Grid>
            <Grid item class={classes.gridMargin}>
              <Typography variant="h5" component="h2">
                {data.fat}
              </Typography>
              <Typography color="textSecondary">Fat</Typography>
            </Grid>
            <Grid item class={classes.gridMargin}>
              <Typography variant="h5" component="h2">
                {data.fibre}
              </Typography>
              <Typography color="textSecondary">Fibre</Typography>
            </Grid>
            <Grid item class={classes.gridMargin}>
              <Typography variant="h5" component="h2">
                {data.calcium}
              </Typography>
              <Typography color="textSecondary">Calcium</Typography>
            </Grid>
            <Grid item class={classes.gridMargin}>
              <Typography variant="h5" component="h2">
                {data.phosphorus}
              </Typography>
              <Typography color="textSecondary">Phosphorus</Typography>
            </Grid>
            <Grid item class={classes.gridMargin}>
              <Typography variant="h5" component="h2">
                {data.lysine}
              </Typography>
              <Typography color="textSecondary">Lysine</Typography>
            </Grid>
            <Grid item class={classes.gridMargin}>
              <Typography variant="h5" component="h2">
                {data.methionine}
              </Typography>
              <Typography color="textSecondary">Methionine</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Menu
        id="sales-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </Grid>
  );
};

export default Mobile;
