import React, {useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import {MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Switch from '@material-ui/core/Switch';
import {useGlobalState} from '../../state';
import Alert from '@material-ui/lab/Alert';
import {version} from "../../common/enums"
const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));
const ConfigView = ({showError}) => {
    const [{user}, dispatch] = useGlobalState();
    // console.log("user from config",user)
    const [loading, setLoading] = useState(false);
    const [configData, setConfigData] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    function tableData(){
        showError('')
        let arr=[];
        for (const [key, value] of Object.entries(user.Config)) {
            if(user.versionID === version.proPlus){
                arr.push({name:key,value:value,label:key.toUpperCase().replace(/_/g, ' ')});
            } else  if((user.versionID === version.pro) && ((key === "truckeggs_as_goodEggs") )) {
                arr.push({name:key,value:value,label:key.toUpperCase().replace(/_/g, ' ')});
            }
           
        }
        setConfigData(arr);
    }
    if(configData.length===0){
        tableData();
    }
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    const onUpdatingConfig = (config) => {
        setLoading(true);
        showError('');
        const req = {
            name : config.name,
            value : !config.value
        };
        const response = api.post(`updateConfiguration`, req);
        response.then(() => {
            dispatch({type:"config",payload:config});
            tableData();
            // console.log("state config after updating",user.Config)
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Subscriber not Updated');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(()=>{
            setLoading(false);
        })
    };

    const handleIsToggleChange = (rowData) =>{
        onUpdatingConfig(rowData);
    };
    const removeErrorMessage = () =>{
        setErrorMessage('');
    }

    return <div className={classes.root}>
        {errorMessage ?
    <Alert severity="error" onClick={removeErrorMessage}>{errorMessage}</Alert>
    : ''}
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Configuration
                </Typography>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                // {title: 'id', field: 'id', hidden: true},
                {title: 'Name', field: "label", hidden: false, editable: 'never'},
                {title: 'Show/Hide', field: 'value', hidden: false, editable: 'never', 
                render : rowData=>{
                    return <Switch
                        checked={rowData.value}
                        onChange={()=>handleIsToggleChange(rowData)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                }},
            ]}
            data={configData}
            title='Configuration Table'
            options={{
                actionsColumnIndex: -1,
                search: false,
                showTitle: false,

                toolbar: true
            }}
        />
    </div>
}

export default ConfigView;