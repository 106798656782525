const generateExcel = function (header, data,fileName='feedMaterials.csv') {
    let csvData = '';
    header.forEach((column, index) => {
        if (index === 0)
            csvData = csvData + column
        else
            csvData = csvData + ',' + column
    });
    csvData = csvData + '\r\n';
    data.forEach(row => {
        //converting row object to array
        var result = Object.keys(row).map(function (key) {
            return row[key];
        });
        //removing the id from the result array
        result.shift();
        result.forEach((column, index) => {
            if (index === 0)
                csvData = csvData + column
            else
                csvData = csvData + ',' + column
        });
        csvData = csvData + '\r\n';
    });

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);


}



export {
    generateExcel
}