import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import moment from 'moment';

const useCardStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    cardActionsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    cardAction:{
        margin:theme.spacing(0),
        marginTop:theme.spacing(2),
        backgroundColor : '#ff1744',
        color : 'white'
    },
    grid :{
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(2)
    }
}));

const Mobile = ({data,onDelete,onEdit,openVacSchedule,openStdProduction}) => {
    const classes = useCardStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        onDelete();
    };

    const handleEdit = () => {
        setAnchorEl(null);
        onEdit();


    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    return <Grid item>
        <Card className={classes.card}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                }
                title={`Date : ${moment(data.date).format("DD-MM-YYYY")}`}
            />
            <CardContent>
                <Grid
                    container
                    justify='space-between'
                    alignItems='flex-end'
                >
                     <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.id}
                        </Typography>
                        <Typography color="textSecondary">
                            ID
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.transactionTypeName}
                        </Typography>
                        <Typography color="textSecondary">
                        Transaction Type
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.bankName}
                        </Typography>
                        <Typography color="textSecondary">
                        Bank
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Typography variant="h5" component="h2">
                            {data.amount}
                        </Typography>
                        <Typography color="textSecondary">
                            Amount (in Rs.)
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Menu
            id="sales-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
    </Grid>
};

export default Mobile;