import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import api from "../../../common/api";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0.7),
    minWidth: 120,
    paddingBottom: theme.spacing(1.25)
  },
  textField: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(0)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  datePicker: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    minWidth: 160
  }
}));

const BatchesListBox = ({
  showError,
  match,
  setValid,
  getChosenBatch,
  setLoading
}) => {
  const theme = useTheme();

  const farmID = match.params["farmID"];
  const [batches, setBatches] = useState([]);
  const [batchIndex, setBatchIndex] = useState("");
  const [includeOnlyInActiveBatch, setIncludeOnlyInActiveBatch] = useState(false);
  const [originalBatches, setOriginalBatches] = useState([]);
  const [activeBatches, setActiveBatches] = useState([]);



  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  });

  const classes = useStyles({ isDesktop });

  const loadBatchList = function() {
    showError("");
    setLoading(true);
    const response = api.get(`farm/${farmID}/batch`);
    //const response = api.get(`farm/20/batch`);
    response
      .then(res => {
        setOriginalBatches([
          ...res
        ])
        console.debug("event ",  res)
        if (includeOnlyInActiveBatch) {
          setBatches(res)
        } else {
          let tempBatches = []
          res.forEach(element => {
            console.debug(element)
            if (element.isActive) {
              console.debug("isActive")
              tempBatches = ([
                ...tempBatches,
                {
                  ...element
                }
              ]);
            }
          });
          setBatches(tempBatches)
          setActiveBatches(tempBatches)

        }
      })
      .catch(err => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError(err.message);
        } else {
          showError("Unknown Error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(loadBatchList, [showError]);
  useEffect(() => {
    if (batchIndex !== "") {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [batchIndex, setValid]);

  const onChangeOfBatches = function(index) {
    setBatchIndex(index);
    getChosenBatch(batches[index]);
  };

  const handleIncludeInActiveBatch = function (event) {
    console.debug("event ", event.target.checked, originalBatches)
    setIncludeOnlyInActiveBatch(event.target.checked);
    if (event.target.checked) {
      let tempInactiveBatches = []
      originalBatches.forEach(element => {
        console.debug(element)
        if (!element.isActive) {
          console.debug("isInactive")
          tempInactiveBatches = ([
            ...tempInactiveBatches,
            {
              ...element
            }
          ]);
        }
      });
      setBatches(tempInactiveBatches)
    } else {
      setBatches(activeBatches)    
    }
  };

  return (
    <Grid>
    <Grid item xs={12}>
      <FormControl className={classes.formControl}>
        <InputLabel id="batchName-select-label">Batch</InputLabel>
        <Select
          id="batchName-select"
          value={batchIndex !== undefined ? batchIndex : ""}
          onChange={event => onChangeOfBatches(event.target.value)}
          name="batchIndex"
        >
          {batches.map((batch, index) => (
            <MenuItem key={batch.id} value={index}>
              {batch.name+ " (" + batch.shedName +")"}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={includeOnlyInActiveBatch}
              onChange={handleIncludeInActiveBatch}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label="Show Inactive Batches only"
        />
      </Grid>
      </Grid>
  );
};

export default BatchesListBox;
