import React,{useState, useEffect} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Container,
    TextField,
    Typography,
    CardActions,
    DialogActions,
  } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { Avatar } from '@material-ui/core';
import api from '../../common/api';
import { useGlobalState } from '../../state';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from "@material-ui/core/DialogContent";
import Chip from "@material-ui/core/Chip";
import UpdateIcon from '@material-ui/icons/Update';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {parse} from 'query-string';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';

import { version } from "../../common/enums";

var queryString = {};
const parsed = parse(window.location.search);
console.log(parsed);
queryString = parsed;

  const useStyles = makeStyles(theme => ({
    avatar: {
      width: 100,
      height: 100,
  },
  root:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name:{
    textAlign:'center',
    paddingTop:10
  },
  bioo:{
    textAlign:'center',
  },
  gridpadding:{
    width: 250 ,
    paddingRight:theme.spacing(2),
    paddingBottom:theme.spacing(2)
  },
  selectOption: {
    width:235
},
progressContainer: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%'
},
progressBar: {
  margin: theme.spacing(2),
  position: 'absolute',
  top: '65%',
  zIndex: '9999'
},
ChipPadding:{
  padding: '15px',
}
}));

const userDetailState = {
  profilePic:"",
	firstName: "",
	lastName: "",
  email: "",
	phoneNumber: "",
  designation:"",
  companyPic:"",
  countryNameID: {
    presence:(queryString.r !== undefined && queryString.r !== "" ? false : 
    {allowEmpty: false, message: 'is required'}),        
},
}
  
const Profilepage = ({showError}) => {
  const [baseImage, setBaseImage] = useState(userDetailState.profilePic);
  const [companyBaseImage, setCompanyBaseImage] = useState(userDetailState.profilePic);
  const [showDeletionDialog,setDeletionDialog] = useState(false)
  const [uploadedCompanyFileName, setUploadedCompanyFileName] = useState('');
  const uploadImage = async(event)=>{
    const file = event.target.files[0];
   const base64 = await convertBase64(file) 
   console.log("base64",base64)
    setBaseImage(base64)
    console.log("baseimage",baseImage)
  }
  const uploadCompanyImage = async(event)=>{
    const file = event.target.files[0];
    if (file){
      setUploadedCompanyFileName(file.name);
    }
    const base64 = await convertBase64(file) 
    setCompanyBaseImage(base64)
  }
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
});
  
  const convertBase64=(file)=>{
    return new Promise((resolve,reject)=>{
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload=()=>{
          resolve(fileReader.result);
        }
        fileReader.onerror =(error)=>{
          reject(error)
        }
      })
  }
  const classes = useStyles();
  
  const [values, setValues] = useState(userDetailState);
      const handleChange = (event) => {
        setValues({
          ...values,
          [event.target.name]:event.target.value
      })
    };
   
    
    const getUserDetail = () => {
      const response = api.get('user-detail');
      response.then((res) => {
        console.log("RESPONSE", res)
        setValues(res)
      })
    }


    const onLoad = () => {
      showError("")
      Promise.all([
          getUserDetail()
      ]).finally(() => {
      });
  };
  
  const [{user}] = useGlobalState();
useEffect(onLoad, [])
async function readFromQueryString(){
  /* const parsed = parse(props.location.search);
   console.log(parsed);
   setQueryString(parsed);*/
}
useEffect(()=>{
  getAllCountryName()
  readFromQueryString()
},[]);
const [popupLoading, setPopupLoading] = useState(false);

      const onDelete = (event) => {
        setDeletionDialog(true)
      }
      const onSubmit = (event) => {
        setUpdateMessage(true)
        setPopupLoading(true)
        event.preventDefault();
        console.log("baseImage",baseImage)
        const response = api.post('profilepage', {
          profilePic:baseImage,    
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email ,
          phoneNumber: values.phoneNumber,
          designation:values.designation,
          countryNameID: values.countryNameID,
          companyPic:companyBaseImage,    
        }); 
        response.then(res => {
          getUserDetail()
        }).catch(err => {
          if (err.message) {
              if (err.message === 'Bad Request' || err.message === 'No data found')
                  setUpdateMessage('Not Updated');
          }
      }).finally(() => {
        setPopupLoading(false);
        
      });
       }
       
       const [countryNames, setCountryNames]= useState([]);
        //Get all countries API Call
    async function getAllCountryName () {
      const response = api.get('country-names');
      response.then(res => {
          setCountryNames([...res]);
      })
      return response;
    }
      const [updateMessage, setUpdateMessage] = useState('')
      const deleteUpdateMessage = () =>{
      setUpdateMessage('');
  }
      return (
<DialogContent>
{//eslint-disable-next-line
values.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)===null ? <Chip  color="secondary"  icon={<HelpOutlineIcon/>} label="Valid Email id is required" /> : ""}
        {
          popupLoading ?
          <div>
            <div className={classes.progressContainer}>
                    <CircularProgress  className={classes.progressBar}/ >
              </div>  
              <br/><br/>
              <form
          autoComplete="off"
          noValidate
        >
          <Container maxWidth="sm"> 
            <Grid 
              item
              xs
            >
              <Card
               >
          <CardContent>
      <Grid
      className={classes.root}
      >
      <Avatar
      src={baseImage}
      style={{opacity: '0.6'}}
      alt="Profile"
      className={classes.avatar}
    />
    </Grid>
 <Grid
 className={classes.name}>
    <Typography
      color="textPrimary"
      gutterBottom
      variant="h3"
      style={{opacity: '0.6'}}
    >
      {values.firstName +" " + values.lastName}
    </Typography>
    </Grid>
  
    <Grid className={classes.bioo}>
      <Typography
      disabled
      color="textSecondary"
      variant="body1"
    >
      { user.role ||  'Managing Director'}
    </Typography>
                </Grid>
            </CardContent>  
    <Divider />
    <CardActions>
    <input  style={{opacity: '0.6'}} name="profilePic"  type="file" accept="image/*;capture=camera" onChange={(event)=>{
      uploadImage(event);
    }} />
      </CardActions>
      </Card>
          <Grid
            item  
            xs
          >
          <Card>
            <CardHeader
              // subheader="The information can be edited"
            />
            <Divider />
            <CardContent>
              
                <Grid container direction="row"
                >
                  <TextField
                    helperText="Please specify the first name"
                    label="First name"
                    name="firstName"
                    onChange={handleChange}
                    disabled
                    required
                    className={classes.gridpadding}
                    value={values.firstName}
                    variant="outlined"
                  />
                  <TextField
                    label="Last name"
                    name="lastName"
                    onChange={handleChange}
                    disabled
                    required
                    className={classes.gridpadding}
                    value={values.lastName}
                    variant="outlined"
                  />
                 <TextField
                    label="Email Address"
                    name="email"
                    disabled
                    // onChange={handleChange}
                    required
                    className={classes.gridpadding}
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    label="Phone Number"
                    name="phoneNumber"
                    disabled
                    onChange={handleChange}
                    // type="number"
                    className={classes.gridpadding}
                    value={values.phoneNumber}
                    variant="outlined"
                  />
                  <TextField
                    label="Designation"
                    disabled
                    name="designation"
                    onChange={handleChange}
                    className={classes.gridpadding}
                    value={values.designation}
                    variant="outlined"
                  />
                  <FormControl variant="outlined" >
                                <InputLabel disabled>Country</InputLabel>
                                <Select
                                  className={classes.selectOption}
                                  id="countryList"
                                  value={values.countryNameID}
                                  disabled
                                  onChange={handleChange}
                                  name="countryNameID"
                                  label="Country"
                                >
                                  {countryNames !== undefined && countryNames !== null ?
                                    countryNames.map((country, index) =>
                                      <MenuItem key={country.ID} value={index}>{country.countryName}</MenuItem>
                                    ) : ""}
                                </Select>
                              </FormControl>
                </Grid>
                  
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: isDesktop ? 'flex' :"grid",
                justifyContent: isDesktop ?'space-between' : "start",
                p: 2
              }}
            >
              <DialogActions>
              <Button
                color="secondary"
                variant="contained"
                onClick={onDelete}
              >
               Delete My Account
              </Button>
              </DialogActions>
              <DialogActions
                style={{
                  justifyContent: !isDesktop ? 'start' : "flex-end",
                }}
              >


              <Button
                color="primary"
                variant="contained"
                //eslint-disable-next-line
                disabled={values.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)===null}
                onClick={onSubmit}
              >
                Save details
              </Button>
              </DialogActions>
            </Box>
          </Card>
          </Grid>
        </Grid>
        </Container>
  </form> 
              </div>
              :
              <>
          <Dialog open={showDeletionDialog} onClose={() => {
                  setDeletionDialog(false)
                }} aria-labelledby="form-dialog-title">
                  <DialogTitle id="alert-dialog-title">Account Deletion</DialogTitle>
                  <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        To delete your data permanently please send an email to admin@poultryproplus.com
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={()=>{ setDeletionDialog(false) }} color="primary" autoFocus>
                        ok
                      </Button>
                  </DialogActions>
              </Dialog>
             <div className={classes.ChipPadding}>
{updateMessage?<Chip  color="secondary" onDelete={deleteUpdateMessage} icon={<UpdateIcon/>} label="Updated. Please logout and login again for your changes to become effective" /> : ""}
</div>
        <form
          autoComplete="off"
          noValidate
        >
          
          <Container maxWidth="sm"> 
            <Grid
              item
              xs
            >
              <Card
               >
          <CardContent>
      <Grid
      className={classes.root}
      >
        
        <Avatar
      
        src={baseImage}
        alt="Profile"
        className={classes.avatar}
      />
    </Grid>
    
 <Grid
 className={classes.name}>
    <Typography
      color="textPrimary"
      gutterBottom
      variant="h3"
    >
      {values.firstName +" " + values.lastName}
    </Typography>
    </Grid>
  
    <Grid className={classes.bioo}>
      <Typography
      color="textSecondary"
      variant="body1"
    >
      { user.role ||  'Managing Director'}
    </Typography>
                </Grid>  
    
            </CardContent>
    <Divider />
    {
      isDesktop ?

    <CardActions>
      <form style={{width:"100%"}} enctype="multipart/form-data">
        <div style={{display:"flex",justifyContent:"space-between"}}>



        <div>
            <Button
              variant="contained"
              component="label"
             
            >
              Upload Profile
              <input
                name="profilePic"
                type="file"
                accept="image/*"
                hidden
                onChange={(event)=>{
                  uploadImage(event);
              }}
              />
            </Button>
      </div>
    {
      user.versionID === version.pro ? 

      <div>
            <Button
              variant="contained"
              component="label"
            >
              {uploadedCompanyFileName ? `Upload: ${uploadedCompanyFileName}` : 'Upload Company Logo'}
              <input
                name="companyImage"
                type="file"
                accept="image/*"
                hidden
                onChange={(event)=>{
                  uploadCompanyImage(event);
              }}
              />
            </Button>
      </div>
      
        :""
        
    }
       </div>
      </form>
      </CardActions> 
      : ""
    }
      </Card>
          <Grid
            item  
            xs
          >
          <Card>
            {
              isDesktop ?  <CardHeader /> : ""
            }
           
            <Divider />
            <CardContent>
              
                <Grid container direction="row"
                >
                  <TextField
                    helperText="Please specify the first name"
                    label="First name"
                    name="firstName"
                    onChange={handleChange}
                    required
                    className={classes.gridpadding}
                    value={values.firstName}
                    variant="outlined"
                  />
                  <TextField
                    label="Last name"
                    name="lastName"
                    onChange={handleChange}
                    required
                    className={classes.gridpadding}
                    value={values.lastName}
                    variant="outlined"
                  />
                 <TextField
                    label="Email Address"
                    autoComplete='email'
                    name="email"
                    onChange={handleChange}
                    required
                    className={classes.gridpadding}
                    value={values.email}  
                    variant="outlined"
                  />
                  <TextField
                    label="Phone Number"
                    name="phoneNumber"
                    onChange={handleChange}
                    // type="number"
                    className={classes.gridpadding}
                    value={values.phoneNumber}
                    variant="outlined"
                  />
                  <TextField
                    label="Designation"
                    name="designation"
                    onChange={handleChange}
                    className={classes.gridpadding}
                    value={values.designation}
                    variant="outlined"
                  />
                              <FormControl variant="outlined" >
                                <InputLabel>Country</InputLabel>
                                <Select
                                  className={classes.selectOption}
                                  id="countryList"
                                  value={values.countryNameID}
                                  onChange={handleChange}
                                  name="countryNameID"
                                  label="Country"
                                >
                                  {countryNames !== undefined && countryNames !== null ?
                                    countryNames.map((country, index) =>
                                      <MenuItem key={country.ID} value={index+1}>{country.countryName}</MenuItem>
                                    ) : ""}
                                </Select>
                              </FormControl>
                </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: isDesktop ? 'flex' :"grid",
                justifyContent: isDesktop ?'space-between' : "start",
                p: 2
              }}
            >
              <DialogActions>
              <Button
                color="secondary"
                variant="contained"
                onClick={onDelete}
              >
               Delete My Account
              </Button>
              </DialogActions>
              <DialogActions
                style={{
                  justifyContent: !isDesktop ? 'start' : "flex-end",
                }}
              >


              <Button
                color="primary"
                variant="contained"
                //eslint-disable-next-line
                disabled={values.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)===null}
                onClick={onSubmit}
              >
                Save details
              </Button>
              </DialogActions>
            </Box>
          </Card>
          </Grid>
        </Grid>
        </Container>
  </form> 
  </>
            } 
            </DialogContent>
      );
    };
export default Profilepage;