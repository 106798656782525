import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import useSearchStyles from "../../../common/searchStyle"
import Switch from '@material-ui/core/Switch';
const schemaAll = {
    farmIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    shedIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    date: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    quantity: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThan: 0,
            onlyInteger: true,
        },
    },
    deliveryWeight: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThan: 0
        }
    }
};

const schemaDeliveryWeight = {
    farmIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    shedIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    date: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    quantity: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThan: 0
        }
    }
};

const schemaQuantity = {
    farmIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    shedIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    date: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    quantity: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThan: 0
        }
    }
};


const schemaStorage = {
    date: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    quantity: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThan: 0
        }
    },
    feedStorageIndex:{
        presence: { allowEmpty: false, message: 'is required' }
    },
};
var schema = {
    ...schemaAll
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    datePicker: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth: 160,
    },
    formControlSwitch: {
        display: 'flex',
        fontSize:"15px"
    },
    formControlSwitchLabel: {
        marginTop: "10px",
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },
}));

const Dailyentry = ({deleteErrorMessage, errorMessage, onEdit, data, onSave, handleClose, showDialog, editMode, farms, sheds, onFarmChange,storageformula }) => {
    const classes = useStyles();
    // eslint-disable-next-line
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [popupLoading, setPopupLoading] = useState(false) ;
    const [isStorage,setIstorage] = useState(false)
    //const [deliveryWeightDisable, setDeliverWeightDisable] = useState(false)
    //const [quantityDisable, setQuantityDisable] = useState(false)
    const closeButtonClass = useSearchStyles() 
    const resetBeforeEdit = () => {
        //setDeliverWeightDisable(false)
        //setQuantityDisable(false)
        if (showDialog) {
            setPopupLoading(false);
            console.debug(data)
            if (editMode){
                if (data.isStorage) {
                    schema = schemaStorage
                    setIstorage(true)
                } else {

                    if ((data.deliveryWeight === 0 || data.deliveryWeight === null) &&
                        (data.quantity === 0 || data.quantity === null)) {
                        schema = schemaAll
                    } else if ((data.deliveryWeight !== 0 || data.deliveryWeight !== null) &&
                        (data.quantity !== 0 || data.quantity !== null)) {
                        schema = schemaAll
                    } else if ((data.deliveryWeight === 0 || data.deliveryWeight === null) &&
                        (data.quantity !== 0 || data.quantity !== null)) {
                        schema = schemaQuantity
                    } else if ((data.deliveryWeight !== 0 || data.deliveryWeight !== null) &&
                        (data.quantity === 0 || data.quantity == null)) {
                        schema = schemaDeliveryWeight
                    } 
                }
            }else {           
                setIstorage(false)
            }
            updateData(data, editMode);
            console.debug(schema)
        }
    };

    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        setPopupLoading(true)
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        let tempData;
        if (isStorage) {
            tempData = {
                ...formState.values,
                isStorage:isStorage,
                FeedStorageID:storageformula[formState.values.feedStorageIndex].id,
                feedStorageName:storageformula[formState.values.feedStorageIndex].formulaName,
                deliveryWeight: parseFloat(formState.values.deliveryWeight),
                quantity: parseFloat(formState.values.quantity)
            };
        } else {

            tempData = {
                ...formState.values,
                farmID: farms[formState.values.farmIndex].id,
                farmName: farms[formState.values.farmIndex].name,
                shedID: sheds[formState.values.shedIndex].id,
                shedName: sheds[formState.values.shedIndex].name,
                deliveryWeight: parseFloat(formState.values.deliveryWeight),
                quantity: parseFloat(formState.values.quantity)
            };
        }
        if (editMode)
            onEdit(tempData, setPopupLoading);
        else
            onSave(tempData, setPopupLoading);
    };

    const onFarmChangeLocal = function (event) {
        onFarmChange(farms[event.target.value].id);
    }
    const onFeedStorageChange = function (event) {
        setIstorage(!isStorage)
        let isStorageSwitch = event.target.checked
        if (isStorageSwitch) {
            schema = schemaStorage
        } else {
            schema = schemaAll
        }
    }



    const schemaChange = (event) => {
        if (event.target.name === 'feedStorageIndex' && isStorage) { 
            if (((event.target.value)  !== "" || (event.target.value)  !== null) &&
            (formState.values.feedStorageIndex === "" || formState.values.feedStorageIndex == null || formState.values.feedStorageIndex === undefined)) {
            schema = schemaStorage
            }
        }

        if (event.target.name === 'deliveryWeight' && !isStorage) {
            console.debug((event.target.value), formState.values.quantity)
            if (((event.target.value) === "" || (event.target.value) === null) &&
                (formState.values.quantity === "" || formState.values.quantity === null || formState.values.quantity === undefined)) {
                schema = schemaAll
            } else if (((event.target.value)  === "" || (event.target.value)  === null) &&
                (formState.values.quantity !== "" || formState.values.quantity !== null || formState.values.quantity !== undefined)) {
                schema = schemaQuantity
            } else if (((event.target.value)  !== "" || (event.target.value)  !== null) &&
                (formState.values.quantity === "" || formState.values.quantity == null || formState.values.quantity === undefined)) {
                schema = schemaDeliveryWeight
            }else if (((event.target.value)  !== "" || (event.target.value)  !== null) &&
            (formState.values.quantity !== "" || formState.values.quantity !== null || formState.values.quantity !== undefined)) {
            schema = schemaAll
        } 
        }
        if (event.target.name === 'quantity' && !isStorage) {
            if (((event.target.value)=== "" || (event.target.value)=== null) &&
                (formState.values.deliveryWeight === "" || formState.values.deliveryWeight === null || formState.values.deliveryWeight === undefined)) {
                schema = schemaAll
            } else if (((event.target.value)=== "" || (event.target.value)=== null) &&
                (formState.values.deliveryWeight !== "" || formState.values.deliveryWeight !== null || formState.values.deliveryWeight !== undefined)) {
                schema = schemaDeliveryWeight
            } else if (((event.target.value)!== "" || (event.target.value)!== null) &&
                (formState.values.deliveryWeight === "" || formState.values.deliveryWeight == null || formState.values.deliveryWeight === undefined)) {
                schema = schemaQuantity
            } else if (((event.target.value)!== "" || (event.target.value)!== null) &&
            (formState.values.deliveryWeight !== "" || formState.values.deliveryWeight !== null || formState.values.deliveryWeight !== undefined)) {
            schema = schemaAll
        }
        }

        console.debug(schema)
    }

    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"  >{editMode ? 'Update' : 'New'} Daily Feed Production
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/>
                </div>
                <br/><br/>
                <FormControl disabled className={classes.formControl}>
                <InputLabel disabled id="farm-select-outlined-label">
                    Farm
                </InputLabel>
                <Select disabled
                    id="farmIndex"
                    value={formState.values.farmIndex !== undefined ? formState.values.farmIndex : ''}
                    onChange={event => {
                        handleChange(event, onFarmChangeLocal)
                    }}
                    helperText={
                        hasError('farmIndex') ? formState.errors.farmIndex[0] : null
                    }
                    name="farmIndex"
                >

                    {farms !== undefined && farms !== null ?
                        farms.map((farm, index) => {
                            return <MenuItem key={farm.id} value={index}>{farm.name}</MenuItem>
                        }
                        ) : []}
                </Select>
            </FormControl>
            <FormControl disabled className={classes.formControl}>
                <InputLabel disabled id="shed-select-outlined-label">
                    Shed
                </InputLabel>
                <Select disabled
                    id="shedIndex"
                    value={formState.values.shedIndex !== undefined ? formState.values.shedIndex : ''}
                    onChange={handleChange}
                    name="shedIndex"
                    helperText={
                        hasError('shedIndex') ? formState.errors.shedIndex[0] : null
                    }
                >
                    {sheds !== undefined && sheds !== null ?
                        sheds.map((shed, index) =>
                            <MenuItem key={shed.id} value={index}>{shed.name}</MenuItem>
                        ) : []}
                </Select>
            </FormControl>
            <MuiPickersUtilsProvider disabled utils={DateFnsUtils}>
                <DatePicker disabled
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    helperText={
                        hasError('date') ? formState.errors.date[0] : null
                    }
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <TextField disabled
                className={classes.textField}
                error={hasError('quantity')}
                helperText={
                    hasError('quantity') ? formState.errors.quantity[0] : null
                }
                //disabled={quantityDisable}
                fullWidth
                label="Quantity(in tonnes)"
                name="quantity"
                onChange={event => { handleChange(event, schemaChange) }}
                //onChange={handleChange}
                type="number"
                inputProps={{
                    step: '1', 
                  }}
                value={formState.values.quantity !== undefined ? formState.values.quantity : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField disabled
                className={classes.textField}
                error={hasError('deliveryWeight')}
                helperText={
                    hasError('deliveryWeight') ? formState.errors.deliveryWeight[0] : null
                }
                //disabled={deliveryWeightDisable}
                fullWidth
                label="Delivery Weight"
                name="deliveryWeight"
                onChange={event => { handleChange(event, schemaChange) }}
                //onChange={handleChange}
                type="number"
                value={formState.values.deliveryWeight !== undefined ? formState.values.deliveryWeight : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
            :
            <div>

          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
                <div  className={classes.formControlSwitch}>
                    <div className={classes.formControlSwitchLabel}>
                        Storage
                    </div>
                    <div>   
                        <Switch
                            checked={isStorage}
                            onChange={(event)=>{
                                onFeedStorageChange(event)
                            
                            }}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                </div>
        <br/>
        {
                    isStorage ? 
                    <FormControl className={classes.formControl}>
                    <InputLabel id="farm-select-outlined-label">
                        Storage
                    </InputLabel>
                    <Select
                        id="feedStorageIndex"
                        value={formState.values.feedStorageIndex !== undefined ? formState.values.feedStorageIndex : ''}
                        onChange={event => { handleChange(event, schemaChange) }}
                        helperText={
                            hasError('feedStorageIndex') ? formState.errors.feedStorageIndex[0] : null
                        }
                        name="feedStorageIndex"
                    >
    
                        {storageformula !== undefined && storageformula !== null ?
                            storageformula.map((formula, index) => {
                                    return <MenuItem key={formula.id} value={index}>{formula.formulaName}</MenuItem>
                                }
                            ) : []}
                    </Select>
                </FormControl> : 
                <>
                <FormControl className={classes.formControl}>
                    <InputLabel id="farm-select-outlined-label">
                        Farm
                    </InputLabel>
                    <Select
                        id="farmIndex"
                        value={formState.values.farmIndex !== undefined ? formState.values.farmIndex : ''}
                        onChange={event => {
                            handleChange(event, onFarmChangeLocal)
                        }}
                        helperText={
                            hasError('farmIndex') ? formState.errors.farmIndex[0] : null
                        }
                        name="farmIndex"
                    >

                        {farms !== undefined && farms !== null ?
                            farms.map((farm, index) => {
                                return <MenuItem key={farm.id} value={index}>{farm.name}</MenuItem>
                            }
                            ) : []}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id="shed-select-outlined-label">
                        Shed
                    </InputLabel>
                    <Select
                        id="shedIndex"
                        value={formState.values.shedIndex !== undefined ? formState.values.shedIndex : ''}
                        onChange={handleChange}
                        name="shedIndex"
                        helperText={
                            hasError('shedIndex') ? formState.errors.shedIndex[0] : null
                        }
                    >
                        {sheds !== undefined && sheds !== null ?
                            sheds.map((shed, index) =>
                                <MenuItem key={shed.id} value={index}>{shed.name}</MenuItem>
                            ) : []}
                    </Select>
                </FormControl>
                </>
                
                }

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    helperText={
                        hasError('date') ? formState.errors.date[0] : null
                    }
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <TextField
                className={classes.textField}
                error={hasError('quantity')}
                helperText={
                    hasError('quantity') ? formState.errors.quantity[0] : null
                }
                //disabled={quantityDisable}
                fullWidth
                label="Quantity(in tonnes)"
                name="quantity"
                onChange={event => { handleChange(event, schemaChange) }}
                //onChange={handleChange}
                type="number"
                value={formState.values.quantity !== undefined ? formState.values.quantity : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('deliveryWeight')}
                helperText={
                    hasError('deliveryWeight') ? formState.errors.deliveryWeight[0] : null
                }
                //disabled={deliveryWeightDisable}
                fullWidth
                label="Delivery Weight"
                name="deliveryWeight"
                onChange={event => { handleChange(event, schemaChange) }}
                //onChange={handleChange}
                type="number"
                value={formState.values.deliveryWeight !== undefined ? formState.values.deliveryWeight : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions>
                <Button
                    className={closeButtonClass.close}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleClose}
                >
                    Close
                </Button>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
          }
        </DialogContent>
    </Dialog>
};

export default Dailyentry;