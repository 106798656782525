import React, {useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {DialogTitle} from "../../../components";
import makeStyles from '@material-ui/styles/makeStyles';
import useFromValidator from '../../../common/formValidator';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';

const schema = {
    name: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    shedTypeIndex:{
        presence: {allowEmpty: false, message: 'is required'}
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      }, 
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(2, 0)
    }
}));
const NewShed = ({ deleteErrorMessage, errorMessage, onEdit, data, onSave, handleClose, showDialog, editMode = false,shedType}) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);

    const resetBeforeEdit = () => {
        if(showDialog){
            updateData(data, editMode);
        }
    };

    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        const tempData = {
            ...formState.values,
            shedTypeID:shedType[formState.values.shedTypeIndex].id,
            shedTypeName: shedType[formState.values.shedTypeIndex].shed_type
        };
        if (editMode)
            onEdit(tempData);
        else
            onSave(tempData);
    };

    return <Dialog open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >{editMode?'Update':'New'} Shed
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
        <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>

            <TextField
                className={classes.textField}
                error={hasError('name')}
                fullWidth
                helperText={
                    hasError('name') ? formState.errors.name[0] : null
                }
                label="Shed Name"
                name="name"
                onChange={handleChange}
                type="text"
                value={formState.values.name || ''}
                variant="outlined"
            />
            <FormControl className={classes.formControl}>
                <InputLabel id="shedType-select-label">Shed Type</InputLabel>
                <Select
                    id="shedType-select"
                    value={formState.values.shedTypeIndex !== undefined ? formState.values.shedTypeIndex : ''}
                    onChange={handleChange}
                    name="shedTypeIndex"
                >
                    {shedType.map((type, index) =>
                        <MenuItem key={type.id} value={index}>{type.shed_type}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <DialogActions>
                <Button
                    className={classes.button}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>

};

export default NewShed;