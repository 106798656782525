import React, {useState, useEffect, useRef} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import html2canvas from 'html2canvas';
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useReactToPrint } from 'react-to-print';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


window.html2canvas = html2canvas;
const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    singleLineBorder: {
        borderTop: 'solid 1px #F26522',
        borderBottom: 'solid 1px #F26522'
    },
    doubleLineBorder: {
        borderTop: 'double #F26522',
        borderBottom: 'double #F26522'
    },
    boldedText:{
        fontWeight: 'bold'
    },
    reportContainer:{
       
    },
    reportHeader:{
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(2)
    },
    rawMaterialName:{
        textAlign:'center',
        textDecoration:'underline'
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },
    formControlLabelClass: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}));

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: '1em',
        backgroundColor: theme.palette.common.white,
        padding: '6px'
    },
  }))(TableCell);
  
const StyledTableRow = withStyles(theme => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.background.default
      }
    }
}))(TableRow);
  
const tableHeader = JSON.parse(
    `[[ "Material Name", "Per Day Usage", "Closing Stock", "Stock Available Days" ]]`
);


const StockAvailabilityReport = ({showError}) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const [loading, setLoading] = useState(false);
    const classes = useStyles({isDesktop});
    const [data, setData] = useState([]);
    const componentRef = useRef();
    const [isHidden, setIsHidden] = useState(true);
    
    const [dataWithZero, setDataWithZero] = useState([]);
    const [dataWithoutZero, setDataWithoutZero] = useState([]);

    const parseReportFromServer = function (responseData) {
        
        let allData = [];
        let nonZeroData = [];

        responseData.forEach((value, index) => {
            if (value[1] !== "0") {
                nonZeroData.push(responseData[index])
            }
            allData.push(responseData[index])
        })

        setDataWithoutZero(nonZeroData)
        setDataWithZero(allData)

        setData(nonZeroData);
        
    };

    const handleChange = (event) => {
        setIsHidden(event.target.checked)
        if (event.target.checked === true) {
            setData(dataWithoutZero)
        } else {
            setData(dataWithZero)
        }
    }

    const getStockAvailabilityReport = () => {
    
        const response = api.get('reports/stock-availability-report');
    
        response.then(res => {
            parseReportFromServer(res)
            console.log("RESPONSE", res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Stock Availability Found');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });
    
        return response;
    }
    
    const onLoad = () => {
        setLoading(true)
        Promise.all([
            getStockAvailabilityReport()
        ]).finally(() => {
            setLoading(false)
        });
    };
    
    useEffect(onLoad, [])

    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
      });

    
    const generateExcel = function (header, data) {

        let csvData = '';
        header.forEach((column, index) => {
            if (index === 0)
                csvData = csvData + column
            else
                csvData = csvData + ',' + column
        });
        csvData = csvData + '\r\n';
        data.forEach(row => {
            row.forEach((column, index) => {
                if (index === 0)
                    csvData = csvData + column
                
                else
                    csvData = csvData + ',' + column
            });
            csvData = csvData + '\r\n';
        });

        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
        element.setAttribute('download', 'report.csv');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    return <div className={classes.root}>

        <Grid container alignItems="flex-start" className={classes.topBar}>
                <Grid>
                    <Typography display="block" variant="h3" gutterBottom>
                        Stock Availability For Farms
                    </Typography>
                </Grid>
        </Grid>

        {loading ? 
        <Grid disabled container spacing={3} justify="flex-start" alignItems="center" >
            <Grid item>
                <Button disabled variant="outlined" color="primary"
                    onClick={generatePDF} endIcon={<PictureAsPdfIcon/>}>Download PDF
                </Button>
            </Grid>
            <Grid item>
                <Button disabled variant="outlined" color="primary"
                    onClick={() => generateExcel(tableHeader, data)}
                    endIcon={<GetAppIcon/>}>Download Excel</Button>
            </Grid>
        </Grid>
            :
        <Grid container spacing={3} justify="flex-start" alignItems="center" >
            <Grid item>
                <Button variant="outlined" color="primary"
                    onClick={generatePDF} endIcon={<PictureAsPdfIcon/>}>Download PDF
                </Button>
            </Grid>
            <Grid item>
                <Button variant="outlined" color="primary"
                    onClick={() => generateExcel(tableHeader, data)}
                    endIcon={<GetAppIcon/>}>Download Excel</Button>
            </Grid>
            <Grid container >
                <FormControlLabel
                    className={classes.formControlLabelClass}
                    control={
                        <Switch
                            checked={isHidden}
                            name="isHidden"
                            color="primary"
                            onChange={handleChange}
                        />
                        }
                    label={"Hide records's which are zero"}
                />
            </Grid>
        </Grid>
        
        }



    
    {
        loading ?
        <div className={classes.progressContainer}>
            <CircularProgress  className={classes.progressBar}/>
        </div>
        :
        <div id="report-container" className={classes.reportContainer} ref={componentRef}>
                <div className={classes.reportHeader}>
                    <Typography pography variant="h4" component="h4" className={classes.rawMaterialName}>
                        Stock Availability Report
                    </Typography>
                </div>
                <Table style={{ tableLayout: "fixed"}} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Material Name</StyledTableCell>
                            <StyledTableCell>Per Day Usage</StyledTableCell>
                            <StyledTableCell>Closing Stock</StyledTableCell>
                            <StyledTableCell>Stock Available Days</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data !== undefined ? data.map((value, index) => {
                                return <StyledTableRow key={index}>
                                    <StyledTableCell align="left"> {value[0]}</StyledTableCell>
                                    <StyledTableCell align="left"> {value[1]}</StyledTableCell>
                                    <StyledTableCell align="left"> {value[2]}</StyledTableCell>
                                    <StyledTableCell align="left"> {value[3] === "0" ? "NA" : value[3]}</StyledTableCell>
                                </StyledTableRow>
                            }):
                            ""
                        }
                    </TableBody>
                </Table>
        </div>
    }


</div>
}

export default StockAvailabilityReport;