import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import moment from 'moment';
import OtherExpense from "./components/otherExpense";
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import PaymentIcon from '@material-ui/icons/Payment';
import OtherExpensePaymentDetails from "./components/otherExpensePaymentDetails";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import {useGlobalState} from '../../state';
import { version } from '../../common/enums';




const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    redChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#f44336',
        backgroundColor: 'rgba(244, 67, 54, 0.08)',
    },
    greenChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#4caf50',
        backgroundColor: 'rgba(76, 175, 80, 0.08)'
    },
    red: {
        color: 'red'
    },
    green: {
        color: 'green'
    },
}));

let currentOtherExpense = undefined;
let editMode = false;

const OtherExpensesView = ({ showError, showMessage, history }) => {

    const [otherExpenses, setOtherExpenses] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [farms, setFarms] = useState([]);
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showOtherExpenseDialog, setShowOtherExpenseDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showOtherExpensePaymentDialog, setShowOtherExpensePaymentDialog] = useState(false);
    const [currentOtherExpensePayment, setCurrentOtherExpensePayment] = useState({});
    const [errorMessage, setErrorMessage] = useState('')
    const [banks, setBanks] = useState([]);




    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    const classes = useStyles({ isDesktop });

    const csvHeader = ["Date", "Amount(in Rs.)", "Vendor", "Farm", "Expense Type", "Payment Status"]

    const csvProHeader = ["Date", "Amount(in Rs.)", "Farm", "Expense Type"]

    function onNewOtherExpense(event) {
        editMode = false;
        event.preventDefault();
        setShowOtherExpenseDialog(true);
    }

    function handleOtherExpenseClose() {
        currentOtherExpense = undefined;
        setErrorMessage(null);
        setShowOtherExpenseDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }
    function handleOtherExpensePaymentClose() {
        currentOtherExpense = undefined;
        setShowOtherExpensePaymentDialog(false);
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const onOtherExpensePaymentUpdate = (payment, otherExpense, setPopupLoading) => {
        //setShowOtherExpensePaymentDialog(false);
        //setLoading(true);
        showError('');
        const response = api.post(`other-expense-payment/${payment.id}`, {
            ...payment,

        });
        response.then(() => {
            let index = otherExpenses.findIndex((srcOtherExpense) => {
                return srcOtherExpense.id === otherExpense.id;
            });

            let totalAmount = 0
            otherExpense.otherExpensePayments.forEach((payment, index) => {
                totalAmount = totalAmount + payment.paymentAmount
            })

            if (totalAmount === 0) {
                otherExpense = {
                    ...otherExpense,
                    paymentStatus: 0
                }
            } else if (totalAmount >= otherExpense.amount) {
                otherExpense = {
                    ...otherExpense,
                    paymentStatus: 2

                }
            } else if (totalAmount < otherExpense.amount) {
                otherExpense = {
                    ...otherExpense,
                    paymentStatus: 1

                }
            }
            otherExpense = {
                ...otherExpense,
                remainingBalance: totalAmount
            }



            setOtherExpenses([...otherExpenses.slice(0, index),
            { ...otherExpense },
            ...otherExpenses.slice(index + 1)]);
            setShowOtherExpensePaymentDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('OtherExpense Payment not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            // setPopupLoading(false);
        });
    };

    const onOtherExpensePaymentSave = (payment) => {
        //setShowOtherExpensePaymentDialog(false);
        //setLoading(true);
        showError('');
        const response = api.post(`other-expense-payment`, {
            ...payment,
        });
        response.then((res) => {

            let tempCurrentOtherExpense = currentOtherExpensePayment
            if (tempCurrentOtherExpense.otherExpensePayments !== undefined && tempCurrentOtherExpense.otherExpensePayments !== null) {
                tempCurrentOtherExpense.otherExpensePayments = [
                    ...tempCurrentOtherExpense.otherExpensePayments,
                    res
                ]
            } else {
                tempCurrentOtherExpense.otherExpensePayments = [
                    res
                ]
            }
            setCurrentOtherExpensePayment({ ...tempCurrentOtherExpense })

            let totalAmount = 0
            tempCurrentOtherExpense.otherExpensePayments.forEach((payment, index) => {
                totalAmount = totalAmount + payment.paymentAmount
            })
            if (totalAmount === 0) {
                tempCurrentOtherExpense = {
                    ...tempCurrentOtherExpense,
                    paymentStatus: 0
                }
            } else if (totalAmount >= tempCurrentOtherExpense.amount) {
                tempCurrentOtherExpense = {
                    ...tempCurrentOtherExpense,
                    paymentStatus: 2
                }
            } else if (totalAmount < tempCurrentOtherExpense.amount) {
                tempCurrentOtherExpense = {
                    ...tempCurrentOtherExpense,
                    paymentStatus: 1
                }
            }
            tempCurrentOtherExpense = {
                ...tempCurrentOtherExpense,
                remainingBalance: totalAmount
            }


            let index = otherExpenses.findIndex((srcOtherExpense) => {
                return srcOtherExpense.id === tempCurrentOtherExpense.id;
            });
            setOtherExpenses([...otherExpenses.slice(0, index),
            { ...tempCurrentOtherExpense },
            ...otherExpenses.slice(index + 1)]);
            setShowOtherExpensePaymentDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('OtherExpense not Updated');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onOtherExpensePaymentDelete = (payment, otherExpense) => {
        if (payment && payment.id) {
            //setShowDeleteConfirm(false);
            //setLoading(true);
            showError('');
            const response = api.post(`other-expense-payment-delete/${payment.id}`, {
                ...payment,
            });

            response.then(res => {
                if (res) {
                    let index = otherExpenses.findIndex((srcOtherExpense) => {
                        return srcOtherExpense.id === otherExpense.id;
                    });

                    let totalAmount = 0
                    otherExpense.otherExpensePayments.forEach((payment, index) => {
                        totalAmount = totalAmount + payment.paymentAmount
                    })
                    if (totalAmount === 0) {
                        otherExpense = {
                            ...otherExpense,
                            paymentStatus: 0
                        }
                    } else if (totalAmount >= otherExpense.totalBillValue) {
                        otherExpense = {
                            ...otherExpense,
                            paymentStatus: 2
                        }
                    } else if (totalAmount < otherExpense.totalBillValue) {
                        otherExpense = {
                            ...otherExpense,
                            paymentStatus: 1
                        }
                    }

                    setOtherExpenses([...otherExpenses.slice(0, index),
                    { ...otherExpense },
                    ...otherExpenses.slice(index + 1)]);
                    setShowDeleteConfirm(false);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        setErrorMessage('OtherExpense not Deleted');
                    else
                    setErrorMessage(err.message)
                } else {
                    setErrorMessage('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentOtherExpense = undefined;
            });

        }
    };

    //Get all Banks API Call
    const getAllBanks = () => {
        showError('');
        showMessage('');

        const response = api.get('bank');

        response.then(res => {
            setBanks([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }

    const onOtherExpenseDeleteConfirm = () => {
        if (currentOtherExpense && currentOtherExpense.id) {
            setShowDeleteConfirm(false);
            //setLoading(true);
            showError('');
            const response = api.delete(`other-expense/${currentOtherExpense.id}`);

            response.then(res => {
                if (res) {
                    let index = otherExpenses.findIndex((srcOtherExpense) => {
                        return srcOtherExpense.id === currentOtherExpense.id;
                    });
                    let newOtherExpenses = [...otherExpenses];
                    newOtherExpenses.splice(index, 1);
                    setOtherExpenses(newOtherExpenses);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('OtherExpense not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentOtherExpense = undefined;
            });

        }
    };

    const onDelete = (otherExpense) => {
        currentOtherExpense = otherExpense;
        setShowDeleteConfirm(true);
    };

    const onOtherExpenseSave = (otherExpense, setPopupLoading) => {
        //setLoading(true);
        // showError('');
        showMessage('');
        const response = api.post('other-expense', {
            ...otherExpense,
        });

        response.then(res => {

            setOtherExpenses([...otherExpenses, res]);
            setShowOtherExpenseDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('OtherExpense not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };


    const onOtherExpenseEdit = (otherExpense) => {
        currentOtherExpense = {
            ...otherExpense,
            farmIndex: farms.findIndex((farm) => { return farm.id === otherExpense.farmId; }),
            vendorIndex: vendors.findIndex((vendor) => { return vendor.id === otherExpense.vendorId; }),
            expenseTypeIndex: expenseTypes.findIndex((expenseType) => { return expenseType.id === otherExpense.expenseTypeId; }),
            amount: otherExpense.amount
        };
        editMode = true;
        setShowOtherExpenseDialog(true);
    };

    const onOtherExpenseUpdate = (otherExpense, setPopupLoading) => {
       // setLoading(true);
        // showError('');
        console.debug(otherExpense, "otherExpense")
        otherExpense.amount = parseInt(otherExpense.amount)
        const response = api.post(`other-expense/${otherExpense.id}`, {
            ...otherExpense,

        });
        response.then(() => {
            let index = otherExpenses.findIndex((srcOtherExpense) => {
                return srcOtherExpense.id === otherExpense.id;
            });

            setOtherExpenses([...otherExpenses.slice(0, index),
            { ...otherExpense },
            ...otherExpenses.slice(index + 1)]);
            setShowOtherExpenseDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('OtherExpense not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setPopupLoading(false);
            setLoading(false);
        });
    };

    //Get all OtherExpenses API Call
    const getAllOtherExpenses = () => {
        setShowOtherExpenseDialog(false);
        showError('');
        showMessage('');

        const response = api.get('other-expense');

        response.then(res => {
            res.forEach((value) => {
                let totalAmount = 0
                if (value.otherExpensePayments !== null) {
                    value.otherExpensePayments.forEach((payment) => {
                        totalAmount += payment.paymentAmount
                    })
                }
                value.remainingBalance = totalAmount
            })
            setOtherExpenses([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No OtherExpenses Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response
    }

    //Get all vendors API Call
    const getAllVendors = () => {
        showError('');
        showMessage('');

        const response = api.get('vendors-list');

        response.then(res => {
            setVendors([{
                name: "Other",
                id: 0
            },
            ...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        })
        return response
    }

    //Get all farms API Call
    const getAllFarms = () => {
        showError('');
        showMessage('');

        const response = api.get('farm');

        response.then(res => {
            setFarms([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        })
        return response
    }

    //Get all ExpenseType API Call
    const getAllExpenseTypes = () => {
        showError('');
        showMessage('');

        const response = api.get('expense-type');

        response.then(res => {
            setExpenseTypes([
                {
                    expenseTypeName: "Other",
                    id: 0
                },
                ...res]
            );

        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        })
        return response
    }

    const generateExcel = function (header, data) {
        console.log("excel ", header, data)
        let csvData = '';

        header.forEach((column, index) => {
            if (index === 0)
                csvData = csvData + column
            else
                csvData = csvData + ',' + column
        });

        csvData = csvData + '\r\n';


        data.map((row, index) => {
            let column = []
            let tempDate;
            let tempPaymentStatus;
            tempDate = moment(row.date).format("DD-MM-YYYY")
            if (row.paymentStatus === 1)
                tempPaymentStatus = "Paid"
            else
                tempPaymentStatus = "Unpaid"

            if (user.versionID === version.pro) {
                column.push(tempDate, row.amount, row.farmName, row.expenseTypeName)

            }else {
                column.push(tempDate, row.amount,row.vendorName, row.farmName, row.expenseTypeName, tempPaymentStatus)
            }

            column.forEach((column, index) => {
                if (index === 0)
                    csvData = csvData + column

                else
                    csvData = csvData + ',' + column
            });
            csvData = csvData + '\r\n';

            return "";
        });

        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
        element.setAttribute('download', 'report.csv');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);


    }

    const onload = () => {
        setLoading(true);
        Promise.all([
            getAllVendors(),
            getAllFarms(),
            getAllExpenseTypes(),
            getAllOtherExpenses(),
            getAllBanks(),
        ]).finally(()=>{
            setLoading(false);
        });
    }
    useEffect(onload, []);


    let otherExpenseNameColumn = {
        title: 'Other Expense', field: 'date', editable: 'never', defaultSort: 'asc',
        render: rowData => <Mobile data={rowData}
            onEdit={() => {
                onOtherExpenseEdit(rowData)
            }}
            onDelete={() => {
                onDelete(rowData)
            }}
        />
    };


    const onPaymentEdit = (otherExpense) => {
        setCurrentOtherExpensePayment({
            ...otherExpense,
        });
        setShowOtherExpensePaymentDialog(true);
    };

    let actions = [];
    if (isDesktop) {
        otherExpenseNameColumn = {
            title: 'Date', field: 'date', type: 'datetime',
            editable: 'never',
            hidden: !isDesktop,
            render: rowData => {
                return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
            }, defaultSort: 'asc'
        };
        actions = [

            {
                icon: () => <PaymentIcon color='error'>payment</PaymentIcon>,
                tooltip: 'Payment Details',
                onClick: (event, rowData) => onPaymentEdit(rowData),
                hidden:(user.versionID === version.pro)
            },
            rowData => ({
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit otherExpense Detail',
                onClick: () => onOtherExpenseEdit(rowData),
                hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
            }),
            rowData => ({
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete otherExpense Details',
                onClick: (event, rowData) => onDelete(rowData),
                hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
            }),
        ]
    }


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Other Expenses
                </Typography>
            </Grid>
            <Grid container
                spacing={3}
                justify="flex-end"
                alignItems="center"
            >
                <Grid item>
                    <Button variant="contained" color="primary" onClick={onNewOtherExpense}>Add other Expense</Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" color="primary"
                        disabled={otherExpenses.length < 1}
                        onClick={() => generateExcel(user.versionID === version.proPlus ? csvHeader : csvProHeader, otherExpenses)}
                        endIcon={<GetAppIcon />}>Download Excel</Button>
                </Grid>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'ID', field: 'id', hidden: (!isDesktop ? true: false )},
                otherExpenseNameColumn,
                { title: 'Vendor', field: 'vendorName', hidden: (!isDesktop ||  (user.versionID === version.pro)), editable: 'never' },
                { title: 'Farm', field: 'farmName', hidden: !isDesktop, editable: 'never' },
                { title: 'Expense Type', field: 'expenseTypeName', hidden: !isDesktop, editable: 'never' },
                { title: 'Amount (in Rs.)', field: 'amount', hidden: !isDesktop, editable: 'never',
                    render: rowData => {
                        return <span>{(rowData.amount).toLocaleString(user.locales,amountObj)}</span>
                    } 
                },
                {
                    title: 'Remaining Balance', field: 'remainingBalance', editable: 'never',hidden:(user.versionID === version.pro),
                    render: rowData => {
                        let paidAmount = 0
                        if (rowData.otherExpensePayments) {
                            rowData.otherExpensePayments.forEach(ele => {
                                paidAmount += ele.paymentAmount
                            })
                        }
                        let remaningBalance = (rowData.amount - paidAmount)
                        if (rowData.paymentStatus === 1 && remaningBalance < 1) {
                            remaningBalance = 0
                        }
                        return <span>{(remaningBalance).toLocaleString(user.locales,amountObj)}</span>
                    }
                },
                {
                    title: 'Payment Status', field: 'paymentStatus', editable: 'never',hidden:(user.versionID === version.pro),
                    cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' },
                    render: (rowData) => {
                        let remaningBalance = rowData.amount - rowData.remainingBalance

                        let statusIcon = <Tooltip title="Not Paid"><CloseIcon className={classes.red} /></Tooltip>
                        if (rowData.paymentStatus === 1 && remaningBalance >= 1) {
                            statusIcon = <Tooltip title="Partially Paid"><QueryBuilderIcon /></Tooltip>
                        } else if (rowData.paymentStatus === 2 || (rowData.paymentStatus === 1 && remaningBalance < 1)) {
                            statusIcon = <Tooltip title="Paid"><DoneIcon className={classes.green} /></Tooltip>
                        }

                        return <span>{statusIcon}</span>
                    }
                }
            ]}
            data={otherExpenses}
            title='Other Expense Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,

                toolbar: true
            }}
            actions={actions}
        />
        <OtherExpense deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showOtherExpenseDialog} handleClose={handleOtherExpenseClose} onSave={onOtherExpenseSave} onEdit={onOtherExpenseUpdate}
            editMode={editMode} data={currentOtherExpense} farms={farms} vendors={vendors} expenseTypes={expenseTypes} />

        <OtherExpensePaymentDetails deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showPaymentDetailsDialog={showOtherExpensePaymentDialog} handlePaymentDetailsClose={handleOtherExpensePaymentClose}
            onPaymentEdit={onOtherExpensePaymentUpdate} data={currentOtherExpensePayment} banks={banks} onOtherExpensePaymentSave={onOtherExpensePaymentSave}
            onOtherExpensePaymentDelete={onOtherExpensePaymentDelete} loading={loading} />

        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onOtherExpenseDeleteConfirm}
            title='Delete Other Expense ?'
            subText='This action will delete the Other Expense Details. Please confirm.' />
    </div>
}

export default OtherExpensesView;