import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        textAlign: "center"
    }
}));

const Footer = props => {
    const {className, ...rest} = props;

    const classes = useStyles({});

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Typography variant="body1">
                &copy;{' '}
                <Link
                    component="a"
                    href="https://poultrypro.in/"
                    target="_blank"
                >
                    Poultry Pro
                </Link>
                . {new Date().getFullYear()}
            </Typography>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string
};

export default Footer;
