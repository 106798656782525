/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {forwardRef} from 'react';
import {NavLink as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import NestedMenu from "../../../../../components/SubMenu";
import api from '../../../../../../common/api';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Alert from '@material-ui/lab/Alert';

import {useGlobalState} from '../../../../../../state';
import { version } from "../../../../../../common/enums";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.white,
    },
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    iconColor: {
        color: theme.palette.icon,
    },
    button: {
        color: theme.palette.buttonColor.primary,
        fontSize:'15px',
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium,
        '&:hover': {
            color: theme.palette.navHover.text
        },
    },
    icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    active: {
        color: theme.palette.navActive,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.navActive
        }
    },
    hover: {
        color: theme.palette.navHover.text
    }
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{flexGrow: 1}}
    >
        <RouterLink {...props} />
    </div>
));

const bookmarkState = {
    id: 0,
    title: "",
    href: "",
    isBookmarked: false
}

const SidebarNav = props => {
    const [{user}] = useGlobalState();
    const {pages, open, className, onClose, ...rest} = props;
    const [errorMessage, setErrorMessage] = React.useState('');
    const [bookmarkDatas, setBookmarkDatas] = React.useState([bookmarkState])
    const [allPages, setAllPages] = React.useState([])


    // Get all bookmark data
    const getAllBookMarks = () => {
        
        console.log("object");
        const response = api.get("get-all-bookmarks")
        
        let arr = pages.filter(page => page.id === undefined)
        let totalPages = arr

        response.then(res => {
            setBookmarkDatas(res)

            if(user.verionID && user.verionID !== version.proPlus){
                totalPages.splice(1, 0, ...res)
            }
            else{
                totalPages.splice(2, 0, ...res)
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Bookmarks not found')
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(()=>{
            setAllPages(totalPages)
        })
    }


    const removeErrorMessage = () =>{
        setErrorMessage('');
    }

    React.useEffect(getAllBookMarks, [open,pages])

    
    // Remove bookmark data
    const removeBookmark = (page) => {
        const response = api.post("remove-bookmark", page)

        response.then(res => {
            console.log("res", res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Remove bookmarks failed');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(()=>{
            getAllBookMarks()
        })
    }

    const classes = useStyles({});

    const generateKey = () => {
        return `${new Date().getTime()}`
    }

    return (
        <div id="error">
    {errorMessage ?
    <Alert severity="error" onClick={removeErrorMessage}>{errorMessage}</Alert>
    : ''}
        <List
            {...rest}
            className={clsx(classes.root, className)}
        >
            {allPages.map(page => {

                if (page.nested !== undefined && page.nested === true) {
                    return <NestedMenu getAllBookMarks={getAllBookMarks} bookmarks={bookmarkDatas} {...page} onClose={onClose} key={page.title} depth={0}/>
                } else {
                    
                    return <ListItem
                        className={classes.item}
                        disableGutters
                        key={page.title+page.href+generateKey()}
                    >
                        {page.icon !== undefined ? 
                            <Button
                                activeClassName={classes.active}
                                className={classes.button}
                                component={CustomRouterLink}
                                to={page.href}
                                onClick={onClose}
                            /*  onTouchEnd={onClose} */
                            >
                                    
                                <div className={classes.icon}>{page.icon}
                                </div>
                                {page.title}
                                    
                            </Button>

                            :

                            (page.isBookmarked === true ?
                                <ListItem
                                className={classes.item}
                                disableGutters
                                key={page.id+page.title+page.href+page.isBookmarked}>
                                <Button
                                    activeClassName={classes.active}
                                    className={classes.button}
                                    component={CustomRouterLink}
                                    to={page.href}
                                    onClick={onClose}
                                    /*  onTouchEnd={onClose} */
                                >
                                    {page.title}
                                </Button>
                                <Tooltip className={classes.tooltip} title={"Remove bookmark"} placement="top" arrow TransitionComponent={Zoom}>
                                    <IconButton onClick={() => removeBookmark(page)} >
                                        <div className={classes.iconColor}>
                                            <BookmarkIcon fontSize="small" />
                                        </div>
                                    </IconButton>
                                </Tooltip>
                                </ListItem>
                            : "")
                            
                        }
                        
                    </ListItem>
                }
            })}
        </List>
        </div>
    );
};

SidebarNav.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.array.isRequired
};

export default SidebarNav;
