import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import red from '@material-ui/core/colors/red';

const useCardStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    cardActionsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    chip: {
        backgroundColor: red[500],
        color: "white",
        fontWeight: 500
    },
    leavingCss:{
        marginTop: theme.spacing(2)
    }
}));

const Mobile = ({data,onDelete,onEdit,handleIsActiveChange}) => {
    const classes = useCardStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        onDelete();
    };

    const handleEdit = () => {
        setAnchorEl(null);
        onEdit();


    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    return <Grid item>
        <Card className={classes.card}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                   
                }
                title={`Name : ${data.name}`}
            />
            <CardContent>
                <Grid
                    container
                    justify='space-between'
                    alignItems='flex-end'
                >
                    <Grid item>
                    <Typography variant="h5" component="h2">
                        {data.payperday}
                    </Typography>
                    <Typography color="textSecondary">
                        Pay/Day
                    </Typography>
                    </Grid>
                    <Grid item>
                    <Typography variant="h5" component="h2">
                        {data.advance}
                    </Typography>
                    <Typography color="textSecondary">
                        Advance
                    </Typography>
                    </Grid>
                    <Grid item>
                    <Typography variant="h5" component="h2">
                        {moment(data.joiningDate).format("DD-MM-YYYY")}
                    </Typography>
                    <Typography color="textSecondary">
                      Joining Date
                    </Typography>
                    </Grid>
                     </Grid>
                   
                   {
                    data.leavingDate   ?

                        <Grid item
                        className={classes.leavingCss}
                        >
                            <Typography variant="h5" component="h2">
                                {moment(data.leavingDate).format("DD-MM-YYYY")}
                            </Typography>
                            <Typography color="textSecondary">
                            Leaving Date
                            </Typography>
                       </Grid> : null
                   }

          

            </CardContent>
        </Card>

        <Menu
            id="labour-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
    </Grid>
};

export default Mobile;