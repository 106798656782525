import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import moment from 'moment';
import Dailyentry from "./components/dailyentry";
import FeedMaterials from "./components/feedMaterials";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import EventNoteIcon from '@material-ui/icons/EventNote';
import HomeIcon from '@material-ui/icons/Home';
import Link from '@material-ui/core/Link';
import Icon from "@material-ui/core/Icon";
import ListAltIcon from '@material-ui/icons/ListAlt';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ConfirmDialog} from '../../components';
import {useGlobalState} from '../../state';


const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    breadcrumbs: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        minWidth: 160,
    },
    searchButton:{
        color:'white',
        width:theme.spacing(21.25),
        backgroundColor:'#EF5350',
        '&:hover':{
            backgroundColor:'#E53935',
        },
        margin:theme.spacing(1),
        padding:theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom:theme.spacing(4)
    },
    success: {
        color:'white',
        backgroundColor:'#4CAF50',
        '&:hover':{
            backgroundColor:'#388E3C',
        },
        width:theme.spacing(13.75),
        margin:theme.spacing(1),
        padding:theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom:theme.spacing(4)
    }
}));
let currentFeedFormulation = undefined;
let editMode = false;


const DailyEntryView = ({showError, showMessage, history}) => {

    const [dailyFeedEntries, setDailyFeedEntries] = useState([]);
    const [feedMaterials, setFeedMaterials] = useState({});
    const [farms, setFarms] = useState([]);
    const [sheds, setShed] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDailyFeedProductionDialog, setShowDailyFeedProductionDialog] = useState(false);
    const [showFeedMaterialsDialog, setShowFeedMaterialsDialog] = useState(false);
    const [filterSelectedStartDate, setFilterSelectedStartDate] = useState(null);
    const [filterSelectedEndDate, setFilterSelectedEndDate] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [storageFormula, setStorageFormula] = useState([]);
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }


    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    function onNewFeedFormulation(event) {
        editMode = false;
        event.preventDefault();
        removeErrorMessage()
        setShowDailyFeedProductionDialog(true);
    }

    const onDailyFeedProductionEdit = (dailyFeedProduction) => {
        removeErrorMessage()
        console.debug(dailyFeedProduction)
        editMode = true;
        currentFeedFormulation = {
            ...dailyFeedProduction
        };
        if (!dailyFeedProduction.isStorage) {
            getAllSheds(dailyFeedProduction.farmID)
        } else {
            getAllStorageFormula()
        }
    };

    const handleFilterStartDateChange = date => {
        setFilterSelectedStartDate(date);
    };

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }


    const handleFilterEndDateChange = date => {
        setFilterSelectedEndDate(date);
    };
    const clearDateRangeFilter=()=>{
        setFilterSelectedStartDate(null)
        setFilterSelectedEndDate(null)
    }

    function handleFeedFormulationClose() {
        setErrorMessage(null);
        currentFeedFormulation = undefined;
        setShed([])
        setLoading(false)
        setShowDailyFeedProductionDialog(false);
    }

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
    }

    function openFeedMaterials(data) {
        console.debug("data", data)
        setShowFeedMaterialsDialog(false);
        setLoading(true);
        showError('');
        showMessage('');
        const payLoad = {
            dailyFeedProductionID : data.id,
            dailyFeedProductionShedID : data.shedID,
            dailyFeedProductionQuantity : data.quantity,
            dailyFeedProductionDate : data.date,
            dailyFeedProductionCreatedDate : data.createTime,
        }
        const response = api.post('get-feed-materials',payLoad);

        response.then(res => {
            setFeedMaterials({...res});
            setShowFeedMaterialsDialog(true);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Daily Feed Production Entries Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    function handleFeedMaterialsClose() {
        setShowFeedMaterialsDialog(false);
        setFeedMaterials({});
    }

    const getAllDailyFeedByDate = () => { 
        setLoading(true);
        if(filterSelectedEndDate === null && filterSelectedStartDate === null){
            getAllFeedFormulations()
        }
        if(filterSelectedEndDate !== null && filterSelectedStartDate !== null && 
            filterSelectedEndDate < filterSelectedStartDate){
            showError('Start Date is Greater than or Equal to End Date');
            return
        }
        let payload = {
            startDate:filterSelectedStartDate,
            endDate:filterSelectedEndDate,
        }

        showError('');
        showMessage('');
        const response = api.post('daily-feed-production-ByDate',{...payload});

        response.then(res => {
            setDailyFeedEntries([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Daily Feed Production Entries Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
        return response;
        
    };


    const onFeedFormulationSave = (feedFormulation, setpopuploading) => {
        //setLoading(true);
        // showError('');
        showMessage('');
        const response = api.post('daily-feed-production', {
            ...feedFormulation
        });

        response.then(res => {
            setDailyFeedEntries([...dailyFeedEntries, res]);
            setShowDailyFeedProductionDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Daily Feed Production not added');
                else if (err.message === 'duplicate key')
                setErrorMessage('Duplicate Entry');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };


    const onDailyFeedProductionUpdate = (dailyFeedProduction, setpopuploading) => {
        //setLoading(true);
        // showError('');
        const response = api.post(`daily-feed-production/${dailyFeedProduction.id}`, {
            ...dailyFeedProduction,

        });
        response.then((res) => {
            let index = dailyFeedEntries.findIndex((dfp) => {
                return dfp.id === res.id;
            });
            
            setDailyFeedEntries([...dailyFeedEntries.slice(0, index),
                {...res},
                ...dailyFeedEntries.slice(index + 1)]);
            setShowDailyFeedProductionDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('Sale not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };

    const onDelete = (dailyFeedProduction) => {
        currentFeedFormulation = dailyFeedProduction;
        setShowDeleteConfirm(true);
    };

    //Get all FeedFormulations API Call
    const getAllFeedFormulations = () => {
        
        showError('');
        showMessage('');

        const response = api.get('daily-feed-production');

        response.then(res => {
            setDailyFeedEntries([...res]);
            if (editMode) {
                setShowDailyFeedProductionDialog(false);
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Daily Feed Production Entries Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
        
    }

    const getAllStorageFormula = () => {
        setLoading(true);
        showError('');
        const response = api.post('/available-feed-formulation-storage');
        response.then(res => {
            setStorageFormula([...res]);
            if(editMode) {
                currentFeedFormulation = { 
                    ...currentFeedFormulation,
                    feedStorageIndex:storageFormula.findIndex((storage)=>{return storage.id === currentFeedFormulation.feedStorageID;}),
                }
                 setShowDailyFeedProductionDialog(true);
            }
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log("No storage Formula Found")
            } else {
                showError('Unknown Error')
            }
        });
        return response;
    };

    const getAllFarms = () => {
        
        showError('');
        const response = api.get('farm');
        response.then(res => {
            setFarms([...res]);
        });
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Farms Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        
        return response;
    };


    //Get all Shed API Call
    const getAllSheds = (farmID) => {
        setShed([])
        //setLoading(true);
        // showError('');
        showMessage('');
        console.log('feed farm ', farmID)
        const response = api.get(`farm/${farmID}/shed`);

        response.then(res => {
            setShed([...res]);
            if (editMode === true){
                currentFeedFormulation = { 
                    ...currentFeedFormulation,
                    farmIndex:farms.findIndex((farm)=>{return farm.id === currentFeedFormulation.farmID;}),
                    shedIndex:res.findIndex((shed)=>{return shed.id === currentFeedFormulation.shedID;}),
                }
                setShowDailyFeedProductionDialog(true);
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('No Sheds');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const getAllData = ()=>{
        setLoading(true);
        Promise.all([getAllFarms(),getAllFeedFormulations(),getAllStorageFormula()]).finally(()=>{
            setLoading(false);
        });
    }

    const onDeleteConfirm = (event) => {
        if (currentFeedFormulation.id) {
            setShowDeleteConfirm(false);
            //setLoading(true);
            showError('');
            const response = api.delete(`daily-feed-production/${currentFeedFormulation.id}`);

            response.then((res) => {
                let index = dailyFeedEntries.findIndex((src) => {
                        return src.id === currentFeedFormulation.id;
                    });
                let newDfp = [...dailyFeedEntries];
                newDfp.splice(index, 1);
                setDailyFeedEntries(newDfp);

            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('FeedFormulation not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentFeedFormulation = undefined;
            });

        }
    };

    useEffect(getAllData,[]);

    let dailyFeedProductionColumn = {
        title: 'Feed Production', field: 'date', defaultSort:'desc',
        render: rowData => <Mobile data={rowData}/>
    };

    let actions = [
        {
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit Daily Feed Production Details',
            onClick: (event, rowData) => onDailyFeedProductionEdit(rowData)
        },
        {
            icon: () => <ListAltIcon color='primary'>feed Materials</ListAltIcon>,
            tooltip: 'Open Feed Materials',
            onClick: (event, rowData) => openFeedMaterials(rowData)
        },
        {
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete Feed Formulation',
            onClick: (event, rowData) => onDelete(rowData)
        }
    ];

    if (isDesktop) {
        dailyFeedProductionColumn = {title: 'Feed Formulation', field: 'name', hidden: true};
    }


    return <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
            <Link color="inherit" href="#" onClick={() => history.push('/')} className={classes.link}>
                <HomeIcon className={classes.icon}/>
                Home
            </Link>
            <Typography color="textPrimary" className={classes.link}>
                <EventNoteIcon className={classes.icon}/>
                Daily Feed Entries
            </Typography>
        </Breadcrumbs>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Daily Feed Production
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewFeedFormulation}>Add Feed</Button>
            </Grid>
        </Grid>
        <Grid container
                spacing={1}
                justify="flex-start"
                alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    className={classes.formControl}
                    margin="normal"
                    autoOk
                    variant="inline"
                    label="From"
                    format="dd/MM/yyyy"
                    value={filterSelectedStartDate || null}
                    onChange={handleFilterStartDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardDatePicker
                    className={classes.formControl}
                    margin="normal"
                    autoOk
                    variant="inline"
                    label="To"
                    format="dd/MM/yyyy"
                    value={filterSelectedEndDate  || null}
                    onChange={handleFilterEndDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
            <Grid>
                <Button   onClick={(getAllDailyFeedByDate)} className={classes.success}>Search <SearchIcon/></Button>
                <Button  onClick={clearDateRangeFilter} className={classes.searchButton}>Clear Search <CloseIcon/></Button>
                </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'id', hidden: true},
                {
                    title: 'Date',
                    field: 'date',
                    type: 'datetime',
                    editable: 'never',
                    hidden: !isDesktop,
                    defaultSort:'desc',
                    render: rowData => {
                        return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                    }
                },
                dailyFeedProductionColumn,
                {title: 'Farm / Storage', field: 'farmName', hidden: !isDesktop, editable: 'never',
                    render: rowData => {
                        return (
                            rowData.isStorage ? `${rowData.feedStorageName} (Storage)` : rowData.farmName
                        )
                    }
                },
                {title: 'Shed', field: 'shedName', hidden: !isDesktop, editable: 'never'},
                {title: 'Quantity (in tonnes)', field: 'quantity', hidden: !isDesktop, editable: 'never',
                render:rowData=>{
                    return <span>{(rowData.quantity).toFixed(2)}</span>
                }},
                {title: 'Cost per tonne', field: 'costPerTonne', hidden: !isDesktop, editable: 'never',
                render:rowData=>{
                    return <span>{(rowData.costPerTonne).toLocaleString(user.locales,amountObj)}</span>
                }},
                {title: 'Actual Weight', field: 'actualWeight', hidden: !isDesktop, editable: 'never',
                render:rowData=>{
                    return <span>{rowData.actualWeight === undefined? 0:(rowData.actualWeight).toFixed(2)}</span>
                }},
                {title: 'Delivery Weight', field: 'deliveryWeight', hidden: !isDesktop, editable: 'never',
                render:rowData=>{
                    return <span>{(rowData.deliveryWeight).toFixed(2)}</span>
                }},
            ]}
            title='Daily Feed Production Table'
            data={dailyFeedEntries}
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                toolbar: true,
            }}
            actions={actions}


        />
        <Dailyentry deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showDailyFeedProductionDialog} handleClose={handleFeedFormulationClose} 
            onSave={onFeedFormulationSave} data={currentFeedFormulation} sheds={sheds} farms={farms} 
            onFarmChange={getAllSheds} onEdit={onDailyFeedProductionUpdate} 
            editMode={editMode}  storageformula={storageFormula} />
        <FeedMaterials showFeedMaterialsDialog={showFeedMaterialsDialog} handleFeedMaterialsClose={handleFeedMaterialsClose} 
        data={feedMaterials}/>

<ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onDeleteConfirm}
            title='Delete Daily Feed Production ?'
            subText='This action will delete the Dail Feed Productiont.Please confirm.' />
    </div>
}

export default DailyEntryView;