import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";

const useCardStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    cardActionsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    cardAction:{
        margin:theme.spacing(0),
        marginTop:theme.spacing(2),
        backgroundColor : '#ff1744',
        color : 'white'
    },
    gridMargin:{
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(3),
        textAlign: 'end'
    }
}));

const Mobile = ({data,onDelete,onEdit}) => {
    const classes = useCardStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        onDelete();
    };

    const handleEdit = () => {
        setAnchorEl(null);
        onEdit();


    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    return <Grid item>
        <Card className={classes.card}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                }
                title={`Material Name : ${data.materialName}`}
            />
            <CardContent>
                <Grid
                    container
                    justify='space-between'
                    alignItems='flex-end'
                >
                    <Grid class={classes.gridMargin}  item>
                        <Typography variant="h5" component="h2">
                            {data.materialCode}
                        </Typography>
                        <Typography color="textSecondary">
                            Material Code
                        </Typography>
                    </Grid>
                    <Grid class={classes.gridMargin}  item>
                        <Typography variant="h5" component="h2">
                            {data.materialTypeName}
                        </Typography>
                        <Typography color="textSecondary">
                            Material Type
                        </Typography>
                    </Grid>
                    <Grid class={classes.gridMargin}  item>
                        <Typography variant="h5" component="h2">
                            {data.inventoryType  === 1 ? 'Feed Mill' : 'Farm'}
                        </Typography>
                        <Typography color="textSecondary">
                            Inventory Type
                        </Typography>
                    </Grid>
                    <Grid class={classes.gridMargin}  item>
                        <Typography variant="h5" component="h2">
                            {data.minimumStockValue}
                        </Typography>
                        <Typography color="textSecondary">
                            Minimum Stock Value
                        </Typography>
                    </Grid>
                    <Grid class={classes.gridMargin}  item>
                        <Typography variant="h5" component="h2">
                            {data.openingStock}
                        </Typography>
                        <Typography color="textSecondary">
                            Opening Stock
                        </Typography>
                    </Grid>
                    <Grid class={classes.gridMargin}  item>
                        <Typography variant="h5" component="h2">
                            {data.currentStock}
                        </Typography>
                        <Typography color="textSecondary">
                            Current Stock
                        </Typography>
                    </Grid>
                    <Grid class={classes.gridMargin}  item>
                        <Typography variant="h5" component="h2">
                            {data.openingValue}
                        </Typography>
                        <Typography color="textSecondary">
                            Opening Value
                        </Typography>
                    </Grid>
                    <Grid class={classes.gridMargin}  item>
                        <Typography variant="h5" component="h2">
                            {data.storageLoss}
                        </Typography>
                        <Typography color="textSecondary">
                            Storage Loss
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Menu
            id="materialMaster-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
    </Grid>
};

export default Mobile;