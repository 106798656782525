class MaterialFunc {
    static isValidMaterial(materials,index){
        if(materials === undefined || index === undefined || index === -1 || materials[index] === undefined || materials[index] === null || materials[index].id === undefined){
            return false;
        }
        return true;
    }

    static removeMaterials(materials,removeID ){
        for(var i in materials){
            var thisMaterial = materials[i];

            if(removeID.length === 0){
                break;
            }

            for(var j in removeID){
                var id = removeID[j];
                if(thisMaterial.id === id){
                    materials.splice(i,1);
                    removeID.splice(j,1);
                }
            }
        }
        return materials;
    }
}

export {
    MaterialFunc
}