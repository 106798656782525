import makeStyles from "@material-ui/styles/makeStyles";
import React, {forwardRef} from "react";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import {NavLink as RouterLink} from "react-router-dom";
import useTheme from '@material-ui/styles/useTheme';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
// import Alert from '@material-ui/lab/Alert';
import api from '../../../common/api';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    nestedItem: {
        display: 'flex',
        borderRadius: 4,
    },
    nestedButton: {
        color: theme.palette.buttonColor.primary,
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontSize:'15px',
        fontWeight: theme.typography.fontWeightMedium,
        '&:hover': {
            color: theme.palette.navHover.text
        },
        '&:active': {
            backgroundColor: 'transparent',
            transitions:'none'
        }
    },
    iconColor: {
        color: theme.palette.icon,
    },
    nestedSubMenu: {
        //paddingLeft: theme.spacing(2),
        paddingTop:0,
        paddingBottom:0,
        paddingLeft:'0'
    },icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },button: {
        color: theme.palette.buttonColor.primary,
        padding: '10px 0px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontSize:'15px',
        fontWeight: theme.typography.fontWeightMedium,
        '&:hover': {
            color: theme.palette.navHover.text
        }
    },
    active: {
        color: theme.palette.navActive,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.navActive
        }
    },
    arrow:{
        width:'0.85em',
        color:theme.palette.navNestedMenuArrow
    }
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{flexGrow: 1}}
    >
        <RouterLink {...props} />
    </div>
));


const NestedMenu = function ({title, icon, subMenus, onClose,depth, bookmarks, getAllBookMarks}) {
    const classes = useStyles({});
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const [errorMessage, setErrorMessage] = React.useState('');
    
    const [menus, setMenus] = React.useState(subMenus)

    const handleClick = () => {
        setOpen(!open);

        if (bookmarks !== undefined) {
            subMenus.forEach(menu => {
                if (menu.nested === undefined) {
                    bookmarks.forEach(bookmark => {
                        if (menu.title === bookmark.title && menu.href === bookmark.href) {
                            menu.isBookmarked = bookmark.isBookmarked
                        }
                    })
                }
            })
        }
        setMenus(subMenus)
    };

    const removeErrorMessage = () =>{
        setErrorMessage('');
    }


    const createBookmark = (page) => {

        const response = api.post("create-new-bookmark", {
            "title": page.title,
            "href": page.href,
        })

        response.then(res => {
            // console.log("RESPONSE", res);
            let updatedMenu = []
            menus.forEach(menu => {
                if (menu.nested !== undefined && menu.nested === true) {
                    menu.subMenus.forEach(subMenu => {
                        if (subMenu.title === res.title && subMenu.href === res.href) {
                            subMenu.isBookmarked = res.isBookmarked
                        }
                    })
                } else {
                    if (menu.title === res.title && menu.href === res.href) {
                        menu.isBookmarked = res.isBookmarked
                    }
                }
                updatedMenu.push(menu)
            })
            // console.log("updatedMenu", updatedMenu);
            setMenus(updatedMenu)
            getAllBookMarks()
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Bookmark not added');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            
        });

    }
    const generateKey = () => {
        return `${new Date().getTime()}`
    }

    return <div id="error">
    {errorMessage ?
    <Alert severity="error" onClick={removeErrorMessage}>{errorMessage}</Alert>
    : ''}
    
    <React.Fragment>
        
        <ListItem button onClick={handleClick}
                  disableGutters
                  className={classes.nestedItem}
                 
        >
            <Button
                className={classes.nestedButton}
                style={{marginLeft:depth>0?theme.spacing(depth+1):0}}
            >
                <div className={classes.icon}>{icon}</div>
                {title}
            </Button>
            {open ? <ExpandLess className={classes.arrow} /> : <ExpandMore className={classes.arrow}/>}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {
                    menus.map(page => {
                        if (page.nested) {
                            return <NestedMenu getAllBookMarks={getAllBookMarks} bookmarks={bookmarks} {...page} onClose={onClose} key={page.title} depth={depth+1}/>
                        }else{
                         return <ListItem
                            className={classes.nestedSubMenu}
                            disableGutters
                            key={page.title+page.href+generateKey()}
                        >
                            <Button
                                activeClassName={classes.active}
                                className={classes.button}
                                component={CustomRouterLink}
                                to={page.href}
                                onClick={onClose}
                              /*   onTouchEnd={onClose} */
                            >
                                <div className={classes.icon}>{page.icon}</div>
                                {page.title}
                            </Button>
                            
                            <Tooltip className={classes.tooltip} title={page.isBookmarked ? "Remove from bookmarks": "Add to bookmarks"} placement="top" arrow TransitionComponent={Zoom}>
                                <IconButton onClick={() => createBookmark(page)} >
                                    <div className={classes.iconColor}>
                                        {page.isBookmarked ?
                                            <BookmarkOutlinedIcon fontSize="small" />:
                                            <BookmarkBorderOutlinedIcon fontSize="small" />
                                        }
                                    </div>
                                </IconButton>
                            </Tooltip>
                            
                        </ListItem>
                        }
                    })
                }
            </List>
        </Collapse>
    </React.Fragment>
    </div>
};

export default NestedMenu;