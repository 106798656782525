import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles({
    table: {
    //   minWidth: 650,
    },
  });


const CustomDialog = ({open, setOpen, bulkDatas, columns}) => {
    const classes = useStyles();

    const handleClose = () => setOpen(false)

    
    return  <Dialog 
    fullWidth={true}
    maxWidth="lg"
    onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle>Bulk Import Details</DialogTitle>
        <DialogContent>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                <caption>
                    <p>Total no. of rows = {bulkDatas.length}</p>
                </caption>
                
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => {
                                return <TableCell>{column.replace(/([A-Z])/g, ' $1')
                                    .replace(/^./, function(str){ return str.toUpperCase(); })}
                                    </TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {bulkDatas.map((data, index) => (
                        <TableRow 
                        key={index}>
                            <TableCell>
                            {data.error ? <ClearIcon style={{color:"red"}} /> : <CheckIcon style={{color:"green"}} />}
                            </TableCell>
                            <TableCell>{index+2}</TableCell>
                            {data.values.map((ele, ind) => (
                                ele.error ? 
                                <TableCell key={ind} style={{color:"red"}} >{ele.value}</TableCell>
                                :<TableCell key={ind}>{ele.value}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
    </Dialog>
    
}

export default CustomDialog;