import React, { useEffect, useState, useRef } from 'react';
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import DateFnsUtils from '@date-io/date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from 'html2canvas';
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../state';


window.html2canvas = html2canvas;
const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    formControl: {
        width: '48%',
        display: "inline-flex",
        marginTop: "16px",
        marginBottom: "8px"
    },
    datePicker: {
        width: '48%',
        display: "inline-flex",
        marginTop: "16px",
        marginRight:'10px',
        marginBottom: "8px"
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    singleLineBorder: {
        borderTop: 'solid 1px #F26522',
        borderBottom: 'solid 1px #F26522'
    },
    doubleLineBorder: {
        borderTop: 'double #F26522',
        borderBottom: 'double #F26522'
    },
    boldedText: {
        fontWeight: 'bold'
    },
    reportContainer: {

    },
    reportHeader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    rawMaterialName: {
        textAlign: 'center',
        textDecoration: 'underline'
    }
}));

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: '1em',
        backgroundColor: theme.palette.common.white,
        padding: '6px'
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);


const tableHeader = JSON.parse(`
[
    {
        "purchasesData" :["Details","Trays","Unit Rate","Value"]
    },
    {
        "salesData" :["Details","Trays","Free Trays","Unit Rate","Value"]
    }
]`);


const generateCSVForSection = function (csvData, heading, header,data){
    csvData = csvData + '\r\n' + heading + '\r\n';

    header.forEach((column, index) => {
        if (index === 0)
            csvData = csvData + column
        else
            csvData = csvData + ',' + column
    });
    csvData = csvData + '\r\n';
    if (data !== null && data !== undefined){
        data.forEach(row => {
            row.forEach((column, index) => {
                if (index === 0)
                    csvData = csvData + column
                
                else
                    csvData = csvData + ',' + column
            });
            csvData = csvData + '\r\n';
        });
    }
    return csvData;
}

const generateExcel = function (header, data) {
    console.debug("excel ", header, data)
    let csvData = 'Line Report' ;
    csvData = generateCSVForSection(csvData, "Purchases", header[0].purchasesData, data.purchasesData);

    csvData = generateCSVForSection(csvData, "Sales", header[1].salesData, data.salesData);

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
    element.setAttribute('download', 'report.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);


}



const LineReport = function ({ showError, match }) {
    const theme = useTheme();

    const [startDate, setStartDate] = useState(new Date());
    const [lineName, setLineName] = useState('');
    const [lineNames, setLineNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [data, setData] = useState([]);
    const [, setReport] = useState({
        truckNumber: ''
    });
    const [invoiceSetting, setInvoiceSetting] = useState({});
    const [saleTotal, setSaleTotal] = useState(0.0);
    const [purchaseTotal, setPurchaseTotal] = useState(0.0);
    const [expenseTotal, setExpenseTotal] = useState(0.0);

    useEffect(() => {
        if (startDate && lineName !== '') {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [startDate, lineName]);

    const getAllInvoiceSettings = () => {
        showError('');
        const response = api.get('invoiceSettings');
        response.then(res => {
            setInvoiceSetting(res[0]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No InvoiceSettings Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }
    const onLoad = () => {
        setLoading(true);
        Promise.all([getAllInvoiceSettings(), getAllLineSales()]).finally(() => {
            setLoading(false);
        });
    }

    const getAllLineSales = () => {
        const response = api.get('line_name');

        response.then(res => {
            setLineNames([...res]
        );

        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            console.log('line_names Loaded')
        });
    }

    useEffect(onLoad, []);

    const parseReportFromServer = function (data) {
        setData(data);
        setReport(data)
        totalPurchases(data)
        totalSales(data)
        setExpenseTotal(data.expenseData !== undefined && data.expenseData !== null 
            && Array.isArray(data.expenseData) && data.expenseData.length > 0 
            ? data.expenseData[data.expenseData.length-1].lineExpenseAmount : 0.0)
        console.log(data)
    };

    const totalPurchases = function (data) {
        let i, totalPurchases = 0
        if(data !== null  && data!== undefined && data.purchasesData !== null && data.purchasesData !== undefined){
            for(i=0; i< data.purchasesData.length; i++ ){
                totalPurchases = totalPurchases + parseInt(data.purchasesData[i][3])
            }
        }
        setPurchaseTotal(totalPurchases)
    };

    const totalSales = function (data) {
        let i, totalSales = 0
        if(data !== null  && data!== undefined && data.salesData !== null && data.salesData !== undefined){
            for(i=0; i< data.salesData.length; i++ ){
                totalSales =  totalSales + parseInt(data.salesData[i][4])
            }
        }
        setSaleTotal(totalSales)
    };


    const generateReport = function () {
        showError('');
        setLoading(true);
        const payload = {
            Date: startDate,
            lineName: lineName
        };

        const response = api.post('reports/line-report', payload);

        response.then(res => {
            parseReportFromServer(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });

    };

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });


    const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
    });
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }


    return <div className={classes.root}>
        <Grid
            container
            alignItems='flex-start'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Line Report
                </Typography>
            </Grid>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">

                <Grid item>
                    <KeyboardDatePicker
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="Date"
                        format="dd/MM/yyyy"
                        value={startDate}
                        className={classes.datePicker}
                        onChange={(date) => setStartDate(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    {/* Line Name */}
                    <FormControl className={classes.formControl}>
                        <InputLabel id="customerName-select-outlined-label">
                            Line Name
                        </InputLabel>
                        <Select
                            id="LineName-input"
                            margin="normal"
                            value={lineName}
                            onChange={(_input) => setLineName(_input.target.value)
                            }
                            name="LineSalesName-select"
                            >
                                {lineNames !== undefined && lineNames !== null ?
                                lineNames.map((lineSale,index)=>
                                <MenuItem key = {lineSale.id} value={lineSale.id}>{lineSale.name}</MenuItem>
                            ):""}     
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Button disabled={!valid} variant="contained" color="primary"
                        onClick={generateReport}>Generate</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                        onClick={generatePDF} endIcon={<PictureAsPdfIcon />}>Download
                        PDF</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                        onClick={() => generateExcel(tableHeader, data)}
                        endIcon={<GetAppIcon />}>Download Excel</Button>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
        {loading ?
            <div className={classes.progressContainer}>
                <CircularProgress className={classes.progressBar} />
            </div>
            :
            <div id="customer-invoice" ref={componentRef} style={{ paddingTop: '35px' }}>
                <div className="invoice-box" style={{
                    backgroundColor: '#ffffff',
                    fontFamily: 'Montserrat, Roboto, sans-serif'
                }}>
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr >
                                <td>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ width: "40%" }}><img src={invoiceSetting.companyLogo}
                                                                    style={{ //width: '50%', 
                                                                        maxWidth: '130px'
                                                                    }}
                                                                    alt="company logo"></img><br /><br />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "right", fontWeight: '600', fontSize: '23px' }} colSpan="2">
                                                                    Line Report<br />
                                                                    <span style={{ fontWeight: '400', fontSize: '15px' }}>
                                                                        <b>Date : </b> {moment(startDate).format('MMM Do YYYY')}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                   
                                                </td>
                                                <td style={{ textAlign: "right", width: "50%" }}>
                                                    <table style={{}}>
                                                        <thead>
                                                            <tr>
                                                                <td colSpan="2"><hr className="MuiDivider-root"></hr><br /></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                            <td style={{ textAlign: "right" }}>
                                                                    Total Purchases
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {purchaseTotal.toLocaleString(user.locales,amountObj)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: "right" }}>
                                                                    Total Sales
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {saleTotal.toLocaleString(user.locales,amountObj)}   
                                                                </td>
                                                            </tr>
                                                            <tr style={{ fontWeight: 'bold' }}>
                                                                <td style={{ textAlign: "right" }}>
                                                                    Difference
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {Math.abs(saleTotal - purchaseTotal).toLocaleString(user.locales,amountObj)}   
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style={{ minHeight: "450px" }}>
                                    <Typography variant="h4" component="h4" className={classes.rawMaterialName}>
                                     Purchases
                                    </Typography>
                                    <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>Details</StyledTableCell>
                                                    <StyledTableCell>Trays</StyledTableCell>  
                                                    <StyledTableCell>Unit Rate</StyledTableCell>  
                                                    <StyledTableCell>Value</StyledTableCell>  
    
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                            {data !== null && data !== undefined &&
                            data.purchasesData !== null && data.purchasesData !== undefined &&
                            data.purchasesData.map((row, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left">
                                                            {row[0]}
                                                        </StyledTableCell>
                            
                                                        <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left"> 
                                                        {parseFloat(row[1])}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left">  
                                                                {parseFloat(row[2]).toLocaleString(user.locales,amountObj)}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left"> 
                                                                {parseFloat(row[3]).toLocaleString(user.locales,amountObj)}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <br/><br/><br/>
                                        <Typography variant="h4" component="h4" className={classes.rawMaterialName}>
                                            Sales
                                        </Typography>
                                    <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>Details</StyledTableCell>
                                                    <StyledTableCell>Trays</StyledTableCell>  
                                                    <StyledTableCell>Free Trays</StyledTableCell>
                                                    <StyledTableCell>Unit Rate</StyledTableCell>  
                                                    <StyledTableCell>Value</StyledTableCell>  
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                            {data !== null && data !== undefined &&
                            data.salesData !== null && data.salesData !== undefined &&
                            data.salesData.map((row, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left">
                                                            {row[0]}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left"> 
                                                        {parseInt(row[1])}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left"> 
                                                        {parseInt(row[2])}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left">  
                                                                {parseFloat(row[3]).toLocaleString(user.locales,amountObj)}
                                                        </StyledTableCell>
                                                        <StyledTableCell className={
                                                            row[0].includes('Total')
                                                                ? classes.boldedText : undefined
                                                        } align="left"> 
                                                                {parseInt(row[4]).toLocaleString(user.locales,amountObj)}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <br/><br/><br/>
                                        <Typography variant="h4" component="h4" className={classes.rawMaterialName}>
                                            Expenses
                                        </Typography>
                                    <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>Expense Item</StyledTableCell>
                                                    <StyledTableCell>Amount</StyledTableCell>  
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                            {data !== null && data !== undefined &&
                            data.expenseData !== null && data.expenseData !== undefined &&
                            data.expenseData.map((row, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell  align="left">
                                                            {row.lineExpenseItem !== "Total" ? row.lineExpenseItem : ""}
                                                        </StyledTableCell>
                                                        <StyledTableCell  align="left">  
                                                                {row.lineExpenseItem !== "Total" ? row.lineExpenseAmount.toLocaleString(user.locales,amountObj):""}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                   
                                                </td>
                                                <td style={{ textAlign: "right", width: "50%" }}>
                                                    <table style={{}}>
                                                        <thead>
                                                            <tr>
                                                                <td colSpan="2"><hr className="MuiDivider-root"></hr><br /></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                            <td style={{ textAlign: "right" }}>
                                                                    Total Purchases
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {purchaseTotal.toLocaleString(user.locales,amountObj)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: "right" }}>
                                                                    Total Sales
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {saleTotal.toLocaleString(user.locales,amountObj)}   
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: "right" }}>
                                                                    Total Expense
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {expenseTotal.toLocaleString(user.locales,amountObj)}   
                                                                </td>
                                                            </tr>
                                                            <tr style={{ fontWeight: 'bold' }}>
                                                                <td style={{ textAlign: "right" }}>
                                                                    Net Profit
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {Math.abs(saleTotal - expenseTotal - purchaseTotal).toLocaleString(user.locales,amountObj)}   
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        }
    </div>
}

export default LineReport;