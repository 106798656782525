import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from "../../../common/formValidator";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import red from "@material-ui/core/colors/red";

const schema = {
  quantity: {
    presence: { allowEmpty: false, message: " is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
    },
  },
  date:{
    presence: { allowEmpty: false, message: " is required" },
  },
  formulaNameIdx: {
    presence: { allowEmpty: false, message: " is required" },
  },
  farmIdx: {
    presence: { allowEmpty: false, message: " is required" },
  },
  shedTypeIdx: {
    presence: { allowEmpty: false, message: " is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.text.default,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  progressBar: {
    margin: theme.spacing(2),
    position: "absolute",
    top: "40%",
    zIndex: "9999",
  },
  typo: {
    marginLeft: theme.spacing(0.25),
    marginTop: theme.spacing(-1),
    fontSize: "initial",
  },
  typoRed: {
    marginLeft: theme.spacing(0.25),
    marginTop: theme.spacing(-1),
    fontSize: "initial",
    color: red[900],
  },
}));

const FeedStorageStock = ({
  deleteErrorMessage,
  errorMessage,
  onEdit,
  data,
  onSave,
  handleClose,
  showDialog,
  editMode,
  match,
  shed,
  storageFormulaData,
  farms,
  onHandleFarmChange,
  handleShedDateChange
}) => {
  const classes = useStyles();
  const [formState, hasError, handleChange, updateData] =
    useFromValidator(schema);
  const [popupLoading, setPopupLoading] = useState(false);
  const [availableStock, setavailableStock] = useState(0);
  const [availableStockErr, setaavailableStockErr] = useState(null);
  const [ShowQuantityMessage, setShowQuantityMessage] = useState(null);
  const [showNotification, setshowNotification] = useState(null);

  const resetBeforeEdit = () => {
    setaavailableStockErr(null);
    if (editMode) {
      let availableStock = storageFormulaData[data.formulaNameIdx]?.availableStorage
      setShowQuantityMessage(
      `The quantity should be less than or equal to ${availableStock}`
        );
      setavailableStock(availableStock)
    }
    if (showDialog) {
      setShowQuantityMessage(null);
      setshowNotification(null);
      setPopupLoading(false);
      updateData(data, editMode);
    }
  };
  useEffect(resetBeforeEdit, [showDialog]);
  const handleFormulaChange = (event) => {
    let availableStock = storageFormulaData[event.target.value]?.availableStorage
    setShowQuantityMessage(
      `The quantity should be less than or equal to ${availableStock}`
  );
    setavailableStock(availableStock)
    handleChange(event)
  }

  const handleFarmChange = (event) => {
    let farmID = farms[event.target.value]?.id
    onHandleFarmChange(farmID)
    handleChange(event)
  }

  useEffect(()=>{
    if (editMode) {
      let availableStock = storageFormulaData[data.formulaNameIdx]?.availableStorage
      setShowQuantityMessage(
      `The quantity should be less than or equal to ${availableStock}`
        );
      setavailableStock(availableStock)
    }
  },[storageFormulaData,data,editMode])

  const handleQuantityChange = event => {
    if(event.target.value > availableStock) {
      setShowQuantityMessage(
        `The quantity should be less than or equal to ${availableStock}`
    );
    }
    handleChange(event)
  }

  const onSubmit = () => {
    setPopupLoading(true);
    const tempData = {
      ...formState.values,
      farmId: farms[formState.values.farmIdx].id,
      farmName: farms[formState.values.farmIdx].name,
      formulaId:storageFormulaData[formState.values.formulaNameIdx].id,
      formulaName: storageFormulaData[formState.values.formulaNameIdx].formulaName,
      shedId: shed[formState.values.shedTypeIdx].id,
      shedName: shed[formState.values.shedTypeIdx].name,
      costperunit:storageFormulaData[formState.values.formulaNameIdx]?.costperunit
    };
    //available stock validation
    if (tempData.quantity > availableStock) {
      setPopupLoading(false);
      return
    }
    var errorElement = document.getElementById("error");
    if (errorElement) {
      errorElement.scrollIntoView();
    }
    if (editMode) {
      onEdit(tempData, setPopupLoading);
    } else onSave(tempData, setPopupLoading);
  };




  function deleteNotification() {
    setshowNotification(null);
  }

  useEffect(() => {
    if (
      availableStock > 0 &&
      (formState.values.quantity < 0 ||
        formState.values.quantity > availableStock)
    ) {
      setshowNotification(true);
    } else {
      setshowNotification(null);
    }
    if (availableStock === 0 && showNotification === true) {
      setshowNotification(null);
    }
    // eslint-disable-next-line
  }, [formState.values.quantity, availableStock]);

  return (
    <Dialog
      disableBackdropClick
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog- title">
        {editMode ? "Update" : "New"} Feed Stock
      </DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>

          <div>
             {
               popupLoading ? 
                <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div> : ""
             }
            <div id="error">
              {errorMessage ? (
                <Chip
                  onDelete={deleteErrorMessage}
                  color="primary"
                  label={errorMessage}
                />
              ) : (
                ""
              )}
            </div>
            <br />
            <InputLabel
              id="type-select-outlined-label"
              className={
                formState.values.quantity > availableStock
                  ? classes.typoRed
                  : classes.typo
              }
            >
              {showNotification === true ? (
                <Chip
                  color="primary"
                  size="small"
                  onDelete={deleteNotification}
                  label={ShowQuantityMessage}
                  className={classes.chip}
                />
              ) : (
                ""
              )}
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                className={classes.textField}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Date"
                format="dd/MM/yyyy"
                value={formState.values.date || null}
                onChange={(data) => {
                  handleChange(
                    { target: { name: "date", value: data } },
                    handleShedDateChange
                  );
                }}
                animateYearScrolling
                name="date"
                style={{ backgroundColor: "#ffffff" }}
              />
            </MuiPickersUtilsProvider>

            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">
               Formula
              </InputLabel>
              <Select
                id="formulaName"
                value={
                  formState.values.formulaNameIdx !== undefined
                    ? formState.values.formulaNameIdx
                    : ""
                }
                onChange={(e) => {
                  handleChange(e, handleFormulaChange(e));
                }}
                name="formulaNameIdx"
              >
                  {storageFormulaData !== undefined && storageFormulaData !== null ?
                            storageFormulaData.map((formula, index) => {
                                    return <MenuItem key={formula.id} value={index}>{formula.formulaName}</MenuItem>
                                }
                            ) : []}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">
               Farm Name
              </InputLabel>
              <Select
                id="farmName"
                value={
                  formState.values.farmIdx !== undefined
                    ? formState.values.farmIdx
                    : ""
                }
                onChange={(e) => {
                  handleChange(e, handleFarmChange(e));
                }}
                name="farmIdx"
              >
                 {farms !== null &&
                farms !== undefined &&
                Array.isArray(farms)
                  ? farms.map((farm, index) => {
                      return (
                        <MenuItem key={farm.id} value={index}>
                          {farm.name}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="type-select-outlined-label">Sheds</InputLabel>
              <Select
                id="shedType"
                value={
                  formState.values.shedTypeIdx !== undefined
                    ? formState.values.shedTypeIdx
                    : ""
                }
                onChange={handleChange}
                name="shedTypeIdx"
              >
                {shed !== undefined && shed !== null
                  ? shed.map((shed, index) => (
                      <MenuItem key={shed.id} value={index}>
                        {shed.name}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>
            <InputLabel id="type-select-outlined-label">
              {formState.values !== undefined &&
              formState.values !== null &&
              formState.values.formulaNameIdx !== undefined &&
              formState.values.formulaNameIdx !== null &&
              formState.values.formulaNameIdx !== "" &&
              availableStock !== null
                ? `Available Stock: ${availableStock}`
                : availableStockErr}{" "}
            </InputLabel>

            <TextField
              className={classes.textField}
              error={hasError("quantity")}
              fullWidth
              helperText={
                hasError("quantity") ? formState.errors.quantity[0] : null
              }
              label="Quantity"
              name="quantity"
              onChange={(event) => handleQuantityChange(event)}
              type="number"
              value={formState.values.quantity || ""}
              variant="outlined"
              style={{ backgroundColor: "#ffffff" }}
            />

            <DialogActions>
              <Button
                className={classes.signInButton}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                disabled={!formState.isValid}
                onClick={onSubmit}
              >
                Save
              </Button>
            </DialogActions>
          </div>

      </DialogContent>
    </Dialog>
  );
};

export default FeedStorageStock;
