import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import api from '../../common/api'
import LinearProgress from '@material-ui/core/LinearProgress';
import {AppProgressBar} from '../../components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    content: {
        paddingTop: 150,
        textAlign: 'center'
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 560
    },
    textMiddle: {
        marginTop: theme.spacing(2)
    },
    textBotton: {
        marginTop: theme.spacing(3)
    }
}));

const FailureText = ({apiErrorText, className}) => <React.Fragment>

    <Typography variant="h4" color="secondary">
        Activation Failed : {apiErrorText}
    </Typography>
    <img
        alt="Failed"
        className={className}
        src="/images/undraw_cancel_u1it.svg"
    />
</React.Fragment>;
const SuccessText = ({className}) => <React.Fragment>
    <Typography variant="h4" color="primary">
        Your account has been activated, you will be redirected to the login page.
    </Typography>
    <LinearProgress/>
    <img
        alt="Success"
        className={className}
        src="/images/undraw_confirmation_2uy0.svg"
    />

</React.Fragment>;

const Activate = ({match, history}) => {
    const classes = useStyles();
    const key = match.params['key'];

    const [loading, setLoading] = useState(true);
    const [apiErrorText, setApiErrorText] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        setApiErrorText(undefined);

        const response = api.get('activate/' + key);
        response.catch(err => {
            if (err.message) {
                if (err.message === 'Request Not Found')
                    setApiErrorText('Invalid Activation Key');
                else
                    setApiErrorText(err.message)
            } else {
                setApiErrorText('Unknown Error')
            }
            setLoading(false);
        });

        response.then(res => {
            if (res === true) {
                setLoading(false);
                setTimeout(() => {
                    history.push('/sign-in');
                }, 4000);

            }
        });
    }, [key, history]);

    return (
        <div className={classes.root}>
            <Grid
                container
                justify="center"
                spacing={4}
            >
                <Grid
                    item
                    lg={6}
                    xs={12}
                >
                    <div className={classes.content}>
                        {
                            loading ?
                                <AppProgressBar visible={loading}/>
                                : apiErrorText ?
                                <FailureText apiErrorText={apiErrorText} className={classes.image}/> :
                                <SuccessText className={classes.image}/>
                        }

                    </div>
                </Grid>
            </Grid>
        </div>
    );
};


export default Activate;