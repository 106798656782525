import React, {useEffect,useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button"; 
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider,DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';

var schema = {
    paymentDate: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    paymentAmount: {
        presence: {allowEmpty: false, message: 'is required'},
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 0,
        }
    },
    paymentMode: {
        presence: {allowEmpty: false, message: 'is required'},
        type: 'number',
    },
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        paddingBottom: theme.spacing(1.25)
    },
    textField: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(0)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    datePicker: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth:160
    }
}));

const Payment = ({deleteErrorMessage, errorMessage, data, currentPaymentFor ,banks, onSalePayment, onPurchasePayment, 
    handlePaymentClose, showPaymentDialog, editMode=false}) => {
    const classes = useStyles();
    const [formState,hasError,handleChange,updateData] = useFromValidator(schema);
    const [showBanks, setShowBanks] = useState(false)

    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if(showPaymentDialog){
            updateData(data, editMode);
        }
    };
    useEffect(resetBeforeEdit, [showPaymentDialog]);

    const onSubmit = () => {
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        let tempData = {
            ...formState.values
        };
        if(tempData.paymentMode === 2){
            tempData = {
                ...tempData,
                paymentBankId:banks[formState.values.bankIndex].id,
                paymentBankName:{
                    String:banks[formState.values.bankIndex].name,
                }
            }
        }
        if(currentPaymentFor === 'purchase'){
            onPurchasePayment(tempData);
        }else if(currentPaymentFor === 'sale'){
            onSalePayment(tempData);
        }
        console.debug(tempData)
    };

    const handleChangePaymentMode = (event) => {
        if(event.target.name === "paymentMode" && event.target.value === 2){
            console.debug("in handle ",event)
            schema = {
                paymentDate: {
                    presence: {allowEmpty: false, message: 'is required'}
                },
                paymentAmount: {
                    presence: {allowEmpty: false, message: 'is required'},
                    type: 'number',
                    numericality: {
                        greaterThanOrEqualTo: 0,
                    }
                },
                paymentMode: {
                    presence: {allowEmpty: false, message: 'is required'},
                    type: 'number',
                },
                bankIndex: {
                    presence: {allowEmpty: false, message: 'is required'},
                    type: 'number',
                },
            }
            setShowBanks(true)
        }else{
            schema = {
                paymentDate: {
                    presence: {allowEmpty: false, message: 'is required'}
                },
                paymentAmount: {
                    presence: {allowEmpty: false, message: 'is required'},
                    type: 'number',
                    numericality: {
                        greaterThanOrEqualTo: 0,
                    }
                },
                paymentMode: {
                    presence: {allowEmpty: false, message: 'is required'},
                    type: 'number',
                },
            }
            setShowBanks(false)
        }
    }
  

    return <Dialog disableBackdropClick open={showPaymentDialog} onClose={handlePaymentClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >Payment
        <IconButton aria-label="close" className={classes.closeButton} onClick={handlePaymentClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
            <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
            <DatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Payment Date"
                format="dd/MM/yyyy"
                value={formState.values.paymentDate || null}
                onChange={data => {
                    handleChange({target: {name: 'paymentDate', value: data}})
                }}
                animateYearScrolling
                name = "paymentDate"
                style={{backgroundColor:"#ffffff"}}
            />
            </MuiPickersUtilsProvider>
            <TextField
                className={classes.textField}
                error={hasError('paymentAmount')}
                helperText={
                    hasError('paymentAmount') ? formState.errors.paymentAmount[0] : null
                }
                label="Amount"
                name="paymentAmount"
                onChange={handleChange}
                type="number"
                value={formState.values.paymentAmount || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <FormControl  className={classes.formControl}>
                <InputLabel id="paymentMode-select-outlined-label">
                Payment Mode
                </InputLabel>
                <Select
                id="paymentMode"
                value={formState.values.paymentMode || ''}
                onChange={event =>{handleChange(event,handleChangePaymentMode)}}
                name="paymentMode"
                >
                    <MenuItem key = {0} value={1}>Cash</MenuItem>
                    <MenuItem key = {1} value={2}>Bank</MenuItem>
                </Select>
            </FormControl>
            {showBanks ? 
            <FormControl className={classes.formControl}>
                <InputLabel id="banks-select-label">Banks</InputLabel>
                <Select
                id="banks-select"
                value={formState.values.bankIndex !== undefined ? formState.values.bankIndex : ''}
                onChange={handleChange}
                name="bankIndex"
                >
                {banks.map((bank,index)=>
                    <MenuItem key = {bank.id} value={index}>{bank.name}</MenuItem>
                )}
                </Select>
            </FormControl>
            :null}
            <TextField
                multiline
                rows="3"
                className={classes.textField}
                error={hasError('paymentNote')}
                fullWidth
                helperText={
                    hasError('paymentNote') ? formState.errors.paymentNote[0] : null
                }
                label="Note"
                name="paymentNote"
                onChange={handleChange}
                type="text"
                value={formState.values.paymentNote || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
};

export default Payment;