import React, {useState, useEffect} from "react"
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog,MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import LinePurchaseBulkPayment from './components/linePurchaseBulkPayment';


const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentPayment = undefined;
let editMode = false;

const LinePurchaseBulkPayments = ({showError, showMessage}) => {

    const [loading, setLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const [banks, setBanks] = useState([]);
    const [lineCustomerNames, setLineCustomerNames] = useState([]);
    const [, setAccountTypes]= useState([]);
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    
    const classes = useStyles({isDesktop});

    const onNewBank = (event) => {
        editMode = false;
        console.log(editMode)
        event.preventDefault();
        setShowPaymentDialog(true);
    }

    const handlePaymentClose = () => {
        setShowPaymentDialog(false);
        setErrorMessage(null);
    }

    const handleDeleteConfirmClose = () => {
        setShowDeleteConfirm(false);
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    // On payment save
    const onPaymentSave = (payment, errorElement, setPopupLoading) => {
        //setLoading(true);
        showMessage('');
        const response = api.post('linePurchaseBulkPayment', {
            ...payment,
        });

        response.then(res => {
            setPayments([...payments, res]);
            setShowPaymentDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Bank not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
            if (errorElement) {
                errorElement.scrollIntoView();
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    }
    
    // On payment edit
    const onPaymentEdit = (payment, errorElement) => {
        console.log("Payment: ", payment);
        //setLoading(true);
        // showError('');
        payment.amount= parseInt(payment.amount)
        const response = api.post(`linePurchaseBulkPayment/${payment.id}`, {
            ...payment,
    
        });
        response.then((res) => {
            console.log("Payment Edit: ", res);
            let index = payments.findIndex((srcPayment) => {
                return srcPayment.id === payment.id;
            });
            setPayments([...payments.slice(0, index),
                {...res},
                ...payments.slice(index + 1)]);
            setShowPaymentDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('Payment not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
            if (errorElement) {
                errorElement.scrollIntoView();
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    // On payment delete confirm
    const onPaymentDeleteConfirm = () => {
        if (currentPayment && currentPayment.id) {
            setShowDeleteConfirm(false);
            //setLoading(true);
            showError('');
            const response = api.delete(`linePurchaseBulkPayment/${currentPayment.id}`);
    
            response.then(res => {
                if (res === 1) {
                    let index = payments.findIndex((srcPayment) => {
                        return srcPayment.id === currentPayment.id;
                    });
                    let newPayments = [...payments];
                    newPayments.splice(index, 1);
                    setPayments(newPayments);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('Payment not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentPayment = undefined;
            });
    
        }
    };

    // Get all banks
    const getAllBanks = () => {
        setShowPaymentDialog(false);
        showError('');
        showMessage('');

        const response = api.get('bank');

        response.then(res => {
          
            setBanks([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        })
        return response;
    }
    
    const getAllLineCustomerNames = () => {
        // line_name
        showError('');
        showMessage('');

        const response = api.get('get-line-vendors');

        response.then(res => {
            console.log("RESPONSE", res);
            setLineCustomerNames([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Customers Found');
                else
                    console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        });

        return response;
    }
    
    const getAllAccountTypes = () => {
        showError('');
        showMessage('');

        const response = api.get('accountTypes');
        response.then(res => {
            setAccountTypes([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log('No AccountType Found Catch Error');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }

    const getAllPayments = () => {
        let payload ={};
        
        showError('');
        showMessage('');

        const response = api.get('getAllLinePurchaseBulkPayments', payload);
        response.then(res => {
            console.log("All line purchase payments: ", res);
            setPayments([...res]);
            
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Payments Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }

    const onload=()=>{
        setLoading(true);
        Promise.all([getAllBanks(),getAllAccountTypes(),getAllLineCustomerNames(),getAllPayments()]).finally(()=>{
            setLoading(false);
        });
    }
    useEffect(onload, []);

    const editPayment=(data)=>{
        editMode = true
        currentPayment = data
        setShowPaymentDialog(true);
    }

    const onDelete = (payment) => {
        currentPayment = payment;
        setShowDeleteConfirm(true);
    };

    let actions = [
        {
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit Bulk Payment',
            onClick: (event, rowData) => editPayment(rowData)
        },
        {
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete Bulk Payment',
            onClick: (event, rowData) => onDelete(rowData)
        },
    ];

    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}
        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Line Purchase Bulk Payments
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewBank}>Add Bulk Payment</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                {title: 'id', field: 'id', hidden : true},
                {title: 'Name', field: 'customerName', editable: 'never'},
                {title: 'Date', field: 'paymentDate', editable: 'never', defaultSort: 'desc',
                    render: rowData => {
                        return <span>{moment(rowData.paymentDate).format("DD-MM-YYYY")}</span>
                    }},
                {title: 'Amount', field: 'paymentAmount', editable: 'never'},
            ]}
            data={payments}
            title='Bulk Payments Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                toolbar: true
            }}
            actions={actions}
        />
        <LinePurchaseBulkPayment deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} banks={banks}  onSave={onPaymentSave} handlePaymentClose={handlePaymentClose} 
        showPaymentDialog={showPaymentDialog} customerNames={lineCustomerNames} onEdit={onPaymentEdit}
         editMode={editMode} data={currentPayment}>
        </LinePurchaseBulkPayment>
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onPaymentDeleteConfirm}
                       title='Delete Bulk Payment?'
                       subText='This action will delete the Bulk Payment. Please confirm.'/>
        </div>
}

export default LinePurchaseBulkPayments;