import React, { useEffect, useState } from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { ConfirmDialog, MaterialTable } from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpensesPopup from "./components/expenses";
import Icon from '@material-ui/core/Icon';
import moment from 'moment';



const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}));

let currentExpense = undefined;
let editMode = false;

const Expenses = ({ showError, showMessage, history }) => {

    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showPaymentDialog, setShowPaymentDialog] = useState(false)
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [expenseTypes, setExpenseTypes] = useState(null);
    const [lineData, setLineData] = useState(null);

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    function onNewBank(event) {
        editMode = false;
        console.log(editMode)
        event.preventDefault();
        setShowPaymentDialog(true);
    }

    function handleDeleteConfirmClose() {
        setErrorMessage(null);
        setShowDeleteConfirm(false);
    }


    function getExpenseTypes() {
        return api.get(`expense-type`)
            .then((res) => {
                if (res !== undefined && res !== null && Array.isArray(res) && res.length > 0) {
                    let lineFilteredExpenseType = res.filter((el)=>{return el.isLineEnabled === 1})
                    setExpenseTypes(lineFilteredExpenseType);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    function getLineData() {
        return api.get(`line_name`)
            .then((res) => {
                setLineData(res);
            })
            .catch((err) => {
                console.log(err);
            })
    }


    const onDelete = (payment) => {
        currentExpense = payment;
        setShowDeleteConfirm(true);
    };

    const removeErrorMessage = value => {
        setErrorMessage('');
    }

    const onExpensesSave = (expense, errorElement) => {
        setLoading(true);
        //showError('');
        showMessage('');
        const response = api.post('line-sale-expenses', {
            ...expense,
        });

        response.then(res => {
            setShowPaymentDialog(false);
            setExpenses([...expenses, { ...res }])
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Bank not added');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };


    const onExpensesEdit = (expense, errorElement) => {
        setLoading(true)
        const response = api.post(`line-sale-expenses/${expense.id}`, {
            ...expense,
        });
        response.then((res) => {
            let index = expenses.findIndex((el) => { return el.id === res.id })
            setExpenses([...expenses.slice(0, index), { ...res }, ...expenses.slice(index + 1)])
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
            setShowPaymentDialog(false);
        });
    };

    const onExpensesDeleteConfirm = () => {
        if (currentExpense && currentExpense.id) {
            setShowDeleteConfirm(false);
            // setLoading(true);
            showError('');
            const response = api.delete(`line-sale-expenses-delete/${currentExpense.id}`);
            response.then((res) => {
                let index = expenses.findIndex((el) => { return el.id === currentExpense.id })
                setExpenses([...expenses.slice(0, index), ...expenses.slice(index + 1)])
            }).catch((err) => {
                showError("Delete Error")
                console.log(err);
            }).finally(() => {
                setLoading(false);
                currentExpense = undefined;
            });

        }
    };

    function handlePaymentClose() {
        //currentExpense = undefined;
        setErrorMessage(null);
        setShowPaymentDialog(false);
    }

    function getAllExpenses() {
        return api.get(`line-sale-expenses`)
            .then((res) => {
                setExpenses(res)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const onload = () => {
        setLoading(true);
        Promise.all([getExpenseTypes(), getLineData(), getAllExpenses()]).finally(() => {
            setLoading(false);
        });
    }
    useEffect(onload, []);

    const editExpenses = (data) => {
        editMode = true;
        currentExpense = data;
        let lineIndex = lineData.findIndex((el) => { return el.id === data.lineID });
        // let expenseIndex = expenses.findIndex((el) => {return el.id === data.id})
        currentExpense.lineNameIdx = lineIndex;
        // currentExpense.expenseTypes = expenses[expenseIndex].expenseTypes;
        setShowPaymentDialog(true);
    }


    let actions = [
        {
            icon: () => <Icon color='primary'>edit</Icon>,
            tooltip: 'Edit Expenses',
            onClick: (event, rowData) => editExpenses(rowData)
        },
        {
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete Expenses',
            onClick: (event, rowData) => onDelete(rowData)
        },
    ];


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Expenses
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewBank}>Add Expenses</Button>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Id', field: 'id', hidden: true },
                {
                    title: 'Date', field: 'date', editable: 'never', defaultSort: 'desc',
                    render: rowData => {
                        return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
                    }
                },
                {
                    title: 'Line Name', editable: 'never', field: "lineName", render: function (rowData) {
                        return <span>{rowData.lineName}</span>
                    }
                },
            ]}
            data={expenses}
            title='Expenses Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,

                toolbar: true
            }}
            actions={actions}
        />
        <ExpensesPopup deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} onSave={onExpensesSave} handlePaymentClose={handlePaymentClose}
            showPaymentDialog={showPaymentDialog} onEdit={onExpensesEdit}
            lineData={lineData} expenseTypes={expenseTypes}
            editMode={editMode} data={currentExpense}>
        </ExpensesPopup>
        <ConfirmDialog showDialog={showDeleteConfirm}
            handleClose={handleDeleteConfirmClose}
            onConfirm={onExpensesDeleteConfirm}
            title='Delete Expense ?'
            subText='This action will delete the Expense Details. Please confirm.' />
    </div>
}

export default Expenses;