import React, {useEffect, useState, useRef} from 'react';
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from 'html2canvas';
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../state';


window.html2canvas = html2canvas;
const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    formControl: {
        width: '100%',
        marginTop: theme.spacing(0.5),
        justifyContent: 'center',
        display: 'flex',
        marginBottom: theme.spacing(-0.5),


    },
    formControlMaterialName: {
        width: '100%',
        marginTop: theme.spacing(1),
        justifyContent: 'center',
        display: 'flex',
        marginRight: theme.spacing(14),
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    progressContainerMaterial: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        size: 10,
        height:'1%',
        width:'1%',
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    progressBarMaterial: {
        margin: theme.spacing(0.0)
    },
    singleLineBorder: {
        borderTop: 'solid 1px #F26522',
        borderBottom: 'solid 1px #F26522'
    },
    doubleLineBorder: {
        borderTop: 'double #F26522',
        borderBottom: 'double #F26522'
    },
    reportContainer:{
       
    },
    reportHeader:{
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(2)
    },
    rawMaterialName:{
        textAlign:'center',
        textDecoration:'underline'
    }
}));

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: '1em',
        backgroundColor: theme.palette.common.white,
        padding:'6px'
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);


const tableHeader = JSON.parse(`[["Date","Operation","Party Name", "In", "Out", "Stock", "Rate", "Value"]]`);




const generateExcel = function (header, data) {
    let csvData = '';
    header.forEach((column, index) => {
        if (index === 0)
            csvData = csvData + column
        else
            csvData = csvData + ',' + column
    });
    csvData = csvData + '\r\n';
    data.forEach(row => {
        row.forEach((column, index) => {
            if (index === 0)
                csvData = csvData + column
            else if (index === 1)
                csvData = csvData + ''
            else
                csvData = csvData + ',' + column
        });
        csvData = csvData + '\r\n';
    });

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
    element.setAttribute('download', 'report.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);


}

const RawMaterials = function ({showError}) {
    const theme = useTheme();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [materialTypes, setMaterialTypes] = useState([]);
    const [materialNames, setMaterialNames] = useState([]);
    const [materialTypeIndex, setMaterialTypeIndex] = useState('');
    const [materialIndex, setMaterial] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingMaterial, setLoadingMaterial] = useState(false);
    const [valid, setValid] = useState(false);
    const [data, setData] = useState([]);
    const [showMaterialNames,setShowMaterialNames]= useState(false)

    const loadMaterialTypeList = function () {
        showError('');
        setLoadingMaterial(true);
        const response = api.get('material-types-for-raw-materials');
        response.then(res => {
            setMaterialTypes([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error during API call for MaterialType')
            }
        }).finally(() => {
            setLoadingMaterial(false);
        });
    };

    const getRawMaterialNameForGivenId = function (materialId) {
        showError('');
        const response = api.get(`raw-material-list-for-material-type/${materialId}`);
        response.then(res => {
            setMaterialNames([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error during API call for MaterialName')
            }
        }).finally(() => {
            setLoadingMaterial(false);
        });
    };
    useEffect(loadMaterialTypeList, [showError]);
    useEffect(() => {

        if (startDate && endDate && materialIndex !== '' && (startDate.getTime() < endDate.getTime())) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [startDate, endDate, materialIndex]);

    const parseReportFromServer = function (data) {
        const {reportsArray} = data;
        setData(reportsArray);
        console.log(data)
    };

    const generateReport = function () {
        showError('');
        setLoading(true);
        const payload = {
            materialMasterID: materialNames[materialIndex].id,
            materialName: materialNames[materialIndex].materialType,
            fromDate: startDate,
            toDate: endDate
        };

        const response = api.post('reports/material-wise', payload);

        response.then(res => {
            parseReportFromServer(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });

    };

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
      });
    const [{user}] = useGlobalState();
    const amountObj = {
          style: "currency",
          currency: user.currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }



    return <div className={classes.root}>
        <Grid
            container
            alignItems='flex-start'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Raw Materials Report
                </Typography>
            </Grid>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container
                  spacing={3}
                  justify="flex-start"
                  alignItems="center">
                <Grid item>
                    <KeyboardDatePicker
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="From"
                        format="dd/MM/yyyy"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>

                <Grid item>
                    <KeyboardDatePicker
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="To"
                        format="dd/MM/yyyy"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
                
                {
                loadingMaterial
                 ?<div className={classes.progressContainerMaterial}>
                 <CircularProgress className={classes.progressBarMaterial}/>
                 </div> :
                 <Grid item xs={2}> 
                <FormControl  className={classes.formControl}
                spacing={3}
                margin="normal">
                <InputLabel id="farm-select-outlined-label">
                Material Type
                </InputLabel>
                <Select
                id="materialTypeid-select"
                value={materialTypeIndex !== undefined ?materialTypeIndex : ''}
                onChange={(event) =>{
                    getRawMaterialNameForGivenId(materialTypes[event.target.value].id)
                    setShowMaterialNames(true)
                    setLoadingMaterial(true)
                    setMaterialTypeIndex(event.target.value)}}
                name="materialTypeIndex"
                >
                {materialTypes.map((materialType,index)=>
                    <MenuItem key = {materialType.id} value={index}>{materialType.materialType}</MenuItem>
                )}
                </Select>  </FormControl></Grid>}<Grid>
                {showMaterialNames ?
                    <FormControl className={classes.formControlMaterialName}
                    spacing={3}>
                        <InputLabel id="materialName-select-label">Material Name</InputLabel>
                        <Select
                        id="materialName-select"
                        value={materialIndex !== undefined ? materialIndex : ''}
                        onChange={(event) => setMaterial(event.target.value)}
                        name="materialIndex"
                        >
                        {materialNames.map((material,index)=>
                            <MenuItem key = {material.id} value={index}>{material.name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                :
                    ""
                }
                </Grid>
                <Grid item>
                    <Button disabled={!valid} variant="contained" color="primary"
                            onClick={generateReport}>Generate</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                            onClick={generatePDF} endIcon={<PictureAsPdfIcon/>}>Download
                        PDF</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                            onClick={() => generateExcel(tableHeader, data)}
                            endIcon={<GetAppIcon/>}>Download Excel</Button>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
        {
            loading ?
                <div className={classes.progressContainer}>
                    <CircularProgress className={classes.progressBar}/>
                </div> : 
               <div id='report-container' className={classes.reportContainer} ref={componentRef}>
                    <div className={classes.reportHeader}>
                        <Typography variant="body1" component="p">
                            <b>Period</b> {moment(startDate).format('DD/MM/YYYY')} to {moment(endDate).format('DD/MM/YYYY')}
                        </Typography>
                        
                        <Typography variant="h4" component="h4" className={classes.rawMaterialName}>
                            {materialNames[materialIndex] && materialNames[materialIndex].name ? `Raw Materials Report for ${materialNames[materialIndex].name}` : '' }
                        </Typography>
                    </div>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Date</StyledTableCell>
                                
                                <StyledTableCell>Operation</StyledTableCell>
                                <StyledTableCell>Party Name</StyledTableCell>
                                <StyledTableCell>In</StyledTableCell>
                                <StyledTableCell>Out</StyledTableCell>
                                <StyledTableCell>Stock</StyledTableCell>
                                <StyledTableCell>Rate</StyledTableCell>
                                <StyledTableCell>Value</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell style={{'fontWeight': 'bold'}} align="left">
                                        {row[0] ? moment(row[0]).format('DD/MM/YYYY') : ''}
                                    </StyledTableCell>
                                    
                                    <StyledTableCell align="left"> {row[2]}</StyledTableCell>
                                    <StyledTableCell align="left"> {row[3]}</StyledTableCell>
                                    <StyledTableCell
                                        className={row[4] > 0 && (row[6] > 0) ? classes.singleLineBorder : (row[8] > 0 && row[4] > 0) ? classes.doubleLineBorder : undefined}
                                        align="left"> {row[4] > 0 ? parseFloat(row[4]).toFixed(2) : ''}</StyledTableCell>
                                    <StyledTableCell
                                        className={row[5] > 0 && (row[6] > 0) ? classes.singleLineBorder : (row[8] > 0 && row[5] > 0) ? classes.doubleLineBorder : undefined}
                                        align="left"> {row[5] > 0 ? parseFloat(row[5]).toFixed(2) : ''}</StyledTableCell>
                                    <StyledTableCell align="left"
                                                     style={{'fontWeight': 'bold'}}> {row[6] !== "" ? parseFloat(row[6]).toFixed(2) : ''}</StyledTableCell>
                                    <StyledTableCell
                                        align="left">  {row[7] !== "" ?'':row[7]}{row[7] !== "" ? parseFloat(row[7]).toLocaleString(user.locales,amountObj) : ''}
                                    </StyledTableCell>
                                    <StyledTableCell className={row[8] !== "" ? classes.doubleLineBorder : undefined}
                                                     align="left">  {row[8] !== "" ?'':row[8]}{row[8] !== "" ? parseFloat(row[8]).toLocaleString(user.locales,amountObj) : ''}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
        }
    </div>
}

export default RawMaterials;