import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import { Divider } from '@material-ui/core';
import SegmentationGraph from './segmentationGraph';
import {useGlobalState} from '../../../state';




const useStyles = makeStyles(theme => ({
    searchGrid: {
        padding: theme.spacing(2)
    },
    loaderGrid: {
        verticalAlign: 'middle',
        borderRight: '1px solid #bfbfbf',
        padding: theme.spacing(2),
        textAlign: 'center',
        display: 'table-cell'
    },
    card: {
        minHeight: '100%',
        minWidth: '94%',
        marginTop: theme.spacing(2),
        display: 'table',
        width: '98.5%',
        tableLayout: 'fixed'
    },
    paddingCenter: {
        padding: theme.spacing(2),
        textAlign: 'center',
        display: 'table-cell'
    }
}));



const Purchases = ({ purchaseSegmentationGraphLoading, purchaseSegmentationGraph }) => {
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }



    return <>
        {purchaseSegmentationGraphLoading ?
            <Card className={classes.card}>
                <Grid className={classes.loaderGrid} item xs={12}>
                    <CircularProgress className={classes.progressBar} />
                </Grid>
            </Card>
            :
            <Card className={classes.card}>
                <CardHeader title="Purchases" className={classes.header} />
                <Divider />
                <CardContent>
                    <Grid>
                        <SegmentationGraph data={purchaseSegmentationGraph.data}
                            label={purchaseSegmentationGraph.label} legendProp={false} />
                    </Grid>
                    <Grid className={classes.searchGrid} > <Divider /></Grid>

                    <Grid
                        container
                        justify='space-between'
                        alignItems='flex-end'
                    >
                        {Object.keys(purchaseSegmentationGraph.materialTypeMap).map((name, key) => (
                            <Grid className={classes.paddingCenter} key={key} id={key} item xs={4}>
                                <Typography color="textSecondary">
                                    {name}
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    {purchaseSegmentationGraph.materialTypeMap[name].toLocaleString(user.locales,amountObj)}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        }
    </ >
};


export default Purchases;