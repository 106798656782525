import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import Button from "@material-ui/core/Button";
import api from "../../common/api";
import Typography from "@material-ui/core/Typography";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import { FinancialStats,Earnings,Purchases,Sales,Summary } from './components';


const useStyles = makeStyles(theme => ({
    root: {
        // padding: theme.spacing(2)
        marginTop: '0%'
    },
    grid: props => ({
        marginTop: theme.spacing(2),
        marginRight: props.isDesktop ? theme.spacing(2) : theme.spacing(0)
    }),
    commonGrid: {
        paddingTop: theme.spacing(2),
        marginTop: theme.spacing(3),
    },
    segmentationGrid: props => ({
        width: props.isDesktop ? '33%' : '100%',
        paddingRight: props.isDesktop ? theme.spacing(2) : theme.spacing(0),
        marginTop: theme.spacing(2),
        //marginBottom: theme.spacing(4),
    }),
    saleSegmentationGrid: props => ({
        width: props.isDesktop ? '33%' : '100%',
        marginTop: theme.spacing(2),
        //marginBottom: theme.spacing(4),
    }),
    purchaseSegmentationGrid: props => ({
        width: props.isDesktop ? '33%' : '100%',
        paddingRight: props.isDesktop ? theme.spacing(2) : theme.spacing(0),
        marginTop: theme.spacing(2),
        //marginBottom: theme.spacing(4),
    }),
    searchGrid: {
        padding: theme.spacing(2)
    }
}));





const Dashboard = ({ showError, match }) => {
    var [startDate, setStartDate] = useState(new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm')));
    var [endDate, setEndDate] = useState(new Date(moment().endOf('month').format('YYYY-MM-DD hh:mm')));
    const [valid, setValid] = useState(false);
    const [netProfitLoading, setNetProfitLoading] = useState(true);
    const [netProfitData, setNetProfitData] = useState({});
    const [purchaseSegmentationGraph, setPurchaseSegmentationGraph] = useState({});
    const [saleSegmentationGraph, setSaleSegmentationGraph] = useState({});
    const [purchaseSegmentationGraphLoading, setPurchaseSegmentationLoading] = useState(true);
    const [saleSegmentationGraphLoading, setSaleSegmentationGraphLoading] = useState(true);



    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });

    useEffect(() => {
        if (startDate && endDate && (startDate.getTime() < endDate.getTime())) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [startDate, endDate]);


    const getNetProfit = () => {
        setNetProfitLoading(true);
        console.debug(startDate, endDate)
        showError('');
        const payload = {
            startDate: startDate,
            endDate: endDate
        };

        const response = api.post('getNetProfits', payload);

        response.then(res => {
            setNetProfitData(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setNetProfitLoading(false);
        });

    }

    const getPurchaseSegmentationGraph = () => {
        showError('');
        setPurchaseSegmentationLoading(true);
        console.debug(startDate, endDate)
        const payload = {
            startDate: startDate,
            endDate: endDate
        };

        const response = api.post('getPurchaseSegmentationGraph', payload);

        response.then(res => {
            setPurchaseSegmentationGraph(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setPurchaseSegmentationLoading(false);
        });

    }

    const getSaleSegmentationGraph = () => {
        showError('');
        setSaleSegmentationGraphLoading(true);
        console.debug(startDate, endDate)
        const payload = {
            startDate: startDate,
            endDate: endDate
        };

        const response = api.post('getSaleSegmentationGraph', payload);

        response.then(res => {
            setSaleSegmentationGraph(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setSaleSegmentationGraphLoading(false);
        });

    }



    const onload = () => {
        
        getNetProfit()
        getPurchaseSegmentationGraph()
        getSaleSegmentationGraph()
        
    }

    const onGenerate = () => {
        getNetProfit()
        getPurchaseSegmentationGraph()
        getSaleSegmentationGraph()
    }

    useEffect(onload, []);




    
    return (
        <div className={classes.root}>

            <Grid className={classes.searchGrid}>
                <Grid>
                    <Typography display="block" variant="h3" gutterBottom>
                        Dashboard
                </Typography>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container
                        spacing={3}
                        justify="flex-start"
                        alignItems="center">

                        <Grid item>
                            <KeyboardDatePicker
                                variant="inline"
                                autoOk
                                margin="normal"
                                label="From"
                                format="dd/MM/yyyy"
                                value={startDate}
                                onChange={(date) => setStartDate(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>

                        <Grid item>
                            <KeyboardDatePicker
                                variant="inline"
                                autoOk
                                margin="normal"
                                label="To"
                                format="dd/MM/yyyy"
                                value={endDate}
                                onChange={(date) => setEndDate(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid item style={{ paddingBottom: "0px" }}>
                            <Button disabled={!valid} variant="contained" color="primary"
                                onClick={onGenerate}>Generate</Button>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid>
                <Summary netProfitLoading={netProfitLoading} netProfitData={netProfitData}/>
            </Grid >
            <Grid container >
                <Grid item //xs={isDesktop ? 4 : 12}

                    className={classes.segmentationGrid}>
                    <Earnings netProfitLoading={netProfitLoading}
                        netProfitData={netProfitData} />
                </Grid>
                
                <Grid item //xs={isDesktop ? 5 : 12} 
                    className={classes.purchaseSegmentationGrid}>
                    <Purchases purchaseSegmentationGraphLoading={purchaseSegmentationGraphLoading} purchaseSegmentationGraph={purchaseSegmentationGraph}/>
                </Grid >

                <Grid item //xs={isDesktop ? 5 : 12} 
                    className={classes.saleSegmentationGrid}>
                    <Sales saleSegmentationGraphLoading={saleSegmentationGraphLoading} saleSegmentationGraph={saleSegmentationGraph}/>
                </Grid >
                <FinancialStats />
            </Grid>

        </div>
    );
};



export default Dashboard;