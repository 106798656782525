import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
//import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { BottomMenu, SidebarNav } from "./components";
import { Link as RouterLink } from "react-router-dom";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import {useGlobalState} from '../../../../state';
import { version } from "../../../../common/enums";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import {Profile} from "../../../components/Sidebar";
import { ACCOUNTING, CPANEL, DASHBOARD, DOCTOR_DASHBOARD, FARMS, FEED_MILL, LINE_SALES, SETTINGS, TAXES, removeThisPage,DAILYFEED } from "../../../../pages/main";


const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      height: "100%"
    }
  },
  root: {
    backgroundColor: theme.palette.navBackground.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "auto",
    color:"#546E7A",
    zIndex:100,
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  logo: {
    width: 60
  },
  logoContainer: {
    // marginLeft: '24%',
    textAlign: "center"
  }
}));


export let pages = ({ user,isDesktop }) => {
  let _pages = [];
  let udhayaPages = [];

  if (user.accountID === 2) {
    udhayaPages = [
      CPANEL
    ]
  }

  switch(user.versionID){
    case version.lite:
      switch(user.role){
        case "SUPER":
          _pages = [FARMS];
          break;
        case "FEEDSUPER":
          _pages = [DAILYFEED];
          break;
  
        case "DRIVER":
          _pages = []
          break;
  
        case "DOCTOR":
          _pages = [
            FARMS,
            SETTINGS.doctorProfile
          ]
          break;

        case "STAFF":
          _pages = [
            FARMS,
            {
              nested: true,
              title: "Settings",
              icon: <SettingsOutlinedIcon />,
              subMenus: [
                SETTINGS.breeds,
                SETTINGS.materialMaster
              ]
            }
          ]
          break;
        
        default:
          _pages = [
            FARMS,
            {
              nested: true,
              title: "Settings",
              icon: <SettingsOutlinedIcon />,
              subMenus: [
                SETTINGS.breeds,
                SETTINGS.materialMaster,
                ...(isDesktop ? [SETTINGS.billing] : []),
                SETTINGS.subUsers,
                SETTINGS.profile
              ]
            }
          ];
          break;
        }
      break;
    
    case version.pro:
      switch(user.role){
        case "SUPER":
          _pages = [FARMS];
          break;
        case "FEEDSUPER":
            _pages = [DAILYFEED];
            break;
  
        case "DRIVER":
          _pages = []
          break;
  
        case "DOCTOR":
          _pages = [
            FARMS,
            SETTINGS.doctorProfile
          ]
          break;

        case "STAFF":
          _pages = [
            FARMS,
            ACCOUNTING.purchases,
            ACCOUNTING.sales,
            {
              title: "Daily Egg Rates",
              href: "/daily-egg-rates",
              icon: <AccountBalanceOutlinedIcon />,
              isBookmarked: false
            },
            {
              nested: true,
              title: "Settings",
              icon: <SettingsOutlinedIcon />,
              subMenus: [
                SETTINGS.breeds,
                SETTINGS.materialMaster,
                SETTINGS.banks,
                SETTINGS.expenseType,
                SETTINGS.incomeType,
              ]
            }
          ]
          break;
        
        default:
          _pages = [
            FARMS,
            ACCOUNTING.purchases,
            ACCOUNTING.sales,
            {
              title: "P/L Report",
              href: "/trail-balance-report",
              icon: <AssessmentOutlinedIcon />,
              isBookmarked: false
            },
            {
              title: "Daily Egg Rates",
              href: "/daily-egg-rates",
              icon: <AccountBalanceOutlinedIcon />,
              isBookmarked: false
            },
            {
              nested: true,
              title: "Settings",
              icon: <SettingsOutlinedIcon />,
              subMenus: [
                SETTINGS.breeds,
                SETTINGS.materialMaster,
                SETTINGS.banks,
                ...(isDesktop ? [SETTINGS.billing] : []),
                SETTINGS.subUsers,
                SETTINGS.profile,
                SETTINGS.expenseType,
                SETTINGS.incomeType,
                SETTINGS.config,
              ]
            },
          ];
          break;
        }
      break;
    
    case version.proPlus:
      switch(user.role){
        case "SUPER":
          _pages = [DASHBOARD];
          break;
        case "FEEDSUPER":
          _pages = [DAILYFEED];
          break;
        
        case "DRIVER":
          _pages =[LINE_SALES.lineSaleDriver]
          break;
    
        case "DOCTOR":
          _pages = [DOCTOR_DASHBOARD,SETTINGS.doctorProfile];
          break;
    
        case "STAFF":
          _pages = [
            DASHBOARD,
            FARMS,
            {
              nested: true,
              title: "Accounting",
              icon: <AccountBalanceOutlinedIcon />,
              subMenus: [
                ACCOUNTING.staffExpenses,
                ACCOUNTING.staffReceipts,
                ACCOUNTING.dailyEggRates,
              ],
            },
            FEED_MILL,
            {
              nested: true,
              title: "Settings",
              icon: <SettingsOutlinedIcon />,
              subMenus: [
                SETTINGS.breeds,
                SETTINGS.materialMaster,
                SETTINGS.customerVendorDetails,
                SETTINGS.invoiceSettings,
                SETTINGS.expenseType,
                SETTINGS.incomeType,
                SETTINGS.banks,
              ],
            },
          ];
          break;
    
        default:
          _pages = [
            DASHBOARD,
            FARMS,
            {
              nested: true,
              title: "Accounting",
              icon: <AccountBalanceOutlinedIcon />,
              subMenus: [
                ACCOUNTING.dashboard,
                ACCOUNTING.bankTransaction,
                ACCOUNTING.expenses,
                ACCOUNTING.receipts,
                ACCOUNTING.dailyEggRates,
                ACCOUNTING.reports,
              ],
            },
            FEED_MILL,
            TAXES,
            {
              nested: true,
              title: "Line Sales",
              icon: <PictureAsPdfOutlinedIcon />,
              subMenus: [
                LINE_SALES.sales,
                LINE_SALES.truck,
                LINE_SALES.expense_type,
                LINE_SALES.expenses,
                LINE_SALES.customerVendorDetails,
                LINE_SALES.purchases,
                LINE_SALES.lineSaleBulkPayment,
                LINE_SALES.linePurchaseBulkPayment,
                LINE_SALES.reports,
                LINE_SALES.taxes,            
              ],
            },
            {
              nested: true,
              title: "Settings",
              icon: <SettingsOutlinedIcon />,
              subMenus: [
                SETTINGS.breeds,
                SETTINGS.materialMaster,
                SETTINGS.customerVendorDetails,
                SETTINGS.invoiceSettings,
                SETTINGS.expenseType,
                SETTINGS.incomeType,
                SETTINGS.banks,
                SETTINGS.lines,
                SETTINGS.subUsers,
                ...(isDesktop ? [SETTINGS.billing] : []),
                SETTINGS.taxProfiles,
                SETTINGS.profile,
                SETTINGS.config,
                ...udhayaPages
              ]
            },
          ];
          break;
      }
      break;

      default:
        break;
  } 

  // Remove config
  if(!user.Config.lines_sales){
    _pages = removeThisPage(_pages,"Line Sales");
  }
  if(!user.Config.taxes){
    _pages = removeThisPage(_pages,"Taxes");
  }
  if(!user.Config.feed_section){
    _pages = removeThisPage(_pages,"Feed Mill");
  }
 
  return _pages;
};


const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;
  const [{user}] = useGlobalState();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
});

  const classes = useStyles();
  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
      
        <RouterLink className={classes.logoContainer} to="/">
          <img
            className={classes.logo}
            alt="Logo"
            src="/images/logos/poultry-client-logo.png"
          />
        </RouterLink>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav open={open} className={classes.nav} pages={pages({user,isDesktop})}  onClose={onClose}/>
        <BottomMenu />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;