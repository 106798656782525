import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height:'100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
}));

/**
 * @return {null}
 */

export default  function AppProgressBar({loading}){
    const classes = useStyles();
    if(loading){
        return <div className={classes.progressContainer}>
            <CircularProgress className={classes.progressBar}/>
        </div>
    }else{
        return null;
    }
}

AppProgressBar.propTypes = {
    loading: PropTypes.bool.isRequired,
};