import React, {useEffect,useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button"; 
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider,DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MaterialTable } from '../../../components';
//import Icon from '@material-ui/core/Icon';
import api from '../../../common/api';
import validate from "validate.js";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useGlobalState} from '../../../state';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

const reg = /^(?:\d*\.\d{1,2}|\d+)$/ 


var schema = {
    paymentDate: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    paymentAmount: {
        presence: {allowEmpty: false},
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 1,
        }
    },
    paymentMode: {
        presence: {allowEmpty: false, message: 'is required'},
        type: 'number',
    },
};

const paymentSchema = {
    purchaseId: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    otherExpenseId: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    paymentAmount: {
        presence: {allowEmpty: false},
       // type: 'number',
        numericality: {
            greaterThanOrEqualTo: 1,
        }
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
        paddingBottom: theme.spacing(1.25)
    },
    textField: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(0)
    },
    noteTextField: {
       // margin: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    datePicker:{
        margin: theme.spacing(1),
        marginTop: theme.spacing(0),
        minWidth:160
    },
    invoice: {
        float:'Left'
    },
    grid: {
        marginLeft: theme.spacing(0),
        margin: theme.spacing(2),
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '2'
    },
}));
//var remainingPaymentAmount = 0
const Payment = ({ deleteErrorMessage, errorMessage, data ,banks, onSave, onEdit, handlePaymentClose,vendors, showPaymentDialog, editMode ,showError, showMessage}) => {
    const classes = useStyles();
    const [formState,hasError,handleChange,updateData] = useFromValidator(schema);
    const [showBanks, setShowBanks] = useState(false)
    const [showInvoiceTable,setShowInvoiceTable] = useState(false)
    const [unPaidPayments, setUnPaidPayment] = useState([]);
    const [deletedPayments, setDeletedPayments] = useState([]);
    const [paymentTable, setPaymentTable] = useState([]);
    const [remainingPaymentAmount, setRemainingPaymentAmount] = useState(0);
    const [originalUnPaidPayments, setOriginalUnPaidPayments] = useState([]);
    const [includeTDSValue, setIncludeTDSValue] = useState(false);
    const [popupLoading, setPopupLoading] = useState(false) ;
    let unPaidPaymentdisplayArr = [];
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }


    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        setRemainingPaymentAmount(0)
        setShowInvoiceTable(false);
        console.debug(data,showInvoiceTable)
        setUnPaidPayment([])
        setDeletedPayments([])
        setPaymentTable([]);
        if(editMode && data !== undefined && data.vendorId !== undefined){
            let index = vendors.findIndex((srcData) => {
                return srcData.id === data.vendorId;
            });
            if(data.paymentMode === 2){
                let bankIndex = banks.findIndex((srcData) => {
                    return srcData.id === data.bankId;
                });
                data.bankIndex = bankIndex
            }
            setIncludeTDSValue(data.includeTDS === 1 ? true  : false)
            setPaymentTable(data.purchasesForPaymentsMade || [])
            console.debug("paymentTable ",paymentTable)
            getAllVendorUnpaid(data.vendorId, data.purchasesForPaymentsMade || [])
            data.vendorIndex = index

            let totalPaymentAmount = 0
            let purchasesArray = data?.purchasesForPaymentsMade
            let ammountpay = purchasesArray?.forEach((ele)=>{
                totalPaymentAmount = totalPaymentAmount + ele.paymentAmount
            })
            console.debug(ammountpay)
            let remainingAmount =  data?.paymentAmount - totalPaymentAmount
            setRemainingPaymentAmount(remainingAmount)
        }
        if(showPaymentDialog){
            updateData(data, editMode);
            setIncludeTDSValue(true)
            setPopupLoading(false);
        }
        if(editMode){
            if( data !== undefined && data.paymentMode !== undefined && data.paymentMode === 2){
                setIncludeTDSValue(true)
                setShowBanks(true)
            }else{
                setShowBanks(false)
            }
        }
    };
    useEffect(resetBeforeEdit, [showPaymentDialog]);

    const validatePayments = (newData) =>{
        if((newData.purchaseId === undefined && newData.invoice !== undefined  && newData.invoice.purchaseId !== undefined)||(newData.otherExpenseId === undefined && newData.invoice !== undefined  && newData.invoice.otherExpenseId !== undefined)){
            newData = {
                ...newData,
                purchaseId : newData.invoice.purchaseId !== undefined ? newData.invoice.purchaseId : "",
                otherExpenseId : newData.invoice.otherExpenseId !== undefined ? newData.invoice.otherExpenseId : ""
            }
        }
        let errors = validate(newData, paymentSchema);
        if(errors){
            return  'Add bill number and payment amount.'
        }
        let index = originalUnPaidPayments.findIndex(newData.purchaseId!==0?(val => val.id === newData.purchaseId):(val => val.id === newData.otherExpenseId))
        let paymentAmount = originalUnPaidPayments[index].remainingAmount
        if(includeTDSValue){
            paymentAmount = Math.abs((paymentAmount + originalUnPaidPayments[index].tcsValue) - originalUnPaidPayments[index].tdsValue)
        }
        paymentAmount = Number(paymentAmount.toFixed(2));
        if(parseFloat(newData.paymentAmount) > paymentAmount){
            return  'Payment amount is greater than remaining amount.'
        }

        return ''
    }

    const onSubmit = () => {
        setPopupLoading(true)
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        let tempData = {
            ...formState.values,
            includeTDS :  includeTDSValue ? 1 : 0
        };
        tempData = {...tempData,
                unPaidPayments}
            console.log(">>>>>>ONSUBMIT", tempData)
        if(tempData.paymentMode === 2){
            if(formState.values.bankIndex===undefined){
                alert('select Bank Name')
                setPopupLoading(false)
                return;
                }
                else{
            tempData = {
                ...tempData,
                bankId:banks[formState.values.bankIndex].id,
                bankName:banks[formState.values.bankIndex].name
            }}
        }
        let totalPaymentAmount = 0
        paymentTable.forEach((ele)=>{
           // let index = originalUnPaidPayments.findIndex((val => val.id === ele.invoice.purchaseId))
            totalPaymentAmount = totalPaymentAmount + ele.paymentAmount
        })
         //remove remaining amount validation 
        // if((paymentTable.length !== 0) && (totalPaymentAmount !== formState.values.paymentAmount)){
        //     alert('Total Invoice payment is not equal to Amount.')
        //     setPopupLoading(false);
        //     return
        // }
        tempData.vendorId = vendors[formState.values.vendorIndex].id
        tempData.vendorName = vendors[formState.values.vendorIndex].name
        tempData.purchasesForPaymentsMade = paymentTable
        tempData.deletedPurchasesForPaymentsMade = deletedPayments
        console.debug(tempData)
        if(editMode){
            onEdit(tempData, setPopupLoading);
        }else{
            onSave(tempData, setPopupLoading);
        }
        setPaymentTable(tempData.purchasesForPaymentsMade);
        setIncludeTDSValue(false)
    };


    const handleVendor = (event) => {
        vendors.forEach((vendor,index)=>{
            if(event.target.value === index){
                getAllVendorUnpaid(vendor.id, [])
            }
        })
    }
    const getAllVendorUnpaid = (vendorID, table) => {       
        const response = api.get(`getUnPaidPurchasesForVendor/${vendorID}`)   
        response.then(res => {
            res.payment = 0
            setOriginalUnPaidPayments([...res]);
            let temp = [
                ...res
            ]

            console.debug("res ",res)
            res.forEach((ele)=>{
                console.debug("remaining amount ", ele.remainingAmount)
                if (ele.remainingAmount === 0){
                    let index = temp.findIndex((srcData) => {
                        return srcData.id === ele.id;
                    }); 
                    console.debug("removing ", index)
                    if (index > -1){
                        temp.splice(index, 1);
                    }
                }
            })
            if(table.length > 0){
                table.forEach((element)=>{
                    let index = temp.findIndex((srcData) => {
                        if(element.invoice.purchaseId!==0){
                        return srcData.id === element.invoice.purchaseId;}
                        else{return srcData.id === element.invoice.otherExpenseId;}
                    }); 
                    if (index > -1){
                        console.debug("re")
                    temp.splice(index, 1);
                    }
                })
            }
            console.debug("temp ",temp)
            setUnPaidPayment([...temp]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                   console.log("Error")
                else
                console.log("Error")
            } else {
            }
        })
        return response;
    }

    const handleChangePaymentMode = (event) => {
        if(event.target.name === "paymentMode" && event.target.value === 2){
            console.debug("in handle ",event)
            setShowBanks(true)
        }else{
            setShowBanks(false)
        }
    }

    const handleChangeAmount = (event) =>{
        if(event.target.value !== ""){
            let totalAmount =parseFloat(event.target.value)
            /*let newpaymentTable = []
            for (let i = 0; i < paymentTable.length; i++) {
                let ele = paymentTable[i]
            //paymentTable.forEach((ele)=>{
                if(ele.reminingAmount >= totalAmount){
                    ele.paymentAmount = totalAmount
                    totalAmount = 0
                    //break;
                }else if(totalAmount === 0){
                    ele.paymentAmount = 0
                }else{
                    ele.paymentAmount = ele.remainingAmount
                    totalAmount = totalAmount - ele.remainingAmount
                }
                newpaymentTable = [
                    ...newpaymentTable,
                    {...ele}
                ]
            }*/
            let addedValue = totalAmount - (formState.values.paymentAmount || 0)
            setShowInvoiceTable(false)
            setRemainingPaymentAmount(remainingPaymentAmount + addedValue)
        }
        else if(event.target.value === ""){
            setRemainingPaymentAmount(0)
        }
    }

    const isFormValid = () =>{
        return  formState.values.paymentAmount!==undefined &&
                formState.values.paymentMode!==undefined &&
                formState.values.paymentDate!==undefined &&
                formState.values.vendorIndex!==undefined
        // return formState.isValid;
    }

    return <Dialog maxWidth='md' disableBackdropClick open={showPaymentDialog} onClose={handlePaymentClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >Add  Payment
        <IconButton aria-label="close" className={classes.closeButton} onClick={handlePaymentClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>

                <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
            <DatePicker
            disabled
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Payment Date"
                format="dd/MM/yyyy"
                value={formState.values.paymentDate || null}
                onChange={data => {
                    handleChange({target: {name: 'paymentDate', value: data}})
                }}
                animateYearScrolling
                name = "paymentDate"
                style={{backgroundColor:"#ffffff"}}
                className={classes.datePicker}
            />
            </MuiPickersUtilsProvider>
            <TextField
            disabled
                className={classes.textField}
                error={hasError('paymentAmount')}
                helperText={
                    hasError('paymentAmount') ? formState.errors.paymentAmount[0] : null
                }
                label="Amount"
                name="paymentAmount"
                onChange={event =>{handleChange(event,handleChangeAmount)}}
                type="number"
                value={formState.values.paymentAmount || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <FormControl disabled className={classes.formControl}>
                  <InputLabel id="vendorName-select-outlined-label">
                      Vendor Name
                  </InputLabel>
                  <Select
                      id="vendorName-select"
                      value={formState.values.vendorIndex !== undefined ? formState.values.vendorIndex : ''}
                      onChange={event =>{handleChange(event,handleVendor)}}
                      name="vendorIndex"
                  >
                      {vendors !== undefined && vendors !== null ?
                          vendors.map((vendor, index) =>
                              <MenuItem key={vendor.id} value={index}>{vendor.name}</MenuItem>
                          ) : ""}
                  </Select>
                  
              </FormControl>
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="paymentMode-select-outlined-label">
                Payment Mode
                </InputLabel>
                <Select
                id="paymentMode"
                value={formState.values.paymentMode || ''}
                onChange={event =>{handleChange(event,handleChangePaymentMode)}}
                name="paymentMode"
                >
                    <MenuItem key = {0} value={1}>Cash</MenuItem>
                    <MenuItem key = {1} value={2}>Bank</MenuItem>
                </Select>
            </FormControl>
            {showBanks ? 
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="banks-select-label">Banks</InputLabel>
                <Select
                id="banks-select"
                value={formState.values.bankIndex !== undefined ? formState.values.bankIndex : ''}
                onChange={handleChange}
                name="bankIndex"
                >
                {banks.map((bank,index)=>
                    <MenuItem key = {bank.id} value={index}>{bank.name}</MenuItem>
                )}
                </Select>
            </FormControl>
            :null}
            <TextField disabled
                multiline
                rows="3"
                className={classes.noteTextField}
                error={hasError('paymentNote')}
                fullWidth
                helperText={
                    hasError('paymentNote') ? formState.errors.paymentNote[0] : null
                }
                label="Note"
                name="paymentNote"
                onChange={handleChange}
                type="text"
                value={formState.values.paymentNote || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
           { formState.isValid && <Grid disabled
                className={classes.grid}
                container
                justify='space-between'
                alignItems='flex-end'
            >
                <Grid  item xs={6}>
                    <Typography style={{opacity: '0.6'}} variant="h5" component="h2">
                        {"Remaining Amount : "+remainingPaymentAmount.toFixed(2)}
                    </Typography>
                </Grid>   
                <Grid hidden item xs={6}>
                    <Typography style={{opacity: '0.6'}} variant="h5" component="h2">
                        {"Include TCS/TDS Amount"}
                        <Checkbox
                            checked={true}
                            onChange = {(event)=>{
                                console.debug("checked ",event.target.checked)
                                setIncludeTDSValue(event.target.checked)}}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </Typography>
                </Grid>  
            </Grid>}
            {formState.isValid && paymentTable && originalUnPaidPayments ?       
            <MaterialTable 
                columns={[
                    {
                        title: 'BillNumber', field: 'key', editComponent: props => {
                            let index = -1
                            let purchaseId= 0
                            let otherExpenseId= 0
                            if(props.rowData.invoice !== undefined && props.rowData.invoice.purchaseId !== undefined&&props.rowData.invoice.otherExpenseId !== undefined){
                                purchaseId = props.rowData.invoice.purchaseId
                                otherExpenseId = props.rowData.invoice.otherExpenseId
                            }else if(props.rowData.purchaseId !== undefined||props.rowData.otherExpenseId !== undefined){
                                purchaseId = props.rowData.purchaseId
                                otherExpenseId = props.rowData.otherExpenseId
                            }
                            if(purchaseId !== 0||otherExpenseId !== 0){
                                let tempUnPaidPaymentIndex = unPaidPaymentdisplayArr.findIndex(purchaseId!==0?(val => val.id === purchaseId):(val => val.id === otherExpenseId));
                                if (tempUnPaidPaymentIndex === -1) {
                                    let originalUnPaidPaymentsIndex = originalUnPaidPayments.findIndex(purchaseId!==0?(val => val.id === purchaseId):(val => val.id === otherExpenseId));
                                    if (originalUnPaidPaymentsIndex === -1) {
                                        unPaidPaymentdisplayArr = unPaidPayments
                                        index = '';
                                    }else{
                                        unPaidPaymentdisplayArr = ([...unPaidPayments, originalUnPaidPayments[originalUnPaidPaymentsIndex]])
                                        index = unPaidPaymentdisplayArr.length - 1
                                    }
                                }else{
                                    index = tempUnPaidPaymentIndex
                                }
                            }else{
                                unPaidPaymentdisplayArr = unPaidPayments
                                index = '';
                            }
                            return <Select
                                id="materialType-select"
                                value={index}
                                required = {true}
                                onChange={e => {
                                    let outStandingAmount  = unPaidPaymentdisplayArr[e.target.value].remainingAmount 
                                    if(includeTDSValue){
                                        outStandingAmount = Math.abs((outStandingAmount + unPaidPaymentdisplayArr[e.target.value].tcsValue) - unPaidPaymentdisplayArr[e.target.value].tdsValue)
                                    }
                                    
                                if(outStandingAmount > 0){
                                        
                                        let rowRemainingAmount = 0  
                                        let localReminingAmount = remainingPaymentAmount

                                        if(outStandingAmount > localReminingAmount){
                                            rowRemainingAmount = localReminingAmount
                                            localReminingAmount = 0
                                            //break;
                                        }else{
                                            rowRemainingAmount = outStandingAmount
                                            localReminingAmount = localReminingAmount - outStandingAmount
                                        }
                                        props.rowData.paymentAmount = rowRemainingAmount
                                }
                                    console.log(e.target.value)
                                    if (unPaidPaymentdisplayArr[e.target.value].typeOfPurchase ===1){
                                    props.rowData.purchaseId = unPaidPaymentdisplayArr[e.target.value].id;
                                    props.rowData.otherExpenseId = 0}
                                    else
                                    {props.rowData.otherExpenseId = unPaidPaymentdisplayArr[e.target.value].id;
                                     props.rowData.purchaseId = 0}
                                    props.rowData.invoice = {
                                        ...props.rowData.invoice,
                                        purchaseId:props.rowData.purchaseId,
                                        otherExpenseId: props.rowData.otherExpenseId
                                    }
                                    props.rowData.remainingAmount = unPaidPaymentdisplayArr[e.target.value].remainingAmount;

                                    props.rowData.key = "BillNumber #"+unPaidPaymentdisplayArr[e.target.value].billNumber+" | "+
                                    (unPaidPaymentdisplayArr[e.target.value].remainingAmount).toLocaleString(user.locales,amountObj)+" Outstanding"

                                    props.onChange("BillNumber #"+unPaidPaymentdisplayArr[e.target.value].billNumber+" | "+
                                    (unPaidPaymentdisplayArr[e.target.value].remainingAmount).toLocaleString(user.locales,amountObj)+" Outstanding");
                                }}
                            >
                                {unPaidPaymentdisplayArr.map((ele, index) =>{
                                    let billValue = ele.remainingAmount
                                    if(includeTDSValue){
                                        billValue = Math.abs((billValue + ele.tcsValue) - ele.tdsValue)
                                    }
                                    return <MenuItem disabled key={index} value={index}>
                                         {
                                             ele.typeOfPurchase === 1?
                                            "BillNumber #"+ele.billNumber+" | "+(billValue).toLocaleString(user.locales,amountObj)
                                            :
                                            "Other Expense #"+ele.billNumber+" | "+(billValue).toLocaleString(user.locales,amountObj)
                                        }
                                    </MenuItem>
                                })}
                            </Select>
                        },
                        render: rowData => {
                            let index = originalUnPaidPayments.findIndex(rowData.invoice.purchaseId!==0?(val => val.id === rowData.invoice.purchaseId):(val => val.id === rowData.invoice.otherExpenseId));
                            
                            let billValue = 0
                            if(index > -1 ){
                                billValue = originalUnPaidPayments[index].remainingAmount
                                if(includeTDSValue){
                                    billValue = Math.abs((billValue + originalUnPaidPayments[index].tcsValue) - originalUnPaidPayments[index].tdsValue)
                                }
                            }

                            if(rowData.invoice.purchaseId!==0){
                            return <span>{index > -1 ? "BillNumber #"+originalUnPaidPayments[index].billNumber +
                            " | "+(billValue).toLocaleString(user.locales,amountObj) : ""}</span>}
                            else
                            {return <span>{index > -1 ? "Other Expense #"+originalUnPaidPayments[index].billNumber +
                            " | "+(billValue).toLocaleString(user.locales,amountObj) : ""}</span>}
                    }},
                    {title: 'Payment', field:'paymentAmount', type: 'numeric', render:rd=>rd.paymentAmount.toFixed(2) }
                    
                ]}
                style={{opacity: '0.6'}}
                data={paymentTable || []}
                options={{
                    actionsColumnIndex: -1,
                    search: false,                    
                    pageSize:5,
                    toolbar: true
                }}
                editable={{
                    onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        let err = validatePayments(newData)
                        if(err){
                            alert(err)
                            reject()
                        }else{
                            setRemainingPaymentAmount(remainingPaymentAmount - newData.paymentAmount)
                            unPaidPaymentdisplayArr = []
                                newData = {
                                    ...newData,
                                    invoice:{
                                        purchaseId : newData.purchaseId,
                                        otherExpenseId : newData.otherExpenseId
                                    },
                                    paymentAmount : parseFloat(newData.paymentAmount)
                                }
                            let unpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                if(newData.invoice.purchaseId!==0){
                                return src.id === newData.invoice.purchaseId}
                                else
                                {return src.id === newData.invoice.otherExpenseId}
                            })
                            let newUnPaidPayments = [...unPaidPayments];
                            newUnPaidPayments.splice(unpaidPaymentIndex, 1);

                            setUnPaidPayment([...newUnPaidPayments])
                            setPaymentTable([...paymentTable, newData]);
                            resolve();
                        }
                        console.debug(err)
                    }),
                    onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        let err = validatePayments(newData)
                        if(err){
                            alert(err)
                            reject()
                        }else{
                            setRemainingPaymentAmount(remainingPaymentAmount + (oldData.paymentAmount - newData.paymentAmount))
                            unPaidPaymentdisplayArr = []
                            const dataUpdate = [...paymentTable];
                            newData = {
                                ...newData,
                                paymentAmount : parseFloat(newData.paymentAmount)
                            }
                            if(newData.purchaseId !== oldData.purchaseId&&newData.otherExpenseId !== oldData.otherExpenseId){
                                let newUnPaidPayments = [...unPaidPayments];
                                let newUnpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                    if(newData.invoice.purchaseId!==0){
                                        return src.id === newData.invoice.purchaseId}
                                        else
                                        {return src.id === newData.invoice.otherExpenseId}
                                })
                                newUnPaidPayments.splice(newUnpaidPaymentIndex, 1);

                                let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                                    if(oldData.invoice.purchaseId!==0){
                                        return src.id === oldData.invoice.purchaseId}
                                        else
                                        {return src.id === oldData.invoice.otherExpenseId}
                                })
                                
                                newUnPaidPayments = [
                                    ...newUnPaidPayments,
                                    originalUnPaidPayments[oldUnpaidPaymentIndex]
                                ]
                                
                                setUnPaidPayment([...newUnPaidPayments])
                            }
                            if(newData.otherExpenseId !== oldData.otherExpenseId){
                                let newUnPaidPayments = [...unPaidPayments];
                                let newUnpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                    if(newData.invoice.purchaseId!==0){
                                        return src.id === newData.invoice.purchaseId}
                                        else
                                        {return src.id === newData.invoice.otherExpenseId}
                                })
                                newUnPaidPayments.splice(newUnpaidPaymentIndex, 1);

                                let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                                    if(oldData.invoice.purchaseId!==0){
                                        return src.id === oldData.invoice.purchaseId}
                                        else
                                        {return src.id === oldData.invoice.otherExpenseId}
                                })
                                
                                newUnPaidPayments = [
                                    ...newUnPaidPayments,
                                    originalUnPaidPayments[oldUnpaidPaymentIndex]
                                ]
                                
                                setUnPaidPayment([...newUnPaidPayments])
                            }
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            setPaymentTable([...dataUpdate]);
                            resolve();
                        }
                    }),
                    onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        unPaidPaymentdisplayArr = []
                        const dataDelete = [...paymentTable];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        setDeletedPayments([...deletedPayments, oldData])
                        setPaymentTable([...dataDelete]);
                        setRemainingPaymentAmount( remainingPaymentAmount + oldData.paymentAmount)
                        let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                            if(oldData.invoice.purchaseId!==0){
                                return src.id === oldData.invoice.purchaseId}
                                else
                                {return src.id === oldData.invoice.otherExpenseId}
                        })
                        let newUnPaidPayments = [
                            ...unPaidPayments,
                        ]
                        if(oldUnpaidPaymentIndex > -1){
                            newUnPaidPayments = [
                                ...newUnPaidPayments,
                                originalUnPaidPayments[oldUnpaidPaymentIndex]
                            ]
                        }
                        setUnPaidPayment([...newUnPaidPayments])

                        resolve();
                    }),
                }}
                title='Purchases and Other Expenses'
            /> :
            
            <MaterialTable 
            columns={[
                {
                    title: 'BillNumber', field: 'key', editComponent: props => {
                        let index = -1
                        let purchaseId= 0
                        let otherExpenseId= 0
                        if(props.rowData.invoice !== undefined && props.rowData.invoice.purchaseId !== undefined&&props.rowData.invoice.otherExpenseId !== undefined){
                            purchaseId = props.rowData.invoice.purchaseId
                            otherExpenseId = props.rowData.invoice.otherExpenseId
                        }else if(props.rowData.purchaseId !== undefined||props.rowData.otherExpenseId !== undefined){
                            purchaseId = props.rowData.purchaseId
                            otherExpenseId = props.rowData.otherExpenseId
                        }
                        if(purchaseId !== 0||otherExpenseId !== 0){
                            let tempUnPaidPaymentIndex = unPaidPaymentdisplayArr.findIndex(purchaseId!==0?(val => val.id === purchaseId):(val => val.id === otherExpenseId));
                            if (tempUnPaidPaymentIndex === -1) {
                                let originalUnPaidPaymentsIndex = originalUnPaidPayments.findIndex(purchaseId!==0?(val => val.id === purchaseId):(val => val.id === otherExpenseId));
                                if (originalUnPaidPaymentsIndex === -1) {
                                    unPaidPaymentdisplayArr = unPaidPayments
                                    index = '';
                                }else{
                                    unPaidPaymentdisplayArr = ([...unPaidPayments, originalUnPaidPayments[originalUnPaidPaymentsIndex]])
                                    index = unPaidPaymentdisplayArr.length - 1
                                }
                            }else{
                                index = tempUnPaidPaymentIndex
                            }
                        }else{
                            unPaidPaymentdisplayArr = unPaidPayments
                            index = '';
                        }
                        return <Select
                            id="materialType-select"
                            value={index}
                            required = {true}
                            onChange={e => {
                                let outStandingAmount  = unPaidPaymentdisplayArr[e.target.value].remainingAmount 
                                if(includeTDSValue){
                                    outStandingAmount = Math.abs((outStandingAmount + unPaidPaymentdisplayArr[e.target.value].tcsValue) - unPaidPaymentdisplayArr[e.target.value].tdsValue)
                                }
                                
                            if(outStandingAmount > 0){
                                    
                                    let rowRemainingAmount = 0
                                    let localReminingAmount = remainingPaymentAmount

                                    if(outStandingAmount > localReminingAmount){
                                        rowRemainingAmount = localReminingAmount
                                        localReminingAmount = 0
                                        //break;
                                    }else{
                                        rowRemainingAmount = outStandingAmount
                                        localReminingAmount = localReminingAmount - outStandingAmount
                                    }
                                    props.rowData.paymentAmount = rowRemainingAmount
                            }
                                console.log(e.target.value)
                                if (unPaidPaymentdisplayArr[e.target.value].typeOfPurchase ===1){
                                props.rowData.purchaseId = unPaidPaymentdisplayArr[e.target.value].id;
                                props.rowData.otherExpenseId = 0}
                                else
                                {props.rowData.otherExpenseId = unPaidPaymentdisplayArr[e.target.value].id;
                                 props.rowData.purchaseId = 0}
                                props.rowData.invoice = {
                                    ...props.rowData.invoice,
                                    purchaseId:props.rowData.purchaseId,
                                    otherExpenseId: props.rowData.otherExpenseId
                                }
                                props.rowData.remainingAmount = unPaidPaymentdisplayArr[e.target.value].remainingAmount;

                                props.rowData.key = "BillNumber #"+unPaidPaymentdisplayArr[e.target.value].billNumber+" | "+
                                (unPaidPaymentdisplayArr[e.target.value].remainingAmount).toLocaleString(user.locales,amountObj)+" Outstanding"

                                props.onChange("BillNumber #"+unPaidPaymentdisplayArr[e.target.value].billNumber+" | "+
                                (unPaidPaymentdisplayArr[e.target.value].remainingAmount).toLocaleString(user.locales,amountObj)+" Outstanding");
                            }}
                        >
                            {unPaidPaymentdisplayArr.map((ele, index) =>{
                                let billValue = ele.remainingAmount
                                if(includeTDSValue){
                                    billValue = Math.abs((billValue + ele.tcsValue) - ele.tdsValue)
                                }
                                return <MenuItem disabled key={index} value={index}>
                                     {
                                         ele.typeOfPurchase === 1?
                                        "BillNumber #"+ele.billNumber+" | "+(billValue).toLocaleString(user.locales,amountObj)
                                        :
                                        "Other Expense #"+ele.billNumber+" | "+(billValue).toLocaleString(user.locales,amountObj)
                                    }
                                </MenuItem>
                            })}
                        </Select>
                    },
                    render: rowData => {
                        let index = originalUnPaidPayments.findIndex(rowData.invoice.purchaseId!==0?(val => val.id === rowData.invoice.purchaseId):(val => val.id === rowData.invoice.otherExpenseId));
                        
                        let billValue = 0
                        if(index > -1 ){
                            billValue = originalUnPaidPayments[index].remainingAmount
                            if(includeTDSValue){
                                billValue = Math.abs((billValue + originalUnPaidPayments[index].tcsValue) - originalUnPaidPayments[index].tdsValue)
                            }
                        }

                        if(rowData.invoice.purchaseId!==0){
                        return <span>{index > -1 ? "BillNumber #"+originalUnPaidPayments[index].billNumber +
                        " | "+(billValue).toLocaleString(user.locales,amountObj) : ""}</span>}
                        else
                        {return <span>{index > -1 ? "Other Expense #"+originalUnPaidPayments[index].billNumber +
                        " | "+(billValue).toLocaleString(user.locales,amountObj) : ""}</span>}
                }},
                {title: 'Payment', field:'paymentAmount', type: 'numeric', render:rd=>rd.paymentAmount.toFixed(2) }
                
            ]}
            style={{opacity: '0.6'}}
            data={paymentTable || []}
            options={{
                actionsColumnIndex: -1,
                search: false,                    
                pageSize:5,
                toolbar: true
            }}
            editable={{
                onRowAdd: newData =>
                new Promise((resolve, reject) => {
                    let err = validatePayments(newData)
                    if(err){
                        alert(err)
                        reject()
                    }else{
                        setRemainingPaymentAmount(remainingPaymentAmount - newData.paymentAmount)
                        unPaidPaymentdisplayArr = []
                            newData = {
                                ...newData,
                                invoice:{
                                    purchaseId : newData.purchaseId,
                                    otherExpenseId : newData.otherExpenseId
                                },
                                paymentAmount : parseFloat(newData.paymentAmount)
                            }
                        let unpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                            if(newData.invoice.purchaseId!==0){
                            return src.id === newData.invoice.purchaseId}
                            else
                            {return src.id === newData.invoice.otherExpenseId}
                        })
                        let newUnPaidPayments = [...unPaidPayments];
                        newUnPaidPayments.splice(unpaidPaymentIndex, 1);

                        setUnPaidPayment([...newUnPaidPayments])
                        setPaymentTable([...paymentTable, newData]);
                        resolve();
                    }
                    console.debug(err)
                }),
                onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                    let err = validatePayments(newData)
                    if(err){
                        alert(err)
                        reject()
                    }else{
                        setRemainingPaymentAmount(remainingPaymentAmount + (oldData.paymentAmount - newData.paymentAmount))
                        unPaidPaymentdisplayArr = []
                        const dataUpdate = [...paymentTable];
                        newData = {
                            ...newData,
                            paymentAmount : parseFloat(newData.paymentAmount)
                        }
                        if(newData.purchaseId !== oldData.purchaseId&&newData.otherExpenseId !== oldData.otherExpenseId){
                            let newUnPaidPayments = [...unPaidPayments];
                            let newUnpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                if(newData.invoice.purchaseId!==0){
                                    return src.id === newData.invoice.purchaseId}
                                    else
                                    {return src.id === newData.invoice.otherExpenseId}
                            })
                            newUnPaidPayments.splice(newUnpaidPaymentIndex, 1);

                            let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                                if(oldData.invoice.purchaseId!==0){
                                    return src.id === oldData.invoice.purchaseId}
                                    else
                                    {return src.id === oldData.invoice.otherExpenseId}
                            })
                            
                            newUnPaidPayments = [
                                ...newUnPaidPayments,
                                originalUnPaidPayments[oldUnpaidPaymentIndex]
                            ]
                            
                            setUnPaidPayment([...newUnPaidPayments])
                        }
                        if(newData.otherExpenseId !== oldData.otherExpenseId){
                            let newUnPaidPayments = [...unPaidPayments];
                            let newUnpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                if(newData.invoice.purchaseId!==0){
                                    return src.id === newData.invoice.purchaseId}
                                    else
                                    {return src.id === newData.invoice.otherExpenseId}
                            })
                            newUnPaidPayments.splice(newUnpaidPaymentIndex, 1);

                            let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                                if(oldData.invoice.purchaseId!==0){
                                    return src.id === oldData.invoice.purchaseId}
                                    else
                                    {return src.id === oldData.invoice.otherExpenseId}
                            })
                            
                            newUnPaidPayments = [
                                ...newUnPaidPayments,
                                originalUnPaidPayments[oldUnpaidPaymentIndex]
                            ]
                            
                            setUnPaidPayment([...newUnPaidPayments])
                        }
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        setPaymentTable([...dataUpdate]);
                        resolve();
                    }
                }),
                onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                    unPaidPaymentdisplayArr = []
                    const dataDelete = [...paymentTable];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    setDeletedPayments([...deletedPayments, oldData])
                    setPaymentTable([...dataDelete]);
                    setRemainingPaymentAmount( remainingPaymentAmount + oldData.paymentAmount)
                    let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                        if(oldData.invoice.purchaseId!==0){
                            return src.id === oldData.invoice.purchaseId}
                            else
                            {return src.id === oldData.invoice.otherExpenseId}
                    })
                    let newUnPaidPayments = [
                        ...unPaidPayments,
                    ]
                    if(oldUnpaidPaymentIndex > -1){
                        newUnPaidPayments = [
                            ...newUnPaidPayments,
                            originalUnPaidPayments[oldUnpaidPaymentIndex]
                        ]
                    }
                    setUnPaidPayment([...newUnPaidPayments])

                    resolve();
                }),
            }}
            title='Purchases and Other Expenses'
        /> }
        
            </div>
            :
            <div>

          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
            <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
            <DatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Payment Date"
                format="dd/MM/yyyy"
                value={formState.values.paymentDate || null}
                onChange={data => {
                    handleChange({target: {name: 'paymentDate', value: data}})
                }}
                animateYearScrolling
                name = "paymentDate"
                style={{backgroundColor:"#ffffff"}}
                className={classes.datePicker}
            />
            </MuiPickersUtilsProvider>
            
            <TextField
                className={classes.textField}
                error={hasError('paymentAmount')}
                helperText={
                    hasError('paymentAmount') ? formState.errors.paymentAmount[0] : null
                }
                label="Amount"
                name="paymentAmount"
                onChange={event =>{
                    if (!reg.test(event.target.value)) {
                       return
                    }
                    handleChange(event,handleChangeAmount)
                 }}
                type="number"
                value={formState.values.paymentAmount|| ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <FormControl className={classes.formControl}>
                  <InputLabel id="vendorName-select-outlined-label">
                      Vendor Name
                  </InputLabel>
                  <Select
                      id="vendorName-select"
                      value={formState.values.vendorIndex !== undefined ? formState.values.vendorIndex : ''}
                      onChange={event =>{handleChange(event,handleVendor)}}
                      name="vendorIndex"
                  >
                      {vendors !== undefined && vendors !== null ?
                          vendors.map((vendor, index) =>
                              <MenuItem key={vendor.id} value={index}>{vendor.name}</MenuItem>
                          ) : ""}
                  </Select>
                  
              </FormControl>
            <FormControl  className={classes.formControl}>
                <InputLabel id="paymentMode-select-outlined-label">
                Payment Mode
                </InputLabel>
                <Select
                id="paymentMode"
                value={formState.values.paymentMode || ''}
                onChange={event =>{handleChange(event,handleChangePaymentMode)}}
                name="paymentMode"
                >
                    <MenuItem key = {0} value={1}>Cash</MenuItem>
                    <MenuItem key = {1} value={2}>Bank</MenuItem>
                </Select>
            </FormControl>
            {showBanks ? 
            <FormControl className={classes.formControl}>
                <InputLabel id="banks-select-label">Banks</InputLabel>
                <Select
                id="banks-select"
                value={formState.values.bankIndex !== undefined ? formState.values.bankIndex : ''}
                onChange={handleChange}
                name="bankIndex"
                >
                {banks.map((bank,index)=>
                    <MenuItem key = {bank.id} value={index}>{bank.name}</MenuItem>
                )}
                </Select>
            </FormControl>
            :null}
          
           {isFormValid() && <Grid
                className={classes.grid}
                container
                justify='space-between'
                alignItems='flex-end'
            >
                <Grid  item xs={6}>
                    <Typography variant="h5" component="h2">
                        {"Remaining Amount : "+remainingPaymentAmount.toFixed(2)}
                    </Typography>
                </Grid>   
                <Grid hidden  item xs={6}>
                    <Typography variant="h5" component="h2">
                        {"Include TCS/TDS Amount"}
                        <Checkbox
                            checked={true}
                            onChange = {(event)=>{
                                console.debug("checked ",event.target.checked)
                                setIncludeTDSValue(event.target.checked)}}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </Typography>
                </Grid>         
            </Grid>}
            {isFormValid() && paymentTable && originalUnPaidPayments ?  
            <div>     
            <MaterialTable
                columns={[
                    {
                        title: 'BillNumber', field: 'key', editComponent: props => {
                            let index = -1
                            let purchaseId= 0
                            let otherExpenseId= 0
                            if(props.rowData.invoice !== undefined && props.rowData.invoice.purchaseId !== undefined&&props.rowData.invoice.otherExpenseId !== undefined){
                                purchaseId = props.rowData.invoice.purchaseId
                                otherExpenseId = props.rowData.invoice.otherExpenseId
                            }else if(props.rowData.purchaseId !== undefined||props.rowData.otherExpenseId !== undefined){
                                purchaseId = props.rowData.purchaseId
                                otherExpenseId = props.rowData.otherExpenseId
                            }
                            if(purchaseId !== 0||otherExpenseId !== 0){
                                let tempUnPaidPaymentIndex = unPaidPaymentdisplayArr.findIndex(purchaseId!==0?(val => val.id === purchaseId):(val => val.id === otherExpenseId));
                                if (tempUnPaidPaymentIndex === -1) {
                                    let originalUnPaidPaymentsIndex = originalUnPaidPayments.findIndex(purchaseId!==0?(val => val.id === purchaseId):(val => val.id === otherExpenseId));
                                    if (originalUnPaidPaymentsIndex === -1) {
                                        unPaidPaymentdisplayArr = unPaidPayments
                                        index = '';
                                    }else{
                                        unPaidPaymentdisplayArr = ([...unPaidPayments, originalUnPaidPayments[originalUnPaidPaymentsIndex]])
                                        index = unPaidPaymentdisplayArr.length - 1
                                    }
                                }else{
                                    index = tempUnPaidPaymentIndex
                                }
                            }else{
                                unPaidPaymentdisplayArr = unPaidPayments
                                index = '';
                            }
                            return <Select
                                id="materialType-select"
                                value={index}
                                required = {true}
                                onChange={e => {
                                    let outStandingAmount  = unPaidPaymentdisplayArr[e.target.value].remainingAmount 
                                    if(includeTDSValue){
                                        outStandingAmount = Math.abs((outStandingAmount + unPaidPaymentdisplayArr[e.target.value].tcsValue) - unPaidPaymentdisplayArr[e.target.value].tdsValue)
                                    }
                                    outStandingAmount = Number(outStandingAmount.toFixed(2));
                                    
                                if(outStandingAmount > 0){
                                        /*let ptData = paymentTable
                                        let obj = null*/
                                        let rowRemainingAmount = 0
                                        let localReminingAmount = Number(remainingPaymentAmount.toFixed(2));

                                        /*if (props.rowData.tableData!== undefined && props.rowData.tableData.id !== undefined){
                                            obj = paymentTable[props.rowData.tableData.id]
                                        }*/

                                        if(outStandingAmount > localReminingAmount){
                                            rowRemainingAmount = localReminingAmount
                                            localReminingAmount = 0
                                            //break;
                                        }else{
                                            rowRemainingAmount = outStandingAmount
                                            localReminingAmount = localReminingAmount - outStandingAmount
                                        }
                                        props.rowData.paymentAmount = rowRemainingAmount
                                        //Auto adding rowdata******************************
                                        /*if(obj === null){
                                            ptData = [...ptData,
                                                {
                                                    id: 0,
                                                    invoice: {
                                                        id: unPaidPaymentdisplayArr[e.target.value].id, 
                                                        purchaseId: unPaidPaymentdisplayArr[e.target.value].id, 
                                                        outstandingAmount: 0
                                                    },
                                                    paymentAmount: rowRemainingAmount,
                                                    tableData:{
                                                        id: paymentTable.length
                                                    }
                                                },
                                            ]
                                        }/*else
                                        ptData = [...ptData.slice(0, props.rowData.tableData.id),
                                            {...obj},
                                            ...ptData.slice(props.rowData.tableData.id + 1)]
                                            */
                                            //props.rowData.paymentAmount = rowRemainingAmount
                                            
                                            /* if(localReminingAmount === 0 && localReminingAmount === remainingPaymentAmount){
                                                alert("Remaining Payment is Zero. So please cancel the current row addition.")
                                            }else{
                                                let unpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                                    return src.id === unPaidPaymentdisplayArr[e.target.value].id
                                                })
                                                let newUnPaidPayments = [...unPaidPayments];
                                                newUnPaidPayments.splice(unpaidPaymentIndex, 1);
                    
                                                setUnPaidPayment([...newUnPaidPayments])
                                            //props.onRowDataChange(props.rowData)
                                                setPaymentTable([
                                                    ...ptData])
                                            }
                                        setRemainingPaymentAmount(localReminingAmount)*/

                                }
                                    console.log(e.target.value)
                                    if (unPaidPaymentdisplayArr[e.target.value].typeOfPurchase ===1){

                                    props.rowData.billNumber = parseInt(unPaidPaymentdisplayArr[e.target.value].billNumber);
                                    props.rowData.purchaseId = unPaidPaymentdisplayArr[e.target.value].id;
                                    props.rowData.otherExpenseId = 0}
                                    else
                                    {props.rowData.otherExpenseId = unPaidPaymentdisplayArr[e.target.value].id;
                                     props.rowData.purchaseId = 0}
                                    props.rowData.invoice = {
                                        ...props.rowData.invoice,
                                        id:props.rowData.billNumber,
                                        purchaseId:props.rowData.purchaseId,
                                        otherExpenseId: props.rowData.otherExpenseId
                                    }
                                    props.rowData.remainingAmount = unPaidPaymentdisplayArr[e.target.value].remainingAmount;

                                    props.rowData.key = "BillNumber #"+unPaidPaymentdisplayArr[e.target.value].billNumber+" | "+
                                    (unPaidPaymentdisplayArr[e.target.value].remainingAmount).toLocaleString(user.locales,amountObj)+" Outstanding"

                                    props.onChange("BillNumber #"+unPaidPaymentdisplayArr[e.target.value].billNumber+" | "+
                                    (unPaidPaymentdisplayArr[e.target.value].remainingAmount).toLocaleString(user.locales,amountObj)+" Outstanding");
                                }}
                            >
                                {unPaidPaymentdisplayArr.map((ele, index) =>{
                                    let billValue = ele.remainingAmount
                                    if(includeTDSValue){
                                        billValue = Math.abs((billValue + ele.tcsValue) - ele.tdsValue)
                                    }
                                    billValue = Number(billValue.toFixed(2));
                                    if(billValue < 2){
                                        return <></>;
                                    }
                                    return <MenuItem key={index} value={index}>
                                         {
                                             ele.typeOfPurchase === 1?
                                            "BillNumber #"+ele.billNumber+" | "+(billValue).toLocaleString(user.locales,amountObj)
                                            :
                                            "Other Expense #"+ele.billNumber+" | "+(billValue).toLocaleString(user.locales,amountObj)
                                        }
                                    </MenuItem>
                                })}
                            </Select>
                        },
                        render: rowData => {
                            let index = originalUnPaidPayments.findIndex(rowData.invoice.purchaseId!==0?(val => val.id === rowData.invoice.purchaseId):(val => val.id === rowData.invoice.otherExpenseId));
                            
                            let billValue = 0
                            if(index > -1 ){
                                billValue = originalUnPaidPayments[index].remainingAmount
                                if(includeTDSValue){
                                    billValue = Math.abs((billValue + originalUnPaidPayments[index].tcsValue) - originalUnPaidPayments[index].tdsValue)
                                }
                            }
                            if(editMode) {
                                let invoiceID = index > -1 ? originalUnPaidPayments[index]?.billNumber :(rowData.invoice?.billNumber !=="0" ? rowData.invoice?.billNumber : rowData.invoice?.id)
                                let invoiceBillValue = index > -1 ? billValue : rowData?.paymentAmount
                                return <span>{ (invoiceBillValue > -1) ? "Invoice #"+invoiceID+
                                " | "+(invoiceBillValue)?.toLocaleString(user.locales,amountObj) : ""}</span>
                            }

                            if(rowData.invoice.purchaseId!==0){
                            return <span>{index > -1 ? "BillNumber #"+originalUnPaidPayments[index].billNumber +
                            " | "+(billValue).toLocaleString(user.locales,amountObj) : ""}</span>}
                            else
                            {return <span>{index > -1 ? "Other Expense #"+originalUnPaidPayments[index].billNumber +
                            " | "+(billValue).toLocaleString(user.locales,amountObj) : ""}</span>}
                    }},
                    {title: 'Payment', field:'paymentAmount', type: 'numeric', render:rd=>rd.paymentAmount.toFixed(2), validate:function(rd){
                        if (rd.paymentAmount) {
                           return Number(rd.paymentAmount.toFixed(2)) === rd.paymentAmount?{isValid:true,helperText: ''}:{isValid:false,helperText: 'value should not exceed 2 decimal places'}
                        }
                    }}
                    
                ]}
                data={paymentTable || []}
                options={{
                    actionsColumnIndex: -1,
                    search: false,                    
                    pageSize:5,
                    toolbar: true
                }}
                editable={{
                    onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        
                        let err = validatePayments(newData)
                        if(err){
                            alert(err)
                            reject()
                        }else{
                            setRemainingPaymentAmount(remainingPaymentAmount - newData.paymentAmount)
                            unPaidPaymentdisplayArr = []
                            let newIndex = originalUnPaidPayments.findIndex(newData.invoice.purchaseId!==0?(val => val.id === newData.invoice.purchaseId):(val => val.id === newData.invoice.otherExpenseId));
                            let invoiceID = newIndex > -1 ? originalUnPaidPayments[newIndex]?.billNumber :(newData.invoice?.billNumber !=="0" ? newData.invoice?.billNumber : newData.invoice?.id)
                                newData = {
                                    ...newData,
                                    invoice:{
                                        purchaseId : newData.purchaseId,
                                        otherExpenseId : newData.otherExpenseId,
                                        id: newData.billNumber,
                                        billNumber: invoiceID
                                    },
                                    paymentAmount : parseFloat(newData.paymentAmount)
                                }
                            let unpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                if(newData.invoice.purchaseId!==0){
                                return src.id === newData.invoice.purchaseId}
                                else
                                {return src.id === newData.invoice.otherExpenseId}
                            })
                            let newUnPaidPayments = [...unPaidPayments];
                            newUnPaidPayments.splice(unpaidPaymentIndex, 1);

                            setUnPaidPayment([...newUnPaidPayments])
                            setPaymentTable([...paymentTable, newData]);
                            resolve();
                        }
                        console.debug(err)
                    }),
                    onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        let err = validatePayments(newData)
                        if(err){
                            alert(err)
                            reject()
                        }else{
                            setRemainingPaymentAmount(remainingPaymentAmount + (oldData.paymentAmount - newData.paymentAmount))
                            unPaidPaymentdisplayArr = []
                            const dataUpdate = [...paymentTable];
                            let newIndex = originalUnPaidPayments.findIndex(newData.invoice.purchaseId!==0?(val => val.id === newData.invoice.purchaseId):(val => val.id === newData.invoice.otherExpenseId));
                            let invoiceID = newIndex > -1 ? originalUnPaidPayments[newIndex]?.billNumber :(newData.invoice?.billNumber !=="0" ? newData.invoice?.billNumber : newData.invoice?.id)
                            if (oldData.isLegacy){
                                newData.isLegacy = false
                                oldData.isDeleted = true
                                setDeletedPayments([...deletedPayments, oldData]);
                            }

                            newData = {
                                ...newData,
                                paymentAmount : parseFloat(newData.paymentAmount),
                                billNumber : invoiceID,
                                invoice :{
                                    ...newData.invoice,
                                    billNumber: invoiceID
                                }
                                
                            }
                         
                            if(newData.purchaseId !== oldData.purchaseId&&newData.otherExpenseId !== oldData.otherExpenseId){
                                let newUnPaidPayments = [...unPaidPayments];
                                let newUnpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                    if(newData.invoice.purchaseId!==0){
                                        return src.id === newData.invoice.purchaseId}
                                        else
                                        {return src.id === newData.invoice.otherExpenseId}
                                })
                                newUnPaidPayments.splice(newUnpaidPaymentIndex, 1);

                                let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                                    if(oldData.invoice.purchaseId!==0){
                                        return src.id === oldData.invoice.purchaseId}
                                        else
                                        {return src.id === oldData.invoice.otherExpenseId}
                                })
                                
                                newUnPaidPayments = [
                                    ...newUnPaidPayments,
                                    originalUnPaidPayments[oldUnpaidPaymentIndex]
                                ]
                                
                                setUnPaidPayment([...newUnPaidPayments])
                            }
                            if(newData.otherExpenseId !== oldData.otherExpenseId){
                                let newUnPaidPayments = [...unPaidPayments];
                                let newUnpaidPaymentIndex = unPaidPayments.findIndex((src)=>{
                                    if(newData.invoice.purchaseId!==0){
                                        return src.id === newData.invoice.purchaseId}
                                        else
                                        {return src.id === newData.invoice.otherExpenseId}
                                })
                                newUnPaidPayments.splice(newUnpaidPaymentIndex, 1);

                                let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                                    if(oldData.invoice.purchaseId!==0){
                                        return src.id === oldData.invoice.purchaseId}
                                        else
                                        {return src.id === oldData.invoice.otherExpenseId}
                                })
                                
                                newUnPaidPayments = [
                                    ...newUnPaidPayments,
                                    originalUnPaidPayments[oldUnpaidPaymentIndex]
                                ]
                                
                                setUnPaidPayment([...newUnPaidPayments])
                            }
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            setPaymentTable([...dataUpdate]);
                            resolve();
                        }
                    }),
                    onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        unPaidPaymentdisplayArr = []
                        const dataDelete = [...paymentTable];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        if (oldData.isLegacy){
                            oldData.isDeleted = true
                        }
                        
                        setDeletedPayments([...deletedPayments, oldData])
                        setPaymentTable([...dataDelete]);
                        setRemainingPaymentAmount( remainingPaymentAmount + oldData.paymentAmount)
                        let oldUnpaidPaymentIndex = originalUnPaidPayments.findIndex((src)=>{
                            if(oldData.invoice.purchaseId!==0){
                                return src.id === oldData.invoice.purchaseId}
                                else
                                {return src.id === oldData.invoice.otherExpenseId}
                        })
                        let newUnPaidPayments = [
                            ...unPaidPayments,
                        ]
                        if(oldUnpaidPaymentIndex > -1){
                            newUnPaidPayments = [
                                ...newUnPaidPayments,
                                originalUnPaidPayments[oldUnpaidPaymentIndex]
                            ]
                        }
                        setUnPaidPayment([...newUnPaidPayments])

                        resolve();
                    }),
                }}
                title='Purchases and Other Expenses'
            />
             <TextField
                multiline
                rows="3"
                className={classes.noteTextField}
                error={hasError('paymentNote')}
                fullWidth
                helperText={
                    hasError('paymentNote') ? formState.errors.paymentNote[0] : null
                }
                label="Note"
                name="paymentNote"
                onChange={handleChange}
                type="text"
                value={formState.values.paymentNote || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            </div>
             :""}
             
             </div>

}
{}

            
        </DialogContent>
        <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!isFormValid() || popupLoading}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
    </Dialog>
};

export default Payment;