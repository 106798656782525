import React, {useEffect, useState} from 'react';
import api from '../../common/api';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import {ConfirmDialog,MaterialTable} from '../../components';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mobile from './components/mobile'
import DailyEggRate from "./components/dailyEggRate";
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {useGlobalState} from '../../state';


const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    red:{
        color:'red'
    },
    green:{
        color: 'green'
    },
    gridMargin:{
        margin:theme.spacing(1.5)
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        minWidth: 160,
    },
    searchButton:{
        color:'white',
        width:theme.spacing(21.25),
        backgroundColor:'#EF5350',
        '&:hover':{
            backgroundColor:'#E53935',
        },
        margin:theme.spacing(1),
        padding:theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom:theme.spacing(4)
    },
    success: {
        color:'white',
        backgroundColor:'#4CAF50',
        '&:hover':{
            backgroundColor:'#388E3C',
        },
        width:theme.spacing(13.75),
        margin:theme.spacing(1),
        padding:theme.spacing(1),
        marginLeft: theme.spacing(1.6),
        marginBottom:theme.spacing(4)
    }
}));

//let currentDailyEggRate = undefined;
// let editMode = false;
const DailyEggRatesView = ({showError, showMessage, history}) => {
    const [{user}] = useGlobalState();
    const [dailyEggRates, setDailyEggRates] = useState([]);
    const [eggMaterials, setEggMaterials] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentDailyEggRate, setCurrentDailyEggRate] = useState(undefined)
    const [showDailyEggRateDialog, setShowDailyEggRateDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [filterSelectedStartDate, setFilterSelectedStartDate] = useState(null);
    const [filterSelectedEndDate, setFilterSelectedEndDate] = useState(null);
    const [errorMessage, setErrorMessage] = useState('')
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});

    function onNewDailyEggRate(event) {
        setEditMode(false)
        setShowDailyEggRateDialog(true);
    }

    function handleDailyEggRateClose() {
        setErrorMessage(null);
        setCurrentDailyEggRate(undefined)
        setShowDailyEggRateDialog(false);
    }

    function handleDeleteConfirmClose() {
        setErrorMessage(null);
        setShowDeleteConfirm(false);
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }


    const onFarmDeleteConfirm = () => {
        if (currentDailyEggRate && currentDailyEggRate.id) {
            setShowDeleteConfirm(false);
            //setLoading(true);
            // showError('');
            const response = api.delete(`daily-egg-rates/${currentDailyEggRate.id}`);

            response.then(res => {
                if (res) {
                    let index = dailyEggRates.findIndex((srcDailyEggRate) => {
                        return srcDailyEggRate.id === currentDailyEggRate.id;
                    });
                    let newDailyEggRates = [...dailyEggRates];
                    newDailyEggRates.splice(index, 1);
                    setDailyEggRates(newDailyEggRates);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        showError('DailyEggRate not Deleted');
                    else
                        showError(err.message)
                } else {
                    showError('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                setCurrentDailyEggRate(undefined)
            });

        }
    };

    const onDelete = (dailyEggRate) => {
        setCurrentDailyEggRate(dailyEggRate)
        setShowDeleteConfirm(true);
    };

    const onDailyEggRateSave = (dailyEggRate, setPopupLoading) => {
        //setLoading(true);
        // setShowDailyEggRateDialog(false);
        // showError('');
        // showMessage('');
        const response = api.post('daily-egg-rates', {
            ...dailyEggRate,
        });

        response.then(res => {
            setDailyEggRates([...dailyEggRates, {
                ...res,
                ...dailyEggRate
            }]);
            setShowDailyEggRateDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('DailyEggRate not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };


    const onDailyEggRateEdit = (dailyEggRate) => {
        setCurrentDailyEggRate(dailyEggRate)
        setEditMode(true)
        setShowDailyEggRateDialog(true);
    };

    const onDailyEggRateUpdate = (dailyEggRate, setPopupLoading) => {
        // setShowDailyEggRateDialog(false);
        //setLoading(true);
        // showError('');
        const response = api.post(`daily-egg-rates/${dailyEggRate.id}`, {
            ...dailyEggRate,           
        });
        response.then(() => {
            let index = dailyEggRates.findIndex((srcDailyEggRate) => {
                return srcDailyEggRate.id === dailyEggRate.id;
            });
            setDailyEggRates([...dailyEggRates.slice(0, index),
                {...dailyEggRate},
                ...dailyEggRates.slice(index + 1)]);
            setShowDailyEggRateDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                setErrorMessage('DailyEggRate not Updated');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setPopupLoading(false);
        });
    };

//Get all Daily Entry By Date API Call
const getAllDailyEggRateByDate = () => {

    if(filterSelectedEndDate === null && filterSelectedStartDate === null){
        getAllDailyEggRates()
    }
    if(filterSelectedEndDate !== null && filterSelectedStartDate !== null && 
        filterSelectedEndDate < filterSelectedStartDate){
        showError('Start Date is Greater than or Equal to End Date');
        return
    }
    let payload = {
        startDate:filterSelectedStartDate,
        endDate:filterSelectedEndDate,
        
    }

    setLoading(true);
    showError('');
    showMessage('');

    const response = api.post('daily-egg-rates-by-date',{...payload});

    response.then(res => {
        let newResponse = []
        res.forEach((resElement)=>{
            if (resElement.eggRates !== null && resElement.eggRates !== undefined){
                (resElement.eggRates).forEach((eggRate)=>{
                    resElement = {
                        ...resElement,
                        [eggRate.materialName]:eggRate.eggRate
                    }
                })
            }
            newResponse = [
                ...newResponse,
                resElement
            ]
        })
        setDailyEggRates([...newResponse]);
    }).catch(err => {
        if (err.message) {
            if (err.message === 'Bad Request' || err.message === 'No data found')
                showError('No Daily Egg Rates Found');
            else
                showError(err.message)
        } else {
            showError('Unknown Error')
        }
    }).finally(() => {
        setLoading(false);
    });
}

    //Get all DailyEggRates API Call
    const getAllDailyEggRates = () => {
        setShowDailyEggRateDialog(false);
        setLoading(true);
        showError('');
        showMessage('');
        const response = api.get('daily-egg-rates');
        response.then(res => {
            let newResponse = []
            res.forEach((resElement)=>{
                if (resElement.eggRates !== null && resElement.eggRates !== undefined){
                    (resElement.eggRates).forEach((eggRate, index)=>{
                        resElement = {
                            ...resElement,
                            [eggRate.materialName]:eggRate.eggRate
                        }
                    })
                }
                newResponse = [
                    ...newResponse,
                    resElement
                ]
            })
            setDailyEggRates([...newResponse]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No DailyEggRates Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }
    
    const getEggMaterials = () => {
        const resp=api.get('egg-materials');
        resp.then(res=>{
            let tempNewColumns = []
            res.forEach(element => {
                tempNewColumns = [
                    ...tempNewColumns,
                    {
                        title: element.materialName, 
                        field: element.materialName, 
                        hidden: !isDesktop, 
                        editable: 'never',
                    }
                ]
            });
            
            setEggMaterials(res)
        })
    }
    const handleFilterStartDateChange = date => {
        setFilterSelectedStartDate(date);
    };

    const handleFilterEndDateChange = date => {
        setFilterSelectedEndDate(date);
    };
    const clearDateRangeFilter=()=>{
        setFilterSelectedStartDate(null)
        setFilterSelectedEndDate(null)
        getAllDailyEggRates()

    }
    const onLoad =() =>{
        getAllDailyEggRates()
        getEggMaterials()
    }

    useEffect(onLoad, []);

    let dailyEggRateNameColumn = {
        render: rowData => <Mobile data={rowData}
                                   onEdit={() => {
                                       onDailyEggRateEdit(rowData)
                                   }}
                                   onDelete={() => {
                                       onDelete(rowData)
                                   }}
        />
    };
    let actions = [];
    if (isDesktop) {
        dailyEggRateNameColumn={title: 'Large', field: 'large', hidden: true, editable: 'never'}
        actions = [
            rowData => ({
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Daily Egg Rates',
                onClick: (event, rowData) => onDailyEggRateEdit(rowData),
                hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
            }),
            rowData => ({
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Daily Egg Rates',
                onClick: (event, rowData) => onDelete(rowData),
                hidden: (user.role === "STAFF" && !moment(rowData.createTime).isSame(moment(), "date") ? true : false)
            })
        ]
    }

    let columns= [ 
        {title: 'id', field: 'id', hidden: true},
        dailyEggRateNameColumn,
        {title: 'Date', field: 'date', type: 'datetime', hidden: !isDesktop, defaultSort: 'desc',
        cellStyle: {textAlign:'center'}, headerStyle: {textAlign:'center'},
        editable: 'never',
        render: rowData => {
            return <span>{moment(rowData.date).format("DD-MM-YYYY")}</span>
        }},
        {title: 'NECC Price', field: 'neccPrice', hidden: !isDesktop, editable: 'never'}
    ]

    /* if( allColumns !== [] && isDesktop){
        columns = [
            ...columns,
            ...allColumns
        ]
    } */
    


    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Daily Egg Rate Details
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" color="primary" onClick={onNewDailyEggRate}>Add Daily Egg Rate</Button>
            </Grid>
        </Grid>
        <Grid container
                spacing={1}
                justify="flex-start"
                alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    className={classes.formControl}
                    margin="normal"
                    autoOk
                    variant="inline"
                    label="From"
                    format="dd/MM/yyyy"
                    value={filterSelectedStartDate || null}
                    onChange={handleFilterStartDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardDatePicker
                    className={classes.formControl}
                    margin="normal"
                    autoOk
                    variant="inline"
                    label="To"
                    format="dd/MM/yyyy"
                    value={filterSelectedEndDate  || null}
                    onChange={handleFilterEndDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
            <Grid>
                <Button   onClick={(getAllDailyEggRateByDate)} className={classes.success}>Search <SearchIcon/></Button>
                <Button  onClick={clearDateRangeFilter} className={classes.searchButton}>Clear Search <CloseIcon/></Button>
                </Grid>
            </Grid>
        <MaterialTable
            detailPanel={rowData => {
                return isDesktop ? (
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {rowData.eggRates.map(egg => {
                                    return <TableCell>{egg.materialName}</TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {rowData.eggRates.map(egg => {
                                    return <TableCell style={{textAlign:"center"}}>{egg.eggRate}</TableCell>
                                })}
                            </TableRow>
                        </TableBody>
                    </Table>
                ): null
            }}
            isLoading={loading}
            columns={columns}
            data={dailyEggRates}
            title='Daily Egg Rate Details Table'
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                
                toolbar: true
            }}
            actions={actions}
        />
        <DailyEggRate deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showDailyEggRateDialog} handleClose={handleDailyEggRateClose} onSave={onDailyEggRateSave} 
        onEdit={onDailyEggRateUpdate}  editMode={editMode} data={currentDailyEggRate} eggMaterials={eggMaterials}/>
        <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onFarmDeleteConfirm}
                       title='Delete Details ?'
                       subText='This action will delete the Daily Egg Rate Details. Please confirm.'/>
    </div>
}

export default DailyEggRatesView;