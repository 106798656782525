import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from "../../../common/formValidator";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import api from "../../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from '@material-ui/core/Chip';

const schema = {
  materialTypeIndex: {
    presence: { allowEmpty: false, message: "is required" },
  },
  materialIndex: {
    presence: { allowEmpty: false, message: "is required" },
  },
  metabolizableEnergy: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
    },
  },
  protein: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
    },
  },
  fat: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
    },
  },
  fibre: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
    },
  },
  calcium: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
    },
  },
  phosphorus: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
    },
  },
  lysine: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
    },
  },
  methionine: {
    presence: { allowEmpty: false, message: "is required" },
    type: "number",
    numericality: {
      greaterThanOrEqualTo: 0,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.text.default,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  progressBar: {
      margin: theme.spacing(2),
      position: 'absolute',
      top: '40%',
      zIndex: '9999'
  },
}));

const MaterialNutritionProfile = ({
  deleteErrorMessage, errorMessage,
  onEdit,
  materialTypes,
  data,
  onSave,
  handleClose,
  showError,
  showDialog,
  editMode = false,
}) => {
  const classes = useStyles();
  const [
    formState,
    hasError,
    handleChange,
    updateData,
    //setFormState,
  ] = useFromValidator(schema);

  const [materialNames, setMaterialNames] = useState([]);
  const [loadingMaterial, setLoadingMaterial] = useState(false);
  const [showMaterialNames, setShowMaterialNames] = useState(false);
  const [popupLoading, setPopupLoading] = useState(false);
  //const [materialTypeIndex, setMaterialTypeIndex] = useState("");
  //const [materialIndex, setMaterial] = useState("");
  //Called if the Profile is Set for Editing
  const resetBeforeEdit = () => {
    if (showDialog) {
      setPopupLoading(false);
      if (editMode) {
        getRawMaterialNameForGivenId(data.materialTypeId).then((res) => {
          let materialTypeIndex = materialTypes.findIndex(
            (ele) => ele.id === data.materialTypeId
          );
          let materialIndex = res.findIndex(
            (ele) => ele.id === data.materialId
          );
          data = {
            ...data,
            materialTypeIndex: materialTypeIndex,
            materialIndex: materialIndex,
          };
          updateData(data, editMode);
          console.log("data", data);
        });
      } else {
        updateData(data, editMode);
      }
    }
  };
  useEffect(resetBeforeEdit, [showDialog]);

  const onSubmit = () => {
    setPopupLoading(true)
    let tempData = {
      ...formState.values,
      materialTypeId: materialTypes[formState.values.materialTypeIndex].id,
      materialTypeName:
        materialTypes[formState.values.materialTypeIndex].materialType,
      materialId: materialNames[formState.values.materialIndex].id,
      materialName: materialNames[formState.values.materialIndex].name,
    };
    console.debug("tempdata", tempData);
    if (editMode) {
      onEdit(tempData, setPopupLoading);
      console.log("tempdata", tempData);
    } else onSave(tempData, setPopupLoading);
  };

  const getRawMaterialNameForGivenId = function (materialId) {
    showError("");
    const response = api.get(
      `raw-material-list-for-material-type/${materialId}`
    );
    response
      .then((res) => {
        setMaterialNames([...res]);
        setShowMaterialNames(true);
        setLoadingMaterial(true);
      })
      .catch((err) => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError(err.message);
        } else {
          showError("Unknown Error during API call for MaterialName");
        }
      })
      .finally(() => {
        setLoadingMaterial(false);
      });
    return response;
  };

  return (
    <Dialog
      disableBackdropClick
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog- title">
        {editMode ? "Update" : "New"} Material Nutrition Detail
      </DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
      {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>
                <FormControl disabled className={classes.formControl}>
          <InputLabel disabled id="type-select-outlined-label">Material Type</InputLabel>
          <Select 
            id="materialType"
            value={
              formState.values.materialTypeIndex !== undefined
                ? formState.values.materialTypeIndex
                : ""
            }
            onChange={(event) => {
              handleChange(
                event,
                getRawMaterialNameForGivenId(
                  materialTypes[event.target.value].id
                )
              );
              //setShowMaterialNames(true);
              //setLoadingMaterial(true);
              //setMaterialTypeIndex(event.target.value);
            }}
            name="materialTypeIndex"
          >
            {materialTypes !== undefined && materialTypes !== null
              ? materialTypes.map((materialType, index) => (
                  <MenuItem key={materialType.ID} value={index}>
                    {materialType.materialType}
                  </MenuItem>
                ))
              : ""}
          </Select>
        </FormControl>
        {loadingMaterial ? (
          <div className={classes.progressContainerMaterial}>
            <CircularProgress className={classes.progressBarMaterial} />
          </div>
        ) : showMaterialNames ? (
          <FormControl disabled className={classes.formControl} spacing={3}>
            <InputLabel id="materialName-select-label">
              Material Name
            </InputLabel>
            <Select disabled
              id="material"
              value={
                formState.values.materialIndex !== undefined
                  ? formState.values.materialIndex
                  : ""
              }
              onChange={handleChange}
              name="materialIndex"
            >
              {materialNames.map((material, index) => (
                <MenuItem key={material.id} value={index}>
                  {material.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          ""
        )}
        <TextField disabled
          className={classes.textField}
          error={hasError("metabolizableEnergy")}
          fullWidth
          helperText={
            hasError("metabolizableEnergy")
              ? formState.errors.metabolizableEnergy[0]
              : null
          }
          label="M.E."
          name="metabolizableEnergy"
          onChange={handleChange}
          type="number"
          value={formState.values.metabolizableEnergy !== undefined ? formState.values.metabolizableEnergy : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField disabled
          className={classes.textField}
          error={hasError("protein")}
          fullWidth
          helperText={hasError("protein") ? formState.errors.protein[0] : null}
          label="Protien in %"
          name="protein"
          onChange={handleChange}
          type="number"
          value={formState.values.protein !== undefined ? formState.values.protein : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField disabled
          className={classes.textField}
          error={hasError("fat")}
          fullWidth
          helperText={hasError("fat") ? formState.errors.fat[0] : null}
          label="Fat in %"
          name="fat"
          onChange={handleChange}
          type="number"
          value={formState.values.fat !== undefined ? formState.values.fat : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField disabled
          className={classes.textField}
          error={hasError("fibre")}
          fullWidth
          helperText={hasError("fibre") ? formState.errors.fibre[0] : null}
          label="Fibre in %"
          name="fibre"
          onChange={handleChange}
          type="number"
          value={formState.values.fibre !== undefined ? formState.values.fibre : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField disabled
          className={classes.textField}
          error={hasError("calcium")}
          fullWidth
          helperText={hasError("calcium") ? formState.errors.calcium[0] : null}
          label="Calcium in %"
          name="calcium"
          onChange={handleChange}
          type="number"
          value={formState.values.calcium !== undefined ? formState.values.calcium : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField disabled
          className={classes.textField}
          error={hasError("phosphorus")}
          fullWidth
          helperText={
            hasError("phosphorus") ? formState.errors.phosphorus[0] : null
          }
          label="Phosphorus in %"
          name="phosphorus"
          onChange={handleChange}
          type="number"
          value={formState.values.phosphorus !== undefined ? formState.values.phosphorus : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField disabled
          className={classes.textField}
          error={hasError("lysine")}
          fullWidth
          helperText={hasError("lysine") ? formState.errors.lysine[0] : null}
          label="Lysine in %"
          name="lysine"
          onChange={handleChange}
          type="number"
          value={formState.values.lysine !== undefined ? formState.values.lysine : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField disabled
          className={classes.textField}
          error={hasError("methionine")}
          fullWidth
          helperText={
            hasError("methionine") ? formState.errors.methionine[0] : null
          }
          label="Methionine in %"
          name="methionine"
          onChange={handleChange}
          type="number"
          value={formState.values.methionine !== undefined ? formState.values.methionine : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <DialogActions>
          <Button
            className={classes.signInButton}
            color="primary"
            disabled
            size="large"
            type="submit"
            variant="contained"
            onClick={onSubmit}
          >
            Save
          </Button>
        </DialogActions>
        </div>
        :
        <div>
      <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
        <FormControl className={classes.formControl}>
          <InputLabel id="type-select-outlined-label">Material Type</InputLabel>
          <Select
            id="materialType"
            value={
              formState.values.materialTypeIndex !== undefined
                ? formState.values.materialTypeIndex
                : ""
            }
            onChange={(event) => {
              handleChange(
                event,
                getRawMaterialNameForGivenId(
                  materialTypes[event.target.value].id
                )
              );
              //setShowMaterialNames(true);
              //setLoadingMaterial(true);
              //setMaterialTypeIndex(event.target.value);
            }}
            name="materialTypeIndex"
          >
            {materialTypes !== undefined && materialTypes !== null
              ? materialTypes.map((materialType, index) => (
                  <MenuItem key={materialType.ID} value={index}>
                    {materialType.materialType}
                  </MenuItem>
                ))
              : ""}
          </Select>
        </FormControl>
        {loadingMaterial ? (
          <div className={classes.progressContainerMaterial}>
            <CircularProgress className={classes.progressBarMaterial} />
          </div>
        ) : showMaterialNames ? (
          <FormControl className={classes.formControl} spacing={3}>
            <InputLabel id="materialName-select-label">
              Material Name
            </InputLabel>
            <Select
              id="material"
              value={
                formState.values.materialIndex !== undefined
                  ? formState.values.materialIndex
                  : ""
              }
              onChange={handleChange}
              name="materialIndex"
            >
              {materialNames.map((material, index) => (
                <MenuItem key={material.id} value={index}>
                  {material.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          ""
        )}
        <TextField
          className={classes.textField}
          error={hasError("metabolizableEnergy")}
          fullWidth
          helperText={
            hasError("metabolizableEnergy")
              ? formState.errors.metabolizableEnergy[0]
              : null
          }
          label="M.E."
          name="metabolizableEnergy"
          onChange={handleChange}
          type="number"
          value={formState.values.metabolizableEnergy !== undefined ? formState.values.metabolizableEnergy : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField
          className={classes.textField}
          error={hasError("protein")}
          fullWidth
          helperText={hasError("protein") ? formState.errors.protein[0] : null}
          label="Protien in %"
          name="protein"
          onChange={handleChange}
          type="number"
          value={formState.values.protein !== undefined ? formState.values.protein : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField
          className={classes.textField}
          error={hasError("fat")}
          fullWidth
          helperText={hasError("fat") ? formState.errors.fat[0] : null}
          label="Fat in %"
          name="fat"
          onChange={handleChange}
          type="number"
          value={formState.values.fat !== undefined ? formState.values.fat : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField
          className={classes.textField}
          error={hasError("fibre")}
          fullWidth
          helperText={hasError("fibre") ? formState.errors.fibre[0] : null}
          label="Fibre in %"
          name="fibre"
          onChange={handleChange}
          type="number"
          value={formState.values.fibre !== undefined ? formState.values.fibre : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField
          className={classes.textField}
          error={hasError("calcium")}
          fullWidth
          helperText={hasError("calcium") ? formState.errors.calcium[0] : null}
          label="Calcium in %"
          name="calcium"
          onChange={handleChange}
          type="number"
          value={formState.values.calcium !== undefined ? formState.values.calcium : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField
          className={classes.textField}
          error={hasError("phosphorus")}
          fullWidth
          helperText={
            hasError("phosphorus") ? formState.errors.phosphorus[0] : null
          }
          label="Phosphorus in %"
          name="phosphorus"
          onChange={handleChange}
          type="number"
          value={formState.values.phosphorus !== undefined ? formState.values.phosphorus : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField
          className={classes.textField}
          error={hasError("lysine")}
          fullWidth
          helperText={hasError("lysine") ? formState.errors.lysine[0] : null}
          label="Lysine in %"
          name="lysine"
          onChange={handleChange}
          type="number"
          value={formState.values.lysine !== undefined ? formState.values.lysine : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <TextField
          className={classes.textField}
          error={hasError("methionine")}
          fullWidth
          helperText={
            hasError("methionine") ? formState.errors.methionine[0] : null
          }
          label="Methionine in %"
          name="methionine"
          onChange={handleChange}
          type="number"
          value={formState.values.methionine !== undefined ? formState.values.methionine : ""}
          variant="outlined"
          style={{ backgroundColor: "#ffffff" }}
        />
        <DialogActions>
          <Button
            className={classes.signInButton}
            color="primary"
            disabled={!formState.isValid}
            size="large"
            type="submit"
            variant="contained"
            onClick={onSubmit}
          >
            Save
          </Button>
        </DialogActions>
        </div>
      }
      </DialogContent>
    </Dialog>
  );
};

export default MaterialNutritionProfile;
