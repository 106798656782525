import { createTheme } from '@material-ui/core/styles';

import palette from './palettes/default';
import bluePalette from "./palettes/blueTheme";
import darkBluePalette from "./palettes/darkBlueTheme";
import typography from './typography';
import overrides from './overrides';
import orangePalette from "./palettes/orangeTheme";
import defaultAppPalette from "./palettes/defaultTheme";

const theme = createTheme({
    palette,
    typography,
    overrides,
    zIndex: {
        appBar: 1200,
        drawer: 1100
    }
});

export const orangeTheme = createTheme({
    palette: orangePalette,
    typography,
    overrides,
    zIndex: {
        appBar: 1200,
        drawer: 1100
    }
});

export const blueTheme = createTheme({
    palette: bluePalette,
    typography,
    overrides,
    zIndex: {
        appBar: 1200,
        drawer: 1100
    }
});

export const darkBlueTheme = createTheme({
    palette: darkBluePalette,
    typography,
    overrides,
    zIndex: {
        appBar: 1200,
        drawer: 1100
    }
});

export const appDefaultTheme = createTheme({
    palette: defaultAppPalette,
    typography,
    overrides,
    zIndex: {
        appBar: 1200,
        drawer: 1100
    }
});


export default theme;
