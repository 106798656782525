import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useTheme from '@material-ui/styles/useTheme';
import makeStyles from "@material-ui/styles/makeStyles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MaterialTable } from '../../components';
import api from '../../common/api';

const useStyles = makeStyles(theme => ({
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
}));

const FarmStorage = ({ showError, showMessage, match }) => {
    const theme = useTheme();
    const farmID = match.params['farmID'];
    const [loading, setLoading] = useState(false);
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });
    const [farmStorage, setfarmStorage] = useState(null);
    const getAllFarmStorage = () => {
        showError('');
        showMessage('');

        const response = api.get(`/farm/${farmID}/farmStorage`);
        response.then(res => {
            setfarmStorage(res);

        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('There is no storage available.');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }

    const onload = () => {
        setLoading(true);
        Promise.all([getAllFarmStorage()]).finally(() => {
            setLoading(false);
        });
    }
    useEffect(onload, []);

    return <div className={classes.root}>
        <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.topBar}
        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    Farm Storage
                </Typography>
            </Grid>
        </Grid>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Name', field: 'materialName', hidden: !isDesktop, editable: 'never' },
                { title: 'Availble Quantity', field: 'availableStock', hidden: !isDesktop, editable: 'never', render:rd=>rd.availableStock.toFixed(2) },
            ]}
            title='Farm Storage'
            data={Array.isArray(farmStorage) && farmStorage.length > 0 
                ? farmStorage : []}
            options={{
                actionsColumnIndex: -1,
                search: true,
                showTitle: true,
                toolbar: true
            }}
        />
    </div>
}

export default FarmStorage;