import React,{useEffect,useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import {useGlobalState} from '../../../state';
import { ConfirmDialog } from "../../../components";
import { isUtcDateTimeExpired } from '../../../common/date';

const useCardStyles = makeStyles(theme => ({
    cardContainer:{
        width:'inherit'
    },
    card: {
        minWidth: 387,
        ['@media (max-width:400px)']:{ // eslint-disable-line no-useless-computed-key
            minWidth:'auto',
            marginRight:'10px'
        },
        marginLeft:'10px',
        marginTop:'5px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    media: {
        height: 140,
        backgroundSize: 'contain'
    },
    cardAction:{
        margin: 'auto'
    },
    grid:{
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    greenChip: {
        borderRadius: '5px',
        margin: 'auto',
        size:'medium',
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '6.5px 10px',
        color: '#ffffff',
        backgroundColor: '#00B020',
    }
}));

const SubscriptionCardsMonthly = ({showBillingConform,handlebillingConfirmClose,billingRedirect,handlebillingConfirmOpen,isDesktop,plan, onDelete, onEdit,showError,history, planReport, onUpdatePlanForUsers,cancelPlanForUser, currentPlan}) => {
    const classes = useCardStyles();
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: (user.currency === "INR") ? user.currency : "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }


    



    const onload=()=>{
        console.debug(planReport)
    }
    useEffect(onload, []);
   
const {tier, planAmount,usd,birdCountMin,birdCountMax,id,planType} = plan;
const [showCancelConfirm, setShowCancelConfirm] = useState(false);
const onCancel = () => {
    setShowCancelConfirm(true);
  };

  
    
    const onClickHandler = (e) =>{
        if(!isDesktop) {
            handlebillingConfirmOpen()
            return
        }
        onUpdatePlanForUsers(plan)
        showError('')
    }
    const cancelPlan = (e)=>{
        cancelPlanForUser(plan)
    }
    function handleCancelConfirmClose() {
        setShowCancelConfirm(false);
      }
    
    return(
        <div>
        {planType === 1?
        <Grid item xl={3} lg={4} md={4} xm={4} sm={6} className={classes.cardContainer}>
        <Card className={classes.card}>
            <CardHeader
                title={tier}
            />
            <CardMedia
                className={classes.media}
                {...(tier==="Monthly_Tier-1")?{image:"images/chick-1-tinted.png"} : (tier==="Monthly_Tier-2")?{image:"images/chick-2-tinted.png"}:{image:"images/chick-3-tinted.png"}}
            />
            <CardContent>
                <Grid
                    container
                    justify='space-between'
                    alignItems='flex-end'
                    className={classes.topBar}
                >
                   
                    <Grid className={classes.grid}  xs={6}   item>
                        <Typography variant="h5" component="h2">
                            {birdCountMin !== null && birdCountMin !== undefined ? 
                            (birdCountMin).toLocaleString(user.locales) : 0}
                        </Typography>
                        <Typography color="textSecondary">
                            Minimum Bird Count
                        </Typography>
                    </Grid>
                    <Grid className={classes.grid}  xs={6}   item>
                        <Typography variant="h5" component="h2">
                            {birdCountMax !== null && birdCountMax !== undefined ? 
                            (birdCountMax).toLocaleString(user.locales) : 0}
                        </Typography>
                        <Typography color="textSecondary">
                            Maximum Bird Count
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions >
            {
                    ((currentPlan.id !==id && currentPlan !== id) || (currentPlan?.endDate  && currentPlan.endDate.Valid && isUtcDateTimeExpired(currentPlan.endDate.Time) ))
                    ?
                    <Button className={classes.cardAction} variant="contained" size="small" color="primary" 
                    onClick={(onClickHandler)}>{((user.currency === "INR") ? planAmount : usd).toLocaleString(user.locales,amountObj)}/monthly</Button>
                    :
                    <>
                    {
                    (currentPlan?.endDate  && currentPlan.endDate.Valid && !isUtcDateTimeExpired(currentPlan.endDate.Time) 
                    ? 
                    <Button className={classes.cardAction} variant="contained" size="small" color="primary" 
                    onClick={(onClickHandler)}>Renew plan</Button>
                    :
                    <Grid className={classes.cardAction}align="center"><div style={{ textAlign: "center", minWidth: 'fit-content' }} className={classes.greenChip}>CURRENT PLAN</div></Grid>
                    )
                    }
                    <Button className={classes.cardAction} variant="contained" size="small" color="#FF0000" onClick={(onCancel)}>Cancel Subscription </Button>
                    </>
                }
                
            </CardActions>
        </Card>
        <ConfirmDialog
            showDialog={showBillingConform}
            handleClose={handlebillingConfirmClose}
            onConfirm={billingRedirect}
            title="Mobile Subscription is not yet supported"
            subText="Please use the site in desktop mode. you will be redirect to Subscription page in new tab. Please confirm."
        />
        <ConfirmDialog
        showDialog={showCancelConfirm}
        handleClose={handleCancelConfirmClose}
        onConfirm={cancelPlan}
        title="Cancel Plan ?"
        subText="This action will Cancel Your Current Plan. Please confirm."
      />
    </Grid>:''}
    </div>)
};

export default SubscriptionCardsMonthly;