import React, { useEffect, useState, useRef } from 'react';
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from 'html2canvas';
import moment from "moment";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { useReactToPrint } from 'react-to-print';
import Divider from '@material-ui/core/Divider';
import {useGlobalState} from '../../state';

window.html2canvas = html2canvas;
const useStyles = makeStyles(theme => ({
    divider: {
        backgroundColor: '#101010'
    },
    root: props => ({
        margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        minWidth: 160,
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    singleLineBorder: {
        borderTop: 'solid 1px #F26522',
        borderBottom: 'solid 1px #F26522'
    },
    doubleLineBorder: {
        borderTop: 'double #F26522',
        borderBottom: 'double #F26522'
    },
    boldedText: {
        fontWeight: 'bold'
    },
    reportContainer: {

    },
    reportHeader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    rawMaterialName: {
        textAlign: 'center',
        textDecoration: 'underline'
    }
}));

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: '1em',
        backgroundColor: theme.palette.common.white,
        padding: '6px',
        paddingLeft: '16px'
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);


const bankStatementHeaders = JSON.parse(`[["Date","Item","Paid","Received","Balance"]]`);
const cashStatementHeaders = JSON.parse(`[["Date","Item","Note", "Paid","Received","Balance"]]`);

let tableHeader = bankStatementHeaders;




const generateExcel = function (header, data, isCashReport) {

    console.debug("excel ", header, data)
    let csvData = '';
    header.forEach((column, index) => {
        if (index === 0)
            csvData = csvData + column
        else
            csvData = csvData + ',' + column
    });
    csvData = csvData + '\r\n';
    data.forEach(row => {
        header[0].forEach((ele, index) => {
            if (ele === "Balance") {
                row[ele.toLowerCase()] = Math.abs(row[ele.toLowerCase()])
            }
            if (index === 0)
                csvData = csvData + row[ele.toLowerCase()]
            else
                csvData = csvData + ',' + row[ele.toLowerCase()]
        })
        csvData = csvData + '\r\n';
    });

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
    let fileName = isCashReport ? "Cash Statement Report.csv" : "Bank Statement Report.csv"
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);


}



const BankStatementReport = function ({ showError, match }) {
    const theme = useTheme();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [bankId, setBankId] = useState(undefined);
    const [data, setData] = useState([]);
    const [banks, setBanks] = useState([]);
    const [invoiceSetting, setInvoiceSetting] = useState({});
    const [bankIndex, setBankIndex] = useState(undefined);
    const [isCashReport, setIsCashReport] = useState(false);

    useEffect(() => {
        setData([])
        if (window.location.pathname === "/cash-statement-report") {
            setIsCashReport(true)
            tableHeader = cashStatementHeaders
        } else {
            setIsCashReport(false)
            tableHeader = bankStatementHeaders
        }
        
        setStartDate(new Date())
        setEndDate(new Date())
        showError('')
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])

    useEffect(() => {
        if (startDate && endDate && (startDate.getTime() < endDate.getTime()) && (bankId !== undefined || isCashReport)) {
            setValid(true);
        } else {
            setValid(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, bankId]);


    const getAllInvoiceSettings = () => {
        showError('');
        const response = api.get('invoiceSettings');
        response.then(res => {
            setInvoiceSetting(res[0]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No InvoiceSettings Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });

        return response;
    }
    //Get all banks API Call

    function getBanks() {
       return api.get('bank')
        .then((res)=>{
            setBanks(res)
            console.log(res, "bank - list");
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    const onLoad = () => {
        setLoading(true);
        Promise.all([getAllInvoiceSettings(),getBanks()]).finally(() => {
            setLoading(false);
        });
    }
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }

    useEffect(onLoad, []);

    const generateReport = function () {
        showError('');
        setLoading(true);
        const payload = {
            startDate: startDate,
            endDate: endDate,
            id: bankId,
            isCashReport,
        };

        console.log(payload,"pl");

        const response = api.post('reports/bank-statement-report', payload);

        response.then(res => {
            setData(res);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found'){
                    showError(err.message)
                    setData([])
                }
                else{
                    showError("Server Error")
                }
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });

    };

    const handleFilterBankChange = (event) => {
        console.log("event.target.value ", event.target.value)
        setBankId(banks[event.target.value].id);
        setBankIndex(event.target.value);
    };

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({ isDesktop });


    const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
    });


    return <div className={classes.root}>
        <Grid
            container
            alignItems='flex-start'
            className={classes.topBar}

        >
            <Grid>
                <Typography display="block" variant="h3" gutterBottom>
                    {isCashReport ? "Cash ": "Bank "} Statement Report
                </Typography>
            </Grid>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container
                spacing={3}
                justify="flex-start"
                alignItems="center">

                <Grid item>
                    <KeyboardDatePicker
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="From"
                        format="dd/MM/yyyy"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>

                <Grid item>
                    <KeyboardDatePicker
                        margin="normal"
                        autoOk
                        variant="inline"
                        label="To"
                        format="dd/MM/yyyy"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
                <Grid item>
                {!isCashReport &&
                    <FormControl className={classes.formControl}>
                        <InputLabel id="bankName-select-outlined-label">
                            Bank Name
                    </InputLabel>
                        <Select
                            id="bankName-select"
                            value={bankIndex !== undefined ? bankIndex : ""}
                            onChange={handleFilterBankChange}
                            name="bankId"
                        >
                            {banks !== undefined && banks !== null ?
                                banks.map((bank, index) =>
                                    <MenuItem key={bank.id} value={index}>{bank.name}</MenuItem>
                                ) : ""}
                        </Select>
                    </FormControl>
                }
                </Grid>
                <Grid item>
                    <Button disabled={!valid} variant="contained" color="primary"
                        onClick={generateReport}>Generate</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                        onClick={generatePDF} endIcon={<PictureAsPdfIcon />}>Download
                        PDF</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!valid || data.length < 1} variant="outlined" color="primary"
                        onClick={() => generateExcel(tableHeader, data, isCashReport)}
                        endIcon={<GetAppIcon />}>Download Excel</Button>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
        {loading ?
            <div className={classes.progressContainer}>
                <CircularProgress className={classes.progressBar} />
            </div>
            :
            <div id="bank-invoice" ref={componentRef} style={{ paddingTop: '35px' }}>
                <div className="invoice-box" style={{
                    backgroundColor: '#ffffff',
                    fontFamily: 'Montserrat,Roboto,sans-serif',
                    color:'#263238'
                }}>
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr >
                                <td>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ width: "40%" }}><img src={invoiceSetting.companyLogo}
                                                                    style={{ //width: '50%', 
                                                                        maxWidth: '130px'
                                                                    }}
                                                                    alt="company logo"></img><br /><br />
                                                                </td>
                                                                <td style={{ width: "50%", fontSize: "13px" }}>
                                                                    <p style={{ fontSize: "14px", fontWeight: 'bolder' }}>
                                                                        {invoiceSetting.companyName}</p>
                                                                    <p style={{ whiteSpace: 'pre-wrap' }}
                                                                    // className="show-white-space"
                                                                    >{invoiceSetting.address}</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "right", fontWeight: '600', fontSize: '23px' }} colSpan="2">
                                                                    Statement of Account<br />
                                                                    <span style={{ fontWeight: '400', fontSize: '18px' }}>
                                                                        Account activity</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <b>{banks[bankIndex] !== undefined ? banks[bankIndex].name + " Bank Statement" : ""}</b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {banks[bankIndex] !== undefined ? banks[bankIndex].primaryContact : ""}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                    {banks[bankIndex] !== undefined ? banks[bankIndex].address : ""}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style={{ textAlign: "right", width: "50%" }}>
                                                    <table style={{}}>
                                                        <thead>
                                                            <tr>
                                                                <td style={{ textAlign: "right" }}>
                                                                    From
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {moment(startDate).format('MMM Do YYYY')}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: "right" }}>
                                                                    To
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {moment(endDate).format('MMM Do YYYY')}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: "right" }}>
                                                                    Opening Balance
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {data.length > 0 && data[0] && data[0].balance? data[0].balance.toLocaleString(user.locales,amountObj):" "}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: "right" }}>
                                                                    Closing Balance
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {data.length > 0 && data[data.length-1].balance && data[data.length-1].balance? data[data.length-1].balance.toLocaleString(user.locales,amountObj):" "}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2" >
                                                                    <Divider />
                                                                    <br />
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style={{ minHeight: "450px" }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>Date</StyledTableCell>
                                                    <StyledTableCell>Item</StyledTableCell>
                                                   {isCashReport ?  <StyledTableCell>Note</StyledTableCell>: null}
                                                    <StyledTableCell>Paid</StyledTableCell>
                                                    <StyledTableCell>Received</StyledTableCell>
                                                    <StyledTableCell>Balance</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.map((data, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell style={{
                                                            width:"150px"
                                                        }} >
                                                            {data.date ? data.date : '-'}
                                                        </StyledTableCell>
                                                        <StyledTableCell >
                                                            {data.item ? data.item : '-'}
                                                        </StyledTableCell>
                                                        {isCashReport? <StyledTableCell >
                                                            {data.note ? data.note : null}
                                                        </StyledTableCell>
                                                        :null}
                                                        <StyledTableCell >
                                                            {data.paid ? data.paid.toLocaleString(user.locales,amountObj) : '-'}
                                                        </StyledTableCell>
                                                        <StyledTableCell >
                                                            {data.received ? data.received.toLocaleString(user.locales,amountObj) : '-'}
                                                        </StyledTableCell>
                                                        <StyledTableCell >
                                                            {data.balance ? data.balance.toLocaleString(user.locales,amountObj) : '-'}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        }
    </div>
}

export default BankStatementReport;