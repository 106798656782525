import React,{useState,useEffect} from 'react';
import {AppProgressBar} from '../../components';
import api from '../../common/api';
import moment from "moment";
import {useGlobalState} from '../../state';

const InvoiceView = ({showError, showMessage, history,match}) => {
    const saleID = match.params['saleID'];
    const [invoice,setInvoice] = useState();
    const [loading, setLoading] = useState(true);
    const [{user}] = useGlobalState();
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    
    useEffect(()=>{
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get(`invoice/${saleID}`);

        response.then(res => {
            
            
            setInvoice({...res});
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Bank Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    },[saleID,showError,showMessage])
    
    if(loading){
        
        return <AppProgressBar loading={loading}/>
    }
    
    
    
    if(invoice){  
    return <div className="invoice-box">
    <table cellPadding="0" cellSpacing="0">
        <tbody>
        <tr className="top">
            <td colSpan="5">
                <table>
                <tbody>
                    <tr>
                        <td className="title">
                            <img src="\images\logos\new-logo1.png" className="invoice-logo" alt="company logo"></img>
                        </td>
                        
                        <td>
                            Invoice #: {invoice.invoiceID}<br></br>
                            Created: {moment(invoice.invoiceDate).format('MM/DD/YYYY')}<br></br>
                            Due: {moment(invoice.dueDate).format('MM/DD/YYYY')}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        
        <tr className="information">
            <td colSpan="5">
                <table>
                    <tbody>
                    <tr>
                        <td>
                            {invoice.accountName}<br></br>
                            {invoice.accountAddress1}<br></br>
                            {invoice.accountAddress2}<br></br>
                            {invoice.accountMailID}
                        </td>
                        
                        <td>
                            {invoice.clientName}<br></br>
                            {invoice.clientAddress1}<br></br>
                            {invoice.clientAddress2}<br></br>
                            {invoice.clientMailID}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        {
            /*
            <tr className="heading">
                <td>
                    Payment Method
                </td>
                
                <td>
                    Check #
                </td>
            </tr>
             <tr className="details">
            <td>
                Check
            </td>
            
            <td>
                1000
            </td>
        </tr >
            */
        }
        <tr className="heading">
            <td>
                Item Name
            </td>
            <td>
                Inventory Type
            </td>
            <td>
                Quantity
            </td>
            <td>
                Cost Per Unit
            </td>
            <td>
                Bill Value
            </td>
        </tr>
        
        {
            invoice.saleMaterials.map(saleItem=>{
                return  <tr className="item" key={saleItem.id}>
                            <td>{saleItem.materialName}</td>
                            <td>F1 (CHICK-1)</td>
                            <td>4</td>
                            <td>10</td>
                            <td>40</td>
                        </tr>
            })
        }
       
        <tr className="total">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
               Total: {invoice.totalBillValue.toLocaleString(user.locales,amountObj)}
            </td>
        </tr>
        </tbody>
    </table>
</div>
  }else{
      return null;
  }
};

export default InvoiceView;