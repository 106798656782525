import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


const schema = {
    openingDate: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    name: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    bankAccountNumber: {
        presence: {allowEmpty: false, message: 'is required'},
        type: 'string',
        numericality: {
            greaterThanOrEqualTo: 0,
        }
    },
    openingBalance: {
        presence: false,
        type: 'number',
        numericality: {
            greaterThanOrEqualTo: 0,
        }
    },
    accountTypeIndex: {
        presence: {allowEmpty: false, message: 'is required'}
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },
}));

const Bank = ({deleteErrorMessage, errorMessage, onEdit, accountTypes,data, onSave, handleClose, showDialog, editMode = false}) => {
    const classes = useStyles();
    const [formState,hasError,handleChange,updateData, setFormState] = useFromValidator(schema);
    const [popupLoading, setPopupLoading] = useState(false);

    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if(editMode){ 
            console.log("data", data)
        }
        if(showDialog){
            setPopupLoading(false);
            updateData(data, editMode);
        }
        
    };
    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        setPopupLoading(true)
        const tempData = {
            ...formState.values,
            accountTypeId: accountTypes[formState.values.accountTypeIndex].ID,
            accountType:accountTypes[formState.values.accountTypeIndex].Name,
        };
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        if (editMode){
            onEdit(tempData, setPopupLoading);
            console.log("tempdata", tempData)
        }
        else
            onSave(tempData, setPopupLoading);
    };

    const setNullForEmptyString = (event) => {
        console.debug("null ",event.target.name,event.target.value)
        if(event.target.value === 0 || event.target.value === ''){
            setFormState({
                ...formState,
                values : {
                    ...formState.values,
                    [event.target.name]:null
                }
            })
        }
    };



    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   
        >{editMode?'Update':'New'} Bank Detail</DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
          <DialogContent>
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>
                <TextField disabled
                className={classes.textField}
                error={hasError('name')}
                fullWidth
                helperText={
                    hasError('name') ? formState.errors.name[0] : null
                }
                label="Bank Name"
                name="name"
                onChange={handleChange}
                type="text"
                value={formState.values.name || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
             <FormControl disabled  className={classes.formControl}>
                <InputLabel id="type-select-outlined-label">
                Account Type
                </InputLabel> 
                <Select disabled
                id="accountType"
                value={formState.values.accountTypeIndex !== undefined ? formState.values.accountTypeIndex : ''}
                onChange={handleChange}
                name="accountTypeIndex"
                >
                      {accountTypes !== undefined && accountTypes !== null ?
                    accountTypes.map((account,index)=>
                    <MenuItem key = {account.ID} value={index}>{account.Name}</MenuItem>
                ):""}     
                </Select>
            </FormControl>
            <TextField disabled
                className={classes.textField}
                error={hasError('bankAccountNumber')}
                fullWidth
                helperText={
                    hasError('bankAccountNumber') ? formState.errors.bankAccountNumber[0] : null
                }
                label="Account Number"
                name="bankAccountNumber"
                onChange={handleChange}
                type="text"
                value={formState.values.bankAccountNumber || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            /> 
            <TextField disabled
                className={classes.textField}
                error={hasError('openingBalance')}
                fullWidth
                helperText={
                    hasError('openingBalance') ? formState.errors.openingBalance[0] : null
                }
                label="Opening Balance"
                name="openingBalance"
                
                onChange={event => {handleChange(event,setNullForEmptyString)}}
                type="number"
                value={formState.values.openingBalance || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField disabled
                multiline
                rowsMax="3"
                className={classes.textField}
                error={hasError('description')}
                fullWidth
                helperText={
                    hasError('description') ? formState.errors.description[0] : null
                }
                label="Description"
                name="description"
                onChange={handleChange}
                type="text"
                value={formState.values.description || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>:
            <div>
          <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
          </div> 
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker 
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.openingDate || null}
                    onChange={data => {
                        handleChange({ target: { name: 'openingDate', value: data } })
                    }}
                    helperText={
                        hasError('date') ? formState.errors.openingDate[0] : null
                    }
                    animateYearScrolling
                    name="openingDate"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
          <br/>
            <TextField
                className={classes.textField}
                error={hasError('name')}
                fullWidth
                helperText={
                    hasError('name') ? formState.errors.name[0] : null
                }
                label="Bank Name"
                name="name"
                onChange={handleChange}
                type="text"
                value={formState.values.name || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
             <FormControl  className={classes.formControl}>
                <InputLabel id="type-select-outlined-label">
                Account Type
                </InputLabel>
                <Select
                id="accountType"
                value={formState.values.accountTypeIndex !== undefined ? formState.values.accountTypeIndex : ''}
                onChange={handleChange}
                name="accountTypeIndex"
                >
                      {accountTypes !== undefined && accountTypes !== null ?
                    accountTypes.map((account,index)=>
                    <MenuItem key = {account.ID} value={index}>{account.Name}</MenuItem>
                ):""}     
                </Select>
            </FormControl>
            <TextField
                className={classes.textField}
                error={hasError('bankAccountNumber')}
                fullWidth
                helperText={
                    hasError('bankAccountNumber') ? formState.errors.bankAccountNumber[0] : null
                }
                label="Account Number"
                name="bankAccountNumber"
                onChange={handleChange}
                type="text"
                value={formState.values.bankAccountNumber || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                className={classes.textField}
                error={hasError('openingBalance')}
                fullWidth
                helperText={
                    hasError('openingBalance') ? formState.errors.openingBalance[0] : null
                }
                label="Opening Balance"
                name="openingBalance"
                
                onChange={event => {handleChange(event,setNullForEmptyString)}}
                type="number"
                value={formState.values.openingBalance || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <TextField
                multiline
                rowsMax="3"
                className={classes.textField}
                error={hasError('description')}
                fullWidth
                helperText={
                    hasError('description') ? formState.errors.description[0] : null
                }
                label="Description"
                name="description"
                onChange={handleChange}
                type="text"
                value={formState.values.description || ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
          }
        </DialogContent>
    </Dialog>
};

export default Bank;