const version = {
    lite: 100,
    pro: 200,
    proPlus: 300,
}

const standardPageSizes = [5, 10, 20];

export  {
    version,
    standardPageSizes
}