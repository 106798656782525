import profileReducer from '../reducers/profile';

export const appReducer = ({user}, action) => {
    return {
        user: profileReducer(user, action)
    };
};

export const getInitialState = () => {
    let userProfile = null;
    if (window.localStorage.getItem('profile') && window.localStorage.getItem('profile').length > 0) {
        userProfile = JSON.parse(window.localStorage.getItem('profile'));
        userProfile.isLoggedIn = (userProfile && userProfile.token && userProfile.token.length > 0)
    }
    return {
        user: userProfile
    }
};