import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({

    formControl: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        minWidth: 160,
    },
}));

const LineDialog = ({showDialog}) => {
    const classes = useStyles();


    return <Dialog disableBackdropClick maxWidth='xs' open={showDialog} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">
    Pick your line
    </DialogTitle>
      <DialogContent>
      <div>
      <FormControl className={classes.formControl}>
                 <InputLabel id="materialName-select-label">Line name</InputLabel>
                 <Select
                     id="materialName-select"
                     value={''}
                     onChange={event => {}}
                     name="materialNameIndex"
                 >
                 </Select>
                </FormControl>
      </div>
      </DialogContent>
      </Dialog>
}

export default LineDialog