import React, {useState, useEffect} from 'react'; 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {DialogTitle} from "../../../components";
import makeStyles from '@material-ui/styles/makeStyles';
import useFromValidator from '../../../common/formValidator';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
const schema = {
    name: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    location: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    latitude: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    longitude: {
        presence: {allowEmpty: false, message: 'is required'}
    },
    pincode: {
        presence: {allowEmpty: false, message: 'is required'},
        type: 'integer',
        numericality: {
            greaterThanOrEqualTo: 100000,
            lessThanOrEqualTo: 999999
        }
    }
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    textField: {
        marginTop: theme.spacing(2)
    },
    longitudeTextField: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '2'
    },
}));


const Farm = ({deleteErrorMessage, errorMessage, onEdit, farm, onSave, handleClose, showDialog, editMode = false, }) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [popupLoading, setPopupLoading] = useState(false) ;
    const resetBeforeEdit = () => {
        if(showDialog){
            updateData(farm, editMode);
        }
    };

    useEffect(resetBeforeEdit, [showDialog]);



    const onSubmit = () => {
        setPopupLoading(true) 
        if (editMode)
            onEdit(formState.values, setPopupLoading); 
        else
            onSave(formState.values, setPopupLoading); 
    };

    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title"   >{editMode?'Update':'New'} Farm
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/>
                </div>
                <br/><br/>
                    <TextField
                        disabled
                        className={classes.textField}
                        error={hasError('name')}
                        fullWidth
                        helperText={
                            hasError('name') ? formState.errors.name[0] : null
                        }
                        label="Farm name"
                        name="name"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.name || ''}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                    />
                    <TextField
                        disabled
                        className={classes.textField}
                        error={hasError('location')}
                        fullWidth
                        helperText={
                            hasError('location') ? formState.errors.location[0] : null
                        }
                        label="Farm location"
                        name="location"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.location || ''}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                    />
                    <TextField
                        disabled
                        className={classes.textField}
                        error={hasError('latitude')}
                        helperText={
                            hasError('latitude') ? formState.errors.latitude[0] : null
                        }
                        label="Farm Latitude"
                        name="latitude"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.latitude || ''}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                    />
                    <TextField
                        disabled
                        className={classes.longitudeTextField}
                        error={hasError('longitude')}
                        helperText={
                            hasError('longitude') ? formState.errors.longitude[0] : null
                        }
                        label="Farm Longitude"
                        name="longitude"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.longitude || ''}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                    />
                    <TextField
                        disabled
                        className={classes.textField}
                        error={hasError('pincode')}
                        fullWidth
                        helperText={
                            hasError('pincode') ? formState.errors.pincode[0] : null
                        }
                        label="Farm pincode"
                        name="pincode"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.pincode || ''}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                    />
                    <DialogActions>
                        <Button
                            className={classes.signInButton}
                            color="primary"
                            disabled
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </div>
                :
                <div>
                    <div id="error">
                        {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
                    </div> 
                    <br/>
                    <TextField
                        className={classes.textField}
                        error={hasError('name')}
                        fullWidth
                        helperText={
                            hasError('name') ? formState.errors.name[0] : null
                        }
                        label="Farm name"
                        name="name"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.name || ''}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                    />
                    <TextField
                        className={classes.textField}
                        error={hasError('location')}
                        fullWidth
                        helperText={
                            hasError('location') ? formState.errors.location[0] : null
                        }
                        label="Farm location"
                        name="location"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.location || ''}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                    />
                    <TextField
                        className={classes.textField}
                        error={hasError('latitude')}
                        helperText={
                            hasError('latitude') ? formState.errors.latitude[0] : null
                        }
                        label="Farm Latitude"
                        name="latitude"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.latitude || ''}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                    />
                    <TextField
                        className={classes.longitudeTextField}
                        error={hasError('longitude')}
                        helperText={
                            hasError('longitude') ? formState.errors.longitude[0] : null
                        }
                        label="Farm Longitude"
                        name="longitude"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.longitude || ''}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                    />
                    <TextField
                        className={classes.textField}
                        error={hasError('pincode')}
                        fullWidth
                        helperText={
                            hasError('pincode') ? formState.errors.pincode[0] : null
                        }
                        label="Farm pincode"
                        name="pincode"
                        onChange={handleChange}
                        type="number"
                        value={formState.values.pincode || ''}
                        variant="outlined"
                        style={{backgroundColor:"#ffffff"}}
                    />
                    <DialogActions>
                        <Button
                            className={classes.signInButton}
                            color="primary"
                            disabled={!formState.isValid}
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </div>
          }

        </DialogContent>
    </Dialog>
};
export default Farm;