import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useGlobalState} from '../../../state';
import { version } from '../../../common/enums';

const schema = {
    amount: {
        presence: { allowEmpty: false, message: 'is required' },
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    date: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    farmIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    incomeTypeIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '2'
    },
}));

const OtherIncome = ({ deleteErrorMessage, errorMessage, farms, customers, incomeTypes, onEdit, data, onSave, handleClose, showDialog, editMode = false }) => {
    const classes = useStyles();
    const [{user}] = useGlobalState();
    const [formState, hasError, handleChange, updateData] = useFromValidator(schema);
    const [incomeTypeNoteVisible, setIncomeTypeNoteVisible] = useState(false)
    const [customerNoteVisible, setCustomerNoteVisible] = useState(false)
    const [popupLoading, setPopupLoading] = useState(false) ;
    const [customerValidation,setcustomerValidation] = useState(false) ;
    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if (showDialog) {
            updateData(data, editMode);
        }
        if (data !== undefined && data.incomeTypeId === 0) {
            setIncomeTypeNoteVisible(true)
        } else {
            setIncomeTypeNoteVisible(false)
        }
        if (data !== undefined && data.customerId === 0) {
            setCustomerNoteVisible(true)
        } else {
            setCustomerNoteVisible(false)
        }
    };
    useEffect(resetBeforeEdit, [showDialog]);

    const onSubmit = () => {
        setPopupLoading(true) 
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
        const tempData = {
            ...formState.values,
            customerId: (user.versionID === version.proPlus) ? customers[formState.values.customerIndex].id : 0,
            farmId: farms[formState.values.farmIndex].id,
            incomeTypeId: incomeTypes[formState.values.incomeTypeIndex].id,
            customerName: (user.versionID === version.proPlus) ?  customers[formState.values.customerIndex].name :" ",
            farmName: farms[formState.values.farmIndex].name,
            incomeTypeName: incomeTypes[formState.values.incomeTypeIndex].incomeTypeName,
            amount: formState.values.amount
        };
        if (editMode)
            onEdit(tempData, setPopupLoading);
        else
            onSave(tempData, setPopupLoading);
    };


    useEffect(() => {
        const customerIndex = formState.values?.customerIndex
        if (user.versionID === version.pro) {
            setcustomerValidation(true)
        } else if(customerIndex)  {
            setcustomerValidation(true)
        }
        // eslint-disable-next-line
    }, [formState.values, schema]);

    const handleIfIncomeTypeOther = (event) => {
        console.debug("In handleIfNote", event);
        if (event.target.name === "incomeTypeIndex" && event.target.value === 0) {
            setIncomeTypeNoteVisible(true)
        } else {
            setIncomeTypeNoteVisible(false)
        }
    }

    const handleIfCustomerOther = (event) => {
        console.debug("In handleIfNote", event);
        if (event.target.name === "customerIndex" && event.target.value === 0) {
            setCustomerNoteVisible(true)
        } else {
            setCustomerNoteVisible(false)
        }
    }



    return <Dialog disableBackdropClick open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title" >{editMode ? 'Update' : 'New'} Other Income
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
        {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>

                <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
                <DatePicker
                disabled
                    autoOk
                    halfWidth
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    animateYearScrolling
                    name="date"
                    style={{ backgroundColor: "#ffffff" }}
                />
            </MuiPickersUtilsProvider>
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="farmName-select-outlined-label">
                    Farm Name
                </InputLabel>
                <Select disabled
                    id="farmName-select"
                    value={formState.values.farmIndex !== undefined ? formState.values.farmIndex : ''}
                    onChange={handleChange}
                    name="farmIndex"
                >
                    {farms !== undefined && farms !== null ?
                        farms.map((farm, index) =>
                            <MenuItem key={farm.id} value={index}>{farm.name}</MenuItem>
                        ) : ""}
                </Select>
            </FormControl>
            {
                 (user.versionID === version.proPlus) && 
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="customerName-select-outlined-label">
                    Customer Name
                </InputLabel>
                <Select disabled
                    id="customerName-select"
                    value={formState.values.customerIndex !== undefined ? formState.values.customerIndex : ''}
                    onChange={data => {
                        handleChange(data, handleIfCustomerOther)
                    }}
                    name="customerIndex"
                >
                    {customers !== undefined && customers !== null ?
                        customers.map((customer, index) =>
                            <MenuItem key={customer.id} value={index}>{customer.name}</MenuItem>
                        ) : ""}
                </Select>
            </FormControl>
            }
            {customerNoteVisible ?
                <TextField disabled
                    multiline
                    rows="3"
                    className={classes.textField}
                    error={hasError('customerNote')}
                    fullWidth
                    helperText={
                        hasError('customerNote') ? formState.errors.customerNote[0] : null
                    }
                    label="Customer Note"
                    name="customerNote"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.customerNote || ''}
                    variant="outlined"
                    style={{ backgroundColor: "#ffffff" }}
                />
                : null}
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="incomeTypeName-select-label">Income Type</InputLabel>
                <Select disabled
                    id="incomeTypeName-select"
                    value={formState.values.incomeTypeIndex !== undefined ? formState.values.incomeTypeIndex : ''}
                    onChange={data => {
                        handleChange(data, handleIfIncomeTypeOther)
                    }}
                    name="incomeTypeIndex"
                >
                    {incomeTypes.map((incomeType, index) =>
                        <MenuItem key={incomeType.id} value={index}>{incomeType.incomeTypeName}</MenuItem>
                    )}
                </Select>
            </FormControl>
            {incomeTypeNoteVisible ?
                <TextField disabled
                    multiline
                    rows="3"
                    className={classes.textField}
                    error={hasError('incomeTypeNote')}
                    fullWidth
                    helperText={
                        hasError('incomeTypeNote') ? formState.errors.incomeTypeNote[0] : null
                    }
                    label="Income Type Note"
                    name="incomeTypeNote"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.incomeTypeNote || ''}
                    variant="outlined"
                    style={{ backgroundColor: "#ffffff" }}
                />
                : null}
            <TextField disabled
                className={classes.textField}
                error={hasError('amount')}
                fullWidth
                helperText={
                    hasError('amount') ? formState.errors.amount[0] : null
                }
                label="Amount (in Rs.)"
                name="amount"
                onChange={handleChange}
                type="number"
                value={formState.values.amount || ''}
                variant="outlined"
                style={{ backgroundColor: "#ffffff" }}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>
                :
                <div>


        <div id="error">
          {errorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={errorMessage}/> : ''}
        </div> 
        <br/>
            <MuiPickersUtilsProvider class={classes.datePicker} utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    halfWidth
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    animateYearScrolling
                    name="date"
                    style={{ backgroundColor: "#ffffff" }}
                />
            </MuiPickersUtilsProvider>
            <FormControl className={classes.formControl}>
                <InputLabel id="farmName-select-outlined-label">
                    Farm Name
                </InputLabel>
                <Select
                    id="farmName-select"
                    value={formState.values.farmIndex !== undefined ? formState.values.farmIndex : ''}
                    onChange={handleChange}
                    name="farmIndex"
                >
                    {farms !== undefined && farms !== null ?
                        farms.map((farm, index) =>
                            <MenuItem key={farm.id} value={index}>{farm.name}</MenuItem>
                        ) : ""}
                </Select>
            </FormControl>
            {
            (user.versionID === version.proPlus) && 
            <FormControl className={classes.formControl}>
                <InputLabel id="customerName-select-outlined-label">
                    Customer Name
                </InputLabel>
                <Select
                    id="customerName-select"
                    value={formState.values.customerIndex !== undefined ? formState.values.customerIndex : ''}
                    onChange={data => {
                        handleChange(data, handleIfCustomerOther)
                    }}
                    name="customerIndex"
                >
                    {customers !== undefined && customers !== null ?
                        customers.map((customer, index) =>
                            <MenuItem key={customer.id} value={index}>{customer.name}</MenuItem>
                        ) : ""}
                </Select>
            </FormControl>
            }
            {customerNoteVisible  && (user.versionID === version.proPlus) ?
                <TextField
                    multiline
                    rows="3"
                    className={classes.textField}
                    error={hasError('customerNote')}
                    fullWidth
                    helperText={
                        hasError('customerNote') ? formState.errors.customerNote[0] : null
                    }
                    label="Customer Note"
                    name="customerNote"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.customerNote || ''}
                    variant="outlined"
                    style={{ backgroundColor: "#ffffff" }}
                />
                : null}
            <FormControl className={classes.formControl}>
                <InputLabel id="incomeTypeName-select-label">Income Type</InputLabel>
                <Select
                    id="incomeTypeName-select"
                    value={formState.values.incomeTypeIndex !== undefined ? formState.values.incomeTypeIndex : ''}
                    onChange={data => {
                        handleChange(data, handleIfIncomeTypeOther)
                    }}
                    name="incomeTypeIndex"
                >
                    {incomeTypes.map((incomeType, index) =>
                        <MenuItem key={incomeType.id} value={index}>{incomeType.incomeTypeName}</MenuItem>
                    )}
                </Select>
            </FormControl>
            {incomeTypeNoteVisible ?
                <TextField
                    multiline
                    rows="3"
                    className={classes.textField}
                    error={hasError('incomeTypeNote')}
                    fullWidth
                    helperText={
                        hasError('incomeTypeNote') ? formState.errors.incomeTypeNote[0] : null
                    }
                    label="Income Type Note"
                    name="incomeTypeNote"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.incomeTypeNote || ''}
                    variant="outlined"
                    style={{ backgroundColor: "#ffffff" }}
                />
                : null}
            <TextField
                className={classes.textField}
                error={hasError('amount')}
                fullWidth
                helperText={
                    hasError('amount') ? formState.errors.amount[0] : null
                }
                label="Amount (in Rs.)"
                name="amount"
                onChange={handleChange}
                type="number"
                value={formState.values.amount || ''}
                variant="outlined"
                style={{ backgroundColor: "#ffffff" }}
            />
            <DialogActions>
                <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid || !customerValidation}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            </div>

}
{}

        </DialogContent>
    </Dialog>
};

export default OtherIncome;