import React, { useEffect, useState } from 'react';
import api from '../../../common/api';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../components";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useFromValidator from '../../../common/formValidator';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MaterialTable } from '../../../components';
import Material from "./material";
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableBody';
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import {useGlobalState} from '../../../state';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { populateTaxData } from '../../../common/functions';



const schema = {
    linePurchaseIndex: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    truckNumber: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    date: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    linePurchaseMaterials: {
        presence: { allowEmpty: false, message: 'is required' }
    },
};

const useStyles = makeStyles(theme => ({
    
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0),
        color: theme.palette.text.default,
      },  
    formControl: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2.7),
        marginBottom: theme.spacing(2),
        minWidth: 160
    },
    textField: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        minWidth: 160,
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    datePicker: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1),
        minWidth: 160
    },
    addButton: {
        textAlign: "right",
        marginBottom: theme.spacing(2),
        minWidth: 160,
    },
    totalTable: {
        float: 'right',
        width: theme.spacing(37.5)
    },
    totalCell: {
        tableLayout:'fixed',
        width: 500,
        paddingBottom: 0,
        paddingLeft: theme.spacing(0)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2),
        position: 'absolute',
        top: '40%',
        zIndex: '9999'
    },
}));
let materialIndex = 0
let currentMaterial = undefined
let editMaterialMode = false
const Purchase = ({ deleteErrorMessage, purchaseerrorMessage, vendors, materials, lineNames, materialTypes, showError, showMessage, farms, onEdit, data, onSave, handleClose, showDialog, editMode = false, resetBillTotal, setResetBillTotal}) => {
    const classes = useStyles();
    const [formState, hasError, handleChange, updateData, setFormState] = useFromValidator(schema);
    const [showMaterialDialog, setShowMaterialDialog] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [linePurchaseMaterials, setLinePurchaseMaterials] = useState([])
    const [loading, setLoading] = useState(false)
    const [materialNames, setMaterialNames] = useState([])
    const [billValueSum, setBillValueSum] = useState(0.0)
    const [, setLinePurchaseCheck] = useState(false)
    const [popupLoading, setPopupLoading] = useState(false) ;
    const history = useHistory();
    const [{user}] = useGlobalState();
    const [TaxProfiles, setTaxProfiles] = useState([]);
    const [taxProfileHashMap, setTaxProfileHashMap] = useState(null);
    const [FinalTotalBillAmt, setFinalTotalBillAmt] = useState(0);
    const [showTcs, setShowTcs] = useState(false)
    const [showTds, setShowTds] = useState(false)
    const [taxProfileIDs, setTaxProfileIDs] = useState([]);
    const [TcsTaxValue, setTcsTaxValue] = useState(null)
    const [TdsTaxValue, setTdsTaxValue] = useState(null)
    const [taxValue, setTaxValue] = useState(0.0)

    const GST = "GST";
    const TDS = "TDS"
    const TCS = "TCS"
    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }


      useEffect(() => {
        if (TaxProfiles !== null && TaxProfiles !== undefined && Array.isArray(TaxProfiles) && TaxProfiles.length > 0) {
            let map = new Map()
            for (const el of TaxProfiles) {
                map.set(el.id, el)
            }
            setTaxProfileHashMap(map)
        }
    }, [TaxProfiles])

    //Called if the Farm Property is Set for Editing
    const resetBeforeEdit = () => {
        if (showDialog) {
            setTaxProfileIDs([]);
            setPopupLoading(false);
            setLinePurchaseMaterials([]);
            setBillValueSum(0.0)
            setTcsTaxValue(undefined);
            setTdsTaxValue(undefined);
            setShowTds(false);
            setShowTcs(false);
            setFinalTotalBillAmt(0)
            setTaxValue(0)
            if (editMode) {
                setBillValueSum(0.0)
                setTaxValue(data.tax)
                if (data.tcs && data.tcs > 0) {
                    setTcsTaxValue(data.tcs)
                    setShowTcs(true);
                }
                if (data.tds && data.tds > 0) {
                    setTdsTaxValue(data.tds)
                    setShowTds(true);
                }
                setLinePurchaseMaterials(data.linePurchaseMaterials)
                setResetBillTotal(true)
            }
            updateData(data, editMode);
            populateTaxData(setTaxProfiles,[GST,TDS,TCS])
        }
    };

    useEffect(resetBeforeEdit, [showDialog]);

    useEffect(() => {
        // calculateTotalOfPurchaseMaterial(purchaseMaterials);
        totalBillValue()
        // eslint-disable-next-line
    }, [resetBillTotal])

    const onSubmit = () => {
        setPopupLoading(true) 
        let tempData = {
            ...formState.values,
            movedStorageID: formState.values.onLinePurchaseSwitch ? 1 : 0,
            lineNameID: lineNames[formState.values.linePurchaseIndex].id,
            lineName: lineNames[formState.values.linePurchaseIndex].name,
            totalBillValue: billValueSum,
            tcs:showTcs !== null && showTcs !== undefined && showTcs === true? TcsTaxValue : 0,
            tds:showTds !== null && showTds !== undefined && showTds === true? TdsTaxValue : 0,
            tax:taxValue !== undefined && taxValue!== null ? taxValue :0,
            taxProfileIDs: taxProfileIDs
        };
        if(formState.values.vendorIndex > -1){
            tempData = {
            ...tempData,
            vendorId: vendors[formState.values.vendorIndex].id,
            vendorName: vendors[formState.values.vendorIndex].name,
            }
        }
        if (editMode){
            onEdit(tempData, setPopupLoading);
        }
        else{
            onSave(tempData, setPopupLoading);
        }
            
        currentMaterial = undefined
        setLinePurchaseMaterials(formState.values.linePurchaseMaterials);
        var errorElement = document.getElementById("error");
        if (errorElement) {
            errorElement.scrollIntoView();
        }
    };

    const removeErrorMessage = value =>{
        setErrorMessage('');
    }

    const changeToUpperCase = (event) => {
        let tempTruckNumber = (event.target.value).toUpperCase()
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                truckNumber: tempTruckNumber
            }
        })
    }

    function onNewMaterial(event) {
        console.debug(formState)
        editMaterialMode = false;
        event.preventDefault();
        setShowMaterialDialog(true);
    }

    function onMaterialEdit(rowData) {
        console.log("rowdata", rowData)
            currentMaterial = {
                ...rowData,
                materialNameIndex: rowData.materialNameIndex,
                quantity: rowData.quantity,
                costPerUnit: rowData.costPerUnit,
            }
            editMaterialMode = true
            setShowMaterialDialog(true);
           
    }

    

    function onMaterialDelete(rowData) {
        //editMode = true
        let index = (formState.values.linePurchaseMaterials).findIndex((srcPurchaseMaterial) => {
            return srcPurchaseMaterial.id === rowData.id;

        });
        console.log("uindeex", index)

        let newLinePurchaseMaterials = [...formState.values.linePurchaseMaterials];
        let newPurchase = {}
        console.log('editmode', editMode)
        if (editMode) {
            let newDeletedLinePurchaseMaterials = []
            if (formState.values.deletedLinePurchaseMaterials !== undefined && formState.values.deletedLinePurchaseMaterials !== null) {
                newDeletedLinePurchaseMaterials = [...formState.values.deletedLinePurchaseMaterials]
            }
            newLinePurchaseMaterials.splice(index, 1);
            console.log("after splice", newLinePurchaseMaterials)
            newDeletedLinePurchaseMaterials = [
                ...newDeletedLinePurchaseMaterials,
                rowData
            ]
            console.log("newdeleted materials", newDeletedLinePurchaseMaterials)
            newPurchase = {
                ...formState.values,
                linePurchaseMaterials: newLinePurchaseMaterials,
            }
            if (rowData.id > 0) {
                newPurchase = {
                    ...newPurchase,
                    deletedLinePurchaseMaterials: newDeletedLinePurchaseMaterials
                }
            }
        } else {
            newLinePurchaseMaterials.splice(index, 1);
            newPurchase = {
                ...formState.values,
                linePurchaseMaterials: newLinePurchaseMaterials
            }
        }
        //Reducing the material index only if the deleted material is newly entered material
        if (rowData.id < 0) {
            materialIndex++
        }
        console.log("from material delete", formState.values.deletedLinePurchaseMaterials)
        console.log("material purchase", linePurchaseMaterials)
        setLinePurchaseMaterials(newLinePurchaseMaterials)
        updateData(newPurchase, true);
    }
 
    
    const totalBillValue = (event) => {
        if (formState.values.linePurchaseMaterials !== undefined) {
            let total = 0.0
            formState.values.linePurchaseMaterials.forEach((linePurchaseMaterial, index) => {

                if (linePurchaseMaterial.billValue > 0.0) {
                    total = total + linePurchaseMaterial.billValue
                }
            })
            total = parseFloat(total.toFixed(2))
            setBillValueSum(total)
            let TCS = showTcs !== null && showTcs !== undefined && showTcs === true? (total * (TcsTaxValue / 100)):0;
            let TDS = showTds !== null && showTds !== undefined && showTds === true?(total * (TdsTaxValue / 100)):0;
            let TAX = taxValue !== undefined && taxValue!== null ? (total * (taxValue / 100)):0;
            let finalTotal = (total + TAX  + TCS) - TDS;
            setFinalTotalBillAmt(finalTotal);        
        }   
    }

    useEffect(totalBillValue, [linePurchaseMaterials])

    function onMaterialUpdate(purchaseMaterial) {

        console.debug(formState.values.linePurchaseMaterials, purchaseMaterial)
        let index = (formState.values.linePurchaseMaterials).findIndex((srcPurchaseMaterial) => {
            return srcPurchaseMaterial.id === purchaseMaterial.id;
        });
        let tempLinePurchaseMaterials = [...(formState.values.linePurchaseMaterials).slice(0, index),
        { ...purchaseMaterial },
        ...(formState.values.linePurchaseMaterials).slice(index + 1)]

        let tempFormStateValues = {
            ...formState.values,
            linePurchaseMaterials: tempLinePurchaseMaterials
        }
        console.debug(tempFormStateValues, tempLinePurchaseMaterials)
        setLinePurchaseMaterials(tempLinePurchaseMaterials)
        updateData(tempFormStateValues, true);
        setShowMaterialDialog(false);
    }

    function onMaterialSave(material) {
        console.debug(formState.values, material)
        let tempFormState = {}
        let tempPurchaseMaterial = []

        if (formState.values.linePurchaseMaterials === undefined) {
            tempPurchaseMaterial = [
                material
            ]
            tempFormState = {
                ...formState.values,
                linePurchaseMaterials: [
                    material
                ]
            }
        } else {
            tempPurchaseMaterial = [
                ...formState.values.linePurchaseMaterials,
                material]
            tempFormState = {
                ...formState.values,
                linePurchaseMaterials: tempPurchaseMaterial
            }
        }
        materialIndex--
        console.debug(tempFormState, formState)
        updateData(tempFormState, true);
        setLinePurchaseMaterials(tempFormState.linePurchaseMaterials)
        console.debug(tempPurchaseMaterial, formState)
        setShowMaterialDialog(false);
    }

    const onGetMaterialNames = (materialId) => {
        setMaterialNames([])
        showError('');
        setLoading(true);
        const response = api.get(`materials-of-type/${materialId}`);
        response.then(res => {
            setMaterialNames([...res]);
            if (editMaterialMode === true) {
                currentMaterial = {
                    ...currentMaterial,
                    materialIndex: res.findIndex((material) => { return material.id === currentMaterial.materialId; }),
                }
                if (currentMaterial.inventoryType === 1) {
                    setShowMaterialDialog(true);
                }
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found') {
                    setErrorMessage('No Materials found for the chosen material type');
                }
                else {
                    setErrorMessage(err.message);
                }
            } else {
                setErrorMessage('Unknown Error');
            }
        });

    }


    function handleMaterialClose() {
        currentMaterial = undefined;
        setShowMaterialDialog(false);
    }

    function onLinePurchaseChange(event) {
        formState.values.onLinePrchaseSwitch ?

        setLinePurchaseCheck(true)
        :
        setLinePurchaseCheck(false)
    }

    function getTaxProfile(e) {
        let vendorID = vendors[e.target.value].id
        /**
         * Note: The below condition is executed upon editing an old record, so the old tax values are used
         */
        if (data !== undefined && data !== null && vendorID === data.vendorId) {
            setTaxValue(data.tax)
            setTcsTaxValue(data.tcs)
            setShowTds(data.tds > 0 ? true : false)
            setTdsTaxValue(data.tds)
            setShowTcs(data.tcs > 0 ? true : false)
            let total = (billValueSum + (billValueSum * (data.tax / 100))  + (billValueSum * (data.tcs / 100))) - (billValueSum * (data.tds / 100));
            setFinalTotalBillAmt(total)
            return;
        }
        api.get(`/customer-vendor-details-tax-profile/${vendorID}`)
        .then((res)=>{
            setTaxProfileIDs(res)
            let TCS=0;
            let TDS=0;
            let TAX=0;
            let obj = {
                tcs:{
                    setState:function() {
                        setTcsTaxValue(null)
                        setShowTcs(false)
                    },
                    set:false
                },tds:{
                    setState:function() {
                        setTdsTaxValue(null)
                        setShowTds(false)
                    },
                    set:false
                },
                tax:{
                    setState:function() {
                        setTaxValue(0.0)
                    },
                    set:false
                },

            }
            for (const el of res) {
                if (taxProfileHashMap.get(el) !== undefined && taxProfileHashMap.get(el) !== null && Object.keys(taxProfileHashMap.get(el)).length > 0) {
                    let {taxTypeId, taxPercentage} = taxProfileHashMap.get(el)
                    if (taxTypeId === 1) {
                        TAX = (billValueSum * (taxPercentage / 100));
                        setTaxValue(taxPercentage)
                        obj.tax.set = true;
                    }else if (taxTypeId === 2) {
                        TDS = (billValueSum * (taxPercentage / 100));
                        setTdsTaxValue(taxPercentage)
                        setShowTds(true)
                        obj.tds.set = true
                    }else {
                        TCS = (billValueSum * (taxPercentage / 100));
                        setTcsTaxValue(taxPercentage)
                        setShowTcs(true)
                        obj.tcs.set = true
                    }
                }
            }
            for (const key in obj) {
                if (obj[key]["set"] === false) {
                    obj[key]["setState"]()
                }
            }
            if (billValueSum > 0) {
                    let finalTotal = (billValueSum + TAX  + TCS) - TDS;
                    setFinalTotalBillAmt(finalTotal);
            }

        })
        .catch((err)=>{
            if (err.message.includes(404)) {
                setTaxProfileIDs([])
                setTaxValue(0.0)
                setTcsTaxValue(null)
                setTdsTaxValue(null)
                setShowTcs(false)
                setShowTds(false)
                let billVal = billValueSum
                setFinalTotalBillAmt(billVal)
            }

        })
    }
        
    
    let actions = [
        {
            icon: () => <Icon color='primary'>edit</Icon>,

            tooltip: 'Edit purchase Details',
            onClick: (event, rowData) => onMaterialEdit(rowData)
        },
        {
            icon: () => <Icon color='error'>delete</Icon>,
            tooltip: 'Delete purchase Details',
            onClick: (event, rowData) => onMaterialDelete(rowData)
        }
    ]

    return <Dialog disableBackdropClick maxWidth='md' loading={loading} open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog- title" >{editMode ? 'Update' : 'New'} Purchase
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
          {
            popupLoading ?
            <div>
              <div className={classes.progressContainer}>
                      <CircularProgress  className={classes.progressBar}/ >
                </div>
                <br/><br/>



                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                <DatePicker disabled
                    className={classes.textField}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="lineSaleName-select-outlined-label">
                    Line Name
                </InputLabel>
                <Select
                id="linePurchaseIndex"
                value={formState.values.linePurchaseIndex !== undefined ? formState.values.linePurchaseIndex : ''}
                onChange={handleChange}
                name="linePurchaseIndex"
                >
                      {lineNames !== undefined && lineNames !== null ?
                    lineNames.map((lineSale,index)=>
                    <MenuItem key = {lineSale.ID} value={index}>{lineSale.name}</MenuItem>
                ):""}     
                </Select>
            </FormControl>
            <TextField disabled
                className={classes.textField}
                error={hasError('truckNumber')}
                helperText={
                    hasError('truckNumber') ? formState.errors.truckNumber[0] : null
                }
                label="Truck Number"
                name="truckNumber"
                onChange={event => { handleChange(event, changeToUpperCase) }}
                type="text"
                value={formState.values.truckNumber !== undefined ? formState.values.truckNumber : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            {formState.values.onLinePurchaseSwitch ?
            null
            :
            <FormControl disabled className={classes.formControl}>
                <InputLabel id="vendorName-select-outlined-label">
                    Vendor Name
                </InputLabel>
                <Select disabled
                    id="vendorName-select"
                    value={formState.values.vendorIndex !== undefined ? formState.values.vendorIndex : ''}
                    onChange={(e)=>{handleChange(e, getTaxProfile)}}
                    name="vendorIndex"
                >
                    {vendors !== undefined && vendors !== null ?
                        vendors.map((vendor, index) =>
                            <MenuItem key={vendor.id} value={index}>{vendor.companyName}</MenuItem>
                        ) : ""}
                </Select>
                    <Link disabled
                    color="inherit"
                    href="#"
                    onClick={() =>{return  history.push("/customer-vendor-details")}}
                    className={classes.link}
                    style={{color:"#02a6c7"}}
                    > Add new Vendor</Link>
            </FormControl>
            } 
            {/* <TextField disabled
                className={classes.textField}
                error={hasError('tds')}
                helperText={
                    hasError('tds') ? formState.errors.tds[0] : null
                }
                label="TCS(%)"
                name="tds"
                onChange={handleChange}
                type="number"
                value={formState.values.tds !== undefined ? formState.values.tds : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            /> */}
            <FormControlLabel  disabled
                className={classes.formControlLabelClass}
                control={
                    <Switch
                        // className={classes.switchClass}
                        checked={formState.values.onLinePurchaseSwitch}
                        //disabled={!rowData.isActive}
                        onChange={event => { handleChange(event,onLinePurchaseChange)}}
                        value={formState.values.onLinePurchase}
                        color="primary"
                        name="onLinePurchaseSwitch"
                        inputProps={{ 'aria-label': 'primary checkbox' }}/>
                }
                label="Moved From Storage"
            />
            <div className={classes.addButton} >
                <Button disabled variant="contained" color="primary" onClick={onNewMaterial}>Add Material</Button>
            </div>
            <MaterialTable disabled
                columns={[
                    { title: 'id', field: 'id', hidden: true },
                    { title: 'Item Name', field: 'materialName', editable: 'never' },
                    { title: 'Number of Trays', field: 'quantity', editable: 'never' },
                    { title: 'Cost Per Unit', field: 'costPerUnit', editable: 'never' },
                    {
                        title: 'Bill Value', field: 'billValue', editable: 'never',
                    }
                ]}
                style={{opacity: '0.6'}}
                data={linePurchaseMaterials}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    
                    toolbar: false,
                    paging: false
                }}
                //style={{boxShadow: 'unset'}}
                actions={actions}
            />
            <Table disabled className={classes.totalTable} >
                <TableHead  >
                    <TableRow>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >Total Bill Value </TableCell>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}>{billValueSum.toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    <TableRow className={classes.totalRow}  >
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >TCS</TableCell>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalRow} >{(billValueSum * ((TcsTaxValue / 100) || 0)).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    <TableRow className={classes.totalRow}  >
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >TDS</TableCell>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalRow} >{(billValueSum * ((TdsTaxValue / 100) || 0)).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    <TableRow className={classes.totalRow}  >
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >TAX</TableCell>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalRow} >{(billValueSum * ((taxValue / 100) || 0)).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}  >Final Total</TableCell>
                        <TableCell style={{opacity: '0.6'}} className={classes.totalCell}>{(FinalTotalBillAmt).toLocaleString(user.locales,amountObj) }</TableCell>
                    </TableRow>
                </TableHead  >
            </Table>

            </div>
                :
                <div>


        <div id="error">
          {purchaseerrorMessage ? <Chip onDelete={deleteErrorMessage} color="primary" label={purchaseerrorMessage}/> : ''}
        </div> 
        <br/>
            <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                <DatePicker
                    className={classes.textField}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={formState.values.date || null}
                    onChange={data => {
                        handleChange({ target: { name: 'date', value: data } })
                    }}
                    animateYearScrolling
                    name="date"
                    style={{backgroundColor:"#ffffff"}}
                />
            </MuiPickersUtilsProvider>
            <FormControl className={classes.formControl}>
                <InputLabel id="lineSaleName-select-outlined-label">
                    Line Name
                </InputLabel>
                <Select
                id="linePurchaseIndex"
                value={formState.values.linePurchaseIndex !== undefined ? formState.values.linePurchaseIndex : ''}
                onChange={handleChange}
                name="linePurchaseIndex"
                >
                      {lineNames !== undefined && lineNames !== null ?
                    lineNames.map((lineSale,index)=>
                    <MenuItem key = {lineSale.ID} value={index}>{lineSale.name}</MenuItem>
                ):""}     
                </Select>
            </FormControl>
            <TextField
                className={classes.textField}
                error={hasError('truckNumber')}
                helperText={
                    hasError('truckNumber') ? formState.errors.truckNumber[0] : null
                }
                label="Truck Number"
                name="truckNumber"
                onChange={event => { handleChange(event, changeToUpperCase) }}
                type="text"
                value={formState.values.truckNumber !== undefined ? formState.values.truckNumber : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            />
            {formState.values.onLinePurchaseSwitch ?
            null
            :
            <FormControl className={classes.formControl}>
                <InputLabel id="vendorName-select-outlined-label">
                    Vendor Name
                </InputLabel>
                <Select
                    id="vendorName-select"
                    value={formState.values.vendorIndex !== undefined ? formState.values.vendorIndex : ''}
                    onChange={(e)=>{handleChange(e, getTaxProfile)}}
                    name="vendorIndex"
                >
                    {vendors !== undefined && vendors !== null ?
                        vendors.map((vendor, index) =>
                            <MenuItem key={vendor.id} value={index}>{vendor.companyName}</MenuItem>
                        ) : ""}
                </Select>
                    <Link
                    color="inherit"
                    href="#"
                    onClick={() =>{return  history.push("/customer-vendor-details")}}
                    className={classes.link}
                    style={{color:"#02a6c7"}}
                    > Add new Vendor</Link>
            </FormControl>
            }
            {/* <TextField
                className={classes.textField}
                error={hasError('tds')}
                helperText={
                    hasError('tds') ? formState.errors.tds[0] : null
                }
                label="TCS(%)"
                name="tds"
                onChange={handleChange}
                type="number"
                value={formState.values.tds !== undefined ? formState.values.tds : ''}
                variant="outlined"
                style={{backgroundColor:"#ffffff"}}
            /> */}
            <FormControlLabel 
                className={classes.formControlLabelClass}
                control={
                    <Switch
                        // className={classes.switchClass}
                        checked={formState.values.onLinePurchaseSwitch}
                        //disabled={!rowData.isActive}
                        onChange={event => { handleChange(event,onLinePurchaseChange)}}
                        value={formState.values.onLinePurchase}
                        color="primary"
                        name="onLinePurchaseSwitch"
                        inputProps={{ 'aria-label': 'primary checkbox' }}/>
                }
                label="Moved From Storage"
            />
            <div className={classes.addButton} >
                <Button variant="contained" color="primary" onClick={onNewMaterial}>Add Material</Button>
            </div>
            <MaterialTable
                columns={[
                    { title: 'id', field: 'id', hidden: true },
                    { title: 'Item Name', field: 'materialName', editable: 'never' },
                    { title: 'Number of Trays', field: 'quantity', editable: 'never' },
                    { title: 'Cost Per Unit', field: 'costPerUnit', editable: 'never' },
                    {
                        title: 'Bill Value', field: 'billValue', editable: 'never',
                    }
                ]}
                
                data={linePurchaseMaterials}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    
                    toolbar: false,
                    paging: false
                }}
                //style={{boxShadow: 'unset'}}
                actions={actions}
            />
            <Table className={classes.totalTable} >
                <TableHead  >
                <TableRow>
                        <TableCell className={classes.totalCell}  >Total Bill Value </TableCell>
                        <TableCell className={classes.totalCell}>{billValueSum.toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    {
                        showTcs ?
                        <TableRow className={classes.totalRow}  >
                        <TableCell className={classes.totalCell}  >TCS</TableCell>
                        <TableCell className={classes.totalRow} >{(billValueSum * ((TcsTaxValue / 100) || 0)).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    :""
                    }
                    {
                        showTds ?
                        <TableRow className={classes.totalRow}  >
                        <TableCell className={classes.totalCell}  >TDS</TableCell>
                        <TableCell className={classes.totalRow} >{(billValueSum * ((TdsTaxValue / 100) || 0)).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>
                    :""
                    }


                    <TableRow className={classes.totalRow}  >
                        <TableCell className={classes.totalCell}  >TAX</TableCell>
                        <TableCell className={classes.totalRow} >{(billValueSum * ((taxValue / 100) || 0)).toLocaleString(user.locales,amountObj)}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className={classes.totalCell}  >Final Total</TableCell>
                        <TableCell className={classes.totalCell}>{(FinalTotalBillAmt).toLocaleString(user.locales,amountObj) }</TableCell>
                    </TableRow>
                </TableHead  >
            </Table>
            </div>
            }
        </DialogContent>
        {showMaterialDialog ?

            <Material deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} materialNames={materialNames}
                onGetMaterialNames={onGetMaterialNames} onEdit={onMaterialUpdate} data={currentMaterial} onSave={onMaterialSave} handleClose={handleMaterialClose} materials = {materials}
                showDialog={showMaterialDialog} editMode={editMaterialMode} materialIndex={materialIndex} loading={loading}>
            </Material>

            : null
        }

        <DialogActions>
            <Button
                className={classes.signInButton}
                color="primary"
                disabled={!formState.isValid || popupLoading}
                size="large"
                type="submit"
                variant="contained"
                onClick={onSubmit}
            >
                Save
            </Button>
        </DialogActions>

    </Dialog>
};


export default Purchase;