import React from 'react';
import { Bar } from 'react-chartjs-2';
import { RemoveYear } from '../../../common/date';

export default function FarmGraph({
    farm,
    weeklyData,
    onFarmDetails
}){
    const labels = [];
    const prodGraphData = [];
    const feedGraphData = [];
    const {id} = farm;

    // IIFE
    (()=>{

        let IsNoweeklyData = false
        Object.keys(weeklyData).map(key=>{
            if (weeklyData[key]) {
                labels.push(RemoveYear(weeklyData[key].date));
            } else {
                IsNoweeklyData = true
            }
            if(weeklyData[key].produtionPercentage && weeklyData[key].feedIntake){
                
                var prodPer = (weeklyData[key].produtionPercentage %1 === 0) ? weeklyData[key].produtionPercentage : weeklyData[key].produtionPercentage.toFixed(1);
                var feedPB  = (weeklyData[key].feedIntakePerGram %1 === 0) ? weeklyData[key].feedIntakePerGram : weeklyData[key].feedIntakePerGram.toFixed(1);
                prodGraphData.push(prodPer);
                feedGraphData.push(feedPB);
            }
            else{
                prodGraphData.push(0);
                feedGraphData.push(0);
            }
            return true;
        });

        if(IsNoweeklyData) {
            // default last week
            let lastWeek = Array.from({length: 7}, (_, i) => {
                const d = new Date();
                d.setDate(d.getDate() - i);
                return `${d.getMonth() + 1}/${d.getDate()}`;
              }).reverse();
              
            labels.push(...lastWeek);
        }
    })();

    const ChartData = {
        labels:labels,
        datasets: [ 
            { 
                label: "Production %",
                backgroundColor: '#1B3A56',
                data:prodGraphData,
                borderWidth: 0
            },
            { 
                label: "Feed Per Bird",
                backgroundColor: '#F3B56B',
                data:feedGraphData,
                borderWidth: 0,
                stack: 'Stack 1'
            }
        ]
    }
        const ChartOptions = { 
            responsive: true ,
            legend: {
             display: true
            },
            onClick:function(e){
                var thisSrc    = this.chart.getElementAtEvent(e)[0];
                if(thisSrc){
                    var clickedEle = thisSrc._index;
                    if(clickedEle !== undefined){
                        var cFarmData = {};
                        cFarmData[id] = weeklyData[clickedEle+1]; // farm weekly data index starting from 1
                        onFarmDetails(farm,cFarmData);
                    }
                }
            },
            scales: {
                yAxes: [
                    {
                        ticks:{
                            min: 0,
                        }
                      }
                ]
              },
          }

    return(
            <Bar options={ChartOptions} data={ChartData} width={300} height={200}/>
    )
};